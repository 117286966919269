<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<div class="signature">
					<el-form ref="form" :model="form" :rules="rules" label-width="130px">
						<el-form-item label="短信类型">
							<el-radio-group v-model="form.template_type">
								<el-radio :label="1">短信通知</el-radio>
								<el-radio :label="2">推广短信</el-radio>
								<el-radio :label="3">国际/港澳台消息</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="模板名称" prop="template_name">
							<el-input class="inputWidth" size="small" v-model="form.template_name"  maxlength="30" show-word-limit placeholder="请输入名称 不超过30个字符"></el-input>
						</el-form-item>
						<el-form-item label="模板标识" prop="templateIdentifying">
							<el-select size="small" v-model="form.templateIdentifying">
								<el-option v-for="(mode,index) in templateIdentifyingdata" :key="index" :value="mode.id" :label="mode.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="模板内容" prop="template_content">
							<el-input class="inputWidth" type="textarea" resize="none" size="small" :autosize="{ minRows:5, maxRows: 6}"
							 placeholder="请输入短信模板内容"  maxlength="500" show-word-limit v-model="form.template_content">
							</el-input>
						</el-form-item>
						<el-form-item label="申请说明" prop="reason">
							<el-input class="inputWidth" type="textarea" resize="none" size="small" :autosize="{ minRows:7, maxRows: 8}"
							 placeholder="请输入备注"  maxlength="100" show-word-limit v-model="form.reason">
							</el-input>
						</el-form-item>
						<el-form-item>
							<div class="hinttext">● 预计两小时完成审核</div>
							<div class="hinttext">● 审核工作时间：周一至周日9：00-23：00（法定节假日延顺）</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet('form')">提交</div>
		</div>
	</div>
</template>

<script>
	import {
		addSmsTemplate,//添加
		templateIdentifying,//模板标识
		modifySmsTemplate,//修改
		querySmsTemplate//模板详情
	} from '@/api/plat/setting.js'
	export default {
		data() {
			return {
				form: {
					template_type: 1,
					template_name: '',
					templateIdentifying: '',
					template_content: '',
					reason: ''
				},
				rules: {
					template_name: [
						 { required: true, message: '请输入模板名称', trigger: 'blur' },
						 { min:1 , max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
					],
					template_content: [
						{ required: true, message: '请输入模板内容', trigger: 'blur' },
						{ min:1 , max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }
					],
					reason: [
						{ required: true, message: '请输入备注信息', trigger: 'blur' },
						{ min:1 , max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
					],
					 templateIdentifying: [
							{ required: true, message: '请选择活动区域', trigger: 'change' }
						  ],
				},
				templateIdentifyingdata: [],
			}
		},
		mounted() {
			this.templateIdentifyings();
			if(this.$route.query.id){
				this.querySmsTemplatedata();
			}
			
		},
		methods: {
			//模板详情
			querySmsTemplatedata(){
				let data={
					sms_template_id:this.$route.query.id
				}
				data = this.tools.filterParams(data);
				querySmsTemplate(data).then(res=>{
					if (res.return_code == 'SUCCESS') {
						this.form = res.data;
					}
				})
				
			},
			//模板标识
			templateIdentifyings() {
				let params = {
					hide:false
				}
				templateIdentifying(params).then(res => {
					if (res.return_code == 'SUCCESS') {
						let data = res.data;
						if (data != '') {
							this.templateIdentifyingdata=data;
							} else {
							//this.templateIdentifyingdata = []
							this.$message.error("没有可创建的模板")
						}
					}
				})
			},
			//提交申请
			submitSet() {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						if(this.$route.query.id){
							//添加模板
							let data =JSON.parse(JSON.stringify(this.form));
							data.sms_template_id = this.$route.query.id;
							data.remark = this.form.reason;
							data = this.tools.filterParams(data);
							//修改模板
							modifySmsTemplate(data).then(res=>{
								if (res.return_code == 'SUCCESS') {
									this.$router.go(-1)
								}
							})
							
						}else{
							//添加模板
							let data = this.form;
							data.remark = this.form.reason;
							data = this.tools.filterParams(data);
							addSmsTemplate(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.$router.go(-1)
								}
							})
						}
					

					} else {
						this.$message.error('请填写完整信息')
						return false;
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.signature {
		padding: 30px 15px;
		border-radius: 6px;
		// min-height: 300px;
		background-color: #f2f2f6;
		margin-bottom: 20px;

	}

	.next-form-item-control {
		display: inline-block;
	}

	.avatar-uploader1 {
		border-radius: 4px 4px;
		border: 1px solid #ddd;
		background-color: #fff;
		width: 200px;
		overflow: hidden;
		height: 240px;
		line-height: 240px;

		img {
			width: 100%;
			height: 100%;
		}

		.el-icon-plus {
			width: 200px;
			height: 240px;
			text-align: center;
			line-height: 240px;
		}
	}

	.next-form-item-control .ul-form-tips {
		padding: 0 0 0 20px;
		line-height: 18px;
		font-size: 12px;
		color: #a9b0b4;
		text-align: left;
		margin-top: 8px;
		margin-bottom: 0;
		list-style: disc;
	}

	.inputWidth {
		width: 350px;
	}

	.hinttext {
		line-height: 18px;
		font-size: 12px;
		color: #a9b0b4;
	}
</style>
