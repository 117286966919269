<template>
	<div>
		<div class="wrapper-cont">
			<div class="main-cont pp20">
				<el-button type="primary" size="small" @click="addMaterial">上传素材图</el-button>
			</div>
			<div class="main-cont pp20 mt10">
				<div class="setTitle">介绍图（C端)</div>
				<div class="intro-cont">
					<div class="intro-item" v-for="(intro,index) in introduceList" :key="index">
						<div class="intro-img">
							<img :src="intro.material_image" />
						</div>
						<div class="intro-opera">
							<el-button type="text" class="buttons" @click="editIntro(intro)">编辑</el-button>
							<el-button type="text" class="buttons" @click="delIntro(intro)">删除</el-button>
						</div>
					</div>
				</div>
			</div>
			<div class="main-cont pp20 mt10">
				<div class="setTitle">海报图（C端）</div>
				<div class="intro-cont">
					<div class="intro-item" v-for="(poster,posterindex) in posterList" :key="posterindex">
						<div class="intro-img">
							<img :src="poster.material_image" />
						</div>
						<div class="intro-opera">
							<el-button type="text" class="buttons" @click="editPoster(poster)">编辑</el-button>
							<el-button type="text" class="buttons" @click="delPoster(poster)">删除</el-button>
						</div>
					</div>

				</div>
			</div>
		</div>
		<!--上传素材-->
		<el-dialog title="上传素材" :visible.sync="dialogMaterialVisible" width="25%">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
				<el-form-item label="广告类型" prop="type">
					<el-select v-model="form.type" class="selectWidth" size="small">
						<el-option value="1" label="营销图介绍图(750*高度不限)"></el-option>
						<el-option value="2" label="海报图(750*1235)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input class="inputWidth" size="small" oninput="value=value.replace(/[^\d.]/g,'')"
						v-model="form.sort"></el-input>
					<div style="font-size: 14px; color: #333;">
						从小到大排序，数值越小越靠前
					</div>
				</el-form-item>
				<el-form-item label="图片" prop="material_image">
					<div class="img_blos">
						<div class="img_blo"  @click="uploadImg()">
							<img :src="form.material_image" class="item_img" v-if="form.material_image" style="margin-right: 0!important;" />
							<div class="plusImg" v-else>
								<i class="el-icon-plus avatar-uploader-icon"></i>
							</div>
						</div>
					</div>

				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogMaterialVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitForm('form')" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>

</template>

<script>
	import ChooseImg from '../../../../../common/uploadImgs.vue';
	import {
		materialList,
		materialInsert,
		materialDel,
		materialDetails,
		materialEdit
	} from "@/api/plat/market.js";
	export default {
		components: {
			ChooseImg
		},
		props: {
			plugin_id: String
		},
		data() {
			return {
				isSingle: true,
				uploadFlag: false,
				dialogMaterialVisible: false,
				introduceList: [],
				posterList: [],
				form: {
					type: '',
					sort: '',
					material_image: ''
				},
				rules: {
					type: [{
						required: true,
						message: '请选择广告类型',
						trigger: 'change'
					}],
					sort: [{
						required: true,
						message: '请填写排序',
						trigger: 'blur'
					}],
					material_image: [{
						required: true,
						message: '请上传图片',
						trigger: 'change'
					}],
				},
				material_id: ''

			}
		},
		mounted() {},
		methods: {
			getMaterialInfo() {
				let data = {
					plugin_id: this.plugin_id,
					material_type: '1'
				}
				data == this.tools.filterParams(data);
				materialList(data).then(res => {
					this.introduceList = res.data.Introduce;
					this.posterList = res.data.poster;
				})
			},
			//编辑介绍图
			editIntro(item) {
				this.dialogMaterialVisible = true;
				this.material_id = item.material_id;
				let data = {
					material_id: item.material_id,
					material_type: '1'
				}
				data = this.tools.filterParams(data);
				materialDetails(data).then(res => {
					this.form = res.data;
					this.form.type = this.form.type.toString()
				})
			},
			//删除介绍图
			delIntro(item) {
				this.tools.confirm("确定要删除此介绍图吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							plugin_id: this.plugin_id,
							material_id: item.material_id,
							material_type: '1'
						}
						data = this.tools.filterParams(data);
						materialDel(data).then(res => {
							this.$message.success("删除成功")
							this.getMaterialInfo();
						})
					}
				})
			},
			//编辑海报图
			editPoster(item) {
				this.dialogMaterialVisible = true;
				this.material_id = item.material_id;
				let data = {
					material_id: item.material_id,
					material_type: '1'
				}
				data = this.tools.filterParams(data);
				materialDetails(data).then(res => {
					this.form = res.data;
					this.form.type = this.form.type.toString()

				})
			},
			//添加海报图
			delPoster(item) {
				this.tools.confirm("确定要删除此海报吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							plugin_id: this.plugin_id,
							material_id: item.material_id,
							material_type: '1'
						}
						data = this.tools.filterParams(data);
						materialDel(data).then(res => {
							this.$message.success("删除成功")
							this.getMaterialInfo();
						})
					}
				})
			},
			addMaterial() {
				this.dialogMaterialVisible = true;
				this.form.type = '';
				this.form.sort = '';
				this.form.material_image = '';
			},
			// 选择图片
			uploadImg() {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				this.form.material_image = val[0].resource_name;
			},
			submitForm(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data = {
							plugin_id: this.plugin_id,
							material_image: this.form.material_image,
							sort: this.form.sort,
							material_type: '1',
							type: this.form.type,
							material_id: this.material_id,
						}
						data.material_image = this.tools.filterImgPre(data.material_image);
						data = this.tools.filterParams(data);
						if (this.material_id) {
							data.material_id = this.material_id;
							materialEdit(data).then(res => {
								this.$message.success("编辑成功！")
								this.dialogMaterialVisible = false;
								this.getMaterialInfo();
								this.material_id = ''
							})

						} else {
							materialInsert(data).then(res => {
								this.$message.success("添加成功！")
								this.dialogMaterialVisible = false;
								this.getMaterialInfo()
							})
						}

					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.intro-cont {
		margin-top: 10px;
		overflow: hidden;

		.intro-item {
			float: left;
			margin-right: 10px;
			background: #FFFFFF;
			width: 200px;
			border-radius: 4px 4px;
			border: 1px solid #C5C5C5;

			.intro-img {
				width: 200px;
				height: 356px;
				background-color: #E9EAEF;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.intro-opera {
				height: 50px;
				line-height: 50px;
				overflow: hidden;

				.buttons {
					height: 50px;
					float: right;
					padding-right: 20px;
				}
			}
		}

	}
</style>
