<template>
	<div>
		<div class="wrapper-main pp20">
			<div class="main-cont" style="padding: 0!important;margin-bottom: 20px;">
				<div class="all_search_list" style="margin-bottom: 0;">
					<el-input prefix-icon="el-icon-search" clearable v-model="keyword" style="width: 200px!important;"
						placeholder="营销名称" size="small" type="text" @keyup.enter.native="toSearch" >
					</el-input>
					<el-button style="margin-left: 10px;" type="primary" size="small" @click="toSearch">搜索</el-button>
					<el-button size="small" @click="toReset">重置</el-button>
				</div>
			</div>
			<div class="setTitle" style="margin-bottom: 20px;">店铺促销</div>
			<div class="itemList">
				<ul class="items">
					<li v-for="(item,index) in marketList" :key="index">
						<div class="marketitem">
							<div class="marketflex">
								<div class="iconimg">
									<div class="tjimg">
										<img class="timg" v-if="item.is_recommend ==1"
											src="../../../../../../assets/img/plat/market/market_tj.png" />
									</div>
									<img :src='item.backstage_image' />
								</div>
								<div class="intro">
									<p class="title">{{item.title}}</p>
									<p>{{item.description}}</p>
								</div>
							</div>
							<div class="marketopen" v-has="item.status==true?'上架':'下架'">
								<img style="vertical-align: middle;"
									:src="item.status==true? require('../../../../../../assets/img/ups.png'): require('../../../../../../assets/img/downs.png')"
									@click="changeSwitch(item)" />
								<span style="line-height: 22px; font-size: 12px;">{{item.status==true?'上架':'下架'}}</span>
							</div>
						</div>
						<div class="tosetting" v-has="'设置'" @click="toSet(item)">设置</div>

					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		twitterList,
		twitterUpState
	} from "@/api/plat/market.js"
	export default {
		data() {
			return {
				marketList: [],
				active: '永久',
				keyword: '',
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let data = {
					title: this.keyword
				}
				data = this.tools.filterParams(data);
				twitterList(data).then(res => {
					this.marketList = res.data;
					this.marketList.forEach(item => {
						if (item.status == 1) {
							item.status = true
						} else if (item.status == 2) {
							item.status = false
						}

					})
				})
			},
			changeSwitch(item) {
				this.tools.confirm(item.status == true?"已使用该应用的用户将会收到影响，您确定要禁用该应用吗？":"确认要上架？").then(res => {
						if (res) {
							let data = {
								plugin_id: item.plugin_id,
								status: item.status == true ? '2' : '1'
							}
							twitterUpState(data).then(res => {
								this.getList()
							})
						}
					});
			},
			selected(name) {
				this.active = name;
			},

			toReset() {
				this.keyword = '';
				this.getList()
			},
			toSearch() {
				this.getList()
			},
			toSet(item) {
				this.$router.push({
					path: "/marketingadmin/usecenter/usecenterset",
					query: {
						plugin_id: item.plugin_id,
						plugin_identifying:item.plugin_identifying
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.itemList {
		width: 100%;
	}

	.items {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.items li {
		list-style: none;
		padding: 10px 10px;
		border-radius: 4px;
		min-height: 36px;
		background: #FFFFFF;
		border: 1px solid #DDDDDD;
		width: 332px;
		margin-right: 20px;
		margin-bottom: 30px;

	}

	.marketitem {
		margin-bottom: 10px;
		overflow: hidden;

		.marketflex {
			float: left;
			display: flex;
			align-items: center;
			flex-shrink: 0;

			.iconimg {
				// width: 60px;
				// height: 60px;
				vertical-align: middle;
				position: relative;

				img {
					width: 50px;
					height: 50px;
					vertical-align: middle;
				}
			}

			.tjimg {
				position: absolute;
				top: 5px;
				left: 4px;
				width: 21px;
				height: 19px;

				.timg {
					width: 100%;
					height: 100%;
				}
			}


			.intro {
				font-size: 14px;
				color: #999;
				margin-left: 10px;

				.title {
					font-size: 14px;
					color: #333;


				}
			}
		}

		.marketopen {
			float: right;
		}
	}

	.timeitem {
		display: flex;
		align-items: center;
		flex-shrink: 0;
	}

	.itemflex {
		flex: 1;
		height: 30px;
		background: #FFFFFF;
		//border-radius: 4px 0px 0px 4px;
		border: 1px solid #DDDDDD;
		border-right: none;
		text-align: center;
		font-size: 14px;
		font-weight: 500;
		line-height: 30px;
		color: #888888;
		cursor: pointer;
	}

	.itemflex.active {
		border: 1px solid #2589FF;
	}

	.itemflex:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	.itemflex:last-child {
		border-right: 1px solid #DDDDDD;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.itemflex:last-child.active {
		border: 1px solid #2589FF;
	}

	.marketset {
		overflow: hidden;
		margin-top: 20px;

		.marketsettxt {
			height: 20px;
			font-size: 14px;
			font-weight: 500;
			color: #E02020;
			line-height: 20px;

			span {
				color: #DDDDDD;
			}

			float: left;
		}

		.marketsetbtn {
			height: 20px;
			font-size: 14px;
			font-weight: 500;
			color: #2589FF;
			line-height: 20px;
			float: right;
			cursor: pointer;
		}
	}

	.tosetting {
		height: 20px;
		font-size: 14px;
		font-weight: 500;
		color: #2589FF;
		line-height: 20px;
		text-align: right;
		cursor: pointer;
	}
</style>
