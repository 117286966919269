<template>
	<div>
		<el-dialog title="打标签" :visible.sync="dialogMarkVisible" width="35%">
			<el-row type="flex" class="row-bg" justify="space-between">
				<el-col :span="12">
					<div class="grid-content bg-purple">
						<el-button plain type="primary" size="small" @click="addTag">新增标签</el-button>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content bg-purple">
						<el-input placeholder="请输入标签名称" v-model="search_tag_name" size="small">
							<template slot="append">搜索</template>
						</el-input>
					</div>
				</el-col>
			</el-row>
			<div class="tag_count mt10">
				<ul v-for="(tag,index) in tags" class="mr10 tagbox" @close="handleClose(tag)" :key="index">
					<li class="item" :class="{active : curIndex == index}" @click.stop="selected(tag,index)">{{tag.name}}
						<i class="el-icon-circle-close" @click.stop="closeTag(index)"></i>
					</li>
				</ul>
			</div>

		</el-dialog>
		<!--新增标签-->
		<el-dialog title="新增标签" :visible.sync="dialogAddTagVisible" width="25%">
			<div class="mt10">标签名称：<el-input size="small" v-model="tag_name" class="inputWidth" placeholder="不能超过10个字">
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogAddTagVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddTag" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			dialogMarkVisible: Boolean
		},
		data() {
			return {
				curIndex: 0,
				dialogAddTagVisible: false,
				tag_name: '',
				search_tag_name: '',
				tags: [{
						name: '电器',
					},
					{
						name: '美妆类',
					},
					{
						name: '服装类',
					},
				],
			}
		},
		methods: {
			//添加标签
			addTag() {
				this.dialogAddTagVisible = true
			},
			closeTag(index) {
				this.tags.splice(index, 1);
			},
			selected(tag,indx) {
				this.curIndex = indx;
				this.$emit("tagname", tag.name);
				this.$emit("hideModel", false);
			},
			//确定添加标签
			sureAddTag() {
				if (this.tag_name == '') {
					this.$message.warning("请输入标签名称！")
					return;
				}
				if (this.tag_name.length >= 10) {
					this.$message.warning("不能超过10个字！")
					return;
				}
				let names = [...new Set(this.tags.map(a => a.name))];
				if (names.indexOf(this.tag_name) == -1) {
					this.tags = this.tags.concat({
						name: this.tag_name
					});
					this.dialogAddTagVisible = !this.dialogAddTagVisible;
					this.tag_name = '';
				} else {
					this.$message.warning("该标签已存在");
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.tag_count {
		min-height: 250px;
		background: #FFF;
		border-radius: 4px;
		border: 1px solid #CCC;
	}

	.tagbox {
		width: 100%;

		>li {
			list-style: none;
			float: left;
			width: 190px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #F2F2F6;
			border-radius: 4px;
			margin: 10px 10px;
			position: relative;

			&.active {
				background: #DFF0FF;
			}

			&:hover {
				i {
					cursor: pointer;
					opacity: 1;
					position: absolute;
					right: 10px;
					color: #999;
					top: 14px
				}
			}

			i {
				cursor: pointer;
				opacity: 0;
				color: #999;
				position: absolute;
				right: 10px;
				top: 14px
			}
		}
	}
</style>
