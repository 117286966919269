import axios from '@/config/httpConfig'
import qs from  'qs'
//获取插件列表
export const pluginList=(params)=> {
	  return axios('get','Marketing/shop/pluginList', params)
}
//插件列表
export const TwitterList=(params)=> {
	  return axios('get','Twiker/shop/TwitterList', params)
}
//佣金设置
export const distributionInsertOrUpdate=(data)=> {
    return axios('post','Twiker/shop/distributionInsertOrUpdate', data);
}
//营销介绍图素材
export const introduceList=(params)=> {
	  return axios('get','Twiker/shop/introduceList', params)
}
//佣金详情
export const distributionDetails=(params)=> {
	  return axios('get','Twiker/shop/distributionDetails', params)
}
//营销插件详情
export const TwitterDetails=(params)=> {
	  return axios('get','Twiker/shop/TwitterDetails', params)
}
//立即购买
export const rechargeAmount=(data)=> {
    return axios('post','/Order/shop/rechargeAmount', data);
}
//幸运拼团列表
export const groupBuyingActivityList=(params)=> {
	  return axios('get','GroupBuying/shop/groupBuyingActivityList', params)
}
//删除会场
export const GroupBuyingActivityDel=(params)=> {
	  return axios('get','GroupBuying/shop/GroupBuyingActivityDel', params)
}
//商品分类列表
export const getCategoryList=(params)=> {
	  return axios('get','GroupBuying/shop/getCategoryList', params)
}
//商品列表
export const getGoodsList=(params)=> {
	  return axios('get','GroupBuying/shop/getGoodsList', params)
}
//添加会场
export const addGroupBuyingActivity=(data)=> {
    return axios('post','GroupBuying/shop/addGroupBuyingActivity', data);
}
//编辑会场
export const updateGroupBuyingActivity=(data)=> {
    return axios('post','GroupBuying/shop/updateGroupBuyingActivity', data);
}
//停止会场
export const stopGroupBuyingActivity=(params)=> {
	  return axios('get','GroupBuying/shop/stopGroupBuyingActivity', params)
}
//活动统计数据
export const activityData=(params)=> {
	  return axios('get','GroupBuying/shop/activityData', params)
}
//商品统计数据
export const goodsData=(params)=> {
	  return axios('get','GroupBuying/shop/goodsData', params)
}
//拼团活动信息
export const goodsGroupInfo=(params)=> {
	  return axios('get','GroupBuying/shop/goodsGroupInfo', params)
}
//拼团详情
export const groupBuyingDetail=(params)=> {
	  return axios('get','GroupBuying/shop/groupBuyingDetail', params)
}
//用户信息
export const numberInfo=(params)=> {
	  return axios('get','GroupBuying/shop/numberInfo', params)
}
/**
 * 优惠券
 */
//优惠券列表
export const activityList=(params)=> {
	return axios('get','coupon/shop/activityList', params)
}
//优惠券商品列表
export const getCouponGoodsList=(params)=> {
	return axios('get','coupon/shop/getGoodsList', params)
}
//优惠券适用活动
export const couponPluginList=(params)=> {
	return axios('get','coupon/shop/pluginList', params)
}
//优惠券活动创建、修改
export const couponCreate=(data)=> {
    return axios('post','coupon/shop/couponCreate', data);
}
//优惠券活动详情
export const activityInfo=(params)=> {
	return axios('get','coupon/shop/activityInfo', params)
}
//优惠券活动分析
export const couponActivityInfo=(params)=> {
	return axios('get','coupon/shop/couponActivityInfo', params)
}
//优惠券活动删除
export const activityDel=(data)=> {
    return axios('post','coupon/shop/activityDel', data);
}
//优惠券活动手动停止
export const activityStop=(data)=> {
    return axios('post','coupon/shop/activityStop', data);
}


