<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<div class="signature">
					<el-form ref="form" :model="form" :rules="rules" label-width="150px">
						<el-form-item label="签名:" prop="sign_name">
							<div>
								<el-input type="text" v-model="form.sign_name" size="small" style="width: 400px;" maxlength="12" show-word-limit></el-input>
							</div>

							<div class="next-form-item-control">
								<ul class="ul-form-tips">
									<li>签名内容为短信发送方的真实应用名称、网站名称、公司名称或商标名称</li>
									<li>若涉及到第三方权益必须获得第三方真实授权</li>
									<li>不支持如“客户服务”、“友情提醒”等过于宽泛内容</li>
									<li>通用场景下不支持带“测试”字样的签名，如需测试请选择验证码场景</li>
									<li>若签名／模版内容侵犯到第三方权益必须获得第三方真实授权</li>
									<li>无须添加【】、()、[]符号，签名发送会自带【】符号，避免重复</li>
								</ul>
							</div>
						</el-form-item>
						
						<el-form-item label="签名来源:" >
								<el-radio v-model="form.sign_source" label="1">企事业单位的全称或简称</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="是否涉及第三方权益:">
							<el-radio-group v-model="Sourcenum">
								<el-radio :label="1">是</el-radio>
								<el-radio :label="2">否</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item v-if="Sourcenum == 1" label="上传证明文件:">
							<div style="display: flex;">
								<div class="avatar-uploader2" v-for="img in sign_file_list">
									<img :src="img" class="avatar"/>
								</div>
								<div class="avatar-uploader2" >
									<i class="el-icon-plus avatar-uploader-icon"  @click="businessLicenseCopy('poster')"></i>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="申请说明:" prop="remark" >
							<el-input class="inputWidth" style="width: 400px;" type="textarea" resize="none" :autosize="{ minRows:5, maxRows: 6}" placeholder="请输入内容"
							 v-model="form.remark">
							</el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet('form')">提交</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="false" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import ChooseImg from '../../../../../common/uploadImgs.vue';
	import {
		addSmsSign,
		querySmsSigns,
		modifySmsSign
	} from '@/api/baseSet.js';
	export default {
		components: {
			ChooseImg
		},
		data() {
			return {
					Sourcenum:2,
				form: {
					sign_name:'',
					sign_source: '1',
					oss_resource_id:'',
					remark:''
				},
				sign_file_list:[],//证件
				rules: {
					sign_name: [
							{ required: true, message: '请填写', trigger: 'blur' }
						  ],
					remark: [
							{ required: true, message: '请填写', trigger: 'blur' }
						  ]
				},
				uploadFlag: false,


			}
		},
		mounted() {
			if(this.$route.query.id){
				this.signNameData();
			}
			
		},
		methods: {
			//模板详情
			signNameData(){
				let data={
					sign_name:this.$route.query.name,
					sms_sign_id:this.$route.query.id
				}
				data = this.tools.filterParams(data);
				querySmsSigns(data).then(res=>{
					if (res.return_code == 'SUCCESS') {
						if(res.data.sign_file_list_path.length>0){
							this.Sourcenum = 1;
							this.sign_file_list = res.data.sign_file_list_path;
						}
						this.form = res.data;
						this.form.remark = res.data.reason;
						this.form.sign_source=res.data.sign_source.toString();
						
					}
				})
				
			},
			//证件
			businessLicenseCopy() {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.sign_file_list=[];
				val.forEach(img=>{
					this.form.oss_resource_id.push(img.oss_resource_id);
					this.form.oss_resource_id = this.form.oss_resource_id.toString();
					this.sign_file_list.push(img.resource_name);
				});
				this.uploadFlag = false;
				
			},
			//提交申请
			submitSet() {
				this.$refs['form'].validate((valid) => {
				  if (valid) {
					  if(this.$route.query.id){
						  //调接口
						  let data = this.form;
						 data.sms_sign_id=this.$route.query.id;
						  if(this.Sourcenum == 1){
						  	data.oss_resource_id = data.oss_resource_id
						  }
						  modifySmsSign(data).then(res=>{
						  	if (res.return_code == 'SUCCESS') {
						  		this.$message({
						  			type: 'success',
						  			message: '更新成功!'
						  		});
						  		this.$router.push('/settingadmin/domesticnews/domesticnewlist')
						  	}
						  })
					  }else{
						 //调接口
						 let data = this.form;
						 if(this.Sourcenum == 1){
						 	data.oss_resource_id = data.oss_resource_id
						 }
						 addSmsSign(data).then(res=>{
						 	if (res.return_code == 'SUCCESS') {
						 		this.$message({
						 			type: 'success',
						 			message: '更新成功!'
						 		});
						 		this.$router.push('/settingadmin/domesticnews/domesticnewlist')
						 	}
						 })
					  }
				
				  } else {
					return false;
				  }
				});
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.signature {
		padding: 30px 15px;
		border-radius: 6px;
		// min-height: 300px;
		background-color: #f2f2f6;
		margin-bottom: 20px;

	}

	.next-form-item-control {
		display: inline-block;
	}
	.avatar-uploader2{
		border-radius: 4px 4px;
		border: 1px solid #ddd;
		background-color: #fff;
		width: 148px;
		overflow: hidden;
		height: 148px;
		line-height: 148px;
		margin-right: 10px;
		.el-icon-plus{
			width: 148px;
			text-align: center;
			height: 148px;
			line-height: 148px;
		}
		img{
			width: 100%;
			height: 100%;
		}
	}

	.next-form-item-control .ul-form-tips {
		padding: 0 0 0 20px;
		line-height: 18px;
		font-size: 12px;
		color: #a9b0b4;
		text-align: left;
		margin-top: 8px;
		margin-bottom: 0;
		list-style: disc;
	}
</style>
