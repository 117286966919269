<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="ffj">
					<div class="title">分红奖结算规则</div>
					<div class="jsms">
						<div class="info">
							<span style="margin-left: 100px; font-weight: 700;">结算模式</span>
							<span><template>
									<el-radio v-model="radio" label="1"><span
											style="font-size: 14px;margin-left: 0;">固定时间每日22：30（结算昨日22:30分至今日22:30的分红奖励）</span></el-radio>
									<!-- <el-radio v-model="radio" label="2"><span
											style="font-size: 14px;margin-left: 0;">按月结算（每月1号，结算上月分红）</span></el-radio> -->
								</template></span>

						</div>
					</div>
				</div>

				<!-- <div class="ffj" style="margin-top: 50px;">
					<div class="title">直推奖结算规则</div>
					<div class="jsms">
						<div class="info">
							<span style="margin-left: 100px; font-weight: 700;">结算模式</span>
							<span><template>
									<el-radio v-model="radio2" label="1"><span
											style="font-size: 14px;margin-left: 0;">按周结算（每周一00:00，结算上周分红）</span></el-radio>
									<el-radio v-model="radio2" label="2"><span
											style="font-size: 14px;margin-left: 0;">按月结算（每月1号，结算上月分红）</span></el-radio>
								</template></span>

						</div>
					</div>
				</div> -->
			</div>

		</div>
		<div class="save-cont">
			<!-- <div class="cancel-btn">取消</div> -->
			<div class="save-btn" @click="Submit()">保存</div>
		</div>
	</div>
</template>

<script>
import {
	config,
	getConfig
} from "@/api/merchant/finance.js"
export default {
	data() {
		return {
			radio: '1',
			// radio2: '1',
		}
	},
	mounted() {
		// this.getConfig()
	},
	methods: {
		Submit() {
			// let data = {
			// 	dividend_type: this.radio,
			// 	direct_type: this.radio2,
			// }
			// config(data).then(res => {
			// 	this.$message.success('保存成功')
			// 	// console.log(res.data);
			// })
			this.$message.success('保存成功')
		},
		// getConfig() {
		// 	getConfig({}).then(res => {
		// 		this.radio = res.data.dividend_type+""
		// 		this.radio2 = res.data.direct_type+""
		// 		// console.log(this.radio);
		// 		// console.log(this.radio2);
				
		// 	})
		// }
	},
}
</script>

<style lang="scss" scoped="scoped">
.wrapper-cont {
	font-size: 14px;
	font-weight: 500;
	height: 700px;
	padding: 20px;

	.ffj {
		height: 250px;
		border: 1px solid rgb(141, 130, 130);
		background-color: #fff;

		.title {
			height: 50px;
			line-height: 50px;
			// text-align: center;
			padding-left: 50px;
			font-size: 14px;
			font-weight: 700;
			border-bottom: 1px solid rgb(141, 130, 130);
		}

		.info {
			margin-top: 70px;

			span {
				margin-left: 150px;
			}
		}
	}
}</style>
