import axios from '@/config/httpConfig'
import qs from  'qs'
//获取商品分类
export const cate_list=(data)=> {
    return axios('post','/Category/platform/cate_list', data);
}
//供货商品
export const goods_list=(data)=> {
    return axios('post','Goods/platform/platform_goods_list', data);
}
//供货商品库列表
export const goods_lists=(data)=> {
    return axios('post','Goods/platform/platformForTheGoodsList', data);
}
//商品上下架-admin
export const goods_putaway=(data)=> {
    return axios('post','Goods/platform/platform_goods_putaway', data);
}
//商品详情
export const platform_shop_goods_deatil=(data)=> {
    return axios('post','Goods/platform/platform_shop_goods_deatil', data);
}
//编辑平台商品-admin
export const platform_edit_goods=(data)=> {
    return axios('post','Goods/platform/platform_edit_goods', data);
}
//商品详情
export const platform_goods_deatil=(data)=> {
    return axios('post','Goods/platform/platform_goods_deatil', data);
}
//商品运费模板select列表
export const goods_temp_list=(params)=> {
	  return axios('get','product/platform/goods_temp_list', params)
}
//商品发货地址
export const getAddressList=(params)=> {
	  return axios('get','Address/platform/getAddressList', params)
}
//所有商品分类
export const all_cate=(data)=> {
    return axios('post','/Category/platform/all_cate', data);
}

// 分类排序
export const changeCateSort=(data)=> {
    return axios('post','Category/platform/changeCateSort', data);
}
//批量改价
export const platformBatchChangePrice=(data)=> {
    return axios('post','Goods/platform/platform_batchchange_price', data);
}
//商品SKU详情
export const platform_GoodsSkuInfo=(data)=> {
    return axios('post','Goods/platform/platform_GoodsSkuInfo', data);
}
//修改商品SKU
export const platform_SetGoodsSkuInfo=(data)=> {
    return axios('post','Goods/platform/platform_SetGoodsSkuInfo', data);
}
//批量删除
export const platform_goods_delete=(data)=> {
    return axios('post','Goods/platform/platform_goods_delete', data);
}
//商品审核列表
export const platform_reviewMerchantsGoodsList=(data)=> {
    return axios('post','Goods/platform/platform_reviewMerchantsGoodsList', data);
}
//商品审核
export const platform_goodsAudit=(data)=> {
    return axios('post','Goods/platform/platform_goodsAudit', data);
}
//获取所有分类
export const getAllCate=(data)=> {
    return axios('post','/Category/platform/get_all_cate', data);
}
//删除分类
export const del_list=(data)=> {
    return axios('post','/Category/platform/del_list', data);
}
//添加分类
export const add_cate=(data)=> {
    return axios('post','/Category/platform/add_cate', data);
}
//编辑分类
export const edit_cate=(data)=> {
    return axios('post','/Category/platform/edit_cate', data);
}
//禁用启用分类分类
export const change_cate=(data)=> {
    return axios('post','/Category/platform/change_cate', data);
}
//库宝宝分类
export const ThirdCateList=(data)=> {
    return axios('post','/Category/platform/ThirdCateList', data);
}
//库宝宝分类关联本地分类
export const cateList=(data)=> {
    return axios('post','/Category/platform/cate_list', data);
}
//库宝宝分类关联本地分类
export const relevanceThirdCate=(data)=> {
	return axios('post','/Category/platform/relevanceThirdCate', data);
}
//规格名
export const SpecificationList=(data)=> {
    return axios('post','Goods/platform/platformSpecificationList', data);
}
//创建规格名与规格值
export const createSpecification=(data)=> {
    return axios('post','Goods/platform/platform_createSpecification', data);
}
//删除规格
export const SpecificationDel=(data)=> {
    return axios('post','Goods/platform/platform_SpecificationDel', data);
}
//平台商品审核-admin
export const plateGoodsAudit=(data)=> {
    return axios('post','Goods/platform/plateGoodsAudit', data);
}
//商户-商品上下架-admin
export const platform_shop_goods_putaway=(data)=> {
    return axios('post','Goods/platform/platform_shop_goods_putaway', data);
}
//添加平台商品
export const add_goods=(data)=> {
    return axios('post','Goods/platform/platform_add_goods', data);
}
// //商品品牌
// export const getGoodsBrand=(params)=> {
// 	  return axios('get','Activity/platform/getGoodsBrand', params)
// }
//商品品牌列表
export const all_Brand=(data)=> {
    return axios('post','/Goods/platform/get_all_brand', data);
}
//添加品牌
export const add_brand=(data)=> {
    return axios('post','/Goods/platform/add_brand', data);
}
//编辑品牌信息
export const edit_Brand=(data)=> {
    return axios('post','/Goods/platform/edit_Brand', data);
}
//删除品牌
export const del_list_brand=(data)=> {
    return axios('post','/Goods/platform/del_list', data);
}
//禁用启用
export const change_Brand=(data)=> {
    return axios('post','/Goods/platform/change_Brand', data);
}
//获取品牌名称和ID
export const getGoodsBrand=(data)=> {
    return axios('post','/Goods/platform/getAllAssortment', data);
}
//库宝宝品牌
export const ThirdBrandList=(data)=> {
    return axios('post','/Goods/platform/ThirdBrandList', data);
}
//库宝宝品牌关联本地品牌
export const relevanceThirdBrand=(data)=> {
    return axios('post','/Goods/platform/relevanceThirdBrand', data);
}
//获取商品折扣
export const getCommodityDiscountSettings=(data)=> {
    return axios('post','/Goods/platform/getCommodityDiscountSetting', data);
}
//设置商品折扣
export const commodityDiscountSettings=(data)=> {
    return axios('post','/Goods/platform/commodityDiscountSettings', data);
}
//添加商品获取验证码
export const commodityVerificationCode=(data)=> {
    return axios('post','Goods/platform/commodityVerificationCode', data);
}
//导出excel表格
export const excelList=(params)=> {
	  return axios('get','/Goods/platform/excelList', params)
}
//平台商品推荐开启关闭-admin
export const plateGoodsRecommendReview=(data)=> {
    return axios('post','Goods/platform/plateGoodsRecommendReview', data);
}
//平台商品审核并添加分类-admin
export const plateGoodsCateAudit=(data)=> {
    return axios('post','Goods/platform/plateGoodsCateAudit', data);
}
//平台商品审核并添加分类-admin
export const plateBulkEditingGoodsCate=(data)=> {
    return axios('post','Goods/platform/plateBulkEditingGoodsCate', data);
}
//商品搜索
export const searchGood=(data)=> {
    return axios('post','/Elasticsearch/shop/search', data);
}

