<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<div class="all_search_list" style="margin-bottom: 0;">
						<div class="demo-input-suffix">
							<el-input prefix-icon="el-icon-search" class="inputWidth" v-model="search.keyword"
								placeholder="品牌名称" size="small" type="text"  @keyup.enter.native="searchOrder" clearable>
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder" style="margin-left: 10px;">查询
							</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
						
					</div>
				</div>
				<div class="tableMain">
					<el-table :data="tableData" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" style="width: 100%" min-height="250" border  max-height="550px" >
						<el-table-column prop="brand_name" label="品牌名称" align="center">
						</el-table-column>
						<el-table-column label="品牌LOGO" align="center">
							<template slot-scope="scope">
								<el-popover placement="right" title width="200" trigger="hover">
									<div style="width:200px;height:200px;">
									  <img
										style="width:100%;height:100%;object-fit: cover;"
										:src="scope.row.brand_logo"
										alt
									  />
									</div>
									<div style="width:50px; height:50px;margin: 0 auto;" slot="reference">
									 <img v-if="scope.row.brand_logo" :src="scope.row.brand_logo" style="width: 100%; height: 100%;"/>
									</div>
								  </el-popover>
								<!-- <img :src="scope.row.brand_logo" style="width:3.125rem; height: 3.125rem;" /> -->
							</template>
						</el-table-column>
						<el-table-column prop="locality_brand_name" label="关联品牌名称" align="center">
						</el-table-column>
						<el-table-column label="操作" width="350" align="center">
							<template slot-scope="scope">
								<el-button @click="toOpera(scope.row,'relation')" type="text" size="small" v-has="'关联'" >关联</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background class="pagecurrent" hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
		<!-- 分页 -->
		
		<!--关联商品-->
		<el-dialog title="平台品牌" :visible.sync="goodLibraryModel" width="469px">
			<el-select v-model="brand_id" placeholder="请选择">
				<el-option v-for="item in getAllAssortment" :key="item.goods_brand_id" :label="item.brand_name" :value="item.goods_brand_id">
				</el-option>
			</el-select>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelModel" size="small">取 消</el-button>
				<el-button type="primary" @click="submitRelevance" size="small">确认</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ThirdBrandList,
		 getGoodsBrand,
		 relevanceThirdBrand
	} from '@/api/plat/goods'
	import ChooseImg from "../../../../../common/uploadImgs.vue";
	export default {
		components: {
			ChooseImg,
		},
		data() {
			return {
				loading:true,
				search: {
					keyword: '',
				},
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				goodLibraryModel: false,
				options: [],
				brand_id: '',
				goodBrandModel: false,
				uploadType: '',
				goods_brand_id:'',
				getAllAssortment:[]
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let data = {
					keyword: this.search.keyword,
					page: this.currentPage,
					perPage: this.pagesize,
				}
				data = this.tools.filterParams(data);
				ThirdBrandList(data).then(res => {
					 this.total = res.data.count;
					 this.tableData = res.data.list;
					 this.loading=false
				})
			},
			
			//获取品牌名称和ID
			getAllAssortments(){
				getGoodsBrand({}).then(res=>{
					this.getAllAssortment=res.data
				})
			},
			
			// //搜索
			searchOrder() {
				this.getList()
			},
			toReset() {
				this.search.keyword = '';
				this.getList()
			},
			// //分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			
			cancelModel() {
				this.goodLibraryModel = !this.goodLibraryModel;
			},
			
			//关联弹框
			toOpera(row,type) {
				if(type=='relation'){
					console.log(row,"6666")
					//关联
					this.goods_brand_id=row.goods_brand_circumscribed_id;
					this.brand_id = '';
					this.goodLibraryModel = !this.goodLibraryModel
					this.getAllAssortments()
				}
			},
			submitRelevance(){
				let data={
					type:'1',
					goods_brand_circumscribed_id:this.goods_brand_id,
					locality_brand_id:this.brand_id
				}
				relevanceThirdBrand(data).then(res=>{
					this.$message.success("关联成功！");
					this.goodLibraryModel = !this.goodLibraryModel;
					this.getList();
				})
			},
			
		}
	}
</script>

<style>
</style>
