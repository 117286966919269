<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont" style="padding-bottom: 100px;">

				<div class="main-cont bgwt">
					<div style="padding: 10px 0 30px 0;" v-if="type == 'banner'">
						<i class="el-icon-picture-outline" style="margin-right: 10px;"></i>
						<span class="title">banner</span>
					</div>
					<div style="padding: 10px 0 30px 0;" v-if="type == 'diamond'">
						<i class="el-icon-picture-outline" style="margin-right: 10px;"></i>
						<span class="title">金刚区</span>
					</div>
					<div style="padding: 10px 0 30px 0;" v-if="type == 'adver'">
						<i class="el-icon-picture-outline" style="margin-right: 10px;"></i>
						<span class="title">广告位</span>
					</div>



					<el-form :model="form" ref="form" :rules="rules" label-width="150px" class="demo-ruleForm">

						<el-form-item label="类型" v-if="type == 'adver'">
							<el-select size="small" v-model="form.type" allow-create filterable>
								<el-option v-for="(items, index) in ggData" :key="index" :label="items.name" :value="items.ggid">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="标题:" prop="title">
							<el-input size="small" placeholder="输入标题名称" style="width: 450px!important;" clearable v-model="form.title"
								maxlength="60" show-word-limit></el-input>
						</el-form-item>

						<el-form-item label="图片:" prop="image">
							<div class="img_blos">
								<div v-for="(item, index) in form.image" :key="index" class="img_blo" style="margin-bottom:15px">
									<i class="el-icon-error close_img" @click="delImg(index, 'goods')"></i>
									<img :src="item" class="item_img" @click="handlePictureCardPreview(item)" />
								</div>
								<div class="plusImg" @click="uploadImg('goods')">
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
							<div v-if="type == 'banner'" style="color: #999; font-size: 12px;">建议尺寸：686*370像素</div>
							<div v-if="type == 'diamond'" style="color: #999; font-size: 12px;">建议尺寸：110*110像素</div>
							<div v-if="type == 'adver'" style="color: #999; font-size: 12px;">建议尺寸：一张尺寸686*260像素,两张尺寸332*260像素</div>
						</el-form-item>

						<el-form-item label="跳转类型" prop="bind_type">
							<el-select size="small" v-model="form.bind_type" allow-create filterable @change="tabs">
								<el-option v-for="(items, index) in recommendData" :key="index" :label="items.name"
									:value="items.goods_recommend_id">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="商品分类" prop="bind_data" v-if="form.bind_type == '2'">
							<el-select size="small" v-model="form.bind_data" allow-create filterable>
								<el-option v-for="(items, index) in shopListData" :key="index" :label="items.name"
									:value="items.id">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="图片" prop="bind_data" v-if="form.bind_type == '4'">
							<div class="img_blos">
								<div class="plusImg" @click="uploadImg('bind_data')" v-if="form.bind_data == ''">
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
								<div class="img_blo" style="margin-bottom:15px" v-else>
									<i class="el-icon-error close_img" @click="delImg(index, 'bind_data')"></i>
									<img :src="form.bind_data" class="item_img" @click="handlePictureCardPreview(form.bind_data)" />
								</div>
							</div>
							<div v-if="type == 'banner'" style="color: #999; font-size: 12px;">建议尺寸：750*自定义像素</div>
						</el-form-item>

						<el-form-item label="页面" prop="bind_data" v-if="form.bind_type == '5'">
							<el-input size="small" placeholder="输入跳转页面地址" style="width: 450px!important;" clearable
								v-model="form.bind_data"></el-input>
							<div v-if="type == 'banner'" style="color: #999; font-size: 12px;">例如：/page/int/int</div>
						</el-form-item>

						<el-form-item label="商品详情" prop="bind_data" v-if="form.bind_type == '1'">
							<el-select size="small" v-model="form.bind_data" allow-create filterable>
								<el-option v-for="(items, index) in list" :key="index" :label="items.goods_name"
									:value="items.goods_unified_id">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="图片二跳转类型" prop="bind_type_a" v-if="form.type == 2">
							<el-select size="small" v-model="form.bind_type_a" allow-create filterable @change="tabs2">
								<el-option v-for="(items, index) in recommendData" :key="index" :label="items.name"
									:value="items.goods_recommend_id">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="商品分类" prop="bind_data" v-if="form.bind_type_a == '2'&& form.type==2">
							<el-select size="small" v-model="form.bind_data_a" allow-create filterable>
								<el-option v-for="(items, index) in shopListData" :key="index" :label="items.name"
									:value="items.id">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="图片" prop="bind_data" v-if="form.bind_type_a == '4'&& form.type==2">
							<div class="img_blos">
								<div class="plusImg" @click="uploadImg('bind_data_a')" v-if="form.bind_data_a == ''">
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
								<div class="img_blo" style="margin-bottom:15px" v-else>
									<i class="el-icon-error close_img" @click="delImg(index, 'bind_data_a')"></i>
									<img :src="form.bind_data_a" class="item_img" @click="handlePictureCardPreview(form.bind_data_a)" />
								</div>
							</div>
							<div v-if="type == 'banner'" style="color: #999; font-size: 12px;">建议尺寸：750*自定义像素</div>
						</el-form-item>

						<el-form-item label="页面" prop="bind_data" v-if="form.bind_type_a == '5'&& form.type==2">
							<el-input size="small" placeholder="输入跳转页面地址" style="width: 450px!important;" clearable
								v-model="form.bind_data_a"></el-input>
							<div v-if="type == 'banner'" style="color: #999; font-size: 12px;">例如：/page/int/int</div>
						</el-form-item>

						<el-form-item label="商品详情" prop="bind_data" v-if="form.bind_type_a == '1'&& form.type==2">
							<el-select size="small" v-model="form.bind_data_a" allow-create filterable>
								<el-option v-for="(items, index) in list" :key="index" :label="items.goods_name"
									:value="items.goods_unified_id">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="排序:" prop="sort">
							<el-input size="small" placeholder="输入排序值" style="width: 450px!important;" clearable
								v-model="form.sort"></el-input>
						</el-form-item>

					</el-form>

				</div>

				<!-- <div class="save-cont" v-if="goodtype == 'detail'">
					<div class="cancel-btn" @click="goback">返回</div>
				</div> -->
				<div class="save-cont">
					<div class="cancel-btn" @click="goback">返回</div>
					<div class="save-btn" @click="addSubmit('form')">保存</div>
				</div>


				<!-- 选择图片弹窗 -->
				<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
				<!--图片放大-->
				<el-dialog :visible.sync="dialogImgVisible">
					<img width="100%" :src="dialogImageUrl" alt="">
				</el-dialog>

			</div>
		</div>
	</div>
</template>

<script>
import ChooseImg from '../../../../../common/uploadImgs.vue';
import {
	// platform_goods_deatil, //商品详情
	// all_cate, //所有商品分类
	// SpecificationList, //规格值
	// createSpecification,
	// SpecificationDel,
	// add_goods,
	// goods_temp_list,
	// goods_deatil,
	// edit_goods,
	// getAddressList,
	// whetherTheMerchantBuysPlugin,
	get_all_Brand,
	goods_list,
	savePageConfig,
	getPageConfigInfo,
	getAllCate
} from "@/api/merchant/good.js"
export default {
	components: {
		ChooseImg
	},
	data() {
		return {
			// loading:true,
			type: this.$route.query.type,
			page_id: this.$route.query.page_id,
			dialogImgVisible: false,
			dialogImageUrl: '',
			uploadFlag: false, //是否展示选择图片弹窗
			isSingle: true, //上传图片是单张还是多张
			form: {
				plate: this.$route.query.type == 'banner' ? '1' : this.$route.query.type == 'diamond' ? '2' : '3',
				title: '',
				image: [],
				type: '',
				bind_type: '',
				bind_type_a: '',
				bind_data: '',
				bind_data_a: '',
				sort: ''
			},
			recommendData: [
				{
					name: '商品',
					goods_recommend_id: '1'
				},
				{
					name: '分类',
					goods_recommend_id: '2'
				},
				{
					name: '图片',
					goods_recommend_id: '4'
				},
				{
					name: '页面',
					goods_recommend_id: '5'
				},
				{
					name: '不跳转',
					goods_recommend_id: '3'
				}
			],
			ggData: [
				{
					name: '一行一个',
					ggid: '1'
				},
				{
					name: '一行两个',
					ggid: '2'
				}
			],
			rules: {
				goods_recommend_id: [
					{ required: true, message: '请选择分类', trigger: 'change' }
				],
				title: [
					{ required: true, message: '请填写商品名称', trigger: 'blur' }
				],
				image: [
					{ type: 'array', required: true, message: '请上传商品图', trigger: 'change' }
				],
				bind_type: [
					{ required: true, message: '请选择跳转类型', trigger: 'change' }
				],
				bind_type_a: [
					{ required: true, message: '请选择跳转类型', trigger: 'change' }
				],
				bind_data: [
					{ required: true, message: '请选择商品详情', trigger: 'change' }
				],
				bind_data_a: [
					{ required: true, message: '请选择商品详情', trigger: 'change' }
				],
				sort: [
					{ required: true, message: '请填写排序', trigger: 'blur' }
				],
			},
			shopListData: [],	//商品分类
			list: [],	//商品
		}
	},
	mounted() {
		this.getRecommend();//获取商品分类
		this.goods_list();
		if (this.page_id) {
			this.getPageConfigInfo();	//详情
		}
	},
	methods: {
		getPageConfigInfo() {
			getPageConfigInfo({ page_id: this.page_id }).then(res => {
				this.form.type = JSON.stringify(res.data.type);
				this.form.title = res.data.title;
				this.form.image = JSON.parse(res.data.image);
			
				
				// console.log(res.data.bind_type);

				this.form.bind_type = JSON.stringify(res.data.bind_type);
				this.form.bind_type_a =  JSON.stringify(res.data.bind_type_a)
				this.form.bind_data = res.data.bind_type == 2 ? res.data.bind_data && JSON.parse(res.data.bind_data) : res.data.bind_data;
				// console.log(1213213213);
				this.form.bind_data_a = res.data.bind_type == 2 ? res.data.bind_data_a && JSON.parse(res.data.bind_data_a) : res.data.bind_data_a;
				
				// this.form.bind_data = JSON.stringify(res.data.bind_data) 
				// this.form.bind_data_a =JSON.stringify(res.data.bind_data_a)
				this.form.sort = res.data.sort;
				// console.log(	this.form.sort, res.data.sort);
				
			})
		},
		tabs() {
			this.form.bind_data = '';
		},
		tabs2() {
			this.form.bind_data_a = '';
		},
		goods_list() {
			goods_list({
				page: 1,
				perPage: 10000,
				is_publish: '1',
				is_verify: '1',
				goods_source: '2'
			}).then(res => {
				this.list = res.data.list;
			})
		},
		//获取商品分类
		getRecommend() {
			getAllCate({}).then(res => {
				this.shopListData = res.data.list;
			})
		},
		// 选择图片
		uploadImg(type, parIndex, index) {
			// if (type == "regus" || type == "banners") {
			// 	this.reguParentIndex = parIndex;
			// 	this.reguIndex = index;
			// 	this.isSingle = true;
			// } else {
			// 	this.isSingle = false;
			// }

			if (this.type == 'banner' && type == 'bind_data') {
				this.form.bind_data = [];
			}
			if (this.type == 'banner' && type == 'bind_data_a') {
				this.form.bind_data_a = [];
			}

			if (this.type == 'banner' || this.type == 'diamond' || this.form.type == 1) {
				if (this.type == 'banner' && this.form.bind_type != 4) {
					// console.log(111111111);
					if (this.form.image && this.form.image.length > 0 && type!='bind_data_a' && type!='bind_data') {
						this.$message.warning("当前区域只能上传一张图片");
						return;
					}
				} else {
					// console.log(222222222);
					if (this.form.image && this.form.image.length > 0 && type!='bind_data_a' && type!='bind_data') {
						this.$message.warning("当前区域只能上传一张图片");
						return;
					}
				}
			} else {
				// console.log(333333333);
				if (this.form.image && this.form.image.length > 1 && type!='bind_data_a' && type!='bind_data') {
					this.$message.warning("当前区域只能上传两张图片");
					return;
				}
			}

			this.uploadType = type;
			this.uploadFlag = !this.uploadFlag;
		},
		// 点击取消
		chooseCancel(val) {
			this.uploadFlag = val;
		},
		// 点击确定
		chooseOk(val) {
			let type = this.uploadType;
			this.uploadFlag = false;
			if (type == "banners") {
				this.form.extend_pic = val[0].resource_name;
			} else if (type == "goods") {
				val.forEach((res) => {
					this.form.image = this.form.image.concat([res.resource_name]);
				});
			} else if (type == "regus") {
				let vals = [...this.reguVal];
				vals[this.reguParentIndex].regus[this.reguIndex].sku_image = val[0].resource_name;
				this.reguVal = [...vals];
			} else if (type == "details") {
				let imgs = this.form.detailImgs || [];
				val.forEach((res) => {
					imgs = imgs.concat([res.resource_name]);
				});
				this.form.detailImgs = imgs;
			} else if (type == 'bind_data') {
				this.form.bind_data = val[0].resource_name;
			} else if (type == 'bind_data_a') {
				this.form.bind_data_a = val[0].resource_name;
			}
		},
		//删除图片
		delImg(index, type) {
			if (type == 'goods') {
				this.form.image.splice(index, 1);
			}
			if (type == 'details') {
				this.form.detailImgs.splice(index, 1);
			}
			if (type == 'bind_data') {
				this.form.bind_data = '';
			}
			if (type == 'bind_data_a') {
				this.form.bind_data_a = '';
			}
		},
		handlePictureCardPreview(file) {
			// console.log("11111111=============",file);
			
			this.dialogImageUrl = file;
			this.dialogImgVisible = true;
		},

		goback() {
			this.$router.push({
				path: '/pageConfig/pageConfiguration/pageConfigurationList',
				query: {
					activeName: this.activeName
				}
			})
		},


		addSubmit(form) {	//提交

			if(this.form.type==2&&this.form.image.length==1){
				this.$message.error("请选择两张图片！")
				return
			}
			if(this.form.type==1&&this.form.image.length <1){
				this.$message.error("请选择一张图片！")
				return
			}
			if(this.form.type==1&&this.form.image.length >1){
				this.$message.error("只能选择一张图片！")
				return
			}
			if(this.form.type==1){
			this.form.bind_data_a=''
			this.form.bind_type_a=''
			}
			this.$refs[form].validate((valid) => {
				if (valid) {
					//调接口
					let forms = JSON.parse(JSON.stringify(this.form));
					if (this.page_id) {
						forms.page_id = this.page_id;
					}
					// console.log(this.form.image);
					forms.image = JSON.stringify(this.form.image);
					forms = this.tools.filterParams(forms);
					
					savePageConfig(forms).then(() => {
						// 返回上一页
						if(this.page_id){
							this.$message.success("修改成功！");
						}else{
							this.$message.success("添加成功！");

						}
						// this.$router.push({
						// 	path: '/merchandiseadmin/selfsupportmerchandise/selfsupportlist',
						// 	query: {
						// 		activeName: this.activeName
						// 	}
						// })
					})

				} else {
					return false;
				}
			});
		}

	}
}
</script>

<style lang="scss" scoped="scoped">
.title {
	font-size: 16px;
}
</style>
