<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="order-search" style="margin-top: 0;">
					<el-row class="all_search_list">
						<div class="demo-input-suffix">
							<el-input prefix-icon="el-icon-search" v-model="search.search" placeholder="客户名称/联系电话"
								size="small" type="text" @keyup.enter.native="toSearch" clearable>
							</el-input>
						</div>

						<div class="demo-input-suffix">
							<el-select style="width: 120px;" size="small" placeholder="机会来源"
								v-model="search.customer_source">
								<el-option label="全部" value=""></el-option>
								<el-option label="平台创建" value="1"></el-option>
								<el-option label="网上注册" value="2"></el-option>
								<el-option label="服务商创建" value="3"></el-option>
							</el-select>
						</div>
						<!-- <div class="demo-input-suffix">
							<el-select style="width: 120px;" size="small" placeholder="是否跟进"
								v-model="search.is_follow_up">
								<el-option label="全部" value=""></el-option>
								<el-option label="不跟进" value="1"></el-option>
								<el-option label="跟进" value="2"></el-option>
							</el-select>
						</div> -->
						<div class="demo-input-suffix">
							<el-select style="width: 120px;" size="small" placeholder="客户行业"
								v-model="search.marketing_trade_id" clearable>
								<el-option v-for="(item,index) in tradeList" :key="index" :label="item.trade_name"
									:value="item.marketing_trade_id"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-select style="width: 120px;" size="small" placeholder="客户状态" v-model="search.is_urgent">
								<el-option label="全部" value=""></el-option>
								<el-option label="不加急" value="1"></el-option>
								<el-option label="加急" value="2"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix" style="font-size: 13px;">
							<div class="block">
								<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
									range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
									@change="changetimer"></el-date-picker>
							</div>
						</div>
						<el-button type="primary" size="small" @click="toSearch"  >查询</el-button>
						<el-button size="small" @click="toReset">重置</el-button>
					</el-row>
					<el-row class="all_search_list mt10" style="margin-bottom:0">
						<el-button type="primary" size="small" @click="toAdd()" v-has="'创建机会'" >创建机会</el-button>
						<el-button type="primary" size="small" @click="toAllocation()" v-has="'分配机会'" >分配机会</el-button>
					</el-row>
				</div>
				<!--表格-->
				<div class="tableMain">
					<!-- <div class="tableTile">客户列表</div> -->
					<el-table :data="tableData" style="width: 100%" min-height="250" border :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
						@selection-change="handleSelectionChange" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="selection" width="55" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="创建时间" align="center">
						</el-table-column>
						<el-table-column prop="name" label="网店类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_entity == 1">线上门店</span>
								<span v-else>实体门店</span>
							</template>
						</el-table-column>
						<el-table-column prop="marketing_trade_info.trade_name" label="行业类目" align="center">
						</el-table-column>
						<el-table-column prop="address" label="地址信息" align="center">
						</el-table-column>
						<el-table-column prop="username" label="客户名称" align="center">
						</el-table-column>
						<el-table-column prop="phone" label="客户联系电话" align="center">
						</el-table-column>
						<el-table-column prop="follow_remark" label="跟进内容" align="center">
						</el-table-column>
						<el-table-column prop="customer_source" label="来源类别" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.customer_source == 1">平台创建</span>
								<span v-else-if="scope.row.customer_source == 2">网上注册</span>
								<span v-else>服务商创建</span>
							</template>
						</el-table-column>
						<el-table-column fixed="left" label="操作" width="180" align="center">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toDetail(scope.row)" v-has="'详情'" >详情</el-button>
								<el-button type="text" size="small" @click="toEduit(scope.row)">
									<span style="margin-right: 5px;"   v-has="'编辑'">编辑</span>
									<el-tag type="danger" size="small" v-if="scope.row.is_urgent == 2" v-has="'加急'" >加急</el-tag>
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<!-- 分页 -->
		
		<!--分配机会弹框-->
		<el-dialog title="分配机会" :visible.sync="allocationModel" width="35%">
			<div class="mb20">
				<el-input placeholder="手机号/姓名" class="inputWidth" size="small" clearable v-model="allocationwords">
					<template slot="append"><span style="cursor: pointer;"
							@click="searchAllocation">搜索</span></template>
				</el-input>
			</div>
			<el-table :data="allocationData" border style="width: 100%" max-height="250"  >
				<el-table-column fixed prop="username" label="服务商名称" width="150" align="center">
				</el-table-column>
				<el-table-column prop="account" label="账号" sortable width="120" align="center">
				</el-table-column>
				<el-table-column prop="channel_info.channel_name" label="等级" sortable width="120" align="center">
				</el-table-column>
				<el-table-column prop="identification_code" label="标识码" width="120" align="center">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="120" align="center">
					<template slot-scope="scope">
						<el-button @click="sureAllocation(scope.row)" type="text" size="small">
							分配
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagecurrent">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizeChange1" hide-on-single-page  @current-change="handleCurrentChange1"
						:current-page="currentPage1" :page-sizes="[10,20,30,50]" :page-size="pagesize1"
						layout="total, sizes, prev, pager, next, jumper" :total="total1">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getMarketingTradeList
	} from '@/api/plat/market.js'
	import {
		BusinessIncubatorList,
		platformGetChannelList,
		allotBusinessIncubator
	} from '@/api/plat/business'
	export default {
		data() {
			return {
				loading:true,
				search: {
					is_allot: 1,
					search: '',
					customer_source: '',
					is_follow_up: '',
					begin_time: '',
					end_time: '',
					marketing_trade_id: '',
					is_urgent: ''
				},
				due_dt: '',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				allocationwords: '',
				allocationModel: false,
				allocationData: [],
				total1: 0,
				currentPage1: 1, //初始页
				pagesize1: 10, //每页的数据
				tradeList: [], //行业分类
				multipleSelection: []
			}
		},
		mounted() {
			this.getTradeList(); //行业分类
			this.getList()
		},
		methods: {
			//行业类目
			getTradeList() {
				getMarketingTradeList({}).then(res => {
					this.tradeList = res.data
				})
			},
			//获取列表
			getList() {
				let data = this.search;
				data.page=this.currentPage;
				data.perPage=this.pagesize;
				data = this.tools.filterParams(data)
				BusinessIncubatorList(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list;
					this.tableData.forEach(item => {
						item.address = (item.shop_province == null ? "" : item.shop_province) + (item
							.shop_city == null ? "" : item.shop_city) + (item.shop_county == null ?
							"" : item.shop_county) + (item.shop_address_detail == null ? "" : item
							.shop_address_detail)
					})

				})
			},
			//搜索
			toSearch() {
				this.currentPage=1;
				this.getList()
			},

			//重置
			toReset() {
				this.currentPage=1;
				this.search.search = '';
				this.address = [];
				this.search.customer_source = '';
				this.search.is_follow_up = '';
				this.due_dt = [];
				this.search.begin_time = '';
				this.search.end_time = '';
				this.search.marketing_trade_id = '';
				this.search.is_urgent = '';
				//调接口
				this.getList()
			},
			//时间搜索
			changetimer(val) { //时间
				this.search.begin_time = val[0] / 1000;
				this.search.end_time = val[1] / 1000;
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//新增
			toAdd() {
				this.$router.push({
					path: "/clientadmin/todistributed/businessadd",
				});
			},
			//详情
			toDetail(row) {
				this.$router.push({
					path: "/clientadmin/todistributed/customerdetails",
					query: {
						id: row.business_incubator_id
					}
				});
			},
			//编辑
			toEduit(row) {
				this.$router.push({
					path: "/clientadmin/todistributed/businessadd",
					query: {
						id: row.business_incubator_id
					}
				});
			},
			handleSelectionChange(val) {
				let ids = []
				val.forEach(item => {
					ids.push(item.business_incubator_id)
				});
				this.multipleSelection = ids
			},
			//分配机会
			toAllocation() {
				if (this.multipleSelection.length <= 0) {
					this.$message.error("请选择客户");
					return;
				}
				this.allocationModel = !this.allocationModel;
				this.getGetChannelList()
			},
			//获取分配列表
			getGetChannelList() {
				let data = {
					search: this.allocationwords,
					page: this.currentPage1,
					perPage: this.pagesize1
				}
				data = this.tools.filterParams(data);
				platformGetChannelList(data).then(res => {
					this.total1 = res.data.count;
					this.allocationData = res.data.list
				})
			},
			//分配机会分页
			handleSizeChange1(size) {
				this.pagesize1 = size;
				// 掉接口
				this.getGetChannelList()
			},
			handleCurrentChange1(currentPage) {
				this.currentPage1 = currentPage;
				// 掉接口
				this.getGetChannelList()
			},
			//搜索机会
			searchAllocation() {
				this.getGetChannelList()
			},
			//分配操作
			sureAllocation(row) {
				let data = {
					business_incubator_id: this.multipleSelection.toString(),
					account_channel_id: row.account_channel_id
				}
				data = this.tools.filterParams(data);
				allotBusinessIncubator(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.allocationModel = !this.allocationModel;
						this.$message.success("分配成功！")
						this.getList()
					}

				})

			},

		}
	}
</script>

<style lang="scss" scoped>
</style>
