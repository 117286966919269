<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>基础设置</span>
                    </div>
                    <el-form :model="ruleForm"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="结算模式">
                            <el-radio-group v-model="ruleForm.plat_commission_period">
                                <el-radio label="1">按周结算<span style="font-size:14px;color:#999">（每周一00:00生成结算单）</span></el-radio>
                                <el-radio label="2">按月结算（每月15日结算）</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                </el-card>
            </div>
        </div>
                <div class="save-cont">
            <div class="save-btn" @click="oneSubmit()">保存</div>
        </div>
    </div>
</template>
<script>
import { platConfigDetail,editPlatConfigPeriod} from '@/api/plat/partner'
export default {
    data() {
        return {
            ruleForm:{
                plat_commission_period:'1'
            }
        }
    },
      mounted() { 
            this.getInfo();
        
    },
    methods:{
        getInfo(){
            platConfigDetail({}).then(res=>{
                this.ruleForm.plat_commission_period=res.data.plat_commission_period.toString()

            })
        },
         oneSubmit() {
            let data={
                plat_commission_period:this.ruleForm.plat_commission_period
            }
            editPlatConfigPeriod(data).then(()=>{
                  this.$message({
                        message: '修改完成',
                        type: 'success'
                      });
                this.getInfo();
            })

         }
    }
   
}
</script>
<style lang="less" scoped>
</style>