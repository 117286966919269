<template>
    <div>
        <div class="wrapper-main pds">
            <div class="wrapper-cont">
                <div class="order-state">
                    <ul>
                        <li v-for="(tim,index) in operation_time" :key="index">
                            <div class="statestep">
                                <span class="circleNum" :class="tim.time?' ':'active'">{{index+1}}</span>
                                <span class="statetext">{{tim.name}}</span>
                                <span class="stateLine" :class="tim.time?' ':'active'"
                                    :style="index===operation_time.length-1?' display: none;':'display: block'"></span>
                            </div>
                            <div class="statetime">{{tim.time}}</div>
                        </li>
                    </ul>
                </div>
                <el-card shadow="never">
                    <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <div class="or-state"><span></span>订单状态</div>
                                <el-form-item label="订单号：">{{ruleForm.order_number}}</el-form-item>
                                <el-form-item label="收货人：" v-if="ruleForm.get_account_user_info">
                                    {{ruleForm.get_account_user_info.nickname}}</el-form-item>
                                <el-form-item label="联系方式：" v-if="ruleForm.get_account_user_info">
                                    {{ruleForm.get_account_user_info.mobile}}</el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <div class="or-state"><span></span>付款信息</div>
                                <el-form-item label="应付："> {{ruleForm.total_money}}</el-form-item>
                                <el-form-item label="支付流水号：">{{ruleForm.pay_order_number}}</el-form-item>
                                <el-form-item label="备注:">
                                    <div>{{ruleForm.seller_remark}}</div>
                                    <div>
                                        <el-button size="small" type="primary" @click="addDesc" plain>
                                            {{ruleForm.seller_remark?'修改备注':'添加备注'}}</el-button>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
                <el-card shadow="never" class="mt10">
                    <div slot="header" class="clearfix">
                        <span class="txtstyles">{{ruleForm.created_at}} 订单号：{{ruleForm.order_number}}</span>
                    </div>
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column prop="goods" label="商品信息" width="380px">
                            <template slot-scope="scope">
                                <div style="display: flex">
                                    <div style="margin:0 20px">
                                        <img :src="scope.row.master_map" style="width: 70px;height: 70px" />
                                    </div>
                                    <div class="namegood">{{ scope.row.goods_name }}</div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sku_desc" label="商品属性" align="center"></el-table-column>
                        <el-table-column prop="goods_price" label="商品单价" align="center"></el-table-column>
                        <el-table-column  label="支付方式" align="center">
                            <template slot-scope="scope">
								<span v-if="scope.row.payment_type==0">未调起</span>
								<span v-else-if="scope.row.payment_type==1">微信</span>
								<span v-else-if="scope.row.payment_type==2">支付宝</span>
								<span v-else-if="scope.row.payment_type==3">余额</span>
								<span v-else-if="scope.row.payment_type==4">组合-微信</span>
								<span v-else-if="scope.row.payment_type==5">组合-支付宝</span>
							</template>
                        </el-table-column>
                        <el-table-column prop="goods_count" label="购买数量" align="center">
                        </el-table-column>
                        <el-table-column prop="states" label="订单状态" align="center">
                            <template slot-scope="scope">
                                <div>
                                    <p v-if="scope.row.status === 1">
                                        <!-- 待付款 -->
                                        <span>待付款 </span>
                                    </p>
                                    <p v-else-if="scope.row.status === 2">
                                        <!-- <span>待发货 </span> -->
                                        <span>已付款，待配送</span>
                                    </p>
                                    <p v-else-if="scope.row.status === 3">
                                        <span>待收货 </span>
                                    </p>
                                    <p v-else-if="scope.row.status === 4">
                                        <span>已完成 </span>
                                    </p>
                                    <p v-else-if="scope.row.status === 5">
                                        <span>已关闭 </span>

                                    </p>
                                    <p v-else-if="scope.row.status === 6">
                                        <span>已退款 </span>
                                    </p>
                                    <p v-else-if="scope.row.status === 8">
                                        <span>已收货 </span>
                                    </p>
                                    <p v-else>
                                        <span>已取消 </span>
                                    </p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goods_actual_payment" label="付款金额" align="center">
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>
        </div>
        <!--添加备注弹框-->
        <el-dialog title="添加备注" :visible.sync="addDescModel" width="25%">
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="descInfo">
            </el-input>
            <div class="submitBtn">
                <el-button size="small" @click="addDescModel=false">取消</el-button>
                <el-button size="small" type="primary" @click="onSubmit">提交</el-button>
            </div>
        </el-dialog>
    </div>

</template>
<script>
import {
    orderInfo
} from '@/api/merchant/order.js'
import {
    saveRemark
} from '@/api/plat/order.js'
export default {
    data() {
        return {
            order_number: this.$route.query.order_number,
            addDescModel: false,
            descInfo: '', //备注信息
            operation_time: [],
            ruleForm: {},
            tableData: []
        }
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        getInfo() {
            let data = {
                order_number: this.order_number
            }
            orderInfo(data).then(res => {
                this.operation_time = res.data.progress;
                this.ruleForm = res.data;
                this.tableData=[res.data.order_goods_info];
                this.tableData.forEach(item=>{
                    item.payment_type= this.ruleForm.payment_type;

                })

            })
        },
        //添加备注
        //添加备注
        addDesc() {
            this.descInfo = '';
            this.addDescModel = !this.addDescModel
        },
        //提交新增备注
        onSubmit() {
            if (!this.descInfo) {
                this.$message.error("请输入备注信息！")
            } else {
                //调接口
                let data = {
                    order_number: this.order_number,
                    type: '2',
                    remark: this.descInfo
                }
                saveRemark(data).then(res => {
                    if (res.return_code === 'SUCCESS') {
                        this.addDescModel = !this.addDescModel
                        this.getInfo();
                    }
                })

            }
        },
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/plat/allorderdetail.scss";
</style>