<template>
	<div>
		<div class="wrapper-main">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="微信支付" name="first">
					<div class="wrapper-cont">
						<el-form ref="wxform" :model="wxform" :rules="wxrules" label-width="250px" size="small"
							class="demo-ruleForm">
							<el-form-item label="小程序Appid:" prop="appid">
								<el-input type="text" placeholder="请输入小程序Appid" v-model="wxform.appid"
									class="inputWidth"></el-input>
							</el-form-item>
							<el-form-item label="小程序appSecret:" prop="appsecret">
								<el-input type="password" show-password placeholder="请输入小程序appSecret" v-model="wxform.appsecret"
									class="inputWidth">
								</el-input>
							</el-form-item>
							<el-form-item label="微信支付商户号:" prop="mch_id">
								<el-input placeholder="请输入微信支付商户号" v-model="wxform.mch_id" class="inputWidth">
								</el-input>
							</el-form-item>
							
							<el-form-item label="微信支付Api密钥:" prop="key">
								<el-input type="password" show-password placeholder="请输入微信支付Api密钥" v-model="wxform.key"
									class="inputWidth">
								</el-input>
							</el-form-item>
							<el-form-item label="微信证书apiclient_cert.pem:" prop="apiclient_cert">
								<el-input type="textarea" :rows="8" placeholder="请输入微信证书apiclient_cert.pem" style="width: 600px;" v-model="wxform.apiclient_cert"></el-input>
							</el-form-item>
							<el-form-item label="微信证书apiclient_key.pem:" prop="apiclient_key">
								<el-input type="textarea" :rows="8"  placeholder="请输入微信证书apiclient_key.pem" style="width: 600px;" v-model="wxform.apiclient_key"></el-input>
							</el-form-item>
							<el-form-item label="开放平台appid:" prop="authorization_appid">
								<el-input type="password" show-password placeholder="请输入开放平台appid" v-model="wxform.authorization_appid"
									class="inputWidth">
								</el-input>
							</el-form-item>
							<el-form-item label="开放平台密钥:" prop="authorization_appsecret">
								<el-input type="password" show-password placeholder="请输入开放平台密钥" v-model="wxform.authorization_appsecret"
									class="inputWidth">
								</el-input>
							</el-form-item>
						</el-form>
					</div>
				</el-tab-pane>
				<!-- <el-tab-pane label="支付宝支付" name="second">
					<div class="wrapper-cont">
						<el-form ref="zfbform" :model="zfbform" :rules="zfbrules" label-width="250px" size="small"
							class="demo-ruleForm">
							<el-form-item label="Appid:" prop="appid">
								<el-input type="text" placeholder="请输入Appid" v-model="zfbform.appid" class="inputWidth">
								</el-input>
							</el-form-item>
							<el-form-item label="私钥:" prop="rsa_private_key">
								<el-input type="textarea" :rows="8" placeholder="请输入私钥" style="width: 600px;" v-model="zfbform.rsa_private_key"></el-input>
							</el-form-item>
							<el-form-item label="公钥:" prop="alipayrsa_public_key">
								<el-input type="textarea" :rows="8" placeholder="请输入公钥" style="width: 600px;" v-model="zfbform.alipayrsa_public_key"></el-input>
							</el-form-item>
							<el-form-item label="app_code:" prop="app_code">
								<el-input type="password" show-password placeholder="请输入公钥" v-model="zfbform.app_code"
									style="width: 600px;">
								</el-input>
							</el-form-item>
						</el-form>
					</div>
				</el-tab-pane> -->
			</el-tabs>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet(activeName=='first'?'wxform':'zfbform')">保存</div>
		</div>
	</div>
</template>

<script>
	import {setWxConfig,getWxConfig,getALiConfig,setALiConfig} from '@/api/plat/setting.js';
	export default {
		data() {
			return {
				activeName: 'first',
				wxform: {
					appid: '',
					appsecret: '',
					mch_id: '',
					key: '',
					apiclient_cert: '',
					apiclient_key: '',
					authorization_appid:'',
					authorization_appsecret:''
				},
				wxrules: {
					appid: [{
						required: true,
						message: '请输入小程序Appid',
						trigger: 'blur'
					}, ],
					appsecret: [{
						required: true,
						message: '请输入小程序appSecret',
						trigger: 'blur'
					}, ],
					mch_id: [{
						required: true,
						message: '请输入微信支付商户号',
						trigger: 'blur'
					}, ],
					notify_url: [{
						required: true,
						message: '请输入微信回调路径',
						trigger: 'blur'
					}, ],
					key: [{
						required: true,
						message: '请输入微信支付Api密钥',
						trigger: 'blur'
					}, ],
					apiclient_cert: [{
						required: true,
						message: '请输入微信证书',
						trigger: 'blur'
					}, ],
					apiclient_key: [{
						required: true,
						message: '请输入微信证书',
						trigger: 'blur'
					}, ]
				},
				zfbform: {
					appid: '',
					rsa_private_key: '',
					alipayrsa_public_key: '',
					app_code:''
				},
				zfbrules: {
					appid: [{
						required: true,
						message: '请输入Appid',
						trigger: 'blur'
					}, ],
					rsa_private_key: [{
						required: true,
						message: '请输入私钥',
						trigger: 'blur'
					}, ],
					alipayrsa_public_key: [{
						required: true,
						message: '请输入公钥',
						trigger: 'blur'
					}, ],
					app_code: [{
						required: true,
						message: '请输入app_code',
						trigger: 'blur'
					}, ]
				},
			}
		},
		mounted() {
			this.getInfo();
		},
		methods: {
			handleClick(tab) {
				if(tab.name=='first'){
					this.getInfo();
				}else if(tab.name=='second'){
					this.getALiInfo()
				}
			},
			//获取微信设置信息
			getInfo(){
				getWxConfig({}).then(res=>{
					this.wxform=res.data
				})
			},
			//获取ali信息
			getALiInfo(){
				getALiConfig({}).then(res=>{
					this.zfbform=res.data
				})
			},
			submitSet(form) {
				if (form == 'wxform') {
					this.$refs.wxform.validate((valid) => {
						if (valid) {
							let data=JSON.parse(JSON.stringify(this.wxform)); 
							data=this.tools.filterParams(data);
							setWxConfig(data).then(res=>{
								this.getInfo()
							})

						} else {
							return false;
						}
					});
				} else {
					this.$refs.zfbform.validate((valid) => {
						if (valid) {
							let data=JSON.parse(JSON.stringify(this.zfbform));
							data=this.tools.filterParams(data);
							setALiConfig(data).then(res=>{
								this.getALiInfo()
							})
						} else {
							return false;
						}
					});
				}
			},
			
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.wrapper-main::v-deep.el-tabs__nav-wrap::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background-color: #fff;
		z-index: 1;
	}

	.wrapper-main::v-deep.el-tabs__item.is-active {
		background-color: #2589FF;
		color: #fff;
	}

	.wrapper-main::v-deep.el-tabs__item {
		padding: 0 20px;
		height: 34px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		line-height: 34px;
		display: inline-block;
		list-style: none;
		font-size: 14px;
		font-weight: 500;
		margin-right: 40px;
		text-align: center;
		color: #303133;
		position: relative;
		border-radius: 4px;
	}

	.wrapper-main::v-deep.el-tabs__item.is-top:nth-child(2) {
		padding-left: 20px;
	}

	.wrapper-main::v-deep.el-tabs--top .el-tabs__item.is-top:last-child {
		padding-right: 20px;
	}

	.wrapper-main::v-deep.el-tabs__active-bar {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 2px;
		background-color: transparent;
		z-index: 1;
	}

	.wrapper-main::v-deep.el-tabs__nav {
		margin-left: 20px;
	}
</style>
