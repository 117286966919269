<template>
	<div style="border-radius: 10px; padding: 10px; background-color: #fff;">
	<div style="border-radius: 10px; padding: 5px; background-color: #F2F2F6;">
		<div class="wrapper-main" style="margin-bottom: 30px;background-color: #F2F2F6; ">
			<div class="model-content">
				<div class="specialmain">
					<div class="store-container">
						<span>实际分配给各个等级的分红为格格等级实际分红的</span><el-input v-model="bonus_percent" placeholder=""
          style="width: 120px; margin-left: 10px;margin-top: -5px;" onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template slot="append">%</template></el-input><span>，放入奖池中。</span>
					</div>
					<!-- <div class="store-container" style="margin-top: 20px;">
						<span>复用昨日沉淀金额比例</span><el-input v-model="reuse_percent" placeholder=""
          style="width: 120px; margin-left: 10px;margin-top: -5px;" onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template slot="append">%</template></el-input><span></span></span>
					</div> -->
				</div>
			</div>
		</div>
	</div>
		<div>
			<div class="save-cont">
				<div class="save-btn" @click="Submit">提交</div>
			</div>
			<!-- <div class="save-cont"   v-if="storage_type == 2" >
				<div class="save-btn" @click="submitFormal('ruleFormal')">提交</div>
			</div> -->
		</div>
	</div>

</template>
<script>
	import {
	config,
	getConfig
} from "@/api/merchant/finance.js"
	export default {
		data() {
			return {
				bonus_percent:null,
				reuse_percent:null
			}
		},
		mounted() {
		this.getConfig()
		},
		methods: {
			Submit() {
			let data = {
				bonus_percent: this.bonus_percent,
				// reuse_percent: this.reuse_percent
			}
			config(data).then(res => {
				this.$message.success('保存成功')
				// console.log(res.data);
			})
		},
		getConfig() {
			getConfig({}).then(res => {
				this.bonus_percent = res.data.bonus_percent
				// this.reuse_percent = res.data.reuse_percent
			})
		}
		}
	}
</script>

<style lang="scss" scoped>
	.store-container {
		padding-bottom: 60px;
		font-size: 14px;
		font-weight: 500;
		background-color: #F2F2F6;
		height: 0px;
		box-sizing: border-box;
		padding-top: 25px;
		padding-left: 20px;
		border-radius: 10px;
		// line-height: 40px;
	}
	.specialmain {
		background: #fff;
		border-radius: 4px;
		padding: 20px 40px;
		font-size: 14px;
		height: 650px;
		}
</style>
