<template>
    <div>
        <el-form ref="threeFormRef" :model="formData" label-width="150px" class="demo-ruleForm" status-icon>
            <div class="wrapper-cont pd20">
                <div class="line-title mgb20">等级三</div>
                <el-form-item label="等级名称">
                    <el-input v-model="formData.level_name" size="small" class="inputWidth" maxlength="10"
                        show-word-limit />
                </el-form-item>
                <el-form-item label="门槛，">
                    <span class="spanstyle mr5">A/B市场的A/B市场各自产生</span>
                    <el-input v-model="formData.upgrade_number_limit"
                        oninput="value=value.replace(/[^\d]/g,'').replace(/^0{1,}/g, '')" size="small" class="w150"
                        style="vertical-align:middle;">
                        <template slot="append">个</template>
                    </el-input><span class="spanstyle ml5">服务中心，即可升级</span>
                </el-form-item>
            </div>
            <div class="wrapper-cont pd20 mt10">
                <div class="line-title mgb20">升级条件权益</div>
                <el-form-item label="市场奖获得">
                    <el-input v-model="formData.deep_percent" :min="0" :max="100"
                        oninput="value=value.replace(/[^\d]/g,'');if(value>100)value='';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
                        size="small" class="w150 mr10" style="vertical-align:middle;">
                        <template slot="append">%</template>
                    </el-input><span class="spanstyle ml5">奖励；</span><span
                        class="redstyle">解释：享受C部门以及C部门之后下面AB部门引发的AB部门业绩 “X%”收益。</span>
                </el-form-item>
                <el-form-item label="培育奖获得">
                    <el-input v-model="formData.keep_percent" :min="0" :max="100"
                        oninput="value=value.replace(/[^\d]/g,'');if(value>100)value='';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
                        size="small" class="w150 mr10" style="vertical-align:middle;">
                        <template slot="append">%</template>
                    </el-input><span class="spanstyle ml5">奖励；</span><span
                        class="redstyle">解释：享受所有直推人收入的“X%”培育奖励，（收入只计算被直推人的推荐奖和团队奖）</span>
                </el-form-item>
                <el-form-item label="团队奖获得">
                    <el-input v-model="formData.level_team_percent" :min="0" :max="100"
                        oninput="value=value.replace(/[^\d]/g,'');if(value>100)value='';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
                        size="small" class="w150 mr10" style="vertical-align:middle;">
                        <template slot="append">%</template>
                    </el-input><span class="spanstyle ml5">整个团队业绩奖励</span>
                </el-form-item>
                <el-form-item label="福利奖">
                    <el-input v-model="formData.re_deep_percent" :min="0" :max="100"
                        oninput="value=value.replace(/[^\d]/g,'');if(value>100)value='';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
                        size="small" class="w150 mr10" style="vertical-align:middle;">
                        <template slot="append">%</template>
                    </el-input><span class="spanstyle ml5">奖励；</span><span
                        class="redstyle">解释：享受C部门以及C部门之后下面AB部门引发的AB市场在小程序和APP内消费的现金部分的“X%”奖励</span>
                </el-form-item>
                <el-form-item label="整个团队">
                    <span class="spanstyle mr5">业绩奖励平均分红，分到</span>
                    <el-input v-model="formData.level_plat_commission_max"
                        oninput="value=value.replace(/[^0-9.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
                        size="small" class="w150" style="vertical-align:middle;">
                        <template slot="append">元</template>
                    </el-input><span class="spanstyle ml5">截止</span>
                </el-form-item>
            </div>
        </el-form>
        <div class="save-cont">
            <div class="cancel-btn" @click="goback">取消</div>
            <div class="save-btn" @click="threeSubmit()">保存</div>
        </div>
    </div>
</template>
<script>
import { editCommissionConfig } from '@/api/plat/platprofit'//分佣配置详情
export default {
    props: ['formData', 'curIndex'],
    data() {
        return {
            threeFormRef: null,
        }
    },
    methods: {
        goback() {
            this.$emit('toback', true)
        },
        threeSubmit() {
            let data = JSON.parse(JSON.stringify(this.formData))
            editCommissionConfig(data).then(() => {
                this.$message({
                    message: '等级三设置成功',
                    type: 'success'
                });

            })
        }


    }
}
</script>
<style lang="scss" scoped="scoped">
.redstyle {
    font-size: 14px;
    color: #FE527F;
    line-height: 22px;
}
</style>