<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<div class="signature">
					<div class="search-cont " style="padding: 20px 20px;">
						<el-input prefix-icon="el-icon-search" class="inputWidth" v-model="phone"  @keyup.enter.native="searchList" clearable  placeholder="请输入手机号查询" size="small" type="text">
						</el-input>
						<el-button style="margin-left: 10px;" type="primary" size="small"  @click="searchList">搜索</el-button>
						<el-button size="small" @click="toReset">重置</el-button>
					</div>
					<div class="search-cont" style="margin-top:10px">
						<el-table border :data="tableData" style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
							<el-table-column prop="phone" label="手机号码" align="center" >
							</el-table-column>
							<el-table-column prop="created_at" label="发送时间" align="center">
							</el-table-column>
							<el-table-column prop="template_code" label="模板类型"  align="center">
							</el-table-column>
							<el-table-column  align="center" show-overflow-tooltip prop="template_content" label="短信内容">
							</el-table-column>
						</el-table>
						<!-- 分页 -->
						<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		smsLogList
	} from '@/api/plat/setting.js'
	export default {
		data() {
			return {
				loading:true,
				total: 0,
				phone:'',
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				tableData:[]
			}
		},
		mounted() {
			this.getsmsLogList()
		},
		methods:{
			getsmsLogList(){
				let data={
					phone:this.phone,
					page: this.currentPage,
					pagesize: this.pagesize,
				}
				data = this.tools.filterParams(data);
				smsLogList(data).then(res=>{
						this.loading=false;
						this.total =res.data.count ;
						this.tableData = res.data.list
				})
			},
			searchList(){
				this.currentPage = 1;
				this.getsmsLogList()
			},
			toReset(){
				this.currentPage = 1;
				this.phone='';
				this.getsmsLogList()
			},
			handleSizeChange(size) {
				this.pagesize = size;
				this.getsmsLogList();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getsmsLogList();
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.signature {
		padding: 15px;
		border-radius: 6px;
		// min-height: 300px;
		background-color: #f2f2f6;
		margin-bottom: 20px;

	}

	.search-cont {
		border-radius: 8px;
		background-color: #fff;
		padding: 10px 50px 10px 20px;
		display: flex;
		flex-direction: row;
		// justify-content: space-between;
	}
</style>

