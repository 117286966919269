<template>
	<div>
		<div class="wrapper-cont">
			<div class="main-cont bgwt">
				<el-tabs v-model="activeNames" @tab-click="handleClicks">
					<el-tab-pane label="已通过" name="2"></el-tab-pane>
					<el-tab-pane label="待审核" name="1"></el-tab-pane>
				</el-tabs>
			</div>
		</div>

		<el-alert class="mt10" title="视频号只限1000名商家" type="warning">
		</el-alert>

		<div class="wrapper-cont mt10">
			<div class="order-search" style="margin-top: 0;margin-bottom: 0;">
				<el-row class="all_search_list" style="margin-bottom: 0;">
					<div class="demo-input-suffix">
						<el-input prefix-icon="el-icon-search" @keyup.enter.native="toSearch" clearable v-model="search"
							placeholder="请输入店铺名称" size="small" type="text">
						</el-input>
					</div>
					<el-button type="primary" size="small" @click="toSearch">查询</el-button>
					<el-button size="small" @click="toReset">重置</el-button>
				</el-row>
			</div>
		</div>
		<div class="wrapper-cont mt10">
			<el-table :data="tableData" border style="width: 100%"  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" >
				<el-table-column type="index" width="50" label="序号">
				</el-table-column>
				<el-table-column label="店铺名称" prop="shop_name" align="center">
				</el-table-column>
				<el-table-column label="视频号ID" align="center">
					<template slot-scope="scope">
						{{scope.row.video_id}}
						<el-button  v-if="scope.row.video_id" style="color: #409EFF;" type="text" @click="handleCopyMark(scope.row,'videoId')">复制
						</el-button>
					</template>
				</el-table-column>
				<el-table-column label="店铺标识" align="center">
					<template slot-scope="scope">
						{{scope.row.shop_tag}}
						<el-button  v-if="scope.row.shop_tag" style="color: #409EFF;" type="text" @click="handleCopyMark(scope.row,'shopTag')">复制
						</el-button>
					</template>
				</el-table-column>
				<el-table-column label="微信号"  align="center">
					<template slot-scope="scope">
						{{scope.row.wechat_id}}
						<el-button v-if="scope.row.wechat_id" style="color: #409EFF;" type="text" @click="handleCopyMark(scope.row,'wechatId')">复制
						</el-button>
					</template>
				</el-table-column>
				<el-table-column label="提交时间" prop="created_at" align="center">
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button v-if="activeNames=='1'" style="padding: 5px 0;" type="text" size="small"
							@click="toOpera(scope.row,'yes')"  v-has="'通过'">通过</el-button>
						<el-button v-if="activeNames=='1'" style="padding: 5px 0;" type="text" size="small"
							@click="toOpera(scope.row,'no')"  v-has="'拒绝'">拒绝</el-button>
						<el-button v-if="activeNames=='2'" style="padding: 5px 0;" type="text" size="small"
							@click="toOpera(scope.row,'del')" v-has="'删除'">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
				<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
		</div>
		<!--拒绝原因弹框-->
		<el-dialog title="拒绝原因" :visible.sync="dialogVisible" width="25%">
			<div>
				<el-input type="textarea" placeholder="请输入内容" v-model="desc_txt" rows="4" maxlength="50"
					show-word-limit>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="refuseCommit" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		videoShopList,
		videoShopDel,
		videoShopAudit
	} from "@/api/plat/videonum.js";
	export default {
		data() {
			return {
				loading:true,
				search: '',
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				tableData: [],
				activeNames: '2',
				dialogVisible: false,
				desc_txt: '',
				status: '',
				wechat_video_id: ''
			}
		},
		mounted() {
			//this.getList()
		},
		methods: {
			getList() {
				let data = {
					status: this.activeNames,
					shop_name: this.search,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				videoShopList(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
				})
			},
			handleClicks(tab) {
				this.$nextTick(()=>{
					this.activeNames=tab.name;
					this.getList()
				})
				
				
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//搜索
			toSearch() {
				this.currentPage = 1;
				this.getList()
			},
			//重置
			toReset() {
				this.currentPage = 1;
				this.search = '';
				this.getList()
			},
			//删除
			toOpera(row, type) {
				if (type == 'del') {
					this.tools.confirm("您好,删除后商家数据将不可恢复,为了避免造成不必要的损失,请您再次仔细查看商家信息再进行删除哦?").then(res => {
						if (res) {
							//调接口
							videoShopDel({
								wechat_video_id: row.wechat_video_id
							}).then(res => {
								this.$message.success("删除成功！")
								this.getList();
							})

						}
					})
				}
				if (type == 'yes') {
					this.wechat_video_id = row.wechat_video_id;
					this.status = '2';
					this.toAudit()
				}
				if (type == 'no') {
					// this.dialogVisible = true;
					this.desc_txt = '';
					this.status = '3';
					this.wechat_video_id = row.wechat_video_id;
					this.toAudit()
				}
			},
			//拒绝原因提交
			refuseCommit() {
				this.toAudit()
			},
			//审核
			toAudit() {
				let data = {
					wechat_video_id: this.wechat_video_id,
					status: this.status,
					refuse_reason: this.desc_txt,
				}
				data = this.tools.filterParams(data);
				videoShopAudit(data).then(res => {
					this.getList();
					if (this.status == '3') {
						this.dialogVisible = false;
					}
				})
			},

			//复制内容
			handleCopyMark(row,type) {
				let _this = this;
				if(type=='videoId'){
					this.$copyText(row.video_id).then(function(e) {
						_this.$message({
							showClose: true,
							message: "复制成功",
							type: "success"
						});
					}, function(e) {
						_this.$message({
							showClose: true,
							message: "复制失败，请手动复制",
							type: "error"
						});
					})
					
				}else if(type=='shopTag'){
					this.$copyText(row.shop_tag).then(function(e) {
						_this.$message({
							showClose: true,
							message: "复制成功",
							type: "success"
						});
					}, function(e) {
						_this.$message({
							showClose: true,
							message: "复制失败，请手动复制",
							type: "error"
						});
					})
					
				}else if(type=='wechatId'){
					this.$copyText(row.wechat_id).then(function(e) {
						_this.$message({
							showClose: true,
							message: "复制成功",
							type: "success"
						});
					}, function(e) {
						_this.$message({
							showClose: true,
							message: "复制失败，请手动复制",
							type: "error"
						});
					})
					
				}
			
			}
	
		}
	}
</script>

<style scoped="scoped" lang="scss">
	::v-deep .el-tabs__item.is-active {
		color: #409EFF;
	}
</style>
