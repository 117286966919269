
<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<el-tabs class="tabstyle" v-model="activeName" type="card" @tab-click="handleClick" v-has>
					<el-tab-pane label="全部订单" name=""></el-tab-pane>
					<el-tab-pane label="待支付" name="1"></el-tab-pane>
					<el-tab-pane label="待发货" name="2"></el-tab-pane>
					<el-tab-pane label="已发货" name="3"></el-tab-pane>
					<el-tab-pane label="已完成" name="5"></el-tab-pane>
					<el-tab-pane label="交易关闭" name="6"></el-tab-pane>
				</el-tabs>
				<div class="order-search">
					<el-row class="all_search_list" style="margin-bottom:0">
						<div class="demo-input-suffix" style="margin-right: 0px;">
							<el-select style="width: 120px;!important" size="small" v-model="search.search_type"
								placeholder="请选择">
								<el-option label="请选择" value=""></el-option>
								<el-option label="订单号" value="1"></el-option>
								<el-option label="姓名" value="2"></el-option>
								<el-option label="手机号" value="3"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-input clearable class="inputWidth" prefix-icon="el-icon-search"
								v-model="search.search_value" placeholder="请输入" size="small" type="text"  @keyup.enter.native="searchOrder" >
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-select class="w150" size="small" v-model="search.order_ganre"
								placeholder="请选择订单类型">
								<el-option label="全部" value=""></el-option>
								<el-option label="普通订单" value="1"></el-option>
								<el-option label="秒杀订单" value="3"></el-option>
								<el-option label="拼团订单" value="4"></el-option>
								<el-option label="视频号订单" value="5"></el-option>
								<el-option label="裂变优惠券" value="6"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
							创建时间：
							<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
								range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
								@change="changetimer"></el-date-picker>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder">查询</el-button>
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
					</el-row>
				</div>
				<div class="tableMain">
					<el-table :header-cell-style="{background:'#f3f8fe',color:'#606060'}" :data="tableData" v-loading="loading"  element-loading-text="拼命加载中..."  element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"
						style="font-size: 12px;width: 100%;" max-height="550px" :default-expand-all="true">
						<div style="overflow: hidden">
							<el-table-column type="expand" width="1" class="rowsdata">
								<template slot-scope="props">
									<table border="1" class="tableStyle">
										<tbody>
											<tr v-for="(item,index) in props.row" :key="index">
												<td style="width:23%;">
													<div style="display: flex; margin: 10px 0px;">
														<div style="margin:auto 20px"  v-if="item.order_goods_info != null">
															<!-- <el-popover placement="right" title width="200" trigger="hover">
																<div style="width:200px;height:200px;">
																  <img
																	style="width:100%;height:100%;object-fit: cover;"
																	:src="item.order_goods_info.master_map"
																	alt
																  />
																</div>
																<div style="width:70px;height:70px;" slot="reference">
																 <img style="width: 100%;height: 100%;"  v-if="item.order_goods_info != null" :src="item.order_goods_info.master_map" class="el_img"/>
																</div>
															  </el-popover> -->
															  <div style="width:70px;height:70px;" >
																 <el-image style="width: 100%;height: 100%;"  :src="item.order_goods_info.master_map" :preview-src-list="[item.order_goods_info.master_map]" class="el_img"/>
																</div>
															<!-- <img  v-if="item.order_goods_info != null" align="middle" :src="item.order_goods_info.master_map"
																style="width: 70px;height: 70px;" /> -->
														</div>
														<div  v-if="item.order_goods_info != null">
															<div class="namegood">
																<span  v-if="item.order_ganre=='3'">整点秒杀</span>
																<span v-if="item.order_ganre=='4'">幸运团购</span>
																<span v-if="item.order_ganre=='5'">视频号</span>
																<span v-if="item.coupon_price">裂变优惠券</span>
																{{item.order_goods_info.goods_name }}
															</div>
															
															<div class="namegood" style="margin-top:5px">子单号：{{item.order_goods_info.order_number }}</div>
															<div class="pricegood" style="margin-top:5px">
																数量：{{item.order_goods_info.goods_count}}
																<span style="margin-left: 50px;">￥{{item.order_goods_info.goods_price }}</span>
															</div>
														</div>
													</div>
												</td>
												<td style="width:9%;text-align: center">
													<!-- <p v-if="item.third_party_type ==1">库宝宝</p>
													<p v-else-if="item.third_party_type ==-1">平台</p>
													<p v-else-if="scope.row.goods_source==3">京东</p>
													<p v-else-if="scope.row.goods_source==4">阿里</p>
													<p v-else-if="scope.row.goods_source==5">天猫</p>
													<p v-else-if="scope.row.goods_source==6">云仓</p>
													<p v-else-if="scope.row.goods_source==7">苏宁</p> -->
													<p >{{props.row.get_shop_info.shop_name}}</p>
												</td>
												<td style="width: 10%;padding-left:15px">
													<p>应付：{{item.total_money}}</p>
													<p>运费：{{item.freight}}</p>
													<p style="color: #F56C6C;" v-if="item.status!=1&&item.status!=6">实付：{{props.row.status}}{{item.actual_payment}}</p>
												</td>
												<td style="width:9%;text-align: center">
													<div>
														<p>{{item.consignee_name}}</p>
														<p>{{item.consignee_phone}}</p>
													</div>
												</td>
												<td style="width:9%;text-align: center">
													<span v-if="item.payment_type==0">未支付</span>
													<span v-else-if="item.payment_type==1">微信</span>
													<span v-else-if="item.payment_type==2">支付宝</span>
													<span v-else-if="item.payment_type==3">余额</span>
													<span v-else-if="item.payment_type==4">微信组合</span>
													<span v-else-if="item.payment_type==5">支付宝组合</span>
												</td>
												<td style="width:9%;text-align: center">
													<span v-if="item.courier_code">快递配送</span>
													<span v-else>/</span>
												</td>
												<td style="width:9%;text-align: center">
													<p v-if="item.status === 1">
														<span>待支付 </span>
													</p>
													<p v-else-if="item.status === 2">
														<span>已支付</span>
													</p>
													<p v-else-if="item.status === 3">
														<span>待收货 </span>
														<br />
														<el-tag style="margin-top: 5px; cursor: pointer;" size="small"
															@click="logisticsQuery(item)" v-has="'物流查询'" >物流查询</el-tag>
													</p>
													<p v-else-if="item.status === 4">
														<span>已收货 </span>
													</p>
													<p v-else-if="item.status === 5">
														<span>已完成 </span>
													</p>
													<p v-else-if="item.status === 6">
														<span>已关闭 </span>
													</p>
												</td>
												<td style="width:9%;text-align: center">备注</td>
												<td style="width:9%;text-align: center">
													<el-button type="text" size="small" @click="orderDetail(item)"  v-has="'查看详情'" >查看详情</el-button>
												</td>
											</tr>
										</tbody>
									</table>
								</template>
							</el-table-column>
						</div>
						<el-table-column label="商品信息" min-width="200"   >
							<template slot-scope="scope" style="padding: 10px 0;"  >
								<div class="aaa">
									<span class="orderTime">{{scope.row[0].created_at}}</span>
									主单号：<span style="color: #1d8ce0">{{ scope.row[0].master_order_number}}
										</span>
								</div>
								
							</template>
							
						</el-table-column>
						<el-table-column label="所属商户" align="center">
						</el-table-column>
						<el-table-column label="付款" align="center">
						</el-table-column>
						<el-table-column label="收货人" align="center">
						</el-table-column>
						<el-table-column label="支付方式" align="center">
						</el-table-column>
						<el-table-column label="配送方式" align="center">
						</el-table-column>
						<el-table-column label="订单状态" align="center">
						</el-table-column>
						<el-table-column label="备注" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"  @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>

		<!--物流详情-->
		<el-dialog title="物流详情" :visible.sync="logisticsDetailModel" width="40%" >
			<div v-if="activities && activities.length>0">
				<div style="font-size: 16px;margin-bottom: 20px;color: #409EFF;">快递名称：{{typename}} 快递单号：{{typenumber}}</div>
				<el-timeline >
				    <el-timeline-item
				      v-for="(activity, index) in activities"
				      :key="index"
				      :timestamp="activity.time">
				      {{activity.status}}
				    </el-timeline-item>
				  </el-timeline>
			</div>
			<div v-else style="height: 100px; display: flex;align-items: center;justify-content: center;">
				暂无快递信息！
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {orderList,orderNumberQuery} from "@/api/plat/order.js"
	export default {
		data() {
			return {
				loading:true,
				activeName: '',
				search: {
					third_party_type:1,
					status:'',
					search_type: '',
					order_ganre:'',
					search_value: '',
					begin_time: '',
					end_time: '',
				},
				due_dt:'',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				logisticsDetailModel:false,
				activities: [],
				typename: '',
				typenumber:'',
			}
		},
		created() {
			if (this.$route.query.name) {
				this.activeName = this.$route.query.name;
				this.search.status = this.$route.query.name;
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList(){
				let params=this.search;
				params.page=this.currentPage;
				params.perPage=this.pagesize;
				params = this.tools.filterParams(params);
				orderList(params).then(res=>{
					this.loading=false;
					this.total=res.data.count;
					this.tableData=res.data.list;
					
				})
			},
			handleClick(val) {
				this.currentPage=1;
				this.search.search_type='';
				this.search.search_value='';
				this.search.order_ganre='';
				this.search.begin_time='';
				this.search.end_time='';
				this.due_dt='';
				this.search.status = val.name;
				this.getList()
			},
			searchOrder() {
				this.currentPage = 1;
				this.getList()
			},
			toReset(){
				this.currentPage = 1;
				this.search.status='';
				this.search.order_ganre='';
				this.search.search_type='';
				this.search.search_value='';
				this.search.begin_time='';
				this.search.end_time='';
				this.due_dt='';
				this.getList()
			},
			//时间选择
			changetimer(val) { //时间
			
				if (val) {
					this.search.begin_time = val[0] / 1000;
					this.search.end_time = val[1] / 1000;
				} else {
					this.search.begin_time = '';
					this.search.end_time = '';
				}
			},
			//订单详情
			orderDetail(row) {
				this.$router.push({
					path: '/orderadmin/supplyorder/orderdetail',
					query:{
						type:'plat-supply',
						id:row.order_number
					}
				})
			},
		
			handleSizeChange(size) {
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
		logisticsQuery(row) {
			this.logisticsDetailModel = !this.logisticsDetailModel;
			let params = {
				order_number: row.order_number
			}
			orderNumberQuery(params).then(res => {
				//物流信息
				if(res.data.list.length>0){
					this.activities = res.data.list;
					this.typename = res.data.typename;
					this.typenumber=res.data.number
				}
				
			})
		}
		
		
		}
	}
</script>

<style lang="scss" scoped="scoped">
	
	@import "@/assets/css/plat/order.scss";
	::v-deep.el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0;
	}
	::v-deep.el-table__expand-icon>.el-icon {
	   display: none;
	}
	::v-deep.el-table .cell {
	     overflow:visible!important; 
	}
	.tableMain ::v-deep.aaa{
		width: 500px;
	}
		 
</style>
