<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-row class="all_search_list" style="margin-bottom: 0;">
						<div class="demo-input-suffix">
							<el-button size="small" @click="addMaterial" v-has="'新建素材'" >新建素材</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="isDisabled('Y')" v-has="'启用'" >启用</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="isDisabled('N')" v-has="'禁用'" >禁用</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-select v-model="search.states" size="small" @change="changeState" class="w120"
								placeholder="全部状态">
								<el-option label="全部状态" value=""></el-option>
								<!-- <el-option label="未发布" value="0"></el-option> -->
								<el-option label="已发布" value="1"></el-option>
								<el-option label="已停止" value="2"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-input @keyup.enter.native="toSearch" clearable :placeholder="search.type == ''?'请选择':search.type ==1 ?'请输入图文名称':'请输入商品名称'" size="small" v-model="search.keyword" class="input-with-select">
							    <el-select class="selectvalinput" v-model="search.type" slot="prepend" placeholder="请选择">
							      <el-option label="请选择" value=""></el-option>
							      <el-option label="图文" value="1"></el-option>
							      <el-option label="商品" value="2"></el-option>
							    </el-select>
							  </el-input>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
						
					</el-row>
				</div>
				<div class="main-cont bgwt mt10">
					<el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" style="width: 100%"
						@selection-change="handleSelectionChange" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="selection" width="55" align="center">
						</el-table-column>
						<el-table-column prop="trade_name" label="所属行业" align="center">
						</el-table-column>
						<el-table-column prop="sender" label="发送人" align="center">
						</el-table-column>
						<el-table-column label="信息" align="center" >
							<template slot-scope="scope">
								<div class="img_cont" >
									<div class="imgs">
										<div style="width:50px;height:50px;">
											<el-image :src="scope.row.images" :preview-src-list="[scope.row.images]"></el-image>
										</div>
									</div>
									<span >{{scope.row.content.substr(0,18) + '...' }}</span>
								</div>
							</template>
						</el-table-column>
						
						<el-table-column prop="push_type" label="推送类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.type=='1'" style="color: #E6A23C;">图文</span>
								<span v-else>商品</span>
							</template>
						</el-table-column>
						<el-table-column prop="state" label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.state=='1'" style="color: #E6A23C;">已发布</span>
								<span v-else-if="scope.row.state=='2'">已停止</span>
								<span v-else>未发布</span>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="创建时间" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center" fixed="right">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toDetail(scope.row)" v-has="'详情'" >详情</el-button>
								<el-button type="text" size="small" @click="toEdit(scope.row)" v-has="'编辑'" >编辑</el-button>
								<el-button type="text" size="small" v-if="scope.row.state=='1'" v-has="'停止'" 
									@click="toStop(scope.row)">停止</el-button>
								<el-button type="text" size="small" v-if="scope.row.state=='2'" v-has="'启用'" 
									@click="toOpen(scope.row)">启用</el-button>
								<el-button type="text" size="small" @click="toDel(scope.row)" v-has="'删除'" >删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getMaterialPageList,
		delMaterial,
		offMaterial,
		onMaterial
	} from '@/api/plat/market.js'
	export default {
		data() {
			return {
				loading:true,
				search: {
					states: '',
					keyword:'',
					type:''
				},
				tableData: [],
				multipleSelection: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let params = {
					state: this.search.states,
					type: this.search.type,
					keyword: this.search.keyword,
					page: this.currentPage,
					perPage: this.pagesize,
				}
				params = this.tools.filterParams(params)
				getMaterialPageList(params).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list;

				})
			},
			//搜索
			toSearch(){
				this.currentPage = 1;
				//调接口
				this.getList()
			},
			//重置
			toReset(){
				this.currentPage = 1;
				this.search.type='';
				this.search.keyword='';
				this.search.states='';
				//调接口
				this.getList()
			},
			changeState(val) {
				//console.log(val,"666")
				this.search.states = val;
				this.getList()
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//多选
			handleSelectionChange(val) {
				let ids = []
				val.forEach(item => {
					ids.push(item.material_id)
				})
				this.multipleSelection = ids;
			},
			//新建素材
			addMaterial() {
				this.$router.push({
					path: '/marketingadmin/materialcircle/materialset'
				})
			},
			//禁用启用
			isDisabled(type) {
				if (this.multipleSelection.length == 0) {
					this.$message.info("请选择素材！")
					return;
				}
				if (type == 'Y') {
					this.tools.confirm("确定要启用？").then(res => {
						if (res) {
							//调接口
							let data = {
								material_id: this.multipleSelection.toString()
							}
							onMaterial(data).then(res => {
								this.getList()
							})
						}
					})
				}
				if (type == 'N') {
					this.tools.confirm("确定要禁用？").then(res => {
						if (res) {
							//调接口
							let data = {
								material_id: this.multipleSelection.toString()
							}
							offMaterial(data).then(res => {
								this.getList()
							})
						}
					})
				}
			},
			//详情
			toDetail(row) {
				this.$router.push({
					path: '/marketingadmin/materialcircle/materialset',
					query: {
						id: row.material_id,
						ishow: 1
					}
				})
			},
			//编辑
			toEdit(row) {
				this.$router.push({
					path: '/marketingadmin/materialcircle/materialset',
					query: {
						id: row.material_id
					}
				})
			},
			//停止
			toStop(row) {
				this.tools.confirm("确定要停止？").then(res => {
					if (res) {
						let data = {
							material_id: row.material_id
						}
						//调接口
						offMaterial(data).then(res => {
							this.getList()

						})
					}
				})
			},
			//停止
			toOpen(row) {
				this.tools.confirm("确定要启用？").then(res => {
					if (res) {
						let data = {
							material_id: row.material_id
						}
						//调接口
						onMaterial(data).then(res => {
							this.getList()

						})
					}
				})
			},
			//删除
			toDel(row) {
				this.tools.confirm("确定要删除？").then(res => {
					if (res) {
						//调接口

						let data = {
							material_id: row.material_id
						}
						//调接口
						delMaterial(data).then(res => {
							this.getList()
						})
					}
				})
			},

		}
	}
</script>

<style lang="scss" scoped="scoped">

	::v-deep.selectvalinput .el-input {
	    width: 130px!important;
	  }
	  ::v-deep.input-with-select .el-input-group__prepend {
	    background-color: #fff;
	  }
	  .img_cont{
		  display: flex; justify-content: flex-start;align-items: center;
		  .imgs{
			  width:50px;height: 50px; 
			  >img{
				width:50px;height: 50px; 
			  }
		  }
		  span{
			  text-align: left;
			  margin-left: 5px;
		  }
	  }
</style>
