<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt" style="padding: 0!important;">
					<el-tabs v-model="activeName" type="card" @tab-click="handleClick" v-has>
						<el-tab-pane label="仓库中" name="3"></el-tab-pane>
						<el-tab-pane label="上架中" name="1"></el-tab-pane>
						<el-tab-pane label="下架中" name="0"></el-tab-pane>
						<el-tab-pane label="已售罄" name="2"></el-tab-pane>
						<el-tab-pane label="审核中" name="4"></el-tab-pane>
						<el-tab-pane label="审核失败" name="5"></el-tab-pane>
					</el-tabs>
				</div>
				<div class="main-cont bgwt mt10  pp20">
					<el-row class="all_search_list" style="margin-bottom: 0; border-bottom: 1px solid #eee;padding-bottom: 20px;">
						<div class="demo-input-suffix">
							<el-select style="width: 9.375rem!important;" size="small" v-model="search.goods_source"
								placeholder="请选择商品来源">
								<!-- <el-option label="库宝宝" value="1"></el-option> -->
								<el-option label="平台" value="2"></el-option>
								<!-- <el-option label="京东" value="3"></el-option>
								<el-option label="阿里" value="4"></el-option>
								<el-option label="天猫" value="5"></el-option>
								<el-option label="云仓" value="6"></el-option>
								<el-option label="苏宁" value="7"></el-option> -->
							</el-select>
						</div>
						<!-- <div class="demo-input-suffix flex"
							v-if="activeName == '1' || activeName == '2' || activeName == '3'"> -->
						<div class="demo-input-suffix flex">
							<el-select v-model="search.one_cat_id" filterable clearable size="small" style="width: 11.25rem;"
								placeholder="请选择一级分类" @change="getCats(1)">
								<el-option v-for="item in oneLevel" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
							<el-select v-model="search.two_cat_id"  filterable clearable size="small"
								style="margin-left:10px;width: 11.25rem;" placeholder="请选择二级分类"
								v-if="twoLevel && twoLevel.length > 0">
								<el-option v-for="item in twoLevel" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-select size="small" v-model="search.keysward" clearable filterable  @clear="toClear" remote @blur="selectBlur" placeholder="请输入关键词"
								:remote-method="remoteMethod" >
								<el-option v-for="(ss,inx) in searchList" :key="inx"
									:value="ss.goods_name">
									<p v-html="ss.highlight"></p>
								</el-option>
							</el-select>
							<!-- <el-autocomplete
								size="small"
								  class="inline-input "
								  style="width: 11.25rem;"
								  v-model="search.keysward"
								  :fetch-suggestions="querySearch"
								  placeholder="请输入内容"
								   @keyup.enter.native="searchOrder"
								  @change="inputSearch"
								  @select="handleSelect"
								></el-autocomplete> -->
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder">查询</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
						<div class="demo-input-suffix" v-if="activeName != '4'">
							<el-button type="warning" size="small" @click="toExcel">导出excel</el-button>
						</div>
						<div class="demo-input-suffix" v-if="activeName == '4'">
							<el-button type="primary" size="small" @click="batchOpera('examine')">批量审核
							</el-button>
						</div>
					</el-row>
					<el-row class="all_search_list" style="margin-bottom: 0;margin-top:20px">
						<div class="demo-input-suffix flex">
							<el-button size="small" v-has="'添加商品'" v-show="activeName == '3'" type="primary"
								@click="addGoods">添加商品
							</el-button>
							<el-button size="small" v-has="'批量改价'" v-show="activeName == '1' ||  activeName == '3'"
								@click="batchOpera('price')">批量改价</el-button>
							<el-button size="small" v-has="'批量删除'"
								v-show="activeName == '1'|| activeName == '2'||  activeName == '3'||  activeName == '5' ||  activeName == '0' "
								@click="batchOpera('del')">批量删除</el-button>
							<el-button size="small" v-has="'批量下架'" v-show="activeName == '2' || activeName == '1'"
								@click="batchOpera('down')">批量下架
							</el-button>
							<el-button size="small" v-has="'批量上架'" v-show="activeName == '3' || activeName == '0'"
								@click="batchOpera('up')">批量上架
							</el-button>
							<el-button size="small" v-has="'批量上架'" v-show="activeName == '3'"
								@click="batchOpera('types')">批量调整分类
							</el-button>
						</div>
					</el-row>
				</div>
				<div class="main-cont mt10">
					<el-table :data="tableData" style="width: 100%" min-height="250" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"   ref="multipleTable"  max-height="550px"  border :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column label="商品名称" width="380" align="left">
							<template slot-scope="scope">
								<div class="el_name">
									<!-- <el-popover placement="right" title width="200" trigger="hover">
										<div style="width:200px;height:200px;">
											<img style="width:100%;height:100%;object-fit: cover;"
												:src="scope.row.goods_main_pic.split(',')[0]" alt />
										</div>
										<div style="width:70px;height:70px;" slot="reference">
											<img :src="scope.row.goods_main_pic.split(',')[0]" class="el_img" :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]"/>
										</div>
									</el-popover> -->
									<div style="width:70px;height:70px;" >
											<el-image  :src="scope.row.goods_main_pic.split(',')[0]" class="el_img" :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]"/>
										</div>
									<div style="width: 78%;margin-left: 10px;">
										<p>{{ scope.row.goods_name }}</p>
										<!-- <p class="mt10">{{ scope.row.count }}</p> -->
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="来源" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.goods_source==1">库宝宝</span>
								<span v-else-if="scope.row.goods_source==2">平台</span>
								<span v-else-if="scope.row.goods_source==3">京东</span>
								<span v-else-if="scope.row.goods_source==4">阿里</span>
								<span v-else-if="scope.row.goods_source==5">天猫</span>
								<span v-else-if="scope.row.goods_source==6">云仓</span>
								<span v-else-if="scope.row.goods_source==7">苏宁</span>
							</template>
						</el-table-column>
						<el-table-column prop="sku_circumscribed_price" label="成本价" align="center">
						</el-table-column>
						<el-table-column prop="sku_unified_price" label="官方统一价" align="center">
						</el-table-column>
						<el-table-column prop="sku_ordinary_store_manager_price" label="普通店长价" align="center">
						</el-table-column>
						<el-table-column prop="sku_premium_store_manager_price" label="高级店长价" align="center">
						</el-table-column>
						<el-table-column prop="sale" label="实际销量" align="center">
						</el-table-column>
						<el-table-column prop="goods_stock" label="库存" align="center">
						</el-table-column>
						<el-table-column prop="goods_stock"
							v-if="activeName =='1' || activeName=='2' || activeName=='3'" label="状态" align="center">
							<template slot-scope="scope">
								<span style="color: #409EFF;" v-if="scope.row.is_publish == 1">上架</span>
								<span style="color: #409EFF;" v-else>下架</span>
							</template>
						</el-table-column>
						<el-table-column label="是否推荐" align="center" v-if="activeName =='1'">
							<template slot-scope="scope">
								 <el-button type="text" size="small" class="el_edits">
									<div v-show="scope.row.whether_recommend == '0'" class="el_state"
										@click="upOrdown(scope.row, 'open')">
										否
										<img src="../../../../../../assets/img/downs.png" style="margin-left: 10px;" />
									</div>
									<div v-show="scope.row.whether_recommend == '1'" class="el_state"
										@click="upOrdown(scope.row, 'close')">
										是<img src="../../../../../../assets/img/ups.png" style="margin-left: 10px;" />
									</div>
								</el-button>
							</template>
						</el-table-column>
						<el-table-column prop="goods_stock" v-if="activeName=='5' || activeName=='4'" label="审核状态"
							align="center">
							<template slot-scope="scope">
								<span style="color: #409EFF;" v-if="scope.row.is_verify == 1">审核通过</span>
								<span style="color: #409EFF;" v-else-if="scope.row.is_verify == 0">未审核</span>
								<span style="color: #409EFF;" v-else>审核不通过</span>
							</template>
						</el-table-column>
						<el-table-column v-if="activeName==5" prop="verify_comment" label="失败原因" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center" width="220" prop="status" fixed="right">
							<template slot-scope="scope">
								<el-button type="text" v-show="scope.row.goods_source!=2" size="small"
									@click="singleOperate(scope.row,'detail')" v-has="'详情'">详情
								</el-button>
								<el-button type="text" v-show="scope.row.goods_source==2" size="small"
									@click="singleOperate(scope.row,'edit')" v-has="'编辑'">编辑
								</el-button>
								<el-button type="text" size="small" v-show="activeName=='5'"
									@click="singleOperate(scope.row,'del')" v-has="'删除'">删除
								</el-button>
								<el-button type="text" size="small" v-show="activeName=='4'"
									@click="singleOperate(scope.row,'shopdown')" v-has="'商户下架'">商户下架
								</el-button>
								<el-button type="text" size="small" v-show="activeName=='4' && scope.row.is_verify == 0"
									@click="repulse(scope.row,1)" v-has="'通过'">通过</el-button>
								<el-button type="text" size="small" v-show="activeName=='4' && scope.row.is_verify == 0"
									@click="repulse(scope.row,2)" v-has="'拒绝'">拒绝</el-button>
								<el-button v-show="activeName =='1' || activeName=='2' || activeName=='3'" type="text"
									size="small" @click="singleOperate(scope.row,'price')" v-has="'改价'">改价
								</el-button>
								<el-button type="text"
									v-show="scope.row.is_publish == 1 && (activeName =='1' || activeName=='2' || activeName=='3')"
									size="small" @click="singleOperate(scope.row,'down')" v-has="'下架'">下架
								</el-button>
								<el-button type="text"
									v-show="scope.row.is_publish == 0 && (activeName =='1' || activeName=='2' || activeName=='3')"
									size="small" @click="singleOperate(scope.row,'up')" v-has="'上架'">上架
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="main-cont mt10  pp20"  v-if="activeName == '4'">
			<el-row>
				<el-col :span="24">
					<div class="grid-content bg-purple">
						<el-row class="all_search_list" style="margin-bottom: 0;">
							<div class="demo-input-suffix">
								<el-checkbox style="margin-top: 0.3125rem;" label="全选" :disabled="tableData.length>0?false:true" :indeterminate="isIndeterminate" v-model="checkAll" @change="selAll()" ></el-checkbox>
							</div>
							<div class="demo-input-suffix">
							<el-select size="small" v-model="search.keysward" clearable filterable remote @blur="selectBlur" @clear="toClear" placeholder="请输入关键词"
								:remote-method="remoteMethod" >
								<el-option v-for="(ss,inx) in searchList" :key="inx"
									:value="ss.goods_name">
									<p v-html="ss.highlight"></p>
								</el-option>
							</el-select>
							<!-- <el-autocomplete
								size="small"
								  class="inline-input "
								  style="width: 11.25rem;"
								  v-model="search.keysward"
								  :fetch-suggestions="querySearch"
								  placeholder="请输入内容"
								   @keyup.enter.native="searchOrder"
								  @change="inputSearch"
								  @select="handleSelect"
								></el-autocomplete> -->
							</div>
							<div class="demo-input-suffix">
								<el-button type="primary" size="small" @click="searchOrder">查询</el-button>
							</div>
							<div class="demo-input-suffix">
								<el-button size="small" @click="toReset">重置</el-button>
							</div>

							<div class="demo-input-suffix">
								<el-button type="primary" size="small" @click="batchOpera('examine')">批量审核
								</el-button>
							</div>
						</el-row>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- 分页 -->
		<div class="pagecurrent">
			<div class="pagination-container">
				<el-pagination background @size-change="handleSizeChange" hide-on-single-page @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<!-- 展示规格弹出层 -->
		<el-dialog title="修改价格/库存" :visible.sync="showRegu" width="720">
			<div class="tables">
				<el-table :data="reguData" border>
					<el-table-column fixed prop="vals1" :label="showForms[0]" v-if="showForms[0]" width="180">
					</el-table-column>
					<el-table-column prop="vals2" :label="showForms[1]" v-if="showForms[1]">
					</el-table-column>
					<el-table-column prop="vals3" :label="showForms[2]" v-if="showForms[2]">
					</el-table-column>
					<el-table-column label="成本价" prop="sku_circumscribed_price">
					</el-table-column>
					<el-table-column label="官方统一价" prop="sku_unified_price">
					</el-table-column>
					<el-table-column label="建议零售价" prop="sku_suggested_selling_price">
					</el-table-column>
					<el-table-column label="普通店长价">
						<template slot-scope="scope">
							<el-input v-model="scope.row.sku_ordinary_store_manager_price" size="small" min="0"
								type="text"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="高级店长价格">
						<template slot-scope="scope">
							<el-input v-model="scope.row.sku_premium_store_manager_price" size="small" min="0"
								type="text"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="库存" prop="sku_stock">
					</el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="reguCancel" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="modifyPrice">确认</el-button>
			</span>
		</el-dialog>
		<!--批量改价-->
		<el-dialog title="批量修改价格" :visible.sync="showBatchModel" width="30%">
			<div>
				<el-form :model="form" ref="form" :rules="rules" label-width="150px" class="demo-ruleForm">
					<el-form-item label="普通店长价" prop="shopPricept">
						<el-input size="small" placeholder="请输入内容"
							oninput="value=value.replace(/[^\d.]/g,'');if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
							style="width: 250px;" v-model="form.shopPricept">
							<template slot="append">倍</template>
						</el-input>
					</el-form-item>
					<el-form-item label="高级店长价" prop="shopPricegj">
						<el-input size="small" placeholder="请输入内容"
							oninput="value=value.replace(/[^\d.]/g,'');if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
							style="width: 250px;" v-model="form.shopPricegj">
							<template slot="append">倍</template>
						</el-input>
					</el-form-item>

				</el-form>
				<div class="pricetip">设置的价格统一，成本价*倍数</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="batchCancel" size="small">取 消</el-button>
				<el-button type="primary" @click="sureBatch('form')" size="small">确认</el-button>
			</span>
		</el-dialog>
		<!--拒绝原因弹框-->
		<el-dialog title="拒绝原因" :visible.sync="dialogVisible" width="25%">
			<div>
				<el-input type="textarea" placeholder="请输入内容" v-model="desc_txt" rows="4" maxlength="50"
					show-word-limit>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="refuseCommit" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!--批量审核弹框-->
		<el-dialog title="商品类目" :visible.sync="examineDialogVisible" width="25%">
			<el-form :model="examineform" ref="examineform" :rules="examinerules" label-width="150px"
				class="demo-ruleForm" style="margin-bottom: 100px;">
				<el-form-item label="商品类目:" prop="goods_category_id" class="demolist">
					<el-cascader size="small" filterable v-model="examineform.goods_category_id" :options="allCate"
						:props="{ expandTrigger: 'hover',value:'id',label:'name',children:'children'}">
					</el-cascader>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="examineDialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="examineCommit('examineform',1)" size="small">审核成功</el-button>
				<el-button type="primary" @click="examineCommit('examineform',2)" size="small">审核失败</el-button>
			</span>
		</el-dialog>
		<!--批量调整分类-->
		<el-dialog title="商品类目" :visible.sync="adjustDialogVisible" width="25%">
			<el-form :model="adjustform" ref="adjustform" :rules="adjustrules" label-width="150px" class="demo-ruleForm"
				style="margin-bottom: 100px;">
				<el-form-item label="商品类目:" prop="goods_category_id" class="demolist">
					<el-cascader size="small" filterable v-model="adjustform.goods_category_id" :options="allCate"
						:props="{ expandTrigger: 'hover',value:'id',label:'name',children:'children'}">
					</el-cascader>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="adjustDialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="adjustCommit('adjustform')" size="small">确定</el-button>
			</span>
		</el-dialog>
		<!--批量拒绝原因弹框-->
		<el-dialog title="拒绝原因" :visible.sync="dialogfailVisible" width="25%">
			<div>
				<el-input type="textarea" placeholder="请输入内容" v-model="verify_comment" rows="4" maxlength="50"
					show-word-limit>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogfailVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="faileCommit" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		all_cate,
		cate_list, //商品分类
		goods_list, //商品列表
		goods_putaway, //商品上下架
		platformBatchChangePrice, //批量改价
		platform_GoodsSkuInfo, //规格详情
		platform_SetGoodsSkuInfo, //规格修改
		platform_goods_delete, //批量删除,
		plateGoodsAudit,
		platform_shop_goods_putaway,
		excelList,
		plateGoodsRecommendReview,
		plateGoodsCateAudit,
		plateBulkEditingGoodsCate,
		searchGood
	} from '@/api/plat/goods';
	import baseURL from "@/config/baseUrl";
	export default {
		data() {
			return {
				loading:true,
				desc_txt: '',
				dialogVisible: false,
				examineDialogVisible: false,
				dialogfailVisible: false,
				adjustDialogVisible: false,
				activeName: '3',
				verify_comment: '',
				search: {
					one_cat_id: '',
					two_cat_id: '',
					keysward: '',
					is_publish: '3',
					goods_source: ''
				},
				tableData: [],
				total: 0,
				currentPage: Number(this.$route.query.currentPage) || 1, //初始页
				pagesize: 10, //每页的数据
				showRegu: false,
				showForms: [],
				reguData: [],
				multipleSelection: [],
				showBatchModel: false,
				form: {
					shopPricept: '',
					shopPricegj: ''
				},
				rules: {
					shopPricept: [{
						required: true,
						message: '请填写普通店长价',
						trigger: 'blur'
					}],
					shopPricegj: [{
						required: true,
						message: '请填写高级店长价',
						trigger: 'blur'
					}]
				},
				oneLevel: [],
				twoLevel: [],
				cateId: "", //判断是否需要清空二级分类id
				goods_unified_id: [], //批量操作id
				original_goods_unified_id: '',
				repulsedata: {},
				parent_id: '0',
				types: '',
				goods_excelIds: [],
				baseUrl: baseURL, //统一地址
				allCate: [],
				examineform: {
					goods_category_id: []
				},
				examinerules: {
					goods_category_id: [{
						type: 'array',
						required: true,
						message: '请选择分类',
						trigger: 'change'
					}]

				},
				adjustform: {
					goods_category_id: []
				},
				adjustrules: {
					goods_category_id: [{
						type: 'array',
						required: true,
						message: '请选择分类',
						trigger: 'change'
					}]

				},
				restaurants: [],
				searchList:[],
				loading: false,
				isIndeterminate:false,//对el-checkbox控制不完整的全选状态
				checkAll:false,//对el-checkbox控制全选状态
			}
		},
		watch: {
			currentPage: {
				handler(newValue, oldValue) {
					let that = this;
					that.currentPage = newValue
					console.log(newValue, oldValue)
					//newValue 改变后的数据
					//oldValue  改变前的数据
				},
				deep: true
			}
		},
		mounted() {
			this.getCats() //商品分类
			// if(localStorage.getItem('searchList')){
			// 	this.searchList=JSON.parse(localStorage.getItem('searchList'))
			// 	this.restaurants = this.loadAll();
			// }

			if (this.$route.query.searchWord) {
				this.search.keysward = this.$route.query.searchWord
			}
			if (this.$route.query.activeName || this.$route.query.currentPage) {
				this.currentPage = Number(this.$route.query.currentPage);
				this.activeName = this.$route.query.activeName;
				this.search.is_publish = this.$route.query.activeName;
				this.getGoodsList() //商品列表
				 // this.restaurants = this.loadAll();
				this.$forceUpdate()
			} else {
				this.getGoodsList() //商品列表
				 // this.restaurants = this.loadAll();
			}
		},
		methods: {
			selectBlur(e) {
				let value = e.target.value;
				this.search.keysward = '';
				if (this.search.keysward == '') {
					this.search.keysward = value;
				}
			},
			// querySearch(queryString, cb) {
			// 	var restaurants = this.restaurants;
			// 	var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			// 	// 调用 callback 返回建议列表的数据
			// 	cb(results);
			//   },
			//   createFilter(queryString) {
			// 	return (restaurant) => {
			// 	  return (restaurant.value);
			// 	};
			//   },
			//   loadAll() {
			// 	return this.searchList;
			//   },
			//   handleSelect(item) {
			// 	console.log(item);
			//   },
		 //  inputSearch(val){
			//   let aa={
			// 	  value:val
			//   }
			//  var flag = this.searchList.find(cur => cur.value == val);		//查找一下传入的val和数组中的val是否相同
			//  	if(!flag){
			//  		this.searchList.unshift(aa)		// 如果没有就直接push
			//  	}
			//   if(this.searchList.length>10){
			// 	 this.searchList.pop()
			//   }
			//  localStorage.setItem('searchList',JSON.stringify(this.searchList));
		 //  },

			remoteMethod(query) {
				if (query !== "") {
				    // this.loading = true;
					let data = {
						index: 'goods',
						type: 'analyze',
						body: query,
						type_id: '-1',
					}
					searchGood(data).then(res => {
						this.searchList=res.data
					});
				}
			},
			//全选按键功能实现
			    selAll() {
			      if(this.$refs.multipleTable.selection.length < this.tableData.length){
			        this.checkAll=true;
			      }else{
			        this.checkAll=false;
			      }
			      this.$refs.multipleTable.toggleAllSelection();
			    },
			    //表格内checkbox触发的全选按钮状态变化
			    handleSelectionChange(val){
			        if(val.length < this.tableData.length && val.length > 0){
			          this.isIndeterminate = true;
			        } else if(val.length == this.tableData.length){
			          this.isIndeterminate = false;
			          this.checkAll = true;
			        } else if(val.length == 0){
			          this.isIndeterminate = false;
			          this.checkAll = false;
			        }
					this.multipleSelection = val;
					let ids = [],
						excelIds = [];
					val.forEach(mul => {
						excelIds.push(mul.original_goods_unified_id)
						ids.push({
							original_goods_unified_id: mul.original_goods_unified_id
						})
					})
					this.goods_unified_id = ids;
					this.goods_excelIds = excelIds
			        
			    },
				// //批量选中操作
				// handleSelectionChange(val) {
					
				
				// },
			
			//获取分类
			getCats(type) {
				if (type == 1) {
					this.search.cate_id = this.search.one_cat_id;
					//清空二级分类
					this.search.two_cat_id && (this.search.two_cat_id = "");
				}
				cate_list({
						parent_id: this.search.cate_id ? this.search.cate_id : '0'
					})
					.then((res) => {
						// console.log(新增商品);
						if (type == 1) {
							this.twoLevel = res.data;
						} else {
							this.oneLevel = res.data;
						}
					})
					
			},
			//获取所有商品分类
			getAllCase() {
				all_cate({}).then(res => {
					this.allCate = res.data
				})
			},
			//获取商品列表
			getGoodsList() {
				let data = {
					keyword: this.search.keysward,
					is_source:'0',
					is_publish: this.search.is_publish,
					goods_source: this.search.goods_source,
					cate_id: this.search.two_cat_id ? this.search.two_cat_id : this.search.one_cat_id,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				goods_list(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
					// this.search.keysward = '';
				})
			},
			handleClick(tab) {
				this.search.two_cat_id = '';
				this.search.one_cat_id = '';
				this.search.goods_source = '';
				this.search.keysward = '';
				this.currentPage = 1;
				this.checkAll=false;
				this.search.is_publish = tab.name;
				this.search.keysward = '';
				this.getGoodsList()
			},
			// 是否推荐
			upOrdown(row, type) {
				if (type == 'open') {
					this.tools.confirm("确认要启用推荐？").then(res => {
						if (res) {
							//调接口
							plateGoodsRecommendReview({
								goods_unified_id: row.original_goods_unified_id,
								whether_recommend: 1
							}).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.getGoodsList() //商品列表
								}
							})
						}
					})
				}
				if (type == 'close') {
					this.tools.confirm("确认要禁用推荐？").then(res => {
						if (res) {
							//调接口
							plateGoodsRecommendReview({
								goods_unified_id: row.original_goods_unified_id,
								whether_recommend: 0
							}).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.getGoodsList() //商品列表
								}
							})
						}
					})
				}

			},
			//导出excel
			toExcel() {
				if (this.multipleSelection.length == 0) {
					this.$message.info("请选择要导出的数据！");
					return;
				}
				let data = {
					original_goods_unified_id: this.goods_excelIds.toString()
				}
				let a = document.createElement('a'); //创建a标签
				a.href = this.baseUrl +
					`/Goods/platform/excelList?original_goods_unified_id=${this.goods_excelIds.toString()}&time=${new Date().getTime()}`; //标签路径
				a.download = '商品'; //下载文件名称
				a.click(); //模拟点击
				a.remove()
			},
			//搜索
			searchOrder() {
				this.currentPage=1;
				this.getGoodsList() //商品列表
			},
			toClear(){
				this.search.two_cat_id = '';
				this.search.one_cat_id = '';
				this.search.keysward = '';
				this.search.goods_source = '';
				this.currentPage = 1;
				this.searchList=[];
				this.getGoodsList()
			},
			toReset() {
				this.search.two_cat_id = '';
				this.search.one_cat_id = '';
				this.search.keysward = '';
				this.search.goods_source = '';
				this.currentPage = 1;
				this.getGoodsList()
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getGoodsList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getGoodsList()
			},
			//添加商品
			addGoods() {
				this.$router.push({
					path: '/goodsadmin/supplygoods/gooddetail',
					query: {
						type: 'add'
					},
				})
			},
			//单个操作
			singleOperate(row, type) {
				if (type == 'detail') {
					this.$router.push({
						path: '/goodsadmin/supplygoods/gooddetail',
						query: {
							id: row.original_goods_unified_id,
							type: 'detail',
							activeName: this.activeName,
							searchWord: this.search.keysward,
							currentPage: this.currentPage
						},
					})
				}
				if (type == 'edit') {
					this.$router.push({
						path: '/goodsadmin/supplygoods/gooddetail',
						query: {
							id: row.original_goods_unified_id,
							type: 'add',
							activeName: this.activeName,
							currentPage: this.currentPage
						},
					})
				}
				if (type == 'price') {
					this.showRegu = !this.showRegu;
					this.original_goods_unified_id = row.original_goods_unified_id;
					this.showForms = [];
					this.getGoodsSkuInfo(row.original_goods_unified_id);
				}
				if (type == 'down' || type == 'up') {
					this.tools.confirm(type == 'down' ? '确定要下架？' : '确定要上架？').then(res => {
						if (res) {
							//调接口
							let data = {
								original_goods_unified_id: JSON.stringify([{
									original_goods_unified_id: row.original_goods_unified_id
								}]),
								is_publish: type == 'down' ? '0' : '1'
							}
							goods_putaway(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.getGoodsList();
								}
							})
						}
					})
				}
				if (type == 'del') {
					this.tools.confirm("确定要删除吗？").then(res => {
						if (res) {
							//调取批量删除接口
							let data = {
								original_goods_unified_id: JSON.stringify([{
									original_goods_unified_id: row.original_goods_unified_id
								}]),
							}
							platform_goods_delete(data).then(res => {
								this.$message.success("删除成功！");
								this.getGoodsList();
							})
						}
					})
				}
				if (type == 'shopdown') {
					this.tools.confirm("确定要下架吗？").then(res => {
						if (res) {
							//调取批量删除接口
							let data = {
								primeval_goods_id: JSON.stringify([{
									primeval_goods_id: row.primeval_goods_id
								}]),
							}
							platform_shop_goods_putaway(data).then(res => {
								this.$message.success("下架成功！");
								this.getGoodsList();
							})
						}
					})
				}

			},
			//获取规格信息
			getGoodsSkuInfo(id) {
				let data = {
					original_goods_unified_id: id
				}
				platform_GoodsSkuInfo(data).then(res => {
					let reguData = res.data.goodsSkuInfo;
					var kk = [];
					if (reguData.length > 0 && reguData[0].showForm != null) {
						this.showForms = reguData[0].showForm;
					}
					reguData.forEach((item, index) => {
						if (item.sku_desc != null) {
							var sku_desc = item.sku_desc; //规格值
							index = 0;
							kk.push({
								vals1: item.sku_desc[index],
								vals2: item.sku_desc[index + 1],
								vals3: item.sku_desc[index + 2]
							});
						}

					});

					this.$forceUpdate();
					var obj = reguData.map((it, index) => {
						return {
							...it,
							...kk[index]
						};
					});
					this.$nextTick(() => {
						this.reguData = obj;
					});

				})
			},

		
			//批量操作
			batchOpera(type) {
				if (this.multipleSelection.length == 0) {
					this.$message.info("请选择要操作的数据！");
					return;
				}
				if (type == 'price') {
					this.showBatchModel = !this.showBatchModel
				}
				if (type == 'del') {
					this.tools.confirm("确定要批量删除吗？").then(res => {
						if (res) {
							//调取批量删除接口
							let data = {
								original_goods_unified_id: JSON.stringify(this.goods_unified_id)
							}
							platform_goods_delete(data).then(res => {
								this.$message.success("批量删除成功！");
								this.getGoodsList();
							})
						}
					})
				}
				if (type == 'examine') {
					this.examineDialogVisible = true;
					this.examineform.goods_category_id = [];
					this.getAllCase();
				}

				if (type == 'down') {
					this.tools.confirm("确定要下架？").then(res => {
						if (res) {
							//调接口
							let data = {
								original_goods_unified_id: JSON.stringify(this.goods_unified_id),
								is_publish: '0'
							}
							goods_putaway(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.getGoodsList();
								}
							})
						}
					})
				}
				if (type == 'up') {
					this.tools.confirm("确定要上架？").then(res => {
						if (res) {
							//调接口
							let data = {
								original_goods_unified_id: JSON.stringify(this.goods_unified_id),
								is_publish: '1'
							}
							goods_putaway(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.getGoodsList();
								}
							})
						}
					})
				}
				if (type == 'types') {
					this.adjustDialogVisible = true;
					this.adjustform.goods_category_id = [];
					this.getAllCase();
				}

			},

			//取消批量设置价格
			batchCancel() {
				this.showBatchModel = !this.showBatchModel
			},
			//确定批量设置价格
			sureBatch(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data = {
							original_goods_unified_id: JSON.stringify(this.goods_unified_id),
							common_multiple: this.form.shopPricept,
							senior_multiples: this.form.shopPricegj
						}
						platformBatchChangePrice(data).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.showBatchModel = !this.showBatchModel
								this.$message.success("批量改价成功！");
								this.form.shopPricept = '';
								this.form.shopPricegj = '';
								this.getGoodsList();
							}
						})
					} else {
						return false;
					}
				});
			},
			reguCancel() {
				this.showRegu = !this.showRegu
			},
			//修改价格
			modifyPrice() {
				let sku = [];
				this.reguData.forEach(item => {
					sku.push({
						goods_original_sku_id: item.goods_original_sku_id,
						sku_premium_store_manager_price: item.sku_premium_store_manager_price,
						sku_ordinary_store_manager_price: item.sku_ordinary_store_manager_price,
					})
				});

				let data = {
					original_goods_unified_id: this.original_goods_unified_id,
					sku: JSON.stringify(sku),

				}
				platform_SetGoodsSkuInfo(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.$message.success("改价成功！");
						this.showRegu = !this.showRegu
						this.getGoodsList();
					}

				})

			},
			// 修改规格数据格式
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				// console.log(columnIndex)
				if (columnIndex === 0) {
					return {
						rowspan: row.row,
						colspan: row.col,
					};
				}
				if (columnIndex === 1) {
					return {
						rowspan: row.subrow,
						colspan: row.subcol,
					};
				}
			},
			//确认审核
			examineCommit(examineform, type) {
				if (type == 1) {
					this.$refs[examineform].validate((valid) => {
						if (valid) {
							//调接口
							let data = {
								goods_unified_id: this.goods_excelIds.toString(),
								is_verify: 1,
								goods_category_id: this.examineform.goods_category_id[1]
							}
							data = this.tools.filterParams(data);
							plateGoodsCateAudit(data).then(res => {
								this.$message.success("审核成功！");
								this.examineDialogVisible = false;
								this.getGoodsList();
							})

						} else {

							return false;
						}
					});
				} else if (type == 2) {
					this.dialogfailVisible = true;
				}


			},
			adjustCommit(adjustform) {
				this.$refs[adjustform].validate((valid) => {
					if (valid) {
						//调接口
						let data = {
							goods_unified_id: this.goods_excelIds.toString(),
							goods_category_id: this.adjustform.goods_category_id[1]
						}
						data = this.tools.filterParams(data);
						plateBulkEditingGoodsCate(data).then(res => {
							this.$message.success("批量设置成功！");
							this.adjustDialogVisible = false;
							this.getGoodsList();
						})

					} else {

						return false;
					}
				});
			},
			faileCommit() {
				let data = {
					goods_unified_id: this.goods_excelIds.toString(),
					is_verify: 2,
					verify_comment: this.verify_comment,
					goods_category_id: this.examineform.goods_category_id[1]
				}
				data = this.tools.filterParams(data);
				plateGoodsCateAudit(data).then(res => {
					this.$message.success("提交成功！");
					this.dialogfailVisible = false;
					this.examineDialogVisible = false;
					this.getGoodsList();
				})
			},
			//审核拒绝弹框
			repulse(row, type) {
				this.repulsedata = row;
				if (type == 1) {
					let data = {
						goods_unified_id: row.original_goods_unified_id,
						is_verify: '1'
					}
					plateGoodsAudit(data).then(res => {
						if (res.return_code == 'SUCCESS') {
							this.$message.success("审核完成")
							this.getGoodsList();
						}
					})

				}
				if (type == 2) {
					this.desctitle = "拒绝原因"
					this.dialogVisible = !this.dialogVisible
				}

			},
			//审核拒绝
			refuseCommit() {
				let data = {
					goods_unified_id: this.repulsedata.original_goods_unified_id,
					is_verify: '2',
					verify_comment: this.desc_txt
				}
				plateGoodsAudit(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.dialogVisible = !this.dialogVisible;
						this.desc_txt = '';
						this.getGoodsList();

					}

				})
			},

		}
	}
</script>

<style lang="scss" scoped="scoped">
	::v-deep.el-image__preview {
		cursor: pointer;
		width: 70px !important;
	}

	::v-deep.el-image {
		overflow: visible;
	}

	.el_name {
		display: -webkit-box;
		justify-content: flex-start;
		align-items: center;
		position: relative;

		.el_img {
			width: 70px;
			height: 70px;
			margin-right: 6px;
			// img{
			// 	width: 100%;
			// 	height: 100%;
			// }
		}

		.largeImg {

			width: 400px;
			height: 400px;
			display: none;
		}

		&:hover .largeImg {
			position: absolute;
			left: 0;
			top: -50px;
			z-index: 5;
			display: block;
		}
	}

	.pricetip {
		height: 30px;
		line-height: 30px;
		width: 300px;
		margin: 0 auto;
		text-align: center;
		background-color: #FFF0E5;
		color: #EB5E00;
		font-size: 14px;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		-moz-appearance: none !important;
		-o-appearance: none !important;
		-ms-appearance: none !important;
		appearance: none !important;
		margin: 0;
	}

	input[type="number"] {
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		-o-appearance: textfield;
		-ms-appearance: textfield;
		appearance: textfield;
	}

	::v-deep.el-tabs__header {
		padding: 0;
		position: relative;
		margin: 0;
	}
</style>
