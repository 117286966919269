<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>基本信息</span>
                    </div>
                    <div class="userinfo">
                        <div class="user_items firstitem">
                            <div class="users">
                                <div class="avatars">
                                    <el-avatar :size="65" :src="user.head_portrait"></el-avatar>
                                </div>
                                <div class="users_msg">
                                    <p>{{user.username}}</p>
                                    <p>手机号：{{user.account}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="user_items">
                            <div class="title">所属部门</div>
                            <div class="title vals">{{user.buyer_type}}</div>
                        </div>
                        <div class="user_items">
                            <div class="title">身份等级</div>
                            <div class="title vals">{{user.level_name}}<el-button type="text" v-if="user.level_name" style="margin-left:5px" @click="modifyLevel">修改</el-button>
                            </div>
                        </div>
                        <div class="user_items">
                            <div class="title">成为合伙人时间</div>
                            <div class="title vals">{{user.agent_at}}</div>
                        </div>
                    </div>
                </el-card>
                <el-card class="box-card mt10" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>TA的粉丝</span>
                        <div style="float: right; padding: 3px 0">A：{{user.A_count}}人      B：{{user.B_count}}人</div>
                    </div>
                    <el-table :data="tableData" border style="width: 100%" v-loading="loading" size="small"
                        element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.2)" max-height="550px"
                        :header-cell-style="{ background: '#f3f8fe', color: '#606060' }">
                        <el-table-column label="所属部门" prop="buyer_type" align="center"></el-table-column>
                        <el-table-column label="合伙人名称" prop="username" align="center"></el-table-column>
                        <el-table-column label="手机号" prop="account" align="center"></el-table-column>
                        <el-table-column label="合伙人等级" prop="level_name" align="center"></el-table-column>
                        <el-table-column label="开通金额" prop="open_price" align="center"></el-table-column>
                        <el-table-column label="成为合伙人时间" prop="agent_at" align="center"></el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]"
                        :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>

                    
                </el-card>
            </div>
        </div>
           <el-dialog title="修改等级" :visible.sync="dialogVisible" width="25%" >
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="用户等级" prop="level" >
                     <el-select style="width: 180px;!important" size="small" clearable v-model="ruleForm.change_level"
                                placeholder="请选择">
                                <el-option v-for="(items, index) in leveldata" :key="index" :label="items.level_name"
									:value="items.level">
								</el-option>
                            </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" size="small">提交</el-button>
            </span>
        </el-dialog>
    </div>

</template>
<script>
import {teamfans,accountLevel,modifyLevel} from '@/api/plat/partner'
export default {
    data() {
        return {
            account_shop_id: this.$route.query.account_shop_id,
            user:{},
            loading:true,
            circleUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
              tableData: [
                {
                    department: 'A'
                }
            ],
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
             dialogVisible:false,
             ruleForm:{
                 change_level:'',
            },
           
            leveldata:[]
        }
    },
    mounted() { 
        if(this.account_shop_id){
            this.getList();
        }
        
    },
     methods: {
        	getList() {
                let data={
                    account_id:this.account_shop_id,
                    page:this.currentPage,
                    perPage:this.pagesize
                }
                teamfans(data).then(res=>{
                    this.user=res.data.user;
                    this.tableData=res.data.list;
                    this.total=res.data.count;
                    this.loading=false;

                })
				
			},
         handleSizeChange(size) {
            this.pagesize = size;
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getList()
        },
         //获取等级
        modifyLevel(){
            this.dialogVisible=true;
            accountLevel({}).then(res=>{
               this.leveldata=res.data
			})
           
            
        },
        //修改等级
        submitForm(){
            let data={
                account_shop_id:this.account_shop_id,
                level:this.ruleForm.change_level
            }
            modifyLevel(data).then(()=>{
                this.dialogVisible=false;
                this.getList();
            })
        },
     }
}
</script>
<style lang="less" scoped>
.userinfo {
    background: #FCFBFC;
    padding: 20px;
    display: flex;

    .user_items {
        flex: 1;
        text-align: center;

        .title {
            line-height: 33px;
            font-size: 14px;
        }

        .title.vals {
            font-weight: border;
            font-size: 16px
        }

    }

    .user_items.firstitem {
        text-align: left
    }
}

.users {
    overflow: hidden;
    font-size: 14px;

    .avatars {
        height: 65px;
        float: left
    }

    .users_msg {
        float: left;
        margin-left: 10px;

        p {
            line-height: 33px;
        }

    }

}

.box-card {
    ::v-deep.el-card__body {
        padding: 0 !important
    }
}
</style>