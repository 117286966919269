<template>
	<div>
		<div class="wrapper-main">
			<el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small" class="demo-ruleForm">
				<div class="wrapper-cont pp20">
					<div class="setTitle">课程目录</div>
					<el-form-item label="课程添加:" prop="tableData">
						<el-button type="primary" size="small" plain icon="el-icon-plus" @click="toAdd">添加课程</el-button>
						<el-table :data="form.tableData" v-if="form.tableData.length>0" border style="width: 100%"
							class="mt20">
							<el-table-column label="排序" type="index" width="50"></el-table-column>
							<el-table-column label="目录名称" align="center" prop="video_name"></el-table-column>
							<el-table-column label="实际学习量" align="center" prop="real_learn_number"></el-table-column>
							<el-table-column label="虚拟学习量" align="center" prop="virtual_learn_number"
								:render-header="renderHeadeTime">
							</el-table-column>
							<el-table-column label="课程类型" align="center">
								<template slot-scope="scope">
									<span style="color: #E02020;" v-if="scope.row.is_trial == '1'">需解锁</span>
									<span style="color: #409EFF;" v-else-if="scope.row.is_trial == '2'">试听</span>
								</template>
							</el-table-column>
							<el-table-column label="课程状态" align="center">
								<template slot-scope="scope">
									<span style="color: #409EFF;" v-if="scope.row.status == '1'">启用</span>
									<span style="color: #E02020;" v-else-if="scope.row.status == '2'">禁用</span>
								</template>
							</el-table-column>
							<el-table-column label="转码状态" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.transcoding == '1'" style="color: #E6A23C;">等待转码</span>
									<span v-else-if="scope.row.transcoding == '2'" style="color: #F56C6C;">转码中</span>
									<span v-else-if="scope.row.transcoding == '3'" style="color: #67C23A;">转码成功</span>
									<span v-else-if="scope.row.transcoding == '4'" style="color: #F56C6C;">转码失败</span>
								</template>
							</el-table-column>
							<el-table-column label="操作" align="center" class="opera-cont" width="180">
								<template slot-scope="scope">
									<el-button type="text" size="small" @click="toEdit(scope.row)">编辑</el-button>
									<el-button type="text" @click="toSetMain(scope.row)" style="color: #0091FF;"
										size="small">{{scope.row.status==1?'禁用':'启用'}}</el-button>
									<el-button type="text" size="small" style="color: #E02020"
										@click="toDel(scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</div>
			</el-form>
			<div style="background-color: #F2F2F6; height: 10px;"></div>
			<!-- 分页 -->
			<div class="pagecurrent" style="margin-top: 0 !important;">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<div class="save-cont">
			<!-- <div class="cancel-btn" @click="cancelSet">上一步</div> -->
			<div class="save-btn" @click="submitBack('form')">完成</div>
		</div>
		<!--添加课程弹框-->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="35%">
			<el-form ref="plusform" :model="plusform" :rules="plusrules" label-width="150px" size="small"
				class="demo-ruleForm">
				<el-form-item label="目录名称" prop="video_name">
					<el-input size="small" v-model="plusform.video_name" class="inputWidth" maxlength="40"
						show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="课程视频">
					<el-upload class="upload-demo" :show-file-list="false" list-type="picture-card" :on-change="videoUp" action="/">
						<el-progress v-if="progressFlag" type="circle" :percentage="Loading" style="margin-top:10px;">
						</el-progress>
						<div v-else>
							<img v-if="plusform.video_cover" :src="plusform.video_cover"
								style="background-size: 100% 100%;width: 148px; height: 148px;" />
							<i v-else class="el-icon-plus"></i>
						</div>
					</el-upload>
					<div style="color: #999;font-size: 12px;">
						建议视频尺寸4:9
					</div>
				</el-form-item>
				<el-form-item label="是否试听:">
					<el-radio v-model="plusform.is_trial" label="2">是</el-radio>
					<el-radio v-model="plusform.is_trial" label="1">否</el-radio>
				</el-form-item>
				<el-form-item label="排序:">
					<el-input placeholder="请输入数值" class="inputWidth" size="small"
						oninput="value=value.replace(/[^\d.]/g,'')" v-model="plusform.sort">
					</el-input>
					<p class="imgtxtstyle">注：排序越小越靠前，当同个数值时按创建时间最早排在前</p>
				</el-form-item>
				<el-form-item label="虚拟学习量:">
					<el-popover placement="bottom" width="284" trigger="hover" content="虚拟学习量=实际学习量*倍数">
						<span slot="reference" class="tips">
							<img align="center" src="../../../../../../assets/img/question.png" class="tipsimg" />
						</span>
					</el-popover>
					<el-input class="w180" oninput="value=value.replace(/[^\d.]/g,'')" v-model="plusform.multiple">
						<template slot="append">倍</template>
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitSet('plusform')" size="small">确认</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import upviedo from "../../../../../../mixins/upvideo.js"
	import {
		saveCurriculumVideo,
		getCurriculumVideoList,
		delCurriculumVideo,
		getCurriculumVideoInfo,
		saveVideoStatus
	} from "@/api/plat/college.js"
	export default {
		mixins: [upviedo],
		data() {
			return {
				form: {
					tableData: []
				},
				rules: {
					tableData: [{
						required: true,
						message: '请选择课程',
						trigger: 'change'
					}],
				},
				dialogVisible: false,
				title: '添加课程',
				plusform: {
					video_name: '',
					video_cover: '',
					is_trial: '1',
					sort: '0',
					multiple: '1'
				},
				plusrules: {
					video_name: [{
						required: true,
						message: '请输入目录名称',
						trigger: 'blur'
					}],


				},
				flag: false,
				id: this.$route.query.id,
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				progressFlag: false
			}
		},
		mounted() {
			this.getVideData()
		},
		methods: {
			getVideData() {
				let data = {
					college_curriculum_id: this.id,
					page: this.currentPage,
					perPage: this.pagesize
				}
				getCurriculumVideoList(data).then(res => {
					this.form.tableData = res.data.list;
					this.total = res.data.count;

				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getVideData()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getVideData()
			},
			videoUp(file) {
				console.log(file.raw,"file.rawfile.rawfile.raw")
				this.progressFlag = true;
				this.plusform.video_cover = '';
				if (this.flag) return;
				this.flag = true;
				this.uploadViedo(file.raw, this.id, 2).then(res => {
					console.log('总体完成')
					this.flag = false;
				}).catch(err => {
					console.log('总体失败');
					this.flag = false;
				})
			},
			//上一步
			cancelSet() {
				this.$router.push({
					path: "/marketingadmin/businesscollege/collegeset",
				})
			},
			//添加
			toAdd() {
				this.title = "添加课程";
				this.progressFlag = false;
				this.anticipation = '';
				this.plusform.video_name = '';
				this.plusform.video_file = '';
				this.plusform.is_trial = '1';
				this.plusform.sort = '0';
				this.plusform.video_cover = '';
				this.plusform.multiple = '1';
				this.dialogVisible = true;
			},
			//编辑
			toEdit(row) {
				if (row.transcoding == 1 || row.transcoding == 1) {
					this.$message.warning("视频处理中")
					return;
				}
				this.title = "编辑课程";
				this.progressFlag = false;
				this.anticipation = row.video_path;
				this.dialogVisible = true;
				this.getInfo(row)
			},
			//修改状态
			toSetMain(row) {
				this.tools.confirm(row.status == 1 ? "确定要禁用课程吗？" : "确定要启用课程吗？").then(res => {
					if (res) {
						//调接口
						saveVideoStatus({
							college_curriculum_video_id: row.college_curriculum_video_id,
							status: row.status == 1 ? 2 : 1
						}).then(res => {
							this.$message.success("操作成功")
							this.getVideData()
						})
					}
				})
			},
			//获取视频详情
			getInfo(row) {
				this.college_curriculum_video_id = row.college_curriculum_video_id;
				getCurriculumVideoInfo({
					college_curriculum_video_id: row.college_curriculum_video_id
				}).then(res => {
					this.plusform = res.data;
					this.plusform.is_trial = this.plusform.is_trial.toString();
					this.plusform.sort = this.plusform.sort.toString();
				})
			},
			toDel(row) {
				this.tools.confirm("确定要删除课程吗？").then(res => {
					if (res) {
						//调接口
						delCurriculumVideo({
							college_curriculum_video_id: row.college_curriculum_video_id
						}).then(res => {
							this.$message.success("删除成功")
							this.getVideData()
						})
					}
				})
			},
			// 为表格头标题添加小图标并hover后出现提示信息
			renderHeadeTime(h, {
				column,
				$index
			}) {
				// h 是一个渲染函数       column 是一个对象表示当前列      $index 第几列
				return h("div", [
					h("span", column.label + "  ", {
						align: "center",
						marginTop: "10px"
					}),
					h(
						"el-popover", {
							props: {
								placement: "top-start", // 一般 icon 处可添加浮层说明，浮层位置等属性
								width: "260",
								trigger: "hover"
							}
						},
						[
							h("p", "虚拟学习量=实际学习量*倍数。", {
								class: "text-align: center; margin: 0"
							}),
							h("i", { // 生成 i 标签 ，添加icon 设置 样式，slot 必填
								class: "el-icon-question",
								style: "color:#ccc,margin:18px,padding-top:10px",
								slot: "reference"
							})
						]
					)
				])
			},
			//提交设置
			submitSet(plusform) {
				this.$refs[plusform].validate((valid) => {
					if (valid) {
						if(this.Loading!=100){
							this.$message.error("视频没有上传成功哦！");
							return;
						}
						//调接口
						let data = JSON.parse(JSON.stringify(this.plusform));
						if (this.anticipation.indexOf(".m3u8") == -1) {
							this.anticipation = this.anticipation + '.m3u8'
						}
						data.video_path = this.anticipation;
						data.college_curriculum_id = this.id;
						if (this.college_curriculum_video_id) {
							data.college_curriculum_video_id = this.college_curriculum_video_id
						}

						data.video_path = this.tools.filterImgPre(data.video_path);
						data.video_cover = this.tools.filterImgPre(data.video_cover)
						data = this.tools.filterParams(data);
						saveCurriculumVideo(data).then(res => {
							if (this.college_curriculum_video_id) {
								this.$message.success("编辑成功！")
							} else {
								this.$message.success("添加成功！")
							}
							this.getVideData();
							this.dialogVisible = false;
							this.college_curriculum_video_id = ''
						})
					}
				});
			},
			//返回列表
			submitBack(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						this.$router.push({
							path: "/marketingadmin/businesscollege/businesscollegeindex",
							query: {
								activeName: 'first'
							}
						})
					}
				});

			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.tipsimg {
		width: 24px;
		height: 24x;

	}

	.img_blo::v-deep.el-upload--text {
		background-color: #fff;
		border: none;
		border-radius: 6px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		width: 120px;
		height: 120px;
		text-align: center;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.img_blo::v-deep.el-upload-dragger {
		background-color: #fff;
		border: none;
		border-radius: 6px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		width: 120px;
		height: 120px;
		text-align: center;
		position: relative;
		overflow: hidden;
	}

	.img_blo::v-deep.plusImg .el-icon-plus {
		width: 115px;
		height: 115px;
		line-height: 115px;
		font-size: 24px;
		color: #969799;
	}
</style>
