<template>
  <div>
    <div style="border-radius: 10px; padding-bottom: 0px; background-color: #F2F2F6;">
      <div class="main">
        <!-- 当前选择的礼包商品 -->
        <div class="goodsinfo" v-if="this.grade >= 2 && this.goodsList.length > 0">
          <div class="title">当前礼包包含商品</div>
          <div class="main-cont bgwt mt10">
            <el-table :data="goodsList" style="width: 100%" min-height="100" max-height="400" border
              :header-cell-style="{ background: '#f3f8fe', color: '#606060' }" element-loading-text="拼命加载中..."
              element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
              <el-table-column label="商品名称" width="350" align="left">
                <template slot-scope="scope">
                  <div class="el_name" style="display: flex;">
                    <div style="width:70px;height:70px;">
                      <el-image style="width: 100%;height: 100%;" :src="scope.row.goods_main_pic.split(',')[0]"
                        :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]" class="el_img" />
                    </div>
                    <!-- <img :src="scope.row.goods_main_pic.split(',', 1)" class="el_img" /> -->
                    <div style="width: 78%;margin-left: 10px; height: 70px;line-height: 70px;">
                      <p>{{ scope.row.goods_name }}</p>
                      <p class="mt10">{{ scope.row.count }}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="goods_stock" label="规格明细" align="center">
                <template #default="{ row }">
                  <span v-for="(item, index) in row.goods_sku[0].value" :key="index">{{ item.specname }} </span>
                </template>
              </el-table-column>
              <el-table-column prop="goods_stock" label="当前库存" align="center">
                <template #default="{ row }">
                  <div>{{ row.skuInfo[0].sku_stock }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="del(scope.row)"><span style="color:rgb(252, 10, 10)"
                      v-has="'删除'">删
                      除</span></el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <!-- <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2" :current-page="currentPage2" :page-sizes="[5, 10, 20, 30]"
            :page-size="pagesize2" layout="total, sizes, prev, pager, next, jumper" :total="total2">
          </el-pagination> -->
          </div>
        </div>


        <div class="djmc">
          <span style="color: red;font-size: 14px;">*</span><span
            style="font-size: 16px; font-weight: 700;">等级名称：</span><el-input v-model="level_name" maxlength="10"
            show-word-limit placeholder="请输入等级名称" style="width: 300px; margin-top:-10px;"
            :class="'listIcon' + this.grade">
          </el-input>
        </div>
        <div class="dqqy">
          <span style="color: red; margin-top: 10px;">*</span>
          <span style="width: 150px; margin-top: 10px;font-weight: 700;font-size: 16px;">当前等级权益：</span>
          <span v-if="this.grade !== '1'" style="margin-top: 10px;margin-left: -5px;font-weight: 600;">1、分红奖：</span><span
            v-if="this.grade !== '1'">可获得奖金池 <el-input v-if="this.grade !== '1'" v-model="deep_percent" placeholder=""
              style="width: 120px; margin-left: 0px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template
                slot="append">%</template></el-input>（与等级二级用户平分）</span>
          <span v-if="this.grade == '1'" style="margin-top: 10px;font-weight: 700;">无</span>
        </div>

        <div class="dqqy" style="margin: 20px 0;" v-if="this.grade !== '1'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;">2、直推奖：</span><span>可获得直属下级用户购买礼包金额的<el-input
              v-model="direct_percent" placeholder="" style="width: 120px; margin-left: 10px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template
                slot="append">%</template></el-input></span>
        </div>

        <div class="dqqy" style="margin: 20px 0;" v-if="this.grade !== '1'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;">3、股票期权：</span><span>可获得股票期权<el-input v-model="stock"
              placeholder="" style="width: 160px; margin-left: 10px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{10,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template
                slot="append">股</template></el-input></span>
        </div>

        <div class="dqqy" style="margin: 20px 0;" v-if="this.grade >= '4'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;">4、团队将：</span><span>可获得下级礼包金额

            <el-input v-model="teamwill4" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')" v-if="grade == '4'"><template
                slot="append">%</template></el-input>

            <el-input v-model="teamwill5" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')" v-if="grade == '5'"><template
                slot="append">%</template></el-input>

            <el-input v-model="teamwill6" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')" v-if="grade == '6'"><template
                slot="append">%</template></el-input>

            <el-input v-model="teamwill7" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')" v-if="grade == '7'"><template
                slot="append">%</template></el-input>

          </span>
        </div>

        <div class="dqqy" style="margin: 20px 0;" v-if="this.grade >= '4'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;">5、管理奖：</span><span>可获得团队奖
            <el-input v-model="managewill4" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')" v-if="grade == '4'"><template
                slot="append">%</template></el-input>

            <el-input v-model="managewill5" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')" v-if="grade == '5'"><template
                slot="append">%</template></el-input>

            <el-input v-model="managewill6" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')" v-if="grade == '6'"><template
                slot="append">%</template></el-input>

            <el-input v-model="managewill7" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')" v-if="grade == '7'"><template
                slot="append">%</template></el-input></span>
        </div>

        <div class="dqqy" v-if="this.grade !== '1'" style="margin-top: 50px;">
          <span style="width: 150px; margin-top: 10px;font-weight: 700;font-size: 16px;margin-left: 10px;">分红上限：</span>
          <span v-if="this.grade !== '1'" style="margin-top: 10px;margin-left: -10px;"><span
              style="font-weight: 600;">1、</span>每购买一次该等级的升级礼包，可增加：</span><span><el-input v-if="this.grade !== '1'"
              v-model="cap_amount" placeholder="" style="width: 180px; margin: 0px 5px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')">
              <template slot="append">元</template></el-input> 可分红金额上限</span>
        </div>

        <div class="dqqy" style="margin: 20px 0 0 0;" v-if="this.grade == '2'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;"><span
              style="font-weight: 600;">2、</span>每拉新3个直属下级，并且这三个直属下级购买礼包时：当前用户购买升级礼包的数量+1，</span>
        </div>

        <div class="dqqy" style="margin: 20px 0 0 0;" v-if="this.grade == '2'">
          <span style="width: 150px;margin-left: 20px;"></span>
          <span style="margin-top: 0px;"><span>增加：<el-input v-model="cap_direct_amount" placeholder=""
                style="width: 180px; margin: 0px 5px;"
                onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template
                  slot="append">元</template></el-input>分红金额上限。</span><span
              style="color: red;font-weight: 600;">升级上限不得大于购买该等级礼包数量!</span></span>
        </div>

        <div class="dqqy" v-if="this.grade !== '1'" style="margin-top: 30px;">
          <span
            style="width: 200px; margin-top: 10px;font-weight: 700;font-size: 16px;margin-left: 10px;">复用昨日沉淀金额比例：</span>
          <span><el-input v-if="this.grade !== '1'" v-model="reuse_percent" placeholder=""
              style="width: 180px; margin: 0px 5px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')">
              <template slot="append">%</template></el-input></span>
        </div>


        <div class="dqqy" style="margin-top: 40px ;" v-if="this.grade == '2'">
          <span style="color: red;">*</span>
          <span style="width: 150px;font-weight: 700;font-size: 16px;">升级条件：</span>
          <span style="margin-right: 20px;">购买礼包，进行升级</span><el-button type="text"
            style="font-size: 14px;margin-top: -10px;" @click="chooseGoods" v-has="'选择礼包'">选择商品</el-button>
        </div>




        <div class="dqqy" style="margin-top: 30px;" v-if="this.grade !== '1' && this.grade !== '2'">
          <span style="color: red;margin-top: 10px;">*</span>
          <span style="width: 150px;font-weight: 700;font-size: 16px;margin-top: 10px;">升级条件：</span>
          <span v-if="this.grade !== '1'" style="margin-top: 10px;"><span style="font-weight: 600;">1、</span>直推等级
            <span v-if="grade == '3'">二</span>
            <span v-if="grade == '4'">三</span>
            <span v-if="grade == '5'">四</span>
            <span v-if="grade == '6'">五</span>
            <span v-if="grade == '7'">六</span>
            人数达到 </span>
          <span v-if="this.grade !== '1'"><el-input v-if="this.grade !== '1'" v-model="direct_num" placeholder=""
              style="width: 120px; margin-left: 0px;margin: 0 5px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template
                slot="append">人</template></el-input> ; 团队业绩达到<span><el-input v-model="performance" placeholder=""
                style="width: 180px; margin: 0px 5px;"
                onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template
                  slot="append">元</template></el-input></span></span>
        </div>

        <!-- <div class="dqqy" style="margin: 20px 0;" v-if="this.grade !== '1' && this.grade !== '2'">
        <span style="width: 150px;margin-left: 10px;"></span>
        <span style="margin-top: 10px;">2、直推业绩达到</span><span><el-input v-model="performance" placeholder=""
            style="width: 130px; margin-left: 10px;"
            onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template slot="append">元</template></el-input></span>
      </div> -->
        <div class="dqqy" style="margin-top: 20px ;" v-if="this.grade > '2'">
          <span style="width: 155px;"></span>
          <span style="margin-right: 20px;"><span style="font-weight: 600;">2、</span>购买礼包，进行升级</span><el-button
            type="text" style="font-size: 14px;margin-top: -10px;" @click="chooseGoods" >选择商品</el-button>
        </div>
      </div>
    </div>
    <div class="save-cont" style="margin-left:-20px;">
      <!-- <div class="cancel-btn">取消</div> -->
      <div class="save-btn" @click="addSubmit()">保存</div>
    </div>

    <!-- 选择商品弹窗 -->
    <el-dialog title="选择商品" :visible.sync="centerDialogVisible" width="30%">
      <div class="serch">
        <el-input clearable class="inputsWidth" prefix-icon="el-icon-search" v-model="name" placeholder="输入关键字"
          @keyup.enter.native="toSearch" size="small" type="text">
        </el-input><el-button type="primary" size="small" @click="toSearch" style="margin-left: 30px;">搜索</el-button>
      </div>
      <div class="main-cont bgwt mt10">
        <el-table :data="tableData" style="width: 100%" min-height="250" max-height="550" border
          @selection-change="handleSelectionChange" :header-cell-style="{ background: '#f3f8fe', color: '#606060' }"
          v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)" :cell-style="grayBg">
          <el-table-column type="selection" width="55" :selectable="selectableFunc">
          </el-table-column>
          <el-table-column label="商品名称" width="350" align="left">
            <template slot-scope="scope">
              <div class="el_name" style="display: flex;">
                <div style="width:70px;height:70px;">
                  <el-image style="width: 100%;height: 100%;" :src="scope.row.goods_main_pic.split(',')[0]"
                    :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]" class="el_img" />
                </div>
                <!-- <img :src="scope.row.goods_main_pic.split(',', 1)" class="el_img" /> -->
                <div style="width: 78%;margin-left: 10px; height: 70px;line-height: 70px;">
                  <p>{{ scope.row.goods_name }}</p>
                  <p class="mt10">{{ scope.row.count }}</p>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="goods_stock" label="总库存" align="center">
          </el-table-column>

        </el-table>
        <!-- 分页 -->
        <el-pagination hide-on-single-page background @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 20, 30]"
          :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="addGoods" size="mini">确定添加</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { config, getConfig } from "@/api/customers"
import {
  goods_list,
} from "@/api/merchant/good.js"
export default {
  name: 'VueShopAdminGradeContent',
  props: ["grade"],
  watch: {
    // grade: {
    //   handler(newVal, oldVal) {
    //     this.getConfig()
    //   }
    // }
  },
  data() {
    return {
      input: '',
      centerDialogVisible: false,
      deep_percent: '',
      direct_percent: '',
      stock: '',
      level: '',
      level_name: '',
      performance: '',
      direct_num: '',
      currentPage: 1,
      pagesize: 10000,
      loading: true,
      loading2: true,
      total: 0,
      tableData: [],
      name: '',
      multipleSelection: [],
      goodsList: [],
      package_id: '',
      cap_amount: '',
      cap_direct_amount: '',
      reuse_percent: 10,
      teamwill4: 3,
      teamwill5: 5,
      teamwill6: 7,
      teamwill7: 9,
      managewill4: 10,
      managewill5: 10,
      managewill6: 10,
      managewill7: 10,
      team_percent:'',
      manage_percent:'',
    };
  },

  mounted() {
    this.getConfig()
  },
  methods: {
    grayBg({ row }) {
      if (row.flag == 1) {
        return {
          background: '#edf2fc'
        }
      }
    },
    selectableFunc(row) {
      // console.log(row);

      return row.flag == 1 ? false : true;
    },
    del(val) {


      // let index = this.goodsList.map((item,i)=>{
      //   if(item.goods_id==val.goods_id){
      //     return i
      //   }
      // });
      // index = index.filter(item=>item||item==0)
      // this.goodsList.splice(index[0],1)

      //用findIndex更加简单
      const index = this.goodsList.findIndex(item => item.goods_id == val.goods_id);
      this.goodsList.splice(index, 1)
    },
    addGoods() {
      // console.log(this.multipleSelection);

      if (this.multipleSelection.length == 0) {
        this.$message.info("请选择商品")
        return
      }
      this.goodsList = [...this.multipleSelection]
      this.centerDialogVisible = false
    },
    //搜索
    toSearch() {
      this.currentPage = 1;
      this.getList();
    },
    searchOrder() {

    },
    //批量选择
    handleSelectionChange(val) {
      // let ids = []
      // val.forEach(item => {
      // 	ids.push(item.goods_unified_id)
      // })
      this.multipleSelection = val;
      // console.log(val);
    },
    //获取列表
    getList() {
      let data = {
        keyword: this.name,
        goods_source: '2',
        is_source: '0',
        page: this.currentPage,
        perPage: this.pagesize,
        is_gift_goods: 1,
        is_publish: 1
      }

      data = this.tools.filterParams(data);
      goods_list(data).then(res => {
        this.loading = false;
        // this.tableData = res.data.list.filter(item =>
        //   item.is_publish == '1'
        // )
        this.tableData = res.data.list
        this.total = res.data.count
        console.log("tab", this.tableData);
        if (this.package_id != '' && this.grade >= 2) {
          let arr = this.package_id.split(',')
          //  console.log("arr",arr);
          arr.map((item, index) => {
            this.tableData.map((i) => {
              if (i.goods_unified_id == item) {
                this.goodsList.push(i)
                //  console.log(213213213213213123);count
                this.goodsList = [...new Set(this.goodsList.map(JSON.stringify))].map(JSON.parse)
              } else {
                return
              }
            })
          })
        }
      })
      // console.log("tab2",this.tableData);

    },
    //分页
    handleSizeChange(size) {
      this.pagesize = size;
      // 掉接口
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      // 掉接口
      this.getList();
    },
    chooseGoods() {
      this.centerDialogVisible = true;
      // this.getList()
    },
    getConfig() {
      getConfig({ level: this.grade }).then(async (res) => {
        if (res) {
          // console.log(res);
          this.deep_percent = res.data.deep_percent
          this.direct_percent = res.data.direct_percent
          this.stock = res.data.stock
          // this.grade=res.data.level
          // this.$emit('callBanck', res.data.level);
          this.level_name = res.data.level_name
          this.performance = res.data.performance
          this.direct_num = res.data.direct_num
          this.input = res.data.package_price
          this.package_id = res.data.package_id //
          this.cap_direct_amount = res.data.cap_direct_amount
          this.reuse_percent = res.data.reuse_percent
          this.cap_amount = res.data.cap_amount
          if (this.grade == 4) {

            if (res.data.team_percent||res.data.team_percent===0) {
              this.teamwill4 = res.data.team_percent

            }
            if (res.data.manage_percent||res.data.manage_percent===0) {
              this.managewill4 = res.data.manage_percent

            }
          }

          if (this.grade == 5) {

            if (res.data.team_percent||res.data.team_percent===0) {
              this.teamwill5 = res.data.team_percent

            }
            if (res.data.manage_percent || res.data.manage_percent === 0) {
              this.managewill5 = res.data.manage_percent

            }
          }

          if (this.grade == 6) {

            if (res.data.team_percent || res.data.team_percent===0) {
              this.teamwill6 = res.data.team_percent

            }
            if (res.data.manage_percent||res.data.manage_percent===0) {
              this.managewill6 = res.data.manage_percent
            }
          }

          if (this.grade == 7) {

            if (res.data.team_percent||res.data.team_percent===0) {
              this.teamwill7 = res.data.team_percent

            }
            if (res.data.manage_percent||res.data.manage_percent===0) {
              this.managewill7 = res.data.manage_percent

            }
          }


          if (this.grade >= 2) {
            this.getList()
          }

        }
      })
    },
    addSubmit() {

      if (this.level_name === "") {
        this.$message.error("请输入等级名称")
        return
      }
      if (this.grade > 1) {
        if (this.deep_percent === '') {
          this.$message.error("请输入分红奖百分比")
          return
        }
        if (this.direct_percent === '') {
          this.$message.error("请输入直推奖百分比")
          return
        }
        if (this.stock === "") {
          this.$message.error("请输入股票期权")
          return
        }
        // if (this.reuse_percent === "") {
        //   this.$message.error("请输入复用昨日沉淀金额比例")
        //   return
        // }
      }
      // if (this.grade == 2) {
      //   if (this.input == "") {
      //     this.$message.error("请输入礼包价值")
      //     return
      //   }
      // }

      if (this.grade > 2) {

        if (this.direct_num === "") {
          this.$message.error("请输入直推人数")
          return
        }
        if (this.performance === "") {
          this.$message.error("请输入团队业绩")
          return
        }
      }
      if (this.grade == 4) {

        if (this.teamwill4 === "") {
          this.$message.error("请输入团队奖金额")
          return
        }
        if (this.managewill4 === "") {
          this.$message.error("请输入管理奖金额")
          return
        }else{
          this.team_percent=this.teamwill4 
          this.manage_percent=this.managewill4 
        }
      }

      if (this.grade == 5) {

        if (this.teamwill5 === "") {
          this.$message.error("请输入团队奖金额")
          return
        }
        if (this.managewill5 === "") {
          this.$message.error("请输入管理奖金额")
          return
        }else{
          this.team_percent=this.teamwill5 
          this.manage_percent=this.managewill5 
        }
      }

      if (this.grade == 6) {

        if (this.teamwill6 === "") {
          this.$message.error("请输入团队奖金额")
          return
        }
        if (this.managewill6 === "") {
          this.$message.error("请输入管理奖金额")
          return
        }else{
          this.team_percent=this.teamwill6
          this.manage_percent=this.managewill6
        }
      }

      if (this.grade == 7) {

        if (this.teamwill7 === "") {
          this.$message.error("请输入团队奖金额")
          return
        }
        if (this.managewill7 === "") {
          this.$message.error("请输入管理奖金额")
          return
        }else{
          this.team_percent=this.teamwill7
          this.manage_percent=this.managewill7
        }
      }
      let id = this.goodsList.length > 0 ? this.goodsList.map(item => {
        return item.goods_sku_id
      }).join(",") : ''
      let id2 = this.goodsList.length > 0 ? this.goodsList.map(item => {
        return item.goods_unified_id
      }).join(",") : ''

      let data = {
        deep_percent: this.deep_percent,
        direct_percent: this.direct_percent,
        stock: this.stock,
        level: this.grade,
        level_name: this.level_name,
        performance: this.performance,
        direct_num: this.direct_num,
        package_price: this.input,
        package_sku_id: id,
        package_id: id2,
        cap_amount: this.cap_amount,
        team_percent: this.team_percent,
        manage_percent: this.manage_percent,
        cap_direct_amount: this.cap_direct_amount,
        reuse_percent: this.reuse_percent === '' ? 10 : this.reuse_percent,
      }
      // console.log(this.form);

      config(data).then(res => {
        if (res) {
          this.$message.success("保存成功！");
          this.getConfig()
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  color: rgb(173, 75, 205);
  font-size: 15px;
  font-weight: 700;
}

.main {
  padding: 30px;
  position: relative;
  background-color: #fff;
  min-height: 580px;
  border-radius: 0px;
padding-bottom: 50px;
  .goodsinfo {
    position: absolute;
    right: 20px;
    top: 40px;
    height: 500px;
    width: 615px;

    // background-color:red;
    .title {
      width: 615px;
      // margin:0 auto 
      text-align: center;
      font-weight: 700;

    }
  }

  .djmc {
    display: flex;
    margin-bottom: 50px;
    // .listIcon1{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	// color:#ffcc33;
    // }
    // .listIcon2{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#ffcc33 !important;
    // }
    // .listIcon3{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#ff6666;
    // }
    // .listIcon4{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#00cc99;
    // }
    // .listIcon5{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#3399ff;
    // }
    // .listIcon6{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#cc33ff;
    // }
    // .listIcon7{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#ff5050;
    // }
  }

  .dqqy {
    display: flex;

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>