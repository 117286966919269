<template>
	<div>
		<div class="wrapper-main">
			<div class="sub-title">活动名称：{{activityDatas.activity_name}}</div>
			<div class="wrapper-cont">
				<el-row :gutter="20">
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="title">活动收益(元)</div>
							<div class="vals">{{activityDatas.money}}</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="pull-cont clearfix">
								<div class="pull-left">活动订单数(单）</div>
								<div class="pull-right">{{activityDatas.order_number}}</div>
							</div>
							<div class="pull-cont clearfix"
								style="margin-top: 12px; padding-top: 13px; border-top: 1px solid #EEEEEE;">
								<div class="pull-left">拼中订单数(单）</div>
								<div class="pull-right">{{activityDatas.is_win}}</div>
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="title">发放红包金额(元)</div>
							<div class="vals">{{activityDatas.reward}}</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="title">参与的会员数</div>
							<div class="vals">{{activityDatas.account_number}}</div>
						</div>
					</el-col>
				</el-row>
				<div class="main-cont bgwt tableMain mt10">
					<div style="height: 40px;line-height: 35px; font-size: 14px;">活动详情</div>
					<el-table :data="tableData" style="width: 100%" border :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column label="商品名称" prop="good_name" align="center">
							<template slot-scope="scope">
								<div class="el_name">
									<img :src="scope.row.goods_image" class="el_img" />
									<div>
										<p>{{ scope.row.goods_name }}</p>
										<p class="mt10" style="text-align: left;" v-if="scope.row.price">
											¥{{ scope.row.price }}</p>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="活动收益" align="center" prop="earnings"></el-table-column>
						<el-table-column label="团购订单数" prop="groupBuyingOrderNumber" align="center"></el-table-column>
						<el-table-column label="拼中订单数" prop="isWinNumber" align="center"></el-table-column>
						<el-table-column label="库存" prop="goods_stock" align="center"></el-table-column>
						<el-table-column label="团购库存" align="center" prop="groupBuyingStock"></el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
				<div class="main-cont bgwt tableMain mt10">
					<div style="height: 40px;line-height: 35px; font-size: 14px;">详细信息</div>
					<el-row class="all_search_list" style="margin-bottom: 20px;">
						<div class="demo-input-suffix">
							<el-input class="inputWidth" prefix-icon="el-icon-search" clearable v-model="search.keyword"
								placeholder="请输入活动名称" size="small" type="text">
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-select v-model="search.status" class="w150" slot="prepend" placeholder="请选择" size="small">
								<el-option label="全部" value=""></el-option>
								<el-option label="未成团" value="0"></el-option>
								<el-option label="成团" value="1"></el-option>
								<el-option label="成团失败" value="2"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toSearch">搜索
							</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
					</el-row>
					<el-table :data="detailData" style="width: 100%" border :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column label="商品名称" prop="goods_name" align="center">
							<template slot-scope="scope">
								<div class="el_name">
									<img v-if="scope.row.goods_main_pic" :src="scope.row.goods_main_pic" class="el_img" />
									<div>
										<p>{{ scope.row.goods_name }}</p>
										<p class="mt10" style="text-align: left;" v-if="scope.row.sku_sale_price">
											¥{{ scope.row.sku_sale_price }}</p>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="期数" align="center" prop="group_buying_no"></el-table-column>
						<el-table-column label="参团人数" prop="accountNumber" align="center"></el-table-column>
						<el-table-column label="团购状态"  align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.status == 0">未成团</span>
								<span v-else-if="scope.row.status == 1">成团</span>
								<span v-else-if="scope.row.status == 2">成团</span>
								<span v-else-if="scope.row.status == 3">成团失败</span>
							</template>
						</el-table-column>
						<el-table-column label="开团时间" prop="created_at" align="center"></el-table-column>
						<el-table-column label="成团时间" align="center" prop="completed_at"></el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button type="text" @click="toDetail(scope.row)">查看</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
						<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChangedetail"
							@current-change="handleCurrentChangedetail" :current-page="currentPagedetail"
							:page-sizes="[5,10,15,20]" :page-size="pagesizedetail"
							layout="total, sizes, prev, pager, next, jumper" :total="totaldetail">
						</el-pagination>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		activityData,
		goodsData,
		goodsGroupInfo
	} from '@/api/merchant/marketing.js'
	export default {
		data() {
			return {
				loading:true,
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 5, //每页的数据   每页请求5条
				detailData: [],
				totaldetail: 0,
				currentPagedetail: 1, //初始页
				pagesizedetail: 5, //每页的数据   每页请求5条
				group_buying_activity_id: this.$route.query.id,
				activityDatas:{},
				search:{
					keyword:'',
					status:''
				}
			}
		},
		mounted() {
			//数据
			if(this.group_buying_activity_id){
				this.getactivityData();
				this.getActiveList();
				this.getDetailList();
			}
			
		},
		methods: {
			//数据
			getactivityData() {
				activityData({
					group_buying_activity_id: this.group_buying_activity_id
				}).then(res => {
					this.activityDatas=res.data

				})
			},
			//活动详情列表
			getActiveList() {
				let data = {
					group_buying_activity_id: this.group_buying_activity_id,
					page: this.currentPage,
					perPage: this.pagesize
				}
				goodsData(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getActiveList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getActiveList()
			},
			//详细信息列表
			getDetailList() {
				let data = {
					group_buying_activity_id: this.group_buying_activity_id,
					status:this.search.status,
					keyword:this.search.keyword,
					page: this.currentPagedetail,
					perPage: this.pagesizedetail
				}
				data = this.tools.filterParams(data);
				goodsGroupInfo(data).then(res => {
					this.loading=false;
					this.detailData = res.data.list;
					this.totaldetail = res.data.count;
					this.detailData.forEach(item=>{
						item.completed_at=item.completed_at=='0'?'--':item.completed_at
					})
				})
			},
			//分页
			handleSizeChangedetail(size) {
				this.pagesizedetail = size;
				// 掉接口
				this.getDetailList()
			},
			handleCurrentChangedetail(currentPage) {
				this.currentPagedetail = currentPage;
				// 掉接口
				this.getDetailList()
			},
			toSearch(){
				this.currentPagedetail=1;
				this.getDetailList();
				
			},
			toReset(){
				this.search.keyword='';
				this.search.status='';
				this.currentPagedetail=1;
				this.getDetailList();
			},
			//查看
			toDetail(row) {
				this.$router.push({
					path: "/marketingcenteradmin/luckypuzzle/luckypuzzledatadetail",
					query: {
						id: row.group_buying_activity_id,
						group_buying_no:row.group_buying_no,
						goods_unified_id:row.goods_unified_id
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.el_img {
			width: 50px;
			height: 50px;
			margin-right: 6px;
		}
	}

	.sub-title {
		color: #2589FF;
		font-size: 14px;
		padding-bottom: 20px;
		margin-left: 10px;
	}

	.bg-purple {
		background: #fff;
		border-radius: 4px;
		text-align: center;
		padding: 20px 0;

		.title {
			line-height: 50px;
			font-size: 14px;
		}
	}

	.pull-cont {
		width: 90%;
		margin: 0 auto;
		font-size: 14px;

		.pull-left {
			float: left;
			width: 50%;
			text-align: left;

		}

		.pull-right {
			float: left;
			width: 50%;
			text-align: right;
		}
	}
</style>
