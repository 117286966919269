<template>
 	<div>
 		<div class="wrapper-main">
 			<div class="wrapper-cont">
 				<div class="main-cont bgwt pp20">
 					<el-row class="all_search_list" style="margin-bottom: 0;">
 						<div class="demo-input-suffix">
 							<el-input size="small" placeholder="商家名称/商家编号" prefix-icon="el-icon-search"
 								v-model="search.keyword" @keyup.enter.native="toSearch">
 							</el-input>
 						</div>
 						<div class="demo-input-suffix">
 							<el-button type="primary" size="small" @click="toSearch">查询</el-button>
 						</div>
 						<div class="demo-input-suffix">
 							<el-button size="small" @click="toReset">重置</el-button>
 						</div>
 						<div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
 							<el-date-picker style="width: 350px" size="small" v-model="dute" type="daterange"
 								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
 								@change="changetimer"></el-date-picker>
 						</div>
 						<div class="demo-input-suffix">
 							<el-button v-for="(item, index) in selectDay" :key="index" size="small"
 								:type="isSelected == index ? 'primary' : ''" @click="changeTime(index,item.id)">
 								{{ item.name }}
 							</el-button>
 						</div>
 					</el-row>
 				</div>
 				<el-row :gutter="10">
 					<el-col :span="12">
 						<div class="grid-content bg-purple">
 							<div class="main-cont bgwt pp20 mt10">
 								<div class="fund_item">
 									<div>总金额</div>
 									<div style="font-size: 26px;margin-top: 10px;">{{money.total_money}}</div>
 								</div>
 							</div>
 						</div>
 					</el-col>
 					<el-col :span="12">
 						<div class="grid-content bg-purple">
 							<div class="main-cont bgwt pp20 mt10">
 								<div class="fund_item">
 									<div>待结算</div>
 									<div style="font-size: 26px;margin-top: 10px;">{{money.Amount_settled}}</div>
 								</div>
 							</div>
 						</div>
 					</el-col>
 				</el-row>
 				<!--表格-->
 				<div class="tableMain">
 					<div class="tableTile">商户列表</div>
 					<el-table :data="tableData" style="width: 100%" min-height="250" border  max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
 						<el-table-column type="index" width="50" label="排序" align="center">
 						</el-table-column>
 						<el-table-column prop="shop_name" label="网店名称" align="center">
 						</el-table-column>
 						<el-table-column prop="agency_distribution" label="服务商分润" align="center">
 						</el-table-column>
 						<el-table-column prop="total_amount_online_trade" sortable label="网店交易金额" align="center">
 						</el-table-column>
 						<el-table-column prop="agency_distribution" label="分润收入" align="center">
 						</el-table-column>
 						<el-table-column prop="total_fenRun" label="店铺总分润" align="center">
 						</el-table-column>
 						<el-table-column label="操作" align="center">
 							<template slot-scope="scope">
 								<el-button type="text" size="small" @click="toDetail(scope.row)">详情</el-button>
 							</template>
 						</el-table-column>
 					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
 				</div>
 			</div>
 		</div>
 	</div>
 </template>

 <script>
 	import {
 		FenRunEarningsList,
 		channelAgentCommissionStatistics
 	} from "@/api/channel/platprofit"
 	export default {
 		data() {
 			return {
				loading:true,
 				dute: [],
 				search: {
 					time_status: '-1',
 					keyword: '',
 					start_time: '',
 					end_time: ''
 				},
 				selectDay: [{
 						id: 1,
 						name: "今天",
 					},
 					{
 						id: 2,
 						name: "昨天",
 					},
 					{
 						id: 3,
 						name: "上个月",
 					},
 					{
 						id: -1,
 						name: "全部",
 					},
 				],
 				isSelected: 3, //当前选择
 				tableData: [],
 				total: 0,
 				currentPage: 1, //初始页
 				pagesize: 10, //每页的数据
 				money: {
 					total_money: "",
 					Amount_settled: '',
 				},
 			}
 		},
 		mounted() {
 			//this.getCurentDay();
 			this.getList();
 			this.getData()
 		},
 		methods: {
 			// getCurentDay() {
 			// 	var now = new Date();
 			// 	var year = now.getFullYear(); //年   
 			// 	var month = now.getMonth() + 1; //月   
 			// 	var day = now.getDate();
 			// 	var clock = year + "-";
 			// 	if (month < 10) clock += "0";
 			// 	clock += month + "-";
 			// 	if (day < 10) clock += "0";
 			// 	clock += day + " ";
 			// 	this.clock = clock
 			// 	this.dute.push(clock);
 			// 	this.dute.push(clock);
 			// },
 			//获取列表
 			getList() {
 				let data = this.search;
 				data.page = this.currentPage;
 				data.perPage = this.pagesize;
 				data = this.tools.filterParams(data);
 				FenRunEarningsList(data).then(res => {
					this.loading=false;
 					this.total = res.data.count;
 					this.tableData = res.data.list;

 				})
 			},
 			//汇总数据
 			getData() {
 				channelAgentCommissionStatistics({}).then(res => {
 					this.money = res.data
 				})
 			},
 			//时间选择
 			changetimer(val) { //时间
 				if (val) {
					this.isSelected= '3';
					this.search.time_status='-1';
 					this.search.start_time = val[0] / 1000;
 					this.search.end_time = val[1] / 1000;
 					this.getList();
 					this.getData()
 				} else {
					this.isSelected= '3';
					this.search.time_status='-1';
 					this.search.start_time = '';
 					this.search.end_time = '';
 					this.getList();
 					this.getData()
 				}
 			},
 			// 改变选择事件
 			changeTime(index, id) {
 				this.isSelected = index;
				this.dute=[];
				this.search.start_time = '';
				this.search.end_time = '';
 				this.search.time_status = id;
 				this.getList();
 				this.getData()
 			},
 			//分页
 			handleSizeChange(size) {
 				this.pagesize = size;
 				// 掉接口
 				this.getList()
 			},
 			handleCurrentChange(currentPage) {
 				this.currentPage = currentPage;
 				// 掉接口
 				this.getList()
 			},
 			//查询
 			toSearch() {
 				//调接口
 				this.getList();

 			},
 			//重置
 			toReset() {
 				this.search.keyword = '';
 				this.search.source_type = '';
 				this.search.order_state = '';
 				//调接口
 				this.getList();
 				this.getData()
 			},
 			toDetail(row) {
 				this.$router.push({
 					path: "/sharebenefitadmin/sharebenefitdetail",
 					query: {
 						id: row.account_id
 					}
 				});
 			}
 		}
 	}
 </script>

 <style scoped="scoped" lang="scss">
 	.fund_item {
 		margin-left: 100px;
 		padding: 10px 0;

 		div {
 			font-size: 14px;
 			color: #000;
 			line-height: 24px;
 		}
 	}
 </style>
