<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-button size="small" type="primary" @click="toAdd" v-has="'添加'">添加资讯</el-button>
				</div>
				<div class="tableMain">
					<el-table :data="tableData" style="width: 100%" min-height="250" border  max-height="620px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="title" label="资讯名称" align="center">
						</el-table-column>
						<el-table-column prop="sort" label="排序值" align="center">
						</el-table-column>
						<el-table-column  label="推广图" align="center">
							<template slot-scope="scope">
								<img :src="scope.row.images"  style="width: 50px; height: 50px;"/>
							</template>
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<span class="zc" v-if="scope.row.status == '1'">正常</span>
								<span class="jy" v-else>禁用</span>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="创建时间" align="center">
						</el-table-column>
						<el-table-column label="操作" width="150" align="center">
							<template slot-scope="scope">
                                <el-button type="text" size="small" @click="toView(scope.row)" v-has="'查看'">查看</el-button>
                                <el-button type="text" size="small" @click="toEdit(scope.row)" v-has="'编辑'">编辑</el-button>
								<el-button type="text" size="small" @click="toDel(scope.row)" v-has="'删除'"><span style="color:red">删除</span></el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	const sha256 = require('js-sha256').sha256;
	import {
		isvalidPhone
	} from '@/utils/validate';
	import {
		getList,
        del
	} from '@/api/plat/account';
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入电话号码'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		data() {
			return {
				loading:true,
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				dialogVisible: false,
				uploadFlag: false,
				title: '添加账号',
				isadd:false,
				form: {
					username: '',
					account: '',
					password:'',
					head_portrait: '',
					role_id: [],
					status:'1'
				},
				rules: {
					username: [{
						required: true,
						message: '请填写名称',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请填写密码',
						trigger: 'blur'
					}],
					// account: [{
					// 		required: true,
					// 		trigger: 'blur',
					// 		validator: validPhone
					// 	}, //这里需要用到全局变量
					// ],
					role_id: [{
						type: 'array',
						required: true,
						message: '请选择角色',
						trigger: 'change'
					}],
				},
				account_platform_id:'',
				
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let data = {
					page: this.currentPage,
					perPage: this.pagesize,
				}
				delete data['keys_type'];
				data = this.tools.filterParams(data);
				getList(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list
				})
			},
			//添加
			toAdd() {
                this.$router.push({
					path: '/dobusiness/information/informationSet',
					query: {
						type: 'add'
					},
				})
			},

			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//禁用启用
			toDel(row) {
				this.tools.confirm('确定要删除吗？').then(res => {
					if (res) {
						del({consult_id:row.consult_id}).then(res=>{
							this.$message.success("删除成功！");
							this.getList()
						})
					}
				})
			},
            //查看
            toView(row){
                this.$router.push({
					path: '/dobusiness/information/informationSet',
					query: {
						type: 'view',
                        consult_id:row.consult_id
					},
				})
            },
			//编辑
			toEdit(row){
                this.$router.push({
					path: '/dobusiness/information/informationSet',
					query: {
						type: 'edit',
                        consult_id:row.consult_id
					},
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.avatar-uploader1 {
		border-radius: 4px 4px;
		border: 1px solid #ddd;
		background-color: #fff;
		width: 50px;
		overflow: hidden;
		height: 50px;
		line-height: 50px;

		.el-icon-plus {
			width: 50px;
			text-align: center;
			height: 50px;
			line-height: 50px;
			font-size: 24px;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

    .zc{
        color: #67C23A;
    }
    .jy{
        color: #F56C6C;
    }
</style>
