<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-row :gutter="10">
                    <el-col :span="6">
                        <el-card shadow="never">
                            <el-form label-width="110px" :model="formUser">
                                <el-form-item style="margin-bottom: 0px;">
                                    <div class="flex-align-center" style="margin-left:-60px">
                                        <div style="width:50px;height:50px;" class="block">
                                            <el-image v-if="formUser.head_portrait" style="width: 100%;height: 100%; display: block;"
                                                :src="formUser.head_portrait"
                                                :preview-src-list="[formUser.head_portrait]" />
                                           

                                        </div>
                                        <div class="ml10">
                                            <p>{{formUser.nickname}}</p>
                                            <p>{{formUser.mobile}}</p>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="生日：" style="margin-bottom: 0px;">
                                   
                                </el-form-item>
                                <el-form-item label="微信：" style="margin-bottom: 0px;">
                                    
                                </el-form-item>
                                <el-form-item label="注册时间：" style="margin-bottom: 0px;">
                                    {{formUser.created_at}}
                                </el-form-item>
                            </el-form>
                        </el-card>
                    </el-col>
                    <el-col :span="18">
                        <el-card shadow="never">
                            <div slot="header" class="clearfix">
                                <span>会员资产</span>
                            </div>
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <div class="assets_item">
                                        <p>累计储值</p>
                                        <p class="vals">{{formUser.earnest_money}}</p>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="assets_item">
                                        <p>储值余额</p>
                                        <p class="vals">{{formUser.available_money}}<el-button type="text" class="ml10" @click="toChange()">充值
                                            </el-button>
                                        </p>

                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="assets_item">
                                        <p>累计消费金额</p>
                                        <p class="vals">{{formUser.accumulative_total_money}}</p>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>
                </el-row>
                <el-card shadow="never" class="mt10">
                    <div slot="header" class="clearfix">
                        <span>交易统计 <span style="font-size:12px;color:#999">最近下单时间:{{formUser.created_at}}</span></span>
                        <el-button style="float: right;" size="small" @click="toOrder">查看订单</el-button>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <div class="assets_item wstyle">
                                <p>累计消费金额</p>
                                <p class="vals">{{formUser.accumulative_total_money}}</p>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="assets_item wstyle">
                                <p>累计订单数</p>
                                <p class="vals">{{formUser.accumulative_total_count}}</p>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="assets_item wstyle">
                                <p>累计退款金额</p>
                                <p class="vals">{{formUser.refund_amount}}</p>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
        </div>
        <!----充值弹框-->
        <el-dialog title="调整储值余额" :visible.sync="dialogVisible" width="25%">
            <el-form :model="forms" ref="formref" label-width="100px" class="demo-dynamic">
                <el-form-item prop="money" label="储值余额：" :rules="[
                  { required: true, message: '请输入金额', trigger: 'blur' },
                ]">
                    <el-input placeholder="请输入金额" v-model="forms.money" oninput="value=value.replace(/[^0-9.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" class="input-with-select" size="small">
                        <el-select v-model="forms.regulation_type" slot="prepend" placeholder="请选择">
                            <el-option label="增加" value="1"></el-option>
                            <el-option label="减少" value="2"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('formref')" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { shopUserInfo,shopUserCapital } from "@/api/merchant/users.js"
export default {
    data() {
        return {
            account_user_id: this.$route.query.account_user_id,
            formUser: {},
            dialogVisible: false,
            forms: {
                money: '',
                regulation_type: '1'
            },
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        getInfo() {
            let data = {
                account_user_id: this.account_user_id
            }
            shopUserInfo(data).then(res => {
                this.formUser = res.data;
            })
        },
        //充值
        toChange() {
            this.forms.money = '';
            this.dialogVisible = true

        },
        //去订单
        toOrder(){
            this.$router.push({
                path: '/ordergoodadmin/shopmallorder/shopmallorderlist',
                query: {
                    account_user_id: this.account_user_id
                }
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = this.forms;
                    data.account_user_id = this.account_user_id
                    shopUserCapital(data).then(() => {
                        this.$message({
                            message: '充值成功',
                            type: 'success'
                        });
                        this.dialogVisible = false;
                        this.getInfo()
                    })
                } else {
                    return false;
                }
            });
        }
 
    }
}
</script>
<style lang="scss" scoped>
.assets_item {
    background: #F0F8FF;
    border-radius: 4px;
    padding: 30px 30px;

    p {
        height: 25px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
    }

    p.vals {
        font-size: 20px;
        line-height: 30px;
    }
}

.assets_item.wstyle {
    background: #fff;
}

::v-deep.el-select .el-input {
    width: 100px;
}

::v-deep.input-with-select .el-input-group__prepend {
    background-color: #fff;
}
</style>