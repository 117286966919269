<template>
    <div class="infos">
        <div class="wrapper-main pds">
            <div class="wrapper-cont">
                <el-form ref="form" :model="form" label-width="120px">
                    <el-card shadow="never">
                        <div slot="header" class="clearfix">
                            <span>基础信息</span>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <div class="flex-align-center">
                                    <div style="width:50px;height:50px;">
                                        <el-image style="width: 100%;height: 100%;" :src="form.shop_logo"
                                            :preview-src-list="[form.shop_logo]" />
                                    </div>
                                    <span class="ml10">{{form.shop_name}}</span>
                                </div>
                                <el-form-item label="主营类目："><span
                                        v-if="form.marketing_trade_info">{{form.marketing_trade_info.trade_name}}</span>
                                </el-form-item>
                                <el-form-item label="所在地区：">{{(form.shop_province || '')+(form.shop_city ||
                                '')+(form.shop_county || '')}}</el-form-item>
                                <el-form-item label="详细地址：">{{form.shop_address_detail || ''}}</el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="姓名：">{{form.tele_name}}</el-form-item>
                                <el-form-item label="手机号：">{{form.service_tel}}</el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card shadow="never" class="mt10">
                        <div slot="header" class="clearfix">
                            <span>其他信息</span>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="营业时间：">
                                    <ul class="infinite-list"  style="overflow:auto">
                                        <li v-for="(item,index) in form.business_day" :key="index" class="infinite-list-item">{{ item.weekText }}：{{item.startTime}}~{{item.diurnalEndTime}}-{{item.overnightBeginTime}}~{{item.endTime}}</li>
                                    </ul>
                                </el-form-item>
                                <el-form-item label="服务电话：">{{form.tele_phone || ''}}</el-form-item>
                                <!-- <el-form-item label="营业电话："></el-form-item> -->
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="微信二维码：">
                                    <div style="width:70px;height:70px;" >
                                        <el-image style="width: 100%;height: 100%;" :src="form.wechat_qrcode" class="el_img" :preview-src-list="[form.wechat_qrcode]"/>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card shadow="never" class="mt10">
                        <div slot="header" class="clearfix">
                            <span>营业执照</span>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="营业执照：">
                                    <div style="width:70px;height:70px;" >
                                        <el-image style="width: 100%;height: 100%;" :src="form.business_license_images" class="el_img" :preview-src-list="[form.business_license_images]"/>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="法人："><span v-if="form.business_license_info">{{form.business_license_info.ENTERPRISE_OWNER}}</span></el-form-item>
                                <el-form-item label="住所："><span v-if="form.business_license_info">{{form.business_license_info.ENTERPRISE_REGISTER_ADDRESS}}</span></el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card shadow="never" class="mt10">
                        <div slot="header" class="clearfix">
                            <span>地址信息</span>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="退货地址：">
                                    <span v-if="form.refund_address">
                                        {{(form.refund_address.address || '')+(form.refund_address.full_address ||
                                '')}}
                                    </span>
                                </el-form-item>
                                <el-form-item label="联系人：">
                                    <span v-if="form.refund_address">{{form.refund_address.contact_name}}</span>
                                    </el-form-item>
                            </el-col>

                        </el-row>
                    </el-card>
                </el-form>
            </div>
        </div>
    </div>

</template>
<script>
import { getShopInfo } from "@/api/merchant/users.js"
export default {
    data() {
        return {
            form: {

            }
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        getInfo() {
            getShopInfo({}).then(res => {
                console.log(res.data, "res.data")
                this.form = res.data

            })
        }
    }
}
</script>
<style lang="scss" scoped>
.infos {
    padding-bottom: 70px;
}
</style>