<!--角色编辑-->

<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<div class="storemain">
					<div class="add">

						<el-row class="title">
							账号信息
						</el-row>
						<el-form :label-position="labelPosition" style="margin-left: 5%" label-width="90px"
							:model="formLabelAlign">
							<el-form-item label="角色：">
								<el-input size="small" v-model="formLabelAlign.role_nickname" style="max-width:500px"
									placeholder="请输入角色名称"></el-input>
							</el-form-item>
						</el-form>
						<el-row class="title">
							权限设置
						</el-row>
						<div class="treeclass">
							<el-tree ref="tree" :check-strictly="checkStrictly" :data="formLabelAlign.permission"
								show-checkbox node-key="permission_id" @node-expand="handleExpand"
								@check-change="handleCheckChange" :props="defaultProps">
							</el-tree>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="save-cont">
			<div class="save-btn" @click="submitForm()" size="small">提交</div>
		</div>
	</div>
</template>

<script>
	import {
		authoritysettingDetail,
		authoritysettingmenuList,
		authoritysettingModify
	} from '@/api/plat/setting.js'
	export default {
		name: 'looks',
		data() {
			return {
				labelPosition: 'right',
				defaultProps: {
					children: 'children',
					label: 'main_part_name'
				},


				role_id: this.$route.query.id,
				formLabelAlign: {
					role_nickname: '',
					permissionArray: [],
					state: 'Y'
				},
				ruleForm: '',
				data: [],
				selectIds: [],
				checkStrictly: false,
			}
		},
		mounted() {
			// this.changeCss();
			this.getDetail();
			// this.List();
		},
		methods: {
			async submitForm() { //保存
				this.formLabelAlign.permissionArray = this.formLabelAlign.permissionArray.toString()
				const params = {
					role_id: this.$route.query.id,
					role_nickname: this.formLabelAlign.role_nickname,
					state: 'Y',
					permissionArray: this.formLabelAlign.permissionArray
				}
				try {
					let data = await authoritysettingModify(params)

					if (data.return_code === "SUCCESS") {
						this.$message({
							message: '修改成功！',
							type: 'success'
						});
						this.$router.push('/settingadmin/jurisdictionsetting/roleList')
					}
				} catch (error) {}
			},

			async getDetail() {
				//查看详情
				const params = {
					role_id: this.$route.query.id
				}
				try {
					let data = await authoritysettingDetail(params)
					this.formLabelAlign = data.data;
					let that = this;
					that.checkStrictly = true //重点：给数节点赋值之前 先设置为true
					that.$nextTick(() => {
						let pp = that.changeTree(data.data.permission);
						that.$refs.tree.setCheckedKeys(pp);
						that.checkStrictly = false //重点： 赋值完成后 设置为false
					})
				} catch (error) {};
			},
			changeTree(val) {
				//获取传进来的
				var self = this;
				var ids = [];
				if (val.length !== 0) {
					val.forEach(item => {
						if (item.exist == true) {
							ids.push(item.permission_id);
						}
						if (item.children) {
							ids.push([...self.changeTree(item.children)]);
						}
					});
					return ids.flat(Infinity);
				}
			},
			getCheckedKeys() {},
			handleCheckChange(data, checked, indeterminate) {
				this.mentId = this.$refs.tree
					.getCheckedKeys()
					.concat(this.$refs.tree.getHalfCheckedKeys())
				this.mentId = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())

				this.formLabelAlign.permissionArray = this.mentId.filter(function(item) {
					if (item !== undefined) {
						return item;
					}
				})
			},
			handleExpand() { //节点被展开时触发的事件
				//因为该函数执行在renderContent函数之前，所以得加this.$nextTick()
				// this.$nextTick(() => {
				// 	this.changeCss();
				// })
			},
			renderContent(h, {
				node,
				data,
				store
			}) { //树节点的内容区的渲染 Function
				let classname = "";
				// 由于项目中有三级菜单也有四级级菜单，就要在此做出判断
				if (node.level === 2) {
					classname = "foo";
				}
				// if (node.level === 1 && node.childNodes.length === 0) {
				// 	classname = "foo";
				// }
				return h(
					"p", {
						class: classname
					},
					node.label
				);
			},
		},


	}
</script>

<style lang="scss" scoped>
	.add {
		height: 100%;
		padding: 20px 60px;

		.title {
			height: 50px;
			left: 50px;
			font-size: 16px;
			color: #000;
		}

		::v-deep .el-textarea__inner {
			min-height: 150px !important;
		}

		.bottom {
			margin-left: 30%;
			margin-top: 10%;

			::v-deep .el-button {
				margin-right: 5%;
			}
		}
	}

	.storemain {
		background: #F2F2F6;
		border-radius: 4px;
		padding: 10px 10px;
		font-size: 14px;
	}

	.treeclass {
		background-color: #fff;
		padding: 30px 10px;
		margin-left: 8%;
		border-radius: 4px;
	}
</style>
