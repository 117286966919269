<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card shadow="never">
                    <el-row class="all_search_list" style="margin-bottom:0">
                        <div class="demo-input-suffix">
							<el-select v-model="status" size="small">
                                <el-option label="充值成功" value="1"></el-option>
                                <el-option label="未支付" value="2"></el-option>
                            </el-select>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder">搜索</el-button>
							<el-button size="small" @click="toResetOrder">重置</el-button>
						</div>
                    </el-row>
                </el-card>
                <el-table class="mt10" size="small" ref="multipleTable" :data="tableData" border tooltip-effect="dark" style="width: 100%"  max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column  label="会员" align="center">
							<template slot-scope="scope">
                            <div class="flex-align-center ">
                                <div style="width:70px;height:70px;">
                                    <el-image v-if="scope.row.head_portrait" style="width: 100%;height: 100%;" :src="scope.row.head_portrait"
                                        :preview-src-list="[scope.row.head_portrait]" />
                                </div>
                                <span class="ml10">{{scope.row.nickname}}</span>
                            </div>
                        </template>
						</el-table-column>
						<el-table-column prop="renew_price" label="充值金额" align="center"></el-table-column>
						<el-table-column prop="created_at" label="充值时间"  align="center"></el-table-column>
						<el-table-column prop="pay_order_number" label="充值单号"  align="center" ></el-table-column>
						<el-table-column  label="状态"  align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.status==1" style="color:#67C23A">充值成功</span>
								<span v-if="scope.row.status==2" style="color:#F56C6C">未支付</span>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
	import { calculateLog } from "@/api/merchant/users.js"
    export default{
        data(){
            return{
                status:'',
                loading:true,
                tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
            }
        },
        mounted() {
			this.getList()
		},
        methods:{
            getList(){
				let data={
					page:this.currentPage,
					perPage:this.pagesize,
					status:this.status
				}
				calculateLog(data).then(res=>{
					this.loading=false;
					this.total=res.data.count;
					this.tableData=res.data.list;
					
				})
			},
            searchOrder(){
                this.currentPage = 1;
				//调接口
				this.getList()
            },
            toResetOrder(){
                this.currentPage = 1;
				this.status = '';
				this.getList()
            },
            //分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 调接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 调接口
				this.getList()
			},
 
        }
    }
</script>