<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<div class="main">
					<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px">
						<el-form-item label="联系人" prop="contact_name">
							<el-input size="small" class="inputwidth" v-model="ruleForm.contact_name"></el-input>
						</el-form-item>
						<el-form-item label="联系电话" prop="mobile">
							<el-input size="small" maxlength="11" class="inputwidth" v-model="ruleForm.mobile">
							</el-input>
						</el-form-item>
						<el-form-item label="地区:" prop="alladdress">
							<el-cascader style="width: 300px;" size="small" class="labelWidth" :options="options"
								collapse-tags :props="{multiple: false }" clearable @change="handleChange"
								v-model="ruleForm.alladdress" ref="cascader">
							</el-cascader>
						</el-form-item>
						<el-form-item label="详细地址:" prop="full_address">
							<el-input class="inputwidth" placeholder="详细地址" v-model="ruleForm.full_address"
								size="small"></el-input>
						</el-form-item>
						<el-form-item label="邮政编码:">
							<el-input class="inputwidth" oninput="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
								placeholder="邮政编码"  maxlength="6" v-model="ruleForm.postcode" size="small"></el-input>
						</el-form-item>
						<el-form-item>
							<el-checkbox v-model="ruleForm.is_delivery_default">设为默认发货地址</el-checkbox>
							<el-checkbox v-model="ruleForm.is_return_default">设为默认退货地址</el-checkbox>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="addressSubmit('ruleForm')">提交</div>
			<div class="cancel-btn" @click="cancelSubmit()">取消</div>
		</div>
	</div>
</template>

<script>
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	import {
		addAddress,
		getAddressInfo,
		editAddress
	} from "@/api/plat/setting.js";
	import {
		isvalidPhone
	} from '@/utils/validate'
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入电话号码'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		data() {
			return {
				options: regionData,
				ruleForm: {
					alladdress: [],
					contact_name: '',
					mobile: '',
					province_id: '',
					city_id: '',
					district_id: '',
					adddress: '',
					full_address: '',
					postcode: '',
					is_delivery_default: '',
					is_return_default: '',

				},
				rules: {
					contact_name: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					mobile: [{
							required: true,
							trigger: 'blur',
							validator: validPhone
						}, //这里需要用到全局变量
					],
					// address: [{
					// 	type: 'array',
					// 	required: true,
					// 	message: '请选择',
					// 	trigger: 'change'
					// }],
					full_address: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
				},

			}
		},
		mounted() {
			if (this.$route.query.id) {
				this.addressInfo();
			}

		},
		beforeDestroy() {
			localStorage.removeItem('commodityId')
		},
		methods: {
			handleChange(area) {
				if (area) {
					this.ruleForm.province_id = area[0];
					this.ruleForm.city_id = area[1];
					this.ruleForm.district_id = area[2];
					this.ruleForm.address = CodeToText[this.ruleForm.province_id] + '-' + CodeToText[this.ruleForm
						.city_id] + '-' + CodeToText[this.ruleForm.district_id]
				}
			},
			addressInfo() {
				let data = {
					address_id: this.$route.query.id
				}
				getAddressInfo(data).then(res => {
					if (res.return_code == "SUCCESS") {
						this.ruleForm = res.data;
						if (this.ruleForm.is_delivery_default == 0) {
							this.ruleForm.is_delivery_default = false
						} else {
							this.ruleForm.is_delivery_default = true
						};
						if (this.ruleForm.is_return_default == 0) {
							this.ruleForm.is_return_default = false
						} else {
							this.ruleForm.is_return_default = true
						};
						this.ruleForm.alladdress = (res.data.province_id + ',' + res.data.city_id + ',' + res.data
							.district_id).split(",");

					}
				})
			},
			addressSubmit(ruleForm) {
				this.$refs[ruleForm].validate((valid) => {
					if (valid) {
						if (this.$route.query.id) {
							let data = JSON.parse(JSON.stringify(this.ruleForm));
							data.address_id = this.$route.query.id;
							if (data.is_delivery_default == true) {
								data.is_delivery_default = '1'
							} else {
								data.is_delivery_default = '0'
							};
							if (data.is_return_default == true) {
								data.is_return_default = '1'
							} else {
								data.is_return_default = '0'
							};
							delete data['alladdress'];
							data = this.tools.filterParams(data);
							editAddress(data).then(res => {
								if (res.return_code == "SUCCESS") {
									this.$message({
										message: '编辑成功',
										type: 'success',
										duration: 2000
									});
									this.$router.go(-1);
									localStorage.setItem('addressBack', 1)
								}
							})
						} else {
							let data = this.ruleForm;
							if (data.is_delivery_default == true) {
								data.is_delivery_default = '1'
							} else {
								data.is_delivery_default = '0'
							};
							if (data.is_return_default == true) {
								data.is_return_default = '1'
							} else {
								data.is_return_default = '0'
							};
							delete data['alladdress'];
							data = this.tools.filterParams(data);
							addAddress(data).then(res => {
								if (res.return_code == "SUCCESS") {
									this.$message({
										message: '添加成功',
										type: 'success',
										duration: 2000
									});

									// this.$router.push('/settingadmin/distributionsettings')
									this.$router.go(-1);
									localStorage.setItem('addressBack', 1)
								}
							})
						}

					} else {
						return false;
					}
				});

			},
			cancelSubmit() {
				this.$router.go(-1);
				localStorage.setItem('addressBack', 1)
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.main {
		width: 100%;
		background: #F2F2F6;
		border-radius: 4px;
		padding: 20px 10px;
	}

	.inputwidth {
		width: 300px;
	}
</style>
