<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-row type="flex" class="row-bg" justify="space-between">
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<el-button v-for="(item, index) in selectDay" :key="index" size="small"
									:type="isSelected == index ? 'primary' : ''" @click="changeTime(index)">
									{{ item.name }}
								</el-button>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple" style="text-align: right;">
								<el-button type="primary" size="small" @click="toWithdrow">提现</el-button>
								<el-button type="primary" size="small" @click="toRecharge">充值</el-button>
							</div>
						</el-col>
					</el-row>
				</div>
				<el-row :gutter="10">
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="main-cont bgwt pp20 mt10">
								<div class="fund_item">
									<div>余额</div>
									<div class="monval">{{ parseFloat(withdrawData.balance).toFixed(2)}}</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="main-cont bgwt pp20 mt10">
								<div class="fund_item">
									<div>待审核金额</div>
									<div class="monval" style="color: #E6A23C;">{{ parseFloat(withdrawData.to_reviewed).toFixed(2)}}</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="main-cont bgwt pp20 mt10">
								<div class="fund_item">
									<div>已审核金额</div>
									<div  class="monval">{{ parseFloat(withdrawData.reviewed).toFixed(2)}}</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="main-cont bgwt pp20 mt10">
								<div class="fund_item">
									<ElPopover :name="popover_audit_money_name" :title="popover_audit_money_title"
										:content="popover_audit_money_content">
									</ElPopover>
									<div  class="monval">{{ parseFloat(withdrawData.earnest_money).toFixed(2)}}</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
				<div class="main-cont bgwt mt10">
					<el-table :data="tableData" style="width: 100%" min-height="250" border  max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="withdrawal_way" label="提现方式" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.pay_type == '1'" style="color: #F56C6C;">微信</span>
								<span v-else>支付宝</span>
							</template>
						</el-table-column>
						<el-table-column prop="money" label="提现金额" align="center" sortable>
						</el-table-column>
						<el-table-column prop="service_fee" label="手续费" align="center" sortable>
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
							<div v-if="scope.row.examine_status == 0">
								待审核
							</div>
							<div  v-else-if="scope.row.examine_status == 1"> 
								<span v-if="scope.row.pay_status == 0">未支付</span>
								<span v-else-if="scope.row.pay_status == 1">已支付</span>
								<span v-else-if="scope.row.pay_status == 2">提现失败</span>
								<span v-else>已撤销</span>
							</div>
							<div  v-else-if="scope.row.examine_status == 2"> 
								已拒绝
							</div>
							</template>
						</el-table-column>
						<el-table-column prop="reason" label="失败原因" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="提交时间" align="center">
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<!--充值-->
		<el-dialog title="充值金额" :visible.sync="dialogRechargeVisible" width="30%" @close="closeCharge">
			<div class="mb10">
				<el-form :model="recharge" ref="recharge" type="number" label-width="100px"
					class="demo-ruleForm formBox">
					<el-form-item label="充值金额:" prop="money" :rules="[
					  { required: true, message: '充值金额不能为空'}]">
						<el-input size="small" oninput="value=value.replace(/[^\d.]/g,'');if(value==0)value=''" v-model="recharge.money" @change="inputMoney" class="inputWidth">
						</el-input>
					</el-form-item>
					<el-form-item label="支付类型">
						<el-radio v-model="recharge.payType" @change="changeRadio" label="1">微信</el-radio>
						<el-radio v-model="recharge.payType" @change="changeRadio" label="2">支付宝</el-radio>
					</el-form-item>
					<el-form-item v-if="recharge.money">
						<div class="qr_code_pay">
							<div v-if="recharge.payType==1">
								<img v-if="recharge_qrCode" :src="recharge_qrCode" width="95" height="95" style="overflow:hidden;" />
							</div>
							<div v-if="recharge.payType==2">
								<iframe :srcdoc="recharge_qrCode" frameborder="no" border="0" marginwidth="0"
									marginheight="0" scrolling="no" width="95" height="95" style="overflow:hidden;">
								</iframe>
							</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitPay" size="small">确认支付完成</el-button>
			</span>
		</el-dialog>
		<!--提现-->
		<el-dialog title="提现" :visible.sync="dialogWithdrawVisible" width="30%">
			<el-form :model="withdrawForm" ref="withdrawForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="可提现金额:">{{withdrawData.balance}}元</el-form-item>
				<el-form-item label="提现方式:">
					<!-- <el-radio v-model="withdrawForm.type" label="1">微信</el-radio> -->
					<el-radio v-model="withdrawForm.type" label="2">支付宝</el-radio>
				</el-form-item>
				<el-form-item label="提现金额:" prop="money" :rules="[
					  { required: true, message: '提现金额不能为空'}]">
					<el-input oninput="value=value.replace(/[^\d.]/g,'');if(value==0)value=''" size="small" style="width: 250px;" placeholder="不能大于可提现金额"
						v-model.number="withdrawForm.money" >
					</el-input>
				</el-form-item>
			<!-- 	<el-form-item label="二维码:">
					<div style="height: auto;">
						<div class="qr_code">
							<iframe :srcdoc="withdraw_zfb_qrCode" frameborder="no" border="0" marginwidth="0"
								marginheight="0" scrolling="no" width="95" height="95" style="overflow:hidden;">
							</iframe>
							<wxlogin self_redirect="false" :appid="code.appid" :scope="'snsapi_login'"
								:state="code.state" :theme="'black'" :redirect_uri="code.redirect_uri"
								:href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIwMHB4O30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9'">
							</wxlogin>
						</div>
					</div>
				</el-form-item> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
			   <el-button type="primary" size="small" @click="sureZFBdraw()">确认</el-button>
			   <el-button  size="small" @click="dialogWithdrawVisible = false">取消</el-button>
			 </span>
		</el-dialog>
		<!--提现结果-->
		<el-dialog title="提现" :visible.sync="dialogResultVisible" width="30%">
			<div class="statepic">
				<img src="@/assets/img/paysuccess.png" />
			</div>
			<div class="paystate">提交成功</div>
			<div class="paybtn">
				<el-button type="primary" size="small" @click="paySuccess">知道了</el-button>
			</div>
		</el-dialog>
		<!--微信二维码-->
		<el-dialog title="扫码" :visible.sync="dialogQRcodeVisible" width="50%">
			<div style="width: 100%; min-height: 400px;position: relative;">
				<iframe :src="QRcodeUrl" id="frame" frameborder="no" border="0" marginwidth="0" marginheight="0"
					scrolling="no" width="300" height="500"
					style="overflow:hidden;position:absolute;left:50%;top:50%;margin-left:-150px;margin-top:-250px">
				</iframe>
			</div>
		</el-dialog>
		<el-dialog title="扫码" :visible.sync="dialogzfbVisible" width="50%">
			<div style="width: 100%; min-height: 400px;position: relative;">
				<div v-html='withdraw_zfb_qrCode'></div>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	import wxlogin from 'vue-wxlogin'
	import {
		getWxWithdrawalPageList,
		getShopWithdrawalData,
		rechargeAmount,
		getVirtualOrderStatus,
		shopApplyWithdrawal,
		getAuthorizationUrl,
		userLoginAuthorization,
		WithdrawalDispose,
		aliPayWithdrawal,
		getResultData,
	} from '@/api/merchant/finance.js'
	import ElPopover from '../../../../common/popover.vue'
	export default {
		components: {
			ElPopover,
			wxlogin
		},
		data() {
			return {
				loading:true,
				code: {},
				selectDay: [{
						id: '',
						name: "全部",
					},
					{
						id: '0',
						name: "待审核",
					},
					{
						id: '1',
						name: "已通过",
					},
					{
						id: '2',
						name: "被驳回",
					},
				],
				dialogzfbVisible:false,
				isSelected: 0, //当前选择
				popover_audit_money_name: '充值金额',
				popover_audit_money_title: '充值金额',
				popover_audit_money_content: '充值的金额不可提现哦',
				tableData: [{
					withdrawal_way: '微信',
					withdrawal_money: '300',
					service_charge: '15',
					state: '1',
					cause_failure: '巴拉巴拉',
					submission_time: '2020-02-02 20:20:23'
				}],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				dialogRechargeVisible: false,
				recharge: {
					money: '',
					payType: '1'
				},
				dialogPayVisible: false,
				withdrawForm: {
					type: '2',
					money: ''
				},

				dialogWithdrawVisible: false,
				dialogResultVisible: false,
				qianHtml: '',
				recharge_qrCode: '',
				order_nuber: '',
				withdrawData: {},
				selectState: '',
				withMoney: '',
				dialogQRcodeVisible: false,
				QRcodeUrl: '',
				timer: '',
				QRcode: '',
				QRstate: '',
				withdraw_zfb_qrCode:''

			}
		},
		mounted() {
			this.getList();
			this.getData();

		},
		watch: {
			"$route.query": {
				handler(val) {
					this.withdrawForm.money = val.money;
					this.QRcode = val.auth_code;
					this.QRstate = val.state;
					this.withdrawForm.money=val.money;
					if (this.QRcode && this.QRstate) {
						this.sureWithdraw()
					}
				},
				immediate: true
			}
		},
		methods: {
			sureZFBdraw(){
				this.changeWidthdraw();
			},
			//获取列表
			getList() {
				let params = {
					state: this.selectState,
					page: this.currentPage,
					perPage: this.pagesize
				}
				params = this.tools.filterParams(params);
				getWxWithdrawalPageList(params).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count
				})
			},
			//获取数据
			getData() {
				getShopWithdrawalData({}).then(res => {
					this.withdrawData = res.data;
					this.withMoney = res.data.balance;
				})
			},
			// 改变选择事件
			changeTime(index) {
				this.isSelected = index;
				if (index == '0') {
					this.selectState = ''
				} else if (index == 1) {
					this.selectState = '0'
				} else if (index == 2) {
					this.selectState = '1'
				} else if (index == 3) {
					this.selectState = '2'
				}
				this.getList();
				this.getData();
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList();
				this.getData();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList();
				this.getData();
			},

			//充值弹框
			toRecharge() {
				this.recharge.money='';
				this.recharge_qrCode = '';
				this.dialogRechargeVisible = !this.dialogRechargeVisible
			},
			closeCharge() {
				this.recharge.money = '';
				this.recharge_qrCode = ''
			},
			getRechargeCode() {
				let data = {
					order_type: '1',
					payType: this.recharge.payType,
					money: this.recharge.money,
				}
				rechargeAmount(data).then(res => {
					this.recharge_qrCode = res.data.images;
					this.order_nuber = res.data.order_nuber
				})
			},
			//输入金额失去焦点
			inputMoney(val) {
				if (val) {
					this.recharge.money = val;
					this.getRechargeCode()
				}

			},
			//选择支付方式
			changeRadio(val) {
				this.recharge.payType = val;
				if (this.recharge.money) {
					this.getRechargeCode();
				}
			},
			//确认支付
			submitPay() {
				let params = {
					order_nuber: this.order_nuber
				}
				getVirtualOrderStatus(params).then(res => {
					if (res.data == 1) {
						this.$message.error("请支付！")
					} else if (res.data == 2) {
						this.$message.success("支付成功！");
						this.dialogRechargeVisible = !this.dialogRechargeVisible;
						this.getList();
						this.getData();
					} else if (res.data == 4) {
						this.$message.error("支付失败！")
						this.dialogRechargeVisible = !this.dialogRechargeVisible;
					}
				})
			},
			//去提现
			toWithdrow() {
				// this.$alert('此功能正在开通中', '', {
				//           confirmButtonText: '确定'
				       
				//         })
				
				this.withdrawForm.money = '';
				this.code.appid = '';
				this.QRcode = '';
				this.QRstate = '';
				this.dialogWithdrawVisible = !this.dialogWithdrawVisible;
			},
			//提现输入失去焦点
			changeWidthdraw() {
				let data = {
					redirect_uri: window.location.protocol + '//' + window.location.host +'/financialmanageadmin/merchantswithdrawal?fromType=swithdrawal&money=' + this.withdrawForm
						.money,
				}
				userLoginAuthorization(data).then(res => {
					//this.withdraw_zfb_qrCode = res.data;
					window.location.href=res.data;
				})
			},
			paySuccess() {
				this.$router.push({
					path: '/financialmanageadmin/merchantswithdrawal'
				});
				this.dialogWithdrawVisible = !this.dialogWithdrawVisible;
				this.dialogResultVisible = !this.dialogResultVisible;
			},
			//确认提现
			sureWithdraw() {
				if(this.withdrawForm.money == ''){
					this.$message.error("提现金额不能为空");
					return
				}
				//弹出扫码
				let data = {
					code: this.QRcode,
					state: this.QRstate,
					money: this.withdrawForm.money,
					payType: this.withdrawForm.type
				}
				aliPayWithdrawal(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.$router.push({
							path: '/financialmanageadmin/merchantswithdrawal'
						});
						this.dialogWithdrawVisible = !this.dialogWithdrawVisible;
						this.dialogResultVisible = !this.dialogResultVisible;
						
						this.getList();
						this.getData();
					} 
				}).catch(err=>{
					this.$message.error(err.return_msg);
					this.$router.push({
						path: '/financialmanageadmin/merchantswithdrawal'
					});
					this.dialogWithdrawVisible = false;
					this.getList();
					this.getData();
					
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.fund_item {
		margin-left: 20px;

		div {
			font-size: 14px;
			font-weight: 400;
			color: #000;
			line-height: 34px;
		}
		.monval{
			font-size: 26px;
		}
	}

	.qr_code {
		width: 200px;
		height: 250px;
	}
	.qr_code_pay{
		width: 100px;
		height: 100px;
	}

	.statepic {
		width: 130px;
		height: 130px;
		margin: 0 auto;
		>img{
			width: 100%;
			height: 100%;
		}
	}

	.paystate {
		margin-top: 10px;
		text-align: center;
		color: #333;
		font-size: 16px;
	}

	.paybtn {
		width: 100%;
		margin-top: 15px;
		text-align: center;
	}

	.impowerBox .qrcode {
		width: 200px;
	}

	.impowerBox .title {
		display: none;
	}

	.impowerBox .info {
		width: 200px;
	}

	.status_icon {
		display: none
	}

	.impowerBox .status {
		text-align: center;
	}
	::v-deep.el-dialog{
		padding-bottom: 30px;
	}
</style>
