import axios from '@/config/httpConfig'
import qs from  'qs'
// 商机孵化列表
export const businessIncubatorList=(params)=> {
  return axios('post','Account/channel/businessIncubatorList', params)
}
// 行业列表
export const getTradeList=(params)=> {
  return axios('get','Marketing/channel/getTradeList', params)
}
// 行业列表新
export const getShopTradeList=(params)=> {
  return axios('get','Marketing/channel/getShopTradeList', params)
}
// 获取商机孵化信息
export const getBusinessIncubatorInfo=(params)=> {
  return axios('post','Account/channel/getBusinessIncubatorInfo', params)
}
// （创建/修改）商机孵化信息
export const createOrUpBusinessIncubator=(params)=> {
  return axios('post','Account/channel/createOrUpBusinessIncubator', params)
}
// 指定商机孵化过程联系人列表
export const businessIncubatorLinkmanList=(params)=> {
  return axios('post','Account/channel/businessIncubatorLinkmanList', params)
}
// 商机孵化过程联系人（添加或修改）
export const createOrUpBusinessIncubatorLinkman=(params)=> {
  return axios('post','Account/channel/createOrUpBusinessIncubatorLinkman', params)
}
// 指定商机孵化处理过程
export const followRemark=(params)=> {
  return axios('post','Account/channel/followRemark', params)
}
