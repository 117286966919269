<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20" v-has="'添加'">
					<el-button type="primary" size="small" plain @click="addPlugin">添加网店</el-button>
				</div>
			
				<!--表格-->
				<div class="tableMain">
					<div class="tableTile">商户列表</div>
					<el-table :data="tableData" style="width: 100%" min-height="250" border max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="shop_name" label="商户名称" align="center">
						</el-table-column>
						<el-table-column prop="account_shop_info.account" label="登录账号" align="center">
						</el-table-column>
						<el-table-column label="网店类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_entity == 1">线上门店</span>
								<span v-else>实体门店</span>
							</template>
						</el-table-column>
						<el-table-column prop="account_level_info.shop_level_name" label="网店等级" align="center">
						</el-table-column>
						<el-table-column prop="account_shop_info.begin_time" label="启用时间" align="center">
						</el-table-column>
						<el-table-column prop="account_shop_info.end_time" label="过期时间" align="center">
						</el-table-column>
						<el-table-column prop="tele_phone" label="联系电话" align="center">
						</el-table-column>
						<el-table-column prop="address" label="地址信息" align="center" width="150">
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.status == 1">启用</span>
								<span v-else-if="scope.row.status == 2">禁用</span>
								<span v-else-if="scope.row.status == 3">审核中</span>
								<span v-else-if="scope.row.status == 4">待续费</span>
							</template>
						</el-table-column>
						<el-table-column fixed="left" label="操作" width="220" align="center">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toEdit(scope.row)" v-has="'编辑'">
									编辑
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getShopList,
		shopCommissionList,
		getStoreManagerPrice,
		giveShop
	} from '@/api/plat/business'

	export default {
		data() {
			return {
				loading:true,
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			// 列表数据
			getList() {
				let data = {
					system:'Y',
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data)
				getShopList(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
						this.tableData.forEach(item => {
							if (item.account_shop_info) {
								item.account_shop_info.begin_time = this.tools.timeFormate((item
									.account_shop_info
									.begin_time) * 1000);
								item.account_shop_info.end_time = this.tools.timeFormate((item
									.account_shop_info
									.end_time) * 1000);
							}
							item.address = (item.shop_province == null ? "" : item.shop_province) + (item
								.shop_city == null ? "" : item.shop_city) + (item.shop_county == null ?
								"" : item.shop_county) + (item.shop_address_detail == null ? "" : item
								.shop_address_detail);

						})
				}).catch(err => {
					this.$message.error(err.return_msg)
				})

			},
			toSearch() {
				this.getList()
			},
			toReset() {
				this.shop_name = '';
				this.getList()
			},
			//网店列表分页
			handleSizeChange(size) {
				this.currentPage = 1
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
			//添加门店
			addPlugin() {
				this.$router.push({
					path: '/clientadmin/modelstore/modelstoreadd',
					query: {
						type: 'add'
					}
				})
			},
			//编辑
			toEdit(row) {
				this.$router.push({
					path: '/clientadmin/modelstore/modelstoreadd',
					query: {
						type: 'eduit',
						id: row.shop_id
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.el-col {
		border-radius: 4px;
	}

	.grid-content {
		background: #fff;
		padding: 30px 30px;
	}

	.monval {
		font-size: 26px;
	}
</style>
