import axios from '@/config/httpConfig'
import qs from  'qs'
//获取当前登录者下级账号
export const platformAccountList=(data)=> {
    return axios('get','/Account/shop/shopList', data);
}
//平台账号创建
// export const createPlatformAccount=(data)=>  {
//     return axios('post','/Account/platform/createPlatformAccount', data);
// }

//平台账号创建
export const createPlatformAccount=(data)=>  {
    return axios('post','Account/shop/saveShopSubmit', data);
}
//获取角色列表
export const getRoleList=(params)=>  {
	return axios('get','/Permission/platform/getRoleList', params)
}
//获取平台登录者账号信息
export const getPlatformAccountInfo=(params)=>  {
	return axios('get','/Account/shop/shopInfo', params)
}
//修改指定平台账号信息
export const revampAssignAccountInfo=(data)=>  {
    return axios('post','/Account/shop/editShopInfo', data);
}
//删除账号
export const remPlaformAccount=(data)=>  {
    return axios('post','/Account/platform/remPlaformAccount', data);
}
//资讯列表
export const getList=(data)=>  {
    return axios('get','/api/Activity/getList', data);
}

//资讯删除
export const del=(data)=>  {
    return axios('get','/api/Activity/del', data);
}

//资讯新增
export const save=(data)=>  {
    return axios('post','/api/Activity/save', data);
}

//资讯新增
export const getInfo=(data)=>  {
    return axios('get','/api/Activity/getInfo', data);
}

