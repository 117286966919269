<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<div class="all_search_list" style="margin-bottom: 0;">
						<div class="demo-input-suffix">
							<el-input prefix-icon="el-icon-search" v-model="search.keyword" class="inputWidth"
								placeholder="账户/名称" size="small" type="text"  @keyup.enter.native="toSearch" clearable>
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-select size="small" v-model="search.state" placeholder="全部状态">
								<el-option label="全部" value=""></el-option>
								<el-option label="待审核" value="1"></el-option>
								<el-option label="已支付" value="2"></el-option>
								<el-option label="已拒绝" value="3"></el-option>
								<el-option label="提现失败" value="4"></el-option>
								<!-- <el-option label="已撤销" value="5"></el-option> -->
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-select size="small" v-model="search.type" placeholder="用户类型">
								<el-option label="全部" value=""></el-option>
								<el-option label="服务商" value="1"></el-option>
								<el-option label="门店" value="2"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
							<el-button size="small"  @click="toReset">重置</el-button>
						</div>
					</div>
				</div>
				<div class="main-cont bgwt mt10">
					<el-table :data="tableData" border style="width: 100%"  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="user_type" label="用户类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.account_type == 1">服务商</span>
								<span v-else-if="scope.row.account_type == 2">门店</span>
								<span v-else-if="scope.row.account_type == 3">用户</span>
							</template>
						</el-table-column>
						<el-table-column prop="account" label="账户" align="center">
						</el-table-column>
						<el-table-column label="提现方式" align="center">
							<template  slot-scope="scope">
								<span v-if="scope.row.pay_type == 1">微信</span>
								<span v-else>支付宝</span>
							</template>
						</el-table-column>
						<el-table-column prop="money" label="提现金额" align="center"></el-table-column>
						<el-table-column prop="service_fee" label="提现手续费" align="center"></el-table-column>
						<el-table-column prop="username" label="名称" align="center">
						</el-table-column>
						<el-table-column  label="电话" align="center" prop="account">
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.examine_status == 0">
									待审核
								</div>
								<div  v-else-if="scope.row.examine_status == 1"> 
									<span v-if="scope.row.pay_status == 0">未支付</span>
									<span v-else-if="scope.row.pay_status == 1">已支付</span>
									<span v-else-if="scope.row.pay_status == 2">提现失败</span>
									<span v-else>已撤销</span>
								</div>
								<div  v-else-if="scope.row.examine_status == 2"> 
									已拒绝
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="创建时间" align="center"></el-table-column>
						<el-table-column fixed="right" label="操作" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.examine_status == 0">
									<el-button size="small" type="text" v-has="'通过'"  @click="toPass(scope.row)">通过</el-button>
									<el-button size="small" type="text" v-has="'拒绝'" @click="toRefuse(scope.row)">拒绝</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
		<!--拒绝原因弹框-->
		<el-dialog title="拒绝原因" :visible.sync="dialogVisible" width="25%" >
			<div>
				<el-input type="textarea" placeholder="请输入内容" v-model="desc_txt" rows="4" maxlength="50" show-word-limit>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureRefuse" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
			getPlatformWithdrawalPageList,
			passWithdrawal,
			refuseWithdrawal
		} from '@/api/plat/finance'
	export default {
		data() {
			return {
				loading:true,
				dialogVisible:false,
				desc_txt:'',
				search:{
					keyword:'',
					state:'',
					type:''
				},
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				tableData:[],
				withdrawal_id:''
			}
		},
		mounted() {
			this.getList()
		},
		methods:{
			getList(){
				let params = this.search;
				params.page=this.currentPage;
				params.perPage=this.pagesize;
				params=this.tools.filterParams(params)
				getPlatformWithdrawalPageList(params).then(res=>{
					this.loading=false;
					this.total=res.data.count;
					this.tableData = res.data.list
				})
			},
			handleSizeChange(size) {
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
			toRefuse(row){
				this.dialogVisible = !this.dialogVisible;
				this.withdrawal_id =row.withdrawal_id
			},
			//搜搜
			toSearch(){
				this.currentPage = 1;
				//调接口
				this.getList()
			},
			//重置
			toReset(){
				this.currentPage = 1;
				this.search.keyword='';
				this.search.state='';
				this.search.type='';
				this.getList()
			},
			//通过
			toPass(row){
				let data={
					withdrawal_id:row.withdrawal_id
				}
				passWithdrawal(data).then(res=>{
					if(res.data.code!=''){
						this.$message.error(res.data.code);
					}
					this.getList()
				})
			},
			//拒绝
			sureRefuse(){
				let data={
					withdrawal_id:this.withdrawal_id,
					reason:this.desc_txt
				}
				refuseWithdrawal(data).then(res=>{
					this.dialogVisible = !this.dialogVisible;
					this.getList()
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
