<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>基本信息</span>
                    </div>
                    <div class="userinfo">
                        <div class="user_items firstitem">
                            <div class="users">
                                <div class="avatars">
                                    <el-avatar :size="65" :src="user.head_portrait"></el-avatar>
                                </div>
                                <div class="users_msg">
                                    <p>{{user.username}}</p>
                                    <p>手机号：{{user.account}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="user_items">
                            <div class="title">所属部门</div>
                            <div class="title vals">{{user.buyer_type}}</div>
                        </div>
                        <div class="user_items">
                            <div class="title">身份等级</div>
                            <div class="title vals">{{user.level_name}}<el-button type="text" v-if="user.level_name" style="margin-left:5px" @click="modifyLevel">修改</el-button>
                            </div>
                        </div>
                        <div class="user_items">
                            <div class="title">成为合伙人时间</div>
                            <div class="title vals">{{user.agent_at}}</div>
                        </div>
                    </div>
                    <div class="userdata">
                        <div class="dataitem">
                            <p>粉丝业绩</p>
                            <p><span>¥{{user.team_achievement}}</span></p>
                        </div>
                        <div class="dataitem">
                            <p>粉丝总人数</p>
                            <p><span>{{fund.fans_count}}</span></p>
                            <p>
                                <el-button type="primary" size="small" style='height:24px;line-height:0px' @click="toFans">查看
                                </el-button>
                            </p>
                        </div>
                        <div class="dataitem">
                            <p>总累计收益</p>
                            <p><span>¥{{fund.commission_total_money}}</span></p>
                        </div>
                        <div class="dataitem">
                            <p>已提现收益</p>
                            <p><span>¥{{fund.commission_withdrawl_total_money}}</span></p>
                        </div>
                    </div>
                </el-card>
                <el-row :gutter="20" class="mt10">
                    <el-col :span="12">
                        <el-card class="box-card" shadow="never">
                            <div slot="header" class="clearfix">
                                <span>
                                    <el-select  style="width: 120px;"  size="small" v-model="buyer_type" placeholder="客户状态" @change="changeLevel">
                                        <el-option label="全部" value="0"></el-option>
                                        <el-option label="A" value="A"></el-option>
                                        <el-option label="B" value="B"></el-option>
                                        <el-option label="C" value="C1"></el-option>
                                    </el-select>
                                </span>
                                <div class="common-btn" style="float: right">
                                    <el-button plain size="small" :class="{ 'btn_active': flag === 0 }" @click="changeflag(0)">今日
                                    </el-button>
                                    <el-button plain size="small" :class="{ 'btn_active': flag === 30 }" @click="changeflag(30)">近30日
                                    </el-button>
                                    <el-button plain size="small" :class="{ 'btn_active': flag === -1 }" @click="changeflag(-1)">全部
                                    </el-button>
                                </div>
                            </div>
                            <div class="sections">
                                <div class="sections_item">
                                    <div class="item_p">
                                        <p>{{level1.level_name}}</p>
                                    </div>
                                    <div class="item_p">
                                        <p>粉丝业绩</p>
                                        <p><span>¥{{level1.team_achievement}}</span></p>
                                    </div>
                                    <div class="item_p">
                                        <p>粉丝人数</p>
                                        <p><span>{{level1.fans_count}}</span></p>
                                    </div>
                                    <div class="item_p">
                                        <p>高预估收益</p>
                                        <p><span>¥{{level1.commission_sum}}</span></p>
                                    </div>
                                </div>
                                <div class="sections_item">
                                    <div class="item_p">
                                        <p>{{level2.level_name}}</p>
                                    </div>
                                    <div class="item_p">
                                        <p>粉丝业绩</p>
                                        <p><span>¥{{level2.team_achievement}}</span></p>
                                    </div>
                                    <div class="item_p">
                                        <p>粉丝人数</p>
                                        <p><span>{{level2.fans_count}}</span></p>
                                    </div>
                                    <div class="item_p">
                                        <p>高预估收益</p>
                                        <p><span>¥{{level2.commission_sum}}</span></p>
                                    </div>
                                </div>
                                <div class="sections_item">
                                    <div class="item_p">
                                        <p>{{level3.level_name}}</p>
                                    </div>
                                    <div class="item_p">
                                        <p>粉丝业绩</p>
                                        <p><span>¥{{level3.team_achievement}}</span></p>
                                    </div>
                                    <div class="item_p">
                                        <p>粉丝人数</p>
                                        <p><span>{{level3.fans_count}}</span></p>
                                    </div>
                                    <div class="item_p">
                                        <p>高预估收益</p>
                                        <p><span>¥{{level3.commission_sum}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card class="box-card" shadow="never">
                            <div slot="header" class="clearfix">
                                <span>{{user.level_name}}奖励</span>
                                <div class="common-btn" style="float: right">
                                    <el-button plain size="small" :class="{ 'btn_active': gift === 0 }" @click="changegift(0)">今日
                                    </el-button>
                                    <el-button plain size="small" :class="{ 'btn_active': gift === 30 }" @click="changegift(30)">近30日
                                    </el-button>
                                    <el-button plain size="small" :class="{ 'btn_active': gift === -1 }" @click="changegift(-1)">全部
                                    </el-button>
                                </div>
                            </div>
                            <div class="sections">
                                <div class="sections_item blues">
                                    <div class="item_p wraps">
                                         <p>直推奖</p>
                                        <p><span>¥{{giftData.direct_commission}}</span></p>
                                    </div>
                                    <div class="item_p wraps">
                                        <p>市场奖</p>
                                        <p><span>¥{{giftData.deep_commission}}</span></p>
                                    </div>
                                    <div class="item_p wraps">
                                        <p>培育奖</p>
                                        <p><span>¥{{giftData.keep_commission}}</span></p>
                                    </div>
                                    <div class="item_p wraps">
                                        <p>粉丝团队奖</p>
                                        <p><span>¥{{giftData.team_commission}}</span></p>
                                    </div>
                                    <div class="item_p wraps">
                                         <p>福利奖</p>
                                        <p><span>¥{{giftData.re_deep_commission}}</span></p>
                                    </div>
                                    <div class="item_p wraps">
                                        <p>预估福利奖</p>
                                        <p><span>¥{{giftData.predict_re_deep_commission}}</span></p>
                                    </div>
                                    <div class="item_p wraps">
                                        <p>分红奖</p>
                                        <p><span>¥{{giftData.stock_commission}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
   <el-dialog title="修改等级" :visible.sync="dialogVisible" width="25%" >
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="用户等级" prop="level" >
                     <el-select style="width: 180px;!important" size="small" clearable v-model="ruleForm.change_level"
                                placeholder="请选择">
                                <el-option v-for="(items, index) in leveldata" :key="index" :label="items.level_name"
									:value="items.level">
								</el-option>
                            </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" size="small">提交</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import {viewUserInfo,viewLevel,myView,accountLevel,modifyLevel} from '@/api/plat/partner'
export default {
    data() {
        return {
            flag: 0,
            gift:0,
            account_shop_id: this.$route.query.account_shop_id,
            user:{},
            fund:{},
            buyer_type:'0',
            giftData:{},
            level1:{},
            level2:{},
            level3:{},
            dialogVisible:false,
            ruleForm:{
                 change_level:'',
            },
           
            leveldata:[]
        }
    },
    mounted() {
        if(this.account_shop_id){
            this.getInfo();
            this.levelDataInfo();
            this.awardDataInfo()
        }
     },
    methods:{
        //用户信息
        getInfo(){
            let data={
                account_shop_id:this.account_shop_id
            }
            viewUserInfo(data).then(res=>{
                console.log(res,"resresres")
                 this.user=res.data.user;
                 this.fund=res.data.fund;

            })
        },
        changeflag(val){
            this.flag=val;
            this.levelDataInfo()
        },
        //数据看榜概览1
        levelDataInfo(){
            let data={
                day:this.flag,
                buyer_type:this.buyer_type,
                level:0,
                account_shop_id:this.account_shop_id
            }
            viewLevel(data).then(res=>{
                this.level1=res.data.level1;
                this.level2=res.data.level2;
                this.level3=res.data.level3

            })
        },
        changeLevel(val){
            this.buyer_type=val;
            this.levelDataInfo()
        },
        changegift(val){
            this.gift=val;
            this.awardDataInfo()
        },
        //数据看榜概览2
        awardDataInfo(){
            let data={
                day:this.gift,
                account_shop_id:this.account_shop_id
            }
            myView(data).then(res=>{
                this.giftData=res.data

            })
        },
        //获取等级
        modifyLevel(){
            this.dialogVisible=true;
            accountLevel({}).then(res=>{
               this.leveldata=res.data
			})
        },
        //修改等级
        submitForm(){
            let data={
                account_shop_id:this.account_shop_id,
                level:this.ruleForm.change_level
            }
            modifyLevel(data).then(()=>{
                this.dialogVisible=false;
                this.getInfo();
                this.levelDataInfo();
                this.awardDataInfo()
            })
        },

        //查看粉丝
        toFans(){
            this.$router.push({
					path: '/partneradmin/partnerlist/fanslist',
					query:{
						account_shop_id:this.account_shop_id
					}
				})
        }
    }
}
</script>
<style lang="less" scoped>
.userinfo {
    background: #FCFBFC;
    padding: 20px;
    display: flex;

    .user_items {
        flex: 1;
        text-align: center;

        .title {
            line-height: 33px;
            font-size: 14px;
        }

        .title.vals {
            font-weight: border;
            font-size: 16px
        }

    }

    .user_items.firstitem {
        text-align: left
    }
}

.users {
    overflow: hidden;
    font-size: 14px;

    .avatars {
        height: 65px;
        float: left
    }

    .users_msg {
        float: left;
        margin-left: 10px;

        p {
            line-height: 33px;
        }
    }
}

.box-card {
    ::v-deep.el-card__body {
        padding: 0 !important
    }
}
.userdata {
    display: flex;
    padding: 20px;

    .dataitem {
        border-right: 1px solid rgba(224, 230, 237, .5);
        flex: 1;
        text-align: center;
        p {
            line-height: 28px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;

            span {
                font-size: 16px;
                display: block;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333;
            }
        }
    }
}

.common-btn {
    float: right;

    button {
        display: inline-block;
        width: 70px;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background: #FFF;
        border: 1px solid #DCDFE6;
        color: #606266;
        outline: 0;
        padding: 7px 8px;
        font-size: 14px;
        border-radius: 4px;
        margin-right: 10px;
    }

    .btn_active {
        background: #ecf5ff;
        border-color: #b3d8ff;
        color: #409eff;
    }
}
.sections{
    padding:20px;
    .sections_item{
         display: flex;
        justify-content:center;
        align-items:center;
        padding:15px 10px;
         border-bottom: 1px solid rgba(224, 230, 237, .5);
        .item_p{
           width:25%;
            text-align: center;
             p {
            line-height: 28px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;

            span {
                font-size: 16px;
                display: block;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333;
            }
        }
        }
        .item_p.wraps{
            margin-bottom:15px;

        }
    }
    .sections_item.blues{
       background: #ebf1ff; 
        flex-wrap:wrap;
        justify-content:left;
       
       
    }
}
</style>