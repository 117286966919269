import axios from '@/config/httpConfig'
import qs from  'qs'
//运费模板列表
export const getProductTemp=(params)=> {
	  return axios('get','product/shop/get_product_temp', params)
}
//删除运费模板
export const delete_product_temp=(params)=> {
  return axios('get','product/shop/delete_product_temp', params)
}
//获取地址信息
export const getProvincesInfo=(data)=> {
    return axios('post','product/shop/getProvincesInfo', data);
}
//编辑用户模版
export const productTempInfo=(params)=> {
	  return axios('get','product/shop/product_temp_info', params)
}
// 新增运费模板
export const create_product_temp=(data)=> {
  return axios('post','product/shop/create_product_temp', data);
}
//修改运费模板
export const modifyProductTemp=(data)=> {
    return axios('post','product/shop/modify_product_temp', data);
}
//获取快递物流列表
export const getExpressInfoList=(data)=> {
    return axios('post','product/shop/getExpressInfoList', data);
}
//添加新增-删除-禁用启用
export const ExpressUpdate=(data)=> {
    return axios('post','product/shop/ExpressUpdate', data);
}
//删除
export const Expressdelete=(data)=> {
    return axios('post','product/shop/Expressdelete', data);
}
//添加新增
export const ExpressInsert=(data)=> {
    return axios('post','product/shop/ExpressInsert', data);
}
//地址分页
export const addressList=(params)=> {
	  return axios('get','Address/shop/getAllAddressPageList', params)
}
//地址删除
export const addressDel=(data)=> {
    return axios('post','Address/shop/delAddress', data);
}
//获取门店详情
export const getShopInfo=(data)=> {
    return axios('post','/Account/shop/getShopInfo', data);
}
//门店信息修改
export const saveShopInfo=(data)=> {
    return axios('post','/Account/shop/saveShopInfo', data);
}
//地址分页
export const getAddressPageList=(params)=> {
	  return axios('get','Address/shop/getAddressPageList', params)
}
//地址删除
export const delAddress=(data)=> {
    return axios('post','Address/shop/delAddress', data);
}
//地址修改
export const editAddress=(data)=> {
    return axios('post','Address/shop/editAddress', data);
}
//地址详细
export const getAddressInfo=(params)=> {
	  return axios('get','Address/shop/getAddressInfo', params)
}
//地址添加
export const addAddress=(data)=> {
    return axios('post','Address/shop/addAddress', data);
}
//地址设置默认
export const setDefault=(data)=> {
    return axios('post','Address/shop/setDefault', data);
}
//海报添加
export const addSharePoster=(data)=> {
    return axios('post','Poster/shop/addSharePoster', data);
}
//海报详情
export const getPosterInfo=(params)=> {
	  return axios('get','Poster/shop/getPosterInfo', params)
}
//海报编辑
export const editPoster=(data)=> {
    return axios('post','Poster/shop/editPoster', data);
}
//营业执照图片信息获取
export const recognition=(data)=> {
    return axios('post','/Thirdparty/shop/recognition', data);
}
//店铺认证数据获取
export const getAuthenticate=(params)=> {
	  return axios('get','/Account/shop/getAuthenticate', params)
}
//店铺认证数据添加/修改
export const saveAuthenticate=(data)=> {
    return axios('post','/Account/shop/saveAuthenticate', data);
}