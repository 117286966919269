<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont">
					<el-button type="primary" size="small" @click="addRole">新增账号</el-button>
					<el-button size="small" @click="batchOpera('del')">删除</el-button>
					<el-button size="small" @click="batchOpera('open')">启用</el-button>
					<el-button size="small" @click="batchOpera('stop')">禁用</el-button>

				</div>
				<div class="main-cont mt10">
					<el-table ref="multipleTable" :data="tableData" border tooltip-effect="dark" style="width: 100%"
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column prop="name" label="名称" align="center">
						</el-table-column>
						<el-table-column prop="account" label="账号" align="center">
						</el-table-column>
						<el-table-column prop="role" label="角色" align="center">
						</el-table-column>
						<el-table-column prop="create_time" label="创建时间" align="center">
						</el-table-column>
						<el-table-column prop="modify_time" label="修改时间" align="center">
						</el-table-column>
						<el-table-column label="启用状态" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.state == 'Y'" class="el_state" @click="upOrdown(scope, 2)">
									禁用
									<img src="../../../../../assets/img/downs.png" />
								</div>
								<div v-else class="el_state" @click="upOrdown(scope, 1)">
									启用<img src="../../../../../assets/img/downs.png" />
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toEdit(scope.row)">编辑</el-button>
								<el-button type="text" size="small" style="color: #F56C6C;" @click="toDel(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<!--新建编辑角色-->
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
				<el-form-item label="名称" prop="name">
					<el-input size="small" class="inputWidth" v-model="form.name" placeholder="请输入名称"></el-input>
				</el-form-item>
				<el-form-item label="登录账号" prop="login_account">
					<el-input size="small" class="inputWidth" v-model="form.login_account" placeholder="请输入登录账号"></el-input>
				</el-form-item>
				<el-form-item label="登录密码" prop="login_pwd">
					<el-input size="small" class="inputWidth" show-password v-model="form.login_pwd" type="password" placeholder="请输入登录密码"></el-input>
				</el-form-item>
				<el-form-item label="角色选择">
					<el-select v-model="form.select_role" size="small">
						<el-option label="1" value="订单编号"></el-option>
						<el-option label="2" value="美工组"></el-option>
						<el-option label="3" value="运营组"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitSet('form') " size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [{
					name: '陈总',
					account:'美工组',
					role: '运营',
					create_time: '2021-04-49 09:56:23',
					modify_time:'2021-04-49 09:56:23',
					state: 'Y',
				}],
				multipleSelection: [],
				dialogVisible: false,
				dialogTitle: "新建账号",
				form: {
					name: '',
					login_account: '',
					login_pwd: '',
					select_role:''
				},
				rules: {
					name: [{
						required: true,
						message: '请填写名称',
						trigger: 'blur'
					}],
					login_account: [{
						required: true,
						message: '请填写登录账号',
						trigger: 'blur'
					}],
					login_pwd: [{
						required: true,
						message: '请填写登录密码',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			//启用禁用删除
			upOrdown(row, type) {

			},
			//批量操作
			batchOpera(type){
				if(this.multipleSelection == 0){
					this.$message.warning("请选择操作的数据！");
					return;
				}
				if(type=='del'){
					this.tools.confirm("确定要批量删除？").then(res=>{
						if(res){
							//调接口
						}
					})
				}
				if(type=='open'){
					this.tools.confirm("确定要批量启用？").then(res=>{
						if(res){
							//调接口
						}
					})
				}
				if(type=='stop'){
					this.tools.confirm("确定要批量禁用？").then(res=>{
						if(res){
							//调接口
						}
					})
				}
			},
			//单个删除
			toDel(row){
				this.tools.confirm("确定要批量删除？").then(res=>{
					if(res){
						//调接口
					}
				})
			},
			//新增角色
			addRole(type) {
				this.dialogVisible = !this.dialogVisible;
				this.dialogTitle = '新建账号'
			},
			//编辑角色
			toEdit(row) {
				this.dialogVisible = !this.dialogVisible;
				this.dialogTitle = '编辑账号'
			},
			//提交设置
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.el_state {
		display: flex;
		justify-content: center;

		img {
			margin-left: 10px;
			width: 42px;
			height: 22px;
		}
	}
</style>
