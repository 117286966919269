<template>
	<div>
		<MerchantOrderDetail :order_type="order_type"></MerchantOrderDetail>
	</div>
</template>

<script>
	import MerchantOrderDetail from "../../../../../pagecomponents/merchantOrderDetail.vue"
	export default{
		components:{
			MerchantOrderDetail
		},
		data(){
			return{
				order_type:''
			}
		},
		mounted() {
			this.order_type = this.$route.query.type
		},
	}
</script>

<style scoped lang="scss">

</style>
