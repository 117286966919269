<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<el-form :model="form" :rules="rules" ref="form" label-width="150px" class="demo-ruleForm">
					<div class="main-cont">
						<div class="setTitle">基本信息</div>
						<el-form-item label="所属类目:" prop="industry_id">
							<el-select v-model="form.industry_id" class="inputWidth" size="small">
								<el-option v-for="(item,index) in tradeList" :key="index" :label="item.trade_name"
									:value="item.marketing_trade_id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="类目推送:">
							<el-radio v-model="form.is_push" label="1">是</el-radio>
							<el-radio v-model="form.is_push" label="0">否</el-radio>
							<p class="pstyle">是：根据行业类目推送给商户； 否：此文章推给全平台商户</p>
						</el-form-item>
					</div>
					<div class="main-cont mt10">
						<el-form-item label="素材类型:">
							<el-radio v-model="form.type" label="1">图片</el-radio>
							<el-radio v-model="form.type" label="2">商品</el-radio>
							<div v-if="form.type == '1'">
								<div class="img_blos">
									<div v-for="(item, index) in form.images" :key="index" class="img_blo"
										style="margin-bottom:15px">
										<i class="el-icon-error close_img" @click="delImg(index)"></i>
										<img :src="item" class="item_img" />
									</div>
									<div class="plusImg" @click="uploadImg()">
										<i class="el-icon-plus avatar-uploader-icon"></i>
									</div>
								</div>
								<div class="pstyle">最多9张素材图片</div>
							</div>
							<div v-if="form.type == '2'">
								<div class="flex" style="height: 30px; line-height: 30px;">
									<span style="vertical-align: middle;">选择商品：</span>
									<div class="selectgood" @click="changegood">
										{{multipleSelection.length>0 || goods_id.length>0?"已选择":"请选择一款商品"}}</div>
								</div>
								<div class="selectgoodsInfo" v-if="multipleSelection.length>0 || goods_id.length>0">
									<div class="goodimg">
										<img v-if="selectGoodInfo.goods_main_pic"
											:src="selectGoodInfo.goods_main_pic.split(',')[0]" />
									</div>
									<div class="goodInfo">
										<div class="name">{{selectGoodInfo.goods_name}}</div>
										<div class="price">{{selectGoodInfo.sku_sale_price}}</div>
									</div>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="素材文案:" prop="content">
							<el-input type="textarea" class="inputWidth" v-model="form.content" :rows="5"
								maxlength="1500" show-word-limit></el-input>
						</el-form-item>
					</div>
				</el-form>
			</div>
		</div>
		<div class="save-cont">
			<div v-if="ishow == 1">
				<div class="cancel-btn" @click="goBack()">返回</div>
			</div>
			<div v-else>
				<div class="save-btn" @click="submitSet('form')">保存</div>
				<div class="cancel-btn" @click="canselSet">取消</div>
			</div>

		</div>
		<!--商品弹框-->
		<el-dialog title="选择商品" :visible.sync="dialogVisible" width="45%">
			<el-row class="all_search_list" style="margin-bottom: 0;">
				<div class="demo-input-suffix">
					<el-select v-model="search.is_publish" size="small" class="w120" placeholder="商品状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="上架" value="1"></el-option>
						<el-option label="下架" value="0"></el-option>
					</el-select>
				</div>
				<div class="demo-input-suffix">
					<el-cascader size="small" v-model="search.category" :options="allCate" placeholder="请选择商品分类"
						:props="{ expandTrigger: 'hover',value:'id',label:'name',children:'children'}">
					</el-cascader>
				</div>
				<div class="demo-input-suffix">
					<el-input prefix-icon="el-icon-search" v-model="search.keysward" placeholder="请输入商品名称" size="small"
						type="text">
						<el-button size="small" type="primary" plain slot="append" @click="searchOrder()">搜索
						</el-button>
					</el-input>
				</div>
			</el-row>
			<div>
				<el-table border :data="tableData" ref="multipleTable" :row-key="getRowKeys"
					style="width: 100%;margin-top:15px" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55" align="center">
					</el-table-column>
					<el-table-column prop="original_goods_unified_id" label="商品ID" align="center">
					</el-table-column>
					<el-table-column label="商品图片" align="center">
						<template slot-scope="scope">
							<img v-if="scope.row.goods_main_pic" :src="scope.row.goods_main_pic.split(',')[0]"
								class="el_img" />
						</template>
					</el-table-column>
					<el-table-column prop="goods_name" label="商品名称" align="center">
					</el-table-column>
					<el-table-column prop="sku_circumscribed_price" label="成本价" align="center">
					</el-table-column>
					<el-table-column prop="created_at" label="创建时间" align="center">
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<div class="pagecurrent">
					<div class="pagination-container">
						<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddGood" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		getMarketingTradeList,
		addMaterial,
		getMaterialInfo,
		editMaterial
	} from '@/api/plat/market.js'
	import {
		all_cate, //所有商品分类
		goods_list,
	} from '@/api/plat/goods';
	import ChooseImg from "../../../../../common/uploadImgs";
	export default {
		components: {
			ChooseImg,
		},
		data() {
			return {
				dialogVisible: false,
				form: {
					industry_id: '',
					is_push: '1',
					type: '1',
					images: [],
					content: ''
				},
				rules: {
					industry_id: [{
						required: true,
						message: '请选择所属类目',
						trigger: 'change'
					}],
					content: [{
						required: true,
						message: '请输入素材文案',
						trigger: 'blur'
					}],
				},
				isSingle: false, //上传图片是单张还是多张
				uploadFlag: false, //是否展示选择图片弹窗
				search: {
					is_publish: '',
					category: '',
					keysward: ''
				},

				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				multipleSelection: [],
				allCate: [],
				tradeList: [],
				goods_id: [],
				material_id: this.$route.query.id,
				ishow: this.$route.query.ishow,
				selectGoodInfo: {}
			}
		},
		mounted() {
			this.getTradeList();
			if (this.material_id) {
				//详情
				this.materialInfo()
			};
			this.getGoodsLists();


		},
		methods: {
			materialInfo() {
				let params = {
					material_id: this.material_id
				}
				getMaterialInfo(params).then(res => {
					this.form = res.data;
					this.form.is_push = this.form.is_push.toString()
					this.form.type = this.form.type.toString()
					if (this.form.images != '') {
						this.form.images = this.form.images.split(",");
					} else {
						this.form.images = [];
					}
					this.goods_id = res.data.original_goods_unified_id.split(",");

				})
			},
			//获取所有商品分类
			getAllCase() {
				all_cate({}).then(res => {
					this.allCate = res.data
				})
			},
			//获取商品列表
			getGoodsLists() {
				let data = {
					keyword: this.search.keysward,
					cate_id: this.search.category[0],
					is_publish: this.search.is_publish,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				goods_list(data).then(res => {
					this.tableData = res.data.list;
					this.total = res.data.count;
					this.tableData.forEach((row) => {
						if (this.goods_id[0] == row.original_goods_unified_id) {
							this.selectGoodInfo = row;
						}
					})

				})
			},
			//获取商品列表
			getGoodsList() {
				let data = {
					keyword: this.search.keysward,
					cate_id: this.search.category[0],
					is_publish: this.search.is_publish,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				goods_list(data).then(res => {
					this.tableData = res.data.list;
					this.total = res.data.count;
					this.$nextTick(() => {
						this.tableData.forEach((row) => {
							if (this.goods_id[0] == row.original_goods_unified_id) {
								this.$refs.multipleTable.toggleRowSelection(row, true);

							}
						})
					})
				})
			},
			getRowKeys(row) {
				return row.original_goods_unified_id;
			},
			//搜索商品
			searchOrder() {
				this.getGoodsList()
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getGoodsList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getGoodsList()
			},
			//行业类目
			getTradeList() {
				getMarketingTradeList({}).then(res => {
					this.tradeList = res.data
				})
			},
			// 上传商品图
			uploadImg(type) {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				val.forEach((res) => {
					if (this.form.images.length >= 9) {
						this.$message.warning("最多添加9张")
						return;
					}
					this.form.images = this.form.images.concat([res.resource_name]);
				});
			},
			//商品图上传图片删除
			delImg(index) {
				this.form.images.splice(index, 1);
			},
			//选择商品
			changegood() {
				this.dialogVisible = !this.dialogVisible;
				this.getAllCase();
				this.getGoodsList();
			},
			//多选
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.goods_id = [];
				val.forEach(item => {
					this.goods_id.push(item.original_goods_unified_id)
				})
				this.goods_id = [...new Set(this.goods_id)];

			},

			sureAddGood() {
				if (this.multipleSelection.length == 0) {
					this.$message.warning("请选择要添加的商品")
					return;
				}

				if (this.multipleSelection.length > 1) {
					this.$message.warning("只能选择一款商品")
					return;
				}

				this.selectGoodInfo = this.multipleSelection[0];
				this.dialogVisible = !this.dialogVisible;

			},
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						let images = []
						this.form.images.forEach(item => {
							images.push(this.tools.filterImgPre(item));
						});
						let data = JSON.parse(JSON.stringify(this.form));
						data.goods_id = this.goods_id.toString();
						data.material_id = this.material_id;
						data.images = images.toString();
						data = this.tools.filterParams(data);
						if (this.material_id) {
							editMaterial(data).then(res => {
								this.$message.success("编辑成功")
								this.$router.push({
									path: "/marketingadmin/materialcircle/materiallist"
								});
							})
						} else {
							addMaterial(data).then(res => {
								this.$message.success("添加成功")
								this.$router.push({
									path: "/marketingadmin/materialcircle/materiallist"
								});
							})
						}
					}
				});

			},
			goBack() {
				this.$router.back(-1)
			},
			canselSet() {
				this.$router.back(-1)
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.el_img {
		width: 72px;
		height: 34px;
	}

	.selectgood {
		cursor: pointer;
		width: 250px;
		height: 30px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EEEEEE;
		text-indent: 4px;
		color: #999;
	}

	.selectgoodsInfo {
		margin-top: 10px;
		width: 312px;
		padding: 10px 10px;
		height: 53px;
		background: #F2F2F6;
		border-radius: 4px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		.goodimg {
			width: 50px;
			height: 50px;
			margin-right: 10px;
			float: left;

			img {
				width: 50px;
				height: 50px;
			}
		}

		.goodInfo {
			float: left;

			.name {
				line-height: 20px;
			}

			.price {
				height: 20px;
				line-height: 20px;
			}
		}
	}
</style>
