<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont pp20">
					<el-select placeholder="模板类型" v-model="template_type" size="small" class="mr10">
						<el-option label="样板店" value="1"></el-option>
					</el-select>
					<el-select placeholder="页面状态" v-model="page_state" size="small" class="mr10">
						<el-option label="已发布" value="1"></el-option>
						<el-option label="禁用" value="2"></el-option>
					</el-select>
					<el-button size="small" @click="batchOpera('tag')">标签</el-button>
					<el-button size="small" @click="batchOpera('open')">启用</el-button>
					<el-button size="small" @click="batchOpera('stop')">禁用</el-button>
					<el-button size="small" @click="batchOpera('del')">删除</el-button>
				</div>
				<div class="main-cont mt10">
					<el-table ref="multipleTable" :data="tableData" border style="width: 100%"
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55" align="center">
						</el-table-column>
						<el-table-column prop="template_type" label="模板类型" align="center">
						</el-table-column>
						<el-table-column prop="template_name" label="模板名称" align="center">
						</el-table-column>
						<el-table-column prop="page_state" label="页面状态" align="center">
						</el-table-column>
						<el-table-column prop="tag" label="标签" align="center">
						</el-table-column>
						<el-table-column prop="create_time" label="标签" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button size="small" type="text">发布</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<!--打标签-->
		<MakeTag :dialogMarkVisible="dialogMarkVisible" @tagname="tagname" @hideModel="hideModel"></MakeTag>
	</div>
</template>

<script>
	import MakeTag from '../../../../pagecomponents/makeTag.vue'
	export default {
		components:{
			MakeTag
		},
		data() {
			return {
				template_type: '',
				page_state: '',
				tableData: [{
					template_type: '样板店',
					template_name: '电器-西门子',
					page_state: '已发布',
					tag: '美妆模版',
					create_time: '2020-09-07 10:26:32'
				}],
				multipleSelection: [],
				dialogMarkVisible: false,
				tagsname:'',//打标签
				
			}
		},
		methods: {
			//全选
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			//打标签
			batchOpera(type){
				if(this.multipleSelection.length==0){
					this.$message.warning("请先选择模板！")
					return;
				}
				if(type=='tag'){
					this.dialogMarkVisible = !this.dialogMarkVisible
				}
				if(type=='open'){
					this.tools.confirm("确认要批量启用？").then(res=>{
						if(res){
							//调接口
						}
					})
				}
				if(type=='stop'){
					this.tools.confirm("确认要批量禁用？").then(res=>{
						if(res){
							//调接口
						}
					})
				}
				if(type=='del'){
					this.tools.confirm("确认要批量删除？").then(res=>{
						if(res){
							//调接口
						}
					})
				}
				
			},
			//获取tagname
			tagname(val) {
				this.tagsname = val;
			},
			//关闭变迁弹框
			hideModel(val){
				this.dialogMarkVisible = val
			}
			
		}
	}
</script>

<style lang="scss" scoped="scoped">
	
</style>
