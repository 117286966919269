<template>
	<div class="video_blo">
		<el-dialog title="选择视频 " :visible.sync="videoShow" width="900px" class="blos"  >
			<div class="blos_top">
				<el-button size="small" type="primary" @click="showVideo">上传视频</el-button>
				<el-input placeholder="请输入内容" v-model="search.search" class="input-with-select">
					<el-button slot="append" icon="el-icon-search" @click="toSearch"></el-button>
				</el-input>
			</div>
			<div class="blos_cen">
				<div v-for="(item, index) in videoList" :key="index" class="blos">
					<img :src="item.video_image_path" class="blo_img" @click="chooseVideo(index)" />
					<img :src="item.checked? require('../../assets/img/videos/choose.png') : require('../../assets/img/videos/pause.png')" class="img_icon" />
					<div class="blo_name">
						<div class="names" :title="item.name">{{ item.resource_name }}</div>
						<i class="el-icon-delete" style="color: #409eff" @click="delVideos(index)"></i>
					</div>
					<div class="blo_time">{{ item.created_at }}</div>
				</div>
			</div>
			<div class="pagination-container cus_page">
				<el-pagination background  @size-change="handleSizeChange" hide-on-single-page @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[20, 40, 60, 80]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="okImg" size="small">确 定</el-button>
				<el-button @click="closeImg" size="small">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog title="选择视频 " :visible.sync="showVideos" width="590" class="chooses">
			<div class="choose_blo">
				<el-form :model="form" ref="form" label-width="150">
					<!-- <el-form-item> -->
					<div class="uploads">
						<p class="p1">上传视频</p>
						<p class="p2">视频不能超过10M，支持mp4视频格式</p>
						<div v-if="form.videoFile != ''" class="video_name">
							<video :src="form.videoFile"></video>
							<!-- <p>{{ form.videoFile }}</p> -->
							<i class="el-icon-delete" style="color: #409eff" @click="delVideo"></i>
						</div>
						<div v-else>
							<el-upload class="avatar-uploader" action="javascript:;"
								:data="paramData" :headers="{ token: tokens }" name="videoFile"
								:on-success="handleVideoSuccess" :show-file-list="false" list-type="picture-card"
								:before-upload="videoChange" :http-request="httpRequestVideo">
								<i class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</div>
					</div>
					<!-- </el-form-item> -->
					<!-- <el-form-item> -->
					<p class="p1">封面</p>
					<div class="img_blo">
						<img class="p_img" :src="form.videoImages" v-if="form.videoImages" />
						<el-upload action="javascript:;" :headers="{ token: tokens }" name="file"
							class="upload-demo" :show-file-list="false" :data="paramData" :on-success="handleSuccess"
							:before-upload="OnChange">
							<label class="p3">修改</label>
							<!-- <el-button size="small" type="primary">点击上传</el-button> -->
						</el-upload>
					</div>
					<p class="p2">默认视频第一帧为封面</p>
					<!-- </el-form-item> -->
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitOk" size="small">确 定</el-button>
				<el-button @click="submitCancle" size="small">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	let Base64 = require("js-base64").Base64;
	const sha256 = require("js-sha256").sha256;
	import md5 from 'js-md5';
	import {
		videoUploadingList,
		videoUploading,
		videoUp,
		videoimages,
	} from "@/api/video";
	import {
		fileUploading,
		imagesNameDel
	} from "@/api/imagesBlo";
	import baseURL from "@/config/baseUrl";
	// import { filter } from 'vue/types/umd';
	export default {
		data() {
			return {
				paramData: {},
				baseUrl: baseURL, //统一地址
				videoShow: true,
				playTime: 0, //视频时长
				tokens: localStorage.getItem("token"),
				form: {
					videoFile: "",
					videoImages: "",
					oss_video_id: "",
					oss_images_id: "",
				}, //视频信息
				showVideos: false, //是否展示上传视频弹窗
				search: {
					search: "",
				}, // 搜索信息
				currentPage: 1, //初始页
				pagesize: 20, //每页的数据
				total: 0, //总条数
				maxCount: 6,
				videoList: [
					// {
					//   img: require("../../assets/img/store/banners.png"),
					//   name: "服装视频",
					//   time: "2020-02-12 16:21",
					// },
					// {
					//   img: require("../../assets/img/store/banners.png"),
					//   name: "服装视频",
					//   time: "2020-02-12 16:21",
					// },
				],
			};
		},
		created() {
			//获取视频列表
			this.getVideos();
		},
		methods: {
			// 勾选视频
			chooseVideo(index) {
				let videos = [...this.videoList];
				videos.forEach((res) => {
					res.checked = false;
				});
				videos[index].checked = !videos[index].checked;
				this.videoList = [...videos];
			},
			//视频上传前
			async videoChange (file) {
				//视频时长
                    const duration  = await this.getTimes(file);
                     const isTime60S = (duration<=90);
					 
				const isLt10M = file.size / 1024 / 1024 < 10;
				const isType = ['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].includes(file.type);
				if (!isType) {
					this.$message.error("请上传正确的视频格式");
					return false;
				}
				if (!isLt10M) {
					this.$message.error("视频大小不能超过10MB");
					return false;
				}
				if (!isTime60S) {
					this.$message.error("视频时常不能超过30S");
					return false;
				}
			},
			httpRequestVideo(data){
					const fd = new FormData();
					fd.append('file', data.file)//当前为
					console.log(data.file,"data.file");
					videoUploading(fd).then(res => {
						console.log(res,'视频')
						this.form.oss_video_id = res.data.oss_resource_id;
						this.form.videoImages = res.data.video_image;
						this.form.videoFile = res.data.resource_name;
					})
			},
			getTimes(file){
                 return new Promise((resolve, reject)=> {
                    //做一些异步操作
                    let url = URL.createObjectURL(file)
                    let audioElement = new Audio(url)
                    let duration = 0;
                    audioElement.addEventListener('loadedmetadata', ()=> {
                        duration = parseInt(audioElement.duration)  //时长为秒，小数，182.36
                        resolve(duration)
                    })
                    audioElement.onerror = ()=> {
                        resolve(0)
                    };
                })
            },
			OnChange(file) {
				const isLt5M = file.size / 1024 / 1024 < 5;
				const isJPG = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg'].includes(file.type);
				if(!isJPG){
					this.$message("请上传正确的图片格式");
					return false
				}
				if (!isLt5M) {
					this.$message.error("上传图片大小不能超过 5MB!");
					return false
				}
				if(isLt5M && isJPG){
					// this.imgFileList.push(File);
					const fd = new FormData();
					fd.append('file', file)//当前为
					fileUploading(fd).then(response => {
						this.form.videoImages = response.data[0].resource_name;
						this.form.oss_images_id = response.data[0].oss_resource_id;
					})
				}
				return false
			},
		
			//上传图片成功后
			handleSuccess(response, file, fileList) {
				// console.log(response, 新增商品);
				// if (response.return_code == "ERROR") {
				// 	this.$message.error(response.return_msg);
				// } else {
				// 	this.form.videoImages = response.data.resource_name;
				// 	this.form.oss_images_id = response.data.oss_resource_id;
				// }
				// this.fileList = response.data;
				//   console.log(response, file, fileList, 22222);
				//   this.imgList.push(...response.data);
			},
			//删除视频
			delVideo() {
				this.form = {
					videoFile: "",
					videoImages: "",
					oss_video_id: "",
					oss_images_id: "",
				};
			},
	

			
			//上传失败回调
			handleError(err) {
				// console.log(err);
				this.$message.error(err.return_msg);
			},
			//视频上传失败回调
			handleVideoError(err) {
				// console.log(err, 新增商品);
				this.$message.error(err.return_msg);
			},
			//上传成功回调
			handleVideoSuccess(file) {
				 console.log(file, 22222);
				// if (file.data.length == 0) {
				// 	this.$message.error(file.return_msg);
				// 	return;
				// }
				// this.form.oss_video_id = file.data.oss_resource_id;
				// this.form.videoImages = file.data.video_image;
				// this.form.videoFile = file.data.resource_name;
				//   this.isShowUploadVideo = true;
				//   this.videoFlag = false;
				// this.videoUploadPercent = 0;
				// let data = new FormData();
				// data.append('video_file', file)
				return;
				var data = {
					video_file: file,
				};
				videoUploading(data, {
						"Content-Type": "multipart/form-data",
					})
					.then((response) => {
						// console.log(response, 121212);
						// this.videoForm.showVideoPath = response.data.path;
					})
					.catch((response) => {
						this.$message.error(response.return_msg);
					});
			},
			showVideo() {
				this.showVideos = !this.showVideos;
			},
			//删除视频
			delVideos(index) {
				//掉接口
				imagesNameDel({
						ossResourceId: this.videoList[index].oss_resource_id
					})
					.then((res) => {
						// console.log("dels", res);
						this.videoList.splice(index, 1);
					})
					.catch((response) => {
						this.$message.error(response.return_msg);
					});
			},
			//点击搜索
			toSearch() {
				this.getVideos();
			},
			getVideos() {
				let search = this.search;
				search.page = this.currentPage;
				search.perPage = this.pagesize;
				//过滤参数
				search = this.tools.filterParams(search);
				videoUploadingList(search)
					.then((res) => {
						// console.log('videos',res)
						this.videoList = res.data.list;
						this.total = res.data.count;
					})
					.catch((err) => {
						this.$message.error(err.return_msg);
					});
			},
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getVideos();
				// this.getList();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getVideos();
			},
			closeImg() {
				this.$emit("videoClose", false);
			},
			// 点击上传视频
			submitOk() {
				// console.log(this.form, 3333);
				delete this.form.videoFile
				delete this.form.videoImages
				//过滤空参数
				this.form = this.tools.filterParams(this.form);
				videoUp(this.form).then((res) => {
						// console.log("save", res);
						this.showVideos = !this.showVideos;
						//清空数据
						this.form = {
							videoFile: "",
							videoImages: "",
							oss_video_id: "",
							oss_images_id: "",
						};
						this.getVideos();
					})
					.catch((err) => {
						this.$message.error(err.return_msg);
					});
			},
			//取消视频上传
			submitCancle() {
				this.showVideos = !this.showVideos;
				this.form = {
					videoFile: "",
					videoImages: "",
					oss_video_id: "",
					oss_images_id: "",
				};
			},
			// 点击确定
			okImg() {
				// 判断是否勾选图片
				let isChecked = this.videoList.some((res) => res.checked);
				if (!isChecked) {
					this.$message("请勾选视频");
					return;
				}
				let list = this.videoList.filter((res) => res.checked);
				// console.log( this.imgList,list);
				this.$emit("videoOk", list);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.blos_top::v-deep.el-input-group {
		width: auto;
	}

	.video_blo::v-deep.el-dialog {
		border-radius: 10px;
	}

	.video_blo::v-deep.el-dialog__title {
		font-size: 14px;
	}

	.video_blo::v-deep.el-dialog__header {
		background: #f1f5f6;
		border-radius: 10px 10px 0 0;
	}

	.chooses::v-deep.el-dialog__header {
		padding: 20px 34px 10px;
	}

	.chooses::v-deep.el-form {
		background: none;
		padding: 20px 66px 0;
	}

	.chooses::v-deep.el-dialog__footer {
		margin-top: 10px;
	}

	.img_blo::v-deep.el-upload--text {
		width: auto;
		height: auto;
		margin-right: 10px;
		border: none;
	}

	.video_blo::v-deep.el-dialog__body {
		padding: 0;
	}

	.choose_blo::v-deep.el-upload--picture-card {
		width: 100px;
		height: 100px;
		margin-top: 20px;
		line-height: 100px;
	}

	.video_blo {
		.chooses {
			.choose_blo {
				.p1 {
					font-size: 14px;
					color: #333;
					margin-bottom: 6px;
				}

				.p2 {
					font-size: 12px;
					color: #999999;
				}

				.img_blo {
					display: flex;
					align-items: flex-end;
					margin-bottom: 10px;

					.p_img {
						width: 150px;
						height: 97px;
						margin: 13px 10px 0 0;
					}

					.p3 {
						color: #2589ff;
						font-size: 14px;
						cursor: pointer;
					}
				}

				.uploads {
					margin-bottom: 20px;

					.video_name {
						margin-top: 20px;
						position: relative;
						display: flex;
						width: 150px;
						border: 1px solid rgba(221, 221, 221, 0.6);

						video {
							width: 100%;
							height: 100px;
							margin-right: 20px;
						}

						p {
							margin-right: 40px;
						}

						i {
							cursor: pointer;
						}
					}
				}
			}
		}

		.blos_top {
			display: flex;
			justify-content: space-between;
			background: #f1f5f6;
			padding: 0 20px 10px;
			margin-bottom: 10px;
		}

		.blos_cen {
			display: flex;
			flex-wrap: wrap;
			padding: 0 20px;
			height: 300px;
			overflow: auto;

			.blos {
				color: #333;
				font-size: 12px;
				width: 200px;
				height: 160px;
				position: relative;
				border: 1px solid #eeeeee;
				border-radius: 2px;
				margin-right: 8px;
				margin-bottom: 10px;

				.blo_img {
					width: 100%;
					height: 100px;
				}

				.img_icon {
					width: 50px;
					height: 50px;
					position: absolute;
					left: 50%;
					margin-left: -25px;
					top: 25px;
				}

				.blo_name {
					padding: 8px 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-bottom: 1px solid #eeeeee;

					.names {
						width: 160px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					i {
						cursor: pointer;
					}
				}

				.blo_time {
					padding: 8px 10px;
				}
			}

			.blos:nth-child(4n) {
				margin-right: 0;
			}
		}

		.cus_page {
			text-align: left;
			padding: 10px 20px 0;
		}
	}
</style>
