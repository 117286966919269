<template>
 	<div>
 		<div class="wrapper-main">
			<div style="padding-bottom: 20px; color: #2589FF;">活动名称：{{money.activityName}}</div>
 			<div class="wrapper-cont">
 				<el-row :gutter="10">
 					<el-col :span="6">
 						<div class="grid-content bg-purple">
 							<div class="main-cont pd20">
 								<div class="fund_item">
 									<div>活动收益(元)</div>
 									<div style="font-size: 26px;margin-top: 10px;">{{money.profitsPrice}}</div>
 								</div>
 							</div>
 						</div>
 					</el-col>
 					<el-col :span="6">
 						<div class="grid-content bg-purple">
 							<div class="main-cont pd20">
 								<div class="fund_item">
 									<div>活动订单数(单）</div>
 									<div style="font-size: 26px;margin-top: 10px;">{{money.totalOrder}}</div>
 								</div>
 							</div>
 						</div>
 					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="main-cont pd20">
								<div class="fund_item">
									<div>活动营业额(元)</div>
									<div style="font-size: 26px;margin-top: 10px;">{{money.totalPrice}}</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="main-cont pd20">
								<div class="fund_item">
									<div>活动参与的商户</div>
									<div style="font-size: 26px;margin-top: 10px;">{{money.shopJoin}}</div>
									<div style="color:#0091FF;cursor: pointer;" @click="toDetail">查看</div>
								</div>
							</div>
						</div>
					</el-col>
 				</el-row>
 				<!--表格-->
 				<div class="tableMain">
 					<el-table :data="tableData" style="width: 100%" min-height="250" border>
 						<el-table-column prop="shop_name" label="商品名称" align="center">
							<template slot-scope="scope">
								<div class="el_name">
									<img :src="scope.row.goods_image" class="el_img" />
									<div>
										<p>{{ scope.row.goods_name }}</p>
									</div>
								</div>
							</template>
 						</el-table-column>
 						<el-table-column prop="diff" label="活动收益" align="center">
 						</el-table-column>
 						<el-table-column prop="orderCount" label="秒杀订单" align="center">
 						</el-table-column>
 						
 					</el-table>
 				</div>
 			</div>
 		</div>
 		<!-- 分页 -->
 		<div class="pagecurrent">
 			<div class="pagination-container">
 				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
 					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
 					layout="total, sizes, prev, pager, next, jumper" :total="total">
 				</el-pagination>
 			</div>
 		</div>
		<!--参与的商户弹框-->
		<el-dialog title="参与的商户" :visible.sync="dialogVisible" width="45%">
			<el-table border :data="shopData" style="width: 100%;margin-top:15px">
				<el-table-column prop="shop.shop_name" label="商户名称" align="center">
				</el-table-column>
				<el-table-column prop="shop.tele_phone" label="手机号码" align="center">
				</el-table-column>
				<el-table-column prop="salesPrice" label="销售额" align="center">
				</el-table-column>
				<el-table-column prop="orderCount" label="活动订单(笔)" align="center">
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<div class="pagecurrent">
				<div class="pagination-container">
					<el-pagination background @size-change="shophandleSizeChange" @current-change="shophandleCurrentChange"
						:current-page="shopcurrentPage" :page-sizes="[10,20,30,50]" :page-size="shoppagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="shoptotal">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
 	</div>
 </template>

 <script>
	import {
		getSecondskillActivityDetail,
		getShopJoinActivityDetail
	} from '@/api/plat/skillactivity';
 	export default {
 		data() {
 			return {
 				tableData: [],
 				total: 0,
 				currentPage: 1, //初始页
 				pagesize: 10, //每页的数据
 				money: {
					activityName:'',
 					profitsPrice: "0",
 					totalOrder: '0',
					totalPrice: '0',
					shopJoin: '0',
 				},
				dialogVisible:false,
				shopData:[],
				shoptotal: 0,
				shopcurrentPage: 1, //初始页
				shoppagesize: 10, //每页的数据
				seconds_kill_activity_id:''
 			}
 		},
 		mounted() {
			if (this.$route.query.id) {
				this.seconds_kill_activity_id = this.$route.query.id;
				this.getList();
			}
 		},
 		methods: {
 			//获取列表
 			getList() {
				let data={
					seconds_kill_activity_id: this.seconds_kill_activity_id,
					page: this.currentPage,
					perPage: this.pagesize
				}
				getSecondskillActivityDetail(data).then(res=>{
					this.tableData = res.data.goodsList.list;
					this.total = res.data.goodsList.count;
					this.money.activityName=res.data.activityName;
					this.money.profitsPrice=res.data.profitsPrice;
					this.money.totalOrder=res.data.totalOrder;
					this.money.totalPrice=res.data.totalPrice;
					this.money.shopJoin=res.data.shopJoin;
				})
 			},
 			//分页
 			handleSizeChange(size) {
 				this.pagesize = size;
 				// 掉接口
 				this.getList()
 			},
 			handleCurrentChange(currentPage) {
 				this.currentPage = currentPage;
 				// 掉接口
 				this.getList()
 			},
 			//查询
 			toSearch() {
 				//调接口
 				this.getList();
 			},
 			toDetail(row) {
				this.dialogVisible=true;
				let data={
					seconds_kill_activity_id: this.seconds_kill_activity_id,
					page: this.shopcurrentPage,
					perPage: this.shoppagesize
				}
				getShopJoinActivityDetail(data).then(res=>{
					this.shopData=res.data.list;
					this.shoptotal=res.data.count;
				})
 			},
			//分页
			shophandleSizeChange(size) {
				this.shoppagesize = size;
				// 掉接口
				this.toDetail()
			},
			shophandleCurrentChange(currentPage) {
				this.shopcurrentPage = currentPage;
				// 掉接口
				this.toDetail()
			},
 		}
 	}
 </script>

 <style scoped="scoped" lang="scss">
	 .el_name {
	 	display: flex;
	 	justify-content: flex-start;
	 	align-items: center;
	 
	 	.el_img {
	 		width: 31px;
	 		height: 31px;
	 		margin-right: 6px;
	 	}
	 }
 	.fund_item {
 		text-align:center;
 		padding: 10px 0;

 		div {
 			font-size: 14px;
 			color: #000;
 			line-height: 24px;
 		}
 	}
	.bg-purple{
		height: 7.625rem;
		background-color: #fff;
	}
 </style>
