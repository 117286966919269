<template>
	<div>
		<div class="wrapper-main pds">
			<el-form ref="form" :model="form" :rules="rules" label-width="130px" class="demo-ruleForm">
				<div class="wrapper-cont">
					<div class="setTitle">基础设置</div>
					<el-form-item label="专场名称:" prop="special_name">
						<el-input size="small" class="inputWidth" type="text" placeholder="请输入专场名称"
							v-model="form.special_name" maxlength="4" show-word-limit></el-input>
						<p class="pstyle">小程序顶部标题展示</p>
					</el-form-item>
					<el-form-item label="文字颜色:">
						<div class="el_color">
							<span>{{ form.text_color }}</span>
							<el-color-picker v-model="form.text_color"></el-color-picker>
						</div>
					</el-form-item>
					<el-form-item label="专场背景色:">
						<div class="el_color">
							<span>{{ form.background_color }}</span>
							<el-color-picker v-model="form.background_color"></el-color-picker>
						</div>
					</el-form-item>
					<el-form-item label="专场排序:">
						<el-input size="small" class="inputWidth" type="text" placeholder="数值越小越排前"
							v-model="form.weight"></el-input>
					</el-form-item>
				</div>
				<div class="wrapper-cont mt10">
					<div class="setTitle">专场图片</div>
					<el-form-item label="专场入口图:" prop="entrance_image">
						<img class="imgs" :src="
					      form.entrance_image
					        ? form.entrance_image
					        : require('../../../../../../assets/img/uploads/banners.png')
					    " @click="uploadImg(1)" />
						<p class="pstyle">建议尺寸750*400像素</p>
					</el-form-item>
					<el-form-item label="专场顶部图:" prop="top_image" style="margin-bottom:0">
						<img class="imgs" :src="
					      form.top_image
					        ? form.top_image
					        : require('../../../../../../assets/img/uploads/banners.png')
					    " @click="uploadImg(2)" />
						<p class="pstyle">建议尺寸750*不限高</p>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<div class="save-cont">
			<div class="cancel-btn" @click="cancelSet()">取消</div>
			<div class="save-btn" @click="submitSet('form')">保存</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="true" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		saveActivitiesResetInfo,
		getActivitiesResetInfo
	} from "@/api/merchant/specia.js"
	import ChooseImg from "../../../../../common/uploadImgs.vue"
	export default {
		components: {
			ChooseImg,
		},
		data() {
			return {
				form: {
					special_name: '',
					text_color: "#D9D9D9",
					background_color: "#D9D9D9",
					weight: '0',
					entrance_image: "",
					top_image: "",
					state: 'N'
				},
				rules: {
					special_name: [{
							required: true,
							message: "请输入专场名称",
							trigger: "blur"
						},
						{
							min: 1,
							max: 4,
							message: "长度为 4 个字符",
							trigger: "blur"
						},
					],
					entrance_image: [{
						required: true,
						message: '请选择专场入口图',
						trigger: 'change'
					}],
					top_image: [{
						required: true,
						message: '请选择专场顶部图',
						trigger: 'change'
					}],

				},
				chooseType: 0, //上传类型
				uploadFlag: false, //是否显示选择图片
				chooseImg: [], //选择的图片
				composing_special_activities_reset_id: ''
			}
		},
		mounted() {
			if (this.$route.query.id) {
				this.composing_special_activities_reset_id = this.$route.query.id;
				this.getinfo()
			}

		},
		methods: {
			getinfo() {
				let data = {
					composing_special_activities_reset_id: this.composing_special_activities_reset_id
				}
				data = this.tools.filterParams(data);
				getActivitiesResetInfo(data).then(res => {
					this.form = res.data;

				})
			},
			// 选择图片
			uploadImg(type) {
				this.chooseType = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.chooseImg = val;
				this.uploadFlag = false;
				if (this.chooseType == 1) {
					this.form.entrance_image = val[0].resource_name;
				} else if (this.chooseType == 2) {
					this.form.top_image = val[0].resource_name;
				}
			},
			//取消添加
			cancelSet() {
				this.$router.go(-1);
			},
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data = JSON.parse(JSON.stringify(this.form));
						data.entrance_image = this.tools.filterImgPre(data.entrance_image);
						data.top_image = this.tools.filterImgPre(data.top_image);
						if (this.composing_special_activities_reset_id) {
							data.composing_special_activities_reset_id = this.composing_special_activities_reset_id
						}
						data = this.tools.filterParams(data);
						saveActivitiesResetInfo(data).then(res => {
							if (this.composing_special_activities_reset_id) {
								this.$message.success("编辑成功！")
							} else {
								this.$message.success("添加成功！")
							}
							this.$router.push({
								path: "/specialadmin/specialmanage/speciallist"
							})
						})
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.el_color::v-deep.el-color-picker__trigger {
		border: none;
	}

	.el_color::v-deep.el-color-picker__trigger {
		border: none;
		padding: 0;
		height: 30px;
		margin-top: 5px;
		width: 30px;
	}

	.el_color::v-deep.el-color-picker__color {
		border: none;
		border-left: 1px solid #D9D9D9;
	}

	.el_color {
		width: 300px;
		height: 32px;
		background: #fff;
		border-radius: 4px;
		border: 1px solid #D9D9D9;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0 0 10px;
		box-sizing: border-box;
	}

	.imgs {
		width: 300px;
		height: 120px;
		display: block;
	}
	.pds{
		padding-bottom: 15px !important;
	}
</style>
