<template>
	<div>
		<div class="wrapper-main">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="服务商列表" name="first">
					<AgentList ref="list"></AgentList>
				</el-tab-pane>
				<el-tab-pane label="服务商审核"  name="second">
					<AgentCheck ref="check"></AgentCheck>
				</el-tab-pane>
			</el-tabs>
		</div>
		
		
	</div>
</template>

<script>
	import AgentList from'../agentlist/index.vue';
	import AgentCheck  from '../agentcheck/index.vue';
	export default {
		components:{
			AgentList,
			AgentCheck
		},
		data() {
			return {
				activeName: 'first',
			}
		},
		mounted() {
			this.$refs.list.getChannelData()
		},
		methods: {
			handleClick(tab) {
				if(tab.name=='first'){
					this.$nextTick(()=>{
						this.$refs.list.getChannelData()
					}) 
				}
				if(tab.name=='second'){
					this.$nextTick(()=>{
						this.$refs.check.getList()
					}) 
				}
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
