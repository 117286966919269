<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-button size="small" type="primary" @click="toAdd" v-has="'添加'">添加账号</el-button>
				</div>
				<div class="tableMain">
					<el-table :data="tableData" style="width: 100%" min-height="250" border  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="username" label="姓名" align="center">
						</el-table-column>
						<el-table-column prop="account" label="账号" align="center">
						</el-table-column>
						<el-table-column  label="头像" align="center">
							<template slot-scope="scope">
								<img :src="scope.row.head_portrait"  style="width: 50px; height: 50px;"/>
							</template>
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.status == '1'">正常</span>
								<span v-else>禁用</span>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="注册时间" align="center">
						</el-table-column>
						<el-table-column label="操作" width="150" align="center">
							<template slot-scope="scope">
								<!-- <el-button type="text" size="small" @click="toDel(scope.row)" v-has="'删除'">删除</el-button> -->
								<el-button type="text" size="small" @click="toEdit(scope.row)" v-has="'编辑'">编辑</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
		
		<!--分配机会弹框-->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="30%" @close="closeDialog">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm"
				style="padding: 0 10%;">
				<el-form-item label="姓名" prop="username">
					<el-input v-model="form.username" placeholder="请输入姓名" size="small"></el-input>
				</el-form-item>
				<el-form-item label="账号" v-if="account_shop_id">
					<el-input v-model="form.account" :disabled="true"  maxlength="11"  placeholder="请输入手机号" size="small"></el-input>
				</el-form-item>
				<el-form-item label="账号" v-else prop="account">
					<el-input v-model="form.account"   maxlength="11"  placeholder="请输入手机号" size="small"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password" v-if="isadd">
					<el-input v-model="form.password" placeholder="请输入密码" maxlength="16" size="small" @input="changeValue"></el-input>
				</el-form-item>
				<el-form-item label="密码"  v-else>
					<el-input v-model="form.password" placeholder="请输入密码" maxlength="16" size="small"></el-input>
				</el-form-item>
				<el-form-item label="头像:" >
					<div class="avatar-uploader1" @click="uploadImg">
						<img v-if="form.head_portrait" :src="form.head_portrait" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</div>
				</el-form-item>
				<el-form-item label="状态">
				     <el-radio v-model="form.status" label="1">正常</el-radio>
				      <!-- <el-radio :label="2">禁用</el-radio> -->
				  </el-form-item>
				<el-form-item label="角色" prop="role_id" >
					<!-- <el-checkbox-group v-model="form.role_id">
						<el-checkbox v-for="(item,index) in roleLists" :key="index" :label="item.role_id" :checked="item.check">
							{{item.role_nickname}}
						</el-checkbox>
					</el-checkbox-group> -->

					<el-radio-group v-model="form.role_id" style="width: 400px;display: flex;flex-flow:row wrap;align-items: center;margin-top: 12px;">
    <el-radio v-for="(item,index) in roleLists" :key="index" :label="item.role_id">	{{item.role_nickname}}</el-radio>
  </el-radio-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog" size="small">取 消</el-button>
				<el-button type="primary" @click="submitForm('form')" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="true" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import ChooseImg from '../../../../common/uploadImgs.vue';
	const sha256 = require('js-sha256').sha256;
	import {
		isvalidPhone
	} from '@/utils/validate';
	import {
		platformAccountList,
		createPlatformAccount,
		getRoleList,
		getPlatformAccountInfo,
		revampAssignAccountInfo,
		remPlaformAccount
	} from '@/api/plat/account';
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入电话号码'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		components: {
			ChooseImg
		},
		data() {
			return {
				loading:true,
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				dialogVisible: false,
				uploadFlag: false,
				title: '添加账号',
				isadd:false,
				roleLists: [],
				form: {
					username: '',
					account: '',
					password:'',
					head_portrait: '',
					role_id: [],
					status:'1'
				},
				rules: {
					username: [{
						required: true,
						message: '请填写名称',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请填写密码',
						trigger: 'blur'
					}],
					// account: [{
					// 		required: true,
					// 		trigger: 'blur',
					// 		validator: validPhone
					// 	}, //这里需要用到全局变量
					// ],
					role_id: [{
						required: true,
						message: '请选择角色',
						trigger: 'blur'
					}],
				},
				account_shop_id:'',
				
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			changeValue (e) {
      this.$forceUpdate()
    },
			uploadImg() {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.form.head_portrait = val[0].resource_name;
				this.uploadFlag = false;
			},
			//获取角色
			roleList() {
				getRoleList({}).then(res => {
					this.roleLists = res.data.list;
				})
			},
			getList() {
				let data = {
					page: this.currentPage,
					perPage: this.pagesize,
				}
				delete data['keys_type'];
				data = this.tools.filterParams(data);
				platformAccountList(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list

				})
			},
			//添加
			toAdd() {
				this.title="添加账号";
				this.isadd=true;
				this.form.username='';
				this.account_shop_id='';
				this.form.account='';
				this.form.password='';
				this.form.head_portrait='';
				this.form.role_id= [];
				this.form.status='1';
				this.dialogVisible = true;
				this.$nextTick(()=>{
					this.$refs['form'].clearValidate();
				});
				this.roleList()
			},
			closeDialog(){
				this.isadd=false;
				this.form.username='';
				this.form.account='';
				this.form.password='';
				this.form.head_portrait='';
				this.form.role_id= '';
				this.form.status='1';
				this.dialogVisible = false;
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//禁用启用
			toDel(row) {
				this.tools.confirm('确定要删除吗？').then(res => {
					if (res) {
						remPlaformAccount({account_shop_id:row.account_shop_id}).then(res=>{
							this.$message.success("删除成功！");
							this.getList()
						})
					}
				})
			},
			//编辑
			toEdit(row){

				// if(row.account_shop_id==1){
				// 	this.$message.error("此账号不允许编辑！");
				// 	return
				// }

				this.$nextTick(()=>{
					this.$refs['form'].clearValidate();
				});
				this.isadd=false;
				this.title="编辑账号";
				this.account_shop_id=row.account_shop_id;
				this.dialogVisible = true;
				this.getInfo(row.account_shop_id);
				this.roleList();
			},
			//获取信息
			getInfo(id){
				getPlatformAccountInfo({account_shop_id:id}).then(res=>{
					this.form=JSON.parse(JSON.stringify(res.data));
					// this.form.role_id=this.form.role_id.split(',').map(Number);
					// this.form.role_id = this.form.role_id.toString();
					this.form.status=this.form.status.toString();
				})
			},
			//添加提交
			submitForm(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						let data =JSON.parse(JSON.stringify(this.form));
						data.head_portrait=this.tools.filterImgPre(data.head_portrait);
						if(data.password){
							data.password=sha256(data.password);
						};
						// data.role_id=data.role_id.join(",");
						data=this.tools.filterParams(data);
						if(this.account_shop_id){
							data.account_shop_id=this.account_shop_id;
							revampAssignAccountInfo(data).then(res=>{
								this.$message.success("编辑成功！");
								this.dialogVisible = false;
								this.getList()
							})
						}else{
							createPlatformAccount(data).then(res => {
								this.$message.success("添加成功！");
								this.dialogVisible = false;
								this.getList()
							})
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.avatar-uploader1 {
		border-radius: 4px 4px;
		border: 1px solid #ddd;
		background-color: #fff;
		width: 50px;
		overflow: hidden;
		height: 50px;
		line-height: 50px;

		.el-icon-plus {
			width: 50px;
			text-align: center;
			height: 50px;
			line-height: 50px;
			font-size: 24px;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}
</style>
