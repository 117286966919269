<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
			<div style="background-color: #F2F2F6;padding: 0px;border-radius: 10px;">
				<div class="main">
					<div class="txkg">
						<span style="font-weight: 600;">提现开关</span>
						<span>
							<template>
								<el-radio v-model="radio" label="0" style="margin-left:30px ;">
									<span style="font-size: 14px;">开</span></el-radio>
								<el-radio v-model="radio" label="1"><span style="font-size: 14px;margin-left: 0;">关</span>
								</el-radio>
							</template>
						</span>
					</div>
					<div class="txed" style="margin-top: 80px;"><span style="font-weight: 600;">最低提现额度</span><el-input
							v-model="withdrawal_min" placeholder="" style="width: 200px; margin-left: 10px;"></el-input></div>
							
					<div class="txed" style="display: flex;margin-top: 80px;"><span style="color: red;">*</span><span
							style="font-weight: 600;">提现手续费</span><el-input v-model="service_fee" placeholder=""
							style="width: 120px; margin-left: 10px;margin-top: -10px;"><template slot="append">%</template></el-input></el-input><span style="text-align: center;">
							</span></div>
				</div>
			</div>

			</div>
		</div>
		<div class="save-cont">
			<!-- <div class="cancel-btn">取消</div> -->
			<div class="save-btn" @click="Submit()">保存</div>
		</div>
	</div>
</template>

<script>
import {
	addWithdrawalConfig,
	getWithdrawalConfigInfo
} from "@/api/merchant/finance.js"
export default {
	data() {
		return {
			radio: '0',
			service_fee:"",
			withdrawal_min:"",
			withdrawal_config_id:"",
		}
	},
	mounted() {
		this.getConfig()
	},
	methods: {
		Submit() {
			if(this.service_fee==''){
				this.$message.error("请输入提现手续费")
				return
			}
			let data = {
				is_open: +this.radio,
				service_fee: this.service_fee,
				withdrawal_min: this.withdrawal_min,
				withdrawal_config_id:this.withdrawal_config_id
			}
			addWithdrawalConfig(data).then(res => {
				this.$message.success('保存成功')
				this.getConfig()
				// console.log(res.data);
			})
		},
		getConfig() {
			getWithdrawalConfigInfo({}).then(res => {
				this.radio = res.data.is_open + "",
				this.withdrawal_config_id=res.data.withdrawal_config_id
				this.service_fee = res.data.service_fee
				this.withdrawal_min=res.data.withdrawal_min
			})
		}
	},
}
</script>

<style lang="scss" scoped="scoped">
.wrapper-cont {
	font-size: 14px;
	font-weight: 500;
	height: 700px;
	padding: 20px;

	.main {
		padding: 30px 100px;
		background-color: #fff;
		border-radius: 10px;
	}

}
</style>
