<template>
	<div>
		<div class="wrapper-main pds">
			<el-form :model="form" ref="form" :rules="rules" label-width="150px" class="demo-ruleForm">
				<div class="wrapper-cont">
					<div class="pmargin10">
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="grid-content bg-purple">
									<div class="setTitle">基础信息</div>
									<el-form-item label="店铺名称:" prop="shop_name">
										<el-input size="small" placeholder="输入店铺名称" class="inputWidth"
											v-model="form.shop_name"  maxlength="5" show-word-limit></el-input>
									</el-form-item>
									<el-form-item label="门店LOGO:">
										<div class="icon_blos" >
											<div class="icon_blo" @click="shopLogo('shop_logo')">
												<img :src="form.shop_logo" class="item_img" v-if="form.shop_logo"  style="margin-right: 0!important;"/>
												<div class="iconImg" v-else>
													<i class="el-icon-plus avatar-uploader-icon"></i>
												</div>
											</div>
										</div>
										<p class="imgtxtstyle">弹窗建议尺寸50*50px</p>
									</el-form-item>
									<el-form-item label="门店图片:">
										<div class="img-uploader" @click="storeImg('shop_images')">
											<img v-if="form.shop_images" :src="form.shop_images" class="avatar" />
											<i v-else class="el-icon-plus"></i>
										</div>
									</el-form-item>
									<el-form-item label="账号有效期">
									    <el-date-picker
									          v-model="form.daterange"
									          type="daterange"
									          range-separator="至"
									          start-placeholder="开始日期"
											  @change="changetimer"
									          end-placeholder="结束日期">
									        </el-date-picker>
									  </el-form-item>
									<el-form-item label="所属地区:">
										<el-cascader size="small" placeholder="所属地区" style="width:300px;"
											:options="options" collapse-tags :props="{multiple: false }" clearable
											@change="handleChange" v-model="address" ref="cascader">
										</el-cascader>
									</el-form-item>
									<el-form-item label="地图点位:">
										<div class="mapsure">请在地图中确认地址信息</div>
										<div class="relation" style="margin-left: 0;">
											<div class="absolute">
												<el-input placeholder="请搜索地址" prefix-icon="el-icon-search"
													v-model="addresskeywords" @input="inputAddress"
													style="width: 250px;" size="small">
												</el-input>
											</div>
											<div id="container" style="width:500px;height:350px;"></div>
										</div>
									</el-form-item>
									<el-form-item label="详细地址:">
										<el-input type="text" placeholder="请输入详细地址" @input="setAddressDetail"
											v-model="form.shop_address_detail" class="inputWidth" size="small">
										</el-input>
									</el-form-item>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content bg-purple">
									<div class="setTitle">网店联系人</div>
									<el-form-item label="联系电话:" prop="tele_phone">
										<el-input size="small" placeholder="输入联系电话" maxlength="11"
											class="tele_phone inputWidth" v-model="form.tele_phone"></el-input>
									</el-form-item>
									<el-form-item label="联系人:" prop="tele_name">
										<el-input size="small" placeholder="输入联系人" class="tele_name inputWidth"
											v-model="form.tele_name"></el-input>
									</el-form-item>
									<el-form-item label="账号:" prop="account">
										<el-input size="small"  placeholder="输入账号" maxlength="11"
											class="tele_name inputWidth"  v-model="form.account"></el-input>
									</el-form-item>
									<el-form-item label="密码:">
										<el-input size="small" type="password" placeholder="输入密码" show-password
											class="tele_name inputWidth" v-model="form.password"></el-input>
									</el-form-item>
									<el-form-item label="账号名称:" prop="username" >
										<el-input size="small" placeholder="输入账号名称" class="tele_name inputWidth"
											v-model="form.username"></el-input>
									</el-form-item>
									<el-form-item label="店铺简介">
										<el-input type="textarea" class="inputWidth" v-model="form.synopsis"></el-input>
									</el-form-item>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-form>
		</div>
		<div class="save-cont" v-if="isTypeShow=='add' || isTypeShow=='eduit'">
			<div class="cancel-btn" @click="goBack">返回上一步</div>
			<div class="save-btn" @click="toSubmit('form')">保存</div>
		</div>
	
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="true" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		getShopTradeList,
		getShopInfo,
		saveShopInfo,
		modelCreateShop
	} from '@/api/plat/business'
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	import ElPopover from '../../../../../common/popover.vue';
	import ChooseImg from '../../../../../common/uploadImgs.vue';
	var searchService, geocoder, map, markersArray = [];
	import {
		TMap
	} from "@/utils/TMap.js";
	import {
		isvalidPhone
	} from '@/utils/validate'
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入电话号码'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		components: {
			ChooseImg
		},
		data() {
			return {
				form: {
					account:'',
					username:'',
					password:'',
					shop_name: '',
					shop_encoding: '',
					is_entity: '',
					marketing_trade_id: '',
					shop_logo: '',
					shop_images: '',
					province: '',
					province_code: '',
					city: '',
					city_code: '',
					county: '',
					county_code: '',
					shop_address_detail: '',
					tele_phone: '',
					tele_name: '',
					shop_qq: '',
					synopsis: '',
					ENTERPRISE_NAME_CH: '',
					ABBREVIATION_NAME: '',
					ENTERPRISE_ID: '',
					business_license_images: '',
					identity_card_front: '',
					identity_card_reverse: '',
					business_license_info: {},
					unique_identification: '',
					endTimeType: '',
					daterange:[],
					begin_time:'',
					end_time:''
				},
				address: [],
				rules: {
					shop_name: [{
						required: true,
						message: '请填写店铺名称',
						trigger: 'blur'
					}],
					shop_encoding: [{
						required: true,
						message: '请填写店铺编码',
						trigger: 'blur'
					}],
					is_entity: [{
						required: true,
						message: '请填写门店类型',
						trigger: 'change'
					}],
					marketing_trade_id: [{
						required: true,
						message: '请选择行业类目',
						trigger: 'change'
					}],
					shop_logo: [{
						required: true,
						message: '请上传店铺logo',
						trigger: 'change'
					}],

					tele_phone: [{
							required: true,
							trigger: 'blur',
							validator: validPhone
						}, //这里需要用到全局变量
					],
					tele_name: [{
						required: true,
						message: '请填写联系人',
						trigger: 'change'
					}],
					username: [{
						required: true,
						message: '请填写昵称',
						trigger: 'change'
					}],
					account: [{
							required: true,
							trigger: 'blur',
							validator: validPhone
						}, //这里需要用到全局变量
					],
				},
				options: regionData,
				uploadFlag: false,
				typeImg: '',
				longitude: 30.18534, //经度
				latitude: 120.26457, //纬度
				searchService: null,
				geocoder: null,
				map: null,
				marker: null,
				mapAddress: '浙江省,杭州市,萧山区',
				addresskeywords: '',
				tradeList: [],
				is_entity: '',
				isTypeShow: this.$route.query.type,
				comboList: [],
				popover_audit_money_content: '若是跨区域销售，成本增加',
				popover_store_money_name: '',
				shop_id: this.$route.query.id,
				timeVal: [],
				moneyVal: {},
				centerDialogVisible: false
			}
		},
		mounted() {
			this.codeAddress();
			if (this.shop_id) {
				this.getInfo();
			}


		},
		methods: {
	
			//选择下拉等级获取值
			getComboVal(val) {
				this.comboList.forEach(item1 => {
					if (item1.unique_identification == val) {
						this.timeVal = item1.list
					}
				})
			},
			//等级时间
			getTimeVal(val) {
				this.timeVal.forEach(item2 => {
					if (item2.indate_time == val) {
						this.moneyVal = item2
					}
				});
				this.centerDialogVisible = !this.centerDialogVisible
			},
			//时间选择
			changetimer(val) { //时间
			
				if (val) {
					this.form.begin_time = val[0] / 1000;
					this.form.end_time = val[1] / 1000;
				} 
			},
			//获取营业执照信息
			getRecognition() {
				let data = {
					business_license_path: this.tools.filterImgPre(this.form.business_license_images)
				}
				recognition(data).then(res => {
					this.form.business_license_info = res.data;

				})

			},
			//获取详细信息
			getInfo() {
				let data = {
					shop_id: this.shop_id
				}
				getShopInfo(data).then(res => {
					this.form = res.data;
					this.form.is_entity = this.form.is_entity.toString();
					this.$set(this.form,"daterange",[this.tools.timeFormate(this.form.account_shop_info.begin_time*1000),this.tools.timeFormate(this.form.account_shop_info.end_time*1000)]);
					//this.form.daterange=[this.tools.timeFormate(this.form.account_shop_info.begin_time*1000),this.tools.timeFormate(this.form.account_shop_info.end_time*1000)]
					this.form.account=this.form.account_shop_info.account;
					this.form.username=this.form.account_shop_info.username;
					let addressData;
					addressData = res.data.shop_province_code + ',' + res.data.shop_city_code + ',' + res.data
						.shop_county_code;
					addressData = addressData.split(",");
					this.address = addressData;
					this.mapAddress = res.data.shop_province + res.data.shop_city + res.data.shop_county
					this.codeAddress();
				})
			},
			//输入调地图
			inputAddress() {
				this.mapAddress = this.addresskeywords;
				this.codeAddress();
			},
			codeAddress() {
				let lng, lat;
				let geocoder = new qq.maps.Geocoder();
				//对指定地址进行解析
				var result = geocoder.getLocation(this.mapAddress);
				//设置服务请求成功的回调函数
				geocoder.setComplete(result => {
					this.longitude = result.detail.location.lng;
					this.latitude = result.detail.location.lat;
					this.setMap()

				});
				//若服务请求失败，则运行以下函数
				geocoder.setError(function() {
					//alert("出错了，请输入正确的地址！！！");
				});
			},
			setMap() {
				var myLatlng = new qq.maps.LatLng(this.latitude, this.longitude);
				var myOptions = {
					zoom: 8,
					center: myLatlng,
					mapTypeId: qq.maps.MapTypeId.ROADMAP
				}
				var map = new qq.maps.Map(document.getElementById("container"), myOptions);
				var marker = new qq.maps.Marker({
					position: myLatlng,
					map: map
				});
			},

			//地址选择
			handleChange(area) {
				this.form.province_code = area[0];
				this.form.city_code = area[1];
				this.form.county_code = area[2];
				this.form.province = CodeToText[this.form.province_code];
				this.form.city = CodeToText[this.form.city_code];
				this.form.county = CodeToText[this.form.county_code];
				this.mapAddress = this.form.province + this.form.city + this.form.county;
				this.codeAddress();
				this.form.unique_identification = '';
				this.form.endTimeType = '';
				this.moneyVal.discount = '';
				this.moneyVal.sale_price = '';
			},
			/** 详细地址 */
			setAddressDetail(value) {
				if (this.form.province && this.form.city && this.form.county) {
					this.mapAddress = CodeToText[this.form.province_code] + CodeToText[this.form.city_code] + CodeToText[
						this.form.county_code] + value
					this.codeAddress()
				} else {
					this.mapAddress = this.mapAddress = this.longitude = this.latitude = value
				}
			},
			//营业执照
			businessLicenseFront(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			//门店logo
			shopLogo(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			//门店图片
			storeImg(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			//身份证
			identitycardfront(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			identitycardback(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			//返回上一页
			goBack() {
				this.$router.push({
					path: '/clientadmin/modelstore/modelstorelist',
				})
			},
			// 点击确定
			chooseOk(val) {
				if (this.typeImg == "shop_logo") {
					this.form.shop_logo = val[0].resource_name;
				}
				if (this.typeImg == 'shop_images') {
					this.form.shop_images = val[0].resource_name;
				}
				if (this.typeImg == 'front') {
					this.form.business_license_images = val[0].resource_name;
					this.getRecognition();
				}
				if (this.typeImg == 'cardfront') {
					this.form.identity_card_front = val[0].resource_name;
				}
				if (this.typeImg == 'cardback') {
					this.form.identity_card_reverse = val[0].resource_name;
				}
				this.uploadFlag = false;
			},
			sureWithhold() {
				this.centerDialogVisible = !this.centerDialogVisible
			},
			//提交设置
			toSubmit(form) {
				this.$refs[form].validate((valid) => {
					let data = JSON.parse(JSON.stringify(this.form));
					data.shop_logo = this.tools.filterImgPre(data.shop_logo);
					data.shop_logo = this.tools.filterImgPre(data.shop_logo);
					data.shop_images = this.tools.filterImgPre(data.shop_images);
					data.shop_longitude = this.longitude;
					data.shop_latitude = this.latitude;
					delete data['business_license_images'];
					delete data['identity_card_reverse'];
					delete data['data.identity_card_front'];
					delete data['ENTERPRISE_NAME_CH'];
					delete data['ABBREVIATION_NAME'];
					delete data['ENTERPRISE_ID'];
					delete data["business_license_info"];
					delete data["daterange"];
					data = this.tools.filterParams(data);
					if (valid) {
						if (this.shop_id) {
							delete data["endTimeType"];
							delete data["unique_identification"];
							data.shop_id = this.shop_id;
							saveShopInfo(data).then(res => {
								this.$router.push({
									path: '/clientadmin/modelstore/modelstorelist',
								})
							})
						} else {
							modelCreateShop(data).then(res => {
								this.$router.push({
									path: '/clientadmin/modelstore/modelstorelist',
								})
							})
						}

					} else {
						return false;
					}
				});
			},
		}

	}
</script>

<style scoped>
	.pds{
		padding-bottom: 120px !important;
	}
</style>
