<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<div class="signature">
					<div class="search-cont " style="padding: 20px 20px;margin-bottom:10px" v-has="'新增广告'" v-if="tableData.length<2">
						<el-button size="small" type="primary" plain @click="toAdd" >新增广告</el-button>
					</div>
					<div class="search-cont" >
						<el-table border :data="tableData" style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
							<el-table-column prop="title" label="标题" align="center">
							</el-table-column>
							<el-table-column label="图片" align="center">
								<template slot-scope="scope">
									<el-image :src="scope.row.filename" style="width: 70px;height: 35px;" :preview-src-list="[scope.row.filename]"></el-image>
									<!-- <img :src="scope.row.filename" style="width: 70px;height: 35px;" /> -->
								</template>
							</el-table-column>
							<el-table-column prop="template_code" label="类型" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.identifying=='general_manager'">邀请普通店长兑换码海报</span>
									<span v-else-if="scope.row.identifying=='senior_manager'">邀请高级店长兑换码海报</span>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="created_at" label="创建时间">
							</el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button type="text" size="small" @click="toEdit(scope.row)"   v-has="'编辑'" >编辑</el-button>
									<el-button type="text" size="small" @click="delAdvertis(scope.row)"  v-has="'删除'" >删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</div>
		<!-- 分页 -->
		<!-- <div class="pagecurrent">
			<div class="pagination-container">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
				 :page-sizes="[10,20,30,50]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div> -->
	</div>
</template>

<script>
	import {
		getAdvertising,
		delAdvertising
	} from '@/api/plat/adverty.js'
	export default {
		data() {
			return {
				loading:true,
				total: 0,
				phone: '',
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				tableData: []
			}
		},
		mounted() {
			this.getsmsLogList()
		},
		methods: {
			getsmsLogList() {
				getAdvertising({}).then(res => {
					this.loading=false;
					this.tableData = res.data;
					this.tableData.forEach(item=>{
						item.filename=item.filename.split(',')[0]
					})
				})
			},
			toAdd() {
				this.$router.push({
					path: '/dobusiness/businessmanage/shopbussinessset',
					// query:{
					// 	type:'plat-supply',
					// 	id:row.order_number
					// }
				})
			},
			toEdit(row) {
				this.$router.push({
					path: '/dobusiness/businessmanage/shopbussinessset',
					query: {
						id: row.advertising_id,
						identifying: row.identifying
					}
				})
			},
			//删除
			delAdvertis(row) {
				this.tools.confirm("确认要删除？").then(res => {
					if (res) {
						let data = {
							advertising_id: row.advertising_id
						}
						delAdvertising(data).then(res => {
							this.getsmsLogList()
						})
					}
				});
			
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.signature {
		padding: 15px;
		border-radius: 6px;
		// min-height: 300px;
		background-color: #f2f2f6;
		margin-bottom: 20px;

	}

	.search-cont {
		border-radius: 8px;
		background-color: #fff;
		padding: 10px 50px 10px 20px;
		display: flex;
		flex-direction: row;
		// justify-content: space-between;
	}
</style>
