import axios from '@/config/httpConfig'
import qs from  'qs'
//获取订单列表
export const getOrderConfig=(params)=> {
	  return axios('get','/Order/platform/getOrderConfig', params)
}
//订单配置-更新/设置
export const saveOrderConfig=(data)=> {
    return axios('post','/Order/platform/saveOrderConfig', data);
}
//短信模板列表
export const querySmsTemplateList=(params)=> {
	  return axios('get','/Sms/platform/querySmsTemplateList', params)
}
//删除短信模板
export const deleteSmsTemplate=(data)=> {
    return axios('post','/Sms/platform/deleteSmsTemplate', data);
}
//添加短信模板
export const addSmsTemplate=(data)=> {
    return axios('post','/Sms/platform/addSmsTemplate', data);
}
//短信模板详情
export const querySmsTemplate=(params)=> {
	  return axios('get','/Sms/platform/getTemplateInfo', params)
}
//修改短信模板
export const modifySmsTemplate=(data)=> {
    return axios('post','/Sms/platform/modifySmsTemplate', data);
}
//短信模板标识
export const templateIdentifying=(params)=> {
	  return axios('get','/Sms/platform/getTemplateIdentifyingList', params)
}
//设置短信配置
export const saveConfiguration=(data)=> {
    return axios('post','/Sms/platform/saveConfiguration', data);
}
//获取短信配置
export const getConfiguration=(params)=> {
	  return axios('get','/Sms/platform/getConfiguration', params)
}
//消息发送日志
export const smsLogList=(data)=> {
    return axios('post','/Sms/platform/smsLogList', data);
}
//运费模板列表
export const getProductTemp=(params)=> {
	  return axios('get','product/platform/get_product_temp', params)
}
//删除运费模板
export const delete_product_temp=(params)=> {
  return axios('get','product/platform/delete_product_temp', params)
}
//获取地址信息
export const getProvincesInfo=(data)=> {
    return axios('post','product/platform/getProvincesInfo', data);
}
//编辑用户模版
export const productTempInfo=(params)=> {
	  return axios('get','product/platform/product_temp_info', params)
}
// 新增运费模板
export const create_product_temp=(data)=> {
  return axios('post','product/platform/create_product_temp', data);
}
//修改运费模板
export const modifyProductTemp=(data)=> {
    return axios('post','product/platform/modify_product_temp', data);
}
//获取快递物流列表
export const getExpressInfoList=(data)=> {
    return axios('post','product/platform/getExpressInfoList', data);
}
//添加新增-删除-禁用启用
export const ExpressUpdate=(data)=> {
    return axios('post','product/platform/ExpressUpdate', data);
}
//文件存储-配置获取
export const getNiuYunAuth=(params)=> {
	  return axios('get','/Oss/platform/getOssAuth', params)
}
//文件存储-关闭云存储
export const niuYunAuthClose=(data)=> {
	  return axios('post','/Oss/platform/ossAuthClose', data)
}
//文件存储-配置设置
export const setNiuYunAuth=(data)=> {
    return axios('post','/Oss/platform/saveOssAuth', data);
}
//地址分页
export const getAddressPageList=(params)=> {
	  return axios('get','Address/platform/getAddressPageList', params)
}
//平台地址添加
export const addAddress=(data)=> {
    return axios('post','Address/platform/addAddress', data);
}
//地址删除
export const delAddress=(data)=> {
    return axios('post','Address/platform/delAddress', data);
}
//平台地址修改
export const editAddress=(data)=> {
    return axios('post','Address/platform/editAddress', data);
}
//平台地址详细
export const getAddressInfo=(params)=> {
	  return axios('get','Address/platform/getAddressInfo', params)
}
//禁用/启用-样板店专用-运费模板
export const disable_product_temp=(params)=> {
	  return axios('get','product/platform/disable_product_temp', params)
}
//微信支付配置
export const setWxConfig=(data)=> {
    return axios('post','Thirdparty/platform/setWxConfig', data);
}
//微信支付配置获取
export const getWxConfig=(params)=> {
    return axios('get','/Thirdparty/platform/getWxConfig', params);
}
//阿里接口配置获取
export const getALiConfig=(params)=> {
	  return axios('get','/Thirdparty/platform/getALiConfig', params)
}
//阿里接口配置设置
export const setALiConfig=(data)=> {
    return axios('post','/Thirdparty/platform/setALiConfig', data);
}
//海报设置添加
export const addPosterSetting=(data)=> {
    return axios('post','Poster/platform/addPosterSetting', data);
}
//海报设置详细
export const getPosterSetting=(params)=> {
	  return axios('get','Poster/platform/getPosterSetting', params)
}
//海报设置编辑
export const editPosterSetting=(data)=> {
    return axios('post','Poster/platform/editPosterSetting', data);
}
//地址设置默认
export const setDefault=(data)=> {
    return axios('post','Address/platform/setDefault', data);
}
//公告添加
export const addNotice=(data)=> {
    return axios('post','Poster/platform/addNotice', data);
}
//公告分页
export const getNoticePageList=(params)=> {
	  return axios('get','Poster/platform/getNoticePageList', params)
}
//公告删除
export const delNotice=(data)=> {
    return axios('post','Poster/platform/delNotice', data);
}
//公告详细
export const getNoticeInfo=(params)=> {
	  return axios('get','Poster/platform/getNoticeInfo', params)
}
//公告编辑
export const editNotice=(data)=> {
    return axios('post','Poster/platform/editNotice', data);
}
//公告停止
export const pauseNotice=(data)=> {
    return axios('post','Poster/platform/pauseNotice', data);
}
//获取角色列表
export const getRoleList=(params)=> {
	  return axios('get','Permission/platform/getRoleList', params)
}
//单个角色添加
export const authoritysettingroleAdd=(data)=> {
    return axios('post','Permission/platform/RoleToWrite', data);
}
//获取指定角色的权限信息
export const authoritysettingmenuList=(params)=> {
	  return axios('get','Permission/platform/getRolePermission', params)
}
//获取单个角色信息
export const authoritysettingDetail=(data)=> {
    return axios('post','Permission/platform/RoleToShow', data);
}
//角色修改
export const authoritysettingModify=(data)=> {
    return axios('post','/Permission/platform/removeRolePermission', data);
}
//数据面板设置
export const setDataPanel=(data)=> {
    return axios('post','/Data/platform/setDataPanel', data);
}
//数据面板设置详情
export const getDataPanel=(params)=> {
	  return axios('get','/Data/platform/getDataPanel', params)
}