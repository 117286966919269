<template>
	<div class="custom-demo-view">
		<div class="menuitem">
			<el-tabs v-if="reload" v-model="tabIndex" @tab-click="jump">
				<el-tab-pane :label="tabs[0].title" v-if="tabs[0].isShow" name="0"></el-tab-pane>
				<el-tab-pane :label="tabs[1].title" v-if="tabs[1].isShow" name="1"></el-tab-pane>
				<el-tab-pane :label="tabs[2].title" v-if="tabs[2].isShow" name="2"></el-tab-pane>
				<el-tab-pane :label="tabs[3].title" v-if="tabs[3].isShow" name="3"></el-tab-pane>
				<el-tab-pane :label="tabs[4].title" v-if="tabs[4].isShow" name="4"></el-tab-pane>
			</el-tabs>
		</div>
		<div class="scroll-content" @scroll="onScroll"
			:style="'overflow-x: hidden; overflow-y: auto;height:' + contentStyleObj.height">
			<el-form :model="form" ref="form" :rules="rules" label-width="150px" class="demo-ruleForm">
				<!-- 基本信息 -->
				<div :ref="tabs[0].refName" class="scroll-item" style="padding-top: 1rem;">
					<div class="wrapper-cont pd20">
						<div class="line-title">
							{{tabs[0].title}}
						</div>
						<el-form-item label="商品类目:">
							<el-cascader filterable size="small" v-model="form.goods_category_id" :options="allCate"
								:props="{ expandTrigger: 'hover',value:'id',label:'name',children:'children'}">
							</el-cascader>
						</el-form-item>
						<el-form-item label="商品名称:" prop="goods_name">
							<el-input size="small" placeholder="输入商品名称" class="inputWidth" v-model="form.goods_name"
								maxlength="60" show-word-limit></el-input>
						</el-form-item>
						<el-form-item label="副标题:" prop="goods_subtitle">
							<el-input size="small" placeholder="输入商品名称" class="inputWidth" v-model="form.goods_subtitle"
								maxlength="60" show-word-limit></el-input>
						</el-form-item>
						<el-form-item label="商品图:">
							<div class="img_blos">
								<div v-for="(item, index) in form.goodsList" :key="index" class="img_blo"
									style="margin-bottom:15px">
									<img :src="item" class="item_img" @click="handlePictureCardPreview(item)"/>
								</div>

							</div>
							<div>建议尺寸：800*800像素，你可以拖拽图片调整顺序，最多上传15张</div>
						</el-form-item>
						<el-form-item label="推广图:">
							<div class="img_blos" @click="uploadImg('banners')" style="width: 115px!important;">
								<div class="img_blo">
									<img :src="form.extend_pic" class="item_img" v-if="form.extend_pic" style="margin-right: 0!important;"  />
									<div class="plusImg" v-else>
										<i class="el-icon-plus avatar-uploader-icon"></i>
									</div>
								</div>
							</div>
							<div>
								只支持.jpg 格式，702*303 px（默认为首页主推板块大图）
							</div>
						</el-form-item>
						<el-form-item label="主图视频:">
							<div class="banners_blos" @click="uploadVideo" style="width: 148px;">
								<div v-if="form.video_file" class="video_bos" style="margin-right: 0!important;">
									<video :src="form.video_file" :poster="form.video_poster"
										v-if="form.video_poster"></video>
								</div>
								<div class="plusImg" v-else>
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
							<div>最多可以上传1个视频，建议大小10M，推荐格式mp4</div>
						</el-form-item>
					</div>
				</div>
				<!-- 规格信息（SKU） -->
				<div :ref="tabs[1].refName" class="scroll-item" style="padding-top: 1rem;">
					<div class="wrapper-cont pd20">
						<div class="line-title">
							{{tabs[1].title}}
						</div>
						<div class="con_blo mt20">
							<label class="con_title">商品规格:</label>
							<div class="blos">
								<!--商品规格-->
								<!-- {{reguVal}} -->
								<div class="regu_item" v-for="(item, index) in reguVal" :key="index">
									<!--删除按钮-->
									<!--规格名下拉框-->
									<div class="item_name">
										<label>规格名:</label>
										<!-- {{item.name}} -->
										<el-select size="small" v-model="item.name" allow-create filterable>
											<el-option
												:disabled="reguVal && !!reguVal.find((vv) => vv.name == items.specification_name)"
												v-for="items in reguNames" :key="items.specification_name"
												:label="items.specification_name" :value="items.specification_name">
											</el-option>
										</el-select>
									</div>
									<!--选择添加规格图片-->
									<!-- <div v-if="index == 0" class="add_check">
										<el-checkbox @change="addImgs(index)"
											:checked="item.adds || !!item.regus[0].sku_image">添加规格图片</el-checkbox>
									</div> -->
									<!--规格值-->
									<div class="item_val">
										<label>规格值:</label>
										<div class="val_select mr20" v-for="(val, vIndex) in item.regus" :key="vIndex">
											<div class="val_select">
												<div>
													<div class="select_blo">
														<div class="blo_left">
															<el-select size="small" v-model="val.vals" allow-create
																filterable @change="createReguVal($event, index)">
																<el-option
																	:disabled="item.regus &&!!item.regus.find((vv) =>vv.vals == items.specification_name)"
																	v-for="items in reguVals[index]"
																	:key="items.goods_specification_id"
																	:label="items.specification_name"
																	:value="items.specification_name">
																</el-option>
															</el-select>
															<!--删除规格值-->
															<!-- <i class="el-icon-error add_close" v-if="vIndex != 0"
																@click="closeReguVal(index, vIndex)"></i> -->
														</div>
														<!--添加规格值按钮-->
														<!-- <div class="add_desc ml0" @click="addReguVal(index)"
															v-if="vIndex == item.regus.length - 1">
															
															
														</div> -->
													</div>
													<div class="regu_blos"
														v-if="!!item.regus[0].sku_image || item.adds">
														<img :src="val.sku_image" v-if="val.sku_image"
															@click="uploadImg('regus', index, vIndex)" />
														<div class="plusImg regus_img"
															@click="uploadImg('regus', index, vIndex)" v-else>
															<i class="el-icon-plus avatar-uploader-icon"></i>
														</div>
														<span v-if="!val.sku_image">请添加规格图片</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="regu_desc">
										买家选择不同规格会看到对应规格图片，建议尺寸750*750像素
									</div>
								</div>
								<div class="con_tem">
									<!-- <div class="item_desc">最多支持三组规格</div> -->
									<!-- <div class="tem_add" v-if="reguVal.length < 3">
										<el-button @click="createRegu">添加规格项目</el-button>
									</div> -->
								</div>
							</div>
						</div>
						<div class="regu_table" v-if="showForm[0]">
							<label class="con_title">规格类型</label>
							<div class="tables">
								<!-- {{reguData}} -->
								<div style="font-size: 12px;color: #666666; paddingBottom:10px ;">所有价格保留两位小数，如输入三位以上将会四舍五入，请谨慎填入</div>
								<el-table :data="reguData" :span-method="objectSpanMethod" border>
									<el-table-column fixed prop="val1" :label="showForm[0]" width="180">
									</el-table-column>
									<el-table-column prop="val2" :label="showForm[1]" v-if="showForm[1]">
									</el-table-column>
									<el-table-column prop="val3" :label="showForm[2]" v-if="showForm[2]">
									</el-table-column>
									<el-table-column label="销售价">
										<template slot-scope="scope">
											<el-input size="small" v-model="scope.row.sku_sale_price"
												:disabled="scope.row.isDis" min="0" type="number"></el-input>
										</template>
									</el-table-column>
									<el-table-column label="划线价">
										<template slot-scope="scope">
											<el-input size="small" v-model="scope.row.sku_market_price"
												:disabled="scope.row.isDis" min="0" type="number"></el-input>
										</template>
									</el-table-column>
									<el-table-column label="成本价">
										<template slot-scope="scope">
											<el-input size="small" v-model="scope.row.sku_purchase_price"
												:disabled="scope.row.isDis" min="0" type="number"></el-input>
										</template>
									</el-table-column>
									<el-table-column label="统一价">
										<template slot-scope="scope">
											<el-input size="small" v-model="scope.row.sku_unified_price"
												:disabled="scope.row.isDis" min="0" type="number"></el-input>
										</template>
									</el-table-column>
									<el-table-column label="建议销售价">
										<template slot-scope="scope">
											<el-input size="small" v-model="scope.row.sku_suggested_selling_price"
												:disabled="scope.row.isDis" min="0" type="number"></el-input>
										</template>
									</el-table-column>
									<el-table-column label="库存数量">
										<template slot-scope="scope">
											<el-input size="small" v-model="scope.row.sku_stock"
												:disabled="scope.row.isDis" min="0" type="number"></el-input>
										</template>
									</el-table-column>
									<el-table-column label="单商品重量(kg)">
										<template slot-scope="scope">
											<el-input size="small" v-model="scope.row.sku_weight"
												:disabled="scope.row.isDis" min="0" type="number"></el-input>
										</template>
									</el-table-column>
									<el-table-column label="单商品体积(m³)">
										<template slot-scope="scope">
											<el-input size="small" v-model="scope.row.sku_volume"
												:disabled="scope.row.isDis" min="0" type="number"></el-input>
										</template>
									</el-table-column>


								</el-table>
								<!-- 	<div class="opera-cont">
									批量设置：
									<div v-if="showOpera">
										<el-button type="text" @click="modifyOpera(1)">销售价</el-button>
										<el-button type="text" @click="modifyOpera(2)">库存</el-button>
										<el-button type="text" @click="modifyOpera(3)">重量</el-button>
									</div>
									<div v-else>
										<el-input style="width: 120px;" size="small" v-model.number="setVal"></el-input>
										<el-button type="text" @click="saveSure">保存</el-button>
										<el-button type="text" @click="cancelSure">取消</el-button>
									</div>
								</div> -->
							</div>
						</div>
					</div>
				</div>
				<!-- 物流信息 -->
				<div :ref="tabs[2].refName" class="scroll-item" style="padding-top: 1rem;top:5px;">
					<div class="wrapper-cont pd20">
						<div class="line-title">
							{{tabs[2].title}}
						</div>
						<el-form-item label="配送方式:">
							<el-radio-group v-model="logistics_type">
								<el-radio label="1">快递发货</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="运费类型:">
							<el-radio label="1" v-model="form.freight_type">统一运费</el-radio>
							<el-radio label="2" v-model="form.freight_type">运费模板</el-radio>
						</el-form-item>
						<el-form-item label="统一运费" v-if="form.freight_type =='1'">
							<el-input size="small"  oninput="value=value.replace(/[^\d.]/g,'')" placeholder="请输入统一运费" class="inputWidth"
								v-model="form.unified_freight">
								<template slot="append">¥</template>
							</el-input>
						</el-form-item>
						<el-form-item label="快递运费:" v-else-if="form.freight_type == '2'">
							<el-select size="small" v-model="form.freight_template_id" placeholder="请选择运费模板"
								class="el_addrs">
								<el-option v-for="item in freights" :key="item.product_template_id" :label="item.title"
									:value="item.product_template_id">
								</el-option>
							</el-select>
							<!-- <el-button type="text" size="small">新建</el-button> -->
							<p style="color: #909399;font-size: 12px;margin-top:10px">
								运费模版支持地区设置运费，按购买件数计算运费。</p>
						</el-form-item>
					</div>
				</div>
				<!-- 其他设置 -->
				<div :ref="tabs[3].refName" class="scroll-item" style="padding-top: 1rem;top:5px;">
					<div class="wrapper-cont pd20">
						<div class="line-title">
							{{tabs[3].title}}
						</div>
						<el-form-item label="商品编码:">
							<el-input class="inputWidth" size="small" v-model="form.goods_coding" type="text"
								placeholder="请输入商品编码"></el-input>
						</el-form-item>
						<el-form-item label="商品发货地址:">
							<el-select size="small" v-model="form.goods_address" placeholder="请选择商品发货地址"
								class="el_addrs">
								<el-option v-for="(item,index) in addressListData" :key="index"
									:label="item.address+item.full_address" :value="item.address+item.full_address">
								</el-option>
							</el-select>
							<!-- <el-input class="inputWidth" size="small" v-model="form.goods_address" type="text"
								placeholder="请输入商品编码"></el-input> -->
						</el-form-item>
						<el-form-item label="审核完成自动上架:">
							<el-radio-group v-model="form.is_whether_shelf">
								<el-radio label="1">是</el-radio>
								<el-radio label="0">否</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="自动下架:" prop="off_type">
							<el-radio-group v-model="form.off_type">
								<el-radio label="1">售空自动下架</el-radio>
							</el-radio-group>
						</el-form-item>
					</div>
				</div>
				<!-- 商品详情 -->
				<div :ref="tabs[4].refName" class="scroll-item" style="padding-top: 1rem;top:5px;">
					<div class="wrapper-cont pd20" style="margin-bottom:60px">
						<div class="line-title">
							{{tabs[4].title}}
						</div>
						<el-form-item label="" class="good_details">
							<div style="display: flex;">
								<div class="tipimg" style="width:300px;">
									<img src="../../assets/img/detail.png" style="width: 100%;" />
								</div>
								<!-- <div class="eduit" style="width: 50%; overflow-y: scroll;background-color: #fff;border: 1px solid #ccc;">
										 <Editor  v-model="form.goods_detail"/>
								</div> -->
								<el-radio-group v-model="form.detail_shop" style="display: flex; flex-direction: row"
									@change="detailType">
									<el-radio :label="1">文本框</el-radio>
									<div v-if="form.detail_shop == 1">
										<editor apiKey="mfyuzxdpnb9l94ck5ga22ju405qie31n9a9yjd29zoqkxejz" :init="init"
											v-model="form.goods_detail"></editor>
									</div>
									<el-radio :label="2" style="margin-left: 20px">纯图片</el-radio>
									<div v-if="form.detail_shop == 2">
										<div class="img_blos">
											<div v-for="(item, index) in form.detailImgs" :key="index" class="img_blo">
												<img :src="item" class="item_img" @click="handlePictureCardPreview(item)"/>
											</div>
										</div>
									</div>
								</el-radio-group>
							</div>

						</el-form-item>
					</div>
				</div>
			</el-form>
		</div>
		<div class="save-cont">
			<div class="cancel-btn" @click="goback">返回</div>
			<!-- <div class="save-btn" @click="addSubmit('form')">保存</div> -->
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
		<ChooseVideo v-if="uploadVideoFlag" @videoClose="videoCancel" @videoOk="videoOk"></ChooseVideo>
		<!--图片放大-->
		<el-dialog :visible.sync="dialogImgVisible">
		 	<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
	import ChooseImg from '../common/uploadImgs.vue'
	import ChooseVideo from "../common/uploadVideos.vue";
	import Editor from "@tinymce/tinymce-vue";
	// import Editor from '../common/Editor';
	import ElPopover from '../common/popover.vue'
	import {
		platform_shop_goods_deatil, //商品详情
		all_cate, //所有商品分类
		// SpecificationList, //规格值
		// createSpecification,
		// SpecificationDel,
		// add_goods,
		goods_temp_list,
		// goods_deatil,
		// edit_goods,
		getAddressList
	} from "@/api/plat/goods.js"
	export default {
		components: {
			ChooseImg,
			ChooseVideo,
			Editor,
			ElPopover
		},
		props: {
			activeName: String
		},
		data() {
			return {
				dialogImgVisible:false,
				dialogImageUrl: '',
				reguInfos: [], //保存规格信息
				reload: true,
				tabIndex: '0',
				contentStyleObj: {
					height: '100px'
				},
				popover_audit_money_name: '储值金额',
				popover_audit_money_title: '储值金额',
				popover_audit_money_content: '充值的金额不可提现哦',
				freights: [], //运费模板
				addrs: [], //地址
				tabs: [{
						isShow: true,
						title: '基本信息',
						refName: 'setOneRef'
					},
					{
						isShow: true,
						title: '规格信息（SKU）',
						refName: 'setTwoRef'
					},
					{
						isShow: true,
						title: '物流信息',
						refName: 'setThreeRef'
					},
					{
						isShow: true,
						title: '其他设置',
						refName: 'setFourRef'
					},
					{
						isShow: true,
						title: '商品详情',
						refName: 'setFiveRef'
					}
				],
				logistics_type: '1',
				form: {
					goods_category_id: [],
					unified_freight: '',
					is_unified_stock: "1",
					goods_name: '',
					freight_type: '1',
					goods_subtitle: '',
					goodsList: [], //商品图片
					extend_pic: '',
					video_poster: '',
					video_file: '',
					freight_template_id: '',
					goods_coding: '',
					goods_address: '',
					is_whether_shelf: '1',
					off_type: '1',
					detail_shop: 1,
					goods_detail: "",
					detailImgs: [], //详情图片
				},
				rules: {},
				init: {
					language: "zh_CN",
					paste_data_images: false,
					content_style: "img {max-width:100%;width:auto;height:auto}",
					// plugins:
					//   "lists image media table textcolor wordcount contextmenu paste",
					toolbar: "undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
					branding: false,
					min_height: 550,
					images_upload_handler: function(blobInfo, success, failure) {
						// console.log(blobInfo,success,failure,9999999)

						var data = {
							file: blobInfo.blob(),
						};
						fileUploading(data, {
								"Content-Type": "multipart/form-data",
							})
							.then((res) => {
								success(res.data.path);
							})
							.catch(() => {
								//  console.log(45454)
								failure("上传失败，请重新上传");
							});
					},
				},
				isSingle: false, //上传图片是单张还是多张
				uploadFlag: false, //是否展示选择图片弹窗
				uploadType: '',
				uploadVideoFlag: false, //是否展示视频弹窗
				//规格
				showOpera: true,
				reguVal: [], //规格
				reguNames: [], //规格名下拉框
				reguVals: [], //规格值下拉框
				reguname: '', //规格名
				reguId: 0, //默认规格id
				uploadType: "", //上传图片类型
				uploadFlag: false, //是否展示选择图片弹窗
				isSingle: false, //上传图片是单张还是多张
				reguIndex: -1, //当前规格索引
				reguParentIndex: -1, //当前规格模块索引
				showForm: [],
				reguData: [],
				setVal: '',
				operaType: '',
				goodsId: '',
				oldReguData: [],
				allCate: [],
				goods_unified_id: this.$route.query.id,
				addressListData: [],
				shop_id: ''
			}
		},
		mounted() {
			// 获取规格名称
			// this.getRegus();
			this.getAllCase(); //获取所有商品分类
			// 获取运费模板

			this.getAddressLists();
			if (this.goods_unified_id) {
				this.goodsInfo()
			}
		},
		watch: {
			reguVal: {
				handler(val) {
					let datas = JSON.parse(JSON.stringify(this.reguData));
					let reguData = [];
					let val1 = val[0];
					let val2 = val[1];
					let val3 = val[2];

					if (!val1) {
						this.showForm = [];
					} else if (!val2) {
						this.showForm = [val1.name];
						val1.regus.forEach((item, index) => {
							let defaultVal = datas[index] || {}
							reguData.push({
								showForm: this.showForm,
								val1: item.vals,
								isDis: !item.vals,
								sku_image: item.sku_image,
								sku_desc: [item.vals],
								sku_volume: defaultVal.sku_volume,
								sku_weight: defaultVal.sku_weight,
								sku_stock: defaultVal.sku_stock,
								sku_purchase_price: defaultVal.sku_purchase_price,
								sku_suggested_selling_price: defaultVal.sku_suggested_selling_price,
								sku_unified_price: defaultVal.sku_unified_price,
								sku_market_price: defaultVal.sku_market_price,
								sku_sale_price: defaultVal.sku_sale_price,
								row: 1,
								col: 1,
								subrow: 1,
								subcol: 1,
							});
						});
					} else if (!val3) {
						this.showForm = [val1.name, val2.name];
						val1.regus.forEach((item, index) => {
							val2.regus.forEach((item2, index2) => {
								let defaultVal = datas[index * val2.regus.length + index2] || {}
								reguData.push({
									showForm: this.showForm,
									val1: item.vals,
									val2: item2.vals,
									isDis: !item2.vals || !item.vals,
									sku_image: item.sku_image,
									sku_desc: [item.vals, item2.vals],
									sku_volume: defaultVal.sku_volume,
									sku_weight: defaultVal.sku_weight,
									sku_stock: defaultVal.sku_stock,
									sku_purchase_price: defaultVal.sku_purchase_price,
									sku_suggested_selling_price: defaultVal
										.sku_suggested_selling_price,
									sku_unified_price: defaultVal.sku_unified_price,
									sku_market_price: defaultVal.sku_market_price,
									sku_sale_price: defaultVal.sku_sale_price,
									row: index2 == 0 ? val2.regus.length : 0,
									subrow: 1,
									subcol: 1,
									col: index2 == 0 ? 1 : 0,
								});
							});
						});
					} else {
						this.showForm = [val1.name, val2.name, val3.name];
						let xxx = 0;
						val1.regus.forEach((item, index) => {
							val2.regus.forEach((item2, index2) => {
								val3.regus.forEach((item3, index3) => {
									let defaultVal = datas[index * val2.regus.length + index2 *
										val3.regus.length + index3] || {}
									let id2 = val2.regus.length * (val3.regus.length || 1);
									reguData.push({
										showForm: this.showForm,
										val1: item.vals,
										val2: item2.vals,
										val3: item3.vals,
										isDis: !item3.vals || !item2.vals || !item.vals,
										sku_image: item.sku_image,
										sku_desc: [item.vals, item2.vals, item3.vals],
										sku_volume: defaultVal.sku_volume,
										sku_weight: defaultVal.sku_weight,
										sku_stock: defaultVal.sku_stock,
										sku_purchase_price: defaultVal.sku_purchase_price,
										sku_suggested_selling_price: defaultVal
											.sku_suggested_selling_price,
										sku_unified_price: defaultVal.sku_unified_price,
										sku_market_price: defaultVal.sku_market_price,
										sku_sale_price: defaultVal.sku_sale_price,
										row: xxx % id2 == 0 ? id2 : 0,
										col: xxx % id2 == 0 ? 1 : 0,
										subrow: index3 == 0 ? val3.regus.length : 0,
										subcol: index3 == 0 ? 1 : 0,
									});
									xxx++;
								});
							});
						});
					}

					// datas.forEach((item, index) => {
					//   reguData[index] = datas[index];
					// });
					// console.log(reguData, 999999);
					this.reguData = reguData;
					//return;
					//this.reguData = [];
				},
				immediate: false,
				deep: true,
			},
			// 监听父组件传值改变
			list(arr) {
				if (arr.length > this.limit && this.limit != 0) {
					this.$message.warning(`当前最多可上传${this.limit}张图片`);
					return false;
				}
				this.banner_list = arr.map((url) => {
					let obj = {
						url: url,
						is_hover: false,
					};
					return obj;
				});
			},
		},
		computed: {
			isDis() {
				return true;
			},
		},
		methods: {

			//获取商品详情
			goodsInfo() {
				let data = {
					original_goods_unified_id: this.goods_unified_id
				}
				platform_shop_goods_deatil(data).then(res => {
					//对图片设置
					if (res.data.goods_main_pic) {
						res.data.goodsList = res.data.goods_main_pic.split(",");
					}
					if (res.data.detail_shop == 2 && res.data.goods_detail) {
						res.data.detailImgs = res.data.goods_detail.split(",");
					}

					this.shop_id = res.data.shop_id;
					this.freightList();
					this.reguInfos = res.data.sku;
					this.reguData = res.data.sku;
					this.goodsId = res.data.goods_id;
					this.form = res.data;
					this.form.freight_type = this.form.freight_type.toString();
					this.form.is_whether_shelf = this.form.is_whether_shelf.toString();
					//商品分类
					this.form.goods_category_id = res.data.category;
					this.form.off_type = this.form.off_type.toString();
					let datav = res.data.goods_sku;
					let specdata = [];
					datav.forEach(item => {
						let valArry = []
						item.value.forEach(item1 => {
							valArry.push({
								vals: item1.specname,
								sku_image: item1.specimg
							})
						})
						let objval = {
							name: item.name,
							regus: valArry,
						};
						specdata.push(objval);
					})
					this.reguVal = specdata;
					console.log(specdata, "66666")
				})

			},
			handlePictureCardPreview(file) {
					this.dialogImageUrl = file;
					this.dialogImgVisible = true;
				 },
			//获取地址列表
			getAddressLists() {

				getAddressList({}).then(res => {
					this.addressListData = res.data

				})
			},
			//获取所有商品分类
			getAllCase() {
				all_cate({}).then(res => {
					this.allCate = res.data
				})

			},
			//运费模板
			freightList() {
				goods_temp_list({
						shop_id: this.shop_id
					})
					.then((res) => {
						this.freights = res.data;

					})
				
			},
			// 点击创建规格
			createRegu() {
				this.reguVal.push({
					regus: [{}],
				});
			},

			// 是否显示规格图片
			addImgs(index) {
				let regus = [...this.reguVal];
				regus[index].adds = !regus[index].adds;
				regus[index].shows = !regus[index].shows;
				this.reguVal = [...regus];
			},



			// 修改规格数据格式
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				// console.log(columnIndex)
				if (columnIndex === 0) {
					return {
						rowspan: row.row,
						colspan: row.col,
					};
				}
				if (columnIndex === 1) {
					return {
						rowspan: row.subrow,
						colspan: row.subcol,
					};
				}
			},



			// 选择视频
			uploadVideo() {
				this.uploadVideoFlag = !this.uploadVideoFlag;
			},
			//视频弹窗-取消
			videoCancel(val) {
				this.uploadVideoFlag = val;
			},
			//视频弹出-确认
			videoOk(val) {
				this.form.video_poster = val[0].video_image_path;
				this.form.video_file = val[0].resource_name;
				this.uploadVideoFlag = false;
			},

			detailType() {
				this.form.goods_detail = "";
			},
			goback() {
				this.$router.push({
					path: '/goodsadmin/commodityaudit/commodityauditlist',
					query: {
						activeName: this.activeName
					}
				})
			},

			refresh() {
				this.reload = false
				this.$nextTick(() => (this.reload = true))
			},
			// tab click
			jump(index, info) {
				let target = document.querySelector('.scroll-content')
				let scrollItems = document.querySelectorAll('.scroll-item')
				// 判断滚动条是否滚动到底部
				if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
					this.tabIndex = index.index.toString()
				}
				let totalY = scrollItems[index.index].offsetTop - scrollItems[0]
					.offsetTop // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)
				let distance = document.querySelector('.scroll-content').scrollTop // 滚动条距离滚动区域顶部的距离
				// let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
				// 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次
				// 计算每一小段的距离
				let step = totalY / 50
				if (totalY > distance) {
					smoothDown(document.querySelector('.scroll-content'))
				} else {
					let newTotal = distance - totalY
					step = newTotal / 50
					smoothUp(document.querySelector('.scroll-content'))
				}

				// 参数element为滚动区域
				function smoothDown(element) {
					if (distance < totalY) {
						distance += step
						element.scrollTop = distance
						setTimeout(smoothDown.bind(this, element), 20)
					} else {
						element.scrollTop = totalY
					}
				}

				// 参数element为滚动区域
				function smoothUp(element) {
					if (distance > totalY) {
						distance -= step
						element.scrollTop = distance
						setTimeout(smoothUp.bind(this, element), 20)
					} else {
						element.scrollTop = totalY
					}
				}
			},
			// 滚动条滚动
			onScroll(e) {
				let scrollItems = document.querySelectorAll('.scroll-item')
				for (let i = scrollItems.length - 1; i >= 0; i--) {
					// 判断滚动条滚动距离是否大于当前滚动项可滚动距离
					let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop - 400
					if (judge) {
						this.tabIndex = i.toString()
						break
					}
				}
			},
			getHight() {
				this.contentStyleObj.height = (window.innerHeight - 190) + 'px'
			}
		},
		created() {
			this.getHight()
			window.addEventListener('resize', this.getHight)
		},
		destroyed() {
			window.removeEventListener('resize', this.getHight)
		},
	}
</script>
<style lang="scss" scoped="scoped">
	@import "../../assets/css/plat/goodadd.scss"; // .com-image-drag

	.menuitem::v-deep.el-tabs__header {
		margin: 0;

	}

	.menuitem::v-deep.el-tabs__nav-wrap::after {
		background-color: #fff
	}

	.menuitem::v-deep.el-tabs__active-bar {
		background-color: #fff;
	}

	.menuitem::v-deep.el-tabs__nav {
		width: 100%;
		height: 50px;
		line-height: 50px;
		background: #F2F2F6;
	}

	.menuitem::v-deep.el-tabs__item {
		padding: 0;
		height: 50px;
		text-align: center;
		width: 20%;
	}

	.line-title {
		font-size: 14px;
		color: #333;
		font-weight: bold;
	}

	.radiostyle::v-deep.el-radio__inner {
		margin-top: 5px
	}
</style>
