<template>
	<div>
		<div class="wrapper-main pds">
			<div class="ms-title titles">
				<li v-for="(item, index) in titleItem" :key="item.name" :class="{ active: curIndex == index + 1 }"
					@click="selected(index)">{{ item.name }}</li>
			</div>
			<div v-if="curIndex == 1">
				<grade-one ref="one" @toback="toback" :formData="formData" :curIndex="curIndex"></grade-one>
			</div>
			<div v-else-if="curIndex == 2">
				<grade-two ref="two" @toback="toback" :formData="formData" :curIndex="curIndex"></grade-two>
			</div>
			<div v-else-if="curIndex == 3">
				<grade-three ref="three" @toback="toback" :formData="formData" :curIndex="curIndex"></grade-three>
			</div>
			<div v-else-if="curIndex == 4">
				<all-set ref="all" @toback="toback" :allFromData="allFromData"></all-set>
			</div>
		</div>
	</div>
</template>

<script>

import gradeOne from './gradeone.vue'//等级一
import gradeTwo from './gradetwo.vue'//等级二
import gradeThree from './gradethree.vue'//等级三
import allSet from './allset.vue'//等级三
import { commissionConfigDetail, platConfigDetail } from '@/api/plat/platprofit'//分佣配置详情
export default {
	components: {
		gradeOne,
		gradeTwo,
		gradeThree,
		allSet
	},
	data() {
		return {
			curIndex: 1,
			formData: {},
			allFromData: {},
			titleItem: [{
				id: 1,
				name: '等级一'
			},
			{
				id: 2,
				name: '等级二'
			},
			{
				id: 3,
				name: '等级三'
			},
			{
				id: 4,
				name: '全局配置'
			}],

		}
	},
	mounted() {
		//this.$refs.general_manager.getManagerFenRunList()

		this.getDetail();
		this.getAllDetail()


	},
	methods: {
		//获取等级详情
		getDetail() {
			let data = {
				commission_config_id: this.curIndex
			}
			commissionConfigDetail(data).then(res => {
				this.formData = res.data;
			})

		},
		//获取全局配置详情
		getAllDetail() {
			platConfigDetail({}).then(res => {
				this.allFromData = res.data
			})
		},
		toback(val) {
			if (val) {
				this.curIndex = 1
			}

		},
		selected(indx) {
			this.curIndex = indx + 1;
			if (this.curIndex == 4) {
				this.getAllDetail()
			} else {
				this.getDetail()
			}
		},
	}
}
</script>

<style lang="scss" scoped="scoped">
.pds {
	padding-bottom: 120px !important;
}
</style>
