<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt" style="padding: 0!important;">
				<el-tabs class="tabstyle" v-model="activeName" type="card" @tab-click="handleClick" v-has>
					<el-tab-pane label="全部订单" name=""></el-tab-pane>
					<el-tab-pane label="待支付" name="1"></el-tab-pane>
					<el-tab-pane label="已完成" name="2"></el-tab-pane>
					<el-tab-pane label="已关闭" name="3"></el-tab-pane>
				</el-tabs>
				</div>
				<div class="order-search">
					<el-row class="all_search_list" style="margin-bottom:0">
						<div class="demo-input-suffix">
							<el-input clearable class="inputWidth" prefix-icon="el-icon-search"
								v-model="search.consignee_phone" placeholder="请输入手机号查询" @keyup.enter.native="searchOrder"  size="small" type="text">
							</el-input>
						</div>
						<div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
							创建时间：
							<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
								range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
								@change="changetimer"></el-date-picker>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder">查询</el-button>
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
					</el-row>
				</div>
				<div class="main-cont mt10">
					<el-table :data="tableData" border style="width: 100%"  v-loading="loading"  element-loading-text="拼命加载中..."  element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}">
						<el-table-column label="商品名称" width="280" align="left">
							<template slot-scope="scope">
								<div class="el_name">
									<!-- <el-popover placement="right" title width="200" trigger="hover">
										<div style="width:200px;height:200px;">
										  <img
											style="width:100%;height:100%;object-fit: cover;"
											:src="scope.row.course_cover"
											alt
										  />
										</div>
										<div style="width:80px;height:80px;" slot="reference">
										 <img v-if="scope.row.course_cover" :src="scope.row.course_cover" class="el_img"/>
										</div>
									  </el-popover> -->
									<div style="width:80px;height:80px;">
										 <el-image v-if="scope.row.course_cover" :src="scope.row.course_cover" :preview-src-list="[scope.row.course_cover]" class="el_img"/>
										</div>
									<div  style="width: 78%;">
										<p>{{ scope.row.course_title }}</p>
										<p class="mt10">{{ scope.row.price }}</p>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="订单号" prop="order_number" align="center">

						</el-table-column>
						<el-table-column label="金额" prop="actual_payment" align="center">
							<template slot-scope="scope">
								<div>
									<p>总金额：{{ scope.row.total_money }}</p>
									<p>实付金额：{{ scope.row.actual_payment }}</p>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="购买人" prop="actual_payment" align="center">
							<template slot-scope="scope">
								<div>
									<p>{{ scope.row.consignee_name }}</p>
									<p>{{ scope.row.consignee_phone }}</p>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="支付时间" prop="payment_time" align="center">
						</el-table-column>
						<el-table-column label="完成时间" prop="complete_time" align="center">
						</el-table-column>
						<el-table-column label="订单关闭时间" prop="cancel_time" align="center">
						</el-table-column>
						<el-table-column label="创建时间" prop="created_at" align="center">
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"  @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getOrderList
	} from "@/api/plat/order.js"
	export default {
		data() {
			return {
				loading:true,
				activeName: '',
				due_dt: '',
				search: {
					consignee_phone: '',
					begin_time: '',
					end_time: '',
					status: ''
				},
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let data = {
					page: this.currentPage,
					perPage: this.pagesize,
					consignee_phone: this.search.consignee_phone,
					begin_time: this.search.begin_time,
					end_time: this.search.end_time,
					status:this.search.status
				}
				data = this.tools.filterParams(data);
				getOrderList(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.tableData.forEach(item=>{
						item.payment_time=item.payment_time!= null?this.tools.formatDate(item.payment_time*1000):'';
						item.complete_time=item.complete_time!= null?this.tools.formatDate(item.complete_time*1000):'';
						item.cancel_time=item.cancel_time!= null?this.tools.formatDate(item.cancel_time*1000):'';
					});
					this.total = res.data.count
				})
			},
			handleClick(val) {
				this.currentPage = 1;
				this.search.consignee_phone = '';
				this.search.begin_time = '';
				this.search.end_time = '';
				this.due_dt = '';
				this.search.status = val.name;
				this.getList()
			},
			searchOrder() {
				this.currentPage = 1;
				this.getList()
			},
			toReset() {
				this.currentPage = 1;
				this.search.consignee_phone = '';
				this.search.begin_time = '';
				this.search.end_time = '';
				this.due_dt = '';
				this.search.status = '';
				this.getList()
			},
			handleSizeChange(size) {
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
			//时间选择
			changetimer(val) { //时间

				if (val) {
					this.search.begin_time = val[0] / 1000;
					this.search.end_time = val[1] / 1000;
				} else {
					this.search.begin_time = '';
					this.search.end_time = '';
				}
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	
	::v-deep.el-tabs__header {
		padding: 0;
		position: relative;
		margin: 0;
	}

	::v-deep.el-table__expand-icon>.el-icon {
		display: none;
	}

	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.el_img {
			width: 70px;
			height: 70px;
			margin-right: 6px;
		}
	}
</style>
