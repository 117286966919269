<template>
	<div>
		<div class="wrapper-main">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="学院课程" name="first">
					<BussinessCollegePage ref="business"></BussinessCollegePage>
				</el-tab-pane>
				<el-tab-pane label="秒杀课程" name="second">
					<SecondKillCourse ref="secondkill"></SecondKillCourse>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import BussinessCollegePage from '../bussinesscollegepage/index.vue';
	import SecondKillCourse from '../secondkillcourse/index.vue';
	export default {
		components: {
			BussinessCollegePage,
			SecondKillCourse
		},
		data() {
			return {
				activeName:'first'
			}
		},
		mounted() {
			//默认渲染课程列表
			this.$emit('collegeList')
			//默认渲染课程分类
			this.$emit('collegeType')
			if (this.$route.query.activeName == 'second') {
				this.activeName=this.$route.query.activeName;
				this.$refs.secondkill.getSecondKillCourseList()

			}

		},
		methods: {
			handleClick(val) {
				if (val.name == 'first') {
					this.$nextTick(() => {
						this.$refs.business.$refs.colleges.getCollegeList()
					})
				}
				if (val.name == 'second') {
					this.$nextTick(() => {
						this.$refs.secondkill.getSecondKillCourseList()
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	::v-deep.el-tabs__header {
		padding: 0;
		position: relative;
		margin: 0px 0px 0px 15px;
	}
</style>
