import axios from '@/config/httpConfig'
import qs from  'qs'
//淘货商品数据
export const shopGoodsList=(params)=> {
	  return axios('get','Activity/shop/getGoodsList', params)
}
//淘货商品详情页
export const ShoppingMerchandiseDetails=(data)=> {
  return axios('post','Goods/shop/ShoppingMerchandiseDetails', data);
}
//商品铺货1 上架到店铺
export const StoreManagerSpreadGoodsTransfer=(data)=> {
  return axios('post','Goods/shop/StoreManagerSpreadGoodsTransfer', data);
}
//淘货所有商品分页
export const getAdminGoodsPageList=(params)=> {
	  return axios('get','Activity/shop/getAdminGoodsPageList', params)
}