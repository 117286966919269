<template>
	<div>
		<div class="wrapper-main">
			<div class="ms-title titles">
				<li v-for="(item,index) in titleItem" :key="item.name" :class="{active : curIndex == index}"
					@click="selected(index)">{{item.name}}</li>
			</div>
			<!--活动管理-->
			<div v-if="curIndex==0">
				<div class="wrapper-cont">
					<!-- v-if="activityData && activityData.length>0" -->
					<div class="alertstyle">
						<el-alert title="活动最多设置两个活动推送至移动端售卖" :closable="false" type="warning">
						</el-alert>
					</div>
					<div class="main-cont bgwt mt10 pp20">
						<el-row :gutter="20">
							<el-col :span="10">
								<div class="grid-content bg-purple">
									<el-button size="small" @click="addActivity" v-has="'活动管理/新建活动'" >创建活动</el-button>
									<el-button size="small" @click="isDisabled('play_y')" v-has="'活动管理/启用'" >启用</el-button>
									<el-button size="small" @click="isDisabled('play_n')" v-has="'活动管理/禁用'" >禁用</el-button>
								</div>
							</el-col>
							<el-col :span="14">
								<div class="grid-content bg-purple" style="text-align: right;">
									<el-row class="all_search_list" style="margin-bottom: 0;">
										<div class="demo-input-suffix">
											<el-select size="small" class="w120" v-model="activity_state"
												@change="changeActivity" placeholder="全部状态">
												<el-option label="全部状态" value=""></el-option>
												<el-option label="未开启" value="0"></el-option>
												<el-option label="已开启" value="1"></el-option>
												<el-option label="已停止" value="2"></el-option>
											</el-select>
										</div>
										<div class="demo-input-suffix">
											<el-input class="inputWidth" prefix-icon="el-icon-search" clearable @keyup.enter.native="toSearchActivity"
												v-model="activity_name" placeholder="请输入活动名称" size="small" type="text">
											</el-input>
										</div>
										<div class="demo-input-suffix">
											<el-button type="primary" size="small" @click="toSearchActivity">搜索
											</el-button>
										</div>
										<div class="demo-input-suffix">
											<el-button size="small" @click="toResetActivity">重置</el-button>
										</div>
									</el-row>
								</div>
							</el-col>
						</el-row>
					</div>
					<div class="main-cont tableMain mt10">
						<el-table :data="activityData" style="width: 100%" min-height="250" border
							@selection-change="handleSelectionChange" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
							<el-table-column type="selection" width="55">
							</el-table-column>
							<el-table-column label="活动名称" prop="activity_name" align="center"></el-table-column>
							<el-table-column label="活动标签" prop="activity_tag" align="center">
								<template slot-scope="scope">
									<el-image :src="scope.row.activity_tag" style="width: 70px;height: 35px;" :preview-src-list="[scope.row.activity_tag]"></el-image>
									<!-- <img :src="scope.row.activity_tag" style="width: 70px;height: 35px;" /> -->
								</template>
							</el-table-column>
							<el-table-column label="活动商品(件)" prop="goods_num" align="center"></el-table-column>
							<el-table-column label="活动状态" prop="state" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.state == '0'">未开启</span>
									<span v-else-if="scope.row.state == '1'">已开启</span>
									<span v-if="scope.row.state == '2'">已停止</span>
								</template>
							</el-table-column>
							<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
							<el-table-column label="操作" align="center" fixed="left">
								<template slot-scope="scope">
									<el-button type="text" size="small" @click="seeGood(scope.row)" v-has="'活动管理/查看商品'" >查看商品</el-button>
									<el-button type="text" size="small" @click="activityDetail(scope.row)" v-has="'活动管理/活动详情'" >活动详情
									</el-button>
									<el-button type="text" size="small" v-if="scope.row.state != 2"
										@click="toStop(scope.row)" v-has="'活动管理/停止活动'" >停止活动</el-button>
									<el-button type="text" size="small" @click="toDel(scope.row)" v-has="'活动管理/删除'" >删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<!-- 		<div class="wrapper-cont" v-else>
					<div class="main-cont">
						<div class="no_activity_pic">
							<img src="../../../../../../assets/img/plat/noActivity.png" />
						</div>
						<div class="no_activity_tip">创建牛店淘货活动，快速推送实现店长快速精准铺货</div>
						<div class="mt20" style="text-align: center;padding-bottom: 100px;">
							<el-button size="small" type="primary"  @click="addActivity">立即创建</el-button>
						</div>
					</div>
				</div> -->
			</div>
			<!--banner管理-->
			<div v-if="curIndex==1">
				<div class="wrapper-cont">
					<div class="main-cont bgwt mt10 pp20">
						<el-row :gutter="20">
							<el-col :span="10">
								<div class="grid-content bg-purple">
									<el-button size="small" @click="addLoop" v-has="'Banner管理/新建轮播'" >新建轮播</el-button>
									<el-button size="small" @click="isDisabled('banner_y')" v-has="'Banner管理/启用'" >启用</el-button>
									<el-button size="small" @click="isDisabled('banner_n')" v-has="'Banner管理/禁用'" >禁用</el-button>
								</div>
							</el-col>
							<el-col :span="14">
								<div class="grid-content bg-purple" style="text-align: right;">
									<el-row class="all_search_list" style="margin-bottom: 0;">
										<div class="demo-input-suffix">
											<el-select size="small" class="w120" v-model="loop_states"
												@change="changeBanner" placeholder="全部状态">
												<el-option label="全部状态" value=""></el-option>
												<el-option label="未启用" value="0"></el-option>
												<el-option label="启用" value="1"></el-option>
												<el-option label="禁用" value="2"></el-option>
											</el-select>
											<el-input class="inputWidth" prefix-icon="el-icon-search"  @keyup.enter.native="toSearchBanner" clearable
												placeholder="请输入轮播名称" size="small" type="text" v-model="banner_name">
											</el-input>
											<el-button type="primary" size="small" @click="toSearchBanner">搜索
											</el-button>
											<el-button size="small" @click="toResetBanner">重置</el-button>
										</div>
									</el-row>
								</div>
							</el-col>
						</el-row>

					</div>
					<div class="main-cont tableMain mt10">
						<el-table :data="bannerData" style="width: 100%" min-height="250" border
							@selection-change="handleSelectionBanner" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
							<el-table-column type="selection" width="55">
							</el-table-column>
							<el-table-column label="标题" prop="title" align="center"></el-table-column>
							<el-table-column label="图片" prop="img" align="center">
								<template slot-scope="scope">
									<img :src="scope.row.pc_pic" class="el_img" />
								</template>
							</el-table-column>
							<el-table-column label="排序" prop="sort" align="center" sortable></el-table-column>
							<el-table-column label="活动状态" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.state == '0'">未启用</span>
									<span v-else-if="scope.row.state == '1'">启用</span>
									<span v-if="scope.row.state == '2'">禁用</span>
								</template>
							</el-table-column>
							<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
							<el-table-column label="操作" align="center" fixed="right">
								<template slot-scope="scope">
									<el-button type="text" size="small" @click="toEditBanner(scope.row)" v-has="'Banner管理/编辑'" >编辑</el-button>
									<el-button type="text" size="small" @click="toDelBanner(scope.row)" v-has="'Banner管理/删除'" >删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
			<!--公告管理-->
			<div class="pd20 " v-if="curIndex==2">
				<div class="wrapper-cont">
					<div class="main-cont  setposition">
						<div class="setTitle">公告管理</div>
						<div class="ml100">
							<div class="img_blos" @click="uploadImg()">
								<div class="img_blo">
									<img :src="notice.notice_pic" class="item_img" v-if="notice.notice_pic" />
									<div class="plusImg" v-else>
										<i class="el-icon-plus avatar-uploader-icon"></i>
									</div>
								</div>
							</div>
							<div class="imgtxtstyle mt10">
								只支持.jpg 格式，702*303 px（默认为首页主推板块大图）
							</div>
						</div>
						<div class="sample" @mouseenter="mouseoverNotice" @mouseleave="mouseleaveNotice">示例</div>
						<div style="position: absolute;z-index: 200;top:0;right: 100px;pointer-events:none;">
							<img src="../../../../../../assets/img/plat/market/noticeManage.png" style=" width: 227px;"
								v-show="noticeshow" />
						</div>
					</div>
					<div class="main-cont mt10 setposition">
						<div class="setTitle">活动标题</div>
						<div class="ml100">
							<el-row class="all_search_list">
								<div class="demo-input-suffix">
									<span class="mr10">大标题</span>
									<el-input size="small" maxlength="4" show-word-limit placeholder="请输入大标题"
										class="inputWidth" v-model="notice.headline_first"></el-input>
								</div>
								<div class="demo-input-suffix">
									<span class="mr10">小标题</span>
									<el-input size="small" maxlength="4" show-word-limit placeholder="请输入小标题"
										class="inputWidth" v-model="notice.subhead_first"></el-input>
								</div>
							</el-row>
							<el-row class="all_search_list">
								<div class="demo-input-suffix">
									<span class="mr10">大标题</span>
									<el-input size="small" maxlength="4" show-word-limit placeholder="请输入大标题"
										class="inputWidth" v-model="notice.headline_second"></el-input>
								</div>
								<div class="demo-input-suffix">
									<span class="mr10">小标题</span>
									<el-input size="small" maxlength="4" show-word-limit placeholder="请输入小标题"
										class="inputWidth" v-model="notice.subhead_second"></el-input>
								</div>
							</el-row>
						</div>
						<div class="sample" @mouseenter="mouseoverCaption" @mouseleave="mouseleaveCaption">示例</div>
						<div style="position: absolute;z-index: 200;top:0;right: 100px;pointer-events:none;">
							<img src="../../../../../../assets/img/plat/market/activityCaption.png"
								style=" width: 227px;" v-show="captionshow" />
						</div>
					</div>

				</div>
				<div class="save-cont">
					<div class="save-btn" @click="submitSet()">保存</div>
				</div>
			</div>
		</div>

		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		getActivityList,
		onActivity,
		offActivity,
		delActivity,
		getBannerList,
		onBanner,
		offBanner,
		delBanner,
		addNotice,
		getNoticeInfo,
		editNotice
	} from '@/api/plat/market.js'
	import ChooseImg from "../../../../../common/uploadImgs";
	export default {
		components: {
			ChooseImg,
		},
		data() {
			return {
				loading:true,
				curIndex: 0,
				activity_name: '',
				banner_name: '',
				titleItem: [{
						id: 1,
						name: '活动管理'
					}, {
						id: 2,
						name: 'Banner管理'
					},
					{
						id: 3,
						name: '公告管理'
					}
				],
				activityData: [],
				multipleSelection: [],
				bannerData: [],
				multipleSelectionBanner: [],
				isSingle: false, //上传图片是单张还是多张
				uploadFlag: false, //是否展示选择图片弹窗
				notice: {
					notice_pic: '',
					headline_first: '',
					subhead_first: '',
					headline_second: '',
					subhead_second: '',
				},
				noticeshow: false,
				captionshow: false,
				activity_state: '',
				loop_states: '',
				notice_id: ''
			}
		},
		mounted() {
			this.getList();
			if (this.$route.query.curIndex) {
				this.curIndex = this.$route.query.curIndex;
				this.getBannerData()
			}
		},
		methods: {
			//活动列表
			getList() {
				let params = {
					keyword: this.activity_name,
					state: this.activity_state,
				}
				params = this.tools.filterParams(params);
				getActivityList(params).then(res => {
					this.loading=false;
					this.activityData = res.data;

				})
			},
			//banner列表
			getBannerData() {
				let data = {
					keyword: this.banner_name,
					state: this.loop_states
				}
				data = this.tools.filterParams(data);
				getBannerList(data).then(res => {
					this.loading=false;
					this.bannerData = res.data

				})

			},
			//切换状态查询
			changeActivity(val) {
				this.activity_state = val;
			},
			//切换banner状态查询
			changeBanner(val) {
				this.loop_states = val;
			},
			//活动搜索
			toSearchActivity() {
				//调接口
				this.getList()
			},
			toResetActivity() {
				this.activity_name = '';
				this.activity_state = '';
				this.getList()
			},
			//切换菜单
			selected(indx) {
				this.curIndex = indx;
				if (indx == "0") {
					this.getList()
				}
				if (indx == "1") {
					this.getBannerData()
				}
				if (indx == '2') {
					this.getNoticeInfoData()
				}
			},
			//创建活动
			addActivity() {
				this.$router.push({
					path: "/marketingadmin/cattleshopgood/newactivities"
				})
			},
			//活动多选
			handleSelectionChange(val) {
				let aa = [];
				val.forEach(item => {
					aa.push(item.activity_id)
				})
				this.multipleSelection = aa;
			},
			//启用禁用
			isDisabled(type) {
				if (type == 'play_y' || type == 'play_n') {
					if (this.multipleSelection.length == 0) {
						this.$message.info("请选择活动！")
						return;
					}
					//调接口
					if (type == 'play_y') {
						this.tools.confirm("确定要批量启用吗？").then(res => {
							if (res) {
								let data = {
									activity_ids: this.multipleSelection.toString()
								}
								data = this.tools.filterParams(data);
								onActivity(data).then(res => {
									if (res.return_code == 'SUCCESS') {
										this.getList()
									}

								})
							}

						})
					}
					if (type == 'play_n') {
						this.tools.confirm("确定要批量禁用吗？").then(res => {
							if (res) {
								let data = {
									activity_ids: this.multipleSelection.toString()
								}
								data = this.tools.filterParams(data);
								offActivity(data).then(res => {
									if (res.return_code == 'SUCCESS') {
										this.getList()
									}

								})
							}

						})
					}
				}
				if (type == 'banner_y' || type == 'banner_n') {
					if (this.multipleSelectionBanner.length == 0) {
						this.$message.info("请选择轮播！")
						return;
					}
					//调接口
					if (type == 'banner_y') {
						this.tools.confirm("确定要批量启用吗？").then(res => {
							if (res) {
								let data = {
									banner_ids: this.multipleSelectionBanner.toString()
								}
								data = this.tools.filterParams(data);
								onBanner(data).then(res => {
									if (res.return_code == 'SUCCESS') {
										this.getBannerData()
									}

								})
							}
						})
					}
					if (type == 'banner_n') {
						this.tools.confirm("确定要批量禁用吗？").then(res => {
							if (res) {
								let data = {
									banner_ids: this.multipleSelectionBanner.toString()
								}
								data = this.tools.filterParams(data);
								offBanner(data).then(res => {
									if (res.return_code == 'SUCCESS') {
										this.getBannerData()
									}
								})
							}

						})
					}
				}
			},

			//活动管理分页
			handleSizeChange(size) {
				this.pagesize = size;
				if (this.curIndex == 0) {
					//调活动管理列表

				} else if (this.curIndex == 1) {
					//banner管理列表
				}
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				if (this.curIndex == 0) {
					//调活动管理列表

				} else if (this.curIndex == 1) {
					//banner管理列表
				}
			},
			//查看商品
			seeGood(row) {
				this.$router.push({
					path: "/marketingadmin/cattleshopgood/activitygoods",
					query: {
						id: row.activity_id
					},
				})
			},
			//活动详情
			activityDetail(row) {
				this.$router.push({
					path: "/marketingadmin/cattleshopgood/newactivities",
					query: {
						id: row.activity_id
					},
				})
			},
			//停止活动
			toStop(row) {
				this.tools.confirm('确认停止该活动？').then(res => {
					if (res) {
						//调接口
						let data = {
							activity_ids: row.activity_id
						}
						data = this.tools.filterParams(data);
						offActivity(data).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.getList()
							}

						})
					}
				})
			},
			//删除活动
			toDel(row) {
				this.tools.confirm('确定要删除吗？').then(res => {
					if (res) {
						//调接口
						delActivity({
							activity_id: row.activity_id
						}).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.getList()
							}
						})
					}
				})
			},



			//新建Banner轮播
			addLoop() {
				this.$router.push({
					path: "/marketingadmin/cattleshopgood/createlooppic"
				})
			},
			//banner搜索
			toSearchBanner() {
				//调接口
				this.getBannerData()
			},
			toResetBanner() {
				this.banner_name = '';
				this.loop_states = '';
				this.getBannerData()
			},
			//banner多选
			handleSelectionBanner(val) {
				val.forEach(item => {
					this.multipleSelectionBanner.push(item.banner_id)
				})
			},
			//banner编辑
			toEditBanner(row) {
				this.$router.push({
					path: "/marketingadmin/cattleshopgood/createlooppic",
					query: {
						id: row.banner_id,
					},
				})
			},
			//banner删除
			toDelBanner(row) {
				this.tools.confirm("确定要删除？").then(res => {
					if (res) {
						//调接口
						let data = {
							banner_id: row.banner_id
						}
						delBanner(data).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.getBannerData()
							}
						})
					}
				})
			},
			// 上传商品图
			uploadImg() {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				this.notice.notice_pic = val[0].resource_name;
			},
			//示例显示隐藏
			mouseoverNotice() {
				this.noticeshow = true;
			},
			mouseleaveNotice() {
				this.noticeshow = false;
			},
			mouseoverCaption() {
				this.captionshow = true;
			},
			mouseleaveCaption() {
				this.captionshow = false;
			},
			//添加公告
			submitSet() {
				// if (this.notice_id) { //公告编辑
				// 	let data = this.notice;
				// 	data.notice_id = this.notice_id;
				// 	data.notice_pic = this.tools.filterImgPre(data.notice_pic)
				// 	editNotice(data).then(res => {
				// 		if (res.return_code == 'SUCCESS') {
				// 			this.$message.success("设置成功");
				// 			this.getNoticeInfoData();
				// 		}
				// 	}).catch(err => {
				// 		this.$message.error(err.return_msg)
				// 	})
				// } else { //公告添加
				this.notice=JSON.parse(JSON.stringify(this.notice));
				this.notice.notice_pic = this.tools.filterImgPre(this.notice.notice_pic);
					//let notice_pic=this.tools.filterImgPre(JSON.parse(JSON.stringify(this.notice.notice_pic)));
					let data = this.notice;
					//data.notice_pic=notice_pic;
					addNotice(data).then(res => {
						if (res.return_code == 'SUCCESS') {
							this.getNoticeInfoData();
							this.$message.success("设置成功");
							this.notice_id = res.data;
						}
					})
				// }
			},
			//获取公告详情
			getNoticeInfoData() {
				let params = {
					notice_id: this.notice_id
				}
				getNoticeInfo(params).then(res => {
					if (res.data != '') {
						this.notice = res.data
					}

				})
			},



		}
	}
</script>

<style scoped="scoped" lang="scss">
	@import "@/assets/css/plat/market.scss"; // .com-image-drag
</style>
