<template>
	<div>
		<div class="wrapper-cont" style="padding: 0;">
			<div class="shopmain" ref="scrollbox" style="margin-top: 20px;">
				<!--头部-->
				<div class="wrapper-main pp20" style="margin-top:44px">
					<el-row type="flex" class="row-bg" justify="space-between">
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div class="flex">
									<img :src="head_portrait" class="head_portrait" />
									<div class="shop_title">牛店-淘货市场</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" style="text-align: right;">
							<div class="grid-content bg-purple">
								<el-input placeholder="请输入关键字" size="small" class="search_input"
									suffix-icon="el-icon-search" v-model="keyword"  @keyup.enter.native="searchGood" clearable>
									<template slot="append" class="searchbtns"> <span
											@click="searchGood">搜索</span></template>
								</el-input>
							</div>
						</el-col>
					</el-row>
				</div>
				<!--菜单-->
				<div class="blo_banner flex">
					<div class="blo_left">
						<div class="top_item">全部分类</div>
						<div class="name_blo" :style="{overflowY:isShow ? 'scroll':'hidden'}">
							<div v-for="(item,index) in dataList.categoryList" :key="index" class="cats_name">
								{{item.name}}
								<div class="cat"
									:style="{height:dataList.categoryList && dataList.categoryList.length > 5 ? '257px' : '217px'}">
									<div class="catin">
										<div class="cat_name">
											<span></span>{{item.name}}
											<div class="cat_line"></div>
										</div>
										<div class="item_blo">
											<div v-for="(blo,index2) in item.children" @click="toList(blo.id)"
												:key="index2" class="item">
												{{blo.name}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mores" @click="seeMores"
							v-if="dataList.categoryList && dataList.categoryList.length > 5">
							<span>查看全部分类</span>
							<img src="../../../../../assets/img/shopping/arrow_right.png"
								:style="{transform: !isShow ? 'rotate(90deg)' : 'rotate(270deg)'}" />
						</div>
					</div>
					<div class="blo_right">
						<el-carousel>
							<el-carousel-item v-for="(item,index) in dataList.bannerList" :key="index">
								<img :src="item.pc_pic" class="slide_img" v-if="item.pc_click=='3'" @click="toGoods(item)" />
								<img :src="item.pc_pic" class="slide_img" v-if="item.pc_click=='2'" @click="toImgs(item)" />
								<img :src="item.pc_pic" class="slide_img" v-else />
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<!--低价专区-->
				<div v-for="(low,index) in dataList.activityList" :key="index">
					<div class="type_title" v-if="low.goodsList.length>0">
						<el-row type="flex" class="row-bg" justify="space-between">
							<el-col :span="6">
								<div class="grid-content bg-purple">
									<div class="gootype">
										<span></span>{{low.activity_name}}
									</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div class="grid-content bg-purple">
									<div class="moreGood" @click="toMore(low.activity_id)">
										<span>更多商品</span><img style="vertical-align: middle;"
											src="../../../../../assets/img/shopping/arrow_right.png" />
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
					<div class="good_cons">
						<div v-for="(item,index) in low.goodsList" :key="index" class="cons">
							<img v-if="item.goods_main_pic" :src="item.goods_main_pic" class="con_img"
								@click="toDet(item)" />
							<div class="con_text">
								<div class="con_blos" v-if="item.is_put == 0"></div>
								<div class="title rich-omit">{{item.goods_name}}</div>
								<div class="clearfix">
									<div class="sales" style="float: left;">已售 {{item.sale}}件</div>
									<div style="float: right;" v-if="item.is_put == 1">
										<el-tag type="info" size="small">已入库</el-tag>
									</div>
								</div>
								<div class="ords" v-if="item.is_put == 0" @click="addOrds(item)">放入仓库</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 公用客服 -->
		<div class="common_service">
			<div class="item_blo">
				<div class="items" @mouseover="showOrHide">
					<img src="../../../../../assets/img/common_kefu.png" />
					<div>客服咨询</div>
					<div class="line"></div>
					<div class="consule_blo">
						<img src="../../../../../assets/img/consule.png" class="blo_img">
						<div class="blo_div">
							<!-- <img src="../assets/images/join_close.png" class="div_close" @click="showOrHide"> -->
							<div class="blo_con">
								<div class="con_div">想了解更多，欢迎拨打热线：<span
										class="span1">17603083828</span><span>你也可以留下联系方式，我们会主动和你联系</span></div>
								<div class="con_btn" @click="toConsule">在线咨询</div>
							</div>
						</div>
					</div>
				</div>
				<div class="items">
					<img src="../../../../../assets/img/common_code.png" />
					<div>添加好友</div>
					<div class="line"></div>
					<div class="friend_blo" :style="{display:addFriend ? 'block' : 'none'}">
						<img src="../../../../../assets/img/fried_code.png" class="code_img" />
					</div>
				</div>
				<!-- 铺货中心才会显示 -->
				<div class="items content-container-top" @click="toOrds" v-if="isGoods">
					<img src="../../../../../assets/img/common_ords.png" />
					<div>商品仓</div>
					<!-- <div class="line"></div> -->
				</div>
			<!-- 	<div class="items content-container-top" @click="toTop()">
					<img src="../../../../../assets/img/common_top.png" />
					<div style="paddingTop:8px">
						UP
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {
		shopGoodsList,
		StoreManagerSpreadGoodsTransfer
	} from "@/api/merchant/shopping.js"
	export default {
		data() {
			return {
				head_portrait: localStorage.getItem("head_portrait"),
				keyword: '',
				isShow: false, //是否展示全部分类
				dataList: {},
				btnFlag: false,
				items: {},
				addFriend: false, //是否展示添加好友弹出层
				isGoods: true, //是否在铺货中心
				isActive: false,
				offsetTop: 0,
				pos: 0
			}
		},
		mounted() {
			this.getList();

			window.addEventListener('scroll', this.scrollToTop)
		},
		destroyed () {
		window.removeEventListener('scroll', this.scrollToTop)
		
		},
		methods: {
			getList() {
				shopGoodsList({}).then(res => {
					res.data.activityList.forEach(act => {
						act.goodsList.forEach(goo => {
							goo.goods_main_pic = goo.goods_main_pic[0]
						})
					})
					this.dataList = res.data;
				})
			},
			// 去商品列表
			toList(id) {
				this.$router.push({
					path: '/shoppingadmin/moreclassify',
					query: {
						category_id: id || ''
					}
				})
			},
			// 显示隐藏分类
			seeMores() {
				this.isShow = !this.isShow;
			},
			// 去商品详情
			toDet(item) {
				this.$router.push({
					path: '/shoppingadmin/detailofgood',
					query: {
						id: item.original_goods_unified_id
					}
				})
			},
			//上架至店铺
			addOrds(item) {
				let obj = {},
					arr = [];
				obj[item.primeval_goods_id] = item.original_goods_unified_id;
				arr.push(obj)
				let data = {
					goodsInfo: JSON.stringify(arr),
					is_publish: '2'
				}
				StoreManagerSpreadGoodsTransfer(data).then(res => {
					this.getList()
				})
			},
			//搜索
			searchGood() {
				this.$router.push({
					path: '/shoppingadmin/moreclassify',
					query: {
						keyword: this.keyword
					}
				})
			},
			toGoods(row){
				localStorage.setItem('pc_click_content', row.pc_click_content);
				this.$router.push({
					path: '/shoppingadmin/moreclassify',
				})
			},
			toImgs(row){
				localStorage.setItem('pc_click_img', row.pc_click_content);
				this.$router.push({
					path: '/shoppingadmin/detailofimg',
				})
			},
			//更多商品
			toMore(activity_id) {
				this.$router.push({
					path: '/shoppingadmin/moreclassify',
					query: {
						activity_id: activity_id
					}
				})
			},
			// 关闭or打开弹出层
			showOrHide() {
				// this.consuleFlag = !this.consuleFlag;
				this.addFriend = false;
			},
			// 点击咨询
			toConsule() {
				this.$message.error("客服小姐姐跑丢了~~~");
			},
			// 去订货单页面
			toOrds() {
				this.$router.push({
					path: '/merchandiseadmin/cattleshopmerchandise/cattleshoplist'
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	@import "../../../../../assets/css/shopping/shopping.scss";

	.page-up {
		background-color: #409eff;
		position: fixed;
		right: 50px;
		bottom: 30px;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		cursor: pointer;
		transition: .3s;
		box-shadow: 0 3px 6px rgba(0, 0, 0, .5);
		opacity: .5;
		z-index: 100;

		.el-icon-caret-top {
			color: #fff;
			display: block;
			line-height: 40px;
			text-align: center;
			font-size: 18px;
		}

		p {
			display: none;
			text-align: center;
			color: #fff;
		}

		&:hover {
			opacity: 1;
		}
	}

	/* 公用客服样式 */
	.common_service {
		position: fixed;
		right: 50px;
		top: 50vh;
		display: flex;
		align-items: center;
		z-index: 999;
		background: #CF1717;

		.item_blo {
			width: 60px;
			color: #fff;
			font-size: 12px;
			padding: 12px 0;
			box-sizing: border-box;
			cursor: pointer;

			.line {
				width: 46px;
				margin: 8px auto 10px;
				border-top: 2px solid #A31B1B;
			}
		}

		.items {
			text-align: center;
			position: relative;

			&:hover .consule_blo {
				display: flex;
			}

			&:hover .friend_blo {
				display: block !important;
			}

			image {
				width: 36px;
				height: 36px;
				margin: 0 auto 4px;
				display: block;
			}


			.con {
				border-bottom: 1px solid #A31B1B;
				padding: 0 12px;
			}

			.item_circle {
				position: absolute;
				right: -12px;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background: #FF9F01;
				text-align: center;
				line-height: 24px;
				font-size: 12px;
				border: 1px solid #fff;
				top: -8px;
			}
		}

		.items:last-child {
			padding-bottom: 0;
		}

		.friend_blo {
			position: absolute;
			right: 50px;
			top: -66px;
			display: none;
			width: 200px;
			height: 200px;
			margin-right: 28px;


		}

		.consule_blo {
			display: none;
			width: 386px;
			height: 200px;
			right: 60px;
			position: absolute;
			top: -10px;
			justify-content: center;

			.blo_div {
				background: #fff;
				box-shadow: 0px 2px 8px 0px rgba(231, 231, 231, 0.83);
			}

			filter: blur(0px);

			.blo_img {
				width: 80px;
				height: 80px;
				position: absolute;
				top: -40px;
			}

			.blo_div {
				.div_close {
					width: 22px;
					height: 22px;
					position: absolute;
					right: 42px;
					top: 12px;
					cursor: pointer;
				}

				.blo_con {
					font-size: 16px;
					color: #c1c1c1;
					margin: 66px auto 14px;

					.con_div {
						margin: 26px auto 14px;
						padding: 0 25px;
						line-height: 1.5;
						text-align: center;

						.span1 {
							font-weight: bold;
							font-size: 20px;
						}

						.span2 {
							font-size: 18px;
							color: #c2c2c2;
						}
					}

					.con_btn {
						width: 132px;
						height: 40px;
						border: 1px solid #D01717;
						border-radius: 4px;
						text-align: center;
						line-height: 40px;
						color: #D01717;
						font-size: 16px;
						margin: 0 auto;
						cursor: pointer;
					}
				}
			}
		}
	}
// .shopmain{
// 	height: 800px;
// }
	
</style>
