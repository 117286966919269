import axios from '@/config/httpConfig'
import qs from  'qs'
//专场商品库
export const specialMerchandiseList=(params)=> {
	   return axios('post','Goods/shop/specialMerchandiseList', params)
}
//专场列表
export const getActivitiesReset=(params)=> {
	  return axios('get','/Fitment/shop/getActivitiesReset', params)
}
//专场状态修改
export const saveActivitiesResetStatus=(params)=> {
	   return axios('post','/Fitment/shop/saveActivitiesResetStatus', params)
}
//专场添加/修改
export const saveActivitiesResetInfo=(params)=> {
	   return axios('post','/Fitment/shop/saveActivitiesResetInfo', params)
}
//专场详情
export const getActivitiesResetInfo=(params)=> {
	  return axios('get','/Fitment/shop/getActivitiesResetInfo', params)
}
//专场商品列表
export const getActivitiesResetGoodsList=(params)=> {
	  return axios('get','/Fitment/shop/getActivitiesResetGoodsList', params)
}
//专场商品添加
export const saveActivitiesResetGoods=(params)=> {
	   return axios('post','/Fitment/shop/saveActivitiesResetGoods', params)
}
//专场商品-主推
export const saveActivitiesResetGoodsHot=(params)=> {
	   return axios('post','/Fitment/shop/saveActivitiesResetGoodsHot', params)
}
//专场商品-删除-批量
export const activitiesResetGoodsDel=(params)=> {
	   return axios('post','/Fitment/shop/activitiesResetGoodsDel', params)
}
//专场删除
export const activitiesResetDel=(params)=> {
	   return axios('post','Fitment/shop/activitiesResetDel', params)
}