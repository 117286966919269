<template>
	<div>
		<platOrderDetail :order_type="order_type"></platOrderDetail>
	</div>
</template>

<script>
	import platOrderDetail from "../../../../../pagecomponents/platOrderDetail.vue"
	export default{
		components:{
			platOrderDetail
		},
		data(){
			return{
				order_type:''
			}
		},
		mounted() {
			this.order_type = this.$route.query.type
		},
	}
</script>

<style scoped lang="scss">

</style>
