import axios from '@/config/httpConfig'
import qs from  'qs'

//佣金分润列表
export const FenRunEarningsList=(data)=> {
  return axios('post',"Commission/channel/ChannelFenRunEarningsList", data)
}
//服务商列表金额
export const channelAgentCommissionStatistics=(data)=> {
  return axios('post',"Commission/channel/channelAgentCommissionStatistics", data)
}
//门店明细详情列表
export const shopFundDetails=(data)=> {
  return axios('post',"Commission/channel/shopFundDetails", data)
}
//门店明细详情-金额
export const shopUsableAmount=(data)=> {
  return axios('post',"Commission/channel/shopUsableAmount", data)
}