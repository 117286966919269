<template>
	<div>
		<div class="wrapper-main">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="添加类目" name="first">
					<VideoNumberCategory ref="category"/>
				</el-tab-pane>
				<el-tab-pane label="审核列表" name="second">
					<VideoNumberAudit ref="audit"/>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import VideoNumberCategory from '../videonumbercategory/index.vue';
	import VideoNumberAudit from '../videonumberaudit/index.vue';
	export default{
		components:{
			VideoNumberCategory,
			VideoNumberAudit
		},
		data(){
			return{
				activeName:'first'
			}
			
		},
		methods:{
			//切换
			handleClick(val){
				if(val.name=='second'){
					this.$nextTick(()=>{
						this.$refs.audit.tableData=[];
						this.$refs.audit.getList()
					})
					
				}
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	>>>.el-tabs__header {
		padding: 0;
		position: relative;
		margin: 0px 0px 0px 15px;
	}
	::v-deep .el-tabs__nav-wrap::after{
		background-color: #fff;
	}
</style>
