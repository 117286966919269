<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<div class="all_search_list" style="margin-bottom: 0;">
						<el-input prefix-icon="el-icon-search"  @keyup.enter.native="toSearch" clearable v-model="keyword" class="inputWidth"
							placeholder="门店账户/门店名称" size="small" type="text">
						</el-input>
						<el-button style="margin-left: 10px;" type="primary" size="small"  @click="toSearch">搜索</el-button>
						<el-button size="small" @click="toReset">重置</el-button>
					</div>
				</div>
				<div class="main-cont bgwt mt10">
					<el-table :data="tableData" border style="width: 100%"  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="username" label="门店名称" align="center">
						</el-table-column>
						<el-table-column prop="account" label="登录账户" align="center">
						</el-table-column>
						<el-table-column prop="income" label="收入(元)"  align="center" sortable>
							<template slot-scope="scope">
								<p style="color:#4B9200">{{scope.row.income}}</p>
								<p>{{scope.row.incomeNum}}笔</p>
							</template>
						</el-table-column>
						<el-table-column prop="expend" label="支出(元)"  align="center" sortable>
							<template slot-scope="scope">
								<p>{{scope.row.paid}}</p>
								<p>{{scope.row.paidNum}}笔</p>
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作"   align="center">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.row)" type="text" size="small" v-has="'详情'"  >详情</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
			shopFundList
		} from '@/api/plat/finance'
	export default {
		data() {
			return {
				loading:true,
				keyword: '',
				tableData:[
					{
						store_name:'悟空的小店',
						login_account:'登录账户',
						income:'+ 73.00',
						income_num:4,
						expend:'- 0.10',
						expend_num:4,
					}
				],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.getList()
		},
		methods:{
			getList(){
				let params={
					keyword:this.keyword,
					page:this.currentPage,
					perPage:this.pagesize
				}
				params = this.tools.filterParams(params)
				shopFundList(params).then(res=>{
					this.loading=false;
					this.total=res.data.count;
					this.tableData =res.data.list
					
				})
			},
			toSearch(){
				this.currentPage = 1;
				this.getList()
			},
			toReset(){
				this.currentPage = 1;
				this.keyword='';
				this.getList()
			},
			handleClick(row){
				this.$router.push({
					path:'/financeadmin/storeparticulars/storedetail',
					query:{
						account_shop_id:row.account_shop_id
					}
				})
			},
			handleSizeChange(size) {
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
