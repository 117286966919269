<template>
	<div class="cus_blo">
		<div class="blos">
			<div class="search_blo">
				<div class="blo_search">
					<div class="search_left" @click="showOrHideAll">
						<img
							:src="showAll ? require('../../../../../../assets/img/cat_downs.png') : require('../../../../../../assets/img/cat_ups.png')" />
						<span v-if="!showAll">展开全部</span>
						<span v-else>折叠全部</span>
					</div>
					<div>
						<el-input size="small" v-model="cateName" placeholder="请输入分类名称" class="inputWidth mr10">
						</el-input>
						<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
						<el-button size="small" @click="toReset">重置</el-button>
					</div>
					<div class="search_right">
						<el-button size="small" @click="createOneCat" v-has="'新增一级分组'">新建一级分类</el-button>
					</div>
				</div>
			</div>
			<div class="blo_table mt10">
				<el-table ref="llzc" :data="catData" :tree-props="{ children: 'children' }" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" row-key="id" max-height="630" :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
					stripe @expand-change="cellChange" border align="left">
					<!-- <el-table-column v-for="(item, index) in col" :key="`col_${index}`" :prop="dropCol[index].prop"
						:label="item.label">
					</el-table-column> -->
					<el-table-column label="分类名称" prop="name"> </el-table-column>
					<el-table-column prop="img" label="分类广告图" min-width="120" align="center">
						<template slot-scope="scope">
							<!-- <el-popover placement="right" title width="320" trigger="hover">
								<div style="width:320px;height:100px;">
									<img style="width:100%;height:100%;object-fit: cover;" :src="scope.row.img" alt />
								</div>
								<div style="width:72px;height:34px;margin: 0 auto;" slot="reference">
									<img v-if="scope.row.img" :src="scope.row.img" class="el_img" />
								</div>
							</el-popover> -->
							<div style="width:72px;height:34px;margin: 0 auto;" >
									<el-image v-if="scope.row.img" :src="scope.row.img" class="el_img" :preview-src-list="[scope.row.img]"/>
								</div>
							<!-- <img v-if="scope.row.img" :src="scope.row.img" class="el_img" /> -->
						</template>
					</el-table-column>
					<el-table-column prop="is_show" label="是否显示">
						<template slot-scope="scope">
							<div class="el_show" v-has="'显示分组'">
								<span>显示分组</span>
								<img :src="scope.row.is_show == 0? require('../../../../../../assets/img/downs.png'): require('../../../../../../assets/img/ups.png')"
									@click="showOrhide(scope)" />
							</div>
						</template>
					</el-table-column>
					<el-table-column label="分组排序" align="center" width="200">
						<template slot-scope="scope" v-if="scope.row.children">
							<el-button plain type="primary" size="mini" @click="moveTo(scope, 1)"
								v-if="scope.$index != 0" v-has="'上移'">上移</el-button>
							<el-button plain type="warning" size="mini" @click="moveTo(scope, 2)" v-if="(scope.$index + (
							catData[catData.length - 1] && catData[catData.length - 1].children && catData[catData.length - 1].children.length)
							) != (totalCount - 1)" v-has="'下移'">下移</el-button>
						</template>
					</el-table-column>
					<el-table-column label="操作" min-width="100">
						<template slot-scope="scope">
							<el-button @click="createtwoCat(scope)" type="text" size="small" v-if="scope.row.children"
								v-has="'新增二级分组'">
								新增二级分组</el-button>
							  <el-button @click="toEdit(scope)" type="text" size="small" v-has="'编辑'">编辑</el-button>
							 <el-button @click="toDels(scope.row)" type="text" size="small" v-has="'删除'"><span style="color:red">删除</span></el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<!-- 新增一级分类 -->
		<el-dialog :title="oneForm.level == 0 ? '新建一级分组' : '新建二级分组'" :visible.sync="oneCat" width="469px">
			<el-form ref="oneForms" :model="oneForm" label-width="110px" class="add_blo"
				:rules="oneForm.level == 0 ? rulesOne : rulesTwo">
				<el-form-item label="级别">
					<el-radio v-model="oneForm.level" label="0" :disabled="oneForm.level == 1">一级</el-radio>
					<el-radio v-model="oneForm.level" label="1" :disabled="oneForm.level == 0">二级</el-radio>
				</el-form-item>
				<el-form-item label="上级分组" v-show="oneForm.level == 1" prop="parent_id">
					<el-select size="small" v-model="oneForm.parent_id" placeholder="请选择"
						:disabled="oneForm.parent_id > 0">
						<el-option v-for="item in oneCats" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="oneForm.level == 0 ? '一级分组名称' : '二级分组名称'" prop="name">
					<el-input type="text" size="small" :placeholder="
		      oneForm.level == 0 ? '请输入一级分组名称' : '请输入二级分组名称'
		    " v-model="oneForm.name" maxlength="6" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="分组广告图" prop="img" v-show="oneForm.level == 0">
					<!-- v-if="oneForm.level == 0" -->
					<div class="img_blos">
						<div class="img_blo" @click="uploadImg">
							<img :src="oneForm.img" class="item_img" v-if="oneForm.img"
								style="margin-right: 0!important;" />
							<div class="plusImg" v-else>
								<i class="el-icon-plus avatar-uploader-icon"></i>
							</div>
						</div>
					</div>
					<div>建议尺寸522*160px</div>
				</el-form-item>
				<el-form-item label="分组广告图"  v-show="oneForm.level == 1">
					<!-- v-if="oneForm.level == 0" -->
					<div class="img_blos">
						<div class="img_blo" @click="uploadImg">
							<img :src="oneForm.img" class="item_img" v-if="oneForm.img"
								style="margin-right: 0!important;" />
							<div class="plusImg" v-else>
								<i class="el-icon-plus avatar-uploader-icon"></i>
							</div>
						</div>
					</div>
					<div>建议尺寸522*160px</div>
				</el-form-item>

				<el-form-item label="分组状态">
					<el-radio v-model="oneForm.is_show" label="1">显示</el-radio>
					<el-radio v-model="oneForm.is_show" label="0">隐藏</el-radio>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelAdd" size="small">取 消</el-button>
				<el-button type="primary" @click="submitAdd" size="small">确认</el-button>
			</span>
		</el-dialog>

		<!-- 选择图片弹窗 -->
		<ChooseImg :single="true" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>
<script>
	import {
		getAllCate,
		add_cate,
		del_list,
		change_cate,
		edit_cate,
		cate_list,
		changeCateSort,
	} from '@/api/plat/goods'
	import ChooseImg from "../../../../../common/uploadImgs"
	import Sortable from 'sortablejs'
	export default {
		components: {
			ChooseImg
		},
		data() {
			return {
				loading:true,
				catData: [],
				uploadFlag: false, //是否显示图片素材弹窗
				totalCount: 0, //所有分类总数
				rulesOne: {
					name: [{
						required: true,
						message: "请输入分组名称",
						trigger: "blur"
					}],
					img: [{
						required: true,
						message: '请上传分组图片',
						trigger: 'change'
					}]
				},
				rulesTwo: {
					parent_id: [{
						required: true,
						message: "请选择上级分组",
						trigger: "blur"
					}],
					// img: [{
					// 	required: true,
					// 	message: '请上传分组图片',
					// 	trigger: 'change'
					// }],
					name: [{
						required: true,
						message: "请输入分组名称",
						trigger: "blur"
					}]
				},
				oneForm: {
					parent_id: "",
					level: "0",
					name: "",
					img: "",
					is_show: "1",
				},
				defaultForm: {
					parent_id: "",
					level: "0",
					name: "",
					img: "",
					is_show: "1",
				},
				oneCat: false, //是否展示一级分类弹出层
				showAll: false, //是否展开全部
				currentRow: [],
				expends: [], //
				levelName: 0, //默认一级
				oneCats: [],
				cateName: ''
			}
		},
		mounted() {
			//this.rowDrop()
			this.getAlls()
		},
		methods: {
			//显示图片素材弹出层
			uploadImg() {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},

			// 点击确定
			chooseOk(val) {
				this.uploadFlag = !this.uploadFlag;
				this.oneForm.img = val[0].resource_name;

			},
			getOneCat() {
				cate_list({
						parent_id: '0',
						type: '0'
					})
					.then((res) => {
						this.oneCats = res.data;
					})
					
			},
			getAlls() {
				let data = {
					name: this.cateName
				}
				data = this.tools.filterParams(data);
				getAllCate(data)
					.then((res) => {
						this.loading=false;
						this.catData = res.data.list;
						let aa = [];
						this.catData.forEach(item => {
							aa.push(...item.children)
						});
						this.totalCount = res.data.list.length + aa.length
					})
				
			},
			toSearch() {
				this.getAlls();
			},
			toReset() {
				this.cateName = '';
				this.getAlls();
			},
			//等级图上传
			uploadLimitImage1(params) {
				if (
					!(
						params.file.type === "image/png" ||
						params.file.type === "image/gif" ||
						params.file.type === "image/jpg" ||
						params.file.type === "image/jpeg"
					)
				) {
					return;
				} else {
					this.getImagePost(params.file, 1);
				}
			},
			handleRemove1(file, fileList1) {
				for (var i = 0; i < this.detailList.length; i++) {
					if (fileList1[i].uid === file.uid) {
						this.detailList.splice(i, 1);
					}
				}
			},
			// 单图上传接口
			getImagePost(image, type) {
				var data = {
					goods_image: image,
				};
				upLoadMoreImg(data, {
						"Content-Type": "multipart/form-data",
					})
					.then((response) => {
						if (type == 0) {
							this.graphList.push(response.data.path);
						} else if (type == 1) {
							this.detailList.push(response.data.path);
						} else {
							this.detailShopList.push(response.data.path);
						}
					})
				
			},
			cellChange(e) {
				// console.log(e,新增商品1)
				let name = e.catname;
				let row = [...this.currentRow];
				if (!row.includes(name)) {
					row[row.length] = name;
				} else {
					row.splice(row.indexOf(name), 1);
				}
				this.currentRow = [...row];
				// console.log(this.currentRow,56565)
				if (this.currentRow.length == 2) {
					this.showAll = !this.showAll;
					this.currentRow = [];
				}
			},
			asd(e) {},
			//设置table中的扩展项
			getExpends() {
				this.catData.forEach((item) => {
					this.$refs.llzc.toggleRowExpansion(item);
				});
			},
			getRowKeys(row) {
				// console.log(row)
				return row.id;
			},
			// 点击取消
			cancelAdd() {
				this.oneCat = !this.oneCat;
				this.oneForm = this.defaultForm;
				// this.oneForm = {};
			},
			submitAdd() {
				// 对图片进行前缀处理
				let forms = JSON.parse(JSON.stringify(this.oneForm));
				forms.img = this.tools.filterImgPre(forms.img);
				if (forms.img == '') {
					delete forms.img;
				}
				// 判断是编辑还是新增
				if (forms.id) {
					//编辑提交
					forms.data_id = forms.id;
					console.log(forms,"forms");
					if(forms.parent_id==0){
						delete forms["children"];
					}
					
					this.$refs['oneForms'].validate((valid) => {
						if (valid) {
							edit_cate(forms)
								.then((res) => {
									// console.log(res, 新增商品);
									this.oneCat = false;
									this.oneForm = this.defaultForm;
									//刷新列表
									this.getAlls()
								})
						
						} else {
							return false;
						}
					});
				} else {
					//新增提交
					forms.parent_id = !forms.parent_id ? '0' : forms.parent_id;
					this.$refs['oneForms'].validate((valid) => {
						if (valid) {
							// 掉接口
							add_cate(forms)
								.then((res) => {
									forms.id = res.data.id;
									// 判断是一级分类还是二级
									if (forms.parent_id == '0') {
										//一级
										// forms.children = forms.children ? forms.children : [];
										//this.catData.unshift(forms);
										//刷新列表
										this.getAlls()
										this.getOneCat();
									} else {
										//二级
										let parIndex;
										this.catData.forEach((item, index) => {
											if (forms.parent_id == item.id) {
												parIndex = index;
											}
										});
										this.catData[parIndex].children.push(forms);
										//console.log(this.catData[parIndex].children, 新增商品);
									}
									this.oneCat = false;
									this.getAlls()
									this.oneForm = this.defaultForm;
								})
						
						} else {
							return false;
						}
					});
				}
			},
			// 新建一级分类
			createOneCat() {
				// this.levelName = 0;
				this.oneForm.name = '';
				this.oneForm.img = '';
				this.oneForm.level = "0";
				this.oneCat = !this.oneCat;
				this.oneForm.parent_id = '';
			},
			// 新增二级分类
			createtwoCat(row) {
				this.oneForm.name = '';
				this.oneForm.img = '';
				this.levelName = 1;
				this.oneCat = true;
				this.oneForm.level = "1";
				this.oneForm.parent_id = row.row.id;
				// 获取一级分类
				this.getOneCat();
			},
			// 编辑分类
			toEdit(row, type) {
				this.oneCat = true;
				this.$nextTick(()=>{
					this.$refs['oneForms'].clearValidate();
				});
				this.oneForm = JSON.parse(JSON.stringify(row.row));
				this.oneForm.is_show = String(this.oneForm.is_show);
				this.oneForm.level =
					(row.row.children) ? "0" : "1";
				// 获取一级分类
				this.getOneCat();
			},
			// 删除分类
			toDels(row) {
				this.tools.confirm("确认要删除？").then(res => {
					if (res) {
						let parIndex, curIndex;
						del_list({
								data_id: row.id
							})
							.then((res) => {
								// 判断是一级分类还是二级
								if (row.parent_id == '0') {
									//一级
									this.catData.forEach((item, index) => {
										if (row.id == item.id) {
											parIndex = index;
										}
									});
									this.catData.splice(parIndex, 1);
								} else {
									//二级
									this.catData.forEach((item, index) => {
										if (row.parent_id == item.id) {
											parIndex = index;
											if (item.children && item.children.length > 0) {
												item.children.forEach((item2, index2) => {
													if (row.id == item2.id) {
														curIndex = index2;
													}
												});
											}
										}
									});
									this.catData[parIndex].children.splice(curIndex, 1);
								}
							})
						
					}
				})

			},
			// 是否全部展开或关闭
			showOrHideAll() {
				// this.currentRow = [];
				this.getExpends();
				this.showAll = !this.showAll;
			},
			// 显示隐藏分租
			showOrhide(row) {
				// console.log(row,23232);
				// //判断
				// return
				// let id = row.row.id;
				// //获取当前id的显示隐藏值
				let data = [...this.catData];
				change_cate({
						data_id: row.row.id,
						is_show: row.row.is_show == 1 ? 0 : 1
					})
					.then((res) => {
						// console.log(res, "显示隐藏");
						this.getAlls()
						// data[row.$index].is_show = data[row.$index].is_show == 1 ? 0 : 1;
						// this.catData = [...data];
					})
				
			},
			// 点击下移
			moveTo(row, type) {
				// console.log(row, 新增商品);
				let data = [...this.catData];
				let dataId, curIndex, lastIndex;
				data.forEach((item, index) => {
					if (item.id == row.row.id) {
						// console.log(item.id == row.row.id);
						curIndex = index;
						lastIndex = index;
					}
				});
				let temp = data[curIndex];
				// type=1 上移
				if (type == 1) {
					lastIndex--;
				} else {
					// type=2 下移
					lastIndex++;
				}
				dataId = data[curIndex].id + "," + data[lastIndex].id;
				// console.log(dataId, 新增商品);

				// return;
				changeCateSort({
						data_id: dataId
					})
					.then((res) => {
						// console.log(res, "排序");
						data[curIndex] = data[lastIndex];
						data[lastIndex] = temp;
						this.catData = [...data];
					})
					

				// let index = row.$index + 1 > data.length - 1 ? 0 : row.$index;
			},


		}
	}
</script>
<style lang="scss" scoped="scoped">
	@import "@/assets/css/plat/goodcats.scss";

	.sortable-ghost {
		opacity: 0.4;
		background-color: #F4E2C9;
	}
</style>
