import axios from '@/config/httpConfig'
import qs from  'qs'
//门店管理
export const getChannelShopList=(data)=> {
  return axios('post','/Account/channel/getChanneShopList', data)
}
//服务商创建门店
export const channelCreateShop=(data)=>  {
  return axios('post','/Account/channel/channelCreateShop', data)
}
//获取门店详情
export const getShopInfo=(data)=>  {
  return axios('post','/Account/shop/getShopInfo', data)
}
//获取等级折扣
export const petCardList=(data)=>  {
  return axios('post',"Commission/channel/storeOpeningTime", data)
}
//门店信息修改
export const saveShopInfo=(data)=>  {
  return axios('post',"/Account/channel/saveShopInfo", data)
}
//获取商机孵化信息
export const getBusinessIncubatorInfo=(data)=>  {
  return axios('post',"/Account/channel/getBusinessIncubatorInfo", data)
}
//门店收益列表
export const shopCommissionList=(params)=>  {
  return axios('get','Twiker/channel/shopCommissionList', params)
}
//获取高级普通店长的1年价格
export const getStoreManagerPrice=(data)=>  {
  return axios('post',"/Commission/channel/getStoreManagerPrice", data)
}
//代理赠送门店次数
export const giveShop=(data)=>  {
  return axios('post',"/Account/channel/giveShop", data)
}
