<template>
	<div class="order">
		<div class="wrapper-main">
			<div class="model-content">
				<el-form :model="form" :rules="rules" ref="form" label-width="200px" class="demo-ruleForm">
					<div class="order-set">
						<el-form-item label="广告类型"  prop="college_advertising_id">
							<el-select v-model="form.college_advertising_id" size="small" @change="getImg">
								<el-option v-for="(item,index) in vaData" :key="index" :label="item.advertising_type_name" 
								:value="item.college_advertising_id">{{item.advertising_type_name}}</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="图片:" prop="image">
							<div class="img_blos" style="width: 100%!important;">
								<div v-for="(item, index) in form.image" :key="index" class="img_blo"
									style="margin-bottom:15px">
									<i class="el-icon-error close_img" @click="delImg(index)"></i>
									<img :src="item" class="item_img" />
								</div>
								<div class="plusImg" @click="uploadImg('background')"  v-if="form.image.length < 5">
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
											
						</el-form-item>
						<!-- <el-form-item label="内链:">
							<el-radio v-model="form.inner" label="2">无</el-radio>
							<el-radio v-model="form.inner" label="1">内链</el-radio>
						</el-form-item>
						<el-form-item label="图片:" v-if="form.inner==1" prop="inner_img">
							<div class="img_blos">
								<div class="img_blo" @click="uploadImg('http')">
									<img :src="form.inner_img" class="item_img" v-if="form.inner_img" />
									<div class="plusImg" v-else>
										<i class="el-icon-plus avatar-uploader-icon"></i>
									</div>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="广告排序:">
							<el-input placeholder="请输入数值" class="inputWidth" size="small"
								oninput="value=value.replace(/[^\d.]/g,'')" v-model="form.sort">
							</el-input>
							<p class="imgtxtstyle">数值越低排序越靠前，默认为0；当都为0时，按照创建时间最早排前面</p>
						</el-form-item> -->
					</div>

				</el-form>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet('form')">提交</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import ChooseImg from "../../../../common/uploadImgs.vue";
	import {
		dropDown,
		addAdvertisingImg,
		AdvertisingImgInfo
	} from '@/api/plat/adverty'
	export default {
		components: {
			ChooseImg
		},
		data() {
			return {
				isSingle: false,
				uploadFlag: false, //是否展示选择图片弹窗
				imgType: '',
				form: {
					college_advertising_id: '',
					image: [],
					inner: '2',
					inner_img: '',
					sort: '0',
					type:'1'
				},
				rules: {
					college_advertising_id:[
						 { required: true, message: '请选择广告类型', trigger: 'change' }
					],
					image:[
						 { required: true, message: '请上传图片', trigger: 'change' }
					],
					inner_img:[
						 { required: true, message: '请上传图片', trigger: 'change' }
					]
				},
				vaData:[]
			}
		},
		mounted() {
			this.getdropDown()
		},
		methods: {
			getImg(){
				AdvertisingImgInfo({college_advertising_id:this.form.college_advertising_id}).then(res=>{
					console.log(res);
					this.$set(this.form,'image',res.data.image.split(','))
				})
			},
			getdropDown(){
				dropDown({}).then(res=>{
					this.vaData=res.data
					this.form.college_advertising_id=res.data[0].college_advertising_id;
					this.getImg();
				})
			},
			uploadImg(type) {
				this.imgType = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				if (this.imgType == 'background') {
					val.forEach((res) => {
						if(this.form.image.length>=5){
							this.$message.warning("最多添加5张")
							return;
						}
						this.form.image = this.form.image.concat([res.resource_name]);
					});
				}
				if (this.imgType == 'http') {
					val.forEach((res) => {
						this.form.inner_img = val[0].resource_name;
					});
				}

			},
			//删除图片
			delImg(index) {
				this.form.image.splice(index, 1);
			},
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data=JSON.parse(JSON.stringify(this.form));
						let main_pic = [];
						data.image.forEach(item=>{
							main_pic.push(this.tools.filterImgPre(item));
						});
						data.image=main_pic.join(',');
						data.inner_img=this.tools.filterImgPre(data.inner_img)
						addAdvertisingImg(data).then(res=>{
							this.$message.success("添加成功")
						})
					} else {
						return false;
					}
				});
			},
		}
	}
</script>

<style>
</style>
