<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont">
					<el-row class="all_search_list " style="border-bottom: 1px solid #F2F2F6; padding-bottom: 20px;">
						<div class="demo-input-suffix">
							<el-input prefix-icon="el-icon-search" placeholder="请输入商品名称" size="small" type="text"
								v-model="keyword"   @keyup.enter.native="toSearch" clearable>
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-select size="small" v-model="is_publish" placeholder="全部">
								<el-option label="全部" value="3"></el-option>
								<el-option label="上架" value="1"></el-option>
								<el-option label="下架" value="0"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
					</el-row>
					<el-row class="all_search_list ">
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" plain @click="changeTab()">返回管理商品</el-button>
							<!-- <el-button size="small" plain @click="batchDel">批量移除</el-button> -->
							<el-button size="small" plain @click="batchAdd">批量添加</el-button>
						</div>
					</el-row>
				</div>
				<div class="main-cont mt10">
					<el-table :data="tableData" tooltip-effect="dark" style="width: 100%" max-height="500px"
						@selection-change="handleSelectionChange" border :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="selection" width="55"> </el-table-column>
						<el-table-column label="商品名称" min-width="360">
							<template slot-scope="scope">
								<div class="el_names" v-if="scope.row">
									<img :src="scope.row.goods_main_pic" class="names_img" />
									<div>
										<div class="namegood">
											{{ scope.row.goods_name }}
										</div>
										<!-- <span>数量：{{ scope.row.goods_amount }}</span> -->
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="sku_market_price" label="原价" align="center">
						</el-table-column>
						<el-table-column prop="sku_purchase_price" label="结算价"  align="center">
						</el-table-column>
						<el-table-column prop="sku_sale_price" label="销售价"  align="center">
						</el-table-column>
						<el-table-column prop="goods_stock" label="总库存"  align="center">
						</el-table-column>
						<el-table-column label="实际销量"  align="center" prop="sale">
						</el-table-column>
						<el-table-column prop="type" label="上架状态"  align="center">
							<template slot-scope="scope">
								<div class="el_ups">
									<span v-if="scope.row.is_publish == 1" >上架</span>
									<span v-else >下架</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="200px" align="center">
							<template slot-scope="scope">
								<el-button type="text" v-if="scope.row.been_added == false" @click="addOrUnadd(scope.row)">添加</el-button>
								<el-button type="text" v-else style="color: #999;">已添加</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
						<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {specialMerchandiseList,saveActivitiesResetGoods} from "@/api/merchant/specia.js"
	export default {
		data() {
			return {
				loading:true,
				is_publish: '',
				keyword: '',
				tableData:[],
				multipleSelection:[],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				composing_special_activities_reset_id:''
			}
		},
		mounted() {
			
			if(this.$route.query.id){
				this.composing_special_activities_reset_id=this.$route.query.id;
				this.getList();
			}
		},
		methods: {
			getList(){
				let data={
					special_id:this.composing_special_activities_reset_id,
					keyword:this.keyword,
					is_publish:this.is_publish,
					page: this.currentPage,
					perPage: this.pagesize
				};
				data=this.tools.filterParams(data);
				specialMerchandiseList(data).then(res=>{
					this.loading=false;
					this.total=res.data.count;
					this.tableData=res.data.list;
					this.tableData.forEach(item=>{
						item.goods_main_pic=item.goods_main_pic.split(',')[0]
					})
					
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList();
			},
			//搜索
			toSearch() {
				this.currentPage = 1;
				this.getList();
			},
			toReset(){
				this.currentPage = 1;
				this.keyword='';
				this.is_publish='';
				this.getList();
			},
			//返回商品库
			changeTab() {
				this.$router.push({
					path: "/specialadmin/specialmanage/specialgood",
					query:{
						id:this.composing_special_activities_reset_id,
					}
				})
			},
			//批量操作
			handleSelectionChange(val) {
				let aa=[];
				val.forEach(item=>{
					aa.push(item.goods_unified_id)
				})
				this.multipleSelection = aa;
			},
			//添加
			addOrUnadd(row) {
			  // 掉添加接口
			  let data={
				  composing_special_activities_reset_id:this.composing_special_activities_reset_id,
				  goods_unified_id:row.goods_unified_id
			  }
			  saveActivitiesResetGoods(data).then(res=>{
				  this.$message.success("添加成功！");
				  this.$router.push({
				  	path: "/specialadmin/specialmanage/specialgood",
				  	query:{
				  		id:this.composing_special_activities_reset_id,
				  	}
				  })
			  })
			
			},
			// 批量移除
			batchDel() {
			  if (this.multipleSelection.length == 0) {
			    this.$message("请选择要操作的数据");
			    return;
			  }
			   let ids = [];
			  this.multipleSelection.forEach(item=>{
			      //判断当前操作的商品是否是未添加状态
			    if(item.pitch == 2){
			      //如果是-当前添加状态不做修改
			    }else{
			      //修改当前状态
			      item.pitch = 2;
			    }
			    ids.push(item.goods_unified_id)
			  })
			  // batchDelSpecialActivities({
			  //   specialActivitiesId:this.specialId,
			  //   goodsUnifiedId:ids.join(',')
			  // }).then(res=>{
			  //   // console.log('dels',res)
			  // }).catch((err) => {
			  //    this.$message.error(err.return_msg);
			  // });
			},
			// 添加
			batchAdd() {
			  if (this.multipleSelection.length == 0) {
			    this.$message("请选择要操作的数据");
			    return;
			  }
			 
			  let data={
				  composing_special_activities_reset_id:this.composing_special_activities_reset_id,
				  goods_unified_id:this.multipleSelection.toString()
			  }
			  saveActivitiesResetGoods(data).then(res=>{
				  this.$message.success("添加成功！");
				  this.$router.push({
					path: "/specialadmin/specialmanage/specialgood",
					query:{
						id:this.composing_special_activities_reset_id,
					}
				  })
			  })
			  
			 // let ids = [];
			 //  this.multipleSelection.forEach(item=>{
			 //      //判断当前操作的商品是否是已添加状态
			 //    if(item.pitch == 1){
			 //      //如果是-当前添加状态不做修改
			 //    }else{
			 //      //修改当前状态
			 //      item.pitch = 1;
			 //    }
			 //    ids.push(item.goods_unified_id)
			 //  })
			 //  掉接口
			 //  addSpecialActivitieGoods({goodsUnifiedId:ids.join(','),specialActivitiesId:this.specialId}).then(res=>{
			 //    // console.log('batchAdd',res);
			 //  }).catch(err=>{
			 //     this.$message.error(err.return_msg);
			 //  })
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.el_names {
	  display: flex;
	  font-size: 12px;
	  color: #666;
	
	  .names_img {
	    width: 70px;
	    height: 70px;
	    margin-right: 5px;
	  }
	
	  .namegood {
	    color: #333;
	    margin-bottom: 30px;
	    width: 180px;
	    overflow: hidden;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	  }
	}
</style>
