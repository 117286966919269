<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="order-search" style="margin-top: 0;margin-bottom: 0;">
					<el-row class="all_search_list" style="margin-bottom: 0;">
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toAllOpera('add')">添加商品</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toAllOpera('down')">批量下架</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-input prefix-icon="el-icon-search" @keyup.enter.native="toSearch" clearable
								v-model="search.keyword" placeholder="请输入商品名称" size="small" type="text">
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-select style="width: 150px;" size="small" placeholder="请选择商品状态" v-model="search.is_publish">
								<el-option label="已上架" value="1"></el-option>
								<el-option label="已下架" value="2"></el-option>
								<el-option label="已售罄" value="3"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-select style="width: 150px;" size="small" placeholder="请选择审核状态"
								v-model="search.status">
								<el-option label="待审核" value="-1"></el-option>
								<el-option label="审核通过" value="1"></el-option>
								<el-option label="审核失败" value="2"></el-option>
							</el-select>
						</div>
						<el-button type="primary" size="small" @click="toSearch">查询</el-button>
						<el-button size="small" @click="toReset">重置</el-button>
					</el-row>
				</div>
			</div>
			<div class="wrapper-cont mt10">
				<el-table :data="tableDataGood" border style="width: 100%"  max-height="550"
					@selection-change="handleSelectionChangeGood">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column label="商品名称"  align="left">
						<template slot-scope="scope">
							<div class="el_name">
								<img v-if="scope.row.goods_main_pic" :src="scope.row.goods_main_pic.split(',')[0]" class="el_img"
									style="width:50px;height:50px" />
								<span>
									{{ scope.row.goods_name }}
								</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="价格" prop="sale_price" align="center"></el-table-column>
					<el-table-column label="库存" prop="stock" align="center"></el-table-column>
					<el-table-column label="商品状态" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.is_publish == 1">上架</span>
							<span v-else-if="scope.row.is_publish == 2">下架</span>
							<span v-else-if="scope.row.is_publish == 3">已售罄</span>
						</template>
					</el-table-column>
					<el-table-column label="微信分类" prop="catename" align="center" ></el-table-column>
					<el-table-column label="提交时间" prop="created_at" align="center" ></el-table-column>
					<el-table-column label="审核状态" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.status == -1">待审核</span>
							<span v-else-if="scope.row.status == 1">审核通过</span>
							<span v-else-if="scope.row.status == 2">审核失败</span>
						</template>
					</el-table-column>
					<el-table-column label="失败原因" prop="wx_reason" align="center" max-width="250"   show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="操作" align="center"  width="220">
						<template slot-scope="scope">
							<!-- <el-button style="padding: 5px 0;" type="text" size="small"
								@click="toOpera(scope.row,'check')">重新提审
							</el-button> -->
							<el-button style="padding: 5px 0;" type="text" size="small"    v-if="scope.row.is_publish==2 || scope.row.status ==2"
								@click="toOpera(scope.row,'del')">删除
							</el-button>
							<el-button style="padding: 5px 0;" type="text" size="small" v-if="scope.row.status !=2"
								@click="toOpera(scope.row,'update')">更新
							</el-button>
							<el-button style="padding: 5px 0;" type="text" size="small" v-if="scope.row.is_publish==1 && scope.row.status !=2"
								@click="toOpera(scope.row,'down')" >
								从视频号下架
							</el-button>
						</template>
					</el-table-column>
				</el-table>

				<!-- 分页 -->
				<div class="pagecurrent">
					<div class="pagination-container">
						<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
			<!--选择商品弹框-->
			<el-dialog title="添加商品" :visible.sync="dialogAddVisible" width="55%">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
					<el-form-item label="选择分类" prop="cat_id" size="small">
						<el-cascader filterable style="width: 250px;"  ref="myCascader" v-model="ruleForm.cat_id" :props="defaultTreeProps" :options="treeOptions" @change="handleChange"></el-cascader>
					</el-form-item>
					<el-form-item label="上传商品资质:" prop="qualification_pics" v-if="product_qualification.product_qualification_type==1">
						<div style="background-color: #F2F2F6; padding: 15px 15px;">
							<div class="cardImg">
								<uploadImg :limit="5"  :uploadUrl="uploadUrl" :fileList="ruleForm.qualification_pics" @submitImg="getQualificationPics"></uploadImg>
							</div>
						</div>
						<p style="font-size: 12px; line-height: 20px;">{{product_qualification.product_qualification}}</p>
					</el-form-item>
					<el-form-item label="选择商品" prop="goods_list" size="small" >
						<el-button type="primary" size="small" @click="selectGood">选择商品</el-button>
						<el-table :data="ruleForm.goods_list" v-if="ruleForm.goods_list.length>0" style="width: 100%"
							min-height="300" class="mt20">
							<el-table-column prop="date" label="商品名称" width="320" align="left">
								<template slot-scope="scope">
									<div class="el_name">
										<img v-if="scope.row.goods_main_pic"
											:src="scope.row.goods_main_pic.split(',')[0]" class="el_img" />
										<div>
											<p>{{ scope.row.goods_name }}</p>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="sku_purchase_price" label="成本价">
							</el-table-column>
							<el-table-column prop="sku_sale_price" label="销售价">
							</el-table-column>
							<el-table-column prop="sku_stock" label="总库存">
							</el-table-column>
							<el-table-column label="操作" align="center" class="opera-cont">
								<template slot-scope="scope">
									<el-button type="text" size="small" style="color: #E02020"
										@click="toDel(scope.$index)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogAddVisible = false" size="small">取 消</el-button>
					<el-button type="primary" @click="submitGood('ruleForm')" size="small">提交审核</el-button>
				</span>
			</el-dialog>
			<!--添加商品弹框-->
			<el-dialog title="选择商品" :visible.sync="dialogVisible" width="55%">
				<el-row :gutter="20">
					<el-col :span="5">
						<div class="grid-content bg-purple treestyle">
							<div style="max-height: 600px; overflow-y: scroll;">
								<el-tree :data="treedata" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
							</div>
						</div>
					</el-col>
					<el-col :span="19">
						<div class="grid-content bg-purple ">
							<el-row class="all_search_list" style="margin-bottom: 0;">
								<div class="demo-input-suffix">
									<el-select v-model="goodsearch.goods_source" class="w150" slot="prepend" placeholder="商品来源" size="small">
										<el-option label="平台" value="1"></el-option>
										<el-option label="店铺" value="2"></el-option>
									</el-select>
								</div>
								<div class="demo-input-suffix">
									<el-select v-model="goodsearch.third_party_type" class="w150" slot="prepend" placeholder="第三方类型" size="small">
										<el-option label="库宝宝" value="1"></el-option>
										<el-option label="平台" value="-1"></el-option>
										<el-option label="京东" value="3"></el-option>
										<el-option label="阿里" value="4"></el-option>
										<el-option label="天猫" value="5"></el-option>
										<el-option label="云仓" value="6"></el-option>
										<el-option label="苏宁" value="7"></el-option>
									</el-select>
								</div>
								<div class="demo-input-suffix">
									<el-input prefix-icon="el-icon-search" v-model="goodsearch.keyword"
										placeholder="请输入商品名称" size="small" type="text">
									</el-input>
								</div>
								<div class="demo-input-suffix">
									<el-button size="small" type="primary" slot="append" @click="searchGood()">搜索
									</el-button>
									<el-button size="small" @click="toResetGood">重置</el-button>
								</div>
							</el-row>
							<el-table border :data="tableData" ref="multipleTable"
								@selection-change="handleSelectionChange" style="width: 100%;margin-top:15px">
								<el-table-column type="selection" width="55" align="center">
								</el-table-column>
								<el-table-column  label="商品名称" align="left" width="400">
									<template slot-scope="scope">
										<div class="el_name">
											<img v-if="scope.row.goods_main_pic"
												:src="scope.row.goods_main_pic.split(',')[0]" class="el_img" />
											<span>
												{{ scope.row.goods_name }}
											</span>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="sku_purchase_price" label="成本价" align="center">
								</el-table-column>
								<el-table-column prop="sku_sale_price" label="销售价" align="center">
								</el-table-column>
								<el-table-column prop="sku_stock" label="库存" align="center">
								</el-table-column>
							</el-table>
						</div>
					</el-col>
				</el-row>
				<!-- 分页 -->
				<div class="pagecurrent" style="margin-top: 5px;">
					<div class="pagination-container">
						<el-pagination background @size-change="handleSizeChangeGood"
							@current-change="handleCurrentChangeGood" :current-page="currentPageGood"
							:page-sizes="[10,20,30,50]" :page-size="pagesizeGood"
							layout="total, sizes, prev, pager, next, jumper" :total="totalGood">
						</el-pagination>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false" size="small">取 消</el-button>
					<el-button type="primary" @click="sureAddGood" size="small">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		all_cate, //所有商品分类
	} from '@/api/merchant/good';
	import {
		getShopVideoList, //所有商品分类
		getCategroyTree,
		getShopCategoryList,
		addGoods,
		delGoods,
		spuDelisting,
		updateWithoutAudit
	} from '@/api/merchant/videonum';
	import uploadImg from '../../../../../common/unloadImg'
	export default {
		components:{
			uploadImg
		},
		data() {
			return {
				search: {
					keyword: '',
					is_publish: '',
					status: ''
				},
				tableDataGood: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				multipleSelectionGood: [], //批量选择
				dialogVisible: false,
				treedata: [],
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				uploadUrl:'/Video/platform/uploadFile',
				totalGood: 0,
				currentPageGood: 1, //初始页
				pagesizeGood: 10, //每页的数据
				tableData: [],
				goodsearch: {
					cate_id: '',
					goods_source:'',
					third_party_type:'',
					keyword:''
				},
				multipleSelectionAll: [], // 所有选中的数据包含跨页数据
				multipleSelection: [], // 当前页选中的数据
				idKey: "goods_unified_id", // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
				checkedData: [], // 初始化弹窗选中的行
				//添加商品
				dialogAddVisible: false,
				ruleForm: {
					cat_id: [],
					goods_list: [],
					qualification_pics:[]
				},
				rules: {
					cat_id: [{
						type: 'array',
						required: true,
						message: '请选择分类',
						trigger: 'change'
					}],
					qualification_pics: [{
						type: 'array',
						required: true,
						message: '请上传图片资质',
						trigger: 'change'
					}],
					goods_list: [{
						type: 'array',
						required: true,
						message: '请选择商品',
						trigger: 'change'
					}],
				},
				treeOptions:[],
				defaultTreeProps: {
					value:'cat_id',
					children: 'children',
					label: 'category_name'
				},
				// wx_product_id:''
				product_qualification:{}
			
			
			}
		},
		mounted() {
			this.getList(),
			this.$nextTick(function() {
				// 模拟初始化一个选中
				this.checkedData = []
			})
		},
		watch: {
			'checkedData': {
				handler(val) {
					// 转换一下目的是为了不被同步
					this.multipleSelectionAll = JSON.parse(JSON.stringify(val));

				},
				immediate: true,
				deep: true
			}
		},
		methods: {
			getList(){
				let data={
					page: this.currentPage,
					perPage: this.pagesize,
					keyword:this.search.keyword,
					is_publish:this.search.is_publish,
					status:this.search.status,
				}
				data = this.tools.filterParams(data);
				getShopVideoList(data).then(res=>{
					this.tableDataGood=res.data.list;
					this.total = res.data.count;
				})
			},
			//上传商品资质
			getQualificationPics(list){
			    this.ruleForm.qualification_pics = list
			},
			//列表全选
			handleSelectionChangeGood(val) {
				let aa = [];
				val.forEach(item => {
					aa.push(item.wx_product_id)
				})
				this.multipleSelectionGood = aa;
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//搜索
			toSearch() {
				this.currentPage = 1;
				this.getList()
			},
			//重置
			toReset() {
				this.currentPage=1;
				this.search.keyword='';
				this.search.is_publish='';
				this.search.status='';
				this.getList()
			},
			//批量操作
			toAllOpera(type) {
				if (type == 'add') {
					this.dialogAddVisible = true;
					this.getCategroyTreeData();
					this.ruleForm.cat_id=[];
					this.ruleForm.qualification_pics=[];
					this.ruleForm.goods_list=[];
					this.multipleSelectionAll=[];
				}
				if (type == 'down') {
					if (this.multipleSelectionGood.length == 0) {
						this.$message.warning("请选择要下架的商品！");
						return;
					}
					//调接口
					spuDelisting({wx_product_id:this.multipleSelectionGood.join(',')}).then(res=>{
						this.$message.success("批量下架成功！");
						this.getList()
					})
				}
			},
			//操作
			toOpera(row, state) {
				//更新商品
				if (state == 'update') {
					//调接口
					let data={
						goods_unified_id:row.goods_unified_id,
						third_cat_id:row.third_cat_id
					}
					updateWithoutAudit(data).then(res=>{
						this.$message.success("商品更新成功！");
						this.getList()
					})
				}
				//删除
				if (state == 'del') {
					this.tools.confirm("确定将商品从视频号删除？").then(res => {
						if (res) {
							//调接口
							delGoods({product_id:row.wx_product_id}).then(res=>{
								this.$message.success("删除成功！");
								this.getList()
								
							})
					
						}
					})
				};
				//下架
				if (state == 'down') {
					this.tools.confirm("是否将商品从视频号下架？").then(res => {
						if (res) {
							//调接口
							spuDelisting({wx_product_id:row.wx_product_id}).then(res=>{
								this.$message.success("下架成功！");
								this.getList()
							})

						}
					})
				}
			},
			/*******
			//添加商品
			
			
			*******/
			//获取微信分类
			getCategroyTreeData(){
				getCategroyTree({}).then(res=>{
					this.treeOptions=res.data;
					this.treeOptions.forEach(item1=>{
						item1.children.forEach(item2=>{
							item2.children.forEach(item3=>{
								//is_submit 0未提交1待审核2通过
								//qualification_type 1必填 2选填
								if(item3.qualification_type==1 && item3.is_submit !=2 ){
									item3.disabled=true
								}
							})
						})
					})
				})
			},
			//选择分类
			handleChange(value) {
				this.product_qualification=this.$refs.myCascader.getCheckedNodes()[0].data;
				
				//console.log(value, this.$refs.myCascader.getCheckedNodes()[0].data.product_qualification_type);
			},
			selectGood() {
				this.dialogVisible = true;
				this.getAllCase();
				// 初始化渲染
				this.currentPageGood = 1;
				this.getGoodsList()
			},
			//删除
			toDel(index) {
				this.tools.confirm("确定要删除此商品吗？").then(res => {
					if (res) {
						//调接口
						let row = [...this.ruleForm.goods_list];
						row.splice(index, 1)
						this.ruleForm.goods_list = [...row];
						this.multipleSelectionAll = this.ruleForm.goods_list;
						this.$forceUpdate();
					}
				})
			},
			submitGood(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let qualification_p=JSON.parse(JSON.stringify(this.ruleForm.qualification_pics)),goodsIds=JSON.parse(JSON.stringify(this.ruleForm.goods_list)),goodsallids=[],qualificationsPics=[];
						goodsIds.forEach(item=>{
							goodsallids.push(item.goods_unified_id)
						});
						qualification_p.forEach(item=>{
							qualificationsPics.push(item.url)
						});
						let data={
							goods_unified_id:goodsallids.join(","),
							third_cat_id:this.ruleForm.cat_id[2],
							qualification_pics:qualificationsPics.join(",")
						}
						addGoods(data).then(res=>{
							this.$message.success("添加成功！");
							this.dialogAddVisible=false;
							this.getList()
							
						})
						

					} else {
						return false;
					}
				});
			},
			//点击分类搜索商品列表
			handleNodeClick(data) {
				this.goodsearch.cate_id = data.id;
				this.getGoodsList();
			},
			//获取所有商品分类
			getAllCase() {
				all_cate({}).then(res => {
					this.treedata = res.data;
					let pp = {
						id: '',
						img: "https://cattle-shop.oss-accelerate.aliyuncs.com/df7b689ed7b511eb959500163e0fb62d.png",
						is_show: 1,
						level: 0,
						name: "全部",
						parent_id: '',
					}
					this.treedata.unshift(pp);
				})
			},
			//分页
			handleSizeChangeGood(size) {
				this.changePageCoreRecordData();
				this.pagesizeGood = size;
				// 掉接口
				this.getGoodsList()
			},
			handleCurrentChangeGood(currentPage) {
				this.changePageCoreRecordData();
				this.currentPageGood = currentPage;
				// 掉接口
				this.getGoodsList()
			},
			// 设置选中的方法
			setSelectRow() {
				if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
					return;
				}
				// 标识当前行的唯一键的名称
				let idKey = this.idKey;
				let selectAllIds = [];
				let that = this;
				this.multipleSelectionAll.forEach(row => {
					selectAllIds.push(row[idKey]);
				});
				this.$refs.multipleTable.clearSelection();

				for (var i = 0; i < this.tableData.length; i++) {
					if (selectAllIds.indexOf(this.tableData[i][idKey]) >= 0) {
						// 设置选中，记住table组件需要使用ref="table"
						this.$refs.multipleTable.toggleRowSelection(this.tableData[i], true);

					}
				}
			},
			// 记忆选择核心方法
			changePageCoreRecordData() {
				// 标识当前行的唯一键的名称
				let idKey = this.idKey;
				let that = this;
				// 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
				if (this.multipleSelectionAll.length <= 0) {
					this.multipleSelectionAll = this.multipleSelection;
					return;
				}
				// 总选择里面的key集合
				let selectAllIds = [];
				this.multipleSelectionAll.forEach(row => {
					selectAllIds.push(row[idKey]);
				});
				let selectIds = [];
				// 获取当前页选中的id
				this.multipleSelection.forEach(row => {
					selectIds.push(row[idKey]);
					// 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
					if (selectAllIds.indexOf(row[idKey]) < 0) {
						that.multipleSelectionAll.push(row);
					}
				});
				let noSelectIds = [];
				// 得到当前页没有选中的id
				this.tableData.forEach(row => {
					if (selectIds.indexOf(row[idKey]) < 0) {
						noSelectIds.push(row[idKey]);
					}
				});
				noSelectIds.forEach(id => {
					if (selectAllIds.indexOf(id) >= 0) {
						for (let i = 0; i < that.multipleSelectionAll.length; i++) {
							if (that.multipleSelectionAll[i][idKey] == id) {
								// 如果总选择中有未被选中的，那么就删除这条
								that.multipleSelectionAll.splice(i, 1);
								break;
							}
						}
					}
				});
			},
			handleSelectionChange(val) {
				// table组件选中事件,记得加上@selection-change="handleSelectionChange"
				this.multipleSelection = val;
			},
			searchGood(){
				this.getGoodsList();
				this.changePageCoreRecordData();
			},
			toResetGood(){
				this.goodsearch.keyword='';
				this.goodsearch.goods_source='';
				this.goodsearch.third_party_type='';
				this.changePageCoreRecordData();
				this.getGoodsList()
			},
			//获取商品列表
			getGoodsList() {
				let data = {
					keyword: this.goodsearch.keyword,
					cate_id: this.goodsearch.cate_id || '',
					goods_source:this.goodsearch.goods_source,
					third_party_type:this.goodsearch.third_party_type,
					page: this.currentPageGood,
					perPage: this.pagesizeGood
				}
				data = this.tools.filterParams(data);
				getShopCategoryList(data).then(res => {
					this.tableData = res.data.list;
					this.totalGood = res.data.count;
					if (this.tableData.length > 0) {
						this.$nextTick(function() {
							// 初始化渲染
							this.setSelectRow();
						})
					}
				})
			},

			sureAddGood() {
				this.changePageCoreRecordData();
				if (this.multipleSelectionAll.length <= 0) {
					this.$message({
						message: '无勾选数据！',
						type: 'warning'
					});
					return;
				};
				this.ruleForm.goods_list = this.multipleSelectionAll;

				this.dialogVisible = false;
			}
		}
	}
</script>
<style lang="scss">
    .el-tooltip__popper{max-width:35%; }
</style>
<style scoped="scoped" lang="scss">
	::v-deep.el-image__preview {
		cursor: pointer;
		width: 50px !important;
	}

	::v-deep.el-image {
		overflow: visible;
	}

	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.el_img {
			width: 30px;
			height: 30px;
			flex-shrink: 0;
			margin-right: 6px;
		}
	}
	
</style>
