<template>
	<div>
		<div class="wrapper-main pp20">
			<div class="setTitle">特色玩法</div>
			<div class="itemList">
				<ul class="items">
					<li v-for="(item,index) in marketList" @click="toSecond(item)" :key="index">
						<div class="tjimg" v-if="item.is_recommend ==1">
							<img class="timg" src="../../../../../../assets/img/tj01.png" />
						</div>
						<div class="marketflex">
							<div class="iconimg">
								<img :src='item.backstage_image'  style="width: 100%; height: 100%;"/>
							</div>
							<div class="intro">
								<p class="title">{{item.title}}</p>
								<p style="margin-top: 8px;">{{item.description}}</p>
							</div>
						</div>
					</li>
					
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import {TwitterList} from '@/api/merchant/marketing.js'
	export default{
		data(){
			return{
				marketList: [],
			}
		},
		mounted() {
			this.getList();
		},
		methods:{
			getList(){
				TwitterList({}).then(res=>{
					this.marketList = res.data
				})
			},
			toSecond(item){
				if(item.is_buy=='1' && item.plugin_identifying==2 ){
					return;
				}else if(item.is_buy=='1' && item.plugin_identifying==3){
					this.$router.push({
						path: '/marketingcenteradmin/luckypuzzle/luckypuzzlelist',
						query:{
							plugin_id:item.plugin_id
						}
					})
				}else if(item.is_buy=='1' && item.plugin_identifying==4){
					this.$router.push({
						path: '/marketingcenteradmin/fissioncoupon/couponlist',
						query:{
							plugin_id:item.plugin_id
						}
					})
				}else{
					if(item.is_buy=='1'){
						this.$router.push({
							path: '/marketingcenteradmin/marketingcenter/twitterset',
							query:{
								plugin_id:item.plugin_id
							}
						})
					}else if(item.is_buy=='2'){
						this.$router.push({
							path: '/marketingcenteradmin/marketingcenter/twitterdetail',
							query:{
								plugin_id:item.plugin_id
							}
						})
					}
				}
				
				
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.itemList {
		width: 100%;
	}
	
	.items {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	
	.items li {
		list-style: none;
		padding: 16px 10px;
		border-radius: 4px;
		min-height: 36px;
		background: #FFFFFF;
		border: 1px solid #DDDDDD;
		width: 228px;
		margin-right: 20px;
		cursor: pointer;
		margin-bottom: 20px;
		position: relative;
		.tjimg{
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	.marketflex {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		.iconimg{
			width: 50px;
			height: 50px;
			vertical-align: middle;
			position: relative;
			img {
				width:100%;
				height: 100%;
				vertical-align: middle;
			}
		}
		.tjimg{
			position: absolute;
			top: 5px;
			left:4px;
			width: 21px;
			height: 19px;
			.timg{
				width: 100%;
				height: 100%;
			}
		}
		
	
		.intro {
			font-size: 0.75rem;
			color: #999;
			margin-left: 10px;
	
			.title {
				font-size: 0.875rem;
				color: #333;
	
	
			}
		}
	}
	
</style>
