<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<el-row :gutter="20">
					<el-col :span="12">
						<div class="grid-content bg-purple">
							<el-card class="box-card">
								<div slot="header" class="clearfix">
									<span>团购信息</span>
								</div>
								<div class="text itemfirst">
									<span class="title">&nbsp;&nbsp;&nbsp;&nbsp;团状态</span>
									{{groupInfo.status=='0'?'未成团':groupInfo.status=='1'?'成团':groupInfo.status=='2'?'成团':'成团失败'}}
								</div>
								<div class="text itemfirst">
									<span class="title">开团时间</span>{{groupInfo.created_at}}
								</div>
								<div class="text itemfirst">
									<span class="title">成团时间</span>{{groupInfo.completed_at=='0'?'--':groupInfo.completed_at}}
								</div>
								<div class="text itemfirst">
									&nbsp;
								</div>
							</el-card>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="grid-content bg-purple">
							<el-card class="box-card">
								<div slot="header" class="clearfix">
									<span>商品图片</span>
								</div>
								<div class="text item">
									<span class="title">商品图片</span><img
										style="width: 30px;height: 30px;vertical-align: middle;"
										:src="groupInfo.goods_image" />
								</div>
								<div class="text item">
									<span class="title">商品名称</span><span>{{groupInfo.goods_name}}</span>
								</div>
								<div class="text item">
									<span class="title">商品价格</span><span class="price" v-if="groupInfo.sku_sale_price">¥{{groupInfo.sku_sale_price}}</span>
								</div>
								<div class="text item">
									<span class="title">团购价格</span><span class="price" v-if="groupInfo.group_buying_price">¥{{groupInfo.group_buying_price}}</span>
								</div>
							</el-card>
						</div>
					</el-col>
				</el-row>
				<div class="main-cont tableMain mt10">
					 <el-table :header-cell-style="{color:'#606266'}" :data="tableData"
					 	style="font-size: 12px;width: 100%;" max-height="550px" :default-expand-all="true">
					 	<div style="overflow: hidden">
					 		<el-table-column type="expand" width="1">
					 			<template slot-scope="props">
					 				<table border="1" class="tableStyle">
					 					<tbody>
					 						<tr v-for="(item,index) in props.row" :key="index">
					 							<td style="width:22%;">
					 								<div style="display: flex">
					 									<div style="margin:0 20px">
					 										<img v-if="item.head_portrait" :src="item.head_portrait"
					 											style="width:40px;height: 40px;border-radius: 40px;" />
					 									</div>
					 									<div v-if="item.nickname">
					 										<div  style="margin-top:5px;line-height: 40px;">
					 											{{item.nickname}}</div>
					 									</div>
					 								</div>
					 							</td>
					 							<td style="width:13%;text-align: center">
					 								<p >{{item.mobile}}</p>
					 							</td>
					 							<td style="width: 13%;text-align: center">
					 								<p>{{item.goods_count}}</p>
					 							</td>
					 							<td style="width:13%;text-align: center">
					 								<p style="color: #E02020;" v-if="item.actual_payment">¥{{item.actual_payment}}</p>
					 							</td>
					 							<td style="width:13%;text-align: center">
					 								{{item.created_at}}
					 							</td>
					 							<td style="width:13%;text-align: center">
					 								{{item.status==1?'待支付':item.status==2?'已支付':item.status==3?'待收货':item.status==4?'已收货':item.status==5?'已完成':'已关闭'}}
					 							</td>
					 							<td style="width:13%;text-align: center">
					 								{{item.is_win=='0'?'未拼中':'拼中'}}
					 							</td>
					 						</tr>
					 					</tbody>
					 				</table>
					 			</template>
					 		</el-table-column>
					 	</div>
					 	<el-table-column label="买家" min-width="130">
					 		<template slot-scope="scope" style="padding: 10px 0;">
					 			<div class="aaa">
					 				订单号：<span
					 					style="color: #1d8ce0;">{{ scope.row[0].master_order_number}}
					 				</span>
					 			</div>
					 		</template>
					 	</el-table-column>
					 	<el-table-column label="手机号" align="center">
					 	</el-table-column>
					 	<el-table-column label="购买数量" align="center">
					 	</el-table-column>
					 	<el-table-column label="实付款" align="center">
					 	</el-table-column>
					 	<el-table-column label="下单时间" align="center">
					 	</el-table-column>
					 	<el-table-column label="状态" align="center">
					 	</el-table-column>
					 	<el-table-column label="拼中状态" align="center">
					 	</el-table-column>
					 </el-table>
				</div>
			</div>
			<!-- 分页 -->
			<div class="pagecurrent">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		groupBuyingDetail,
		numberInfo,
	} from '@/api/merchant/marketing.js'
	export default {
		data() {
			return {
				tableData: [],
				goods_unified_id:this.$route.query.goods_unified_id,
				group_buying_no:this.$route.query.group_buying_no,
				group_buying_activity_id:this.$route.query.id,
				groupInfo:{},
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.getgroupBuyingDetail();
			this.getList()
		},
		methods:{
			//数据
			getgroupBuyingDetail(){
				let data={
					group_buying_activity_id:this.group_buying_activity_id,
					goods_unified_id:this.goods_unified_id,
					group_buying_no:this.group_buying_no
				}
				groupBuyingDetail(data).then(res=>{
					this.groupInfo=res.data
				})
			},
			//列表
			getList(){
				let data={
					group_buying_activity_id:this.group_buying_activity_id,
					goods_unified_id:this.goods_unified_id,
					group_buying_no:this.group_buying_no,
					page: this.currentPage,
					perPage: this.pagesize
				}
				numberInfo(data).then(res=>{
					// this.groupInfo=res.data
					this.total=res.data.count;
					this.tableData=res.data.list
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			 
		}
	}
</script>

<style lang="scss" scoped="scoped">
		@import "@/assets/css/plat/order.scss";
	.text {
		font-size: 14px;
	}

	.item {
		margin-bottom: 18px;
	}

	.itemfirst {
		margin-bottom: 21px;
	}

	.title {
		color: #333;
		font-size: 14px;
		margin-right: 20px;
		text-align: right;
	}

	.nums {
		color: #FA6400;
	}

	.price {
		color: #E02020;
	}
	
.el-tabs__header {
		padding: 0;
		position: relative;
		margin: 0;
	}

	.el-table__expand-icon>.el-icon {
		display: none;
	}

	.el-table .cell {
		overflow: visible !important;
	}

	.tableMain.aaa {
		width: 500px;
	}

	.demo-table-expand {
		font-size: 0;
	}

	.demo-table-expand label {
		width: 90px;
		color: #99a9bf;
	}

	.demo-table-expand .el-form-item {
		margin-right: 0;
		margin-bottom: 0;
		width: 50%;
	}

</style>
