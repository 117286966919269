<template>
    <div>
        <div class="wrapper-main">
            <el-tabs v-model="activeName" @tab-click="handleClick" style="background-color:#fff">
                <el-tab-pane label="基础信息" name="first">
                    <baseInfo ref="base"></baseInfo>
                </el-tab-pane>
                <!-- <el-tab-pane label="营销插件" name="second">
                    <marketPlug ref="plug"></marketPlug>
                </el-tab-pane> -->
            </el-tabs>
        </div>
    </div>
</template>
<script>
import baseInfo from './baseinfo.vue'
import marketPlug from './marketplug.vue'
export default {
    components:{
        baseInfo,
        marketPlug
    },
    data() {
        return {
            activeName: 'first'
        }
    },
    mounted() {
        this.$refs.base.getInfo()
    },
    methods: {
        handleClick(val) {
            if(val.name == 'first'){
                this.$nextTick(()=>{
                    this.$refs.base.getInfo()
                })
            }
               
            

        }
    }
}
</script>
<style lang="less" scoped>

</style>