<template>
	<div>
		<div class="wrapper-main pd70" >
			<div class="wrapper-cont">
				<div class="pmargin10">
					<div class="setTitle">基础信息</div>
					<div class="formBox">
						<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
							<el-row :gutter="20">
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-form-item label="客户名称:" prop="username">
											<el-input type="text" placeholder="请输入客户名称" v-model="form.username"
												class="inputWidth" size="small">
											</el-input>
										</el-form-item>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-form-item label="客户来源:">
											<el-select v-model="form.customer_source" size="small" class="inputWidth"
												placeholder="请选择客户来源">
												<el-option label="平台创建" value="1"></el-option>
												<el-option label="网上注册" value="2"></el-option>
												<el-option label="服务商创建" value="3"></el-option>
											</el-select>
										</el-form-item>
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-form-item label="联系电话:" prop="phone">
											<el-input type="text" class="inputWidth" maxlength="11"
												placeholder="请输入联系电话" size="small" v-model.number="form.phone">
											</el-input>
										</el-form-item>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-form-item label="是否加急:">
											<el-select v-model="form.is_urgent" size="small" class="inputWidth"
												placeholder="是否加急">
												<el-option label="否" value="1"></el-option>
												<el-option label="是" value="2"></el-option>
											</el-select>
										</el-form-item>
									</div>
								</el-col>
							</el-row>
							<el-form-item label="门店类型:">
								<el-select v-model="form.is_entity" size="small" class="inputWidth"
									placeholder="请选择门店类型">
									<el-option label="线上门店" value="1"></el-option>
									<el-option label="实体门店" value="2"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="行业类目:">
								<el-select v-model="form.marketing_trade_id" size="small" class="inputWidth"
									placeholder="请选择门店类型">
									<el-option v-for="(item,index) in tradeList" :key="index" :label="item.trade_name"
										:value="item.marketing_trade_id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="跟进内容:">
								<el-input type="textarea" v-model="form.follow_remark" class="inputWidth"></el-input>
							</el-form-item>
							<el-form-item label="通信地址:">
								<el-cascader size="small" placeholder="地区" style="width:300px;" :options="options"
									collapse-tags :props="{multiple: false }" clearable @change="handleChange"
									v-model="address" ref="cascader">
								</el-cascader>
							</el-form-item>
							<el-form-item label="地图点位:">
								<div class="mapsure">请在地图中确认地址信息</div>
								<div class="relation">
									<div class="absolute">
										<el-input placeholder="请搜索地址" prefix-icon="el-icon-search"
											v-model="addresskeywords" @input="inputAddress" style="width: 250px;"
											size="small">
										</el-input>
									</div>
									<div id="container" style="width:500px;height:350px;"></div>
								</div>
							</el-form-item>
							<el-form-item label="详细地址:">
								<el-input type="text" placeholder="请输入详细地址" v-model="form.shop_address_detail"
									@input="setAddressDetail" class="inputWidth" size="small">
								</el-input>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<div class="save-cont">
			<div class="cancel-btn" @click="toBack">返回上一页</div>
			<div class="save-btn" @click="submitSet('form')">保存</div>
		</div>
	</div>
</template>

<script>
	import {
		getMarketingTradeList
	} from '@/api/plat/market.js'
	import {
		createOrUpBusinessIncubator,
		getBusinessIncubatorInfo
	} from '@/api/plat/business'
	var searchService, geocoder, map, markersArray = [];
	import {
		TMap
	} from "@/utils/TMap.js";
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	import {
		isvalidPhone
	} from '@/utils/validate'
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入联系电话'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		data() {
			return {
				form: {
					username: '',
					customer_source: '',
					phone: '',
					is_urgent: '',
					is_entity: '',
					marketing_trade_id: '',
					follow_remark: '',
					shop_address_detail: '',
					shop_province: '',
					shop_province_code: '',
					shop_city: '',
					shop_city_code: '',
					shop_county: '',
					shop_county_code: '',
				},
				address: [],
				rules: {
					username: [{
							required: true,
							message: '请输入客户名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 10,
							message: '长度在10个字以内',
							trigger: 'blur'
						}
					],
					phone: [{
							required: true,
							trigger: 'blur',
							validator: validPhone
						}, //这里需要用到全局变量
					]
				},
				options: regionData,
				longitude: 30.18534, //经度
				latitude: 120.26457, //纬度
				searchService: null,
				geocoder: null,
				map: null,
				marker: null,
				mapAddress: '浙江省,杭州市,萧山区',
				addresskeywords: '',
				tradeList: [],
				business_incubator_id: this.$route.query.id
			}
		},
		mounted() {
			this.codeAddress()
			this.getTradeList()
			if (this.business_incubator_id) {
				this.getInfo()
			}

		},
		methods: {
			//行业类目
			getTradeList() {
				getMarketingTradeList({}).then(res => {
					this.tradeList = res.data;

				})
			},
			//详情
			getInfo() {
				let data = {
					business_incubator_id: this.business_incubator_id
				}
				getBusinessIncubatorInfo(data).then(res => {

					res.data.address_detail = res.data.shop_address_detail;
					this.form = res.data;
					this.form.customer_source = this.form.customer_source.toString();
					this.form.is_urgent = this.form.is_urgent.toString();
					this.form.is_entity = this.form.is_entity.toString();
					let addressData;
					addressData = res.data.shop_province_code + ',' + res.data.shop_city_code + ',' + res.data
						.shop_county_code;
					addressData = addressData.split(",");
					this.address = addressData;
					this.mapAddress = res.data.shop_province + res.data.shop_city + res.data.shop_county
					this.codeAddress();

				})

			},
			//地址
			handleChange(area) {
				this.form.shop_province_code = area[0];
				this.form.shop_city_code = area[1];
				this.form.shop_county_code = area[2];
				this.form.shop_province = CodeToText[this.form.shop_province_code];
				this.form.shop_city = CodeToText[this.form.shop_city_code];
				this.form.shop_county = CodeToText[this.form.shop_county_code];
				this.mapAddress = this.form.shop_province + this.form.shop_city + this.form.shop_county
				this.codeAddress();
			},
			/** 详细地址 */
			setAddressDetail(value) {
				if (this.form.shop_province && this.form.shop_city && this.form.shop_county) {
					this.mapAddress = CodeToText[this.form.shop_province_code] + CodeToText[this.form.shop_city_code] +
						CodeToText[
							this.form.shop_county_code] + value
					this.codeAddress()
				} else {
					this.mapAddress = this.mapAddress = this.longitude = this.latitude = value
				}
			},
			//输入调地图
			inputAddress() {
				this.mapAddress = this.addresskeywords;
				this.codeAddress();
			},
			codeAddress() {
				let lng, lat;
				let geocoder = new qq.maps.Geocoder();
				//对指定地址进行解析
				var result = geocoder.getLocation(this.mapAddress);
				//设置服务请求成功的回调函数
				geocoder.setComplete(result => {
					this.longitude = result.detail.location.lng;
					this.latitude = result.detail.location.lat;
					this.setMap()

				});
				//若服务请求失败，则运行以下函数
				geocoder.setError(function() {
					//alert("出错了，请输入正确的地址！！！");
				});
			},
			setMap() {
				var myLatlng = new qq.maps.LatLng(this.latitude, this.longitude);
				var myOptions = {
					zoom: 8,
					center: myLatlng,
					mapTypeId: qq.maps.MapTypeId.ROADMAP
				}
				var map = new qq.maps.Map(document.getElementById("container"), myOptions);
				var marker = new qq.maps.Marker({
					position: myLatlng,
					map: map
				});
			},
			//返回上一步
			toBack() {
				// 返回列表页
				this.$router.go(-1);
			},
			//保存提交
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.form));
						if (this.business_incubator_id) {
							data.business_incubator_id = this.business_incubator_id
						}
						data.longitude = this.longitude;
						data.latitude = this.latitude;
						delete data["marketing_trade_info"];
						data = this.tools.filterParams(data);

						//调接口
						createOrUpBusinessIncubator(data).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.$router.push({
									path: "/clientadmin/todistributed/bussinesslist",
								});
							}
						})

					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.formBox::v-deep.el-form-item {
		margin-bottom: 10px;
	}

	.mapsure {
		font-size: 14px;
		color: #999999;
	}
	::v-deep.el-form-item__error{
		padding-top: 0;
	}
	.pd70{
		padding-bottom: 70px;
	}
</style>
