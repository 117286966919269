<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont pp20">
					<el-row  class="all_search_list">
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toBackGood">返回管理商品</el-button>
							<el-button size="small" @click="batchOpera('add')">批量添加</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-select v-model="search.one_cat_id" filterable clearable size="small" style="width: 11.25rem;"
								placeholder="请选择一级分类" @change="getCats(1)">
								<el-option v-for="item in oneLevel" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
							<el-select v-model="search.two_cat_id"  filterable clearable size="small"
								style="margin-left:10px;width: 11.25rem;" placeholder="请选择二级分类"
								v-if="twoLevel && twoLevel.length > 0">
								<el-option v-for="item in twoLevel" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-input class="inputWidth" placeholder="请输入商品名称" v-model="search.activity_name" size="small"
								type="text">
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toSearch">查询</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
					</el-row>
				</div>
				<div class="main-cont mt10">
					<div class="tableMain">
						<el-table :data="tableData" style="width: 100%" min-height="250" border
							@selection-change="handleSelectionChange">
							<el-table-column type="selection" width="55">
							</el-table-column>
							<el-table-column label="商品名称" width="380" align="left">
								<template slot-scope="scope">
									<div class="el_name">
										<img :src="scope.row.goods_main_pic.split(',', 1)" class="el_img" />
										<div>
											<p>{{ scope.row.goods_name }}</p>
											<p class="mt10" style="color: #E6A23C;">{{ scope.row.count }}</p>
										</div>
									</div>
								</template>
							</el-table-column>

							<el-table-column prop="sku_circumscribed_price" label="成本价" align="center">
							</el-table-column>
							<el-table-column prop="sku_ordinary_store_manager_price" label="普通店长价" align="center">
							</el-table-column>
							<el-table-column prop="sku_premium_store_manager_price" label="高级店长价" align="center">
							</el-table-column>
							<el-table-column prop="sale" label="销量" align="center">
							</el-table-column>
							<el-table-column prop="goods_stock" label="库存" align="center">
							</el-table-column>
							<el-table-column label="操作" align="center" prop="status" fixed="right">
								<template slot-scope="scope">
									<el-button v-if="scope.row.been_added==false" type="text" size="small"
										@click="addGood(scope.row)">添加</el-button>
									<el-button style="color: #757993;" v-else-if="scope.row.been_added==true"
										type="text" size="small">已添加</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</div>
		<!-- 分页 -->
		<div class="pagecurrent">
			<div class="pagination-container">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		goods_lists, //商品列表
		platform_goods_delete,
		cate_list, //商品分类
	} from '@/api/plat/goods'
	import {
		addActivityGoods
	} from '@/api/plat/market'
	export default {
		data() {
			return {
				activity_name: '',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				multipleSelection: [],
				goods_ids: [],
				activity_id: '',
				search: {
					one_cat_id: '',
					two_cat_id: '',
					activity_name: '',
				},
				oneLevel: [],
				twoLevel: [],
				cateId: "", //判断是否需要清空二级分类id
			}
		},
		mounted() {
			this.activity_id = this.$route.query.id;
			this.getList();
			this.getCats() //商品分类
		},
		methods: {
			//获取商品列表
			getList() {
				let data = {
					activity_id: this.activity_id,
					is_publish: '1',
					cate_id: this.search.two_cat_id ? this.search.two_cat_id : this.search.one_cat_id,
					page: this.currentPage,
					perPage: this.pagesize,
					keyword: this.search.activity_name
				}
				data = this.tools.filterParams(data);
				goods_lists(data).then(res => {
					this.tableData = res.data.list;
					this.total = res.data.count;
				})
			},
			//获取分类
			getCats(type) {
				if (type == 1) {
					this.search.cate_id = this.search.one_cat_id;
					//清空二级分类
					this.search.two_cat_id && (this.search.two_cat_id = "");
				}
				cate_list({
						parent_id: this.search.cate_id ? this.search.cate_id : 0
					})
					.then((res) => {
						// console.log(新增商品);
						if (type == 1) {
							this.twoLevel = res.data;
						} else {
							this.oneLevel = res.data;
						}
					})
					
			},
			//搜索
			toSearch() {
				//搜索
				this.getList()
			},
			toReset(){
				this.search.two_cat_id = '';
				this.search.one_cat_id = '';
				this.search.activity_name = '';
				this.currentPage = 1;
				this.getList()
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//返回商品管理
			toBackGood() {
				this.$router.push({
					path: "/marketingadmin/cattleshopgood/cattleshoppage"
				})
			},
			//商品库添加商品
			addGood(row) {
				let data = {
					activity_id: this.activity_id,
					goods_ids: row.original_goods_unified_id
				}
				addActivityGoods(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.$router.go(-1)
					}

				})
				// this.$router.push({
				// 	path:"/marketingadmin/cattleshopgood/activitygoods"
				// })
			},
			//批量选择
			handleSelectionChange(val) {
				val.forEach(mul => {
					this.multipleSelection.push({
						original_goods_unified_id: mul.original_goods_unified_id
					});
					this.goods_ids.push(mul.original_goods_unified_id)
				})
			},
			//批量添加
			batchOpera(type) {
				if (this.multipleSelection.length == 0) {
					this.$message.info("请选择商品！");
					return;
				}
				if (type == 'add') {
					//调取添加接口
					let data = {
						activity_id: this.activity_id,
						goods_ids: this.goods_ids.toString()
					}
					addActivityGoods(data).then(res => {
						if (res.return_code == 'SUCCESS') {
							this.$message.success("添加成功！");
							this.getList()
							this.$router.push({
								path: "/marketingadmin/cattleshopgood/activitygoods",
								query: {
									id: this.activity_id
								},
							})

						}
					})
				}
				if (type == 'remove') {
					this.tools.confirm("确定要批量删除？").then(res => {
						if (res) {
							//调取批量删除接口
							let data = {
								original_goods_unified_id: JSON.stringify(this.multipleSelection)
							}
							platform_goods_delete(data).then(res => {
								this.$message.success("批量删除成功！");
								this.getList();
							})
						}
					})
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.el_img {
			width: 70px;
			height: 70px;
			margin-right: 6px;
		}
	}
</style>
