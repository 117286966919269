<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<el-tabs class="tabstyle" v-model="activeName" type="card" @tab-click="handleClick">
					<el-tab-pane name=""><span slot="label">全部订单</span></el-tab-pane>
					<el-tab-pane label="待支付订单" name="1"><span slot="label">待支付</span></el-tab-pane>
					<el-tab-pane :label="type == 'merchant-supply' ? '待牛店发货' : '待发货'" name="2"><span
							slot="label">{{type == 'merchant-supply' ? '待牛店发货' : '待发货'}}</span></el-tab-pane>
					<el-tab-pane :label="type == 'merchant-supply' ? '牛店已发货' : '已发货'" name="3"><span
							slot="label">{{type == 'merchant-supply' ? '牛店已发货' : '已发货'}}</span></el-tab-pane>
					<el-tab-pane label="已完成" name="5"><span slot="label">已完成</span></el-tab-pane>
					<el-tab-pane label="交易关闭" name="6"><span slot="label">交易关闭</span></el-tab-pane>
				</el-tabs>
				<div class="order-state">
					<ul>
						<li v-for="(tim,index) in operation_time">
							<div class="statestep">
								<span class="circleNum" :class="tim.time?' ':'active'">{{index+1}}</span>
								<span class="statetext">{{tim.name}}</span>
								<span class="stateLine" :class="tim.time?' ':'active'"
									:style="index===operation_time.length-1?' display: none;':'display: block'"></span>
							</div>
							<div class="statetime">{{tim.time}}</div>
						</li>
					</ul>
				</div>
				<div class="order-info">
					<div class="order-info-item">
						<div class="or-state"><span></span>订单状态</div>
						<el-form ref="formstate" class="formstyle" :model="formstate" label-width="90px">
							<el-form-item label="订单号:">
								{{formstate.order_number}}
							</el-form-item>
							<el-form-item label="支付方式:">
								<span v-if="formstate.payment_type==0">未支付</span>
								<span v-else-if="formstate.payment_type==1">微信</span>
								<span v-else-if="formstate.payment_type==2">支付宝</span>
								<span v-else-if="formstate.payment_type==3">余额</span>
								<span v-else-if="formstate.payment_type==4">微信组合</span>
								<span v-else-if="formstate.payment_type==5">支付宝组合</span>
							</el-form-item>
							<el-form-item label="快递公司:">
								{{formstate.logistics_name}}
							</el-form-item>
							<el-form-item label="快递单号:">
								{{formstate.courier_code}}
							</el-form-item>
							<el-form-item label="收货人:">
								{{formstate.consignee_name}}
							</el-form-item>
							<el-form-item label="联系方式:">
								{{formstate.consignee_phone}}
							</el-form-item>
							<el-form-item label="收货地址:">
								{{formstate.address}}
							</el-form-item>
						</el-form>
					</div>
					<div class="order-info-item">
						<div class="or-state"><span></span>付款信息</div>
						<el-form ref="formpay" class="formstyle" :model="formpay" label-width="90px">
							<el-form-item label="运费:">
								{{formpay.freight}}
							</el-form-item>
							<el-form-item label="应付:">
								{{formpay.total_money}}
							</el-form-item>
							<el-form-item label="支付流水号:">
								{{formpay.serialNum}}
							</el-form-item>
							<el-form-item label="留言:">
								<div>{{formpay.buyers_remark}}</div>
							</el-form-item>
							<el-form-item label="备注:">
								<div>{{formpay.seller_remark}}</div>
								<div>
									<el-button size="small" type="primary" @click="addDesc" plain>
										{{formpay.seller_remark?"修改备注":'添加备注'}}</el-button>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<div class="orders-table">
					<div class="order-num">{{created_at}} <span
							style="margin-left: 10px;">订单号：{{formstate.order_number}}</span></div>
					<el-table :data="tableData" border style="width: 100%">
						<el-table-column prop="goods" label="商品信息" width="380px">
							<template slot-scope="scope">
								<div style="display: flex">
									<div style="margin:0 20px">
										<img :src="scope.row.master_map" style="width: 70px;height: 70px" />
									</div>
									<div class="namegood">{{ scope.row.goods_name }}</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="sku_desc" label="商品属性" align="center">
						</el-table-column>
						<el-table-column prop="goods_price" label="商品单价" align="center">
						</el-table-column>
						<el-table-column  v-if="fsnumShow"   prop="discount_amounts" label="优惠" align="center">
						</el-table-column>
						<el-table-column prop="goods_count" label="购买数量" align="center">
						</el-table-column>
						<el-table-column prop="freight" label="运费" align="center">
						</el-table-column>
						<el-table-column label="订单状态" align="center">
							<template slot-scope="scope">
								<p v-if="scope.row.status === 1">
									<!-- 待付款 -->
									<span>待支付 </span>
									<br />
									<el-tag style="margin-top: 5px; cursor: pointer;" size="small"
										@click="closeOrders()">取消订单</el-tag>
								</p>
								<p v-else-if="scope.row.status === 2">
									<span>已支付</span>
									<br />
									<el-tag style="margin-top: 5px; cursor: pointer;"  v-if="group_buying_id == 0" size="small" @click="shipments()">
										发货</el-tag>
									<el-tag style="margin-top: 5px; cursor: pointer;"  v-else-if="(group_buying_status==1 || group_buying_status==2) && group_pay_status==1" size="small" @click="shipments()">
										发货</el-tag>
								</p>
								<p v-else-if="scope.row.status === 3">
									<span>待收货</span>
									<br />
									<el-tag style="margin-top: 5px; cursor: pointer;" size="small"
										@click="logisticsQuery()">物流查询</el-tag>
								</p>
								<p v-else-if="scope.row.status === 4">
									<span>已收货</span>
								</p>
								<p v-else-if="scope.row.status === 5">
									<span>已完成 </span>
								</p>
								<p v-else-if="scope.row.status === 6">
									<span>已关闭 </span>
								</p>
							</template>
						</el-table-column>
						<el-table-column prop="goods_actual_payment" label="付款金额" align="center">
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<!--添加备注弹框-->
		<el-dialog title="添加备注" :visible.sync="addDescModel" width="25%">
			<el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="descInfo">
			</el-input>
			<div class="submitBtn">
				<el-button size="small" @click="addDescModel=false">取消</el-button>
				<el-button size="small" type="primary" @click="onSubmit">提交</el-button>
			</div>
		</el-dialog>
		<!--物流发货弹框-->
		<el-dialog title="物流发货" :visible.sync="logisticsModel" width="30%">
			<el-form ref="logisticsForm" :model="logisticsForm" :rules="logisticsRules" label-width="100px"
				size="small">
				<el-form-item label="物流单号:" prop="oddNum">
					<el-input class="inputWidth" v-model="logisticsForm.oddNum" placeholder="请填写物流单号"></el-input>
				</el-form-item>
				<el-form-item label="物流公司:" prop="company">
					<el-select v-model="logisticsForm.company">
						<el-option v-for="item in logisticData" :key="item.name" :value="item.name" :label="item.name">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="logisticsModel=false">取消</el-button>
				<el-button size="small" type="primary" @click="onSubmitLogistic('logisticsForm')">提交</el-button>
			</div>
		</el-dialog>
		<!--物流详情-->
		<el-dialog title="物流详情" :visible.sync="logisticsDetailModel" width="40%">
			<div v-if="activities.length>0">
				<div style="font-size: 16px;margin-bottom: 20px;color: #409EFF;">快递名称：{{typename}} 快递单号：{{typenumber}}</div>
				<el-timeline>
					<el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.time">
						{{activity.status}}
					</el-timeline-item>
				</el-timeline>
			</div>
			<div v-else style="height: 100px; display: flex;align-items: center;justify-content: center;">
				暂无快递信息！
			</div>

		</el-dialog>
	</div>
</template>

<script>
	import {
		orderInfo,
		getExpressInfoList,
		dispatchBill,
		closeOrder,
		orderNumberQuery
	} from '@/api/merchant/order.js'
	import {
		saveRemark
	} from '@/api/plat/order.js'
	export default {
		props: {
			order_type: String
		},
		data() {
			return {
				addDescModel: false,
				logisticsModel: false,
				logisticsDetailModel: false,
				logisticData: [],
				activeName: '',
				invitation_code: '',
				nickname: '',
				descInfo: '', //备注信息
				formstate: {
					order_number: '',
					payment_type: '',
					logistics_name: "",
					courier_code: '',
					consignee_name: '',
					consignee_phone: '',
					address: '',

				},
				formpay: {
					freight: '',
					total_money: "",
					serialNum: '',
					buyers_remark: '',
					seller_remark: ''
				},
				tableData: [],
				logisticsModel: false,
				logisticsForm: {
					oddNum: '',
					company: ''
				},
				logisticsRules: {
					oddNum: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}, ],
					company: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}]
				},
				operation_time: [],
				id: '',
				created_at: '',
				order_number: this.$route.query.id,
				type: this.$route.query.type,
				activities: [],
				typename: '',
				typenumber:'',
				master_order_number: '',
				group_buying_id: '',
				group_buying_status: '',
				group_pay_status: '',
				fsnumShow:false
			}
		},
		mounted() {
			this.getInfo();
		},
		methods: {
			//订单详情
			getInfo() {
				let params = {
					order_number: this.order_number
				}
				params = this.tools.filterParams(params);
				orderInfo(params).then(res => {
					if (res.return_code === 'SUCCESS') {
						let dd = [];
						dd.push(res.data.order_goods_info);
						this.tableData = dd;
						this.tableData.forEach(item => {
							item.status = res.data.status;
							if(item.discount_amount && item.discount_amount>0){
								item.discount_amounts=item.discount_amount;
								this.fsnumShow=true
							}else{
								this.fsnumShow=false
							}
						});
						this.master_order_number = res.data.order_goods_info.master_order_number;
						this.operation_time = res.data.progress;
						this.formstate.order_number = res.data.order_number;
						this.formstate.payment_type = res.data.payment_type;
						this.formstate.status = res.data.status;

						this.formstate.logistics_name = res.data.logistics_name;
						this.formstate.courier_code = res.data.courier_code;
						this.formstate.consignee_name = res.data.consignee_name;
						this.formstate.consignee_phone = res.data.consignee_phone;

						this.formstate.address = (res.data.consignee_provincial == null ? '' : res.data
							.consignee_provincial) + (res.data.consignee_city == null ? '' : res.data
							.consignee_city) + (res.data.consignee_county == null ? '' : res.data
							.consignee_county) + (res.data.consignee_street == null ? '' : res.data
							.consignee_street);
						this.formpay.freight = res.data.freight;
						this.formpay.total_money = res.data.total_money;
						this.formpay.serialNum = res.data.pay_order_number;
						this.formpay.buyers_remark = res.data.buyers_remark;
						this.formpay.seller_remark = res.data.seller_remark;
						this.created_at = res.data.created_at;
						this.group_buying_id=res.data.group_buying_id;
						this.group_buying_status=res.data.group_buying_status;
						this.group_pay_status=res.data.group_pay_status;
					}
				})

			},

			//发货
			shipments() {
				this.logisticsForm.oddNum = '';
				this.logisticsForm.company = '';
				this.logisticsModel = !this.logisticsModel;
				this.getExpressList()
			},
			//获取物流列表
			getExpressList() {
				let params = {
					pitchon: 1
				}
				params = this.tools.filterParams(params);
				getExpressInfoList(params).then(res => {
					this.logisticData = res.data;

				})

			},
			//提交物流
			onSubmitLogistic(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//调接口
						let data = {
							order_number: this.order_number,
							courierCode: this.logisticsForm.oddNum,
							logisticsName: this.logisticsForm.company
						}
						data = this.tools.filterParams(data)
						dispatchBill(data).then(res => {
							this.logisticsModel = !this.logisticsModel;
							this.getInfo();
							this.logisticsForm.oddNum = '',
								this.logisticsForm.company = ''
						})

					} else {
						return false;
					}
				});
			},
			//取消订单
			closeOrders() {
				this.tools.confirm("确定要取消此订单吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							master_order_number: this.master_order_number
						}
						closeOrder(data).then(res => {
							this.getInfo();
						})

					}
				})
			},
			//tab切换
			handleClick(tab) {
				//商户自营订单
				if (this.$route.query.type == 'merchant-self') {
					this.$router.push({
						path: '/ordergoodadmin/selfsupportorder/selfsupportorderlist',
						query: {
							name: tab.name
						}
					})
				}
				//商户供货订单
				if (this.$route.query.type == 'merchant-supply') {
					this.$router.push({
						path: '/ordergoodadmin/supplierorder/supplierorderlist',
						query: {
							name: tab.name
						}
					})
				}
			},
			//添加备注
			addDesc() {
				this.descInfo = '';
				this.addDescModel = !this.addDescModel
			},
			//提交新增备注
			onSubmit() {
				if (!this.descInfo) {
					this.$message.error("请输入备注信息！")
				} else {
					//调接口
					let data = {
						order_number: this.order_number,
						type: '2',
						remark: this.descInfo
					}
					data = this.tools.filterParams(data);
					saveRemark(data).then(res => {
						if (res.return_code === 'SUCCESS') {
							this.addDescModel = !this.addDescModel
							this.descInfo = '';
							this.getInfo();
						}
					})

				}
			},
			logisticsQuery() {
				this.logisticsDetailModel = !this.logisticsDetailModel;
				let params = {
					order_number: this.order_number
				}
				orderNumberQuery(params).then(res => {
					//物流信息
					if (res.data.list.length > 0) {
						this.activities = res.data.list;
						this.typename = res.data.typename;
						this.typenumber=res.data.number
					}
				})

			}

		}

	}
</script>

<style scoped lang="scss">
	@import "@/assets/css/plat/allorderdetail.scss";

	.order-info-item::v-deep.el-form-item {
		margin-bottom: 0;
	}
</style>
