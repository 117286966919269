import axios from '@/config/httpConfig'
import qs from  'qs'
//课程老师列表
export const lecturerListPage=(params)=> {
	  return axios('get','/College/platform/lecturerListPage', params)
}
//添加课程老师
export const addLecturer=(params)=> {
	  return axios('get','/College/platform/addLecturer', params)
}
//编辑课程老师
export const updateLecturer=(params)=> {
	  return axios('get','/College/platform/updateLecturer', params)
}
//课程老师详情
export const lecturerInfo=(params)=> {
	  return axios('get','/College/platform/lecturerInfo', params)
}
//删除课程老师
export const delLecturer=(params)=> {
	  return axios('get','/College/platform/delLecturer', params)
}
//修改老师状态
export const updateLecturerStatus=(params)=> {
	  return axios('get','/College/platform/updateLecturerStatus', params)
}
//课程分类列表
export const courseListPage=(params)=> {
	  return axios('get','/College/platform/courseListPage', params)
}
//添加一级课程分类
export const addFirstCourse=(params)=> {
	  return axios('get','/College/platform/addFirstCourse', params)
}
//添加二级课程分类
export const addSecondCourse=(params)=> {
	  return axios('get','/College/platform/addSecondCourse', params)
}
//修改课程分类
export const updateCourse=(params)=> {
	  return axios('get','/College/platform/updateCourse', params)
}
//删除课程分类
export const delCourse=(params)=> {
	  return axios('get','/College/platform/delCourse', params)
}
//课程分类名字信息
export const courseInfo=(params)=> {
	  return axios('get','/College/platform/courseInfo', params)
}
//修改课程分类是否显示
export const updateCourseIsShow=(params)=> {
	  return axios('get','/College/platform/updateCourseIsShow', params)
}
//获取课程列表
export const getCollegeCurriculumList=(params)=> {
	  return axios('get','/College/platform/getCollegeCurriculumList', params)
}
//课程分类列表
export const courseList=(params)=> {
	  return axios('get','/College/platform/courseList', params)
}
//课程讲师列表
export const lecturerList=(params)=> {
	  return axios('get','/College/platform/lecturerList', params)
}
//课程添加/修改
export const saveCollegeCurriculum=(data)=> {
  return axios('post','/College/platform/saveCollegeCurriculum', data);
}
//商学院-课程-课程视频添加/修改
export const saveCurriculumVideo=(data)=> {
  return axios('post','/College/platform/saveCurriculumVideo', data);
}
//商学院-课程-课程视频列表
export const getCurriculumVideoList=(params)=> {
	  return axios('get','/College/platform/getCurriculumVideoList', params)
}
//商学院-课程-删除课程视频
export const delCurriculumVideo=(params)=> {
	  return axios('get','/College/platform/delCurriculumVideo', params)
}
//商学院-课程-课程视频信息
export const getCurriculumVideoInfo=(params)=> {
	  return axios('get','/College/platform/getCurriculumVideoInfo', params)
}
//商学院-课程-视频状态修改
export const saveVideoStatus=(params)=> {
	  return axios('get','/College/platform/saveVideoStatus', params)
}
//获取指定课程基础信息
export const getCollegeCurriculumInfo=(params)=> {
	  return axios('get','/College/platform/getCollegeCurriculumInfo', params)
}
//商学院-课程-状态修改
export const saveStatus=(data)=> {
  return axios('post','/College/platform/saveStatus', data);
}
//商学院-课程-删除课程
export const delCurriculum=(params)=> {
	  return axios('get','/College/platform/delCurriculum', params)
}
//获取活动秒杀列表-admin
export const getSecondsKillList=(params)=> {
	  return axios('get','/College/platform/getSecondsKillList', params)
}
//秒杀课程添加/修改
export const saveSecondsKill=(data)=> {
  return axios('post','/College/platform/saveSecondsKill', data);
}
//获取活动秒杀详情-admin
export const getSecondsKillInfo=(params)=> {
	  return axios('get','/College/platform/getSecondsKillInfo', params)
}
//获取活动秒杀-停止活动
export const stopSecondsKill=(params)=> {
	  return axios('get','/College/platform/stopSecondsKill', params)
}
//获取活动秒杀-删除活动
export const delSecondsKill=(params)=> {
	  return axios('get','/College/platform/delSecondsKill', params)
}