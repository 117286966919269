<template>
	<div>
		<div class="wrapper-cont" style="padding: 0;">
			<div class="shopmain">
				<!--头部-->
				<div class="wrapper-main pp20">
					<el-row type="flex" class="row-bg" justify="space-between">
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div class="flex">
									<img :src="head_portrait" class="head_portrait" />
									<div class="shop_title">牛店-淘货市场</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" style="text-align: right;">
							<div class="grid-content bg-purple">
								<el-input placeholder="请输入内容" size="small" class="search_input"
									suffix-icon="el-icon-search" v-model="shop_search"  @keyup.enter.native="searchGood" clearable>
									<template slot="append" class="searchbtns"> <span
											@click="searchGood">搜索</span></template>
								</el-input>
							</div>
						</el-col>
					</el-row>
				</div>
				<!--滚动-->
				<div class="detail_cons">
					<div class="cons_top">
						<div class="top_left">
							<div class="thumb-example">
								<!-- swiper1 -->
								<swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
									<swiper-slide v-for="(item,index) in imgs" :key="index">
										<img :src="item" @click="replaceImg(item)" />
									</swiper-slide>
								</swiper>
								<!-- swiper2 Thumbs -->
								<swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
									<swiper-slide v-for="(item,index) in imgs" :key="index">
										<img :src="item" @click="replaceImg(item)" />
									</swiper-slide>
								</swiper>
							</div>
						</div>
						<div class="top_right">
							<div class="top_title">{{goods_name}}</div>
							<div class="profit_cons">
								<div class="profit_items">
									<div class="items">
										<label>利润比</label><span class="first"
											style="color: #F56C6C;">{{amountInterval.profitMinimumPrice}} -
											{{amountInterval.profitTopPrice}}</span>
									</div>
									<div class="items">
										<label>建议售价</label><span
											v-if="amountInterval.suggestedMinimumPrice || amountInterval.suggestedTopPrice">￥{{amountInterval.suggestedMinimumPrice}}
											- ￥{{amountInterval.suggestedTopPrice}}</span>
									</div>
									<div class="items">
										<label>供货价</label><span
											v-if="amountInterval.supplyMinimumPrice || amountInterval.suggestedTopPrice">￥{{amountInterval.supplyMinimumPrice}}
											- ￥{{amountInterval.supplyTopPrice}}</span>
									</div>
								</div>
							</div>
							<div class="Sales" style="padding-left:10px;">
								<label>总销量</label>
								<div class="flex sales_cons">
									<div class="sales_item"><span>{{amountNum.sale}}</span> 件 </div>
									<div class="line"></div>
									<div class="sales_item">已有<span>{{amountNum.shopGoodsCount}}</span> 位分销商上架</div>
								</div>
							</div>
							<div class="Sales"  style="padding-left:10px;">
								<label>总库存</label>
								<div class="count">{{amountNum.countStock}}</div>
							</div>

							<div class="Salesnum" style="margin-top:15px;padding-left: 15px;" >
								<div style="width: 100%;" class="clearfix">
									<div style=" float: left;">
										<div v-for="(gg,index) in skuName" class="skuname" :key="index"
											:style="goods_sku.map(item => item.is_img == 2) && index==0 ? 'height:130px;line-height:60px' : 'height:50px;line-height:50px' ">
											{{gg}}</div>
										<!--左边-->
									</div>
									<div class="colors" style="margin-left:20px; float: left;">
										<!--  --><!-- {{scrollLength>2}} --><!-- {{goodsval.map(item => item.value.length>3).toString()}} -->
										<div class="scroolWidth"  :style="{'overflow-x':goodsval.map(item => item.value.length>7).toString()=='true'?'scroll':'hidden'}">
											<!-- :style="{'overflow-x':goodsval.map(item => item.value.length>7)?'scroll':'none'}" -->
											<!--右边-->
											<!--图片第一规格-->
											<div class="img_blo" style="margin-bottom: 10px;"
												v-if="goods_sku.map(item => item.is_img == 2) ">
												<div class="item_img " v-for="(regu,index) in goods_sku" :key="index"
													:class="{ 'active': (active == regu.name && regu.sku_img)}"
													@click="selectedregu(regu.name,index)">
													<img v-if="regu.sku_img" :src="regu.sku_img" />
													<img src="../../../../../assets/img/check.png"
														v-if="active == regu.name && regu.sku_img " class="active_img" />
												</div>
											</div>
											<!--名字第一规格-->
											<div class="img_blo">
												<div class="item " v-for="(regu,index) in goods_sku" :key="index"
													:class="{ 'active': active == regu.name }"
													@click="selectedregu(regu.name,index)">
													<span>{{regu.name}}</span>
												</div>
											</div>
										</div>
										


										<div v-for="(ggval,idx) in reguList" :key="idx" class="flex mt20">
											<div style="width: 150px;">{{ggval.standard}}</div>
											<div style="width: 150px;">{{ggval.goods_price}}元</div>
											<div style="width: 150px;">{{ggval.sku_stock}}件可售</div>
										</div>
									</div>
								</div>
							</div>
							<div class="detail_btns" style="margin-left: 80px;">
								<div class="btns" @click="toPutaway">上架到店铺</div>
								<div class="btns second" @click="toAdd">添加到仓库</div>
							</div>


						</div>
					</div>
				</div>
				<div class="top_det">
					<div class="det_title">商品详情</div>
					<div id="ol_boxs" class="detail_cons">
						<template v-if="details.goodsDetailList">
							<div v-for="(img,index) in details.goodsDetailList" :key="index">
								<img :src="img" />
							</div>

						</template>
						<template v-else>
							<div v-html="details.goods_detail" class="det_cons"></div>
						</template>
					</div>
				</div>
			</div>
		</div>
		<!-- 公用客服 -->
		<div class="common_service">
			<div class="item_blo">
				<div class="items" @mouseover="showOrHide">
					<img src="../../../../../assets/img/common_kefu.png" />
					<div>客服咨询</div>
					<div class="line"></div>
					<div class="consule_blo">
						<img src="../../../../../assets/img/consule.png" class="blo_img">
						<div class="blo_div">
							<!-- <img src="../assets/images/join_close.png" class="div_close" @click="showOrHide"> -->
							<div class="blo_con">
								<div class="con_div">想了解更多，欢迎拨打热线：<span
										class="span1">17603083828</span><span>你也可以留下联系方式，我们会主动和你联系</span></div>
								<div class="con_btn" @click="toConsule">在线咨询</div>
							</div>
						</div>
					</div>
				</div>
				<div class="items">
					<img src="../../../../../assets/img/common_code.png" />
					<div>添加好友</div>
					<div class="line"></div>
					<div class="friend_blo" :style="{display:addFriend ? 'block' : 'none'}">
						<img src="../../../../../assets/img/fried_code.png" class="code_img" />
					</div>
				</div>
				<!-- 铺货中心才会显示 -->
				<div class="items content-container-top" @click="toOrds" v-if="isGoods">
					<img src="../../../../../assets/img/common_ords.png" />
					<div>商品仓</div>
					<!-- <div class="line"></div> -->
				</div>
				<!-- <div class="items" @click="toTop()">
					<img src="../../../../../assets/img/common_top.png" />
					<div style="paddingTop:8px">TOP</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ShoppingMerchandiseDetails,
		StoreManagerSpreadGoodsTransfer
	} from "@/api/merchant/shopping.js"
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/dist/css/swiper.css'
	export default {
		components: {
			Swiper,
			SwiperSlide
		},

		data() {
			return {
				selected: [],
				reguList: [],
				head_portrait: localStorage.getItem("head_portrait"),
				shop_search: '',
				swiperOptionTop: {
					loop: true,
					loopedSlides: 5, // looped slides should be the same
					spaceBetween: 10,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				swiperOptionThumbs: {
					loop: true,
					loopedSlides: 5, // looped slides should be the same
					spaceBetween: 10,
					centeredSlides: true,
					slidesPerView: 'auto',
					touchRatio: 0.2,
					slideToClickedSlide: true
				},
				imgs: [],
				// goods_address: [{
				// 	"province": null,
				// 	"city": null,
				// 	"country": null
				// }],

				goodsDetailList: [],
				detailsCon: '<p>二二五二</p>',
				goods_unified_id: this.$route.query.id,
				amountInterval: {
					supplyTopPrice: '',
					supplyMinimumPrice: '',
					suggestedTopPrice: '',
					suggestedMinimumPrice: '',
					profitTopPrice: '',
					profitMinimumPrice: '',
				},
				amountNum: {
					sale: '',
					shopGoodsCount: '',
					countStock: ''
				},
				details: {}, //商品详情
				goods_sku: [], //所有规格
				skuName: [],
				active: '',
				primeval_goods_id: '',
				original_goods_unified_id: '',
				addFriend: false, //是否展示添加好友弹出层
				isGoods: true, //是否在铺货中心
				isActive: false,
				goods_name:'',
				goodsval:[],
				scrollLength:''
			}
		},
		mounted() {
			this.getGoodInfo();
			this.$nextTick(() => {
				const swiperTop = this.$refs.swiperTop.$swiper
				const swiperThumbs = this.$refs.swiperThumbs.$swiper
				swiperTop.controller.control = swiperThumbs
				swiperThumbs.controller.control = swiperTop
			});
			let goods_detail =
				"https://qiniu.duihuo360.com/110136942520176641/202006/GnfVNXDZcfRyv4ioAnfGrE1mdTiDPt0dTEvl2YV2.jpeg,https://qiniu.duihuo360.com/110136942520176641/202006/6dmNQts0TlzU0BBRT88ozcw0K0TPywwlWjb9eEM9.jpeg,https://qiniu.duihuo360.com/110136942520176641/202006/jqUjJcbZGfT8H5xwyQOYTWbYSBzmkOhDDk35lJoy.jpeg,https://qiniu.duihuo360.com/110136942520176641/202006/MYqRRR9NJUbad7nK7GwqSuNiE4rt3xfnUuv8CZzG.jpeg"
			this.goodsDetailList = goods_detail.split(',');
			window.addEventListener("scroll", this.handleScroll, true);
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll); //离开页面需要移除这个监听的事件
		},

		methods: {
			//搜索
			searchGood() {
				this.$router.push({
					path: '/shoppingadmin/moreclassify',
					query: {
						keyword: this.shop_search
					}
				})
			},
			selectedregu(name, index) {
				this.active = name;
				this.reguList = this.goods_sku[index].value
			},
			//获取详细信息
			getGoodInfo() {
				let data = {
					goods_unified_id: this.goods_unified_id
				}
				ShoppingMerchandiseDetails(data).then(res => {
					this.imgs = res.data.goods_main_pic.split(',');
					this.amountInterval = res.data.amountInterval;
					this.goods_name=res.data.goods_name;
					this.amountNum = {
						sale: res.data.sale,
						shopGoodsCount: res.data.shopGoodsCount,
						countStock: res.data.countStock,
					};
					// 判断详情的类型
					if (res.data.goods_detail.indexOf('</') < 0) {
						res.data.goodsDetailList = res.data.goods_detail.split(',');
					};
					this.goodsval=res.data.goods_sku;
					res.data.goods_sku.forEach(item=>{
						this.scrollLength=item.value.length
					})
					//console.log(this.goodsval.map(item=>{item.value}),"888")
					
					this.details = res.data;
					this.goods_sku = res.data.sku;
					this.skuName = res.data.skuName;
					this.reguList = res.data.sku[0].value;
					this.primeval_goods_id = res.data.primeval_goods_id;
					this.original_goods_unified_id = res.data.original_goods_unified_id


				})

			},
			//去上架
			toPutaway() {
				let obj = {},
					arr = [];
				obj[this.primeval_goods_id] = this.original_goods_unified_id;
				arr.push(obj)
				let data = {
					goodsInfo: JSON.stringify(arr),
					is_publish: '1'
				}
				StoreManagerSpreadGoodsTransfer(data).then(res => {
					this.$router.go(-1)
				})
			},
			//去添加
			toAdd() {
				let obj = {},
					arr = [];
				obj[this.primeval_goods_id] = this.original_goods_unified_id;
				arr.push(obj)
				let data = {
					goodsInfo: JSON.stringify(arr),
					is_publish: '2'
				}
				StoreManagerSpreadGoodsTransfer(data).then(res => {
					this.$router.go(-1)
				})
			},
			// 关闭or打开弹出层
			showOrHide() {
				// this.consuleFlag = !this.consuleFlag;
				this.addFriend = false;
			},
			// 点击咨询
			toConsule() {
				this.$message.error("客服小姐姐跑丢了~~~");
			},
			// 去订货单页面
			toOrds() {
				this.$router.push({
					path: '/merchandiseadmin/cattleshopmerchandise/cattleshoplist'
				})
			},
			handleScroll(e) {
				let scrolltop = e.target.scrollTop;
				scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
			},
			toTop() {

				let top = document.documentElement.scrollTop || document.body.scrollTop;
				// 实现滚动效果 
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			}
		}
	}
</script>
<style lang="scss" scoped>
	@import "../../../../../assets/css/shopping/shopping.scss";
	@import "../../../../../assets/css/shopping/details.scss";

	.skuname {
		font-size: 14px;
		font-weight: 400;
		color: #999;
	}

	.thumb-example {
		width: 300px;
	}

	.swiper {
		.swiper-slide {
			background-size: cover;
			background-position: center;

		}

		&.gallery-top {
			width: 300px;
			height: 300px;
			display: block;
			margin-bottom: 10px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		&.gallery-thumbs {
			width: 300px;
			display: flex;
			position: relative;

			img {
				width: 100%;
				height: 100%;
			}
		}

		&.gallery-thumbs .swiper-slide {
			width: 56px;
			height: 56px;
			display: block;
			cursor: pointer;
			margin-right: 5px;
			// opacity: 0.4;
		}

		&.gallery-thumbs .swiper-slide-active {
			opacity: 1;
		}
	}

	.areaBox {
		background: #fff;
		color: #333;
		font-size: 16px;
	}

	.areaBox::v-deep.area-select-wrap .area-select {
		width: 64px;
		text-align: left;
		border: none;
		background: none;
	}

	.areaBox::v-deep.area-select-wrap .area-select span {
		padding: 8px 10px 7px 0;
	}

	.areaBox::v-deep.area-selectable-list-wrap {
		width: 428px !important;
		border: none;
		box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
		font-size: 12px;
		color: #333;
	}

	.areaBox::v-deep.area-selectable-list {
		display: flex;
		flex-wrap: wrap;
		padding: 20px;
		box-sizing: border-box;
	}

	.areaBox::v-deep.area-selectable-list li {
		width: 76px;
		text-align: center;
	}

	.areaBox::v-deep.area-selectable-list .area-select-option.selected {
		background: #FFF3F3;
		color: #D01717;
		font-size: 12px;
		border-radius: 2px;
	}

	.areaBox::v-deep.area-selectable-list .area-select-option.hover {
		background-color: #FFF3F3;
		color: #D01717;
	}

	.areaBox::v-deep.area-select-wrap .area-select-empty {
		text-align: center;
		width: 100%;
	}

	.img_blo {
		display: flex;
		align-items: center;
		position: relative;

		.item_img {
			margin-right: 10px;
			cursor: pointer;
			position: relative;

			>img {
				width: 80px;
				height: 80px;
				display: block;
			}

			.active_img {
				width: 16px;
				height: 16px;
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}

		.item {
			min-width: 78px;
			box-sizing: border-box;
			padding: 0 10px;
			border: 1px solid #979797;
			height: 40px;
			color: #444;
			font-size: 14px;
			text-align: center;
			line-height: 40px;
			border-radius: 2px;
			margin-right: 10px;
			cursor: pointer;
			float: left;

		}

		.active {
			color: #D01717;
			border: 1px solid #D01717;
		}


	}

	/* 公用客服样式 */
	.common_service {
		position: fixed;
		right: 50px;
		top: 50vh;
		display: flex;
		align-items: center;
		z-index: 100;
		background: #CF1717;

		.item_blo {
			width: 60px;
			color: #fff;
			font-size: 12px;
			padding: 12px 0;
			box-sizing: border-box;
			cursor: pointer;

			.line {
				width: 46px;
				margin: 8px auto 10px;
				border-top: 2px solid #A31B1B;
			}
		}

		.items {
			text-align: center;
			position: relative;

			&:hover .consule_blo {
				display: flex;
			}

			&:hover .friend_blo {
				display: block !important;
			}

			image {
				width: 36px;
				height: 36px;
				margin: 0 auto 4px;
				display: block;
			}


			.con {
				border-bottom: 1px solid #A31B1B;
				padding: 0 12px;
			}

			.item_circle {
				position: absolute;
				right: -12px;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background: #FF9F01;
				text-align: center;
				line-height: 24px;
				font-size: 12px;
				border: 1px solid #fff;
				top: -8px;
			}
		}

		.items:last-child {
			padding-bottom: 0;
		}

		.friend_blo {
			position: absolute;
			right: 50px;
			top: -66px;
			display: none;
			width: 200px;
			height: 200px;
			margin-right: 28px;


		}

		.consule_blo {
			display: none;
			width: 386px;
			height: 200px;
			right: 60px;
			position: absolute;
			top: -10px;
			justify-content: center;

			.blo_div {
				background: #fff;
				box-shadow: 0px 2px 8px 0px rgba(231, 231, 231, 0.83);
			}

			filter: blur(0px);

			.blo_img {
				width: 80px;
				height: 80px;
				position: absolute;
				top: -40px;
			}

			.blo_div {
				.div_close {
					width: 22px;
					height: 22px;
					position: absolute;
					right: 42px;
					top: 12px;
					cursor: pointer;
				}

				.blo_con {
					font-size: 16px;
					color: #c1c1c1;
					margin: 66px auto 14px;

					.con_div {
						margin: 26px auto 14px;
						padding: 0 25px;
						line-height: 1.5;
						text-align: center;

						.span1 {
							font-weight: bold;
							font-size: 20px;
						}

						.span2 {
							font-size: 18px;
							color: #c2c2c2;
						}
					}

					.con_btn {
						width: 132px;
						height: 40px;
						border: 1px solid #D01717;
						border-radius: 4px;
						text-align: center;
						line-height: 40px;
						color: #D01717;
						font-size: 16px;
						margin: 0 auto;
						cursor: pointer;
					}
				}
			}
		}
	}
	.scroolWidth{
		width: 43.75rem;
	}
</style>
