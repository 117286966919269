<template>
  <div>
    <div class="wrapper-main">
      <div class="main">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick" style="background-color: #fff;">
          <el-tab-pane label="等级一" name="1"><gradeContent :grade="activeName" @callBanck="callBanck" v-if="this.activeName==1"></gradeContent></el-tab-pane>
          <el-tab-pane label="等级二" name="2"><gradeContent :grade="activeName" @callBanck="callBanck" v-if="this.activeName==2"></gradeContent></el-tab-pane>
          <el-tab-pane label="等级三" name="3"><gradeContent :grade="activeName" @callBanck="callBanck" v-if="this.activeName==3"></gradeContent></el-tab-pane>
          <el-tab-pane label="等级四" name="4"><gradeContent :grade="activeName" @callBanck="callBanck" v-if="this.activeName==4"></gradeContent></el-tab-pane>
          <el-tab-pane label="等级五" name="5"><gradeContent :grade="activeName" @callBanck="callBanck" v-if="this.activeName==5"></gradeContent></el-tab-pane>
          <el-tab-pane label="等级六" name="6"><gradeContent :grade="activeName" @callBanck="callBanck" v-if="this.activeName==6"></gradeContent></el-tab-pane>
          <el-tab-pane label="等级七" name="7"><gradeContent :grade="activeName" @callBanck="callBanck" v-if="this.activeName==7"></gradeContent></el-tab-pane>
        </el-tabs>
      </div>
      
    </div>
  </div>
</template>
<script>
import gradeContent from "@/components/pagecomponents/gradeContent.vue"
export default {
  components:{
    gradeContent
  },
  data() {
    return {
      activeName: '1'
    }
  },
  mounted() {

  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    callBanck(val){
     this.activeName=val+''
    }
  }
}
</script>
<style lang="less" scoped>
.wrapper-main {
  // background-color: red;
  // padding: 10px;

  .main {
    width: 100%;
    background-color: #F2F2F6;
    min-height: 700px;
    border-radius: 10px;
    padding: 10px;
    // padding-bottom: 40px;
  }
}
</style>