import axios from '@/config/httpConfig'
import qs from  'qs'
//获取商品分类
export const BusinessIncubatorList=(data)=> {
    return axios('post','/Account/platform/businessIncubatorList', data);
}
//创建
export const createOrUpBusinessIncubator=(data)=> {
    return axios('post','/Account/platform/createOrUpBusinessIncubator', data);
}
//详情
export const followRemark=(data)=> {
    return axios('post','/Account/platform/followRemark', data);
}
export const getBusinessIncubatorInfo=(data)=> {
    return axios('post','/Account/platform/getBusinessIncubatorInfo', data);
}

//待分配详情
export const businessIncubatorLinkmanList=(data)=> {
    return axios('post','/Account/platform/businessIncubatorLinkmanList', data);
}
//分配服务商列表
export const platformGetChannelList=(params)=> {
	  return axios('get','/Account/platform/platformGetChannelList', params)
}
//分配机会
export const allotBusinessIncubator=(data)=> {
    return axios('post','/Account/platform/allotBusinessIncubator', data);
}
//储值套餐列表
export const petCardList=(params)=> {
	 return axios('get','/Account/platform/petCardList', params)
}
//储值套餐添加/修改
export const savePetCard=(data)=> {
    return axios('post','/Account/platform/savePetCard', data);
}
//储值套餐删除
export const delPetCard=(params)=> {
	 return axios('get','/Account/platform/delPetCard', params)
}
//服务商列表
export const getChannelList=(data)=> {
    return axios('post','/Account/platform/getChannelList', data);
}
//获取服务商等级
export const channelAgentLevel=(data)=> {
    return axios('post','Commission/platform/channelAgentLevel', data);
}
//平台(创建/修改)服务商账号
export const createOrUpChannelAccount=(data)=> {
    return axios('post','/Account/platform/createOrUpChannelAccount', data);
}
//获取指定服务商信息
export const getChannelAccountInfo=(data)=> {
    return axios('post','/Account/platform/getChannelAccountInfo', data);
}
//获取服务商流水记录
export const getChannelFundLog=(data)=> {
    return axios('post','/Account/platform/getChannelFundLog', data);
}
//获取指定服务商下级门店列表
export const getChanneShopList=(data)=> {
    return axios('post','/Account/platform/getChanneShopList', data);
}
//服务商密码修改
export const upChannelPasswd=(data)=> {
    return axios('post','/Account/platform/upChannelPasswd', data);
}
//服务商账号删除
export const delChannel=(data)=> {
    return axios('post','/Account/platform/delChannel', data);
}
//修改服务商账号状态-启用/停用
export const setChannelStatus=(data)=> {
    return axios('post','/Account/platform/setChannelStatus', data);
}
//平台审核服务商账号-通过/拒绝
export const auditChannelType=(data)=> {
    return axios('post','/Account/platform/auditChannelType', data);
}
//批量处理-平台审核服务商账号-通过/拒绝
export const batchAuditChannelType=(data)=> {
    return axios('post','/Account/platform/batchAuditChannelType', data);
}
//获取门店列表
export const getShopList=(data)=> {
    return axios('post','/Account/platform/getShopList', data);
}
//门店状态修改
export const saveStatus=(data)=> {
    return axios('post','/Account/platform/saveStatus', data);
}
//获取门店详情
export const getShopInfo=(data)=> {
    return axios('post','/Account/platform/getShopInfo', data);
}
//门店信息修改
export const saveShopInfo=(data)=> {
    return axios('post','/Account/platform/saveShopInfo', data);
}
//营业执照图片信息获取
export const recognition=(data)=> {
    return axios('post','/Thirdparty/platform/recognition', data);
}
//获取C端用户信息列表
export const getAccountUserList=(data)=> {
    return axios('post','/Account/platform/getAccountUserList', data);
}
//会员账号状态修改
export const saveAccountUserStatus=(data)=> {
    return axios('post','/Account/platform/saveAccountUserStatus', data);
}
//门店实体店审核列表
export const getAuditShopList=(params)=> {
	  return axios('get','/Account/platform/getAuditShopList', params)
}
//门店实体店审核
export const saveAuditShop=(data)=> {
    return axios('post','/Account/platform/saveAuditShop', data);
}
//订单-创建虚拟订单
export const rechargeAmount=(data)=> {
    return axios('post','/Order/platform/rechargeAmount', data);
}
//服务端插件列表
export const serverTwitterList=(params)=> {
	  return axios('get','/Twiker/platform/serverTwitterList', params)
}
//样板店创建
export const modelCreateShop=(data)=> {
    return axios('post','/Account/platform/modelCreateShop', data);
}
//创业扶持审核列表
export const businessSupportAuditList=(data)=> {
    return axios('post','/Account/platform/businessSupportAuditList', data);
}
//获取创业扶持申请信息
export const getBusinessSupportAudit=(data)=> {
    return axios('post','/Account/platform/getBusinessSupportAudit', data);
}
//创业扶持申请--审核
export const auditBusinessSupportAudit=(data)=> {
    return axios('post','/Account/platform/auditBusinessSupportAudit', data);
}
//设置团队长
export const setTeamLeader=(data)=> {
    return axios('post','/Account/platform/setTeamLeader', data);
}
//设置团队长比例
export const setTeamProfit=(data)=> {
    return axios('post','/Account/platform/setTeamProfit', data);
}
//关系表
export const shareTree=(params)=> {
	  return axios('get','/Account/platform/shareTree', params)
}
//代理赠送门店次数
export const giveShop=(data)=> {
  return axios('post',"/Account/platform/giveShop", data)
}
//代理赠送门店次数
export const channelRenew=(data)=> {
  return axios('post',"/Account/channel/channelRenew ", data)
}



