<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card shadow="never">
                    <el-row class="all_search_list" style="margin-bottom:0">
                        <div class="demo-input-suffix">
                            合伙人名称：
                            <el-input clearable class="inputsWidth" prefix-icon="el-icon-search"
                                v-model="search.shop_name" placeholder="店铺名称/用户账号" @keyup.enter.native="toSearch"
                                size="small" type="text">
                            </el-input>
                        </div>
                        <div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
                            店铺状态：
                            <el-select style="width: 150px;!important" size="small" clearable v-model="search.status"
                                placeholder="请选择">
                                <el-option value="1" label="正常"></el-option>
                                <el-option value="2" label="关闭"></el-option>
                            </el-select>
                        </div>
                        <div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
                            创建时间：
                            <el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
                                range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']" @change="changetimer"></el-date-picker>
                        </div>

                        <div class="demo-input-suffix">
                            <el-button type="primary" size="small" @click="toSearch">查询</el-button>
                            <el-button size="small" @click="toReset">重置</el-button>
                        </div>
                    </el-row>
                </el-card>
                <el-card shadow="never" class="mt10">
                    <div slot="header" class="clearfix">
                        <span>门店数量：{{total}}</span>
                    </div>
                    <el-table :data="tableData" border style="width: 100%" v-loading="loading" size="small"
                        element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.2)" max-height="550px"
                        :header-cell-style="{ background: '#f3f8fe', color: '#606060' }">
                        <el-table-column label="店铺信息" align="center">
                            <template slot-scope="scope">
                                <div  class="sizecolor">{{scope.row.shop_name}}</div>
                                <div v-if="scope.row.account_shop_info" class="sizecolor">{{scope.row.account_shop_info.account}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="开店时间" prop="created_at" align="center"></el-table-column>
                        <el-table-column label="成交金额" prop="orderSum" align="center"></el-table-column>
                        <el-table-column label="订单数" prop="orderCount" align="center"></el-table-column>
                        <el-table-column label="会员数" prop="vipCount" align="center"></el-table-column>
                        <el-table-column label="店铺状态" prop="status" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status == 1">正常</span>
                                <span v-else-if="scope.row.status == 2">禁用</span>
                                <span v-else-if="scope.row.status == 3">审核中</span>
                                <span v-else-if="scope.row.status == 4">待续费</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="toDetail(scope.row)" v-has="'查看'">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]"
                        :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
import { getShopList} from '@/api/plat/shop.js'
export default {
    data() {
        return {
            due_dt: [],
            loading:true,
            search: {
                shop_name: '',
                status: '',
                start: '',
                end: ''
            },
            tableData: [
                {
                    shop_name:'晨晨'
                }
            ],
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
        }

    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            let data=this.search;
            data.page=this.currentPage;
            data.perPage=this.pagesize;
            getShopList(data).then(res=>{
                this.total = res.data.count;
            	 this.tableData = res.data.list;
            	 this.loading=false
            })
        },
        //搜索
        toSearch() {
            this.currentPage = 1;
				this.getList()
        },
        //重置
        toReset() {
            this.currentPage = 1;
            this.search.start = '';
            this.search.end = '';
            this.due_dt = '';
            this.search.shop_name='';
            this.search.status = '';
            this.getList()
        },
        changetimer(val) { //时间
            if (val) {
                this.search.start = val[0] / 1000;
                this.search.end = val[1] / 1000;
            } else {
                this.search.start = '';
                this.search.end = '';
            }
        },
        handleSizeChange(size) {
            this.pagesize = size;
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getList()
        },
        toDetail(row){
            this.$router.push({
					path: '/storeadmin/storelist/storedetail',
					query:{
						shop_id:row.shop_id
					}
				})
        }

    }
}
</script>
<style lang="less" scoped>
    .sizecolor{
        color: #409EFF;
        
    }

</style>