<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card shadow="never">
                    <el-row class="all_search_list" style="margin-bottom:0">
                        <div class="demo-input-suffix">
                            合伙人名称：
                            <el-input clearable class="inputsWidth" prefix-icon="el-icon-search" v-model="search.shop_name"
                                placeholder="请输入合伙人名称" @keyup.enter.native="searchOrder" size="small" type="text">
                            </el-input>
                        </div>
                        <div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
                            合伙人等级：
                            <el-select style="width: 150px;!important" size="small" clearable v-model="search.level"
                                placeholder="请选择">
                                <el-option v-for="(items, index) in leveldata" :key="index" :label="items.level_name"
									:value="items.level">
								</el-option>
                            </el-select>
                        </div>
                        <div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
                            成为合伙人时间：
                            <el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
                                range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']" @change="changetimer"></el-date-picker>
                        </div>

                        <div class="demo-input-suffix">
                            <el-button type="primary" size="small" @click="searchOrder">查询</el-button>
                            <el-button size="small" @click="toReset">重置</el-button>
                        </div>
                    </el-row>
                </el-card>
                <el-card shadow="never" class="mt10">
                    <el-table :data="tableData" border style="width: 100%" v-loading="loading" size="small"
                        element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.2)" max-height="550px"
                        :header-cell-style="{ background: '#f3f8fe', color: '#606060' }">
                        <el-table-column label="所属部门" prop="buyer_type" align="center"></el-table-column>
                        <el-table-column label="合伙人名称" prop="username" align="center"></el-table-column>
                        <el-table-column label="手机号" prop="account" align="center"></el-table-column>
                        <el-table-column label="合伙人等级" prop="level_name" align="center"></el-table-column>
                        <el-table-column label="累计分红" prop="total_money" align="center"></el-table-column>
                        <el-table-column label="已提现分红" prop="withdrawl_total_money" align="center"></el-table-column>
                        <el-table-column label="成为合伙人时间" prop="agent_at" align="center"></el-table-column>
                        <el-table-column label="状态" prop="status" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status == 1">正常</span>
                                <span v-else-if="scope.row.status == 2">禁用</span>
                                <span v-else-if="scope.row.status == 3">审核中</span>
                                <span v-else-if="scope.row.status == 4">待续费</span>
						    </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="toDetail(scope.row)" v-has="'查看'">查看</el-button>
                                <!-- <el-button type="text" size="small">禁用</el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]"
                        :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
	import {accountShopList,accountLevel} from '@/api/plat/partner'
export default {
    data() {
        return {
            leveldata:[],
            loading:true,
            due_dt: [],
            search: {
                shop_name: '',
                start_time: '',
                end_time: '',
                level: ''
            },
            tableData: [],
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
        }
    },
    mounted() { 
        this.getlevel();
        this.getList();
    },
    methods: {
         getlevel(){
            accountLevel({}).then(res=>{
               this.leveldata=res.data
			})
        },
        getList() {
                let data=this.search;
                data.page=this.currentPage;
                data.perPage=this.pagesize;
                accountShopList(data).then(res=>{
                    this.total = res.data.count;
					 this.tableData = res.data.list;
					 this.loading=false
                })
			},
        searchOrder() {
           this.currentPage=1;
			this.getList()
        },
        toReset() {
            this.currentPage=1;
				this.search.shop_name='';
				this.search.start_time='';
                this.search.end_time='';
                this.search.level='';
                 this.due_dt=[];
				this.getList()
        },
        //时间选择
        changetimer(val) { //时间
            if (val) {
                this.search.start_time = val[0] / 1000;
                this.search.end_time = val[1] / 1000;
            } else {
                this.search.start_time = '';
                this.search.end_time = '';
            }
        },
        handleSizeChange(size) {
            this.pagesize = size;
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getList()
        },
        //查看详情
        toDetail(row){
            this.$router.push({
					path: '/partneradmin/partnerlist/partnerdetail',
					query:{
						account_shop_id:row.account_shop_id
					}
				})
        }
    }
}
</script>
<style lang="less" scoped>
</style>