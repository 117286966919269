<template>
	<div>
		<el-upload :action="baseUrl + uploadUrl" list-type="picture-card"
			accept=".jpg,.jpeg,.png,.gif" :on-success="handleSuccess"  :before-upload="handleBefore"
			:file-list="fileLists" :headers="{ token: tokens }" :data="paramData"  :limit='limit'  :disabled="fileLists.length >= limit" >
			<i slot="default" class="el-icon-plus" ></i>
			<div slot="file" slot-scope="{file}">
				<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
				<span class="el-upload-list__item-actions">
					<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
						<i class="el-icon-zoom-in"></i>
					</span>
					<!-- <span class="el-upload-list__item-edit" @click="handlePictureCardEdit(file)">
						<i class="el-icon-edit"></i>
					</span> -->
					<span class="el-upload-list__item-delete" @click="handleRemove(file)">
						<i class="el-icon-delete"></i>
					</span>
				</span>
			</div>
		</el-upload>
		<el-dialog :visible.sync="dialogVisible" append-to-body>
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
		<el-dialog :visible.sync="editView" width="40%" append-to-body>
			<el-input type="textarea" :rows="4" v-model="editForm.url" @input="editPic"></el-input>
		</el-dialog>
	</div>
</template>

<script>
	import {
		platRefreshToken
	} from "@/api/plat/platlogin";
	import {
		shopRefreshToken
	} from "@/api/merchant/login";
	import {
		channelRefreshToken
	} from "@/api/channel/login";
	import md5 from 'js-md5';
	import baseURL from "@/config/baseUrl";
	export default {
		props: {
			limit: {
				type: Number,
				default: 1,
			},
			action: {
				type: String,
				default: '#'
			},
			uploadUrl: {
				type: String,
				default: ''
			},
			fileList: {
				type: Array,
				default: () => {
					return []
				}
			},
			multiples: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				tokens: localStorage.getItem("token"),
				baseUrl: baseURL, //统一地址
				tokens: localStorage.getItem("token"),
				dialogImageUrl: '',
				dialogVisible: false,
				fileLists: this.fileList,
				editForm: {
					url: '',
					uid: null
				},
				editView: false,
				paramData: {}
			}
		},
		mounted() {},
		methods: {
			// 移除图片
			handleRemove(file) {
				for (let i in this.fileLists) {
					if (this.fileLists[i].uid == file.uid) {
						this.fileLists.splice(i, 1)
					}
				}
				this.submitFile()
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleBefore(file) {
				//接口加密
				let dd = this.paramData;
				dd.sign && delete dd.sign;
				dd.timestamp = (new Date().valueOf() / 1000).toFixed(0) * 1;
				let token = this.$store.state.UserToken;
				const data = JSON.parse(JSON.stringify(dd));
				data.dynamic = localStorage.getItem('dynamic');
				const ordered = {};
				delete data["sign"];
				Object.keys(data).sort().forEach(function(k) {
					ordered[k] = data[k];
				});
				let string = '';
				for (const key in ordered) {
					string += `${ordered[key]}&`;
				}

				dd.sign = md5(string);
				this.paramData = dd;
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (
					!(
						file.type === "image/png" ||
						file.type === "image/gif" ||
						file.type === "image/jpg" ||
						file.type === "image/jpeg"
					)
				) {
					this.$message("请上传正确的图片格式");
					return false;
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
					return false;
				}
			},
			handleSuccess(response, file, fileList) {
				if (response.return_code == "VERIFICATION") {
					this.$message.error(response.return_msg);
				} else if (response.return_code == 'TOKEN_IS_INVALID') {
					if (localStorage.getItem('terminal') == 'platform') {
						platRefreshToken({
							refreshToken: localStorage.getItem('refreshToken')
						}).then(res => {
							localStorage.setItem('dynamic', res.data.dynamic);
							localStorage.setItem('refreshToken', res.data.refreshToken);
							localStorage.setItem('head_portrait', res.data.head_portrait);
							localStorage.setItem('shop_name', res.data.shop_name);
							localStorage.setItem('terminal', res.data.terminal);
							store.commit('LOGIN_IN', res.data.token);
							window.location.reload()
						});
					} else if (localStorage.getItem('terminal') == 'shop') {
						shopRefreshToken({
							refreshToken: localStorage.getItem('refreshToken')
						}).then(res => {
							localStorage.setItem('dynamic', res.data.dynamic);
							localStorage.setItem('refreshToken', res.data.refreshToken);
							localStorage.setItem('head_portrait', res.data.head_portrait);
							localStorage.setItem('shop_name', res.data.shop_name);
							localStorage.setItem('terminal', res.data.terminal);
							store.commit('LOGIN_IN', res.data.token);
							window.location.reload()
						});
					} else if (localStorage.getItem('terminal') == 'channel') {
						channelRefreshToken({
							refreshToken: localStorage.getItem('refreshToken')
						}).then(res => {
							localStorage.setItem('dynamic', res.data.dynamic);
							localStorage.setItem('refreshToken', res.data.refreshToken);
							localStorage.setItem('head_portrait', res.data.head_portrait);
							localStorage.setItem('shop_name', res.data.shop_name);
							localStorage.setItem('terminal', res.data.terminal);
							store.commit('LOGIN_IN', res.data.token);
							window.location.reload()
						});
					}
				} else {
					// console.log(response,"3333")
					// console.log(URL.createObjectURL(file.raw),"444")
					// console.log(fileList,"555")
					// console.log(file,"666")
					URL.createObjectURL(file.raw)
					let obj = {
						name: file.name,
						status: "success",
						uid: file.uid,
						url: response.data.img_info.temp_img_url,
					}
					if(this.limit=='5'){
						this.fileLists.push(obj);
					}else{
						this.fileLists=[obj]
					}
					//console.log(this.fileLists,"this.fileLists")
					this.submitFile()
				}
			},
			// 编辑图片弹窗
			handlePictureCardEdit(file) {
				this.editForm.url = file.url
				this.editForm.uid = file.uid
				this.editView = true
			},
			// 编辑图片
			editPic() {
				for (let i in this.fileLists) {
					if (this.fileLists[i].uid == this.editForm.uid) {
						this.fileLists[i].url = this.editForm.url
					}
				}
				this.submitFile()
			},
			// 将图片文件传回给父组件
			submitFile() {
				this.$emit('submitImg', this.fileLists)
			},
		}
	}
</script>

<style scoped>
	.el-icon-plus {
		font-size: 30px !important;
	}

	.el-icon-edit {
		font-size: 18px !important;
	}

	.el-icon-zoom-in {
		font-size: 18px !important;
	}

	.el-icon-delete {
		font-size: 18px !important;
		color: rgb(243, 143, 130);
	}

	.el-input>>>.el-textarea__inner {
		font-size: 18px !important;
	}
	.avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}
	
		.avatar-uploader .el-upload:hover {
			border-color: #409eff;
		}
	
		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 178px;
			height: 178px;
			line-height: 178px;
			text-align: center;
		}
	
		.avatar {
			width: 178px;
			height: 178px;
			display: block;
		}

</style>
