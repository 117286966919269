<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="positionstyle">
					<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm ">
						<div class="main-cont pp20">
							<div class="setTitle">基本信息</div>
							<el-form-item label="广告类型" prop="identifying" v-if="!advertising_id" >
								<el-select v-model="form.identifying" size="small" style="width: 350px!important;">
									<el-option v-for="(item,index) in general_manager_list" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="图片:" prop="filename">
								<div class="img_blos">
									<div v-for="(item, index) in form.filename" :key="index" class="img_blo"
										style="margin-bottom:15px">
										<i class="el-icon-error close_img" @click="delImg(index)"></i>
										<img :src="item" class="item_img" />
									</div>
									<div class="plusImg" @click="uploadImg('img')">
										<i class="el-icon-plus avatar-uploader-icon"></i>
									</div>
								</div>
					
							</el-form-item>
							<el-form-item label="标题:" prop="title">
								<el-input class="inputWidth" size="small" v-model="form.title" maxlength="4"
									placeholder="请输入标题" show-word-limit></el-input>
							</el-form-item>
						</div>
					
					</el-form>
					<div class="sample" @mouseenter="mouseoverCaption" @mouseleave="mouseleaveCaption">示例</div>
					<div style="position: absolute;z-index: 200;top:1.875rem;right: 15%;pointer-events:none;">
						<img src="../../../../../../assets/img/plat/market/activitybusiness.png"
							style=" width: 227px;" v-show="captionshow" />
					</div>
				</div>
				
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet('form')">保存</div>
			<div class="cancel-btn" @click="goBack">取消</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		getAdvertising,
		saveAdvertising,
		getidentifying
	} from '@/api/plat/adverty.js'
	import ChooseImg from "../../../../../common/uploadImgs";
	export default {
		components: {
			ChooseImg,
		},
		data() {
			return {
				form: {
					identifying: '',
					filename: [],
					title: '',

				},
				rules: {
					identifying: [{
						required: true,
						message: '请选择广告类型',
						trigger: 'change'
					}],
					filename: [{
						required: true,
						message: '请上传图片',
						trigger: 'change'
					}],
					title: [{
						required: true,
						message: '请填写标题',
						trigger: 'blur'
					}]
				},
				general_manager_list:[
					{
						label:'邀请普通店长邀请码海报(500*820)',
						value:'general_manager'
					},
					{
						label:'邀请高级店长邀请码海报(500*820)',
						value:'senior_manager'
					}
				],
				isSingle: false, //上传图片是单张还是多张
				uploadFlag: false, //是否展示选择图片弹窗
				imgType: '',
				advertising_id: '',
				captionshow:false,
			}
		},
		mounted() {
			this.advertising_id = this.$route.query.id;
			this.identifying = this.$route.query.identifying;
			if (this.advertising_id) {
				this.getInfo()
			}
		},
		methods: {
			getInfo(){
				let data={
					identifying:this.identifying
				}
				data=this.tools.filterParams(data);
				getAdvertising(data).then(res=>{
					this.form=res.data[0]
					this.form.filename=this.form.filename.split(',');
				})
			},
			
			mouseoverCaption() {
				this.captionshow = true;
			},
			mouseleaveCaption() {
				this.captionshow = false;
			},
			// 上传商品图
			uploadImg(type) {
				this.imgType = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				if (this.imgType == 'img') {
					val.forEach((res) => {
						this.form.filename = this.form.filename.concat([res.resource_name]);
					});
				}
			},
			//删除图片
			delImg(index) {
				this.form.filename.splice(index, 1);
			},
			//保存并添加商品
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data=JSON.parse(JSON.stringify(this.form));
						let main_pic = [];
						data.filename.forEach(item=>{
							main_pic.push(this.tools.filterImgPre(item));
						});
						data.filename=main_pic.join(',');
						if(this.advertising_id){
							data.advertising_id=this.advertising_id
						}
						
						
						saveAdvertising(data).then(res=>{
							this.$router.push({
								path: '/dobusiness/businessmanage/businesslist',
							})
						})
				
					} else {
						return false;
					}
				});
			},
			//取消新建
			goBack() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.img_blosmark {
		display: flex;
		flex-wrap: wrap;
		width: 700px;
		min-height: 150px;

		.img_blomark {
			position: relative;
			height: 150px;

			.item_imgmark {
				width: 100px;
				height: 100%;
				margin-right: 30px;
			}

			.close_imgmark {
				font-size: 20px;
				right: 6px;
				top: -10px;
				position: absolute;
				cursor: pointer;
			}
		}

		.add_imgmark {
			width: 150px;
			height: 150px;
			cursor: pointer;
		}
	}

	.plusImgmark {
		width: 150px;
		border: 1.3px dashed #CCC;
		background-color: #F7F8FA;
		border-radius: 4px 4px;
		text-align: center;
		height: 150px;
		line-height: 150px;
		cursor: pointer
	}

	.plusImgmark .el-icon-plus {
		width: 150px;
		height: 150px;
		line-height: 150px;
		font-size: 24px;
		color: #969799
	}

	.el_color {
		display: flex;
		align-items: center;

		.color_con {
			height: 32px;
			line-height: 32px;
			padding: 0 10px;
			box-sizing: border-box;
			width: 210px;
			border-radius: 4px;
			margin: 0 12px;
			border: 1px solid rgba(0, 0, 0, 0.15);
			background-color: #fff;
		}
	}
	.positionstyle{
		position: relative;
	}
	.sample{
		position: absolute;
		top:30px;
		right: 30%;
		color: #2589FF;
	}
	.sampleImg{
		text-align: right;
		width: 80%;
		height: auto;
	}
</style>
