<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card shadow="never">
                    <div class="user_info">
                        <div class="block">
                            <el-avatar :size="50" :src="circleUrl"></el-avatar>
                        </div>
                        <div class="users">
                            <p>{{username}}</p>
                            <p>手机号：{{phone}}</p>

                        </div>
                    </div>
                </el-card>
                <el-card class="box-card mt10" shadow="never">
                    <div slot="header" class="clearfix">
                        <span></span>
                        <div style="float: right; padding: 3px 0;margin-right: 10%;">
                            <el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
                                range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']" @change="changetimer"></el-date-picker>
                            <el-button style="margin-left: 10px;" type="primary" size="small" @click="toSearch">搜索
                            </el-button>
                            <el-button size="small" @click="toReset">重置</el-button>
                        </div>
                    </div>
                    <div>
                        <el-table :data="tableData" border style="width: 100%" max-height="550px"
                            :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"
                            element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(0, 0, 0, 0.2)">
                            <el-table-column prop="username" label="粉丝名称" align="center"></el-table-column>
                            <el-table-column prop="level_name" label="等级" align="center"></el-table-column>
                            <el-table-column prop="amount" label="支付金额(元)" align="center"></el-table-column>
                            <el-table-column prop="commission_money" label="获得奖励" align="center"></el-table-column>
                            <el-table-column prop="comm_order_number" label="备注信息" show-overflow-tooltip align="center"
                                to></el-table-column>
                            <el-table-column prop="created_at" label="成为合伙人时间" align="center"></el-table-column>
                        </el-table>
                        <!-- 分页 -->
                        <el-pagination class="pagecurrent" hide-on-single-page background
                            @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
                            layout="total, sizes, prev, pager, next, jumper" :total="total">
                        </el-pagination>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
import {
    accountShopDetail
} from '@/api/plat/finance'
export default {
    data() {
        return {
            loading: true,
            circleUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            username: '',
            phone: '',
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
            tableData: [],
            due_dt: [],
            start_time: '',
            end_time: '',
            account_shop_id: this.$route.query.account_shop_id

        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        //获取列表
        getList() {
            let params = {
                account_shop_id: this.account_shop_id,
                start_time: this.start_time,
                end_time: this.end_time,
                page: this.currentPage,
                perPage: this.pagesize
            }
            accountShopDetail(params).then(res => {
                this.circleUrl = res.data.head_portrait;
                this.username = res.data.username;
                this.phone = res.data.phone;
                this.loading = false;
                this.total = res.data.count;
                this.tableData = res.data.list
            })
        },
        //时间选择
        changetimer(val) { //时间
            if (val) {
                this.start_time = val[0] / 1000;
                this.end_time = val[1] / 1000;
            } else {
                this.start_time = '';
                this.end_time = '';
            }
        },
        //搜索
        toSearch() {
            this.currentPage = 1;
            //调接口
            this.getList()
        },
        toReset() {
            this.currentPage = 1;
            this.start_time = '';
            this.end_time = '';
            this.due_dt = [];
            this.getList()
        },
        handleSizeChange(size) {
            this.pagesize = size;
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getList()
        },
    }
}
</script>
<style lang="less" scoped>
.user_info {
    display: flex;
    justify-content: left;
    align-items: center;

    .users {
        margin-left: 10px;
    }
}
</style>