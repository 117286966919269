<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont">
					<el-input placeholder="请输入" size="small" class="inputWidth" v-model="search.name"  @keyup.enter.native="toSearch" clearable>
						<template slot="append" @click="toSearch">搜索</template>
					</el-input>
					<el-select size="small" v-model="search.position_type" style="margin-left: 50px;" placeholder="全部">
						<el-option label="全部" value="0"></el-option>
						<el-option label="单商品" value="1"></el-option>
						<el-option label="专场活动" value="2"></el-option>
					</el-select>
				</div>
				<div class="main-cont mt10">
					<el-table :data="customerData" min-height="200px" max-height="600px" style="width: 100%" border :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="selection" width="50" align="center"></el-table-column>
						<el-table-column min-width="171" label="名称" align="left">
							<template slot-scope="scope">
								<div class="el_names">
									<img :src="scope.row.oneImg" class="el_img" />
									<div>{{ scope.row.goods_name }}</div>
								</div>
							</template>
						</el-table-column>
						   <el-table-column prop="weight" label="首页排序" align="center">
						</el-table-column>
						   <el-table-column prop="position_type" label="类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.position_type == 3">单商品</span>
								<span v-else>专场</span>
							</template>
						</el-table-column>
						   <el-table-column prop="regTime" label="手动排序" align="center" min-width="158">
							<template slot-scope="scope">
								<el-button plain type="primary" size="mini" @click="moveTo(scope, 1)"
									v-if="scope.$index != 0">上移</el-button>
								<el-button plain type="warning" size="mini" @click="moveTo(scope, 2)"
									v-if="scope.$index != customerData.length-1">下移</el-button>
							</template>
						</el-table-column>
						 <el-table-column label="浏览量" align="center" prop="glance_amount">
							 </el-table-column>
						 <el-table-column label="操作" align="center">
							<template slot-scope="scope">
								        <el-button @click="toDels(scope)" type="text" size="small"
									style="color:#FF0000">移除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
						<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getComposingPpositionList,
		ComposingPpositionMove,
		delPosition,
	} from "@/api/specialsort";
	export default {
		data() {
			return {
				loading:true,
				customerData: [],
				search: {
					position_type: '0',
					name: ''
				},
				total: 0,
				currentPage: 1, //初始页
				pagesize: 20, //每页的数据
			};
		},
		mounted() {
			this.getList();
		},
		methods: {
			getList() {
				let search = this.search;
				search.page = this.currentPage;
				search.perPage = this.pagesize;
				search = this.tools.filterParams(search);
				getComposingPpositionList(search)
					.then((res) => {
						this.loading=false;
						res.data.list.forEach((item) => {
							item.oneImg = item.goods_main_pic.split(",")[0];
						});
						this.total = res.data.count;
						this.customerData = res.data.list;
					})
					
			},
			//上移或下移
			moveTo(row, type) {
				let data = [...this.customerData];
				let index = row.$index;
				let temp = data[index];
				let tempIndex = index;
				if (type == 1) {
					tempIndex--;
				} else {
					tempIndex++;
				}
				data[index] = data[tempIndex];
				data[tempIndex] = temp;
				data[index].weight = data[index].weight - 1;
				data[tempIndex].weight = temp.weight - 0 + 1;
				// console.log(data[index],data[tempIndex],454545)
				let search = {
					change_id: data[index].composing_position_id,
					change_weight_id: data[index].weight,
					substitute_id: data[tempIndex].composing_position_id,
					substitute_weight_id: data[tempIndex].weight,
				};
				//掉接口
				ComposingPpositionMove(search)
					.then((res) => {
						// console.log("move", res);
						this.customerData = [...data];
					})
					
			},

			// 删除数据
			toDels(row) {
				// 掉接口
				delPosition({
						composing_position_id: row.row.composing_position_id
					})
					.then((res) => {
						this.customerData.splice(row.$index, 1);
					})
					
			},
			// 去搜索
			toSearch() {
				this.getList();
			},
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList();
			},
		},
	};
</script>

<style lang="scss" scoped="scoped">
	.el_names {
		display: flex;
		align-items: center;
		color: #333333;

		.el_img {
			width: 70px;
			border-radius: 4px;
			height: 70px;
			margin-right: 10px;
		}
	}

	.el_move {
		cursor: pointer;
	}

	//@import "../../../../../assets/css/merchant/specialsorts.scss";
</style>
