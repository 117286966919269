<template>
	<div>
		<div class="wrapper-main pp20">
			<div class="setTitle">特色玩法</div>
			<div class="itemList">
				<ul class="items">
					<!-- @click="toSecond(index)" -->
					<li v-for="(item,index) in marketList"  :key="index">
						<div class="marketflex">
							<img :src='item.cover_image' />
							<div class="intro">
								<p class="title">{{item.title}}</p>
								<p style="margin-top: 10px;">{{item.description}}</p>
							</div>
						</div>
					</li>
			
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import {getPluginList} from "@/api/plat/market.js"
	export default{
		data(){
			return{
				marketList: [],
			}
		},
		mounted() {
			this.getList()
		},
		methods:{
			getList(){
				getPluginList({}).then(res=>{
					this.marketList=res.data
					
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.itemList {
		width: 100%;
	}
	
	.items {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	
	.items li {
		list-style: none;
		padding: 1% 1%;
		border-radius: 4px;
		min-height: 36px;
		background: #FFFFFF;
		border: 1px solid #DDDDDD;
		width: 16%;
		margin: 0 1%;
		margin-bottom: 30px;
	}
	.marketflex {
		display: flex;
		align-items: center;
		flex-shrink: 0;
	
		img {
			width: 60px;
			height: 60px;
			vertical-align: middle;
		}
	
		.intro {
			font-size: 14px;
			color: #999;
			margin-left: 15px;
	
			.title {
				font-size: 16px;
				color: #333;
	
	
			}
		}
	}
	
</style>
