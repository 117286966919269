import axios from '@/config/httpConfig'
import qs from  'qs'
//秒杀会场列表
export const secondskillActivityList=(params)=> {
	  return axios('get','/Secondskill/platform/secondskillActivityList', params)
}
//分配行业
export const distributionIndustry=(data)=> {
    return axios('post','/Secondskill/platform/distributionIndustry', data);
}
//更新分配行业
export const updateIndustry=(data)=> {
    return axios('post','/Secondskill/platform/updateIndustry', data);
}
//添加会场
export const addSecondskillActivity=(data)=> {
    return axios('post','/Secondskill/platform/addSecondskillActivity', data);
}
//供货商品库列表
// export const platform_goods_list=(data)=> {
//     return axios('post','/Secondskill/platform/getGoodsOriginalList', data);
// }
export const platform_goods_list=(params)=> {
	  return axios('get','/Secondskill/platform/getGoodsOriginalList', params)
}
//获取商品Sku列表
export const getSkuInfo=(params)=> {
	  return axios('get','/Secondskill/platform/getSkuInfo', params)
}
//删除会场
export const secondskillActivityDel=(params)=> {
	  return axios('get','/Secondskill/platform/secondskillActivityDel', params)
}
//获取会场信息
export const updateSecondskillActivity=(data)=> {
	  return axios('post','/Secondskill/platform/updateSecondskillActivity', data)
}
//停止会场
export const stopSecondskillActivity=(params)=> {
	  return axios('get','/Secondskill/platform/stopSecondskillActivity', params)
}
//获取会场信息
export const getSecondskillActivityDetail=(params)=> {
	  return axios('get','/Secondskill/platform/getSecondskillActivityDetail', params)
}
//参与活动店铺列表
export const getShopJoinActivityDetail=(params)=> {
	  return axios('get','/Secondskill/platform/getShopJoinActivityDetail', params)
}
//批量删除会场
export const secondskillActivityBatchDel=(params)=> {
	  return axios('get','/Secondskill/platform/secondskillActivityBatchDel', params)
}
