<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <div class="main-cont bgwt" style="padding: 0!important;">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane label="全部" name="-1"></el-tab-pane>
                        <el-tab-pane label="仓库中" name="3"></el-tab-pane>
                        <el-tab-pane label="上架中" name="1"></el-tab-pane>
                    </el-tabs>
                </div>
                <el-card shadow="never" class="mt10">
                    <el-row class="all_search_list"
                        style="margin-bottom: 0; border-bottom: 1px solid #eee;padding-bottom: 20px;">
                        <div class="demo-input-suffix">
                            商品名称：
                            <el-input clearable class="inputsWidth" prefix-icon="el-icon-search"
                                v-model="search.keyword" placeholder="请输入商品名称" @keyup.enter.native="toSearch"
                                size="small" type="text">
                            </el-input>
                        </div>
                        <div class="demo-input-suffix flex">
                            <el-select v-model="search.one_cat_id" filterable clearable size="small"
                                style="width: 11.25rem;" placeholder="请选择一级分类" @change="getCats(1)">
                                <el-option v-for="item in oneLevel" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                            <el-select v-model="search.two_cat_id" filterable clearable size="small"
                                style="margin-left:10px;width: 11.25rem;" placeholder="请选择二级分类"
                                v-if="twoLevel && twoLevel.length > 0">
                                <el-option v-for="item in twoLevel" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="demo-input-suffix">
                            <el-button type="primary" size="small" @click="toSearch">查询</el-button>
                            <el-button size="small" @click="toReset">重置</el-button>
                        </div>
                    </el-row>
                    <el-row class="all_search_list" style="margin-bottom: 0;margin-top:20px">
                        <div class="demo-input-suffix flex">
                            <el-button size="small" type="primary" @click="addGoods">新增商品</el-button>
                            <el-button size="small" @click="batchOpera('del')">批量删除</el-button>
                            <el-button size="small" @click="batchOpera('down')" v-if="activeName==1">批量下架</el-button>
                            <el-button size="small" @click="batchOpera('up')" v-if="activeName==3">批量上架</el-button>
                        </div>
                    </el-row>
                </el-card>
                <div class="main-cont mt10">
                    <el-table :data="tableData" style="width: 100%" min-height="250" v-loading="loading"
                        element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.2)" ref="multipleTable" max-height="550px" border
                        :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
                        @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column label="商品名称" width="380" align="left">
                            <template slot-scope="scope">
                                <div class="el_name">
                                    <div style="width:70px;height:70px;">
                                        <el-image :src="scope.row.goods_main_pic.split(',')[0]" class="el_img"
                                            :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]" />
                                    </div>
                                    <div style="width: 78%;margin-left: 10px;">
                                        <p>{{ scope.row.goods_name }}</p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sku_unified_price" label="价格" align="center">
                        </el-table-column>
                        <el-table-column prop="sale" label="商品类型" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.type==1">实物商品素材</span>
                                <span v-else-if="scope.row.type==2">虚拟商品素材</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" label="创建时间" align="center">
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="220">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="singleOperate(scope.row,'edit')">编辑
                                </el-button>
                                <el-button type="text" size="small" @click="singleOperate(scope.row,'del')">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <div class="pagecurrent">
                        <div class="pagination-container">
                            <el-pagination background @size-change="handleSizeChange" hide-on-single-page
                                @current-change="handleCurrentChange" :current-page="currentPage"
                                :page-sizes="[10,20,30,50]" :page-size="pagesize"
                                layout="total, sizes, prev, pager, next, jumper" :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
		cate_list, //商品分类
		goods_list, //商品列表
		goods_putaway, //商品上下架
		platform_goods_delete, //批量删除,

} from '@/api/plat/goods';
export default {
    data() {
        return {
            activeName: '-1',
            search: {
                keyword: '',
                one_cat_id: '',
                two_cat_id: '',
                is_publish: '',
            },
            oneLevel: [],
            twoLevel: [],
            cateId: "", //判断是否需要清空二级分类id,
            tableData: [
                {
                    goods_main_pic: '',
                    goods_name: '商品名称'
                }
            ],
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
            loading: true,
            multipleSelection: [],
            goods_unified_id: ''
        }
    },
    mounted() {
        this.getCats() //商品分类
        this.getList()
    },
    methods: {
        getList() {
            let data = {
                keyword: this.search.keysward,
                is_publish: this.activeName == -1 ? '' : this.search.is_publish,
                keyword: this.search.keyword,
                is_source: '1',
                cate_id: this.search.two_cat_id ? this.search.two_cat_id : this.search.one_cat_id,
                page: this.currentPage,
                perPage: this.pagesize
            }
            data = this.tools.filterParams(data);
            goods_list(data).then(res => {
                this.loading = false;
                this.tableData = res.data.list;
                this.total = res.data.count;
            })

        },
        //搜索
        toSearch() {
            this.currentPage = 1;
            this.getList()
        },
        //重置
        toReset() {
            this.search.two_cat_id = '';
            this.search.one_cat_id = '';
            this.search.keyword = '';
            this.currentPage = 1;
            this.getList()
        },
        //分页
        handleSizeChange(size) {
            this.pagesize = size;
            // 掉接口
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            // 掉接口
            this.getList()
        },
        handleClick(tab) {
            this.search.two_cat_id = '';
            this.search.one_cat_id = '';
            this.search.keysward = '';
            this.currentPage = 1;
            if (tab.name) {
                this.search.is_publish = tab.name
            } else {
                this.search.is_publish = ''
            }

            this.getList()
        },
        //表格内checkbox触发的全选按钮状态变化
        handleSelectionChange(val) {
            this.multipleSelection = val;
            let ids = []
            val.forEach(mul => {
                ids.push({
                    original_goods_unified_id: mul.original_goods_unified_id
                })
            })
            this.goods_unified_id = ids;
        },
        //批量操作
        batchOpera(type) {
            if (this.multipleSelection.length == 0) {
                this.$message.info("请选择要操作的数据！");
                return;
            }
            //批量删除
            if (type == 'del') {
                this.tools.confirm("确定要批量删除吗？").then(res => {
                    if (res) {
                        //调取批量删除接口
                        let data = {
                            original_goods_unified_id: JSON.stringify(this.goods_unified_id)
                        }
                        platform_goods_delete(data).then(res => {
                            this.$message.success("批量删除成功！");
                            this.getList();
                        })
                    }
                })
            }
            if (type == 'down') {
					this.tools.confirm("确定要下架？").then(res => {
						if (res) {
							//调接口
							let data = {
								original_goods_unified_id: JSON.stringify(this.goods_unified_id),
								is_publish: '0'
							}
							goods_putaway(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.getList();
								}
							})
						}
					})
				}
				if (type == 'up') {
					this.tools.confirm("确定要上架？").then(res => {
						if (res) {
							//调接口
							let data = {
								original_goods_unified_id: JSON.stringify(this.goods_unified_id),
								is_publish: '1'
							}
							goods_putaway(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.getList();
								}
							})
						}
					})
				}
        },
        //单个操作
        singleOperate(row, type) {
            if (type == 'edit') {
                this.$router.push({
                    path: '/goodsadmin/materialgoods/materialdetail',
                    query: {
                        id: row.original_goods_unified_id,
                    }
                })

            }
            if (type == 'del') {
					this.tools.confirm("确定要删除吗？").then(res => {
						if (res) {
							//调取批量删除接口
							let data = {
								original_goods_unified_id: JSON.stringify([{
									original_goods_unified_id: row.original_goods_unified_id
								}]),
							}
							platform_goods_delete(data).then(() => {
								this.$message.success("删除成功！");
								this.getList();
							})
						}
					})
				}
        },
        //新增
        addGoods() {
            this.$router.push({
                path: '/goodsadmin/materialgoods/materialdetail',

            })

        },
        //获取分类
        getCats(type) {
            if (type == 1) {
                this.search.cate_id = this.search.one_cat_id;
                //清空二级分类
                this.search.two_cat_id && (this.search.two_cat_id = "");
            }
            cate_list({
                parent_id: this.search.cate_id ? this.search.cate_id : '0'
            })
                .then((res) => {
                    // console.log(新增商品);
                    if (type == 1) {
                        this.twoLevel = res.data;
                    } else {
                        this.oneLevel = res.data;
                    }
                })

        },
    }
}
</script>
<style lang="less" scoped>
::v-deep.el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0;
}

.el_name {
    display: -webkit-box;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .el_img {
        width: 70px;
        height: 70px;
        margin-right: 6px;
        // img{
        // 	width: 100%;
        // 	height: 100%;
        // }
    }

    .largeImg {

        width: 400px;
        height: 400px;
        display: none;
    }

    &:hover .largeImg {
        position: absolute;
        left: 0;
        top: -50px;
        z-index: 5;
        display: block;
    }
}
</style>