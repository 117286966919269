<template>
	<div class="urls">
		<el-dialog title="链接页面" :visible.sync="showPages" width="700px" class="url_blos">
			<el-tabs v-model="urlName" @tab-click="handleClick">
				<el-tab-pane label="基础页面" name="urlone" v-if="type == 1">
					<el-table :data="curUrls" height="300" min-height="200" style="width: 100%" border>
						 <el-table-column label="页面名称" align="center" prop="title">
							 </el-table-column>
						 <el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.use == 'Y'" class="el_added" @click="toChoose(scope, 1)">
									已选
								</div>
								<div v-else class="el_state" @click="toChoose(scope, 2)">
									选择链接
								</div>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="专场活动" name="urltwo">
					<el-table :data="curUrls" height="300" min-height="200" style="width: 100%" border>
						 <el-table-column label="页面名称" align="center" prop="title">
							 </el-table-column>
						 <el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.use == 'Y'" class="el_added" @click="toChoose(scope, 1)">
									已选
								</div>
								<div v-else class="el_state" @click="toChoose(scope, 2)">
									选择链接
								</div>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="营销页面" name="urlthree" v-if="type == 1 || type == 2">
					<el-table :data="curUrls" height="300" min-height="200" style="width: 100%" border>
						 <el-table-column label="页面名称" align="center" prop="title">
							 </el-table-column>
						 <el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.use == 'Y'" class="el_added" @click="toChoose(scope, 1)">
									已选
								</div>
								<div v-else class="el_state" @click="toChoose(scope, 2)">
									选择链接
								</div>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="商品详情" name="urlfour" v-if="type == 2">
					<div class="el_search">
						<el-cascader :options="oneLevel" @change="catChange" size="small" clearable></el-cascader>
						<div class="el_box">
							<label>
								<el-checkbox v-model="goodsSearch.is_publish"></el-checkbox>只显示上架中
							</label>
						</div>
						<el-input placeholder="请输入商品名称" size="small" v-model="goodsSearch.keyword" class="input-with-select">
							<el-button slot="append" icon="el-icon-search" @click="searchGoods">搜索</el-button>
						</el-input>
					</div>
					<el-table :data="curUrls" height="300" min-height="200" style="width: 100%" border>
						<el-table-column label="商品名称" align="center" prop="goods_name">
							 </el-table-column>
						<el-table-column label="销售价格" align="center" prop="sku_sale_price">
							 </el-table-column>
						<el-table-column label="商品状态" align="center">
							<template slot-scope="scope">
								<label v-if="scope.row.is_publish == 1">已上架</label>
								<label v-else>已下架</label>
							</template>
							 
						</el-table-column>
						 <el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.use == 'Y'" class="el_added" @click="toChoose(scope, 1)">
									已选
								</div>
								<div v-else class="el_state" @click="toChoose(scope, 2)">
									选择链接
								</div>
							</template>
							 
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
			<div class="pagination-container cus_page">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[20, 40, 60, 80]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="toSubmit" size="small">确 定</el-button>
				<el-button @click="toCancel" size="small">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	let nowChose = "";
	let nowChoseId = "";
	let nowReplaceChose = "";
	let parameter = "";
	let newType = "";
	import {
		getWebpageStoreBinding,
		delWebpageStoreBinding,
		setWebpageStoreBinding,
		getPageLink,
		publish,
	} from "@/api/pagenav";
	import {
		getAllCate
	} from "@/api/goodsCat";
	import {
		goods_list
	} from "@/api/goodsList";
	import {
		getspecialActivitiesList
	} from "@/api/pageset";

	export default {
		props: {
			type: Number,
			nowChose: String,
			nowReplaceChose: String,
			curPos: Number,
			footerIcon: Array,
			parameter: String,
		},
		data() {
			return {
				showPages: true,
				goodsSearch: {
					keyword: "",
					is_publish: "1",
					cate_id: "",
				},
				oneLevel: [], //商品分类
				total: 0,
				currentPage: 1, //初始页
				pagesize: 20, //每页的数据
				chooseIndex: -1, //选择链接
				curUrls: [],//页面链接
				allGoods: [], //商品列表
				isUrl: false, //是否展示url选择弹出层
				urlName: "urlone",
				urlNames: "基础页面",
				footerIconCopy: [],
			};
		},
		watch: {
			urlName(val) {
				if (val == "urlone") {
					this.urlNames = "基础页面";
				} else if (val == "urltwo") {
					this.urlNames = "功能页面";
				} else if (val == "urlthree") {
					this.urlNames = "营销页面";
				} else {
					this.urlNames = "商品详情";
				}
			},
		},
		computed: {
			addCheck() {
				let set = new Set();
				let footerIcon = this.footerIcon;
				footerIcon.forEach((item) => {
					item.urlName && set.add(item.urlName);
				});
				//console.log(set,"44444")
				return set;
			},
			removeCheck() {
				let set = new Set();
				let footerIcon = this.footerIcon;
				let footerIconCopy = this.footerIconCopy;
				footerIconCopy.forEach((item, index) => {
					if (item.title != footerIcon[index].title) {
						item.title && set.add(item.title);
					}
				});
				return set;
			},
		},
		created() {
			nowChose = this.nowChose;
			nowReplaceChose = this.nowReplaceChose;
			parameter = this.parameter || "";
			if (this.type == 3 || this.type == 2) {
				this.urlName = "urltwo";
			} else {
				this.urlName = "urlone";
			}
			// 获取连接列表
			this.getLinks();
			// 获取分类
			this.getOneCats();
		},
		methods: {
			//切换弹出tab
			async handleClick(tab, event) {
				this.currentPage = 1;
				await this.getLinks();
				this.checkAdd();
				this.checkRemov1();
			},
			//获取链接列表
			getLinks() {
				let type;
				if (this.urlName == "urlone") {
					type = this.type == 3 ? 2 : 1;
				} else if (this.urlName == "urltwo") {
					type = 2;
				} else if (this.urlName == "urlthree") {
					type = 3;
				} else {
					type = 4;
				}
				let search = {
					type: type,
					page: this.currentPage,
					perPage: this.pagesize,
					location: this.type == 1 ? 1 : 2,//位置 1导航 2icon
				};
				getPageLink(this.tools.filterParams(search))
					.then((res) => {
						this.curUrls = res.data.list;
						this.total = res.data.count;
						if (this.type == 3) {
							//营销列表
							this.getSpecialList();
							this.curUrls[0].title = "";
						} else {
							if (type == 2 && res.data.count > 0) {
								//获取专场列表
								this.getSpecialList();
								this.curUrls[0].title = "";
							}
						}
			
						if (type == 4 && res.data.count > 0) {
							//获取商品
							this.getGoods();
						}
					})
				
			},
			//获取专场列表
			getSpecialList() {
				getspecialActivitiesList()
					.then((res) => {
						res.data.forEach((item) => {
							item.title = item.special_name;
							item.webpage_link_id = this.curUrls[0].webpage_link_id;
							item.webpage_link_type = this.curUrls[0].type;
						});
			
						this.curUrls = res.data;
						this.total = res.data.count;
					})
				
			},
			//获取商品弹窗列表
			getGoods() {
				let search = this.goodsSearch;
				search.page = this.currentPage;
				search.perPage = this.pagesize;
				search.is_publish = search.is_publish == true ? 1 : search.is_publish;
				//过滤空数据
				search = this.tools.filterParams(search);
				goods_list(search).then((res) => {
					let data = res.data && res.data.list;
					data.forEach((item) => {
						item.title = item.goods_name;
						item.webpage_link_id = this.curUrls[0].webpage_link_id;
						item.webpage_link_type = this.curUrls[0].type;
					});
					this.allGoods = data;
					this.curUrls = data;
					this.total = res.data.count;
				});
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				//根据类型判断
				if (this.urlName == "urlone") {
					this.getLinks();
				} else if (this.urlName == "urltwo") {
					this.getSpecialList();
				} else if (this.urlName == "urlthree") {
					this.getLinks();
				} else if (this.urlName == "urlfour") {
					this.getGoods();
				}
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				//根据类型判断
				if (this.urlName == "urlone") {
					this.getLinks();
				} else if (this.urlName == "urltwo") {
					this.getSpecialList();
				} else if (this.urlName == "urlthree") {
					this.getLinks();
				} else if (this.urlName == "urlfour") {
					this.getGoods();
				}
			},
			//分类切换
			catChange(ev) {
				this.goodsSearch.cate_id = ev[ev.length - 1];
			},
			//仅显示上架的商品
			onlyUpGoods() {
				//过滤未上架的商品
				let goods = [...this.allGoods];
				let newGoods = goods.filter((res) => {
					return res.is_publish == 1;
				});
				this.allGoods = [...newGoods];
			},
			// 筛选商品
			searchGoods() {
				//掉接口
				this.getGoods();
			},
			//导航列表
			getFootList() {
				getWebpageStoreBinding({
						versions: "dev"
					})
					.then((res) => {
						this.footerIconCopy = res.data;
					})
				
			},
			// 获取一级分类列表
			getOneCats() {
				getAllCate({
					parent_id: 0
				}).then((res) => {
					res.data.list.forEach((item) => {
						item.label = item.name;
						item.value = item.id;
						if (item.children.length > 0) {
							item.children.forEach((iItem) => {
								iItem.label = iItem.name;
								iItem.value = iItem.id;
							});
						} else {
							delete item.children;
						}
					});
					this.oneLevel = res.data.list;
				});
			},
			// 点击取消
			toCancel() {
				this.$emit("closePage", false);
			},
			// 点击提交
			toSubmit() {
				let icons = [...this.footerIcon];
				icons[this.curPos].title = nowChose;
				if (this.type == 3) {
					icons[this.curPos].special_activities_id = nowChoseId;
				} else {
					icons[this.curPos].webpage_link_id = nowChoseId;
				}
				icons[this.curPos].webpage_link_type = newType;

				icons[this.curPos].parameter = parameter || "";
				this.$emit("okPage", icons);
			},
			
			//点击选择
			toChoose(row, type) {
				if (type == 1) {
					
				}else {//未选
					if (nowChose) {
						this.checkRemove();
					}
					let index = row.$index;
					let urls = [...this.curUrls];
					nowChose = urls[index].title;
					if (this.type == 3) {
						nowChoseId = urls[index].special_activities_id;
					} else {
						nowChoseId = urls[index].webpage_link_id;
					}
					newType = urls[index].type || urls[index].webpage_link_type;
					if (this.urlName == "urltwo") {
						//需要传递专场id
						parameter = urls[index].special_activities_id;
					} else if (this.urlName == "urlfour") {
						//需要传递商品id
						parameter = urls[index].goods_unified_id;
					}
					urls[index].use = urls[index].use == "Y" ? "N" : "Y";
					this.curUrls = [...urls];
					this.chooseIndex = index;
					
				}
			},
			checkAdd() {
				let curUrls = [...this.curUrls];
				curUrls.forEach((item, index) => {
					if (this.addCheck.has(item.url) || nowChose == item.url) {
						item.use = "Y";
					}
				});
				this.curUrls = curUrls;
			},
			checkRemove() {
				let curUrls = [...this.curUrls];
				curUrls.forEach((item, index) => {
					if (
						this.removeCheck.has(item.title) ||
						(nowReplaceChose != nowChose && item.title == nowReplaceChose) ||
						nowChose == item.title
					) {
						item.use = "N";
					}
				});
				this.curUrls = curUrls;
			},
			checkRemov1() {
				let curUrls = [...this.curUrls];
				curUrls.forEach((item, index) => {
					if (
						this.footerIcon[this.curPos].title == item.title && nowChose !== item.title
					) {
						item.use = "N";
					}
				});
				this.curUrls = curUrls;
			},
		},
	};
</script>
<style lang="scss" scoped>
	.url_blos::v-deep.el-dialog__body {
		padding: 0 20px;
	}

	.urls::v-deep.el-dialog {
		border-radius: 4px;
	}

	.el_box::v-deep.el-checkbox {
		margin: 0 10px 0 0;
	}

	.el_search::v-deep.el-input-group {
		width: 240px;
	}

	.el_search::v-deep.el-button {
		width: 50px;
		padding: 0;
		font-size: 12px;
		text-align: center;
	}

	.el_search::v-deep.el-button [class*="el-icon-"]+span {
		margin: 0;
	}

	.urls {
		.url_blos {
			.el_added {
				color: #1890ff;
				cursor: pointer;
			}

			.el_state {
				cursor: pointer;
			}

			.cus_page {
				margin-top: 12px;
				text-align: right;
				margin-bottom: 20px;
			}

			.el_search {
				margin-bottom: 20px;

				.el_select {
					width: 150px;
					margin-right: 10px;
				}

				display: flex;
				justify-content: space-between;
				align-items: center;

				.el_box {
					color: #666666;
					font-size: 12px;
				}
			}
		}
	}
</style>
