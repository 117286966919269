<template>
    <div>
        <div class="wrapper-cont">
            <el-card shadow="never">
                    <el-row class="all_search_list" style="margin-bottom:0">
                        <div class="demo-input-suffix">
                            营销活动名称：
                            <el-input clearable class="inputsWidth" prefix-icon="el-icon-search"
                                v-model="search.active_name" placeholder="店铺名称/用户账号" @keyup.enter.native="searchOrder"
                                size="small" type="text">
                            </el-input>
                        </div>
                        <div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
                            状态：
                            <el-select style="width: 150px;!important" size="small" clearable v-model="search.status"
                                placeholder="请选择">
                                <el-option value="1">正常</el-option>
                                <el-option value="2">关闭</el-option>
                            </el-select>
                        </div>
                        <div class="demo-input-suffix">
                            <el-button type="primary" size="small" @click="searchOrder">查询</el-button>
                            <el-button size="small" @click="toReset">重置</el-button>
                        </div>
                    </el-row>
                </el-card>
                <el-card shadow="never" class="mt10">
                    <el-table :data="tableData" border style="width: 100%" v-loading="loading" size="small"
                        element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.2)" max-height="550px"
                        :header-cell-style="{ background: '#f3f8fe', color: '#606060' }">
                        <el-table-column label="营销活动名称" align="center">
                            <template slot-scope="scope">
                               {{scope.row.shop_name}}
                            </template>
                        </el-table-column>
                        <el-table-column label="到期时间" prop="username" align="center"></el-table-column>
                        <el-table-column label="店铺状态" prop="status" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status == 1">正常</span>
                                <span v-else-if="scope.row.status == 2">禁用</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]"
                        :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </el-card>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading:true,
            search:{
                active_name:'',
                status:'' 
            },
            tableData: [
                {
                    shop_name:'晨晨'
                }
            ],
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList() {
            // let data=this.search;
            // data.page=this.currentPage;
            // data.perPage=this.pagesize;
            // accountShopList(data).then(res=>{
            //     this.total = res.data.count;
            // 	 this.tableData = res.data.list;
            // 	 this.loading=false
            // })
            this.loading=false
        },
        //搜索
        searchOrder(){

        },
        //重置
        toReset(){

        },
        handleSizeChange(size) {
            this.pagesize = size;
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getList()
        },
    }
}
</script>
<style lang="less" scoped>

</style>