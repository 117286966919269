<template>
	<div>
		<div class="wrapper-main">
			<el-form :model="form" ref="form" :rules="rules" label-width="150px" class="demo-ruleForm">
				<div class="wrapper-cont">
					<div class="setTitle">基础信息</div>
					<el-form-item label="分销设置:">
						<el-radio v-model="form.state" label="1">关闭</el-radio>
						<el-radio v-model="form.state" label="2">开启</el-radio>
					</el-form-item>
					<el-form-item label="直推佣金:" prop="straight_money">
						<el-input placeholder="请输入"  oninput="value=value.replace(/[^\d.]/g,'')"
							style="vertical-align:middle;" class="w150" size="small" v-model="form.straight_money">
							<template slot="append">%</template>
						</el-input>
					</el-form-item>
					<el-form-item label="间推佣金:" prop="indirect_money">
						<el-input placeholder="请输入" oninput="value=value.replace(/[^\d.]/g,'')" class="w150" size="small" v-model="form.indirect_money">
							<template slot="append">%</template>
						</el-input>
					</el-form-item>
					<div class="tips">
						说明：开启直间推佣金，推客自购可获得直推佣金奖励；推客邀请好友在店铺商品购买下单，也可获得佣金奖励。
						<br /><span style="margin-left: 2.5%;">佣金算法：销售价-采购价=利润，利润*百分比=推客返佣</span>
					</div>
					<el-form-item label="推客是否开启门槛:">
						<el-switch
						  v-model="form.doorsill_state"
						  active-text="开启"
						  inactive-text="关闭">
						</el-switch>
					</el-form-item>
					<el-form-item label="推客条件:">
						满<el-input placeholder="请输入" oninput="value=value.replace(/[^\d.]/g,'')" class="w150" style="vertical-align: middle;margin: 0 5px;" size="small" v-model="form.doorsill_money">
							<template slot="append">元</template>
						</el-input>可成为推客
					</el-form-item>
					<div class="tips">
						说明：开启推客条件，则在本店购买下单金额达到条件才可
						以成为推客；<br /><span style="margin-left: 2.5%;">若关闭推客条件，则无门槛就可以成为推客。</span>
					</div>
				</div>
				<div class="wrapper-cont mt10 pp20">
					<el-form-item label="推客有礼营销图:" prop="material_image" class="marketpic">
						<div class="examples" @mouseenter="mouseover" @mouseleave="mouseleave">示例：</div>
						<div class="examples-pic" v-show="tipIimg">
							<img src="../../../../../../assets/img/merchant/twitter/example_pic.png" />
						</div>
						<img class="imgs" :src="
						  form.material_image
							? form.material_image
							: require('../../../../../../assets/img/uploads/banners.png')
						" />
						<p class="pstyle">尺寸750宽度，不限制高度</p>
						<el-button type="text" size="small" @click="uploadImg()">更多营销图</el-button>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<!-- 选择图片弹窗 -->
		<el-dialog title="选择营销图" :visible.sync="dialogVisible" width="36%">
			<div class="marketImg">
				<div class="imgItem" v-for="(img,index) in imgList" :key="index" @click="chooseImg(index,img)">
					<div class="activebg" v-if="img.checked">
						<img src="../../../../../../assets/img/selectImg.png" />
					</div>
					<img :src="img.material_image" />
				</div>
			</div>
			<!-- 分页 -->
			<div class="pagecurrent" v-if="imgList.length>=10">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="selectedImgBtn">确 定</el-button>
			</span>
		</el-dialog>
		<!--提交按钮-->
		<div class="save-cont">
			<div class="cancel-btn" @click="cancelback">取消</div>
			<div class="save-btn" @click="submitForm('form')">提交</div>
		</div>
	</div>
</template>

<script>
	import {
		distributionInsertOrUpdate,
		introduceList,
		distributionDetails
	} from '@/api/merchant/marketing.js'
	export default {
		data() {
			return {
				tipIimg: false,
				uploadFlag: false, //是否显示选择图片
				form: {
					state: '1',
					straight_money: '',
					indirect_money: '',
					material_image: '',
					material_id:'',
					doorsill_state:true,
					doorsill_money:''
				},
				rules: {
					straight_money: [{
						required: true,
						message: '请填写直推佣金',
						trigger: 'blur'
					}],
					indirect_money: [{
						required: true,
						message: '请填写间推佣金',
						trigger: 'blur'
					}],
					material_image: [{
						required: true,
						message: '请上传营销图',
						trigger: 'change'
					}],
					straight_money: [{
						required: true,
						message: '请填写成为推客金额',
						trigger: 'blur'
					}],
				},
				dialogVisible: false,
				plugin_id: '',
				imgList: [],
				selectedImg: {},
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.plugin_id = this.$route.query.plugin_id;
			if (this.plugin_id) {
				this.getInfo()
			}
		},
		methods: {
			//佣金设置详情
			getInfo() {
				let data = {
					plugin_id: this.plugin_id,
					account_type: '2'
				}
				distributionDetails(data).then(res => {
					this.form = res.data;
					this.form.state = this.form.state.toString();
					this.form.doorsill_state=this.form.doorsill_state==2?true:false;
				})
			},
			//营销介绍图素材
			getIntroduceList() {
				let data = {
					page: this.currentPage,
					perPage: this.pagesize,
					type: 2,
					plugin_id: this.plugin_id
				}
				introduceList(data).then(res => {
					this.total = res.data.count;
					this.imgList = res.data.list;
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 调接口
				this.getIntroduceList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 调接口
				this.getIntroduceList()
			},
			// 选中图片
			chooseImg(index, item) {
				let list = [...this.imgList];
				//console.log(list, "666666")
				//单选
				let check = !list[index].checked;
				list.forEach((res) => {
					res.checked = false;
				});
				list[index].checked = check;

				this.imgList = [...list];
			},
			selectedImgBtn() {
				this.imgList.forEach(res => {
					if (res.checked == true) {
						this.selectedImg = res;
					}
				});
				this.dialogVisible = !this.dialogVisible;
				this.form.material_image = this.selectedImg.material_image;
				this.form.material_id = this.selectedImg.material_id;	

			},
			submitForm(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.form));
						data.material_image = this.tools.filterImgPre(data.material_image);
						data.plugin_id = this.plugin_id;
						data.doorsill_state=data.doorsill_state==true?2:1;
						data.account_type = "2";
						distributionInsertOrUpdate(data).then(res => {
							this.$message.success("设置成功！");
							this.$router.push({
								path: "/marketingcenteradmin/marketingcenter/marketingcenteritem",
							});
						})
						//调接口

					} else {
						return false;
					}
				});
			},
			cancelback() {
				this.$router.push({
					path: "/marketingcenteradmin/marketingcenter/marketingcenteritem",
				});
			},
			// 选择图片
			uploadImg() {
				this.dialogVisible = !this.dialogVisible;
				this.getIntroduceList()
			},

			//删除图片
			delImg(index) {
				this.formpic.market_image.splice(index, 1);
			},
			mouseover() {
				this.tipIimg = true;
			},
			mouseleave() {
				this.tipIimg = false;
			},
		}
	}
</script>

<style scoped lang="scss">
	.tips {
		margin-left: 80px;
		font-size: 12px;
		font-weight: 400;
		color: #333;
		line-height: 22px;
		margin-bottom: 10px;
	}

	.pstyle {
		height: 18px;
		font-size: 12px;
		font-weight: 500;
		color: #999999;
		line-height: 17px;
		margin-top: 20px;
	}

	.marketpic {
		position: relative;
		z-index: 1;

		.examples {
			position: absolute;
			top: 44px;
			left: -60px;
			height: 20px;
			font-size: 14px;
			font-weight: 400;
			color: #1890FF;
			line-height: 20px;
			cursor: pointer;
			z-index: 2;
		}

		.examples-pic {
			pointer-events: none;
			position: absolute;
			z-index: 3;
			top: 70px;
			left: -164px;
		}
	}

	.imgs {
		width: 300px;
		height: 120px;
		display: block;
	}

	.marketImg {
		padding: 10px 10px;
		overflow: hidden;

		.imgItem {
			float: left;
			margin: 5px;
			width: 200px;
			height: 130px;
			position: relative;
			border-radius: 2px;

			>img {
				width: 100%;
				height: 100%;
				display: block;
			}

			.activebg {
				width: 100%;
				height: 100%;
				position: absolute;
				background: rgba(000, 000, 000, 0.5);
				top: 0;
				left: 0;
				border-radius: 2px;

				>img {
					width: 36px;
					height: 26px;
					left: 50%;
					margin-left: -18px;
					top: 50%;
					margin-top: -13px;
					position: relative;
				}
			}
		}
	}

	::v-deep.el-dialog__body {
		padding: 0;
	}
</style>
