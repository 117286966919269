import axios from '@/config/httpConfig'
import qs from  'qs'
//获取商品列表
export const goods_list=(data)=> {
    return axios('post','Goods/goods_list', data);
}
// 添加商品
export const add_goods=(data)=> {
    return axios('post','Goods/add_goods', data);
}
// 编辑商品提交
export const edit_goods=(data)=> {
    return axios('post','Goods/edit_goods', data);
}
// 商品详情
export const goods_deatil=(data)=> {
    return axios('post','Goods/goods_deatil', data);
}
// 删除商品
export const goods_delete=(data)=> {
    return axios('post','Goods/goods_delete', data);
}
// 商品上下架
export const goods_putaway=(data)=> {
    return axios('post','Goods/goods_putaway', data);
}
// 创建规格
export const createSpecification=(data)=> {
    return axios('post','Goods/createSpecification', data);
}
// 规格列表
export const SpecificationList=(data)=> {
    return axios('post','Goods/SpecificationList', data);
}
// 删除规格
export const SpecificationDel=(data)=> {
    return axios('post','Goods/SpecificationDel', data);
}

// 获取发货/退货地址列表
export const AddressAdminShipmentsList=(data)=> {
    return axios('post','Address/AddressAdminShipmentsList', data);
}
// 获取运费模板列表
export const goods_temp_list=(params)=> {
    return axios('get','product/goods_temp_list', params)
} 
// 获取规格详情
export const GoodsSkuInfo=(data)=> {
    return axios('post','Goods/GoodsSkuInfo', data);
}
// 提交规格详情
export const SetGoodsSkuInfo=(data)=> {
    return axios('post','Goods/SetGoodsSkuInfo', data);
}
// 获取商品统计数量
export const getGoodsCount=(data)=> {
    return axios('post','Goods/getGoodsCount', data);
}
//套餐列表
export const GiftPackageList=(data)=> {
    return axios('post','GiftPackage/GiftPackageList', data);
}
//套餐列表启用禁用
export const giftPackagePutaway=(data)=> {
    return axios('post','GiftPackage/giftPackagePutaway', data);
}
//套餐列表删除
export const deleteGiftPackage=(data)=> {
    return axios('post','GiftPackage/deleteGiftPackage', data);
}
//选择商品列表
export const goodsSelectList=(data)=> {
    return axios('post','/Goods/goodsSelectList', data);
}
//添加套餐
export const GiftPackageInset=(data)=> {
    return axios('post','/GiftPackage/GiftPackageInset', data);
}
//套餐详情
export const getGiftPackage=(data)=> {
    return axios('post','/GiftPackage/getGiftPackage', data);
}
//套餐修改
export const GiftPackageModification=(data)=> {
    return axios('post','/GiftPackage/GiftPackageModification', data);
}



