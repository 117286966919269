import axios from '@/config/httpConfig'
import qs from  'qs'
//门店明细
export const shopFundList=(params)=> {
    return axios('get','Withdrawal/platform/shopFundList', params);
}
//门店明细详情-金额
export const shopUsableAmount=(data)=> {
    return axios('post','Withdrawal/platform/shopUsableAmount', data);
}
//门店明细详情
export const shopFundDetails=(data)=> {
    return axios('post','Withdrawal/platform/shopFundDetails', data);
}
//服务商明细
export const channelFundList=(params)=> {
    return axios('get','Withdrawal/platform/channelFundList', params);
}
//服务商明细详情-金额和基本信息
export const channelAreClassifiedIntoTopInfo=(data)=> {
    return axios('post','Withdrawal/platform/channelAreClassifiedIntoTopInfo', data);
}
//服务商明细详情
export const channelAreClassifiedIntoDetails=(data)=> {
    return axios('post','Withdrawal/platform/channelAreClassifiedIntoDetails', data);
}
//提现列表
export const getPlatformWithdrawalPageList=(params)=> {
    return axios('get','Withdrawal/platform/getPlatformWithdrawalPageList', params);
}
//提现通过
export const passWithdrawal=(data)=> {
    return axios('post','Withdrawal/platform/passWithdrawal', data);
}
//平台提现拒绝
export const refuseWithdrawal=(data)=> {
    return axios('post','Withdrawal/platform/refuseWithdrawal', data);
}
//提现配置添加
export const addWithdrawalConfig=(data)=> {
    return axios('post','Withdrawal/platform/addWithdrawalConfig', data);
}
//提现配置详细
export const getWithdrawalConfigInfo=(params)=> {
    return axios('get','Withdrawal/platform/getWithdrawalConfigInfo', params);
}
//平台佣金明细
export const platformFenRunEarningsList=(data)=> {
    return axios('post','Commission/platform/platformFenRunEarningsList', data);
}
//合伙人明细
export const accountShopList=(data)=> {
    return axios('post','/Account/platform/accountShopList', data);
}
//合伙人明细详情
export const accountShopDetail=(params)=> {
    return axios('get','/Account/platform/accountShopDetail', params);
}