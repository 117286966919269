<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-input prefix-icon="el-icon-search" clearable placeholder="输入搜索网店名称/手机号" class="inputWidth"
						size="small" v-model="shop_name"  @keyup.enter.native="toSearch">
					</el-input>
					<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
					<el-button type="primary" size="small" plain @click="toReset">重置</el-button>
					<el-button type="primary" size="small" plain @click="addPlugin">添加网店</el-button>

				</div>
				<div class="mt10">
					<el-row :gutter="15">
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div>正常商户</div>
								<div class="mt10 monval">{{dataval.account_normal}}</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div>停用商户</div>
								<div class="mt10 monval">{{dataval.account_forbidden}}</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div>审核中商户</div>
								<div class="mt10 monval">{{dataval.account_audit}}</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div>待续费商户</div>
								<div class="mt10 monval">{{dataval.account_continued_fee}}</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<!--表格-->
				<div class="tableMain">
					<div class="tableTile">商户列表</div>
					<el-table :data="tableData" style="width: 100%" min-height="250" border  max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="shop_name" label="商户名称" align="center"  width="120">
						</el-table-column>
						<el-table-column prop="account_shop_info.account" label="登录账号" align="center" width="120">
						</el-table-column>
						<el-table-column label="网店类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_entity == 1">线上门店</span>
								<span v-else>实体门店</span>
							</template>
						</el-table-column>
						<el-table-column prop="account_level_info.shop_level_name" label="网店等级" align="center">
						</el-table-column>
						<el-table-column prop="marketing_trade_info.trade_name" label="行业类目" align="center">
						</el-table-column>
						<el-table-column prop="account_channel_info.username" label="所属服务商" align="center"  width="120">
						</el-table-column>
						<el-table-column label="来源类别" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.source == 1">网上注册</span>
								<span v-else-if="scope.row.source == 2">代理创建</span>
								<span v-else-if="scope.row.source == 3">商户赠送</span>
							</template>
						</el-table-column>
						<el-table-column prop="account_shop_info.senior_manager_number" label="高级网店" align="center">
						</el-table-column>
						<el-table-column prop="account_shop_info.general_manager_number" label="普通网店" align="center">
						</el-table-column>
						<el-table-column prop="account_shop_info.begin_time" label="启用时间" align="center"  width="120">
						</el-table-column>
						<el-table-column prop="account_shop_info.end_time" label="过期时间" align="center"  width="120">
						</el-table-column>
						<el-table-column prop="tele_phone" label="联系电话" align="center"  width="120">
						</el-table-column>
						<el-table-column prop="address" label="地址信息" align="center" width="150">
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.status == 1">启用</span>
								<span v-else-if="scope.row.status == 2">禁用</span>
								<span v-else-if="scope.row.status == 3">审核中</span>
								<span v-else-if="scope.row.status == 4">待续费</span>
							</template>
						</el-table-column>
						<el-table-column fixed="left" label="操作" width="220" align="center">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toPlugin(scope.row)">
									插件
								</el-button>
								<el-button type="text" size="small" @click="toDetail(scope.row)">
									详情
								</el-button>
								<el-button type="text" size="small" @click="toEdit(scope.row)">
									编辑
								</el-button>
								<el-button type="text" size="small" @click="giftShop(scope.row)">
									赠送店铺
								</el-button>
								<!-- <el-button type="text" style="color: #F56C6C;" size="small" @click="toDel(scope.row)">
									删除
								</el-button> -->
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<!--插件弹框-->
		<el-dialog title="网店插件" :visible.sync="dialogPlugInVisible" width="40%">
			<el-table :data="PlugInData" style="width: 100%" min-height="250" border>
				<el-table-column label="插件名称" prop="title" align="center"></el-table-column>
				<el-table-column label="实付" prop="amount" sortable align="center"></el-table-column>
				<el-table-column label="收益" prop="commission_money" sortable align="center"></el-table-column>
				<!-- <el-table-column label="商户" prop="merchant" align="center"></el-table-column> -->
			</el-table>
			<!-- 分页 -->
			<div class="pagecurrent">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizePlugChange"
						@current-change="handleCurrentPlugChange" :current-page="currentPlugPage"
						:page-sizes="[10,20,30,50]" :page-size="pagePlugsize"
						layout="total, sizes, prev, pager, next, jumper" :total="totalPlug">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
		<!--赠送店铺-->
		<el-dialog title="赠送网店/年" :visible.sync="dialogGiftShopVisible" width="25%">
			<el-form :model="form" ref="form" label-width="100px" class="demo-ruleForm">
				<el-form-item label="普通店长" prop="commonNum" :rules="[
			      { required: true, message: '不能为空'},
			      { type: 'number', message: '必须为数字值'}
			    ]">
					<el-input size="small"  style="width: 85%;" v-model.number="form.commonNum">
						<template slot="append">个</template>
					</el-input>
				</el-form-item>
				<el-form-item label="高级店长" prop="seniorNum" :rules="[
				  { required: true, message: '不能为空'},
				  { type: 'number', message: '必须为数字值'}
				]">
					<el-input size="small" style="width: 85%;"  v-model.number="form.seniorNum">
						<template slot="append">个</template>
					</el-input>
				</el-form-item>
				<el-form-item label="扣除金额">
					<span style="color: #F56C6C;">{{price_all || 0}}</span>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="submitForm('form')">提交</el-button>
					<el-button size="small" @click="resetForm('form')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!--金额扣除失败-->
		<el-dialog :visible.sync="centerFailVisible" :show-close="false" width="25%" center>
			<div style="width: 100%; text-align: center;">{{return_msg}}</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerFailVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="toRecharge" size="small">去充值</el-button>
			</span>
		</el-dialog>
		<!--金额扣除成功-->
		<el-dialog :visible.sync="centerSuccessVisible" :show-close="false" width="25%" center>
			<div style="width: 100%; text-align: center;">店铺赠送需扣款{{price_all||0}}元哦～</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerSuccessVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="surePay" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!--充值-->
		<el-dialog title="充值金额" :visible.sync="dialogRechargeVisible" width="38%">
			<div class="mb10">
				<el-form :model="recharge" ref="recharge" :rules="rules" label-width="100px"
					class="demo-ruleForm formBox">
					<el-form-item label="充值类型:">
						<el-radio-group v-model="recharge.radiotype" @change="changeRadio">
							<!--  <el-radio disabled :label="1">实付金额</el-radio> -->
							<el-radio :label="2">套餐充值</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="储值套餐:" v-if="recharge.radiotype == '2'">
						<el-select v-model="recharge.combo" size="small" @change="getComboVal(recharge.combo)"
							class="inputWidth" placeholder="全部">
							<el-option v-for="(item,index) in comboList" :key="index"
								:label="item.pet_card_title" :value="item.pet_card_id"></el-option>
						</el-select>
						<div style="overflow: hidden">
							<label style="float: left;margin-right: 5px;">折扣 </label>
							<el-input size="small" v-model="comboVal.discount"
								style="width: 80px !important;float: left"></el-input>
							<span style="float: left;margin-left: 5px;">%</span>
							<label style="float: left;margin-right: 5px;margin-left: 5px;">充值金额 </label>
							<el-input size="small" v-model="comboVal.package_price"
								style="width: 80px !important;float: left"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="充值金额:" v-if="recharge.radiotype == '1'" prop="money">
						<el-input size="small" v-model="recharge.money" class="inputWidth" @change="inputMoney">
						</el-input>
					</el-form-item>
					<el-form-item label="实付金额:" style="color:#F56C6C;font-weight: bold;"
						v-if="recharge.radiotype == '1'">
						<span v-if="recharge.money">¥{{recharge.money}}</span>
					</el-form-item>
					<el-form-item label="实付金额:" style="color:#F56C6C;font-weight: bold;"
						v-if="recharge.radiotype == '2' && recharge.combo">
						<span v-if="comboVal.selling_price">¥{{comboVal.selling_price}}</span>
					</el-form-item>
					<el-form-item label="支付类型" v-if="recharge.money || comboVal.selling_price">
						<el-radio v-model="recharge.payType" @change="changePayRadio" label="1">微信</el-radio>
						<el-radio v-model="recharge.payType" @change="changePayRadio" label="2">支付宝</el-radio>
					</el-form-item>
					<el-form-item label="二维码" v-if="recharge.money || comboVal.selling_price" style="margin-bottom: 0;">
						<div class="qr_code_pay">
							<div v-if="recharge.payType==1">
								<img :src="recharge_qrCode" width="95" height="95" style="overflow:hidden;" />
							</div>
							<div v-if="recharge.payType==2">
								<iframe :srcdoc="recharge_qrCode" frameborder="no" border="0" marginwidth="0"
									marginheight="0" scrolling="no" width="95" height="95" style="overflow:hidden;">
								</iframe>
							</div>
		
						</div>
					</el-form-item>
					<el-form-item style="margin-bottom: 0;" v-if="recharge.money || comboVal.selling_price"><span
							class="pstyle">{{recharge.payType=="1"?"请用微信扫一扫授权":"请用支付宝扫一扫授权"}}</span></el-form-item>
					<el-form-item style="margin-bottom: 0;">
						<el-button type="primary" @click="submitPay('recharge')" size="small">确认支付完成</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getChannelShopList,
		shopCommissionList,
		getStoreManagerPrice,
		giveShop
	} from '@/api/channel/onlineStore'
	import {
		VirtualOrderList,
		petCardList,
		rechargeAmount,
		getVirtualOrderStatus
	} from '@/api/channel/order.js'
	export default {
		data() {
			return {
				loading:true,
				dialogPlugInVisible: false,
				dialogGiftShopVisible: false,
				centerFailVisible: false,
				centerSuccessVisible: false,
				dialogRechargeVisible:false,
				shop_name: '',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				PlugInData: [],
				totalPlug: 0,
				currentPlugPage: 1, //初始页
				pagePlugsize: 10, //每页的数据
				dataval: {},
				comboList: [],
				form: {
					commonNum: 0,
					seniorNum: 0
				},
				account_id: '',
				username: '',
				senior_manager_price: '', //高级店长价格
				general_manager_price: '', //普通店长价格
				general_manager_price_all: '', //普通计算后的总数
				senior_manager_price_all: '', //高级计算后的总数
				account_shop_id: '',
				kcMoney: '',
				return_msg:'',
				recharge: {
					radiotype: 2,
					money: '',
					combo: '',
					discount: '',
					rechargeMoney: '',
					payType: '1'
				},
				rules: {
					money: [{
						required: true,
						message: '请填写金额',
						trigger: 'blur'
					}],
				},
				comboVal: {},
				recharge_qrCode: '',
				order_nuber: '',
			}
		},
		mounted() {
			this.getList()
		},
		computed:{
		    price_all(){
		        return (parseFloat(this.form.commonNum* this.general_manager_price) + parseFloat(this.form.seniorNum* this.senior_manager_price)).toFixed(2)
		    }
		},
		
		methods: {
			// 列表数据
			getList() {
				let data = {
					shop_name: this.shop_name,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data)
				getChannelShopList(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
					this.dataval = res.data.account_count ? res.data.account_count : {},
						this.tableData.forEach(item => {
							if (item.account_shop_info) {
								item.account_shop_info.begin_time = this.tools.timeFormate((item
									.account_shop_info
									.begin_time) * 1000);
								item.account_shop_info.end_time = this.tools.timeFormate((item
									.account_shop_info
									.end_time) * 1000);
							}
							item.address = (item.shop_province == null ? "" : item.shop_province) + (item
								.shop_city == null ? "" : item.shop_city) + (item.shop_county == null ?
								"" : item.shop_county) + (item.shop_address_detail == null ? "" : item
								.shop_address_detail);

						})
				})

			},
			//获取插件列表
			getPlugList() {
				let data = {
					account_id: this.account_id,
					username: this.username,
					page: this.currentPlugPage,
					perPage: this.pagePlugsize,
				}
				data = this.tools.filterParams(data);
				shopCommissionList(data).then(res => {
					this.totalPlug = res.data.count;
					this.PlugInData = res.data.list;
				})
			},
			//赠送普通 高级店长一年价格
			getStorePrice() {
				getStoreManagerPrice({type:'3'}).then(res => {
					this.senior_manager_price = res.data.senior_manager_price;
					this.general_manager_price = res.data.general_manager_price
				})
			},
			//输入普通个数
			commonChange(val) {
				this.general_manager_price_all = val * this.general_manager_price;

			},
			//输入高级个数
			seniorChange(val) {
				this.senior_manager_price_all = val * this.senior_manager_price;

			},
			
			//赠送店铺
			giftShop(row) {
				this.form.commonNum = 0;
				this.form.seniorNum = 0;
				this.general_manager_price_all = ''; //普通计算后的总数
				this.senior_manager_price_all = ''; //高级计算后的总数
				this.dialogGiftShopVisible = true;
				this.account_shop_id = row.account_shop_id;
				this.getStorePrice();
			},
			submitForm(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						this.centerSuccessVisible=true;
						
						//如果失败
						//this.centerSuccessVisible = true
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
			       this.$refs[formName].resetFields();
				   this.dialogGiftShopVisible = false;
			     },
			surePay() {
				let data = {
					account_shop_id: this.account_shop_id,
					senior_manager_number: this.form.seniorNum,
					general_manager_number: this.form.commonNum,
				}
				data = this.tools.filterParams(data);
				giveShop(data).then(res => {
					this.centerSuccessVisible = false;
					this.dialogGiftShopVisible = false;
					this.getList();
					this.$message({
						message: '店铺赠送成功，商户可开始使用咯',
						type: 'success'
					});
				}).catch(err => {
					this.centerFailVisible = true;
					this.return_msg=err.return_msg
					//this.$message.error(err.return_msg)
				})
				
			},
			toSearch() {
				this.currentPage = 1;
				this.getList()
			},
			toReset() {
				this.currentPage = 1;
				this.shop_name = '';
				this.getList()
			},
			//网店列表分页
			handleSizeChange(size) {
				this.currentPage = 1
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
			//插件分页
			handleSizePlugChange(size) {
				this.pagePlugsize = size;
				// 掉接口
				this.getPlugList()
			},
			handleCurrentPlugChange(currentPage) {
				this.currentPlugPage = currentPage;
				// 掉接口
				this.getPlugList()
			},
			//插件
			toPlugin(row) {
				this.account_id = row.account_shop_id;
				this.username = row.shop_name;
				this.dialogPlugInVisible = true;
				this.getPlugList()
			},

			//删除
			toDel(row) {
				this.tools.confirm("确定要删除？").then(res => {
					if (res) {
						//调接口
					}
				})
			},
			//添加门店
			addPlugin() {
				this.$router.push({
					path: '/onlinestoreadmin/onlinestore/onlinestoredetail',
					query: {
						type: 'add'
					}
				})
			},
			//编辑
			toDetail(row) {
				this.$router.push({
					path: '/onlinestoreadmin/onlinestore/onlinestoredetail',
					query: {
						type: 'detail',
						id: row.shop_id
					}
				})
			},
			//编辑
			toEdit(row) {
				this.$router.push({
					path: '/onlinestoreadmin/onlinestore/onlinestoredetail',
					query: {
						type: 'eduit',
						id: row.shop_id
					}
				})
			},
			//充值弹框
			toRecharge() {
				this.recharge.combo = '';
				this.comboVal = {};
				this.dialogRechargeVisible = !this.dialogRechargeVisible;
				this.cardList()
			},
			//储值套餐下拉列表
			cardList() {
				petCardList({}).then(res => {
					this.comboList = res.data;
			
				})
			},
		//选择下拉获取值
		getComboVal(val) {
			this.comboList.forEach(item => {
				if (item.pet_card_id == val) {
					this.comboVal = item
				}
			})
			this.getRechargeCode()
		},
		//切换获取储值套餐
		changeRadio(val) {
			if (val == 2) {
				this.cardList()
			}
		},
		//输入值失去焦点
		inputMoney(val) {
			this.recharge.money = val;
			this.getRechargeCode()
		},
		//选择支付方式
		changePayRadio(val) {
			this.recharge.payType = val;
			if (this.recharge.money || this.comboVal.selling_price) {
				this.getRechargeCode();
			}
		},
		//获取支付二维码
		getRechargeCode() {
			let data = {
				order_type: this.recharge.radiotype == 1 ? '1' : '4',
				payType: this.recharge.payType,
				money: this.recharge.radiotype == 1 ? this.recharge.money : this.comboVal.selling_price,
			}
			if (data.order_type == '4') {
				data.commodity_id = this.comboVal.pet_card_id
			};
			rechargeAmount(data).then(res => {
				this.recharge_qrCode = res.data.images;
				this.order_nuber = res.data.order_nuber
			})
		},
		//提交充值
		submitPay(recharge) {
			this.$refs[recharge].validate((valid) => {
				if (valid) {
					let params = {
						order_nuber: this.order_nuber
					}
					getVirtualOrderStatus(params).then(res => {
						if (res.data == 1) {
							this.$message.error("请支付！");
						} else if (res.data == 2) {
							this.$message.success("充值成功！")
							this.dialogRechargeVisible = !this.dialogRechargeVisible;
							this.centerFailVisible = !this.centerFailVisible;
							this.dialogGiftShopVisible = !this.dialogGiftShopVisible;
							this.centerSuccessVisible = !this.centerSuccessVisible;
							this.getList()
						} else if (res.data == 4) {
							this.$message.error("支付失败！")
							this.dialogRechargeVisible = !this.dialogRechargeVisible;
							this.centerFailVisible = !this.centerFailVisible;
							this.dialogGiftShopVisible = !this.dialogGiftShopVisible;
							this.centerSuccessVisible = !this.centerSuccessVisible;
						}
					})
				} else {
					return false;
				}
			});
		
		},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.el-col {
		border-radius: 4px;
	}

	.grid-content {
		background: #fff;
		padding: 30px 30px;
	}

	.monval {
		font-size: 26px;
	}
</style>
