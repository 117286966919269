<template>
	<div>
		<div class="wrapper-main">
			<el-button type="primary" size="small" plain @click="templateAdd" v-has="'新增运费模板'">新增运费模板</el-button>
			<span>开启商家配送的商户需设置，最多可创建100个</span>
		</div>
		<div class="tables">
			<table class="tablestyle m-b-none" v-for="(item, outIndex) in tableData" :key="outIndex">
				<thead>
					<tr>
						<td colspan="6" class="height50 padder-15">
							<div class="topheader">
								<div class="areaType">{{ item.title }}</div>
								<div class="default">{{ item.default }}</div>
								<div class="editdt">{{ item.updated_at }}</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>可配送区域</td>
						<td>{{ item.labelNums }}</td>
						<td>运费(元)</td>
						<td>{{ item.labelPrices }}</td>
						<td>续费(元)</td>
						<td>操作</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(tds, index) in item.charil" :key="index">
						<td style="width: 16.6666%">{{ tds.province_city }}</td>
						<td style="width: 16.6666%">{{ tds.default_number }}</td>
						<td style="width: 16.6666%">{{ tds.default_price }}</td>
						<td style="width: 16.6666%">{{ tds.create_number }}</td>
						<td style="width: 16.6666%">{{ tds.create_price }}</td>
						<td v-if="index == 0" style="border-left: 1px solid #ededef; width: 16.6666%"
							:rowspan="item.charil.length">
							<span style="color: #409eff; cursor: pointer" v-if="item.special_stores==1"
								@click="useTemplete(item,2)" v-has="'取消样板店专用'">取消样板店专用</span>
							<span style="color: #409eff; cursor: pointer" v-else
								@click="useTemplete(item,1)" v-has="'设置样板店专用'">设置样板店专用</span>
							<span style="color: #409eff; cursor: pointer"
								@click="editModel(item.product_template_id)" v-has="'模板编辑'" >编辑</span>
							<span style="color: #f56c6c; cursor: pointer"  v-has="'模板删除'"  @click="delModel(item, outIndex)">删除</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="wrapper-main" style="margin-bottom:50px">
			<div class="pagination-container" style="margin-top: 20px; text-align: right">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getProductTemp,
		delete_product_temp,
		disable_product_temp
	} from "@/api/plat/setting.js";
	export default {
		data() {
			return {
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				tableData: [],
			};
		},
		mounted() {
			
		},
		methods: {
			getProductTempData() {
				let data = {
					page: this.currentPage,
					pagesize: this.pagesize,
				};
				getProductTemp(data).then((res) => {
					//if (res.return_code == "SUCCESS") {
						let datas =[]
						datas=Object.values(res.data.list);
						//判断计价方式
						datas.forEach((item) => {
							if (item.billing_method == 1) {
								//按件数
								item.labelNums = "首件（个）";
								item.labelPrices = "续件（个）";
							} else if (item.billing_method == 2) {
								//按重量
								item.labelNums = "首重（kg）";
								item.labelPrices = "续重（kg）";
							} else {
								//按体积
								item.labelNums = "首体积（m³）";
								item.labelPrices = "续体积（m³）";
							}
						});
						this.tableData = datas;
						this.total = res.data.count;
					//}
					
				});
				
			},
			handleSizeChange(size) {
				this.pagesize = size;
				this.getProductTempData();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getProductTempData();
			},
			templateAdd() {
				this.$router.push("/settingadmin/distributionsettings/templatemodify");
			},
			editModel(id) {
				this.$router.push({
					path: "/settingadmin/distributionsettings/templatemodify",
					query: {
						id: id,
					},
				});
			},
			//样板店专用
			useTemplete(row, type) {
				this.tools.confirm(type == 1 ? "确定设置样板店专用" : "确定取消样板店专用").then(res => {
					if (res) {
						let params = {
							id: row.product_template_id,
							special_stores: type
						}
						params = this.tools.filterParams(params)
						disable_product_temp(params).then(res => {
							this.getProductTempData();
						})
					}
				})
			},
			//删除运费模板
			delModel(item, outIndex) {
				this.tools.confirm("确定删除？").then(res => {
					if (res) {
						//调接口
						delete_product_temp({
								id: item.product_template_id
							})
							.then((res) => {
								//   this.tableData.splice(outIndex, 1);
								//刷新列表
								this.getProductTempData();
							})
							
					}
				})

			},
		},
	};
</script>

<style scoped="scoped" lang="scss">
	.storemain {
		background: #f2f2f6;
		border-radius: 4px;
		padding: 10px 10px;
		font-size: 14px;

		span {
			font-size: 12px;
			color: #999;
			margin-left: 10px;
		}

		.title {
			font-size: 16px;
			padding: 20px 20px;
			color: #333;
			margin-bottom: 20px;
		}
	}

	.topheader {
		display: flex;
		height: 40px;
		line-height: 40px;

		.areaType {
			padding-left: 20px;
			font-weight: 500;
			color: #333333;
			font-size: 14px;
		}

		.default {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #ff5e00;
			margin-left: 300px;
		}

		.usernum {
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			margin-left: 40px;
		}

		.editdt {
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			margin-left: 40px;
		}
	}

	.tablestyle {
		background-color: #fff;
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #eeeeee;
		margin: 10px auto;
		text-align: center;
	}

	.tablestyle th {
		padding: 5px 5px;
		height: 50px;
		border-bottom: 1px solid #eeeeee;
		color: #666;
		height: 30px;
	}

	.tablestyle td {
		padding: 5px 5px;
		height: 50px;
		border-bottom: 1px solid #eeeeee;
		color: #666;
		height: 30px;
	}
</style>
