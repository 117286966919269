<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				 <div class="storemain">
				 	<div class="title">
				 		<span>数据面板设置：</span><el-switch
				 		  v-model="is_real"
				 		  active-text="开启"
				 		  inactive-text="关闭">
				 		</el-switch>
				 	</div>
				 </div>
				
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet">保存</div>
		</div>
	</div>
</template>

<script>
	import {setDataPanel,getDataPanel} from '@/api/plat/setting.js';
	export default{
		data(){
			return{
				is_real:true
			}
		},
		mounted() {
			this.getInfo()
		},
		methods:{
			getInfo(){
				getDataPanel({}).then(res=>{
					this.is_real=res.data.is_real=='0'?false:true
					
				})
			},
			submitSet(){
				setDataPanel({is_real:this.is_real==true?'1':'0'}).then(res=>{
					this.$message.success("设置成功！");
					this.getInfo();
				})
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.storemain {
		background: #F2F2F6;
		border-radius: 4px;
		padding: 10px 10px;
		font-size: 14px;

		.title {
			font-size: 16px;
			padding: 20px 20px;
			color: #333;
			margin-bottom: 20px
		}
	}

	.switch {
		margin-left: 20px
	}
</style>
