import axios from '@/config/httpConfig'
//获取订单列表
export const getShopList=(params)=> {
    return axios('get','/Account/platform/getShopList', params)
}
//获取门店详情
export const getShopInfo=(data)=> {
    return axios('post','/Account/platform/getShopInfo', data);
}
//门店状态修改
export const saveStatus=(data)=> {
    return axios('post','/Account/platform/saveStatus', data);
}