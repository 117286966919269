<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="order-search" style="margin-top: 0;">
					<el-row class="all_search_list" style="margin-bottom:0">
						<div class="demo-input-suffix">
							<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
								range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
								@change="changetimer"></el-date-picker>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder">搜索</el-button>
							<el-button size="small" @click="toResetOrder">重置</el-button>
						</div>
					</el-row>
				</div>
				<div class="main-cont bgwt">
					<el-table ref="multipleTable" size="small" :data="tableData" border tooltip-effect="dark" style="width: 100%"  max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="nickname" label="用户" align="center"></el-table-column>
						<el-table-column prop="order_sn" label="订单号" align="center"></el-table-column>
						<el-table-column label="订单类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.straight_push == '1'" >直推佣金收入</span>
								<span v-if="scope.row.straight_push == '2'" >间推佣金收入</span>
								<span v-if="scope.row.straight_push == '3'">自购奖励</span>
							</template>
						</el-table-column>
						<el-table-column label="汇率"  align="center" prop="commission_interest_rate"></el-table-column>
						<el-table-column prop="commission_money" label="返佣金额" sortable align="center"></el-table-column>
						<el-table-column prop="settle_time" label="结算时间"  align="center"></el-table-column>
						<el-table-column prop="reason" label="备注信息" show-overflow-tooltip align="center"></el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getCommissionList
	} from '@/api/merchant/finance.js'
	export default {
		data() {
			return {
				loading:true,
				search: {
					begin_time: '',
					end_time:'',
				},
				due_dt:'',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList(){
				let data={
					page:this.currentPage,
					perPage:this.pagesize,
					starttime:this.search.begin_time,
					endtime:this.search.end_time,
				}
				data=this.tools.filterParams(data)
				getCommissionList(data).then(res=>{
					this.loading=false;
					this.total=res.data.count;
					this.tableData=res.data.list;
					this.tableData.forEach(item=>{
						item.settle_time=this.tools.timeFormate(item.settle_time*1000);
					})
				})
			},
			//时间选择
			changetimer(val) { //时间
				if (val) {
					this.search.begin_time = val[0] / 1000;
					this.search.end_time = val[1] / 1000;
				} else {
					this.search.begin_time = '';
					this.search.end_time = '';
				}
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 调接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 调接口
				this.getList()
			},
			//搜索
			searchOrder(){
				this.currentPage = 1;
				//调接口
				this.getList()
			},
			toResetOrder(){
				this.currentPage = 1;
				this.search.begin_time = '';
				this.search.end_time = '';
				this.due_dt='';
				this.getList()
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
