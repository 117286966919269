<template>
	<div class="order">
		<div class="wrapper-main">
			<div class="model-content">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
					<div class="order-set">
						<div class="top_title">
							快递接口
						</div>
						<el-form-item label="快递查询" >
							<el-link  type="primary" href="https://market.aliyun.com/products/57126001/cmapi011120.html?spm=5176.2020520132.101.1.22187218tkpdye#sku=yuncode512000008" target="_blank">全国快递物流查询API接口（自动识别）- 快递单号查询</el-link>
						</el-form-item>
						<!-- <el-form-item label="AppCode" >
							<div class="orderTime">
								<el-input type="text" v-model="ruleForm.expressage_code" size="small" class="shippedInput"></el-input>
							</div>
						</el-form-item> -->
					</div>
					<div class="order-set">
						<div class="top_title">
							身份证接口
						</div>
						<el-form-item label="身份证图片识别">
							<el-link  type="primary" href="https://market.aliyun.com/products/57000002/cmapi00037883.html?spm=5176.2020520132.101.74.1cb17218sPiPzy#sku=yuncode31883000010" target="_blank">身份证验证</el-link>
							<el-link  type="primary" href="https://market.aliyun.com/products/57000002/cmapi031137.html?spm=5176.2020520132.101.3.735872186OC3hD#sku=yuncode2513700001" target="_blank">营业执照图文识别</el-link>
						</el-form-item>
						<!-- <el-form-item label="AppCode" >
							<div class="orderTime">
								<el-input type="text" v-model="ruleForm.identity_image_info_code" size="small" class="shippedInput"></el-input>
							</div>
						</el-form-item> -->
					</div>
			<!-- 		<div class="order-set">
						<div class="top_title">
							短信接口
						</div>
						<el-form-item label="短信文档" >
							<el-link type="primary" href="https://www.aliyun.com/ss/?k=%E7%9F%AD%E4%BF%A1"  target="_blank">短信文档链接</el-link>
						</el-form-item>
						
					<el-form-item label="AppKey" >
						<div class="orderTime">
							<el-input type="text" v-model="ruleForm.access_key" size="small" class="shippedInput" ></el-input>
						</div>
					</el-form-item>
					<el-form-item label="AppSecret" >
						<div class="orderTime">
							<el-input type="text" v-model="ruleForm.access_secret" size="small" class="shippedInput"></el-input>
						</div>
					</el-form-item>
					</div> -->
				</el-form>
			</div>
		</div>
		<!-- <div class="save-cont">
			<div class="save-btn" @click="submitSet('ruleForm')">提交</div>
		</div> -->
	</div>
</template>

<script>
	import {
		getALiConfig,
		setALiConfig
	} from '@/api/baseSet.js'
	export default {
		data() {
			return {
				ruleForm:{
					expressage_code:'',
					identity_image_info_code:'',
					access_key:'',
					access_secret:''
				},
				rules:{}
			}
		},
		mounted() {
			// this.getALiConfigData();
		},
		methods: {
			//数据获取
			getALiConfigData(){
				let params={}
				getALiConfig(params).then(res=>{
					if(res.return_code == 'SUCCESS'){
						this.ruleForm=res.data;
					}
				})
			},
			//提交申请
			submitSet() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						//调接口
						let data=this.ruleForm;
						data = this.tools.filterParams(data);
						setALiConfig(data).then(res=>{
							if (res.return_code == 'SUCCESS') {
								this.$message({
									message: '更新成功',
									type: 'success'
								});
								this.getALiConfigData();
							}
						})
					} else {
						this.$message.error('请填写完整信息')
						return false;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.order {
		padding-bottom: 60px;

		.order-set {
			padding: 15px;
			border-radius: 6px;
			// min-height: 300px;
			background-color: #f2f2f6;
			margin-bottom: 20px;

			.top_title {
				font-weight: bold;
				padding: 15px;
				margin: 10px 0;
				font-size: 14px;
				color: #606266
			}

			.orderTime {
				.timeInput {
					width: 50px !important;
					text-align: center;
				}

				.shippedInput {
					width: 400px !important;
				}

				span {
					color: #595961;
					margin: 0px 10px;
				}

				.tipmsg {
					font-size: 12px;
					color: #999999;
				}
			}

			.radioContent {
				span {
					color: #9797a1;
				}

				::v-deep .el-radio {
					color: #666;
					cursor: pointer;
					margin-right: 30px;
				}
			}

			.afterSale {
				span {
					color: #9797a1;
					margin-left: 30px;
				}
			}

			.reasonList {
				ul {
					li {
						padding: 10px;
						display: inline-block;
					}
				}
			}

			.explain {
				width: 600px;
				background-color: #ffe6c6;
				padding: 10px 20px;

				.ant-col {
					line-height: 30px;
					color: #595961;
				}
			}
		}

		.store-btns {
			position: fixed;
			left: 200px;
			bottom: 0px;
			right: 20px;
			padding: 8px 10px;
			background: white;
			z-index: 100;
			box-shadow: 0 2px 10px 0 rgba(15, 12, 70, 0.1);

			// border-radius: 10px 10px 0 0;
			.text-center {
				text-align: center !important;
			}
		}
	}
</style>
