<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt  pp20">
					<el-button type="primary" plain size="small" @click="toAdd" v-has="'添加套餐'" >添加套餐</el-button>
				</div>
				<!--表格-->
				<div class="tableMain">
					<el-table :data="tableData" style="width: 100%" min-height="250" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" border max-height="550" >
						<el-table-column prop="pet_card_title" label="套餐名称" align="center">
						</el-table-column>
						<el-table-column prop="package_price" label="套餐现价" align="center">
						</el-table-column>
						<el-table-column prop="selling_price" label="实付价" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="添加时间" align="center">
						</el-table-column>
						<el-table-column label="操作" width="200" align="center">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toEdit(scope.row)" v-has="'编辑'" >编辑</el-button>
								<el-button type="text" size="small" @click="toDel(scope.row)" v-has="'删除'" >删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<!--拒绝原因弹框-->
		<el-dialog title="储值套餐" :visible.sync="dialogAddVisible" width="30%">
			<div class="mb10">
				<el-form :model="form" ref="form" :rules="rules" label-width="80px" class="demo-ruleForm formBox">
					<el-form-item label="套餐名称" prop="pet_card_title">
						<el-input size="small" placeholder="请输入套餐名称" v-model="form.pet_card_title">
						</el-input>
					</el-form-item>
					<el-form-item label="套餐价格" prop="package_price">
						<el-input size="small" placeholder="请输入套餐价格" v-model.number="form.package_price"></el-input>
					</el-form-item>
					<el-form-item label="套餐折扣" prop="discount">
						<el-input placeholder="请输入套餐折扣" size="small" v-model.number="form.discount"
							oninput="value=value.replace(/[^\d.]/g,'1');if(value>100)value='1'">
							<template slot="append">%</template>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogAddVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureCommit('form')" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		petCardList,
		savePetCard,
		delPetCard
	} from '@/api/plat/business'
	export default {
		data() {
			return {
				loading:true,
				dialogAddVisible: false,
				form: {
					pet_card_title: '',
					package_price: '',
					discount: ''
				},
				rules: {
					pet_card_title: [{
						required: true,
						message: '请填写套餐名称',
						trigger: 'blur'
					}],
					package_price: [{
						required: true,
						message: '请填写套餐价格',
						trigger: 'blur'
					}],
					discount: [{
						required: true,
						message: '请填写套餐折扣',
						trigger: 'blur'
					}]
				},
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				tableData: [{
					name: 'v1套餐',
					price: '10000',
					realPrice: '20000',
					createTime: '2020-04-21 18:06:26'
				}]
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let params = {
					page: this.currentPage,
					perPage: this.pagesize
				}
				params = this.tools.filterParams(params)
				petCardList(params).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list;

				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//添加套餐
			toAdd() {
				this.form.pet_card_title = '';
				this.form.package_price = '';
				this.form.discount = '';
				this.dialogAddVisible = !this.dialogAddVisible
			},
			//编辑套餐
			toEdit(row) {
				this.dialogAddVisible = !this.dialogAddVisible;
				this.form = JSON.parse(JSON.stringify(row));
			},
			//删除
			toDel(row) {
				this.tools.confirm('确定要删除吗？').then(res => {
					if (res) {
						//调接口
						delPetCard({
							pet_card_id: row.pet_card_id
						}).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.getList()
							}
						})
					}
				})
			},
			//提交设置储值套餐
			sureCommit(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data = this.form;
						data = this.tools.filterParams(data)
						savePetCard(data).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.dialogAddVisible = !this.dialogAddVisible
								this.getList();
								this.form.pet_card_id = ''
							}

						})
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style>
</style>
