<template>
	<div>
		<div class="wrapper-cont pp20">
			<div class="timeStyle">基础设置</div>
			<div class="level_title">
				<label class="pla">代理商等级：</label>
				 <el-checkbox-group v-model="selectLevel" >
					  <el-checkbox
						v-for="level in channelLevel"  
						:label="level.unique_identification"
						:key="level.unique_identification">
						{{level.channel_name}}
					  </el-checkbox>
					</el-checkbox-group>
			</div>
		</div>
		<div class="mt10 tabsitem">
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="A级" name="first">
					<div class="wrapper-cont pd20">
						<el-form :model="formA" ref="formA" label-width="150px" class="demo-ruleForm">
							<div class="timeStyle">A级代理商</div>
							<el-form-item label="等级收益">
								<el-input v-model="formA.level_income" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0'" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>
								<p class="pstyle">例：店铺售卖价100元，A代理商是80%，那么代理商购买店铺花80元, 代理商收益20元。 实付=原价*折扣</p>
							</el-form-item>
							<el-form-item label="店铺成交奖励">
								<el-input v-model="formA.store_transaction_reward" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">平台将某个店铺归属给某区域代理成交，则他享受设置的15%收益；如果店长直接付费开通，是无享受收益</p>
							</el-form-item>
							<el-form-item label="商品出货奖励">
								<el-input v-model="formA.merchandise_shipment_incentive" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
							<el-form-item label="区域商品出货奖励">
								<el-input v-model="formA.area_shipment_reward" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">用户购买商品收件地址是为该区域代理商，平台给到代理商的收益奖励。商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
							<el-form-item label="插件奖励">
								<el-input v-model="formA.plugin_reward" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">插件收益=插件售价*百分比</p>
							</el-form-item>
						</el-form>
					</div>
				</el-tab-pane>
				<el-tab-pane label="B级" name="second">
					<div class="wrapper-cont pd20">
						<el-form :model="formB" ref="formB" label-width="150px" class="demo-ruleForm">
							<div class="timeStyle">B级代理商</div>
							<el-form-item label="等级收益">
								<el-input v-model="formB.level_income" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>
								<p class="pstyle">例：店铺售卖价100元，A代理商是80%，那么代理商购买店铺花80元, 代理商收益20元。 实付=原价*折扣</p>
							</el-form-item>
							<el-form-item label="店铺成交奖励">
								<el-input v-model="formB.store_transaction_reward" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">平台将某个店铺归属给某区域代理成交，则他享受设置的15%收益；如果店长直接付费开通，是无享受收益</p>
							</el-form-item>
							<el-form-item label="商品出货奖励">
								<el-input v-model="formB.merchandise_shipment_incentive" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
							<el-form-item label="区域商品出货奖励">
								<el-input v-model="formB.area_shipment_reward" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">用户购买商品收件地址是为该区域代理商，平台给到代理商的收益奖励。商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
							<el-form-item label="插件奖励">
								<el-input v-model="formB.plugin_reward" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">插件收益=插件售价*百分比</p>
							</el-form-item>
						</el-form>
					</div>
				</el-tab-pane>
				<el-tab-pane label="C级" name="third">
					<el-form :model="formC" ref="formC" label-width="150px" class="demo-ruleForm">
						<div class="wrapper-cont pd20">
							<div class="timeStyle">C级代理商</div>
							<el-form-item label="等级收益">
								<el-input v-model="formC.level_income" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>
								<p class="pstyle">例：店铺售卖价100元，A代理商是80%，那么代理商购买店铺花80元, 代理商收益20元。 实付=原价*折扣</p>
							</el-form-item>
							<el-form-item label="店铺成交奖励">
								<el-input v-model="formC.store_transaction_reward" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">平台将某个店铺归属给某区域代理成交，则他享受设置的15%收益；如果店长直接付费开通，是无享受收益</p>
							</el-form-item>
							<el-form-item label="商品出货奖励">
								<el-input v-model="formC.merchandise_shipment_incentive" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
							<el-form-item label="区域商品出货奖励">
								<el-input  v-model="formC.area_shipment_reward" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">用户购买商品收件地址是为该区域代理商，平台给到代理商的收益奖励。商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
							<el-form-item label="插件奖励">
								<el-input v-model="formC.plugin_reward" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">插件收益=插件售价*百分比</p>
							</el-form-item>
						</div>
						<div class="wrapper-cont pd20 mt10">
							<el-form-item label="全国分红收益">
								<el-switch v-model="formC.national_share_bonus" inactive-text="开启">
								</el-switch>
							</el-form-item>
							<el-form-item label="店铺成交奖励">
								<el-input v-model="formC.national_store_transaction_award" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">平台将某个店铺归属给某区域代理成交，则他享受设置的15%收益；如果店长直接付费开通，是无享受收益</p>
							</el-form-item>
							<el-form-item label="商品出货奖励">
								<el-input v-model="formC.national_merchandise_shipment_award" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
						</div>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="D级" name="fourth">
					<el-form :model="formD" ref="formD" label-width="150px" class="demo-ruleForm">
						<div class="wrapper-cont pd20">
							<div class="timeStyle">D级代理商</div>
							<el-form-item label="等级收益">
								<el-input v-model="formD.level_income" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>
								<p class="pstyle">例：店铺售卖价100元，A代理商是80%，那么代理商购买店铺花80元, 代理商收益20元。 实付=原价*折扣</p>
							</el-form-item>
							<el-form-item label="店铺成交奖励">
								<el-input v-model="formD.store_transaction_reward" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">平台将某个店铺归属给某区域代理成交，则他享受设置的15%收益；如果店长直接付费开通，是无享受收益</p>
							</el-form-item>
							<el-form-item label="商品出货奖励">
								<el-input v-model="formD.merchandise_shipment_incentive" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
							<el-form-item label="区域商品出货奖励">
								<el-input v-model="formD.area_shipment_reward"  :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">用户购买商品收件地址是为该区域代理商，平台给到代理商的收益奖励。商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
							<el-form-item label="插件奖励">
								<el-input v-model="formD.plugin_reward"  :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">插件收益=插件售价*百分比</p>
							</el-form-item>
						</div>
						<div class="wrapper-cont pd20 mt10">
							<el-form-item label="全国分红收益">
								<el-switch v-model="formD.national_share_bonus" inactive-text="开启">
								</el-switch>
							</el-form-item>
							<el-form-item label="店铺成交奖励">
								<el-input  v-model="formD.national_store_transaction_award"  :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">平台将某个店铺归属给某区域代理成交，则他享受设置的15%收益；如果店长直接付费开通，是无享受收益</p>
							</el-form-item>
							<el-form-item label="商品出货奖励">
								<el-input   v-model="formD.national_merchandise_shipment_award"  :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
						</div>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="E级" name="five">
					<el-form :model="formE" ref="formE" label-width="150px" class="demo-ruleForm">
						<div class="wrapper-cont pd20">
							<div class="timeStyle">E级代理商</div>
							<el-form-item label="等级收益">
								<el-input v-model="formE.level_income" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>
								<p class="pstyle">例：店铺售卖价100元，A代理商是80%，那么代理商购买店铺花80元, 代理商收益20元。 实付=原价*折扣</p>
							</el-form-item>
							<el-form-item label="店铺成交奖励">
								<el-input  v-model="formE.store_transaction_reward" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">平台将某个店铺归属给某区域代理成交，则他享受设置的15%收益；如果店长直接付费开通，是无享受收益</p>
							</el-form-item>
							<el-form-item label="商品出货奖励">
								<el-input  v-model="formE.merchandise_shipment_incentive" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
							<el-form-item label="区域商品出货奖励">
								<el-input  v-model="formE.area_shipment_reward" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>15)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">用户购买商品收件地址是为该区域代理商，平台给到代理商的收益奖励。商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
							<el-form-item label="插件奖励">
								<el-input v-model="formE.plugin_reward" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">插件收益=插件售价*百分比</p>
							</el-form-item>
						</div>
						<div class="wrapper-cont pd20 mt10">
							<el-form-item label="全国分红收益">
								<el-switch v-model="formE.national_share_bonus"  inactive-text="开启">
								</el-switch>
							</el-form-item>
							<el-form-item label="店铺成交奖励">
								<el-input v-model="formE.national_store_transaction_award" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益
								<p class="pstyle">平台将某个店铺归属给某区域代理成交，则他享受设置的15%收益；如果店长直接付费开通，是无享受收益</p>
							</el-form-item>
							<el-form-item label="商品出货奖励">
								<el-input v-model="formE.national_merchandise_shipment_award" :min="0" :max="15"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}" size="small"
									class="w150 mr10" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>收益 最高设置15%
								<p class="pstyle">商品利润=采购价-成本价，代理商收益=商品利润*百分比</p>
							</el-form-item>
						</div>
					</el-form>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet()">保存</div>
		</div>
	</div>
</template>

<script>
	import {
		channelAllocationInfo, //获取代理商分润信息
		channelAllocationSetting, //代理商分润设置-全部提交
	} from '@/api/plat/platprofit'
	export default {
		data() {
			return {
				activeName: 'first',
				channelLevel: [],
				selectLevel: [],
				formA: {},
				formB: {},
				formC: {},
				formD: {},
				formE: {},
			}
		},


		methods: {
			handleClick() {

			},
			aa(){
				
			},
			//获取信息
			getChannelAllocationInfo() {
				let data = {}
				channelAllocationInfo(data).then(res => {
					//代理商等级
					this.channelLevel = JSON.parse(JSON.stringify(res.data.channelLevel));
					let aaLevel=[];
					this.channelLevel.forEach(item=>{
						if (item.channel_status == 'Y') {
							 aaLevel.push(item.unique_identification);
						}
					});
					this.selectLevel=aaLevel;
					//代理商
					let channelShareProfit = JSON.parse(JSON.stringify(res.data.channelShareProfit));
					channelShareProfit.forEach(rex=>{
						if(rex.national_share_bonus == 'Y'){
							rex.national_share_bonus=true
						}else{
							rex.national_share_bonus=false
						}
						if(rex.channel_level ==1){
							this.formA = rex;
							this.formA.level_income=(this.formA.level_income *100).toFixed(2);
							this.formA.store_transaction_reward = (this.formA.store_transaction_reward*100).toFixed(2);
							this.formA.merchandise_shipment_incentive = (this.formA.merchandise_shipment_incentive*100).toFixed(2);
							this.formA.area_shipment_reward = (this.formA.area_shipment_reward*100).toFixed(2);
							this.formA.plugin_reward = (this.formA.plugin_reward*100).toFixed(2);
						}
						if(rex.channel_level ==2){
							this.formB = rex;
							this.formB.level_income=(this.formB.level_income *100).toFixed(2);
							this.formB.store_transaction_reward=(this.formB.store_transaction_reward *100).toFixed(2);
							this.formB.merchandise_shipment_incentive=(this.formB.merchandise_shipment_incentive *100).toFixed(2);
							this.formB.area_shipment_reward=(this.formB.area_shipment_reward *100).toFixed(2);
							this.formB.plugin_reward=(this.formB.plugin_reward *100).toFixed(2);
						}
						if(rex.channel_level ==3){
							this.formC = rex;
							this.formC.level_income=(this.formC.level_income *100).toFixed(2);
							this.formC.store_transaction_reward=(this.formC.store_transaction_reward *100).toFixed(2);
							this.formC.merchandise_shipment_incentive=(this.formC.merchandise_shipment_incentive *100).toFixed(2);
							this.formC.area_shipment_reward=(this.formC.area_shipment_reward *100).toFixed(2);
							this.formC.plugin_reward=(this.formC.plugin_reward *100).toFixed(2);
							this.formC.national_store_transaction_award=(this.formC.national_store_transaction_award *100).toFixed(2);
							this.formC.national_merchandise_shipment_award=(this.formC.national_merchandise_shipment_award *100).toFixed(2);
							
						}
						if(rex.channel_level ==4){
							this.formD = rex;
							this.formD.level_income=(this.formD.level_income *100).toFixed(2);
							this.formD.store_transaction_reward=(this.formD.store_transaction_reward *100).toFixed(2);
							this.formD.merchandise_shipment_incentive=(this.formD.merchandise_shipment_incentive *100).toFixed(2);
							this.formD.area_shipment_reward=(this.formD.area_shipment_reward *100).toFixed(2);
							this.formD.plugin_reward=(this.formD.plugin_reward *100).toFixed(2);
							this.formD.national_store_transaction_award=(this.formD.national_store_transaction_award *100).toFixed(2);
							this.formD.national_merchandise_shipment_award=(this.formD.national_merchandise_shipment_award *100).toFixed(2);
						}
						if(rex.channel_level ==5){
							this.formE = rex;
							this.formE.level_income=(this.formE.level_income *100).toFixed(2);
							this.formE.store_transaction_reward=(this.formE.store_transaction_reward *100).toFixed(2);
							this.formE.merchandise_shipment_incentive=(this.formE.merchandise_shipment_incentive *100).toFixed(2);
							this.formE.area_shipment_reward=(this.formE.area_shipment_reward *100).toFixed(2);
							this.formE.plugin_reward=(this.formE.plugin_reward *100).toFixed(2);
							this.formE.national_store_transaction_award=(this.formE.national_store_transaction_award *100).toFixed(2);
							this.formE.national_merchandise_shipment_award=(this.formE.national_merchandise_shipment_award *100).toFixed(2);
						}
					})
					
					
					
				})
			},
			submitSet() {
				//A级代理商设置判断
				let paramsA = this.formA,paramsB = this.formB,paramsC = this.formC,paramsD = this.formD,paramsE = this.formE;
				let arrayA = [],arrayB = [],arrayC = [],arrayD = [],arrayE = [];
				//A级代理
				for (var key in paramsA) {
					if(paramsA[key] != '' && paramsA[key] != '0'){
						arrayA.push(paramsA[key])
					}
				}
				if (this.selectLevel.includes('stair_step') && arrayA.length <5) {
					this.$message.error("请完善A级代理商数据")
					return; // 终止程序
				}
				//B级代理
				for (var key in paramsB) {
					if(paramsB[key] != '' && paramsB[key] != '0'){
						arrayB.push(paramsB[key])
					}
				}
				if (this.selectLevel.includes('second_step') && arrayB.length <5) {
					this.$message.error("请完善B级代理商数据")
					return; // 终止程序
				}
				//C级代理
				for (var key in paramsC) {
					if(paramsC[key] != '' && paramsC[key] != '0'){
						arrayC.push(paramsC[key])
					}
				}
				if (this.selectLevel.includes('three_step') && arrayC.length <7) {
					this.$message.error("请完善C级代理商数据")
					return; // 终止程序
				}
				//D级代理
				for (var key in paramsD) {
					if(paramsD[key] != '' && paramsD[key] != '0'){
						arrayD.push(paramsD[key])
					}
				}
				if (this.selectLevel.includes('fully_step') && arrayD.length <7) {
					this.$message.error("请完善D级代理商数据")
					return; // 终止程序
				}
				//E级代理
				for (var key in paramsE) {
					if(paramsE[key] != '' && paramsE[key] != '0'){
						arrayE.push(paramsE[key])
					}
				}
				if (this.selectLevel.includes('five_step') && arrayE.length <7) {
					this.$message.error("请完善E级代理商数据")
					return; // 终止程序
				}
				let shareProfit =[
						this.formA,
						this.formB,
						this.formC,
						this.formD,
						this.formE
					]
					shareProfit.forEach(item=>{
						item.national_share_bonus=item.national_share_bonus==true?'Y':'N'
						if(item.channel_level == "1" || item.channel_level == "2"){
							delete item.national_store_transaction_award;
							delete item.national_merchandise_shipment_award;
							delete item.national_share_bonus;
						}
					})
				let data={
					channel_unique_identification:this.selectLevel.toString(),
					shareProfit:JSON.stringify(shareProfit)
				}
				channelAllocationSetting(data).then(res=>{
					if (res.return_code == 'SUCCESS') {
						this.$message({
							message: '设置成功',
							type: 'success'
						});
						this.getChannelAllocationInfo()
					}
				})
				
			}

		}

	}
</script>


<style lang="scss" scoped="scoped">
	@import "@/assets/css/plat/profit.scss";

	.tabsitem::v-deep.el-tabs__item {
		padding: 0 40px !important;
	}
</style>
