<template>
	<div class="wrapper-main">
		<div class="wrapper-cont">
			<div class="main-cont bgwt pp20">
				<el-row class="all_search_list" style="margin-bottom: 0;">
					<div class="demo-input-suffix">
						<el-button type="primary" v-if="plugin_relevance" size="small" @click="addSeckill">创建幸运团购活动</el-button>
					</div>
					<div class="demo-input-suffix">
						<el-input class="inputWidth" prefix-icon="el-icon-search" clearable v-model="search.keyword"
							placeholder="请输入活动名称" size="small" type="text">
						</el-input>
					</div>
					<div class="demo-input-suffix">
						<el-select v-model="search.status" class="w150" slot="prepend" placeholder="请选择" size="small">
							<el-option label="全部" value=""></el-option>
							<el-option label="待开始" value="0"></el-option>
							<el-option label="已开始" value="1"></el-option>
							<el-option label="已停止" value="2"></el-option>
							<el-option label="手动停止" value="3"></el-option>
						</el-select>
					</div>
					<div class="demo-input-suffix">
						<el-button type="primary" size="small" @click="toSearch">搜索
						</el-button>
					</div>
					<div class="demo-input-suffix">
						<el-button size="small" @click="toReset">重置</el-button>
					</div>
				</el-row>
			</div>
			<div class="main-cont bgwt tableMain mt10">
				<el-table :data="tableData" style="width: 100%" border @selection-change="handleSelectionKillChange" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column label="活动名称" prop="activity_name" align="center"></el-table-column>
					<el-table-column label="活动时间" align="center">
						<template slot-scope="scope">
							<div>开始时间：<span>{{scope.row.start_time}}</span></div>
							<div>结束时间：<span>{{scope.row.end_time}}</span></div>
						</template>
					</el-table-column>
					<el-table-column label="成团人数" prop="group_number" align="center"></el-table-column>
					<el-table-column label="未拼中奖励" prop="reward" align="center"></el-table-column>
					<el-table-column label="实际成团数" prop="number" align="center"></el-table-column>
					<el-table-column label="活动状态" align="center" width="200px">
						<template slot-scope="scope">
							<span style="color: #F7B500;" v-if="scope.row.status == '0'">待开始</span>
							<span style="color: #FA6400;" v-else-if="scope.row.status == '1'">已开始</span>
							<span style="color: #E02020;" v-else-if="scope.row.status == '2'">已停止</span>
							<span style="color: #E02020;" v-if="scope.row.status == '3'">手动停止</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button style="padding: 5px 0;" type="text" size="small" @click="toData(scope.row)">团购数据
							</el-button>
							<el-button style="padding: 5px 0;" type="text" size="small" @click="toDetail(scope.row)">查看
							</el-button>
							<el-button style="padding: 5px 0;color: #E02020;" v-if="scope.row.status != '1'" type="text"
								size="small" @click="toDel(scope.row)">删除</el-button>
							<br />
							<el-button style="padding: 5px 0px;" v-if="scope.row.status == '0'" type="text" size="small"
								@click="toEdit(scope.row)">编辑
							</el-button>
							<el-button v-if="scope.row.status == '1'" style="padding: 5px 0px;" type="text" size="small"
								@click="toStop(scope.row)">停止
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		
	</div>
</template>

<script>
	import {
		groupBuyingActivityList,
		GroupBuyingActivityDel,
		stopGroupBuyingActivity
	} from '@/api/merchant/marketing.js'

	export default {
		data() {
			return {
				loading:true,
				search:{
					keyword: '',
					status: '',
				},
				tableData: [{
					activity_name: '幸运拼团'
				}],
				multipleSelection: [],
				//分配行业弹框
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				seconds_kill_activity_id: '',
				plugin_relevance:false,
			}
		},
		mounted() {
			this.getList();
			
		},
		methods: {
			//拼团列表
			getList() {
				let data = {
					keyword: this.search.keyword,
					status: this.search.status,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				groupBuyingActivityList(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
					this.plugin_relevance=res.data.plugin_relevance;


				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//添加拼团
			addSeckill() {
				this.$router.push({
					path: "/marketingcenteradmin/luckypuzzle/luckypuzzledetail",
					query: {
						type: 'add'
					}
				})
			},
			//搜索
			toSearch() {
				this.getList()
			},
			//重置
			toReset() {
				this.search.keyword = '';
				this.search.status = '';
				this.currentPage = 1;
				this.getList()
			},
			//活动多选
			handleSelectionKillChange(val) {
				let ids = [];
				val.forEach(item => {
					ids.push(item.seconds_kill_activity_id)
				})
				this.multipleSelection = ids;
			},
			//查看
			toDetail(row) {
				this.$router.push({
					path: "/marketingcenteradmin/luckypuzzle/luckypuzzledetail",
					query: {
						id: row.group_buying_activity_id,
						type: 'detail'
					}
				})
			},
			//单个删除
			toDel(row) {
				this.tools.confirm("确定要删除此活动吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							group_buying_activity_id: row.group_buying_activity_id
						}
						GroupBuyingActivityDel(data).then(res => {
							this.getList();
							this.$message.success("删除成功！")
						})
					}
				})
			},
			//查看数据
			toData(row) {
				this.$router.push({
					path: "/marketingcenteradmin/luckypuzzle/luckypuzzledata",
					query: {
						id: row.group_buying_activity_id
					}
				})
			},
			//编辑
			toEdit(row) {
				this.$router.push({
					path: "/marketingcenteradmin/luckypuzzle/luckypuzzledetail",
					query: {
						id: row.group_buying_activity_id,
						type: 'edit'
					}
				})

			},
			//停止
			toStop(row) {
				this.tools.confirm("确定要停止此活动吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							group_buying_activity_id: row.group_buying_activity_id
						}
						stopGroupBuyingActivity(data).then(res => {
							this.getList();
							this.$message.success("停止成功！")
						})
					}
				})

			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
