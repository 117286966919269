<template>
	<div>
	<div class="wrapper-main pds">
		<div class="wrapper-cont">
			<el-tabs class="tabstyle" v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane name="first"><span slot="label">全部订单</span></el-tab-pane>
				<el-tab-pane label="待付款" name="second"><span slot="label">待付款</span></el-tab-pane>
				<el-tab-pane   :label="order_type == 'merchant-supply' ? '待牛店发货' : '待发货'" name="third"><span slot="label">{{order_type == 'merchant-supply' ? '待牛店发货' : '待发货'}}</span></el-tab-pane>
				<el-tab-pane :label="order_type == 'merchant-supply' ? '牛店已发货' : '待收货'" name="fourth"><span slot="label">{{order_type == 'merchant-supply' ? '牛店已发货' : '待收货'}}</span></el-tab-pane>
				<el-tab-pane label="已完成" name="five"><span slot="label">已完成</span></el-tab-pane>
				<el-tab-pane label="交易关闭" name="six"><span slot="label">交易关闭</span></el-tab-pane>
			</el-tabs>
			<div class="order-state">
				<ul>
					<li v-for="(tim,index) in operation_time" :key="index">
						<div class="statestep">
							<span class="circleNum" :class="tim.time?' ':'active'">{{index+1}}</span>
							<span class="statetext">{{tim.name}}</span>
							<span class="stateLine" :class="tim.time?' ':'active'"
								:style="index===operation_time.length-1?' display: none;':'display: block'"></span>
						</div>
						<div class="statetime">{{tim.time}}</div>
					</li>
				</ul>
			</div>
			<div class="order-info">
				<div class="order-info-item">
					<div class="or-state"><span></span>订单状态</div>
					<el-form ref="formstate" class="formstyle" :model="formstate" label-width="90px">
						<el-form-item label="订单号:">
							{{formstate.orderNum}}
						</el-form-item>
						<el-form-item label="快递公司:">
							{{formstate.kd}}
						</el-form-item>
						<el-form-item label="快递单号:">
							{{formstate.kdnum}}
						</el-form-item>
						<el-form-item label="收货人:">
							{{formstate.name}}
						</el-form-item>
						<el-form-item label="联系方式:">
							{{formstate.phone}}
						</el-form-item>
						<el-form-item label="收货地址:">
							{{formstate.address}}
						</el-form-item>
						<el-form-item label="退款原因:">
							{{formstate.desc}}
						</el-form-item>
					</el-form>
				</div>
				<div class="order-info-item">
					<div class="or-state"><span></span>付款信息</div>
					<el-form ref="formpay" class="formstyle" :model="formpay" label-width="90px">
						<el-form-item label="运费:">
							{{formpay.freight}}
						</el-form-item>
						<el-form-item label="应付:">
							{{formpay.pay}}
						</el-form-item>
						<el-form-item label="支付流水号:">
							{{formpay.serialNum}}
						</el-form-item>
						<el-form-item label="留言:">
							<div>{{formpay.seller_note}}</div>
							<div>
								<el-button size="small" type="primary" @click="addDesc" plain>添加备注</el-button>
							</div>
						</el-form-item>
	
					</el-form>
				</div>
			</div>
	
			<div class="orders-table">
				<div class="order-num">{{created_at}} <span
						style="margin-left: 10px;">订单号：{{formstate.orderNum}}</span></div>
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column prop="goods" label="商品信息" width="380px">
						<template slot-scope="scope">
							<div style="display: flex">
								<div style="margin:0 20px">
									<img :src="scope.row.master_map" style="width: 70px;height: 70px" />
								</div>
								<div class="namegood">{{ scope.row.goods_name }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="size" label="商品属性" align="center">
						<template slot-scope="scope">
							<div v-for="(item,index) in scope.row.one_specification" :key="index">
								<span>{{item.name}}{{item.value}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="goods_price" label="商品单价" align="center">
					</el-table-column>
					<el-table-column label="支付方式" align="center">
						<span>微信支付</span>
					</el-table-column>
					<el-table-column prop="goods_count" label="购买数量" align="center">
					</el-table-column>
					<el-table-column prop="states" label="订单状态" align="center">
						<template slot-scope="scope">
							<div>
								<p v-if="scope.row.order_status === 1">
									<!-- 待付款 -->
									<span>待付款 </span>
									<br />
									<el-tag style="margin-top: 5px; cursor: pointer;" size="small"
										@click="closeOrder()">取消订单</el-tag>
								</p>
								<p v-else-if="scope.row.order_status === 2">
									<!-- <span>待发货 </span> -->
									<span>已付款，待配送</span>
									<br />
									<el-tag style="margin-top: 5px; cursor: pointer;" size="small"
										@click="shipments()">发货</el-tag>
								</p>
								<p v-else-if="scope.row.order_status === 3">
									<span>待收货 </span>
								</p>
								<p v-else-if="scope.row.order_status === 4">
									<span>已完成 </span>
								</p>
								<p v-else-if="scope.row.order_status === 5">
									<span>已关闭 </span>
	
								</p>
								<p v-else-if="scope.row.order_status === 6">
									<span>已退款 </span>
								</p>
								<p v-else-if="scope.row.order_status === 8">
									<span>已收货 </span>
								</p>
								<p v-else>
									<span>已取消 </span>
								</p>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="actual_payment" label="付款金额" align="center">
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
	<!--添加备注弹框-->
	<el-dialog title="添加备注" :visible.sync="addDescModel" width="25%">
		<el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="descInfo">
		</el-input>
		<div class="submitBtn">
			<el-button size="small" @click="addDescModel=false">取消</el-button>
			<el-button size="small" type="primary" @click="onSubmit">提交</el-button>
		</div>
	</el-dialog>
	<!--物流发货弹框-->
	<el-dialog title="物流发货" :visible.sync="logisticsModel" width="40%" @close="closeForm">
		<el-form ref="logisticsForm" :model="logisticsForm" :rules="logisticsRules" label-width="100px"
			size="small">
			<el-form-item label="物流单号:" prop="oddNum">
				<el-input class="inputWidth" v-model="logisticsForm.oddNum" placeholder="请填写物流单号"></el-input>
			</el-form-item>
			<el-form-item label="物流公司:" prop="company">
				<el-select v-model="logisticsForm.company">
					<el-option v-for="item in logisticData" :key="item.id" :value="item.id" :label="item.name">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<div class="submitBtn">
			<el-button size="small" @click="cancelLogistic('logisticsForm')">取消</el-button>
			<el-button size="small" type="primary" @click="onSubmitLogistic('logisticsForm')">提交</el-button>
		</div>
	</el-dialog>
	</div>
</template>

<script>
	import {
		orderDetail,
		deliverGoods,
		getExpressList,
		closeOrders,
		editAdminOrdersInfo,
		getOrderTypeNumbers
	} from '@/api/order.js'
	export default {
		props:{
			order_type:String
		},
		data() {
			return {
				addDescModel: false,
				logisticsModel: false,
				logisticData: [],
				activeName: 'first',
				invitation_code:'',
				nickname:'',
				descInfo: '', //备注信息
				formstate: {
					orderNum: '',
					kd: "",
					kdnum: '',
					name: '',
					phone: '',
					address: '',
					desc: '',
				},
				formpay: {
					freight: '',
					pay: "",
					serialNum: '',
					seller_note: '',
					
				},
				tableData: [],
				logisticsForm: {
					oddNum: '',
					company:''
				},
				logisticsRules: {
					oddNum: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}, ],
					company: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}]
				},
				operation_time:[],
				multipleSelection: [],
				id:'',
				orderTypeNum:{
					close_number: 0,
					completed: 0,
					refund_number: 0,
					to_be_deliverd_number: 0,
					to_be_received: 0,
					unpaid_number: 0,
					whole_order_number: 0,
				},
				created_at:'',
				tableData: [],
				allData: {
							actual_payment: "218.50",
							address: {
								account_id: "3a97747665c811eb9b1b02424d14ae90",
								address_detail: "美哉美城",
								address_id: 2147483647,
								city: "杭州市",
								consignee_name: "郭洋",
								consignee_phone: "13693584341",
								county: "萧山区",
								province: "浙江省",
							},
							goods: [{
								goods_actual_payment: "230.00",
								goods_commodity_id: "58217025399df09b1923b3ad74fbb5de",
								goods_count: 1,
								goods_name: "斗篷秋冬汉服女成人古装白色红色加绒加厚披肩外套中长款纳兰词刺绣 米白色斗篷 有毛领 加绒",
								goods_price: "230.00",
								master_map: "https://socialcontactoss.gcaimall.com/-02.jpg",
							}],
							invitation_user: {
								invitation_code: "",
								nickname: "",
							},
							operation_time: [
								{
									name: "下单时间",
									time: "2021-03-18 11:01:50",
								},
								{
								name: "下单时间",
								time: "2021-03-18 11:01:50",
								},
								{
									name: "商家发货",
									time: "",
								},
								{
									name: "确认收货",
									time: "",
								},
								{
									name: "交易完成",
									time: "",
								},
						],
						order: {
							actual_payment: "218.50",
							address_id: 2147483647,
							buyer_id: "3a97747665c811eb9b1b02424d14ae90",
							buyer_name: "般无",
							complete_time: "",
							courier_code: "",
							created_at: "2021-03-18 11:01:50",
							delivery_time: "",
							expire_time: 1616126530,
							freight: "0.00",
							gross_amount: "230.00",
							is_after_sale: 0,
							logistics_name: "",
							logistics_phone: null,
							order_source: 2,
							order_status: 1,
							orders_number: "GOODS202103181101505510901",
							pattern_payment: 0,
							pay_order_sn: "0",
							receiving_time: "",
							seller_note: "",
							time_payment: "",
						},
						pursue_reason: "",
					total_goods_money: 230,
					total_order_money: "230.00",
				
				},
				
			}
		},
		mounted() {
			this.orderDetailData();
			
		},
		methods: {

			//物流接口
			getExpressListData() {
				// let data = {}
				// getExpressList(data).then(res => {
				// 	if (res.return_code == 'SUCCESS') {
				// 		this.logisticData = res.data;
				// 	}

				// }).catch({})
			},
			//订单详情
			orderDetailData() {
				//console.log(this.tableData,"3333")
				this.tableData=this.allData.goods;
				this.tableData.forEach(tab => {
					tab.order_status = this.allData.order.order_status;
					tab.actual_payment = this.allData.order.actual_payment;
				});
				this.created_at = this.allData.order.created_at;
				this.operation_time = this.allData.operation_time;
				this.formstate.kd = this.allData.order.logistics_name;
				this.formstate.desc = this.allData.pursue_reason;
				this.formstate.orderNum = this.id;
				this.formstate.kdnum = this.allData.order.courier_code;
				this.formstate.phone = this.allData.address.consignee_phone;
				this.formstate.name = this.allData.address.consignee_name;
				this.formstate.address = this.allData.address.province +this.allData.address.city + this.allData.address
					.county + this.allData.address.address_detail;
				this.formpay.freight = this.allData.order.freight;
				this.formpay.pay = this.allData.order.gross_amount;
				this.formpay.serialNum = this.allData.order.pay_order_sn;
				this.formpay.seller_note = this.allData.order.seller_note
				
				
				
				// let data = {
				// 	orders_number: this.id
				// }
				// data = this.tools.filterParams(data);
				// orderDetail(data).then(res => {
				// 		if (res.return_code === 'SUCCESS') {
				// 			this.tableData=res.data.goods;
							
				// 		this.tableData.forEach(tab => {
				// 			tab.order_status = res.data.order.order_status;
				// 			tab.actual_payment = res.data.order.actual_payment;
				// 		});
				// 		this.created_at = res.data.order.created_at;
				// 		this.operation_time = res.data.operation_time;
				// 		this.formstate.kd = res.data.order.logistics_name;
				// 		this.formstate.desc = res.data.pursue_reason;
				// 		this.formstate.orderNum = this.id;
				// 		this.formstate.kdnum = res.data.order.courier_code;
				// 		this.formstate.phone = res.data.address.consignee_phone;
				// 		this.formstate.name = res.data.address.consignee_name;
				// 		this.formstate.address = res.data.address.province + res.data.address.city + res.data.address
				// 			.county + res.data.address.address_detail;
				// 		this.formpay.freight = res.data.order.freight;
				// 		this.formpay.pay = res.data.order.gross_amount;
				// 		this.formpay.serialNum = res.data.order.pay_order_sn;
				// 		this.formpay.seller_note = res.data.order.seller_note
				// 	}
				// })
		},
		//tab切换
		handleClick(tab) {
			//商户自营订单
			if(this.order_type == 'merchant-self'){
				this.$router.push({
					path: '/ordergoodadmin/selfsupportorder/selfsupportorderlist',
					query: {
						name: tab.name
					}
				})
			}
			//商户供货订单
			if(this.order_type == 'merchant-supply'){
				this.$router.push({
					path: '/ordergoodadmin/supplierorder/supplierorderlist',
					query: {
						name: tab.name
					}
				})
			}
			//平台供货订单
			if(this.order_type == 'plat-supply'){
				this.$router.push({
					path: '/orderadmin/supplyorder/orderlist',
					query: {
						name: tab.name
					}
				})
			}
			//平台商户订单
			if(this.order_type == 'plat-merchant'){
				this.$router.push({
					path: '/orderadmin/merchantorder/orderlist',
					query: {
						name: tab.name
					}
				})
			}
		},
		//添加备注
		addDesc() {
			this.descInfo='';
			this.addDescModel = true
		},
		//提交新增备注
		onSubmit() {
			// if (!this.descInfo) {
			// 	this.$message.error("请输入备注信息！")
			// } else {
			// 	//调接口
			// 	let data = {
			// 		orders_number: this.id,
			// 		seller_note: this.descInfo
			// 	}
			// 	data = this.tools.filterParams(data);
			// 	editAdminOrdersInfo(data).then(res => {
			// 		if (res.return_code === 'SUCCESS') {
			// 			this.addDescModel = false;
			// 			this.descInfo = '';
			// 			this.orderDetailData();
			// 		}
			// 	})
			// }
		},

		//发货
		shipments() {
			this.logisticsForm.oddNum='';
			this.logisticsForm.company = '';
			this.logisticsModel = true;
		},
		//全选
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		closeForm() {
			this.logisticsModel = false;
			this.logisticsForm.oddNum = '',
				this.logisticsForm.company = ''
		},
		cancelLogistic(formName) {
			this.logisticsModel = false;
			this.$refs[formName].resetFields();
		},
		//提交物流
		onSubmitLogistic(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					//调接口
					// let data = {
					// 	orders_number: this.orderNo,
					// 	number: this.logisticsForm.oddNum,
					// 	type: this.logisticsForm.company.toString()
					// }
					// data = this.tools.filterParams(data);
					// deliverGoods(data).then(res => {
					// 	if (res.return_code === 'SUCCESS') {
					// 		this.$message({
					// 			message: '发货成功',
					// 			type: 'success'
					// 		});
					// 		this.logisticsModel = false;
					// 		this.logisticsForm.oddNum = '';
					// 		this.logisticsForm.company = '';
					// 	}

					// }).catch({})
				} else {
					return false;
				}
			});
		},
		//取消订单
		closeOrder(row) {
			this.$confirm('确定要取消此订单吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				//调接口
				// let data = {
				// 	orders_number: this.formstate.orderNum
				// }
				// data = this.tools.filterParams(data);
				// closeOrders(data).then(res => {
				// 	if (res.return_code == 'SUCCESS') {
				// 		this.$message({
				// 			message: '取消成功',
				// 			type: 'success'
				// 		});
				// 		this.orderDetailData();
				// 	}
				// })
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				})
			})
		},
	}

	}
</script>

<style scoped lang="scss">

	@import "@/assets/css/plat/allorderdetail.scss";

	.order-info-item::v-deep.el-form-item {
		margin-bottom: 0;
	}
	.pds{
		padding-bottom: 120px !important;
	}
	
</style>
