<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<div class="all_search_list" style="margin-bottom: 0;">
						<div class="demo-input-suffix">
							<el-select v-model="search.account_type" size="small" placeholder="用户类型">
								<el-option label="服务商" value="1"></el-option>
								<el-option label="门店" value="2"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-select v-model="search.source_type" size="small" placeholder="佣金类型">
								<el-option label="商品佣金" value="1"></el-option>
								<el-option label="店长订单" value="2"></el-option>
								<el-option label="插件佣金" value="3"></el-option>
							</el-select>
						</div>
						<!-- <div class="demo-input-suffix" style="margin-right: 0;">
							<el-select style="width: 120px;" v-model="search.keyword_state" size="small" placeholder="请选择">
								<el-option label="请选择" value=""></el-option>
								<el-option label="订单编号" value="1"></el-option>
								<el-option label="账户" value="2"></el-option>
							</el-select>
						</div> -->
						<div class="demo-input-suffix">
							<!-- <el-input prefix-icon="el-icon-search" v-model="search.keyword"
								:placeholder="search.keyword_state == ''?'请选择':search.keyword_state ==1 ?'订单编号':'账户'" size="small"
								type="text">
							</el-input> -->
							<el-input @keyup.enter.native="toSearch" clearable :placeholder="search.keyword_state == ''?'请选择':search.keyword_state ==1 ?'订单编号':'账户'" size="small" v-model="search.keyword" class="input-with-select">
							    <el-select class="selectvalinput" v-model="search.keyword_state" slot="prepend" placeholder="请选择">
							      <el-option label="请选择" value=""></el-option>
							      <el-option label="订单编号" value="1"></el-option>
							      <el-option label="账户" value="2"></el-option>
							    </el-select>
							   <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
							  </el-input>
						</div>
						<div class="demo-input-suffix" style="margin-right: 8px;">
							<span style="width:80px;display: block;line-height: 32px;">创建时间：</span>
						</div>
						<div class="demo-input-suffix" style="font-size: 13px;">
							<el-date-picker style="width: 300px!important" size="small" v-model="dute" :clearable="false" type="daterange"
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
								@change="changetimer"></el-date-picker>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
					</div>
				</div>
				<div class="main-cont bgwt mt10">
					<el-table :data="tableData" border style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="usertype" label="用户类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.account_type ==1">服务商</span>
								<span v-else>门店</span>
							</template>
						</el-table-column>
						<el-table-column prop="account" label="账户" align="center">
						</el-table-column>
						<el-table-column prop="shop_name" label="姓名" align="center">
						</el-table-column>
						<el-table-column prop="commissiontype" label="佣金类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.source_type ==1">商品佣金</span>
								<span v-else-if="scope.row.source_type ==2">店长订单</span>
								<span v-else-if="scope.row.source_type ==3">插件佣金</span>
							</template>
						</el-table-column>
						<el-table-column prop="amount" label="实收金额" align="center">
						</el-table-column>
						<el-table-column prop="service_fee" label="手续费" align="center">
						</el-table-column>
						<el-table-column prop="reason" show-overflow-tooltip label="备注信息" align="center">
						</el-table-column>
						<el-table-column prop="examined_at" label="下单时间" align="center"></el-table-column>
					</el-table>
					<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
			platformFenRunEarningsList
		} from '@/api/plat/finance'
	export default {
		data() {
			return {
				loading:false,
				search: {
					account_type: '',
					source_type: '',
					keyword_state: '',
					keyword: '',
					start_time: '',
					end_time: ''
				},
				dute:'',
				tableData:[],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList(){
				let data=this.search;
				data.page=this.currentPage;
				data.perPage=this.pagesize;
				data=this.tools.filterParams(data)
				platformFenRunEarningsList(data).then(res=>{
					this.total=res.data.count;
					this.tableData=res.data.list;
				})
			},
			
			//时间选择
			changetimer(val) { //时间
				 this.search.start_time = val[0].getTime()/1000;
				 this.search.end_time = val[1].getTime()/1000
			},
			handleSizeChange(size) {
				this.pagesize = size;
				//调接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				//调接口
				this.getList()
			},
			//搜索
			toSearch(){
				this.currentPage = 1;
				//调接口
				this.getList()
			},
			//重置
			toReset(){
				this.currentPage = 1;
				this.search.account_type='';
				this.search.source_type='';
				this.search.keyword_state='';
				this.search.keyword='';
				 this.search.start_time='';
				 this.search.end_time='';
				this.dute='';
				//调接口
				this.getList()
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">

	::v-deep.selectvalinput .el-input {
	    width: 130px!important;
	  }
	  ::v-deep.input-with-select .el-input-group__prepend {
	    background-color: #fff;
	  }
</style>
