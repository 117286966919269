<template>
	<div>
		<div class="wrapper-main" style="padding:0px">
			<div class="wrapper-cont">
		<!--订单统计-->
		<div class="data-cont ">
			<el-row type="flex" class="row-bg" justify="space-between">
				<el-col :span="6">
					<div class="grid-content bg-purple">实时概况</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple"><span class="tims">更新时间：{{collect.updateTime}}</span></div>
				</el-col>
			</el-row>
			<el-row type="flex" class="row-bg" justify="space-around" style="margin-top:40px">
				<el-col :span="6">
					<div class="grid-content bg-purple flex-align-center">
						<div class="icons">
							<img src="../../assets/img/merchant/icons/index_position01.png" />
						</div>
						<div class="datacount">
							<div>总计销售额(元)</div>
							<div class="amount">{{collect.sales}}</div>
							<div>昨日：{{collect.yesterdaySales}}</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple flex-align-center">
						<div class="icons">
							<img src="../../assets/img/merchant/icons/index_position02.png" />
						</div>
						<div class="datacount">
							<div>支付订单数</div>
							<div class="amount">{{collect.saleNum}}</div>
							<div>昨日：{{collect.yesterdaySalesNum}}</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple flex-align-center">
						<div class="icons">
							<img src="../../assets/img/merchant/icons/index_position03.png" />
						</div>
						<div class="datacount">
							<div>新增客户数</div>
							<div class="amount">{{collect.customerNum}}</div>
							<div>昨日：{{collect.newCustomerNum}}</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<el-row style="margin:10px 0px">
			<el-col :span="16">
				<div class="grid-content bg-purple">
					<div class="data-cont mr10">
						<div class="title mt10">重要提醒</div>
						<div class="type mt40">订单相关</div>
						<el-row type="flex" class="row-bg mt10" justify="space-between">
							<el-col :span="6">
								<div class="grid-content bg-purple">
									<div class="order_amount mb10">{{collect.undeliveredNum}}</div>
									<div class="order_state">待发货订单</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div class="grid-content bg-purple-light">
									<div class="order_amount mb10">{{collect.unreceivedNum}}</div>
									<div class="order_state">待收货订单</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div class="grid-content bg-purple">
									<div class="order_amount mb10">{{collect.afterSaleNum}}</div>
									<div class="order_state">待处理售后</div>
								</div>
							</el-col>
						</el-row>
						<div class="type mt40">商品相关</div>
						<el-row type="flex" class="row-bg mt10" justify="space-between">
							<el-col :span="6">
								<div class="grid-content bg-purple">
									<div class="order_amount mb10">{{collect.onSaleNum}}</div>
									<div class="order_state">网店在售</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div class="grid-content bg-purple-light">
									<div class="order_amount mb10">{{collect.selloutNum}}</div>
									<div class="order_state">网店售空</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div class="grid-content bg-purple">
									<div class="order_amount mb10">{{collect.stockAlarmNum}}</div>
									<div class="order_state">库存预警</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="grid-content bg-purple">
					<div class="data-cont ml0 pp20">
						<div>起止时间</div>
						<div class="mt20">开始时间：{{collect.beginTime}} </div>
						<div class="mt20">结束时间：{{collect.endTime}}</div>
					</div>
					<div class="data-cont ml0 mt10 pp20">
						<div>区域负责人</div>
						<div class="mt20">称呼：{{collect.username}} </div>
						<div class="mt20">联系电话：{{collect.phone}} </div>
					</div>
				</div>
			</el-col>
		</el-row>
		<!--销售商品榜单-->
		<div class="data-cont">
			<el-row type="flex" class="row-bg flex-align-center" justify="space-between">
				<el-col :span="6">
					<div class="grid-content bg-purple">
						<div class="dataTitle">销售商品榜单</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple" style="text-align: right;">
						<el-radio-group v-model="tabTable" size="small" @change="changeType">
							<el-radio-button label="week">本周</el-radio-button>
							<el-radio-button label="month">本月</el-radio-button>
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
			<el-tabs>
				<el-table :data="tableData" style="width: 100%" stripe border class="table_one"
					@sort-change="sortChange">
					<el-table-column type="index" width="50" align="center" label="排名">
					</el-table-column>
					<el-table-column min-width="200" label="商品名称" align="left">
						<template slot-scope="scope">
							<div class="el_names">
								<img v-if="scope.row.image" :src="scope.row.image.split(',', 1)" class="el_img" />
								<div>{{ scope.row.goodsName }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="cost" label="结算价" sortable="cost" align="center">
						 </el-table-column>
					 <el-table-column prop="stock" label="总库存" sortable="stock" align="center">
					</el-table-column>
					<el-table-column label="实际销量" align="center" prop="salesVolume" sortable="salesVolume">
					</el-table-column>
					<el-table-column label="成交额" prop="sales" align="center" sortable="sales">
						<template slot-scope="scope">
							<div style="color: #0091ff">{{ scope.row.sales }}</div>
						</template>
					</el-table-column>
				</el-table>
			</el-tabs>
		</div>
	</div>
	</div>
	</div>
</template>

<script>
	import {
		statistics,
		goodsSaleData
	} from '@/api/merchant/index'
	export default {
		data() {
			return {
				tabTable: 'week',
				field: '',
				sort: '',
				collect: {},
				tableData: []
			}
		},
		mounted() {
			this.getStatistics();
			this.getGoods()
		},
		methods: {
			//获取数据
			getStatistics() {
				let params = {}
				statistics(params).then(res => {
					this.collect = res.data;
				})

			},
			//销售榜单
			getGoods() {
				let params = {
					filter: this.tabTable,
					field: this.field,
					sort: this.sort
				}
				params = this.tools.filterParams(params)
				goodsSaleData(params).then(res => {
					this.tableData = res.data
				})

			},
			//切换榜单
			changeType(val) {
				this.tabTable = val;
				this.getGoods()

			},
			//排序
			sortChange(column) {
				if (column.order == "ascending") {
					this.sort = "0";
					this.field = column.prop

				} else if (column.order == "descending") {
					this.sort = "1";
					this.field = column.prop
				}
				this.getGoods()
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../assets/css/merchantdashboard.scss";
</style>
