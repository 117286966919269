<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="order-search" style="margin-top: 0;">
					<el-row class="all_search_list">
						<div class="demo-input-suffix">
							<el-input prefix-icon="el-icon-search" clearable v-model="search.search" 
								placeholder="客户名称/联系电话"
								size="small" type="text"  @keyup.enter.native="toSearch" >
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-select style="width: 120px;" size="small" placeholder="机会来源" 
								v-model="search.customer_source">
								<el-option label="平台创建" value="1"></el-option>
								<el-option label="网上注册" value="2"></el-option>
								<el-option label="服务商创建" value="3"></el-option>
							</el-select>
						</div>
						<!-- <div class="demo-input-suffix">
							<el-select style="width: 120px;" size="small" placeholder="是否跟进" 
								v-model="search.is_follow_up">
								<el-option label="是" value="1"></el-option>
								<el-option label="否" value="2"></el-option>
							</el-select>
						</div> -->
						<div class="demo-input-suffix" style="font-size: 13px;">
							<div class="block">
								<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
									range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
									@change="changetimer"></el-date-picker>
							</div>
						</div>
						<div class="demo-input-suffix">
							<el-select style="width: 120px;" size="small" placeholder="客户行业"
								v-model="search.industry_type">
								<el-option v-for="items in industry" :key="items.marketing_trade_id" :label="items.trade_name"
									:value="items.marketing_trade_id">
								</el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-select style="width: 120px;" size="small" placeholder="客户状态" 
								v-model="search.is_urgent">
								<el-option label="不加急" value="1"></el-option>
								<el-option label="加急" value="2"></el-option>
							</el-select>
						</div>
					</el-row>
					<el-row class="all_search_list" style="margin-bottom:0">
						<el-button type="primary" size="small" @click="toAdd()">创建机会</el-button>
						<el-button type="primary" size="small" @click="toSearch">查询</el-button>
						<el-button size="small" @click="toReset">重置</el-button>
					</el-row>
				</div>
				<!--表格-->
				<div class="tableMain">
					<div class="tableTile">客户列表</div>
					<el-table :data="tableData" style="width: 100%" min-height="250" border  max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="created_at" label="创建日期" align="center">
						</el-table-column>
						<el-table-column  label="门店类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_entity == 1">线上门店</span>
								<span v-else>实体门店</span>
							</template>
						</el-table-column>
						<el-table-column prop="address" label="地址信息" align="center">
						</el-table-column>
						<el-table-column prop="address" label="是否付费" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_pay == 1">待付款</span>
								<span v-else-if="scope.row.is_pay == 2">已支付</span>
							</template>
						</el-table-column>
						<el-table-column prop="username" label="客户名称" align="center">
						</el-table-column>
						<el-table-column prop="phone" label="客户联系电话" align="center">
						</el-table-column>
						<el-table-column prop="follow_remark" label="跟进内容" align="center">
						</el-table-column>
						<el-table-column  label="来源类别" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.customer_source == 1">平台创建</span>
								<span v-else-if="scope.row.customer_source == 2">网上注册</span>
								<span v-else>服务商创建</span>
							</template>
						</el-table-column>
						<el-table-column fixed="left" label="操作" width="180" align="center">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toEdit(scope.row)">
									编辑
									
								</el-button>
								<el-button type="text" size="small" @click="toDetail(scope.row)">
									详情
								</el-button>
								<el-button v-if="scope.row.is_pay==1" type="text" size="small" @click="toUpgrade(scope.row)">
									升级
								</el-button>
								<el-tag type="danger" size="small" v-if="scope.row.is_urgent == 2">加急</el-tag>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	import {
		businessIncubatorList,
		getTradeList
	} from "@/api/channel/business"
	export default {
		data() {
			return {
				loading:true,
				search: {
					search: '',
					customer_source: '',
					is_follow_up: '',
					begin_time: '',
					end_time: '',
					industry_type: '',
					is_urgent: ''
				},
				industry: [],
				address: '',
				options: regionData,
				province: '',
				city: '',
				country: '',
				due_dt: '',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				show: false
			}
		},
		mounted() {
			this.getTradeData()
			this.getList();
			
		},
		methods: {
			/** 行业列表 */
			getTradeData(){
				getTradeList({}).then(res => {
					this.industry=res.data;
				})
			},
			/** 初始化 */
			getList() {
				this.tableData = []
				let params = this.search;
				params.page=this.currentPage;
				params.perPage=this.pagesize
				params = this.tools.filterParams(params)
				businessIncubatorList(params).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list;
					this.accountcounts=res.data.account_count;
					this.tableData.forEach(item=>{
						item.address = item.shop_province+item.shop_city+item.shop_county+item.shop_address_detail;
						// item.account_shop_info.begin_time = this.tools.timeFormate(item.account_shop_info.begin_time*1000);
						// item.account_shop_info.end_time = this.tools.timeFormate(item.account_shop_info.end_time*1000)
					})
				})
			},
			//搜索
			toSearch() {
				this.currentPage = 1;
				this.getList()
			},
			//重置
			toReset() {
				this.currentPage = 1;
				this.search.search = '';
				this.search.customer_source = '';
				this.search.is_follow_up = '';
				this.due_dt = '';
				this.search.industry_type = '';
				this.search.is_urgent = '';
				this.address = '';
				this.search.begin_time = ''
				this.search.end_time = ''
				this.getList()
			},
			//地址
			handleChange(area) {
				this.province = area[0];
				this.city = area[1];
				this.country = area[2];
			},
			//时间搜索
			changetimer(val) { //时间
				this.search.begin_time = val[0] / 1000;
				this.search.end_time = val[1] / 1000;
			},
			//分页
			handleSizeChange(size) {
				this.currentPage = 1
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
			//新增
			toAdd() {
				this.$router.push({
					path: "/businessadmin/businessincubator/incubatoradd",
				});
			},
			//详情
			toDetail(row) {
				this.$router.push({
					path: "/businessadmin/businessincubator/incubatordetails",
					query: {
						id: row.business_incubator_id,
						name: row.username
					}
				});
			},
			//编辑
			toEdit(row) {
				this.$router.push({
					path: "/businessadmin/businessincubator/incubatoradd",
					query: {
						id: row.business_incubator_id
					}
				});
			},
			//升级
			toUpgrade(row) {
				this.$router.push({
					path: "/businessadmin/businessincubator/clientupgrade",
					query: {
						id: row.business_incubator_id
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
