import axios from '@/config/httpConfig'
import qs from  'qs'
// 订单-虚拟订单列表
export const VirtualOrderList=(params)=> {
  return axios('get','/Order/channel/VirtualOrderList', params)
}
//储值套餐下拉列表
export const petCardList=(params)=> {
  return axios('get','/Account/channel/petCardList', params)
}
//订单-创建虚拟订单
export const rechargeAmount=(data)=> {
    return axios('post','/Order/channel/rechargeAmount', data);
}
//订单-虚拟订单查找支付状态
export const getVirtualOrderStatus=(params)=> {
  return axios('get','/Order/channel/getVirtualOrderStatus', params)
}