<template>
	<div>
		<OrderDetailModule></OrderDetailModule>
	</div>
</template>

<script>
	import OrderDetailModule from "../../../../../pagecomponents/orderDetailModule.vue"
	export default{
		components:{
			OrderDetailModule
		},
		data(){
			return{
				
			}
		}
	}
</script>

<style scoped lang="scss">

</style>
