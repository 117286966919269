<template>
	<div>
		<div class="wrapper-main pds">
			<el-form :model="form" :rules="rules" ref="form" label-width="150px" class="demo-ruleForm">
				<div class="wrapper-cont">
					<div class="pmargin10">
						<div class="setTitle">基础信息</div>
						<el-row :gutter="20">
							<el-col :span="6">
								<div class="grid-content bg-purple">
									<el-form-item label="标识码:" prop="identification_code">
										<el-input type="text" class="inputWidth" placeholder="请输入标识码" size="small"
											v-model="form.identification_code"></el-input>
									</el-form-item>
								</div>
							</el-col>
							<el-col :span="6" :offset="6">
								<div class="grid-content bg-purple">
									<el-form-item label="手机号:" prop="account">
										<el-input type="text" class="inputWidth" maxlength="11" placeholder="请输入手机号"
											size="small" v-model="form.account"></el-input>
									</el-form-item>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="6">
								<div class="grid-content bg-purple">
									<el-form-item label="登录密码:">
										<el-input type="password" class="inputWidth" maxlength="16"
											placeholder="请输入登录密码" size="small" v-model="form.passwords"></el-input>
									</el-form-item>
								</div>
							</el-col>
							<el-col :span="6" :offset="6">
								<div class="grid-content bg-purple">
									<el-form-item label="确认密码:">
										<el-input type="password" class="inputWidth" maxlength="16"
											placeholder="请输入确认密码" size="small" v-model="form.surepassword"></el-input>
									</el-form-item>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="wrapper-cont mt10">
					<div class="pmargin10">
						<div class="setTitle">业务信息</div>
						<el-form-item label="服务商名称:" prop="username">
							<el-input type="text" class="inputWidth" placeholder="请输入服务商名称" size="small"
								v-model="form.username"></el-input>
						</el-form-item>
						<el-form-item label="服务商所属地区:" prop="address">
							<el-cascader size="small" placeholder="服务商所属地区" style="width:300px;" :options="options"
								collapse-tags :props="{multiple: false }" clearable @change="handleChange"
								v-model="form.address" ref="cascader">
							</el-cascader>
						</el-form-item>
					</div>
				</div>
				<div class="wrapper-cont mt10">
					<div class="pmargin10">
						<div class="setTitle">等级信息</div>
						<el-form-item label="服务商等级:" prop="channel_level">
							<el-select size="small" v-model="form.channel_level" class="inputWidth" placeholder="请选择">
								<el-option v-for="(item,index) in levelList" :key="index" :label="item.channel_name"
									:value="item.unique_identification"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="实付金额:" prop="opening_amount">
							<el-input type="text" class="inputWidth" placeholder="请输入实付金额" size="small"
								v-model.number="form.opening_amount"></el-input>
						</el-form-item>
						<el-form-item label="起止时间:" prop="due_dt">
							<el-date-picker style="width: 350px" size="small" unlink-panels v-model="form.due_dt" type="daterange"
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
								@change="changetimer"></el-date-picker>
							<el-button style="margin-left:10px" type="text" @click="toRenew" v-if="account_channel_id">
								续费</el-button>
						</el-form-item>
					</div>
				</div>
				<div class="wrapper-cont mt10">
					<div class="pmargin10">
						<div class="setTitle">联系信息</div>
						<el-form-item label="企业联系人:" prop="contact_name">
							<el-input type="text" class="inputWidth" placeholder="请输入企业联系人" size="small"
								v-model="form.contact_name"></el-input>
						</el-form-item>
						<el-form-item label="手机号:" prop="contact_phone">
							<el-input type="text" class="inputWidth" maxlength="11" placeholder="请输入手机号" size="small"
								v-model.number="form.contact_phone"></el-input>
						</el-form-item>
					</div>
				</div>
			</el-form>
		</div>
		<div class="save-cont">
			<div class="cancel-btn" @click="toBack">返回上一页</div>
			<div class="save-btn" @click="submitSet('form')">保存</div>
		</div>
		<!--续费弹框-->
		<el-dialog title="代理等级续费" width="30%" :visible.sync="dialogVisible">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="服务商等级:" prop="level_id">
					<el-select size="small" v-model="ruleForm.level_id" class="inputWidth" placeholder="请选择" @change="levelChange">
						<el-option v-for="(item,index) in levelList" :key="index" :label="item.channel_name"
							:value="item.channel_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="等级原价:">
					<el-input  class="inputWidth" size="small" v-model="ruleForm.original_price" :disabled="true">
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
				<el-form-item label="实付金额:" prop="money">
					<el-input placeholder="请输入金额"  class="inputWidth" size="small" v-model="ruleForm.money" >
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
				<el-form-item label="起止时间:">
					<el-date-picker style="width: 350px" size="small" v-model="ruleForm.renew_dt" :disabled="true"
						type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button size="small" @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" size="small" @click="submitForm('ruleForm')">确认</el-button>
				</el-form-item>
			</el-form>

		</el-dialog>
	</div>
</template>

<script>
	import {
		channelAgentLevel,
		createOrUpChannelAccount,
		getChannelAccountInfo,
		channelRenew
	} from '@/api/plat/business'
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	const sha256 = require('js-sha256').sha256;
	import {
		isvalidPhone
	} from '@/utils/validate'
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入电话号码'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		data() {
			return {
				form: {
					identification_code: '',
					account: '',
					passwords: '',
					surepassword: '',
					username: '',
					address: [],
					channel_level: '',
					opening_amount: '',
					due_dt: '',
					contact_phone: '',
					contact_name: '',
					province_code: '',
					city_code: '',
					county_code: '',
					province: '',
					city: '',
					county: '',
					begin_time: '',
					end_time: '',
				},
				options: regionData,
				rules: {
					identification_code: [{
						required: true,
						message: '请输入标识码',
						trigger: 'blur'
					}],
					account: [{
							required: true,
							trigger: 'blur',
							validator: validPhone
						}, //这里需要用到全局变量
					],
					passwords: [{
						required: true,
						message: '请输入登录密码',
						trigger: 'blur'
					}],
					surepassword: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}],
					username: [{
						required: true,
						message: '请输入服务商名称',
						trigger: 'blur'
					}],
					opening_amount: [{
						required: true,
						message: '请输入实付金额',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '服务商所属地区',
						trigger: 'change'
					}],
					channel_level: [{
						required: true,
						message: '服务商等级',
						trigger: 'change'
					}],
					due_dt: [{
						required: true,
						message: '启用时间',
						trigger: 'change'
					}],
					contact_phone: [{
							required: true,
							trigger: 'blur',
							validator: validPhone
						}, //这里需要用到全局变量
					],
					contact_name: [{
						required: true,
						message: '请输入企业联系人',
						trigger: 'blur'
					}],
				},
				levelList: [],
				account_channel_id: this.$route.query.id,
				dialogVisible: false,
				ruleForm: {
					level_id: '',
					original_price: '',
					money:'',
					renew_dt:''
				},
				rules: {
					level_id: [{
						required: true,
						message: '选择等级',
						trigger: 'change'
					}],
					money: [{
						required: true,
						message: '请输入实付金额',
						trigger: 'blur'
					}],
				}
			}
		},
		mounted() {
			this.getChannelAgentLevel();
			if (this.account_channel_id) {
				this.getInfo();
			}
		},
		methods: {
			getInfo() {
				let data = {
					account_channel_id: this.account_channel_id
				}
				getChannelAccountInfo(data).then(res => {
					this.form = res.data;
					this.form.account = Number(this.form.account);
					this.form.contact_phone = Number(this.form.contact_phone);
					let addressData;
					addressData = res.data.province_code + ',' + res.data.city_code + ',' + res.data.county_code;
					addressData = addressData.split(",");
					this.form.address = addressData;
					// this.form.due_dt = [this.tools.timeFormate(res.data.begin_time * 1000), this.tools.timeFormate(
					// 	res.data.end_time * 1000)];
					this.$set(this.form, "due_dt", [this.tools.timeFormate(res.data.begin_time * 1000), this.tools
						.timeFormate(
							res.data.end_time * 1000)
					]);

				})
			},
			//获取服务商等级
			getChannelAgentLevel() {
				let data = {}
				channelAgentLevel(data).then(res => {
					this.levelList = res.data
				})
			},
			//地址
			handleChange(area) {
				this.form.province_code = area[0];
				this.form.city_code = area[1];
				this.form.county_code = area[2];
				this.form.province = CodeToText[this.form.province_code];
				this.form.city = CodeToText[this.form.city_code];
				this.form.county = CodeToText[this.form.county_code];
			},
			//时间搜索
			changetimer(val) { //时间
				this.form.begin_time = val[0] / 1000;
				this.form.end_time = val[1] / 1000;
			},
			//续费
			toRenew() {
				this.ruleForm.money='';
				this.dialogVisible = !this.dialogVisible;
				this.levelList.forEach(item=>{
					if(this.form.channel_level == item.unique_identification){
						this.ruleForm.level_id=item.channel_id
					}
				})
				if(this.ruleForm.level_id==1){
					this.ruleForm.original_price='2万'
				}else if(this.ruleForm.level_id==2){
					this.ruleForm.original_price='5万'
				}else if(this.ruleForm.level_id==3){
					this.ruleForm.original_price='10万'
				}else if(this.ruleForm.level_id==4){
					this.ruleForm.original_price='20万'
				}else if(this.ruleForm.level_id==5){
					this.ruleForm.original_price='50万'
				}
				let startTime=this.form.due_dt[1] //结束时间;
				let dueTime=startTime.split('-')//结束时间分割;
				let dueEnd=[((dueTime[0])*1+(1*1)).toString(),dueTime[1],dueTime[2]];//结束时间年+1组合时间
				let endTime=dueEnd.join('-');
				this.ruleForm.renew_dt=[startTime,endTime];
			},
			levelChange(val){
				if(val==1){
					this.ruleForm.original_price='2万'
				}else if(val==2){
					this.ruleForm.original_price='5万'
				}else if(val==3){
					this.ruleForm.original_price='10万'
				}else if(val==4){
					this.ruleForm.original_price='20万'
				}else if(val==5){
					this.ruleForm.original_price='50万'
				}
				
				
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let  timep = new Date(this.ruleForm.renew_dt[1]).getTime()  / 1000
						let data={
							account_channel_id:this.account_channel_id,
							levelId:this.ruleForm.level_id,
							end_time:timep,
							money:this.ruleForm.money,
							original_price:this.ruleForm.original_price
						}
						channelRenew(data).then(res=>{
							this.dialogVisible = !this.dialogVisible;
							this.getInfo();
						})
					} else {
						return false;
					}
				});
			},
			//返回上一步
			toBack() {
				// 返回列表页
				this.$router.go(-1);
			},
			//保存提交
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调取接口
						// if(this.form.passwords !='' || this.form.surepassword != ''){
						// 	if(this.form.passwords != this.form.surepassword){
						// 		this.$message.error("登录密码必须和确认密码一致！");
						// 		return;
						// 	}
						// }
						let data = JSON.parse(JSON.stringify(this.form));
						if (this.account_channel_id) {
							data.account_channel_id = this.account_channel_id
						}
						if (this.form.passwords) {
							data.password = sha256(this.form.passwords);
						}
						delete data["passwords"];
						delete data["surepassword"];
						delete data["due_dt"];
						delete data["address"]
						data = this.tools.filterParams(data);
						createOrUpChannelAccount(data).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.$router.go(-1);
							}
						})

					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style scoped>
	.pds{
		padding-bottom: 120px !important;
	}
</style>
