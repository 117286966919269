<template>
	<div>
		<el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small" class="demo-ruleForm">
			<div class="wrapper-cont pp20">
				<div class="setTitle">基础设置</div>
				<el-form-item label="营销名称" prop="title">
					<el-input class="inputWidth" size="small" v-model="form.title"></el-input>
				</el-form-item>
				<el-form-item label="营销图标" prop="backstage_image">
					<div class="allicon">
						<div class="backicon">
							<div class="icon_blos" style="width: 200px;">
								<div class="icon_blo"  @click="uploadImg('backstage_image')">
									<img :src="form.backstage_image" class="item_img" v-if="form.backstage_image" style="margin-right: 0!important;" />
									<div class="iconImg" v-else>
										<i class="el-icon-plus avatar-uploader-icon"></i>
									</div>
								</div>
							</div>
							<p class="imgtxtstyle">后台图标50*50px</p>
						</div>
						<div class="moveicon">
							<div class="icon_blos" style="width: 200px;" >
								<div class="icon_blo" @click="uploadImg('cover_image')">
									<img :src="form.cover_image" class="item_img" v-if="form.cover_image" style="margin-right: 0!important;" />
									<div class="iconImg" v-else>
										<i class="el-icon-plus avatar-uploader-icon"></i>
									</div>
								</div>
							</div>
							<p class="imgtxtstyle">移动端图标70*70px</p>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="营销简介" prop="description">
					<el-input type="textarea" style="width: 500px;" :rows="5" placeholder="请输入简介"
						v-model="form.description" maxlength="150" show-word-limit>
					</el-input>
				</el-form-item>
				<el-form-item label="营销标签" prop="tag_id">
					<el-select v-model="form.tag_id" placeholder="请选择营销标签">
						<el-option v-for="(item,index) in marketingLabel" :key="index" :label="item.tag_name"
							:value="item.tag_id">{{item.tag_name}}</el-option>
					</el-select>
					<el-button type="text" size="small" @click="toAddTag">添加标签</el-button>
				</el-form-item>
				<el-form-item label="应用分组" prop="packet_id">
					<el-select v-model="form.packet_id" placeholder="请选择应用分组">
						<el-option v-for="(item,index) in marketingGroup" :key="index" :label="item.packet_name"
							:value="item.packet_id">{{item.packet_name}}</el-option>
					</el-select>
					<el-button type="text" size="small" @click="toAddGroup">添加分组</el-button>
				</el-form-item>
				<el-form-item label="推荐">
					<el-radio v-model="form.is_recommend" label="1">开启</el-radio>
					<el-radio v-model="form.is_recommend" label="0">关闭</el-radio>
				</el-form-item>
				<el-form-item label="活动状态">
					<el-radio v-model="form.status" label="1">上架</el-radio>
					<el-radio v-model="form.status" label="2">下架</el-radio>
				</el-form-item>
			</div>
			<div class="wrapper-cont pp20 mt10">
				<div class="setTitle">售卖商户设置</div>
				<el-form-item label="是否免费">
					<el-radio v-model="form.is_free" label="1">是</el-radio>
					<el-radio v-model="form.is_free" label="2">否</el-radio>
				</el-form-item>
				<el-form-item label="售卖价格">
					<div>
						<span class="mr5">永久</span>
						<el-input placeholder="请输入" class="w150" oninput="value=value.replace(/[^\d.]/g,'')"
							size="small" v-model="sale_price">
							<template slot="append">元</template>
						</el-input>
						<el-checkbox v-model="is_checked"></el-checkbox>
					</div>
					<div class="specs-cont">
						<div class="specs-cont-item" v-for="(item,index) in specsList" :key="index">
							<el-input placeholder="请输入" oninput="value=value.replace(/[^\d.]/g,'')"
								v-model="item.number_time" class="input-with-select w150">
								<el-select v-model="item.time_quantum" slot="prepend" placeholder="请选择">
									<el-option label="月" value="月"></el-option>
									<el-option label="年" value="年"></el-option>
								</el-select>
							</el-input>
							<el-input placeholder="请输入" oninput="value=value.replace(/[^\d.]/g,'')" class="w150"
								size="small" v-model="item.sale_price">
								<template slot="append">元</template>
							</el-input>
							<el-button style="margin-left: 10px;" type="text" size="small" @click="delSet(index)">删除
							</el-button>
						</div>
						<div class="mt20 flex" v-if="specsList.length !== 3">
							<el-button icon="el-icon-plus" type="primary" size="small" plain @click="addSpecs()">添加规格
							</el-button>
							<p class="pstyle ml0">最多可添加3条</p>
						</div>
					</div>
				</el-form-item>
			</div>
			<div class="wrapper-cont pp20 mt10">
				<div class="setTitle">售卖服务商设置</div>
				<el-form-item label="是否开启">
					<el-radio v-model="form.service_status" label="1">启用</el-radio>
					<el-radio v-model="form.service_status" label="2">禁用</el-radio>
				</el-form-item>
				<el-form-item label="售卖价格" v-for="(it,index) in form.sale_service" :key="index">
					<span class="mr5">{{it.time_cycle}}</span>
					<el-input placeholder="请输入" class="w150" size="small" v-model="it.sale_price">
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
			</div>
			<div class="wrapper-cont pp20 mt10">
				<div class="setTitle" style="margin-bottom: 0;">应用详情</div>
				<el-form-item style="margin-top: -15px;">

					<div style="height: 500px; ">
						<Editor v-model="form.details" />
					</div>
				</el-form-item>
			</div>
		</el-form>
		<div class="save-cont">
			<div class="cancel-btn" @click="cancelSet">取消</div>
			<div class="save-btn" @click="submitSet('form')">提交</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
		<!--新增标签-->
		<el-dialog title="新增标签" :visible.sync="dialogAddTagVisible" width="25%">
			<div class="tipstyle" @mouseover="mouseover" @mouseleave="mouseleave">示例</div>
			<div class="tipsimg" v-show="tagImgShow">
				<img src="../../../../../../assets/img/plat/market/tagtips.png" />
			</div>
			<el-form :model="tagForm" :rules="tagRules" ref="tagForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="标签名称">
					<el-input size="small" maxlength="4" show-word-limit v-model="tagForm.tag_name" class="inputWidth"
						placeholder="请输入标签名称">
					</el-input>
				</el-form-item>
				<el-form-item label="已有标签">
					<div class="tagList">
						<div class="taglist-item" v-for="(item,index) in marketingLabel" :key="index">
							<div class="closeimg" @click="closetag(item)"></div>
							{{item.tag_name}}
						</div>
					</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogAddTagVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddTag('tagForm')" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!--新增分组-->
		<el-dialog title="新增分组" :visible.sync="dialogAddGroupVisible" width="25%">
			<div class="tipstyle" @mouseover="mouseover" @mouseleave="mouseleave">示例</div>
			<div class="tipsimg" v-show="tagImgShow">
				<img src="../../../../../../assets/img/plat/market/grouptips.png" />
			</div>
			<el-form :model="groupForm" :rules="groupRules" ref="groupForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="分组名称">
					<el-input size="small" maxlength="4" show-word-limit v-model="groupForm.packet_name"
						class="inputWidth" placeholder="请输入标签名称">
					</el-input>
				</el-form-item>
				<el-form-item label="已有分组">
					<div class="tagList">
						<div class="taglist-item" v-for="(item,index) in marketingGroup" :key="index">
							<div class="closeimg" @click="closegroup(item)"></div>
							{{item.packet_name}}
						</div>
					</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogAddGroupVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddGroup('groupForm')" size="small">确 定</el-button>
			</span>
		</el-dialog>

	</div>


</template>

<script>
	import ChooseImg from "../../../../../common/uploadImgs.vue";
	import Editor from '../../../../../common/newEditor';
	import {
		twitterDetails,
		tagList,
		packetList,
		twitterUpdate,
		packetInsert,
		tagInsert,
		tagDel,
		packetDel
	} from "@/api/plat/market.js";
	export default {
		components: {
			ChooseImg,
			Editor
		},
		props: {
			plugin_id: String,
			plugin_identifying: String
		},
		data() {
			return {
				isSingle: false,
				uploadFlag: false,
				uploadType: '',
				form: {
					title: '',
					backstage_image: '',
					cover_image: '',
					description: '',
					tag_id: '',
					packet_id: '',
					is_recommend: '1',
					status: '1',
					is_free: '1',
					service_status: '1',
					sale_service: []
				},
				sale_price: '',
				is_checked: true,
				specsList: [{
					number_time: "1",
					time_quantum: '月',
					sale_price: '1'
				}],
				marketingLabel: [],
				marketingLabelNew: [],
				marketingGroup: [],
				marketingGroupNew: [],
				rules: {
					title: [{
							required: true,
							message: '请输入营销名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 8,
							message: '长度在 1 到 8 个字符',
							trigger: 'blur'
						}
					],
					backstage_image: [{
						required: true,
						message: '请上传营销图标',
						trigger: 'change'
					}],
					tag_id: [{
						required: true,
						message: '请选择营销标签',
						trigger: 'change'
					}],
					packet_id: [{
						required: true,
						message: '请选择应用分组',
						trigger: 'change'
					}],
				},
				backstage_image: '',
				dialogAddTagVisible: false,
				tagForm: {
					tag_name: ''
				},
				tagRules: {
					tag_name: [{
							required: true,
							message: '请输入标签',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 4,
							message: '长度在 1 到 4 个字符',
							trigger: 'blur'
						}
					]
				},
				dialogAddGroupVisible: false,
				groupForm: {
					packet_name: ''
				},
				groupRules: {
					packet_name: [{
							required: true,
							message: '请输入标签',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 4,
							message: '长度在 1 到 4 个字符',
							trigger: 'blur'
						}
					]
				},
				tagImgShow: false,
				groupImgShow: false,
			}
		},
		mounted() {

		},
		methods: {
			//标签列表
			tagListData() {
				let data = {
					tag_type: '1'
				}
				tagList(data).then(res => {
					this.marketingLabel = res.data;
					this.marketingLabel.forEach(item => {
						item.tag_id = item.tag_id.toString()
					})

				})
			},
			//分组列表
			packetListData() {
				let data = {
					packet_type: '1'
				}
				packetList(data).then(res => {
					this.marketingGroup = res.data;
					this.marketingGroup.forEach(item => {
						item.packet_id = item.packet_id.toString()
					})
				})
			},
			//获取详情
			getBaseInfo() {
				let data = {
					plugin_id: this.plugin_id,
					plugin_identifying: this.plugin_identifying
				}
				data = this.tools.filterParams(data);
				twitterDetails(data).then(res => {
					this.form = JSON.parse(JSON.stringify(res.data));
					this.form.is_recommend = this.form.is_recommend.toString();
					this.form.status = this.form.status.toString();
					this.form.is_free = this.form.is_free.toString();
					this.form.service_status = this.form.service_status.toString();
					// this.form.sale_service = this.form.service_price_state == 1 ? true : false
					let sList = [];
					this.form.sale_commercial.forEach(item => {
						if (item.time_cycle == '永久') {
							this.sale_price = item.sale_price;
							this.is_checked = item.status == 'true' ? true : false;
						} else {
							sList.push(item);
						}

					})

					this.specsList = sList;

				})
			},

			// 上传商品图
			uploadImg(type) {
				this.uploadType = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				let type = this.uploadType;
				this.uploadFlag = false;
				if (type == "backstage_image") {
					this.form.backstage_image = val[0].resource_name;
				}
				if (type == "cover_image") {
					this.form.cover_image = val[0].resource_name;
				}
			},
			addSpecs() {
				if (this.specsList.length >= 3) {
					this.$message.warning("最多只能够添加3个")
					return;
				}
				this.specsList.push({
					number_time: "1",
					time_quantum: '月',
					sale_price: '1',
				})
			},
			//删除
			delSet(index) {
				this.specsList.splice(index, 1);
			},
			//示例
			mouseover() {
				this.tagImgShow = true;
			},
			mouseleave() {
				this.tagImgShow = false;
			},
			//添加标签弹框
			toAddTag() {
				this.tagForm.tag_name = '';
				this.dialogAddTagVisible = true
			},
			//删除标签
			closetag(item) {
				this.tools.confirm("确定要删除此标签吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							tag_id: item.tag_id,
							tag_type: '1'
						}
						data = this.tools.filterParams(data);
						tagDel(data).then(res => {
							this.$message.success("删除成功")
							this.tagListData();
						})
					}
				})
			},
			//删除分组
			closegroup(item) {
				this.tools.confirm("确定要删除此分组吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							packet_id: item.packet_id,
							packet_type: '1'
						}
						data = this.tools.filterParams(data);
						packetDel(data).then(res => {
							this.$message.success("删除成功")
							this.packetListData();
						})
					}
				})
			},
			//确认添加标签
			sureAddTag(tagForm) {
				//调接口
				this.$refs[tagForm].validate((valid) => {
					if (valid) {
						//调接口
						let data = {
							tag_name: this.tagForm.tag_name,
							tag_type: '1'
						}
						tagInsert(data).then(res => {
							this.$message.success("添加成功！");
							this.dialogAddTagVisible = false;
							this.tagListData();
						})
					} else {
						return false;
					}
				});
			},
			//添加分组弹框
			toAddGroup() {
				this.groupForm.packet_name = '';
				this.dialogAddGroupVisible = true
			},
			//确认添加分组
			sureAddGroup(groupForm) {
				//调接口
				this.$refs[groupForm].validate((valid) => {
					if (valid) {
						//调接口
						let data = {
							packet_name: this.groupForm.packet_name,
							packet_type: '1'
						}
						packetInsert(data).then(res => {
							this.$message.success("添加成功！");
							this.dialogAddGroupVisible = false;
							this.packetListData();
						})
					} else {
						return false;
					}
				});
			},
			//整体提交
			cancelSet() {
				this.$router.push({
					path: '/marketingadmin/usecenter/usecenteritem'
				})

			},
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data = JSON.parse(JSON.stringify(this.form));
						data.backstage_image = this.tools.filterImgPre(data.backstage_image);
						data.cover_image = this.tools.filterImgPre(data.cover_image);
						data.sale_commercial = JSON.parse(JSON.stringify(this.specsList));
						data.service_price_state = data.service_price_state == true ? 1 : 2;
						data.sale_commercial.forEach(item => {
							item.time_cycle = item.number_time + item.time_quantum;
							item.status = true;
						});

						data.sale_commercial.push({
							time_cycle: '永久',
							sale_price: this.sale_price,
							status: this.is_checked == true ? 'true' : 'false',
							time_quantum: '',
							number_time: ''
						});
						data.sale_commercial = JSON.stringify(data.sale_commercial);
						data.sale_service = JSON.stringify(data.sale_service);
						data.plugin_id = this.plugin_id;
						data.plugin_identifying = this.plugin_identifying;
						delete data["deleted_at"];
						twitterUpdate(data).then(res => {
							this.$message.success("编辑成功！");
							this.$router.push({
								path: "/marketingadmin/usecenter/usecenteritem"
							})
						})
					} else {
						return false;
					}
				});
			}

		}
	}
</script>

<style lang="scss" scoped>
	.backicon {
		float: left;
	}

	.moveicon {
		float: left;
	}

	.allicon {
		overflow: hidden;
	}

	.specs-cont {
		margin-top: 20px;

		.specs-cont-item {
			margin-bottom: 10px
		}
	}

	::v-deep.specs-cont-item .el-select .el-input {
		width: 60px;
	}

	::v-deep.specs-cont-item .input-with-select .el-input-group__prepend {
		background-color: #fff;
	}

	.tagList {
		overflow: hidden;

		.taglist-item {
			float: left;
			margin-right: 5px;
			width: 80px;
			height: 30px;
			background: #EBF6FF;
			border-radius: 2px;
			border: 1px solid #8ECEFF;
			position: relative;
			font-size: 14px;
			font-weight: 400;
			color: #1890FF;
			line-height: 30px;
			padding-left: 10px;
			position: relative;
			cursor: pointer;
			margin-bottom: 10px;

			.closeimg {
				position: absolute;
				width: 20px;
				height: 20px;
				top: 5px;
				right: 5px;
				display: none;
				background-image: url(../../../../../../assets/img/closeimg.png);
			}

		}

		.taglist-item:hover .closeimg {
			cursor: pointer;
			display: block;

		}
	}

	.tipstyle {
		position: relative;
		width: 100%;
		text-align: right;
		top: -20px;
		color: #FA6400;
		cursor: pointer;
		margin-right: 10px;
		font-size: 14px;
	}

	.tipsimg {
		width: 277px;
		position: absolute;
		z-index: 3;
		right: 0;
		top: 63px;
		pointer-events: none;
	}
</style>
