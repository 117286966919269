<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="order-search">
					<el-row class="all_search_list" style="margin-bottom:0">
						<div class="demo-input-suffix">
							<el-select style="width: 120px;" size="small" v-model="search.consumetype"
								placeholder="消费类型">
								<el-option label="线上网店" value="1"></el-option>
								<el-option label="营销插件" value="2"></el-option>
								<el-option label="样板店" value="3"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-select style="width: 120px;" size="small" v-model="search.usertype" placeholder="用户类型">
								<el-option label="服务商" value="1"></el-option>
								<el-option label="门店" value="2"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix ml0" style="margin-left: 30px;font-size: 13px;">
							下单时间：
							<el-date-picker style="width: 350px；" size="small" v-model="search.validity"
								type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
								@change="changetimer"></el-date-picker>
						</div>
						<div class="demo-input-suffix" style="margin-right: 0px;">
							<el-select style="width: 120px;" size="small" v-model="search.orderNum" placeholder="订单编号">
								<el-option label="订单编号" value="1"></el-option>
								<el-option label="客户名称" value="2"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-input clearable class="inputWidth" prefix-icon="el-icon-search"
								v-model="search.keywards" placeholder="请输入" size="small" type="text">
								<el-button size="small" type="primary" plain slot="append" @click="searchOrder()">搜索
								</el-button>
							</el-input>
						</div>
					</el-row>
				</div>
				<div class="tableMain">
					<el-table :data="tableData" border style="width: 100%">
						<el-table-column label="用户类型" prop="usertype"></el-table-column>
						<el-table-column label="客户名称" prop="cusname"></el-table-column>
						<el-table-column label="联系方式" prop="phone"></el-table-column>
						<el-table-column label="实收金额" prop="actuallypay"></el-table-column>
						<el-table-column label="充值类型" prop="chargetype"></el-table-column>
						<el-table-column label="支付方式" prop="paytype"></el-table-column>
						<el-table-column label="订单状态" prop="orderstate"></el-table-column>
						<el-table-column label="备注信息" prop="mark"></el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<!-- 分页 -->
		<div class="pagecurrent">
			<div class="pagination-container">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				search: {
					consumetype: '',
					usertype: '',
					transaction_time: '',
					orderNum: ''
				},
				tableData:[
					{
						usertype:'服务商',
						cusname:'三胜',
						phone:'15306068156',
						actuallypay:'¥122.00',
						chargetype:'推客插件',
						paytype:'微信',
						orderstate:'已完成',
						mark:'插件订单 订单id:143 订单号: 2021022717380028279'
					}
				],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		methods:{
			handleSizeChange(size) {
				this.pagesize = size;
				//this.GetList(0);
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				//this.GetList(0);
			},
			searchOrder(){
				
			},
			//时间选择
			changetimer(val) { //时间
				if (val) {
					this.search.transaction_time = val.toString();
					//this.GetList(0)
				} else {
					this.search.transaction_time = '';
					//this.GetList(0)
				}
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
