<template>
	<div>
		<div class="wrapper-main pds">
			<div class="wrapper-cont">
				<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
					<div class="main-cont  pp20">
						<el-form-item label="banner名称:" prop="title">
							<el-input class="inputWidth" size="small" v-model="form.title" maxlength="4"
								placeholder="请输入banner名称" show-word-limit></el-input>
						</el-form-item>
						<!-- <el-form-item label="排序:">
							<el-input class="inputWidth" size="small" v-model="form.sort" placeholder="请输入排序">
							</el-input>
						</el-form-item> -->
					</div>
					<div class="main-cont pp20 mt10">
						<el-form-item label="移动端轮播:">
							<el-row :gutter="20">
								<el-col :span="8">
									<div class="grid-content bg-purple">
										<div class="img_blos" @click="uploadImg('wx_pic')" style="width: 115px;">
											<div class="img_blo" v-if="form.wx_pic">
												<img :src="form.wx_pic" v-if="form.wx_pic" class="item_img" />
											</div>
											<div class="plusImg" v-else>
												<i class="el-icon-plus avatar-uploader-icon"></i>
											</div>
										</div>
										<div class="imgtxtstyle mt10">
											移动端轮播建议尺寸：703*230px
										</div>
									</div>
								</el-col>
								<el-col :span="4" :offset="6">
									<div class="grid-content bg-purple">
										<div class="setopera">
											点击事件：<div class="item-title titles">
												<li v-for="(item,indexp) in moveItem" :key="item.name"
													:class="{active : backIndex == indexp}"
													@click="selectedback(indexp)">
													{{item.name}}
												</li>
											</div>
										</div>
										<div class="goodsel" v-if="backIndex == '2'">
											<span class="mr10 ml0">{{goodcount}}</span>款商品 <span @click="lookGood"
												style="color:#409EFF;cursor: pointer;" class="ml0">查看</span>
										</div>

									</div>
								</el-col>
							</el-row>
						</el-form-item>
						<el-form-item label="图片" v-if="backIndex == '1'">
							<div class="img_blos">
								<div v-for="(item, index) in form.wx_loop" :key="index" class="img_blo"
									style="margin-bottom:15px">
									<i class="el-icon-error close_img" @click="delImg(index,'wx_loop')"></i>
									<img :src="item" class="item_img" />
								</div>
								<div class="plusImg" @click="uploadImg('wx_loop')">
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
							<div class="imgtxtstyle mt10">
								建议尺寸：800宽度，不限制高度，长图建议切图分多张上传
							</div>
						</el-form-item>

					</div>
					<div class="main-cont pp20 mt10">
						<el-form-item label="后台轮播:">
							<el-row :gutter="20">
								<el-col :span="8">
									<div class="grid-content bg-purple">
										<div class="img_blos" @click="uploadImg('back_pic')" style="width: 115px;">
											<div class="img_blo" v-if="form.back_pic">
												<img :src="form.back_pic" v-if="form.back_pic" class="item_img" />
											</div>
											<div class="plusImg" v-else>
												<i class="el-icon-plus avatar-uploader-icon"></i>
											</div>
										</div>
										<div class="imgtxtstyle mt10">
											移动端轮播建议尺寸：945*300px
										</div>
									</div>
								</el-col>
								<el-col :span="4" :offset="6">
									<div class="grid-content bg-purple">
										<div class="setopera">
											点击事件：<div class="item-title titles">
												<li v-for="(item,index) in moveItempc" :key="item.name"
													:class="{active : backIndexpc == index}"
													@click="selectedbackpc(index)">
													{{item.name}}
												</li>
											</div>
										</div>
										<div class="goodsel" v-if="backIndexpc == '2'">
											<span class="mr10 ml0">{{pcgoodcount}}</span>款商品 <span @click="lookGoodpc"
												style="color:#409EFF;cursor: pointer;" class="ml0">查看</span>
										</div>

									</div>
								</el-col>
							</el-row>
						</el-form-item>
						<el-form-item label="图片" v-if="backIndexpc == '1'">
							<div class="img_blos">
								<div v-for="(item, index) in form.back_loop" :key="index" class="img_blo"
									style="margin-bottom:15px">
									<i class="el-icon-error close_img" @click="delImg(index,'back_loop')"></i>
									<img :src="item" class="item_img" />
								</div>
								<div class="plusImg" @click="uploadImg('back_loop')">
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
							<div class="imgtxtstyle mt10">
								建议尺寸：800宽度，不限制高度，长图建议切图分多张上传
							</div>
						</el-form-item>
					</div>
				</el-form>
			</div>
		</div>
		<!--商品弹框-->
		<el-dialog title="选择商品" :visible.sync="dialogVisible" width="45%">
			<el-row class="all_search_list" style="margin-bottom: 0;">
				<div class="demo-input-suffix">
					<el-select v-model="search.is_publish" size="small" class="w120" placeholder="商品状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="上架" value="1"></el-option>
						<el-option label="下架" value="0"></el-option>
					</el-select>
				</div>
				<div class="demo-input-suffix">
					<el-cascader size="small" v-model="search.category" :options="allCate"
						:props="{ expandTrigger: 'hover',value:'id',label:'name',children:'children'}">
					</el-cascader>
				</div>
				<div class="demo-input-suffix">
					<el-input prefix-icon="el-icon-search" v-model="search.keysward" placeholder="请输入商品名称" size="small"
						type="text">
					</el-input>
				</div>
				<div class="demo-input-suffix">
					<el-button size="small" type="primary" plain @click="searchOrder()">搜索</el-button>
					<el-button size="small" plain @click="toreset()">重置</el-button>
				</div>
			</el-row>

			<el-table border :data="tableData" height="350" ref="multipleTable"
				:row-key="(row)=>{ return row.original_goods_unified_id}" style="width: 100%;margin-top:15px"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" align="center" :reserve-selection="true">
				</el-table-column>
				<el-table-column prop="original_goods_unified_id" label="商品ID" align="center">
				</el-table-column>
				<el-table-column label="商品图片" align="center">
					<template slot-scope="scope">
						<img v-if="scope.row.goods_main_pic" :src="scope.row.goods_main_pic.split(',')[0]"
							class="el_img" />
					</template>
				</el-table-column>
				<el-table-column prop="goods_name" label="商品名称" align="center">
				</el-table-column>
				<el-table-column prop="sku_circumscribed_price" label="成本价" align="center">
				</el-table-column>
				<el-table-column prop="created_at" label="创建时间" align="center">
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<div class="pagecurrent">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddGood" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="选择商品" :visible.sync="dialogVisiblepc" width="45%">
			<el-row class="all_search_list" style="margin-bottom: 0;">
				<div class="demo-input-suffix">
					<el-select v-model="search.is_publish" size="small" class="w120" placeholder="商品状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="上架" value="1"></el-option>
						<el-option label="下架" value="0"></el-option>
					</el-select>
				</div>
				<div class="demo-input-suffix">
					<el-cascader size="small" v-model="search.category" :options="allCate"
						:props="{ expandTrigger: 'hover',value:'id',label:'name',children:'children'}">
					</el-cascader>
				</div>
				<div class="demo-input-suffix">
					<el-input prefix-icon="el-icon-search" v-model="search.keysward" placeholder="请输入商品名称" size="small"
						type="text">
					</el-input>
				</div>
				<div class="demo-input-suffix">
					<el-button size="small" type="primary" plain @click="searchOrder()">搜索</el-button>
					<el-button size="small" plain @click="toreset()">重置</el-button>
				</div>
			</el-row>
			<el-table border :data="tableDatapc" ref="multipleTablepc" height="350"
				:row-key="(row)=>{ return row.original_goods_unified_id}" style="width: 100%;margin-top:15px"
				@selection-change="handleSelectionChangepc">
				<el-table-column type="selection" width="55" align="center" :reserve-selection="true">
				</el-table-column>
				<el-table-column prop="original_goods_unified_id" label="商品ID" align="center">
				</el-table-column>
				<el-table-column label="商品图片" align="center">
					<template slot-scope="scope">
						<img v-if="scope.row.goods_main_pic" :src="scope.row.goods_main_pic.split(',')[0]"
							class="el_img" />
					</template>
				</el-table-column>
				<el-table-column prop="goods_name" label="商品名称" align="center">
				</el-table-column>
				<el-table-column prop="sku_circumscribed_price" label="成本价" align="center">
				</el-table-column>
				<el-table-column prop="created_at" label="创建时间" align="center">
				</el-table-column>
			</el-table>

			<div class="pagecurrent">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisiblepc = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddGoodpc" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<div class="save-cont">
			<div class="save-btn" @click="setSubmit('form')">保存</div>
			<div class="cancel-btn" @click="goBack">取消</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		all_cate, //所有商品分类
		goods_list,
	} from '@/api/plat/goods';
	import {
		addBanner,
		getBannerInfo,
		editBanner
	} from '@/api/plat/market.js'
	import ChooseImg from "../../../../../common/uploadImgs";
	export default {
		components: {
			ChooseImg,
		},
		data() {
			return {
				form: {
					title: '', //名称
					sort: '', //排序
					wx_pic: '', //移动端展示图
					wx_loop: [], //移动端轮播图
					back_pic: '', //后台展示图
					back_loop: [], //后台轮播图
				},
				goodids: [], //移动端商品
				goodidspc: [], //PC端商品
				backIndex: "0", //移动端点击
				backIndexpc: "0", //PC端点击
				rules: {
					title: [{
						required: true,
						message: '请填写名称',
						trigger: 'blur'
					}]
				},
				allCate: [],
				isSingle: false, //上传图片是单张还是多张
				uploadFlag: false, //是否展示选择图片弹窗
				imgType: '',
				moveItem: [{
						id: 1,
						name: '无'
					}, {
						id: 2,
						name: '图片'
					},
					{
						id: 3,
						name: '商品'
					}
				],
				moveItempc: [{
						id: 1,
						name: '无'
					}, {
						id: 2,
						name: '图片'
					},
					{
						id: 3,
						name: '商品'
					}
				],
				tableData: [],
				tableDatapc: [],
				dialogVisible: false,
				dialogVisiblepc: false,
				search: {
					is_publish: '',
					category: '',
					keysward: ''
				},
				multipleSelection: [],
				multipleSelectionpc: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据

				goodcount: 0,
				pcgoodcount: 0,
				banner_id: this.$route.query.id
			}
		},
		mounted() {
			if (this.banner_id) {
				this.getBannerData()
			}


		},
		methods: {
			getBannerData() {
				let params = {
					banner_id: this.banner_id
				}
				getBannerInfo(params).then(res => {
					this.form.title = res.data.title;
					this.form.sort = res.data.sort;
					this.form.wx_pic = res.data.wx_pic;
					this.form.back_pic = res.data.pc_pic;
					this.backIndex = Number(res.data.wx_click) - 1;
					this.backIndexpc = Number(res.data.pc_click) - 1;
					if (this.backIndex == 1) {
						this.form.wx_loop = res.data.wx_click_content.split(",")
					} else if (this.backIndex == 2) {
						this.goodids = res.data.wx_click_content
					}
					if (this.backIndexpc == 1) {
						this.form.back_loop = res.data.pc_click_content.split(",");
					} else if (this.backIndexpc == 2) {
						this.goodidspc = res.data.pc_click_content
					}
					this.goodcount = res.data.wx_click_content.length;
					this.pcgoodcount = res.data.pc_click_content.length
				})
			},
			//获取所有商品分类
			getAllCase() {
				all_cate({}).then(res => {
					this.allCate = res.data
				})
			},
			//获取商品列表
			getGoodsList() {
				let data = {
					keyword: this.search.keysward,
					cate_id: this.search.category[0],
					is_publish: this.search.is_publish,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				goods_list(data).then(res => {
					this.tableData = res.data.list;
					this.tableDatapc = res.data.list;
					this.total = res.data.count;
					 this.$nextTick(() => {
						for(var i = 0; i < this.tableData.length; i++){
							if (this.goodids.indexOf(this.tableData[i].original_goods_unified_id) >= 0) {
								this.$refs.multipleTable.toggleRowSelection(this.tableData[i], true)
							}
						}

					 })
					 this.$nextTick(() => {
						for(var i = 0; i < this.tableDatapc.length; i++){
							if (this.goodidspc.indexOf(this.tableDatapc[i].original_goods_unified_id) >= 0) {
								this.$refs.multipleTablepc.toggleRowSelection(this.tableDatapc[i], true)
							}
						}
					 })
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getGoodsList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getGoodsList()
			},
			//搜索商品
			searchOrder() {
				this.getGoodsList()
			},
			toreset() {
				this.search.is_publish = '';
				this.search.category = '';
				this.search.keysward = '';
				this.getGoodsList()
			},
			// 上传商品图
			uploadImg(type) {
				this.imgType = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				if (this.imgType == 'wx_pic') {
					val.forEach((res) => {
						this.form.wx_pic = val[0].resource_name;
					});
				}
				if (this.imgType == 'wx_loop') {
					val.forEach((res) => {
						this.form.wx_loop = this.form.wx_loop.concat([res.resource_name]);
					});
				}
				if (this.imgType == 'back_pic') {
					val.forEach((res) => {
						this.form.back_pic = val[0].resource_name;
					});
				}
				if (this.imgType == 'back_loop') {
					val.forEach((res) => {
						this.form.back_loop = this.form.back_loop.concat([res.resource_name]);
					});
				}
			},
			//删除图片
			delImg(index, type) {

				if (type == 'wx_loop') {
					this.form.wx_loop.splice(index, 1)
				}
				if (type == 'back_loop') {
					this.form.back_loop.splice(index, 1)
				}

			},

			//查看商品
			lookGood() {
				this.search.keysward = '';
				this.search.category = '';
				this.search.is_publish = '';
				this.dialogVisible = !this.dialogVisible;
				this.currentPage = 1;
				this.getAllCase();
				this.getGoodsList()
				//获取商品列表
				// 	let data = {
				// 		keyword: this.search.keysward,
				// 		cate_id: this.search.category[0],
				// 		is_publish: this.search.is_publish,
				// 		page: this.currentPage,
				// 		perPage: this.pagesize
				// 	}
				// 	data = this.tools.filterParams(data);
				// 	goods_list(data).then(res => {
				// 		this.tableData = res.data.list;
				// 		this.total = res.data.count;
				// 		if(this.tableData){
				// 			this.$nextTick(() => {
				// 				this.tableData.forEach((row) => {
				// 					if (this.goodids.indexOf(row.original_goods_unified_id) >= 0) {
				// 						this.$refs.multipleTable.toggleRowSelection(row, true)
				// 					}
				// 				});

				// 			})
				// 		}



				// 	})


			},
			//查看商品
			lookGoodpc() {
				this.search.keysward = '';
				this.search.category = '';
				this.search.is_publish = '';
				this.dialogVisiblepc = !this.dialogVisiblewpc
				this.currentPage = 1;
				this.getAllCase();
				this.getGoodsList()
				
			},
			selectedback(indx) {
				this.backIndex = indx;
			},
			selectedbackpc(indx) {
				this.backIndexpc = indx;
			},
			//多选
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleSelectionChangepc(val) {
				this.multipleSelectionpc = val;

			},
			getRowKeys(row) {
				return row.original_goods_unified_id;
			},
			getRowKeyspc(row) {
				return row.original_goods_unified_id;
			},
			fixArray(arr1, arr2) {
				let arr = arr1.filter(function(val) {
						return !(arr2.indexOf(val) > -1)
					})
					.concat(arr2.filter(function(val) {
						return !(arr1.indexOf(val) > -1)
					}));
				return arr;
			},
			intersectArray(arr1, arr2) {
				let intersection = arr1.filter(function(val) {
					return arr2.indexOf(val) > -1
				})
				return intersection;
			},
			diffArray(arr1, arr2) {
				let diff = arr1.filter(function(val) {
					return arr2.indexOf(val) === -1
				})
				return diff;
			},
			//提交
			sureAddGood() {
				this.dialogVisible = !this.dialogVisible;
				//接口和数据源比较 
				//console.log(this.goodids, "接口选择的")
				let getids = [],
					copyGoodids = this.goodids;
				this.tableData.forEach(item => {
					this.goodids.forEach(ide => {
						if (item.original_goods_unified_id == ide) {
							getids.push(item.original_goods_unified_id);
						}
					})
				});
				//console.log(getids, "当页接口有的id数组")

				let multipleSelection_ids = []; //选择的  当页
				this.multipleSelection.forEach(item => {
					multipleSelection_ids.push(item.original_goods_unified_id);
				});
				if (getids.length > 0) {
					let fixArr = this.fixArray(getids,
						multipleSelection_ids); /** 找到当页原接口和当页table选择都不同的值 补集后的数组 当页接口选择的id数组和当页table触发select的数组的补集*/
					let interArr = this.intersectArray(fixArr,
						copyGoodids); /* 找出取消的值的数组，比较补集后的数组和接口是由存在相同id 相同要在原id数组删除，不同加加上*/
					if (interArr.length == 0) {
						//console.log("2");
						let concArr = this.diffArray(multipleSelection_ids, getids);
						copyGoodids = copyGoodids.concat(concArr);
					} else {
						//console.log("1");
						let concArr = this.diffArray(copyGoodids, interArr);
						//console.log(concArr, "1-2");
						copyGoodids = concArr.concat(multipleSelection_ids);
					}
				} else {
					copyGoodids = copyGoodids.concat(multipleSelection_ids);
				}
				//console.log(copyGoodids, "final");
				//console.log(getids.indexOf(multipleSelection_ids),"9999")



				this.goodids = copyGoodids;
				//console.log(multipleSelection_ids, "新增商品");
				//console.log(this.goodids, "222")
				let allids = [...multipleSelection_ids, ...this.goodids];
				let selids = new Set(allids);
				selids = [...selids];
				//console.log(selids, "7777")
				this.goodcount = selids.length;

			},
			sureAddGoodpc() {
				this.dialogVisiblepc = !this.dialogVisiblepc;
				//接口和数据源比较 
				//console.log(this.goodidspc, "接口选择的")
				let getids = [],
					copyGoodids = this.goodidspc;
				this.tableData.forEach(item => {
					this.goodidspc.forEach(ide => {
						if (item.original_goods_unified_id == ide) {
							getids.push(item.original_goods_unified_id);
						}
					})
				});
				//console.log(getids, "当页接口有的id数组")

				let multipleSelection_ids = []; //选择的  当页
				this.multipleSelectionpc.forEach(item => {
					multipleSelection_ids.push(item.original_goods_unified_id);
				});
				if (getids.length > 0) {
					let fixArr = this.fixArray(getids,
						multipleSelection_ids); /** 找到当页原接口和当页table选择都不同的值 补集后的数组 当页接口选择的id数组和当页table触发select的数组的补集*/
					let interArr = this.intersectArray(fixArr,
						copyGoodids); /* 找出取消的值的数组，比较补集后的数组和接口是由存在相同id 相同要在原id数组删除，不同加加上*/
					if (interArr.length == 0) {
						//console.log("2");
						let concArr = this.diffArray(multipleSelection_ids, getids);
						copyGoodids = copyGoodids.concat(concArr);
					} else {

						//console.log("1");
						let concArr = this.diffArray(copyGoodids, interArr);
						//console.log(concArr, "1-2");
						copyGoodids = concArr.concat(multipleSelection_ids);
					}
				} else {
					copyGoodids = copyGoodids.concat(multipleSelection_ids);
				}
				//console.log(copyGoodids, "final");
				//console.log(getids.indexOf(multipleSelection_ids),"9999")



				this.goodidspc = copyGoodids;
				//console.log(multipleSelection_ids, "新增商品");
				//console.log(this.goodids, "222")
				let allids = [...multipleSelection_ids, ...this.goodidspc];
				let selids = new Set(allids);
				selids = [...selids];
				//console.log(selids, "7777")
				this.pcgoodcount = selids.length;
			},
			//保存淘货设置
			setSubmit(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						this.form = JSON.parse(JSON.stringify(this.form));
						if (this.form.wx_pic == '') {
							this.$message.error("请选择移动端图片！")
							return;
						}
						if (this.form.back_pic == '') {
							this.$message.error("请选择后台轮播图片！")
							return;
						}
						let wx_pic = this.form.wx_pic;
						wx_pic = this.tools.filterImgPre(wx_pic);
						this.form.back_pic = this.tools.filterImgPre(this.form.back_pic);
						let wx_loop = [],
							back_loop = []
						this.form.wx_loop.forEach(item => {
							wx_loop.push(this.tools.filterImgPre(item));

						});
						this.form.back_loop.forEach(item => {
							back_loop.push(this.tools.filterImgPre(item));
						});
						let multipleSelection_ids = [];
						this.multipleSelection.forEach(item => {
							multipleSelection_ids.push(item.original_goods_unified_id);
						})
						let allids = [...multipleSelection_ids, ...this.goodids];
						let selids = new Set(allids)
						selids = [...selids]

						// this.goodids=[...new Set(this.goodids )];
						this.goodidspc = [...new Set(this.goodidspc)]
						if (this.banner_id) {
							let data = {
								banner_id: this.banner_id,
								title: this.form.title,
								wx_pic: wx_pic,
								wx_click: Number(this.backIndex + 1),
								wx_click_content: this.backIndex == '1' ? wx_loop.toString() : selids
									.toString(),
								pc_pic: this.form.back_pic,
								pc_click_content: this.backIndexpc == '1' ? back_loop.toString() : this
									.goodidspc.toString(),
								sort: this.form.sort,
								pc_click: Number(this.backIndexpc + 1),
							}
							data = this.tools.filterParams(data);
							editBanner(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.$message.success("编辑成功");
									this.$router.push({
										path: "/marketingadmin/cattleshopgood/cattleshoppage",
										query: {
											curIndex: "1"
										},
									});
									this.goodids = [];
									this.goodidspc = [];
								}
							})
						} else {
							let data = {
								title: this.form.title,
								wx_pic: wx_pic,
								wx_click: Number(this.backIndex + 1),
								wx_click_content: this.backIndex == '1' ? wx_loop.toString() : selids
									.toString(),
								pc_pic: this.form.back_pic,
								pc_click_content: this.backIndexpc == '1' ? back_loop.toString() : this
									.goodidspc.toString(),
								sort: this.form.sort,
								pc_click: Number(this.backIndexpc + 1),
							}
							data = this.tools.filterParams(data);
							addBanner(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.$message.success("添加成功");
									this.$router.push({
										path: "/marketingadmin/cattleshopgood/cattleshoppage",
										query: {
											curIndex: "1"
										},
									});
									this.goodids = [];
									this.goodidspc = [];
								}
							})
						}



					}
				})
			},
			//取消新建
			goBack() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.setposition {
		position: relative;
	}

	.setopera {}

	//切换菜单
	.item-title {
		// width: 100%;
		margin-top: 20px;
		display: flex;
		margin-bottom: 10px;
	}

	.item-title li {
		list-style: none;
		color: #999;
		font-size: 16px;
		cursor: pointer;
		text-align: center;
		margin-right: 30px;
		width: 60px;
		height: 34px;
		line-height: 34px;
		border-radius: 4px;
		border: 1px solid #DDDDDD;
	}

	.item-title li.active {
		width: 60px;
		height: 34px;
		line-height: 34px;
		border-radius: 4px;
		color: #2589FF;
		border: 1px solid #2589FF;
	}

	.goodsel {
		margin-bottom: 20px;
		width: 145px;
		height: 36px;
		background: #F0F2F5;
		box-shadow: 0px 0px 12px 0px rgba(230, 230, 230, 0.5);
		border-radius: 4px;
	}

	.el_img {
		width: 72px;
		height: 34px;
	}
	.pds{
	 	padding-bottom: 120px !important;
	 }

</style>
