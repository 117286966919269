<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont pp20">
					<div style="margin-left:40px">
						<el-row type="flex" class="row-bg">
						  <el-col :span="6"><div class="grid-content bg-purple">服务商名称：{{channelInfo.username}}</div></el-col>
						  <el-col :span="6"><div class="grid-content bg-purple-light">服务商区域：{{channelInfo.area}}</div></el-col>
						  <el-col :span="6"><div class="grid-content bg-purple">门店数量：{{channelInfo.shop_count}}</div></el-col>
						</el-row>
					</div>
					<div style="margin-top:30px">
						<el-row type="flex" class="row-bg" justify="space-around">
							<el-col :span="6">
								<div class="grid-content bg-purple">
									<div class="storeData">
										<ElPopover :name="popover_store_money_name"  :content="popover_store_money_content"></ElPopover>
									</div>
									<div class="money">{{channelInfo.withdrawable_balance}}</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div class="grid-content bg-purple">
									<div class="storeData">
										<ElPopover :name="popover_forthe_name"  :content="popover_forthe_content"></ElPopover>
									</div>
									<div class="money">{{channelInfo.amount_settled}}</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div class="storeData">
									储值金额(元)
								</div>
								<div class="grid-content bg-purple">
									<div class="money">{{channelInfo.stored_value_balance}}</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="main-cont mt10 pp20">
					<div class="all_search_list" style="margin-bottom: 0;">
						
						<div class="demo-input-suffix">
							<el-select v-model="search.order_type" size="small" placeholder="订单类型">
								<el-option label="全部" value="1"></el-option>
								<el-option label="商品订单" value="2"></el-option>
								<el-option label="推广订单" value="3"></el-option>
								<el-option label="虚拟订单" value="4"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
							创建时间：
							<el-date-picker style="width: 350px" size="small" v-model="dute" type="daterange"
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
								@change="changetimer"></el-date-picker>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
					</div>
				</div>
				<div class="main-cont mt10" >
					<el-tabs class="tabstyle" v-model="activeName" type="card" @tab-click="handleClick">
						<el-tab-pane name="1" v-if="search.order_type==1 || search.order_type==2 || search.order_type==3 || search.order_type==4"><span slot="label">全部</span></el-tab-pane>
						<el-tab-pane v-if="search.order_type==1 || search.order_type==2 || search.order_type==3" label="待结算" name="2"><span slot="label">待结算</span></el-tab-pane>
						<el-tab-pane v-if="search.order_type==1 || search.order_type==2 || search.order_type==3 || search.order_type==4" label="交易完成" name="5"><span slot="label">交易完成</span></el-tab-pane>
					</el-tabs>
				</div>
				<div class="main-cont mt10">
					<el-table :data="tableData" border style="width: 100%">
						<el-table-column prop="order_type" label="订单类型" align="center">
						</el-table-column>
						<el-table-column prop="actual_payment" label="金额(元)" align="center">
						</el-table-column>
						<el-table-column prop="commission_money" label="佣金" align="center">
						</el-table-column>
						<el-table-column prop="order_status" label="状态" align="center">
						</el-table-column>
						<el-table-column prop="reason" label="备注信息" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="创建时间" align="center">
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<!-- 分页 -->
		<div class="pagecurrent">
			<div class="pagination-container">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
			channelAreClassifiedIntoTopInfo,
			channelAreClassifiedIntoDetails
		} from '@/api/plat/finance'
	import ElPopover from '../../../../../common/popover.vue'
	export default {
		components:{
			ElPopover
		},
		data() {
			return {
				popover_store_money_name:'可用服务商余额(元)',
				popover_store_money_content:'可用服务商余额=服务商余额-不可用服务商余额',
				popover_forthe_name:'待结算(元)',
				popover_forthe_content:'交易未完成的订单总额，消费者确认收货后将自动转入店铺余额',
				popover_unusable_name:'储值金额(元)',
				popover_unusable_content:'提现中或者退款中的冻结店铺余额',
				search:{
					order_type:'1',
					start_time:'',
					end_time:'',
					status:'1',
				},
				dute:'',
				activeName:'first',
				tableData:[
					{
						order_type:'商品订单',
						pay_money:'200.00',
						commission:'11.00',
						state:'待结算',
						mark:'实物商品订单 订单id:143 订单号: 2021022717380028279商品出单金额',
						create_time:'2021-02-26 12:23:10',
					}
				],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				channel_id:'',
				channelInfo:{}

			}
		},
		mounted() {
			if(this.$route.query.channel_id){
				this.channel_id =this.$route.query.channel_id;
				this.getData();
				this.getDetail()
				
			}
			
		},
		methods:{
			getData(){
				let data={
					channel_id:this.channel_id
				}
				channelAreClassifiedIntoTopInfo(data).then(res=>{
					this.channelInfo=res.data
				})
			},
			getDetail(){
				let data=this.search;
				data.channel_id=this.channel_id;
				data.page=this.currentPage;
				data.perPage=this.pagesize;
				channelAreClassifiedIntoDetails(data).then(res=>{
					this.total =res.data.count;
					this.tableData = res.data.list
				})
			},
			//时间选择
			changetimer(val) { //时间
				this.search.start_time = val[0].getTime()/1000;
				this.search.end_time = val[1].getTime()/1000;
			},
			handleClick(val){
				this.search.status=val.name;
				this.getDetail()
			},
			handleSizeChange(size) {
				this.pagesize = size;
				this.getDetail()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getDetail()
			},
			//搜索
			toSearch(){
				//调接口
				this.getDetail()
			},
			//重置
			toReset(){
				this.search.order_type='1';
				this.search.status='1';
				this.search.start_time='';
				this.search.end_time='';
				this.dute='';
				//调接口
				this.getDetail()
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.storeData {
		position: relative;
		height: 20px;
		display: block;
		font-size: 14px;
		line-height: 20px;
		>img{
			width: 20px;
			height: 20px;
			vertical-align: middle;
			margin-left: 8px;
			
		}
	}
	.money{
		margin-top: 10px;
		font-size: 20px;
		color: #333;
	}
	.main-cont ::v-deep.el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0;
}

	
</style>
