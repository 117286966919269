<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <div class="fin_item">
                            <p>提现申请中</p>
                            <p class="vals">{{parseFloat(forms.applyIng).toFixed(2)}}</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="fin_item">
                            <p>提现已打款</p>
                            <p class="vals">{{ parseFloat(forms.alreadyAppliy).toFixed(2)}}</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="fin_item">
                            <p>提现失败</p>
                            <p class="vals">{{ parseFloat(forms.refuseAppliy).toFixed(2)}}</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="fin_item">
                            <p>总余额</p>
                            <p class="vals">{{ parseFloat(forms.available_money_total).toFixed(2)}}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-table class="mt10" size="small" ref="multipleTable" :data="tableData" border tooltip-effect="dark" style="width: 100%"  max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column  label="提现方式" align="center">
                            <template slot-scope="scope">
                                {{scope.row.pay_type==1?'微信':'支付宝'}}
                            </template>
                        </el-table-column>
						<el-table-column prop="money" label="提现金额" align="center"></el-table-column>
						<el-table-column prop="service_fee" label="手续费"  align="center" ></el-table-column>
						<el-table-column  label="状态"  align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.pay_status==0">未支付</span>
                                <span v-else-if="scope.row.pay_status==1">已支付</span>
                                <span v-else-if="scope.row.pay_status==2">支付失败</span>
                                <span v-else-if="scope.row.pay_status==3">已撤销</span>
                            </template>
                        </el-table-column>
						<el-table-column prop="reason" label="失败原因"  align="center"></el-table-column>
						<el-table-column prop="created_at" label="提交时间" align="center"></el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {withdrawalInfo} from "@/api/merchant/users.js"
    export default{
        data(){
            return{
                loading:true,
                tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
                forms:{}
            }
        },
        mounted() {
			this.getList()
		},
        methods:{
            getList(){
				let data={
					page:this.currentPage,
					perPage:this.pagesize,
				}
				withdrawalInfo(data).then(res=>{
					this.loading=false;
                    this.forms=res.data;
					this.total=res.data.count;
					this.tableData=res.data.list;
					
				})
			},
            //分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 调接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 调接口
				this.getList()
			},
        }
    }
</script>
<style lang="scss" scoped>
.fin_item {
    background-color: #fff;
    padding: 30px 50px;

    p {
        text-align: center;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
    }

    p.vals {
        height: 37px;
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 37px;
    }
}
</style>