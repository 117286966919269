<template>
	<div>
		<!-- 图片上传组件辅助 -->
		<!-- <el-upload class="avatar-uploader quill-img" :action="uploadImgUrl" name="file" :headers="importHeaders"
			:show-file-list="false" :on-success="quillImgSuccess" :on-error="uploadError"
			:before-upload="quillImgBefore" accept='.jpg,.jpeg,.png,.gif'></el-upload> -->

		<!-- 富文本组件 -->
		<quill-editor class="editor" v-model="content" ref="quillEditor" @childByValue="childByValue"
			:options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
			@change="onEditorChange($event)"></quill-editor>

		<!-- 选择图片弹窗 -->
			<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk">
			</ChooseImg>
		
		
	</div>
</template>

<script>
	import ChooseImg from "./uploadImgs.vue";
	// import {
	// 	getToken
	// } from '@/utils/auth'
	import baseURL from '@/config/baseUrl.js'
	// 工具栏配置
	const toolbarOptions = [
		["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
		["blockquote", "code-block"], // 引用  代码块
		[{
			list: "ordered"
		}, {
			list: "bullet"
		}], // 有序、无序列表
		[{
			indent: "-1"
		}, {
			indent: "+1"
		}], // 缩进
		[{
			size: ["small", false, "large", "huge"]
		}], // 字体大小
		[{
			header: [1, 2, 3, 4, 5, 6, false]
		}], // 标题
		[{
			color: []
		}, {
			background: []
		}], // 字体颜色、字体背景颜色
		[{
			align: []
		}], // 对齐方式
		["clean"], // 清除文本格式
		// ["link", "image", "video"] // 链接、图片、视频
		["link", "image"] // 链接、图片、视频
	];

	import {
		quillEditor
	} from "vue-quill-editor";
	import "quill/dist/quill.core.css";
	import "quill/dist/quill.snow.css";
	import "quill/dist/quill.bubble.css";

	export default {
		props: {
			/* 编辑器的内容 */
			value: {
				type: String
			},
			/* 图片大小 */
			maxSize: {
				type: Number,
				default: 4000 //kb
			}
		},
		components: {
			quillEditor,
			ChooseImg
		},
		data() {
			return {
				isSingle: false,
				uploadFlag: false,
				importHeaders: {
					token: localStorage.getItem('token')
				},
				picList: [],
				content: this.value || '',
				// uploadImgUrl: "",
				editorOption: {
					placeholder: "",
					theme: "snow", // or 'bubble'
					placeholder: "请输入内容",
					modules: {
						toolbar: {
							container: toolbarOptions,
							handlers: {
								image:(value=>{
									if (value) {
										this.uploadFlag=true;
									} else {
										this.quill.format("image", false);
									}
								})
								
							}
						}
					}
				},
				uploadImgUrl: baseURL + '/Oss/platform/fileUploading', // 上传的图片服务器地址
				headers: {
					Authorization: this.getToken
				}
			};
		},
		watch: {
			value: function() {
				this.content = this.value;
			}
		},
		mounted() {
		},
		methods: {
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},

			// 点击确定
			chooseOk(val) {
				val.forEach(res => {
					this.picList = this.picList.concat([res.resource_name]);
				});
				let quill = this.$refs.quillEditor.quill;
				// 如果上传成功
				// 获取光标所在位置
				//let length = quill.getSelection().index;
				// 插入图片  res.url为服务器返回的图片地址
				let ll=999;
				val.forEach(res => {
					quill.insertEmbed(ll, "image", res.resource_name);
				})
				// 调整光标到最后
				//quill.setSelection(length + 1);
				//this.$refs.quillimg.style = 'display:none';
				this.uploadFlag=false;
			},
			childByValue(val) {
				console.log(1);
			},
			onEditorBlur() {
				//失去焦点事件
				console.log(2);
				this.$nextTick(()=>{
				this.$refs.quillEditor.quill.enable(true);
				});
			},
			onEditorFocus(event) {
				//获得焦点事件
				console.log(3);
				//this.uploadFlag=true;
			},
			onEditorChange() {
				this.$nextTick(()=>{
				  this.$refs.quillEditor.quill.enable(true);
				});
				//console.log(4);
				//this.uploadFlag=!this.uploadFlag;
				//内容改变事件
				this.$emit("input", this.content);
			},

			// 富文本图片上传前
			quillImgBefore(file) {
				//console.log(5);
				let fileType = file.type;
				if (fileType === 'image/jpeg' || fileType === 'image/png') {
					return true;
				} else {
					this.$message.error('请插入图片类型文件(jpg/jpeg/png)');
					return false;
				}
			},

			quillImgSuccess(res, file) {
				//console.log(6);
				// res为图片服务器返回的数据
				// 获取富文本组件实例
				let quill = this.$refs.quillEditor.quill;
				// 如果上传成功
				if (res.return_code == "SUCCESS") {
					// 获取光标所在位置
					let length = quill.getSelection().index;
					// 插入图片  res.url为服务器返回的图片地址
					quill.insertEmbed(0, "image", res.data[0].resource_name);
					// 调整光标到最后
					quill.setSelection(length + 1);
					this.$refs.quillImg.style = 'display:none';
				} else {
					this.$message.error("上传文件过大，请上传2M一下的文件");
				}
			},
			// 富文本图片上传失败
			uploadError() {
				//console.log(7);
				// loading动画消失
				this.$message.error("图片插入失败");
			}
		}
	};
</script>

<style>
	.editor {
		line-height: normal !important;
		height: 200px;

	}

	.quillImg {
		display: none;

	}

	.ql-snow .ql-tooltip[data-mode="link"]::before {
		content: "请输入链接地址:";
	}

	.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
		border-right: 0px;
		content: "保存";
		padding-right: 0px;
	}


	.ql-editor {
		background-color: #FFFFFF;
	}

	.ql-snow .ql-tooltip[data-mode="video"]::before {
		content: "请输入视频地址:";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item::before {
		content: "14px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
		content: "10px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
		content: "18px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
		content: "32px";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item::before {
		content: "文本";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
		content: "标题1";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
		content: "标题2";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
		content: "标题3";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
		content: "标题4";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
		content: "标题5";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
		content: "标题6";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item::before {
		content: "标准字体";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
		content: "衬线字体";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
		content: "等宽字体";
	}
</style>
