<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<!-- <el-tab-pane label="签名管理" name="first">
						<div class="signature">
							<div class="search-cont">
								<div>
									<el-input  prefix-icon="el-icon-search" v-model="sign_name" placeholder="请输入签名名称" size="small" type="text">
										<el-button size="small" type="primary" plain slot="append" @click="searchSignName">搜索</el-button>
									</el-input>
								</div>
								<div>
									<el-button type="primary" size="small" @click="signatureModify"> 添加签名</el-button>
								</div>
							</div>
							<div class="search-cont" style="margin-top:10px">
								<el-table border :data="tableData" style="width: 100%">
									<el-table-column prop="sign_name" label="签名名称" align="center">
									</el-table-column>
									<el-table-column prop="status" label="审核状态(全部)" align="center">
										<template slot-scope="scope">
											<span v-if="scope.row.status == 1">申请中</span>
											<span v-else-if="scope.row.status == 2">通过</span>
											<span v-else-if="scope.row.status == 3">审核失败</span>
											<span v-else>停用</span>
										</template>
									</el-table-column>
									<el-table-column prop="default_employ" label="默认" align="center">
										<template slot-scope="scope">
											<span v-if="scope.row.default_employ == 'Y'">使用</span>
											<span v-else>不使用</span>
										</template>
									</el-table-column>
									<el-table-column prop="created_at" label="创建时间" align="center">
									</el-table-column>
									<el-table-column  show-overflow-tooltip prop="reason" width="400px" label="备注" align="center">
									</el-table-column>
									<el-table-column prop="address" label="操作" align="center">
										<template slot-scope="scope">
											<el-button size="small" v-if="scope.row.default_employ == 'N'" type="text" @click="useSignName(scope.row)" >使用</el-button>
											<el-button size="small" v-if="scope.row.status == 3" type="text" @click="SignNameModify(scope.row)">修改</el-button>
											<el-button size="small"  v-if="scope.row.status != 1" type="text" @click="SignNameDel(scope.row)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
						 分页 -->
					<!-- 	<div class="pagecurrent">
							<div class="pagination-container">
								<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
								 :page-sizes="[10,20,30,50]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
								</el-pagination>
							</div>
						</div>
					</el-tab-pane> -->
					<el-tab-pane label="模板管理" name="first">
						<div class="signature">
							<div class="search-cont" style="padding: 20px 20px;">
								<!-- <div>
									<el-input v-model="searchParams" prefix-icon="el-icon-search" style="width: 300px;" placeholder="请输入模版名称或模版CODE" size="small" type="text">
										<el-button size="small" type="primary" plain slot="append" @click="searchModule">搜索</el-button>
									</el-input>
								</div> -->
								<div>
									<el-button type="primary" size="small" @click="moduleAdd" v-has="'添加模板'">添加模板</el-button>
									<el-button  type="primary" size="small" @click="toSet"  v-has="'设置'">设置</el-button>
								</div>
							</div>
							<div class="search-cont" style="margin-top:10px">
								<el-table border :data="moduleData" style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
									<el-table-column prop="template_name" label="模板名称" align="center">
									</el-table-column>
									<el-table-column prop="template_code" label="模版CODE" align="center">
									</el-table-column>
									<el-table-column  label="模版类型" align="center">
										<template slot-scope="scope">
											<span v-if="scope.row.template_type == 0">验证码</span>
											<span v-else-if="scope.row.template_type == 1">短信通知</span>
											<span v-else-if="scope.row.template_type == 2">推广短信</span>
											<span v-else-if="scope.row.template_type == 3">国际/港澳台消息</span>
										</template>
									</el-table-column>
									<el-table-column show-overflow-tooltip prop="template_content" label="模版内容" align="center">
									</el-table-column>
									<el-table-column prop="created_at" label="创建时间" align="center">
									</el-table-column>
									<el-table-column prop="status" label="审核状态(全部)" align="center">
										<template slot-scope="scope">
											<span v-if="scope.row.status == 1">通过</span>
											<span v-else-if="scope.row.status == 2">申请中</span>
											<span v-else-if="scope.row.status == 3">失败</span>
											<span v-else>停用</span>
										</template>
									</el-table-column>
									<el-table-column  show-overflow-tooltip prop="reason" width="200px" label="备注" align="center">
									</el-table-column>
									<el-table-column prop="address" label="操作" align="center">
										<template slot-scope="scope">
											<el-button size="small" type="text" v-if="scope.row.status != 2"  v-has="'删除'" @click="modulDel(scope.row)">删除</el-button>
											<el-button v-if="scope.row.status == 3" size="small" type="text"  v-has="'修改'" @click="modulModify(scope.row)">修改</el-button>
										</template>	
									</el-table-column>
								</el-table>
								<!-- 分页 -->
								<el-pagination class="pagecurrent" background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1"
								 :page-sizes="[10,20,30,50]" :page-size="pagesize1" layout="total, sizes, prev, pager, next, jumper" :total="total1">
								</el-pagination>
								
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	<!--设置弹框-->
		<el-dialog title="设置" :visible.sync="settingDialogModel" width="30%" >
			<el-form ref="form" :model="form" :rules="rules" label-width="130px">
				<el-form-item label="短信文档" >
						<el-link type="primary" href="https://www.aliyun.com/ss/?k=%E7%9F%AD%E4%BF%A1"  target="_blank">短信文档链接</el-link>
					</el-form-item>
				<el-form-item label="AppKey"  prop="accessKey">
						<el-input type="text" v-model="form.accessKey" size="small" class="inputWidth" ></el-input>
				</el-form-item>
				<el-form-item label="AppSecret" prop="accessSecret">
						<el-input type="text" v-model="form.accessSecret" size="small" class="inputWidth"></el-input>
				</el-form-item>
				<el-form-item label="签名" prop="sign_name">
						<el-input type="text" v-model="form.sign_name" size="small" class="inputWidth"></el-input>
				</el-form-item>
			</el-form>
			 <span slot="footer" class="dialog-footer">
				<el-button size="small" @click="settingDialogModel=false">取消</el-button>
				<el-button size="small" type="primary" @click="submitSet('form')">提交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		querySmsTemplateList,
		deleteSmsTemplate,
		//querySmsList,
		//deleteSmsSign,
		//signDefaultEmploy,
		saveConfiguration,
		getConfiguration
	} from '@/api/plat/setting.js'
	export default {
		data() {
			return {
				loading:true,
				settingDialogModel:false,
				form:{
					accessKey:'',
					accessSecret:'',
					sign_name:''
				},
				rules:{
					 accessKey: [
						{ required: true, message: '请输入AppKey', trigger: 'blur' }
					  ],
					  accessSecret: [
					  	{ required: true, message: '请输入AppSecret', trigger: 'blur' }
					  ],
					  sign_name: [
					   	{ required: true, message: '请输入签名', trigger: 'blur' }
					  ],
				},
				activeName: 'first',
				tableData: [],
				moduleData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				total1: 0,
				currentPage1: 1, //初始页
				pagesize1: 10, //每页的数据
				searchParams:'',
				sign_name:''
			}
		},
		mounted() {
			this.getQuerySmsTemplateList()
			// if(this.$route.query.name){
			// 	this.activeName = this.$route.query.name;
			// 	if(this.$route.query.name == 'second'){
			// 		this.getQuerySmsTemplateList()
			// 	}
			// };
			//短信签名列表
			//this.getQuerySmsList();
			
		},
		methods: {
			handleClick(tab) {
				if (tab.name == 'first') {
					this.getQuerySmsList()
				}
				if (tab.name == 'second') {
					this.getQuerySmsTemplateList()
				}
			},
			//模板列表
			getQuerySmsTemplateList() {
				let params = {
					search:this.searchParams,
					page: this.currentPage1,
					pagesize: this.pagesize1,
				};
				params = this.tools.filterParams(params);
				querySmsTemplateList(params).then(res => {
						this.loading=false;
						this.moduleData = res.data.list;
						this.total1 =res.data.count ;
				})
			},
			//短信签名列表
			getQuerySmsList() {
				let params = {
					sign_name:this.sign_name,
					page: this.currentPage,
					pagesize: this.pagesize,
				};
				params = this.tools.filterParams(params);
				querySmsList(params).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.tableData = res.data.list;
						this.total  =res.data.count ;
					}
				})
			},
			//搜索签名
			searchSignName(){
				this.getQuerySmsList();
			},
			//搜索模板列表
			searchModule(){
				this.getQuerySmsTemplateList()
			},
			handleSizeChange(size) {
				this.pagesize = size;
				this.getQuerySmsList();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getQuerySmsList();
			},
			handleSizeChange1(size) {
				this.pagesize1 = size;
				this.getQuerySmsTemplateList();
			},
			handleCurrentChange1(currentPage) {
				this.currentPage1 = currentPage;
				this.getQuerySmsTemplateList();
			},
			signatureModify() {
				this.$router.push('/settingadmin/domesticnews/signaturemodify')
			},
			//模块管理添加
			moduleAdd() {
				this.$router.push('/settingadmin/domesticnews/modulemodify')
			},
			//模块管理修改
			modulModify(row){
				this.$router.push({
					path: '/settingadmin/domesticnews/modulemodify',
					query: {
						id: row.sms_template_id
					}
				})
			},
			//签名修改
			SignNameModify(row){
				this.$router.push({
					path: '/settingadmin/domesticnews/signaturemodify',
					query: {
						id: row.sms_sign_id,
						name:row.sign_name,
					}
				})
			},
			//模块管理删除
			modulDel(row){
				this.$confirm('确定要删除此短信模板吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//调接口
					let data={
						template_code:row.template_code
					}
					data = this.tools.filterParams(data);
					deleteSmsTemplate(data).then(res=>{
						if(res.return_code == 'SUCCESS'){
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.getQuerySmsTemplateList();
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					})
				})
				
			},
			//删除签名
			SignNameDel(row){
				this.$confirm('确定要删除此签名吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//调接口
					let data={
						sms_sign_id:row.sms_sign_id,
						sign_name:row.sign_name
					}
					data = this.tools.filterParams(data);
					deleteSmsSign(data).then(res=>{
						if(res.return_code == 'SUCCESS'){
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.getQuerySmsList();
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					})
				})
				
			},
			//使用签名
			useSignName(row){
				this.$confirm('确定要使用此签名吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//调接口
					let data={
						sms_sign_id:row.sms_sign_id
					}
					data = this.tools.filterParams(data);
					signDefaultEmploy(data).then(res=>{
						if(res.return_code == 'SUCCESS'){
							this.getQuerySmsList();
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					})
				})
			},
			//设置
			toSet(){
				this.settingDialogModel = !this.settingDialogModel
				this.getConfigurationInfo();
			},
			//设置详情
			getConfigurationInfo(){
				getConfiguration({}).then(res=>{
					this.form=res.data
				})
			},
			//设置提交
			submitSet(form){
				this.$refs['form'].validate((valid) => {
					if (valid) {
						//调接口
						let data=JSON.parse(JSON.stringify(this.form));
						data=this.tools.filterParams(data);
						saveConfiguration(data).then(res=>{
							this.settingDialogModel = !this.settingDialogModel;
							this.getQuerySmsTemplateList()
						})
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.signature {
		padding: 15px;
		border-radius: 6px;
		// min-height: 300px;
		background-color: #f2f2f6;
		margin-bottom: 20px;

	}

	.search-cont {
		border-radius: 8px;
		background-color: #fff;
		padding: 10px 50px 10px 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
</style>
