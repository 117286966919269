<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<!-- <div style="background-color:#fff;width: 200px;margin: 0 40px;height: 70px;line-height: 70px;border-radius: 10px;text-align: center ;">
					<span>当前可参与分红人数： <span style="margin-left: 5px;font-size: 20px;font-weight: 500;">{{ this.allCount }}</span></span>
				</div>
				<div class="dj">
					<div v-for="(item,index ) in levelData" :key="index" style="background-color:#fff;width: 200px;margin: 0 40px;height: 30px;line-height: 30px;border-radius: 10px;text-align: center ;">等级 <span style="font-size: 16px;font-weight: 500;color:#8583cd;">{{item.name}} </span>人数: <span style="font-size: 20px;font-weight: 500;">{{ item.count }}</span></div>
				</div> -->
			<div style="background-color: #fff;border-radius: 10px; margin-top: -0px;padding: 10px;box-sizing: border-box;">
				<div class="box" style="display: flex;">
					<div style="background-color:#F2F2F6;width: 200px;margin: 0 40px;height: 70px;line-height: 70px;border-radius: 10px;text-align: center ;">
					<span style="font-size: 14px;font-weight: 700;">当前可参与分红人数： <span style="margin-left: 5px;font-size: 20px;font-weight: 500;color: #1b17a7;">{{ this.allCount }}</span></span>
				</div>
				<div style="flex:1; display: flex;width: 500px;margin-top: -30px;">
					<div class="dj" style=" flex-wrap: wrap;">
					<div v-for="(item,index ) in levelData" :key="index" style="background-color:#F2F2F6;width: 260px;margin: 10px 40px;height: 50px;line-height: 50px;border-radius: 10px;text-align: center ;"> <span :class="'listIcon'+(index+1)">{{item.name}} </span>可分红人数: <span style="font-size: 20px;font-weight: 500;">{{ item.count }}</span></div>
				</div>
				</div>
				</div>
				<!-- <div class="dj" style="margin: 20px 0 0 280px;background-color:#F2F2F6;width: 1060px;margin-left:320px; border-radius: 10px;">
					<div style="background-color:#F2F2F6;width: 400px;margin: 0 40px;height: 70px;line-height: 70px;border-radius: 10px;text-align: center ;font-size: 14px;font-weight: 700;">当前未结算业绩总额: ￥<span style="color:#f42f0c;font-size: 20px;font-weight: 500;" >{{ this.un_settlement }}</span></div>
					<div style="background-color:#F2F2F6;width: 400px;margin: 0 40px;height: 70px;line-height: 70px;border-radius: 10px;text-align: center ;font-size: 14px;font-weight: 700;">当前已结算业绩总额: ￥<span style="color:#2ede7a;font-size: 20px;font-weight: 500;">{{ this.settled }}</span></div>
				</div> -->
				
			</div>
			<div style="margin-top: 20px;">
					<el-table class="mt10" ref="multipleTable" :data="tableData" size="small" border tooltip-effect="dark"
			max-height="550" style="width: 100%" :header-cell-style="{ background: '#f3f8fe', color: '#606060' }" v-loading="loading"
			element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0.2)">
			<el-table-column prop="dividend_date" label="时间" align="center"></el-table-column>
			<el-table-column prop="amount" label="业绩" align="center"></el-table-column>
			<el-table-column prop="reuse_money_level2" :label="level1+'沉淀分红金额'" align="center"></el-table-column>
			<el-table-column prop="reuse_money_level3" :label="level2+'沉淀分红金额'" align="center"></el-table-column>
			<el-table-column prop="reuse_money_level4" :label="level3+'沉淀分红金额'" align="center"></el-table-column>
			<el-table-column prop="reuse_money_level5" :label="level4+'沉淀分红金额'" align="center"></el-table-column>
			<el-table-column prop="reuse_money_level6" :label="level5+'沉淀分红金额'" align="center"></el-table-column>
			<el-table-column prop="reuse_money_level7" :label="level6+'沉淀分红金额'" align="center"></el-table-column>
			<el-table-column prop="commission_state" label="结算状态" align="center">
				<template #default="{row}">
					<div v-if="row.commission_state==1">未结算</div>
					<div v-if="row.commission_state==2">已结算</div>
				</template>
			</el-table-column>
			<el-table-column prop="settle_time" label="结算时间" sortable align="center"></el-table-column>
		</el-table>
				</div>
			</div>
		</div>
		

	<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
			layout="total, sizes, prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>

<script>
import {
	statistics,
	list,
} from "@/api/merchant/finance.js"
export default {
	data() {
		return {
			tableData:[],
			loading:true,
			currentPage: 1, //初始页
      pagesize: 10, //每页的数据
			total: 0,
			allCount:0,
			settled:0,
			un_settlement:0,
			levelData: [],
			level2:'',
			level3:'',
			level5:'',
			level6:'',
			level4:'',
			level1:'',
		
	}
},
	mounted() {
		this.statistics()
		this.list()
	},
	methods: {
		handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
        },
				handleSizeChange(size) {
            this.pagesize = size;
        },

		// 	toDel() {
		// 		this.tools.confirm("确定要删除？")
		// }

		statistics(){
			statistics({}).then(res => {
        // console.log(res)
        this.levelData = res.data.levelData
        this.allCount = res.data.allCount
        this.settled = res.data.settled
        this.level1 = res.data.levelData[0].name
        this.level2 = res.data.levelData[1].name
        this.level3 = res.data.levelData[2].name
        this.level4 = res.data.levelData[3].name
        this.level5 = res.data.levelData[4].name
        this.level6 = res.data.levelData[5].name
        this.un_settlement = res.data.un_settlement
        this.total = res.data.total
      })
		},
		list(){
			list({}).then(res => {
        // console.log(res)
        this.tableData = res.data.list
        this.loading = false
				this.count = res.data.count
      })
		}
	}
}
</script>

<style lang="scss" scoped="scoped">
.wrapper-cont {
	font-size: 14px;
	// font-weight: 500;

	div {
		margin-top: 20px;
	}

	.dj {
		display: flex;
		.listIcon1{
			font-size: 16px;
			font-weight: 700;
			color:#ffcc33;
		}
		.listIcon2{
			font-size: 16px;
			font-weight: 700;
			color:#ff6666;
		}
		.listIcon3{
			font-size: 16px;
			font-weight: 700;
			color:#00cc99;
		}
		.listIcon4{
			font-size: 16px;
			font-weight: 700;
			color:#3399ff;
		}
		.listIcon5{
			font-size: 16px;
			font-weight: 700;
			color:#cc33ff;
		}
		.listIcon6{
			font-size: 16px;
			font-weight: 700;
			color:#ff5050;
		}

		div {
			width: 400px;
			margin-top: 0
		}
	}
}
</style>
