<template>
	<div class="merchant_login">
		<div class="logopic">
			<img src="../../assets/img/cown.png" class="logoimg" />
			<span>众康联  | 平台后台业务系统</span>
		</div>
		<div class="logintxt">
			<div class="title">志同道合 合作共赢</div>
			<div class="text">一群人   一件事   一起拼   一起赢</div>
		</div>
		<div class="merchant_login_cont">
			<div class="tabs">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="商户登录" name="first">
						<merchantLogin :codeShow="codeShow" ref="getCodeData"></merchantLogin>
					</el-tab-pane>
					<el-tab-pane label="服务商登录" name="second">
						<channelLogin></channelLogin>
					</el-tab-pane>
				</el-tabs> 
			</div>
			<!--扫码登录提示-->
			<div v-if="activeName == 'first' && tipShow == false">
				<div class="logincode"  @click="scanCode">
					<img src="../../assets/img/login/logincode.png" />
				</div>
				<div class="logintip" >
					<img src="../../assets/img/login/codetip.png" />
					<span>扫码登录更便捷</span>
				</div>
			</div>

			<!--用户登录提示-->
			<div v-else-if="activeName == 'first' && tipShow == true">
				<div class="logincode"  @click="toUser">
					<img src="../../assets/img/login/userlogin.png" />
				</div>
				<div class="logintip">
					<img src="../../assets/img/login/userlogintip.png" />
					<span>账号登录</span>
				</div>
			</div>
			<div class="loginxy" @click="openAgreen">登录后表明您同意<span>众康联登录服务协议</span></div>
		</div>
		<!--弹框-->
		<el-dialog title="众康联用户入驻协议" center  :visible.sync="agreementModel" width="40%" >
			<div class="agreeCont" >
				<Editor  v-model="agreenData"/>
			</div>
			
		</el-dialog>
	</div>

</template>

<script>
	import merchantLogin from './merchant_login.vue';
	import channelLogin from './channel_login.vue'
	import Editor from '../common/loginEditor';
	import {
		getProtocol
	} from '@/api/plat/platlogin'
	export default {
		components:{
			merchantLogin,
			channelLogin,
			Editor
		},
		data() {
			return {
				agreementModel:false,
				agreenData:'',
				activeName: 'first',
				tipShow:false,
				codeShow:false,
			}
		},
		created() {
			this.$store.commit('LOGIN_OUT');
			localStorage.removeItem('dataset');
			localStorage.removeItem('head_portrait');
			localStorage.removeItem('terminal');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('shop_name');
			localStorage.removeItem('dataset');
			if (location.href.indexOf("#reloaded") == -1) {
				location.href = location.href + "#reloaded";
				window.location.reload()
			}
		},
		mounted(){
			
			if(localStorage.getItem("activeName")){
				this.activeName=localStorage.getItem("activeName")
			}

		},
		methods: {
			openAgreen(){
				this.agreementModel = !this.agreementModel;
				this.getProtocolData()
			},
			getProtocolData(){
				let data={
					type:1
				}
				getProtocol(data).then(res=>{
					this.agreenData=res.data;
				})
			},
			//菜单切换
			handleClick(val) {
				this.codeShow=false;
				this.tipShow=false;
			},
			//扫码按钮点击
			scanCode(){
				//console.log(this.$refs.getCodeData,"333")
				this.$refs.getCodeData.getRcode();
				this.codeShow=!this.codeShow;
				this.tipShow = !this.tipShow;
			},
			//账号登录
			toUser(){
				this.codeShow=!this.codeShow;
				this.tipShow = !this.tipShow
			},
		
			
			

		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/css/login.scss";

	.tabs::v-deep.el-tabs__nav {
		margin-left: 27%;
		font-size: 16px;
	}

	.tabs::v-deep.el-tabs__item {
		font-size: 16px;
		padding: 0 50px;
	}

	.login-cont {
		width: 420px;
		margin: 0 auto;
	}
	::v-deep.el-tabs__item.is-active {
    color: #409EFF;
	font-weight: bold;
}

	.titles {
		margin: 35px 0;
	}
	//切换菜单
	.item-title {
		    // width: 100%;
			display: flex;
			margin-bottom: 30px;
		}
		.item-title li{
			list-style: none;
			width: 100px;
			line-height: 34px;
			height: 34px;
			color: #999;
			font-size: 16px;
			cursor: pointer;
			text-align: center;
			margin-right:30px

		}
		.item-title li.active{
			width: 100px;
			height: 34px;
			line-height: 34px;
			border-radius: 4px;
			color: #333300;
			font-size: 20px;
		}
		::v-deep.ql-container.ql-snow {
		   border: none;
		   padding: 0;
		}
		::v-deep.ql-toolbar.ql-snow{
			border: none;
			padding: 0;
		}
		.agreeCont{
			width: 100%;
			min-height: 27.5rem;
			 overflow-y: scroll;
		}
		
		::v-deep.ql-editor{
			overflow-y: scroll;
		}
		::v-deep.ql-editor::-webkit-scrollbar { width: 0 !important;display: none; }
		 ::v-deep.ql-editor { -ms-overflow-style: none; }
		    ::v-deep.ql-editor { overflow: -moz-scrollbars-none; }
		::v-deep.ql-editor::-webkit-scrollbar { width: 0 !important }
		 
		::v-deep.ql-editor { -ms-overflow-style: none; }
		 
		::v-deep.ql-editor { overflow: -moz-scrollbars-none; }
</style>
