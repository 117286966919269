import axios from '@/config/httpConfig'
import qs from  'qs'
//获取导航配置列表
export const getWebpageStoreBinding=(params)=> {
    return axios('get','/WebpageConfiguration/getWebpageStoreBinding', params)
}
// 删除指定导航
export const delWebpageStoreBinding=(data)=> {
    return axios('post','/WebpageConfiguration/delWebpageStoreBinding', data);
}
// 保存导航信息
export const setWebpageStoreBinding=(data)=> {
    return axios('post','/WebpageConfiguration/setWebpageStoreBinding', data);
}
// 获取链接列表
export const getPageLink=(data)=> {
    return axios('post','/WebpageConfiguration/getPageLink', data);
}
//发布导航
export const publish=(params)=> {
    return axios('get','/WebpageConfiguration/publish', params)
}
