<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card shadow="never">
                    <el-row class="all_search_list" style="margin-bottom:0">
                        <div class="demo-input-suffix">
                            <el-input placeholder="请输入内容" v-model="search.search" size="small" class="input-with-select"
                                @keyup.enter.native="searchOrder" clearable>
                                <el-select v-model="search.type" slot="prepend" clearable placeholder="请选择">
                                    <el-option label="昵称" value="1"></el-option>
                                    <el-option label="手机号" value="2"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                        <div class="demo-input-suffix">
                            <el-button type="primary" size="small" @click="searchOrder">搜索</el-button>
                            <el-button size="small" @click="toResetOrder">重置</el-button>
                        </div>
                    </el-row>
                </el-card>
                <el-table class="mt10" ref="multipleTable" :data="tableData" size="small" border to oltip-effect="dark"
                    max-height="550" style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
                    v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.2)">
                    <el-table-column label="名称" align="center">
                        <template slot-scope="scope">
                            <div class="flex-align-center ">
                                <div style="width:70px;height:70px;">
                                    <el-image style="width: 100%;height: 100%;" :src="scope.row.head"
                                        :preview-src-list="[scope.row.head]" />
                                </div>
                                <span class="ml10">{{scope.row.nickname}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="用户手机号" align="center"></el-table-column>
                    <el-table-column prop="leave" label="等级" align="center"></el-table-column>
                    <el-table-column label="成交" align="center">
                        <template slot-scope="scope">
                            <p>订单：{{scope.row.order_num}}</p>
                            <p>金额：{{scope.row.order_money}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="储值余额" align="center">
                        <template slot-scope="scope">
                            {{scope.row.available_money}}
                            <el-button type="text" @click="toChange(scope.row)">充值</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="注册时间" align="center"></el-table-column>
                    <el-table-column align="center">
                        <template slot-scope="scope">
                            <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10,20,30,50]"
                    :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>

        </div>
        <!----充值弹框-->
        <el-dialog title="调整储值余额" :visible.sync="dialogVisible" width="25%">
            <el-form :model="forms" ref="formref" label-width="100px" class="demo-dynamic">
                <el-form-item prop="money" label="储值余额：" :rules="[
                  { required: true, message: '请输入金额', trigger: 'blur' },
                ]">
                    <el-input placeholder="请输入金额" v-model="forms.money" oninput="value=value.replace(/[^0-9.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" class="input-with-select" size="small">
                        <el-select v-model="forms.regulation_type" slot="prepend" placeholder="请选择">
                            <el-option label="增加" value="1"></el-option>
                            <el-option label="减少" value="2"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
            </el-form>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('formref')" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { shopVipList, shopUserCapital } from "@/api/merchant/users.js"
export default {
    data() {
        return {
            search: {
                type: '',
                search: ''
            },
            tableData: [],
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
            loading: true,
            dialogVisible: false,
            forms: {
                money: '',
                regulation_type: '1'
            },
            account_user_id: ''

        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            let data = this.search;
            data.page = this.currentPage;
            data.perPage = this.pagesize;
            shopVipList(data).then(res => {
                this.loading = false;
                this.tableData = res.data.list;
                this.total = res.data.count

            })
        },
        //分页
        handleSizeChange(size) {
            this.pagesize = size;
            // 调接口
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            // 调接口
            this.getList()
        },
        //搜索
        searchOrder() {
            this.currentPage = 1;
            //调接口
            this.getList()
        },
        toResetOrder() {
            this.currentPage = 1;
            this.search.search = '';
            this.search.type = '';
            this.getList()
        },
        //查看
        toDetail(row) {
            this.$router.push({
                path: '/customeradmin/storemember/storememberdetail',
                query: {
                    account_user_id: row.account_user_id
                }
            })
        },
        //充值
        toChange(row) {
            this.account_user_id = row.account_user_id
            this.forms.money = '';
            this.dialogVisible = true

        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = this.forms;
                    data.account_user_id = this.account_user_id
                    shopUserCapital(data).then(() => {
                        this.$message({
                            message: '充值成功',
                            type: 'success'
                        });
                        this.dialogVisible = false;
                        this.getList()
                    })
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped="scoped">
::v-deep.el-select .el-input {
    width: 100px;
}

::v-deep.input-with-select .el-input-group__prepend {
    background-color: #fff;
}
</style>