<template>
	<div class="wrapper-main">
		<div class="wrapper-cont">
			<div class="main-cont bgwt pp20">
				<el-row :gutter="20">
					<el-col :span="10">
						<div class="grid-content bg-purple">
							<el-button type="primary" size="small" @click="addSeckill"  v-has="'新建活动'" >新建活动</el-button>
							<el-button size="small" @click="delSeckill" v-has="'批量删除'" >批量删除</el-button>
						</div>
					</el-col>
					<el-col :span="14">
						<div class="grid-content bg-purple" style="text-align: right;">
							<el-row class="all_search_list" style="margin-bottom: 0;">
								<div class="demo-input-suffix">
									<el-input class="inputWidth" prefix-icon="el-icon-search" clearable
										v-model="activity_name" placeholder="请输入活动名称"  @keyup.enter.native="toSearch"  size="small" type="text">
									</el-input>
								</div>
								<div class="demo-input-suffix">
									<el-button type="primary" size="small" @click="toSearch">搜索
									</el-button>
								</div>
								<div class="demo-input-suffix">
									<el-button size="small" @click="toReset">重置</el-button>
								</div>
							</el-row>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="main-cont tableMain mt10">
				<el-table :data="tableData" style="width: 100%" border  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..."  element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"  @selection-change="handleSelectionKillChange">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column label="活动名称" prop="activity_name" align="center"></el-table-column>
					<el-table-column label="活动时间" align="center" width="250">
						<template slot-scope="scope">
							<div>开始时间：<span>{{scope.row.activity_start_time}}</span></div>
							<div>结束时间：<span>{{scope.row.activity_end_time}}</span></div>
						</template>
					</el-table-column>
					<el-table-column label="活动商品" prop="total" align="center"></el-table-column>
					<el-table-column label="活动状态" align="center" width="200px">
						<template slot-scope="scope">
							<span style="color: #F7B500;" v-if="scope.row.status == '0'">待开始</span>
							<span style="color: #FA6400;" v-else-if="scope.row.status == '1'">进行中</span>
							<span style="color: #E02020;" v-else-if="scope.row.status == '2'">停止</span>
							<span style="color: #E02020;" v-if="scope.row.status == '3'">已结束</span>
						</template>
					</el-table-column>
					<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
					<el-table-column label="停止时间" prop="activity_end_time" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.stop_time == null">--</span>
							<span v-else>{{scope.row.stop_time}}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button style="padding: 5px 0;" type="text" size="small" @click="toDetail(scope.row)" v-has="'查看'" >查看
							</el-button>
							<el-button style="padding: 5px 0;" type="text" size="small" @click="toData(scope.row)" v-has="'数据'" >数据
							</el-button>
							<el-button style="padding: 5px 0;color: #E02020;" v-if="scope.row.status != '1'" type="text"
								size="small" @click="toDel(scope.row)" v-has="'删除'" >删除</el-button>
							<br />
							<el-button style="padding: 5px 0px;" v-if="scope.row.status == '0'" type="text" size="small"
								@click="toEdit(scope.row)" v-has="'编辑'" >编辑
							</el-button>
							<el-button v-if="scope.row.status == '1'" style="padding: 5px 0px;" type="text" size="small"
								@click="toStop(scope.row)" v-has="'停止'" >停止
							</el-button>
							<el-button style="padding: 5px 0px;" type="text" size="small"
								@click="toSistribution(scope.row)" v-has="'分配'" >分配</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"  @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>

		<el-dialog title="选择行业分配" :visible.sync="dialogIndustryVisible" width="58%">
			<div class="all_search_list">
				<div class="demo-input-suffix">
					<el-input prefix-icon="el-icon-search" class="inputWidth" v-model="industry_name"
						placeholder="请输入行业名称" size="small" type="text">
					</el-input>
				</div>
				<div class="demo-input-suffix">
					<el-button size="small" type="primary" plain @click="searchIndustry()">搜索</el-button>
					<el-button size="small" plain @click="toResetIndustry()">重置</el-button>
				</div>
			</div>

			<el-table :data="industryData" style="width: 100%;" border class="mt10" ref="multipleTable"
				@selection-change="handleIndustryChange" height="400">
				<el-table-column type="selection" width="55" align="center">
				</el-table-column>
				<el-table-column prop="trade_name" label="行业名称" align="center">
				</el-table-column>
				<el-table-column prop="sku_sale_price" label="行业状态" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.state == '0'">
							禁用
						</span>
						<span v-else-if="scope.row.state == '1'">
							启用
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="sku_purchase_price" label="是否分配" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.is_distribution == '0'">
							未分配
						</span>
						<span style="color: rgb(230, 162, 60)" v-else-if="scope.row.is_distribution == '1'">
							已分配
						</span>
					</template>
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogIndustryVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddIndustry" size="small">确 定</el-button>
			</span>
			<!-- 分页 -->
			<!-- <div class="pagecurrent">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizeChangeIndustry"
						@current-change="handleCurrentChangeIndustry" :current-page="currentPageIndustry"
						:page-sizes="[10,20,30,50]" :page-size="pagesizeIndustry"
						layout="total, sizes, prev, pager, next, jumper" :total="totalIndustry">
					</el-pagination>
				</div>
			</div> -->
		</el-dialog>
	</div>
</template>

<script>
	import {
		secondskillActivityList, //秒杀列表
		distributionIndustry, //分配行业
		updateIndustry, //更新分配
		secondskillActivityDel, //删除活动
		stopSecondskillActivity, //停止活动
		secondskillActivityBatchDel, //批量删除
	} from '@/api/plat/skillactivity';
	export default {
		data() {
			return {
				loading:true,
				activity_name: '',
				tableData: [],
				multipleSelection: [],
				//分配行业弹框
				dialogIndustryVisible: false,
				industry_name: '',
				industryData: [],
				multipleSelectionAll: [], // 所有选中的数据包含跨页数据
				multipleIndustrySelection: [],
				checkedData: [], // 初始化弹窗选中的行
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				seconds_kill_activity_id: '',
				//行业分页
				totalIndustry: 0,
				currentPageIndustry: 1, //初始页
				pagesizeIndustry: 100, //每页的数据
				idKey: "marketing_trade_id", // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
				multipleaa:[]
			}
		},
		mounted() {
			this.getList()
			this.$nextTick(function() {
				// 模拟初始化一个选中
				this.checkedData = []
			})

		},
		watch: {
			'checkedData': {
				handler(val) {
					// 转换一下目的是为了不被同步
					this.multipleSelectionAll = JSON.parse(JSON.stringify(val));
				},
				immediate: true,
				deep: true
			}
		},
		methods: {
			//秒杀列表
			getList() {
				let data = {
					keyword: this.activity_name,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				secondskillActivityList(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
					
					
				})
			},

			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//添加秒杀
			addSeckill() {
				this.$router.push({
					path: "/marketingadmin/timeseckill/seckillset",
					query: {
						type: 'add'
					}
				})

			},
			//删除秒杀
			delSeckill() {
				if (this.multipleSelection.length <= 0) {
					this.$message.warning("请选择秒杀商品！")
					return;
				}
				this.tools.confirm("确定批量删除此活动吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							seconds_kill_activity_id: this.multipleSelection.toString()
						}
						secondskillActivityBatchDel(data).then(res => {
							this.getList();
							this.$message.success("删除成功！")
						})
					}
				})

			},
			//搜索
			toSearch() {
				this.currentPage = 1;
				this.getList()
			},
			//重置
			toReset() {
				this.activity_name = '';
				this.currentPage = 1;
				this.getList()
			},
			//活动多选
			handleSelectionKillChange(val) {
				let ids = [];
				val.forEach(item => {
					ids.push(item.seconds_kill_activity_id)
				})
				this.multipleSelection = ids;
			},
			//查看
			toDetail(row) {
				this.$router.push({
					path: "/marketingadmin/timeseckill/seckillset",
					query: {
						id: row.seconds_kill_activity_id,
						type: 'detail'
					}
				})

			},
			//单个删除
			toDel(row) {
				this.tools.confirm("确定要删除此活动吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							seconds_kill_activity_id: row.seconds_kill_activity_id
						}
						secondskillActivityDel(data).then(res => {
							this.getList();
							this.$message.success("删除成功！")
						})
					}
				})

			},
			//查看数据
			toData(row) {
				this.$router.push({
					path: "/marketingadmin/timeseckill/seckilldata",
					query: {
						id: row.seconds_kill_activity_id
					}
				})
			},
			//编辑
			toEdit(row) {
				this.$router.push({
					path: "/marketingadmin/timeseckill/seckillset",
					query: {
						id: row.seconds_kill_activity_id,
						type: 'edit'
					}
				})

			},
			//停止
			toStop(row) {
				this.tools.confirm("确定要停止此活动吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							seconds_kill_activity_id: row.seconds_kill_activity_id
						}
						stopSecondskillActivity(data).then(res => {
							this.getList();
							this.$message.success("停止成功！")
						})
					}
				})

			},
			//分配
			toSistribution(row) {
				this.seconds_kill_activity_id = row.seconds_kill_activity_id;
				// 初始化渲染
				this.currentPageIndustry = 1;
				this.$nextTick(()=>{
					this.getDistributionIndustry();
					
				})
				
				this.dialogIndustryVisible = true;

			},

			//分页
			handleSizeChangeIndustry(size) {
				// 改变页的时候调用一次
				this.changePageCoreRecordData();
				this.pagesizeIndustry = size;
				this.getDistributionIndustry()
			},
			handleCurrentChangeIndustry(currentPage) {
				// 改变页的时候调用一次
				this.changePageCoreRecordData();
				this.currentPageIndustry = currentPage;
				this.getDistributionIndustry()
			},
			//多选
			handleIndustryChange(val) {
				this.multipleIndustrySelection = val;
			},
			//搜索行业
			searchIndustry() {
				this.getDistributionIndustry()
			},
			toResetIndustry() {
				this.industry_name = '',
				this.currentPageIndustry = 1;
				this.getDistributionIndustry()

			},

			// 得到选中的所有数据
			getAllSelectionData() {
				// 再执行一次记忆勾选数据匹配，目的是为了在当前页操作勾选后直接获取选中数据
				this.changePageCoreRecordData();

			},
			// 设置选中的方法
			setSelectRow() {
				if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
					return;
				}
				// 标识当前行的唯一键的名称
				let idKey = this.idKey;
				let selectAllIds = [];
				let that = this;
				this.multipleSelectionAll.forEach(row => {
					selectAllIds.push(row[idKey]);
				});
				this.$refs.multipleTable.clearSelection();
				for (var i = 0; i < this.industryData.length; i++) {
					if (selectAllIds.indexOf(this.industryData[i][idKey]) >= 0) {
						// 设置选中，记住table组件需要使用ref="table"
						this.$refs.multipleTable.toggleRowSelection(this.industryData[i], true);
					}
				}
			},
			// 记忆选择核心方法
			changePageCoreRecordData() {
				//console.log(this.multipleSelectionAll,"this.multipleSelectionAll")
				// 标识当前行的唯一键的名称
				let idKey = this.idKey;
				let that = this;
				// 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
				if (this.multipleSelectionAll.length <= 0) {
					this.multipleSelectionAll = this.multipleIndustrySelection;
					return;
				}
				// return
				// 总选择里面的key集合
				let selectAllIds = [];
				this.multipleSelectionAll.forEach(row => {
					selectAllIds.push(row[idKey]);
				});
				
				// 获取当前页选中的id
				let selectIds = [];
				this.multipleIndustrySelection.forEach(row => {
					selectIds.push(row[idKey]);
					// 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
					if (selectAllIds.indexOf(row[idKey]) < 0) {
						that.multipleSelectionAll.push(row);
					}
				});
				// 得到当前页没有选中的id
				let noSelectIds = [];
				this.industryData.forEach(row => {
					if (selectIds.indexOf(row[idKey])  < 0) {
						noSelectIds.push(row[idKey]);
					}
				});
				noSelectIds.forEach(id => {
					if (selectAllIds.indexOf(id) >= 0) {
						for (let i = 0; i < that.multipleSelectionAll.length; i++) {
							if (that.multipleSelectionAll[i][idKey] == id) {
								// 如果总选择中有未被选中的，那么就删除这条
								that.multipleSelectionAll.splice(i, 1);
								break;
							}
						}
					}
				});
			},
			//分配行业
			getDistributionIndustry() {
				let data = {
					seconds_kill_activity_id: this.seconds_kill_activity_id,
					keyword: this.industry_name,
					page: this.currentPageIndustry,
					pageSize: this.pagesizeIndustry
				}
				data = this.tools.filterParams(data);
				distributionIndustry(data).then(res => {
					this.totalIndustry = res.data.count;
					this.industryData = res.data.list;
						this.$nextTick(function() {
							// 初始化渲染
							this.multipleSelectionAll=res.data.industryData;
							this.setSelectRow();
						})
					
				})
			},
			getAllSelectionData() {
				// 再执行一次记忆勾选数据匹配，目的是为了在当前页操作勾选后直接获取选中数据
				this.changePageCoreRecordData();
			},
			//确认分配行业
			sureAddIndustry() {
				this.changePageCoreRecordData();
				
				if (this.multipleSelectionAll.length <= 0) {
					this.$message({
						message: '无勾选数据！',
						type: 'warning'
					});
					return;
				};
				
				let selectAlls = JSON.parse(JSON.stringify(this.multipleSelectionAll));
				let multipleIndustry = [];
				selectAlls.forEach(item => {
					multipleIndustry.push(item.marketing_trade_id)
				})
				let data = {
					seconds_kill_activity_id: this.seconds_kill_activity_id,
					marketing_trade_id: multipleIndustry.toString()
				}
				updateIndustry(data).then(res => {
					this.dialogIndustryVisible = false;
					this.getList()

				})

			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
