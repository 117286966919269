<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-input prefix-icon="el-icon-search" class="inputWidth" v-model="search.keysward"
						placeholder="用户昵称/手机号" size="small" type="text"  @keyup.enter.native="searchOrder" clearable>
						<!-- <el-button size="small" type="primary" plain slot="append">
							<span @click="searchOrder()">搜索</span>
						</el-button> -->
					</el-input>
					<el-button type="primary" size="small" @click="searchOrder" style="margin-left: 10px;">查询</el-button>
					<el-button size="small" @click="toReset">重置</el-button>
				</div>
				<div class="tableMain">
					<el-table :data="tableData" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" style="width: 100%" min-height="250" border max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}">
						<el-table-column prop="nickname" label="昵称" align="center">
						</el-table-column>
						<el-table-column prop="mobile" label="手机号码" align="center">
						</el-table-column>
						<el-table-column prop="shop_info.shop_name" label="所属店铺" align="center">
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.status == '1'">正常</span>
								<span v-else>禁用</span>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="注册时间" align="center">
						</el-table-column>
						<el-table-column label="操作" width="150" align="center">
							<template slot-scope="scope">
								<el-button type="text" size="small" class="el_edits">
									<div v-show="scope.row.status == '2'" class="el_state"
										@click="upOrdown(scope.row, 1)" v-has="'启用'" >
										启用
										<img src="../../../../../../assets/img/downs.png" style="margin-left: 10px;" />
									</div>
									<div v-show="scope.row.status == '1'" class="el_state"
										@click="upOrdown(scope.row, 2)" v-has="'禁用'" >
										禁用<img src="../../../../../../assets/img/ups.png" style="margin-left: 10px;" />
									</div>
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getAccountUserList,
		saveAccountUserStatus
	} from '@/api/plat/business'
	export default {
		data() {
			return {
				loading:true,
				search: {
					keysward: '',
				},
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let data = {
					search: this.search.keysward,
					page: this.currentPage,
					perPage: this.pagesize,
				}
				delete data['keys_type'];
				data = this.tools.filterParams(data);
				getAccountUserList(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list

				})
			},
			//搜索
			searchOrder() {
				this.currentPage=1;
				this.getList()
			},
			toReset(){
				this.currentPage=1;
				this.search.keysward='';
				this.getList()
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//禁用启用
			upOrdown(row, type) {
				this.$confirm(type == 1 ? '确定要启用吗？' : '确定要禁用吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						account_user_id: row.account_user_id,
						status: type == 1 ? 1 : 2
					}
					saveAccountUserStatus(data).then(() => {
						this.getList()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					})
				})
			},

		}
	}
</script>

<style>
</style>
