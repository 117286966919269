<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="order-search"  style="margin-bottom:0;margin-top: 0;">
					<el-row class="all_search_list" style="margin-bottom: 0;">
						<div class="demo-input-suffix">
							<el-input prefix-icon="el-icon-search" v-model="search.search"
								placeholder="客户名称/联系电话"
								size="small" type="text" @keyup.enter.native="toSearch" clearable>
							</el-input>
						</div>
						<div class="demo-input-suffix" style="font-size: 13px;">
							<div class="block">
								<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
									range-separator="至" start-placeholder="开始日期"  end-placeholder="结束日期"  :default-time="['00:00:00', '23:59:59']"
									@change="changetimer"></el-date-picker>
							</div>
						</div>
						<div class="demo-input-suffix" >
							<el-select style="width: 120px;" size="small" placeholder="客户行业"
								v-model="search.marketing_trade_id" clearable>
								<el-option v-for="(item,index) in tradeList" :key="index"  :label="item.trade_name" :value="item.marketing_trade_id"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix" >
							<el-select  style="width: 120px;"  size="small" v-model="search.is_pay" placeholder="客户状态">
								<el-option label="未付费" value="1"></el-option>
								<el-option label="已付费" value="2"></el-option>
							</el-select>
						</div>
						<el-button type="primary" size="small" @click="toSearch">查询</el-button>
						<el-button  size="small" @click="toReset">重置</el-button>
					</el-row>
				</div>
				<!--表格-->
				<div class="tableMain" >
					<!-- <div class="tableTile">客户列表</div> -->
					<el-table :data="tableData" style="width: 100%" min-height="250" border max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="selection" width="55" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="创建时间" align="center" width="180">
						</el-table-column>
						<el-table-column  label="网店类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_entity == 1">线上门店</span>
								<span  v-else>实体门店</span>
							</template>
						</el-table-column>
						<el-table-column  label="来源类别" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.customer_source == 1">平台创建</span>
								<span v-else-if="scope.row.customer_source == 2">网上注册</span>
								<span v-else>服务商创建</span>
							</template>
						</el-table-column>
						<el-table-column prop="marketing_trade_info.trade_name" label="行业类目" align="center">
						</el-table-column>
						<el-table-column prop="address" label="地址信息" width="150" align="center">
						</el-table-column>
						<el-table-column  label="分配状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_allot == 1">未分配</span>
								<span v-else>已分配</span>
							</template>
						</el-table-column>
						<el-table-column  label="客户状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_pay == 1">未付费</span>
								<span v-else>已付费</span>
							</template>
						</el-table-column>
						<el-table-column prop="username" label="客户名称" align="center">
						</el-table-column>
						<el-table-column prop="phone" label="客户联系电话" align="center">
						</el-table-column>
						<el-table-column prop="follow_remark" label="跟进内容" align="center">
						</el-table-column>
						<el-table-column prop="allot_time" label="所属服务商时间" width="150" align="center">
						</el-table-column>
						<el-table-column fixed="left" label="操作" width="150">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toDetail(scope.row)" v-has="'详情'">
									详情
								</el-button>
								<el-button type="text" size="small" @click="toEduit(scope.row)" v-has="'编辑'" >
									编辑
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<!-- 分页 -->
		
	</div>
</template>

<script>
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	import {getMarketingTradeList}  from '@/api/plat/market.js'
	import {BusinessIncubatorList,getBusinessIncubatorInfo} from '@/api/plat/business'
	export default {
		data() {
			return {
				loading:true,
				search: {
					search: '',
					begin_time:'',
					end_time:'',
					marketing_trade_id:'',
					is_pay:'',
					is_allot:'2'
				},
				options: regionData,
				due_dt:'',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				tradeList:[],
			}
		},
		mounted() {
			this.getTradeList();//行业分类
			this.getList()
		},
		methods:{
			//行业类目
			getTradeList(){
				getMarketingTradeList({}).then(res=>{
					this.tradeList=res.data
				})
			},
			//获取列表
			getList(){
				let data=this.search;
				if(this.keys_type == 1){
					data.username=this.search.keysward
				}else{
					data.phone=this.search.keysward
				}
				data.page=this.currentPage;
				data.perPage=this.pagesize;
				//data=this.tools.filterParams(data)
				console.log(data,"datadata")
				BusinessIncubatorList(data).then(res=>{
					this.loading=false;
					this.total=res.data.count;
					this.tableData=res.data.list;
					this.tableData.forEach(item=>{
						item.address = (item.shop_province == null ? "" : item.shop_province) + (item
							.shop_city == null ? "" : item.shop_city) + (item.shop_county == null ?
							"" : item.shop_county) + (item.shop_address_detail == null ? "" : item
							.shop_address_detail);
						item.allot_time = item.allot_time==null?'':this.tools.timeFormate(item.allot_time*1000)
					})
					
				})
			},
			//时间搜索
			changetimer(val) { //时间
			console.log(val,"3333")
				this.search.begin_time = val[0] / 1000;
				this.search.end_time = val[1] / 1000;
			},
			//查询
			toSearch(){
				this.currentPage=1;
				this.getList()
			},
			//重置
			toReset(){
				this.currentPage=1;
				this.search.search='';
				this.search.address=[];
				this.due_dt='';
				this.search.begin_time = '';
				this.search.end_time='';
				this.search.marketing_trade_id='';
				this.search.is_pay='';
				//调接口
				this.getList()
				
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//详情
			toDetail(row){
				this.$router.push({
					path:'/clientadmin/allocated/allocateddetail',
					query:{
						id:row.business_incubator_id
					}
				})
			},
			//编辑
			toEduit(row){
				this.$router.push({
					path:'/clientadmin/allocated/allocateddetail',
					query:{
						id:row.business_incubator_id,
						type:'eduit'
					}
				})
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
</style>
