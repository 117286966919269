<template>
	<div>
		<div class="wrapper-cont">
			<div class="order-search" style="margin-top: 0;margin-bottom: 0;">
				<el-row class="all_search_list" style="margin-bottom: 0;">
					<div class="demo-input-suffix">
						<el-button type="primary" size="small" @click="toAdd()" v-has="'添加类目'">添加类目</el-button>
					</div>
					<div class="demo-input-suffix">
						<el-select style="width: 150px;" size="small" placeholder="请选择状态" v-model="status">
							<el-option label="审核通过" value="1"></el-option>
							<el-option label="拒绝" value="2"></el-option>
							<el-option label="审核中" value="-1"></el-option>
						</el-select>
					</div>
					<el-button type="primary" size="small" @click="toSearch">查询</el-button>
					<el-button size="small" @click="toReset">重置</el-button>
				</el-row>
			</div>
		</div>
		<div class="wrapper-cont mt10">
			<el-table :data="tableData" border style="width: 100%"  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" >
				<el-table-column label="类目" prop="category_name" align="center">
				</el-table-column>
				<el-table-column label="状态" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 1">审核通过</span>
						<span v-else-if="scope.row.status == 2">审核失败</span>
						<span v-else-if="scope.row.status == -1">审核中</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.status == 2">
							<el-button style="padding: 5px 0;" type="text" size="small"
								@click="toOpera(scope.row,'check')" v-has="'重新提审'">重新提审
							</el-button> <span style="padding: 0 5px;  color: #409EFF;">|</span>
							<el-button style="padding: 5px 0;" type="text" size="small"
								@click="toOpera(scope.row,'reason')" v-has="'失败原因'">失败原因
							</el-button>
						</div>
						<div v-else>--</div>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
		</div>
		<!--添加类目弹框-->
		<el-dialog title="添加类目" :visible.sync="dialogVisible" width="55%">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="选择分类" prop="type_id" size="small">
					<el-cascader filterable :disabled="isdisabled" style="width: 250px;" v-model="ruleForm.cat_id" :props="defaultTreeProps"
						:options="treeOptions" @change="handleChange"></el-cascader>
				</el-form-item>
				<el-form-item label="营业执照:" prop="cardImg" v-if="third_cat_id">
					<div class="cardImg">
						<uploadImg :limit="1" :uploadUrl="uploadUrl" :multiples=false :fileList="ruleForm.cardImg" @submitImg="getImgList"></uploadImg>
					</div>
				</el-form-item>
				<el-form-item label="上传资质:" prop="qualifications" v-if="third_cat_id">
					<div style="background-color: #F2F2F6; padding: 15px 15px;">
						<div style="font-size: 12px;line-height: 20px;">
							<p><span style="color:#F56C6C;margin-right: 5px;">[资质]：</span>{{qualification}}</p>
							<p><span style="color:#F56C6C;margin-right: 5px;">[商品资质]：</span>{{product_qualification}}</p>
						</div>
						<div class="cardImg">
							<uploadImg :limit="5"  :uploadUrl="uploadUrl" :fileList="ruleForm.qualifications" @submitImg="getImgListqu"></uploadImg>
						</div>
					</div>
					<p style="font-size: 12px;">请确认图片url可以正常打开，图片大小在2MB以下，图片格式为jpg, jpeg, png，如图片不能正常显示，会导致审核驳回。</p>
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm('ruleForm')" size="small">提交审核</el-button>
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
			</span>
		</el-dialog>
		<!--拒绝原因弹框-->
		<el-dialog title="拒绝原因" :visible.sync="dialogVisibleDesc" width="25%">
			<div>
				{{desc_txt}}
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleDesc = false" size="small">知道了</el-button>
			</span>
		</el-dialog>
	
	</div>
</template>

<script>
	import uploadImg from '../../../../../common/unloadImg'
	import {
		getCategroyTree,
	} from '@/api/merchant/videonum';
	import {
		getShopVideoCategroyList,
		getThirdCatDetail,
		uploadFile,
		auditCategory
	} from "@/api/plat/videonum.js";
	import {
		platRefreshToken
	} from "@/api/plat/platlogin";
	import {
		shopRefreshToken
	} from "@/api/merchant/login";
	import {
		channelRefreshToken
	} from "@/api/channel/login";
	import md5 from 'js-md5';
	import baseURL from "@/config/baseUrl";
	export default {
		components:{
			uploadImg
		},
		data() {
			return {
				loading:true,
				 dialogImageUrl: '',
				        dialogVisible: false,
				        disabled: false,
				
				dialogImgVisible: false,
				paramData: {}, //上传参数
				uploadUrl:'/Video/platform/uploadFile',
				status: '',
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				tableData: [],
				dialogVisible: false,
				ruleForm: {
					cat_id: [],
					cardImg: [],
					qualifications: []
				},
				rules: {
					cat_id: [{
						type: 'array',
						required: true,
						message: '请选择分类',
						trigger: 'change'
					}],
					cardImg: [{
						required: true,
						message: '请上传营业执照',
						trigger: 'change'
					}],
					qualifications: [{
						type: 'array',
						required: true,
						message: '请上传资质',
						trigger: 'change'
					}]

				},
				desc_txt: '',
				dialogVisibleDesc: false,
				treeOptions: [],
				defaultTreeProps: {
					value: 'cat_id',
					children: 'children',
					label: 'category_name'
				},
				isSingle: false, //上传图片是单张还是多张
				uploadFlag: false, //是否展示选择图片弹窗
				imgType: '',
				third_cat_id: '',
				product_qualification: '',
				qualification:'',
				imgFileList: [], //上传图片
				fileList:[],
				fileListqu:[],
				isdisabled:false
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
			// 获取图片
			getImgList(list){
			    this.ruleForm.cardImg = list
			},
			 getImgListqu(list){
			     this.ruleForm.qualifications = list
			 },
			getList() {
				let data = {
					status: this.status,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				getShopVideoCategroyList(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//搜索
			toSearch() {
				this.currentPage = 1;
				this.getList()
			},
			//重置
			toReset() {
				this.status = '';
				this.currentPage = 1;
				this.getList()
			},
			//获取微信分类
			getCategroyTreeData() {
				getCategroyTree({}).then(res => {
					this.treeOptions = res.data;
					this.treeOptions.forEach(item1 => {
						item1.children.forEach(item2 => {
							item2.children.forEach(item3 => {
								if (item3.qualification_type ==
									0 || item3.is_submit == 2) {
									item3.disabled = true
								}
							})
						})
					})
				})
			},
			//添加类目
			toAdd() {
				this.dialogVisible = true;
				this.ruleForm.cat_id=[];
				this.ruleForm.cardImg=[];
				this.ruleForm.qualifications=[];
				this.third_cat_id='';
				this.isdisabled=false;
				this.getCategroyTreeData()

			},
			getThirdCatDetailData() {
				let data = {
					third_cat_id: this.third_cat_id
				}
				getThirdCatDetail(data).then(res => {
					this.product_qualification = res.data.product_qualification;
					this.qualification=res.data.qualification
				})
			},

			//选择分类
			handleChange(value) {
				this.third_cat_id = value[2];
				this.getThirdCatDetailData()
			},
			//提交
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let aa=JSON.parse(JSON.stringify(this.ruleForm.cardImg)),bb=JSON.parse(JSON.stringify(this.ruleForm.qualifications)),
						cardImgs=[],qualificationsImgs=[];
						aa.forEach(item=>{
							cardImgs.push(item.url)
						});
						bb.forEach(item=>{
							qualificationsImgs.push(item.url)
						});
						let data = {
							license: cardImgs.join(","),
							level1: this.ruleForm.cat_id[0],
							level2: this.ruleForm.cat_id[1],
							level3: this.ruleForm.cat_id[2],
							certificate: qualificationsImgs.join(","),
						}
						auditCategory(data).then(res => {
							this.$message.success("添加成功！");
							this.dialogVisible = false;
							this.getList()
						})
					} else {
						return false;
					}
				});
			},
			toOpera(row, type) {
				if (type == 'check') {
					//调接口
					this.dialogVisible = true;
					this.ruleForm.cat_id=[row.first_cat_id,row.second_cat_id,row.third_cat_id];
					this.getCategroyTreeData();
					this.isdisabled=true;
					this.third_cat_id = row.third_cat_id;
					this.getThirdCatDetailData()
				
					
				}
				if (type == 'reason') {
					//调接口
					this.desc_txt='';
					this.dialogVisibleDesc = true;
					this.desc_txt = row.wx_reason
				}

			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.img_blosmark {
		display: flex;
		flex-wrap: wrap;
		width: 90px;
		min-height: 90px;

		.img_blomark {
			position: relative;
			height: 90px;

			.item_imgmark {
				width: 100px;
				height: 100%;
				margin-right: 30px;
			}

			.close_imgmark {
				font-size: 20px;
				right: 6px;
				top: -10px;
				position: absolute;
				cursor: pointer;
			}
		}

		.add_imgmark {
			width: 90px;
			height: 90px;
			cursor: pointer;
		}
	}

	.plusImgmark {
		width: 90px;
		border: 1.3px dashed #CCC;
		background-color: #F7F8FA;
		border-radius: 4px 4px;
		text-align: center;
		height: 90px;
		line-height: 90px;
		cursor: pointer
	}

	.plusImgmark .el-icon-plus {
		width: 90px;
		height: 90px;
		line-height: 90px;
		font-size: 24px;
		color: #969799
	}

	.card_blomark {
		width: 126px;
		position: relative;
		border: 1.3px dashed #CCC;
		background-color: #F7F8FA;
		height: 88px;

		.card_imgmark {
			width: 100px;
			height: 100%;
			margin-right: 30px;
		}
	}

	.cardImg ::v-deep .el-upload--text {
		background-color: #fff;
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		box-sizing: border-box;
		width: 90px;
		height: 90px;
		text-align: center;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.cardImg ::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.cardImg ::v-deep .avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.cardImg ::v-deep .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 90px;
		height: 90px;
		line-height: 90px;
		text-align: center;
	}

	.cardImg ::v-deep .avatar {
		width: 90px;
		height: 90px;
		display: block;
	}
</style>
