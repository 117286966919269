<template>
  <div>
    <div class="wrapper-main">
      <div style="background-color: #F2F2F6;padding: 10px;">
        <div class="main">
          <div class="title">基本信息</div>
          <div class="info">
            <div class="info-box">
              <div class="tx">
                <img :src="userInfo.head_portrait" alt="">
              </div>
              <div style="margin-bottom: 0px;" class="single-omit">昵称: {{ userInfo.nickname }}</div>
              <div>手机号:{{ userInfo.mobile }}</div>
            </div>
            <div class="info-box" style="position: relative;width: 350px;">
              <div style="margin-bottom: 0px;" class="single-omit">所属上级: {{ userInfo.pid_nickname }}</div>
              <el-button type="primary" size="mini"
                style="margin-left: 15px;  padding: 3px !important;position: absolute;right:20px;top:20px;"
                @click="superiorsDialogVisible = true">修改上级</el-button>
              <div>手机号:{{ userInfo.pid_mobile }}</div>
              <div> <el-button type="primary" size="mini" style="margin-left: 0px;" @click="getPinfo"
                  v-has="'查看上级链路'">查看上级链路</el-button>
              </div>
            </div>
            <div class="info-box">
              <div style="margin-bottom: 0px;"><span>身份等级：</span>
                <span style="font-weight: 700;color:#ad4bcd;font-size: 25px;">{{ userInfo.level }}</span>
                <div>
                  <el-button type="primary" size="mini" style="margin-left: 0px;padding: 3px !important;"
                    @click="leveDialogVisible = true" v-has="'调整等级'">调整等级</el-button>
                </div>
              </div>
              <!-- <div>合伙人</div> -->
            </div>
          </div>

          <div class="team">
            <div class="team-box">
              <div style="margin-bottom: 0px;font-size:14px;">邀请码：<span style="font-weight: 700;color:#0091ff;">{{
                userInfo.unique }}</span></div>
              <!-- <div>手机号</div> -->
            </div>
            <!-- <div class="team-box">
            <div style="margin-bottom: 0px;">直属下级：{{ userInfo. }}</div>
            <div>手机号</div>
          </div> -->
          </div>
          <div class="money">
            <div class="money-box">
              <div style="margin-bottom: 0px;"><span style="font-size: 14px;font-weight: 700;">股份：</span><span
                  style="color:#2589FF;font-size: 18px;">{{ userInfo.stock_money }}</span></div>
              <!-- <div>手机号</div> -->
            </div>
            <div class="money-box">
              <div style="margin-bottom: 0px;"><span style="font-size: 14px;font-weight: 700;">余额：</span><span
                  style="color:#303331;font-size: 18px;">{{ userInfo.available_money }}</span>
                <el-button type="primary" size="mini" style="margin-left: 15px;  padding: 3px !important;"
                  @click="rechargeDialogVisible = true" v-has="'充值'">充值</el-button>
              </div>
            </div>
            <!-- <div class="money-box">
              <div style="margin-bottom: 0px;"><span style="font-size: 14px;font-weight: 700;">可提现金额：</span><span
                  style="color:#323330;font-size: 18px;">{{ userInfo.transfer_money }}</span></div>
            </div> -->
          </div>
          
          <div class="money" style="margin-top: 20px;">
            <div class="money-box">
              <div style="margin-bottom: 0px;"><span style="font-size: 14px;font-weight: 700;">分红上限：</span><span
                  style="color:#ef0e3b;font-size: 18px;">{{ userInfo.commission_total }}</span>
                  <el-button type="primary" size="mini" style="margin-left: 15px;  padding: 3px !important;"
                  @click="adjustDialogVisible = true">调整</el-button>
                </div>
              <!-- <div>手机号</div> -->
            </div>
            <div class="money-box">
              <div style="margin-bottom: 0px;"><span style="font-size: 14px;font-weight: 700;">已结算分红：</span><span
                  style="color:#323330;font-size: 18px;">{{ userInfo.commission_total_money }}</span></div>
            </div>
            <div class="money-box">
              <div style="margin-bottom: 0px;"><span style="font-size: 14px;font-weight: 700;">已结算直推奖：</span><span
                  style="color:#323330;font-size: 18px;">{{ userInfo.direct_award }}</span></div>
            </div>
          </div>
          <!-- <div class="money" style="margin-top: 20px;"> 
            <div class="money-box">
              <div style="margin-bottom: 0px;"><span style="font-size: 14px;font-weight: 700;">团队人数（V1-V7）:</span><span
                  style="color:#2589FF;font-size: 18px;">{{ userInfo.stock_money }}</span></div>
            </div>
            <div class="money-box">
              <div style="margin-bottom: 0px;"><span style="font-size: 14px;font-weight: 700;">团队业绩：</span><span
                  style="color:#303331;font-size: 18px;">{{ userInfo.available_money }}</span>
                <el-button type="primary" size="mini" style="margin-left: 15px;  padding: 3px !important;"
                  @click="teamDialogVisible = true">查看</el-button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div style="font-weight: 700;margin-top: 30px;margin-left: 20px;font-size: 14px;">记录（不包含直推奖、分红奖结算信息）</div>

      <el-tabs v-model="activeName" type="card" style="padding-top: 20px;" @tab-click="handleClick">

        <!-- <el-tab-pane label="转出记录" name="1">
          <el-table class="mt10" ref="multipleTable" :data="tableData" size="small" border tooltip-effect="dark"
            max-height="550" style="width: 100%" :header-cell-style="{ background: '#f3f8fe', color: '#606060' }"
            v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)">

            <el-table-column prop="amount" label="转出金额" align="center">
            </el-table-column>
            <el-table-column prop="amount" label="状态" align="center">
              <template #default="{ row }">
                <div v-if="row.examine_status==0">
                  转入中
                </div>
                <div v-if="row.examine_status==1">
                 <div>转出成功</div>
                 <div>{{ row.examined_at }}</div>
                </div>
                <div v-if="row.examine_status==2">
                 <div>转出失败</div>
                 <div>{{ row.examined_at }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="reason" label="失败原因" align="center"></el-table-column>
            <el-table-column prop="created_at" label="提交时间" sortable align="center"></el-table-column>
          </el-table>
        </el-tab-pane> -->

        <el-tab-pane label="充值记录" name="2">
          <el-table class="mt10" ref="multipleTable" :data="tableData" size="small" border tooltip-effect="dark"
            max-height="550" style="width: 100%" :header-cell-style="{ background: '#f3f8fe', color: '#606060' }"
            v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)">


            <el-table-column prop="regulation_type" label="增减事件" align="center">
              <template #default="{ row }">
                <div>
                  <span v-if="row.regulation_type == 1">后台充值</span>
                  <span v-if="row.regulation_type == 2">后台消费</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="regulation_type" label="类型" align="center">
              <template #default="{ row }">
                <div v-if="row.regulation_type == 1" style="color: #69d741;">增加</div>
                <div v-if="row.regulation_type == 2" style="color: #ea0e0e;">减少</div>
              </template>
            </el-table-column>
            <el-table-column prop="money" label="增减数数量" align="center"></el-table-column>
            <el-table-column prop="created_at" label="变更时间" sortable align="center"></el-table-column>
            <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          </el-table>
        </el-tab-pane>


        <el-tab-pane label="订单记录" name="3">
          <el-table class="mt10" ref="multipleTable" :data="tableData" size="small" border tooltip-effect="dark"
            max-height="550" style="width: 100%" :header-cell-style="{ background: '#f3f8fe', color: '#606060' }"
            v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)">


            <el-table-column prop="regulation_type" label="增减事件" align="center">
              <template #default="{ row }">
                <div>
                  <span v-if="row.regulation_type == 1">后台充值</span>
                  <span v-if="row.regulation_type == 2">后台消费</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="" label="主订单号" align="center"></el-table-column>
            <el-table-column prop="" label="售单号" align="center"></el-table-column>

            <el-table-column prop="regulation_type" label="类型" align="center">
              <template #default="{ row }">
                <div v-if="row.regulation_type == 1" style="color: #69d741;">增加</div>
                <div v-if="row.regulation_type == 2" style="color: #ea0e0e;">减少</div>
              </template>
            </el-table-column>

            <el-table-column prop="money" label="增减数数量" align="center"></el-table-column>
            <el-table-column prop="created_at" label="变更时间" sortable align="center"></el-table-column>
          </el-table>
        </el-tab-pane>


        <el-tab-pane label="转增记录" name="4">
          <el-table class="mt10" ref="multipleTable" :data="tableData" size="small" border tooltip-effect="dark"
            max-height="550" style="width: 100%" :header-cell-style="{ background: '#f3f8fe', color: '#606060' }"
            v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)">

            <el-table-column prop="regulation_type" label="增减事件" align="center">
              <template #default="{ row }">
                <div>
                  <span v-if="row.regulation_type == 1">接收赠送</span>
                  <span v-if="row.regulation_type == 2">赠送他人</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="gift_mobile" label="赠送者账号" align="center">
              <template #default="{ row }">
                <div>{{ row.regulation_type == 1 ? row.gift_mobile : '' }}</div>

              </template>
            </el-table-column>
            <el-table-column prop="gift_mobile" label="接受者账号" align="center">
              <template #default="{ row }">
                <div>{{ row.regulation_type == 1 ? '' : row.gift_mobile }}</div>

              </template>
            </el-table-column>

            <el-table-column prop="regulation_type" label="类型" align="center">
              <template #default="{ row }">
                <div v-if="row.regulation_type == 1" style="color: #69d741;">增加</div>
                <div v-if="row.regulation_type == 2" style="color: #ea0e0e;">减少</div>
              </template>
            </el-table-column>

            <el-table-column prop="money" label="增减数数量" align="center"></el-table-column>
            <el-table-column prop="created_at" label="变更时间" sortable align="center"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="page" :page-sizes="[10, 15, 20, 30]" :page-size="per_page"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>



    <!-- 弹窗 -->
    <el-dialog title="上级链路" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="conment">
        <div class="dj" v-for="(item, index) in parr" :key="index">
          {{ item }}
        </div>
      </div>
    </el-dialog>

    <!-- 修改等级弹窗 -->
    <el-dialog title="修改等级" :visible.sync="leveDialogVisible" width="30%" center>
      <div class="conment">
        <div style="min-height: 100px;">
          <div style="margin: 80px 0 0 50px;">
            <span style="font-weight: 700;"> 身份等级：</span><el-select style="width: 150px;!important" size="small" clearable
              v-model="level" placeholder="请选择">
              <el-option value="1" label="等级1"></el-option>
              <el-option value="2" label="等级2"></el-option>
              <el-option value="3" label="等级3"></el-option>
              <el-option value="4" label="等级4"></el-option>
              <el-option value="5" label="等级5"></el-option>
              <el-option value="6" label="等级6"></el-option>
              <el-option value="7" label="等级7"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="leveDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="levelModify">提交</el-button>
      </span>
    </el-dialog>


    <!-- 修改上级弹窗 -->
    <el-dialog title="修改上级" :visible.sync="superiorsDialogVisible" width="30%" center>
      <div class="conment">
        <div style="min-height: 50px;">
          <div>
            <!-- <el-select style="width: 150px;!important" size="small" clearable
              v-model="superiors" placeholder="请选择">
              <el-option :value="item.mobile" :label="item.mobile" v-for="(item,index) in selectData" :key="index">
              </el-option>
            </el-select> -->

            <div class="goodsinfo">
              <el-row class="all_search_list" style="margin-bottom:0;margin-top: 30px;">
                <div class="demo-input-suffix">
                  上级手机号：
                  <el-input clearable class="inputsWidth" prefix-icon="el-icon-search" v-model="search2.search"
                    placeholder="请输入手机号" @keyup.enter.native="toSearch" size="small" type="text">
                  </el-input>
                </div>


                <div class="demo-input-suffix">
                  <el-button type="primary" size="small" @click="searchSj">搜索</el-button>
                  <!-- <el-button size="small" @click="toResetOrder">重置</el-button> -->
                </div>
              </el-row>
              <div class="main-cont bgwt mt10">
                <el-table :data="selectData" style="width: 100%" min-height="100" max-height="400" border
                  highlight-current-row @row-click="handleRadioChange"
                  :header-cell-style="{ background: '#f3f8fe', color: '#606060' }" element-loading-text="拼命加载中..."
                  element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
                  <el-table-column align="center" width="55" label="选择">
                    <template slot-scope="scope">
                      <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
                      <el-radio class="radio" v-model="templateSelection"
                        :label="scope.row.account_user_id">&nbsp;</el-radio>
                    </template>
                  </el-table-column>
                  <el-table-column prop="mobile" label="手机号" align="center">

                  </el-table-column>
                  <el-table-column prop="nickname" label="昵称" align="center">

                  </el-table-column>
                </el-table>
              </div>
            </div>



          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="superiorsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="modifyParent">提交</el-button>
      </span>
    </el-dialog>

    <!-- 充值弹窗 -->
    <el-dialog title="充值" :visible.sync="rechargeDialogVisible" width="30%" center>
      <div class="conment">
        <div style="min-height: 100px;">
          <div style="margin: 80px 0 0 50px;">
            <span style="font-weight: 700;"> 充值金额：</span><el-select style="width: 90px;" size="small" v-model="type"
              placeholder="请选择">
              <el-option value="1" label="增加"></el-option>
              <el-option value="2" label="减少"></el-option>
            </el-select>
            <el-input placeholder="请输入" v-model="money" clearable style="width: 200px;margin-left: 15px;" size="small"
              type="number" oninput="if(value<0)value=''">
            </el-input>
          </div>
          <div style="margin: 30px 0 0 50px;position: relative;">
            <span style="font-weight: 700;display: inline-block;"> 备注：</span><el-input type="textarea" autosize
              placeholder="请输入内容" v-model="remark" style="width: 335px;position: absolute;top: -5px;" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rechargeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="balanceRecharges">提交</el-button>
      </span>
    </el-dialog>

     <!-- 调整分红弹窗 -->
     <el-dialog title="分红上限" :visible.sync="adjustDialogVisible" width="20%" center>
      <div class="conment">
        <div style="min-height: 0px;">
          <div style="margin: 20px 0 0 50px;">
            <span style="font-weight: 700;"> 调整：</span>
            <el-input placeholder="请输入" v-model="money" clearable style="width: 200px;margin-left: 15px;" size="small"
            oninput="value=value.replace(/^0|[^0-9]/g,'')">
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adjustDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="commissionTotalModify">提交</el-button>
      </span>
    </el-dialog>

    <!-- 查看弹窗 -->
    <el-dialog title="团队业绩" :visible.sync="teamDialogVisible" width="35%" center>
      <div class="conment" style="margin-top: 10px;width: 700px;">
        <div class="demo-input-suffix" style="margin-left: 70px;font-size: 13px;">
          <span style="font-weight: 700;">付款时间：</span>
          <!-- <el-date-picker style="width: 280px" size="small" v-model="due_dt" type="daterange" range-separator="至"
            start-placeholder="开始日期" clearable end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
            @change="changetimer"></el-date-picker> -->
          <el-date-picker end-placeholder="结束日期" style="width: 350px" range-separator="至" start-placeholder="开始日期"
            type="datetimerange" v-model="due_dt" :default-time="['00:00:00', '23:59:59']" @change="changetimer"
            size="small">
          </el-date-picker>

          <el-button type="primary" size="small" @click="searchOrder" style="margin-left: 30px;">查询</el-button>
          <!-- <el-button size="small" @click="toResetOrder">重置</el-button> -->
        </div>
        <div style="margin-top: 120px;margin-left: 100px;">
          <div style="margin-bottom: 0px;"><span style="font-size: 20px;font-weight: 700;">团队业绩：</span><span
              style="color:#303331;font-size: 25px;">{{ userInfo.available_money }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getUserInfo, getLink, levelModify, balanceRecharge, accountCapitalLog, accountList, modifyParent, commissionTotalModify, transferListPlatform } from "@/api/merchant/users.js"
import { debounce } from 'lodash';  
export default {

  data() {
    return {
      loading: true,
      tableData: [],
      centerDialogVisible: false,
      leveDialogVisible: false,
      rechargeDialogVisible: false,
      teamDialogVisible: false,
      superiorsDialogVisible: false,
      adjustDialogVisible:false,
      level: '',
      money: '',
      due_dt: '',
      remark: '',
      type: '1',
      activeName: '2',
      page: 1,
      per_page: 10,
      id: this.$route.query.account_user_id,
      userInfo: {},
      parr: [],
      selectData: [],
      search: {
        buy_start_time: '',
        buy_end_time: '',
      },
      postType: 1,
      total: 0,
      currentPage: 1,
      pagesize: 100000,
      superiors: '',
      templateSelection: '',
      search2: {
        search: ''
      }
    }
  },
  watch: {
    // activeName(){
    //  if(this.activeName>1){
    //   this.accountCapitalLog()
    //  }
    // }
  },
  mounted() {
    this.getUserInfo()
    this.getLink()
    // this.transferListPlatform()
    this.getList()
    this.accountCapitalLog()
  },
  methods: {

    commissionTotalModify(){
      if(this.money===''){
        this.$message.error('请输入调整金额！')
      }
      let data = {
        account_user_id:this.id,
        amount:this.money,
      }
      commissionTotalModify(data).then(res=>{
        this.$message.success('修改成功')
        this.getUserInfo()
        this.adjustDialogVisible=false

      })
    },

    handleRadioChange(currentRow, oldCurrentRow) {

      this.templateSelection = currentRow.account_user_id
      this.superiors = currentRow.mobile
      // console.log(currentRow,'-------------13213123');

    },

    searchSj() {
      this.getList()
    },
    getList() {
      let data = this.search2;
      data.page = this.currentPage;
      data.perPage = this.pagesize;
      data.type = 1;
      // data.account_user_id=107
      accountList(data).then(res => {
        this.selectData = res.data.list;
        console.log(213131323123);
        console.log(res.data);
      })
    },

    //分页
    handleSizeChange(per_page) {
      this.per_page = per_page;
      // 掉接口
      if (this.activeName > 1) {
        this.accountCapitalLog()
      } else {
        this.transferListPlatform()
      }

    },
    handleCurrentChange(page) {
      this.page = page;
      // 掉接口
      if (this.activeName > 1) {
        this.accountCapitalLog()
      } else {
        this.transferListPlatform()
      }

    },
    transferListPlatform() {
      let data = {
        page: this.page,
        per_page: this.per_page,
        account_user_id: this.id,
      }
      transferListPlatform(data).then(res => {
        this.loading = false
        this.tableData = res.data.list
      })
    },
    handleClick() {
      this.page = 1
      this.per_page = 10
      this.accountCapitalLog()
    },
    accountCapitalLog() {
      if (this.activeName == 2) {
        // console.log(2222); 
        this.postType = 1
      } else if (this.activeName == 3) {
        // console.log(333);

        this.postType = 2
      } else if (this.activeName == 4) {
        // console.log(4444);

        this.postType = 3
      } else {
        this.transferListPlatform()
        return
      }
      // console.log(11111);
      // console.log(this.postType);

      let data = {
        page: this.page,
        per_page: this.per_page,
        account_user_id: this.id,
        type: this.postType
      }
      accountCapitalLog(data).then(res => {
        // this.tableData=res.data
        this.loading = false
        console.log(res.data);
        this.total = res.data.count
        this.tableData = res.data.list

      })

    },
    searchOrder() {

    },
    balanceRecharges: debounce(function() {
        if (!this.money) {
          this.$message.error("请输入充值金额！")
          return
        }
        let data = {
          type: +this.type,
          account_user_id: +this.id,
          remark: this.remark,
          amount: +this.money
        }
        balanceRecharge(data).then(res => {
          this.rechargeDialogVisible = false;
          this.$message.success("充值成功")
          this.remark = ''
          this.money = ''
          this.getUserInfo()
        })
        console.log('按钮被点击了！');  
    }, 300), // 这里的 300 是毫秒，表示在 300 毫秒内只执行一次处理逻辑 
    // balanceRecharges() {  //充值
    //   if (!this.money) {
    //     this.$message.error("请输入充值金额！")
    //     return
    //   }
    //   let data = {
    //     type: +this.type,
    //     account_user_id: +this.id,
    //     remark: this.remark,
    //     amount: +this.money
    //   }
    //   balanceRecharge(data).then(res => {
    //     this.rechargeDialogVisible = false;
    //     this.$message.success("充值成功")
    //     this.remark = ''
    //     this.money = ''
    //     console.log('执行了，马上执行定时器');
    //     this.getUserInfo()
    //   })
    // },
    
    levelModify() {
      levelModify({ account_user_id: this.id, level: +this.level }).then(res => {
        this.leveDialogVisible = false;
        this.$message.success("修改成功")
        this.getUserInfo()
      })
    },
    modifyParent() {
      modifyParent({ account_user_id: this.id, mobile: +this.superiors }).then(res => {
        this.superiorsDialogVisible = false;
        this.$message.success("修改成功")
        this.getUserInfo()
      })
    },
    //时间搜索
    changetimer(val) { //时间
      this.search.buy_start_time = val[0] / 1000;
      this.search.buy_end_time = val[1] / 1000;
      console.log(this.search.buy_start_time, this.search.buy_end_time);

    },
    getPinfo() {
      this.centerDialogVisible = true
      this.getLink()
    },
    getUserInfo() {
      getUserInfo({ account_user_id: this.id }).then(res => {
        // console.log(111111111);
        // console.log(res.data);
        this.userInfo = res.data
        this.superiors = this.userInfo.pid_mobile
      })
    },
    getLink() {
      getLink({ account_id: this.id }).then(res => {
        // console.log(2323213213);
        this.parr = res.data
        // console.log(res.data);

      })
    }
  }
}
</script>
<style lang="less" scoped>
.wrapper-main {
  // background-color: red;
  padding: 10px;
  padding-bottom: 100px;
  height: 100%;

  .main {
    width: 100%;
    background-color: #fff;
    height: 700px;
    border-radius: 8px;
    padding: 10px;

    .title {
      font-size: 14px;
      font-weight: 700;
      margin-left: 20px;
      // padding-top: 10px;
    }

    .info {
      display: flex;
      margin-top: 30px;

      .info-box {
        position: relative;
        height: 150px;
        width: 250px;
        // background-color: red;
        margin-left: 150px;
        background-color: #F2F2F6;
        border-radius: 5px;
        padding: 10px 40px;
        box-sizing: border-box;

        .tx {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          // background-color: skyblue;
          position: absolute;
          left: -110px;
          top: 17px;

          img {
            width: 100%;
          }
        }

        div {

          height: 40px;
          line-height: 40px;
          font-size: 14px;
          // font-weight: 600;
        }
      }
    }

    .team {
      display: flex;
      margin-left: 150px;
      margin-top: 30px;
      background-color: #F2F2F6;
      border-radius: 5px;
      width: 250px;
      height: 50px;
      line-height: 50px;
      padding-left: 40px;
      box-sizing: border-box;

      text-align:center .team-box {
        height: 150px;
        width: 300px;
        // background-color: red;
        background-color: #F2F2F6;
        ;
        // margin-left: 150px;

        div {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          // font-weight: 600;
        }
      }
    }

    .money {
      display: flex;
      margin-top: 100px;

      .money-box {
        height: 50px;
        width: 300px;
        // background-color: red;
        line-height: 50px;
        border-radius: 5px;
        margin-left: 150px;
        padding-top: 10px;
        text-align: center;
        background-color: #F2F2F6;


        div {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          // font-weight: 600;
        }
      }
    }
  }
}

.conment {
  // background-color: red;
  // height: 100px;
  width: 100%;
  margin-top: -30px;

  .dj {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px #F2F2F6 solid;
    // background-color: blue;
    margin: 10px auto;
  }
}
</style>