<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<div class="all_search_list" style="margin-bottom: 0;">
						<div class="demo-input-suffix">
							<el-input prefix-icon="el-icon-search" class="inputWidth" v-model="search.keyword"
								placeholder="分类名称" size="small" type="text"  @keyup.enter.native="searchOrder" clearable>
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-select style="width: 9.375rem!important;" size="small" v-model="search.is_show"
								placeholder="全部状态">
								<el-option label="全部" value=""></el-option>
								<el-option label="启用" value="1"></el-option>
								<el-option label="禁用" value="2"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder" style="margin-left: 10px;">查询
							</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toAdd">添加分类</el-button>
						</div>
					</div>
				</div>
				<div class="tableMain">
					<el-table :data="tableData" style="width: 100%" min-height="250" border v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"  max-height="550px"  :header-cell-style="{background:'#f3f8fe',color:'#606060'}">
						<el-table-column prop="name" label="分类名称" align="center">
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_show == '1'">启用</span>
								<span v-else>禁用</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="350" align="center">
							<template slot-scope="scope">
								<el-button @click="toOpera(scope.row,'edit')" type="text" size="small" v-has="'编辑'">编辑</el-button>
								<el-button @click="toOpera(scope.row,'del')" type="text" size="small" v-has="'删除'"><span style="color:red">删除</span></el-button>
							</template>
						</el-table-column>
					</el-table>
						<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
		<!--添加分类-->
		<el-dialog title="分类" :visible.sync="goodBrandModel" width="469px">
			<el-form ref="form" :model="form" :rules="rules" label-width="100px">
				<el-form-item label="分类名称" prop="name">
					<el-input type="text" size="small" class="inputWidth" v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="是否开启">
					<el-radio v-model="form.is_show" label="1">启用</el-radio>
					<el-radio v-model="form.is_show" label="2">禁止</el-radio>
				</el-form-item>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="goodBrandModel = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitAddBrand('form')" size="small">确认</el-button>
			</span>
		</el-dialog>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		get_all_Brand,
		add_recommend,
		edit_recommend,
		del_data
	} from "@/api/merchant/good.js"
	import {
		change_Brand
	} from '@/api/plat/goods'
	import ChooseImg from "../../../../../common/uploadImgs.vue";
	export default {
		components: {
			ChooseImg,
		},
		data() {
			return {
				loading:true,
				imgshow: false,
				isSingle: false, //上传图片是单张还是多张
				uploadFlag: false, //是否展示选择图片弹窗
				search: {
					keyword: '',
					is_show: ''
				},
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				goodLibraryModel: false,
				options: [],
				case_id: '',
				goodBrandModel: false,
				uploadType: '',
				form: {
					name: '',
					is_show: '1'
				},
				rules: {
					name: [{
						required: true,
						message: '请填写分类名称',
						trigger: 'blur'
					}],
					
				},
				goods_recommend_id: '',
				getAllAssortment: null
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let data = {
					keyword: this.search.keyword,
					is_show: this.search.is_show,
					page: this.currentPage,
					perPage: this.pagesize,
				}
				
				get_all_Brand(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list

				})
			},
			//添加
			toAdd() {
				this.form.name = '';
				this.form.is_show = '1';
				this.goodBrandModel = !this.goodBrandModel;
			},
			//获取分类名称和ID
			getAllAssortments() {
				getAllAssortment({}).then(res => {

				})
			},
			// 上传商品图
			uploadImg() {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				this.form.brand_logo = val[0].resource_name;
			},
			// //搜索
			searchOrder() {
				this.currentPage = 1;
				this.getList()
			},
			toReset() {
				this.currentPage = 1;
				this.search.keyword = '';
				this.search.is_show = '';
				this.getList()
			},
			// //分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//禁用启用
			upOrdown(row, type) {
				this.$confirm(type == 1 ? '确定要启用吗？' : '确定要禁用吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						goods_recommend_id: row.goods_recommend_id,
						is_show: type == 1 ? 1 : 2
					}
					change_Brand(data).then(res => {
						this.getList()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					})
				})
			},
			cancelModel() {
				this.goodLibraryModel = !this.goodLibraryModel;
			},

			//确认关联
			toOpera(row, type) {
				if (type == 'relation') {
					//关联
					this.goodLibraryModel = !this.goodLibraryModel;
					this.getAllAssortments()
				} else if (type == 'del') {
					//删除
					this.tools.confirm("确定要删除？").then(res => {
						if (res) {
							//调接口
							let data = {
								goods_recommend_id: row.goods_recommend_id
							}
							del_data(data).then(() => {
								this.$message.success("删除成功");
								this.getList()
							})
						}
					})

				} else if (type == 'edit') {
					//编辑
					this.goods_recommend_id = row.goods_recommend_id;
					row.is_show = row.is_show.toString();
					this.form = row;
					this.goodBrandModel = !this.goodBrandModel;
				}
			},
			//确认添加分类
			submitAddBrand(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						this.form = JSON.parse(JSON.stringify(this.form));
						let data = this.form;
						if (this.goods_recommend_id) {
							data.goods_recommend_id = this.goods_recommend_id;
							edit_recommend(data).then(() => {
								this.$message.success("编辑成功");
								this.goodBrandModel = !this.goodBrandModel;
								this.getList();
								this.goods_recommend_id = ''
							})
						} else {
							add_recommend(data).then(() => {
								this.$message.success("添加成功");
								this.goodBrandModel = !this.goodBrandModel;
								this.getList()
							})
						}

					} else {
						return false;
					}
				});
			},
		}
	}
</script>

<style>
</style>
