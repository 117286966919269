<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-input prefix-icon="el-icon-search" placeholder="输入搜索网店名称/手机号" class="inputWidth" size="small"
						v-model="shop_name" @keyup.enter.native="toSearch" clearable>
					</el-input>
					<el-button type="primary" size="small" @click="toSearch" style="margin-left: 10px;">查询</el-button>
					<el-button size="small" @click="toReset">重置</el-button>
				</div>
				<div class=" mt10">
					<el-row :gutter="20">
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div>正常商户</div>
								<div class="mt10">{{accountcounts.account_normal}}</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div>停用商户</div>
								<div class="mt10">{{accountcounts.account_forbidden}}</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div>审核中商户</div>
								<div class="mt10">{{accountcounts.account_audit}}</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div>待续费商户</div>
								<div class="mt10">{{accountcounts.account_continued_fee}}</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<!--表格-->
				<div class="tableMain">
					<div class="tableTile">商户列表</div>
					<el-table :data="tableData" style="width: 100%" min-height="250" border max-height="550" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" :header-cell-style="{background:'#f3f8fe',color:'#606060'}">
						<el-table-column prop="shop_name" label="商户名称" align="center" width="100">
						</el-table-column>
						<el-table-column prop="account_shop_info.account" label="登录账号" align="center" width="130">
						</el-table-column>
						<el-table-column label="网店类型" align="center" width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.is_entity == 1">线上门店</span>
								<span v-else>实体门店</span>
							</template>
						</el-table-column>
						<el-table-column prop="account_level_info.shop_level_name" label="网店等级" align="center"
							width="100">
						</el-table-column>
						<el-table-column prop="marketing_trade_info.trade_name" label="行业类目" align="center" width="100">
						</el-table-column>
						<el-table-column prop="account_shop_info.senior_manager_number" label="高级网店" align="center"
							width="100">
						</el-table-column>
						<el-table-column prop="account_shop_info.general_manager_number" label="普通网店" align="center"
							width="100">
						</el-table-column>
						<el-table-column prop="" label="是否团队长" align="center" width="150">
							<template slot-scope="scope">
								<el-button type="text" size="small" class="el_edits"
									v-if="scope.row.team_leader == '0' || scope.row.team_leader == '1'">
									<div v-show="scope.row.team_leader == '0'" class="el_state"
										@click="isOpenColonel(scope.row, '1')">
										否
										<img src="../../../../../../assets/img/downs.png" style="margin-left: 10px;" />
									</div>
									<div v-show="scope.row.team_leader == '1'" class="el_state">
										是<img src="../../../../../../assets/img/ups.png"
											@click="isOpenColonel(scope.row, '0')" style="margin-left: 10px;" />
										<i class="el-icon-edit" @click="toEditColonel(scope.row)"
											style="font-size: 16px; color: #409EFF; margin-left: 5px;"></i>
									</div>
								</el-button>
							</template>
						</el-table-column>
						<el-table-column prop="account_channel_info.username" label="所属服务商" align="center" width="100">
						</el-table-column>
						<el-table-column label="来源类别" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.source == 1">网上注册</span>
								<span v-else-if="scope.row.source == 2">代理创建</span>
								<span v-else-if="scope.row.source == 3">商户赠送</span>
							</template>
						</el-table-column>
						<el-table-column prop="account_shop_info.begin_time" label="启用时间" align="center" width="100">
						</el-table-column>
						<el-table-column prop="account_shop_info.end_time" label="过期时间" align="center" width="100">
						</el-table-column>
						<el-table-column prop="tele_phone" label="联系电话" align="center" width="100">
						</el-table-column>
						<el-table-column prop="address" label="地址信息" align="center" width="150" show-overflow-tooltip>
						</el-table-column>

						<el-table-column label="状态" align="center" width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.status == 1">启用</span>
								<span v-else-if="scope.row.status == 2">禁用</span>
								<span v-else-if="scope.row.status == 3">审核中</span>
								<span v-else-if="scope.row.status == 4">待续费</span>
							</template>
						</el-table-column>
						<el-table-column fixed="left" label="操作" width="280" align="center">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toDetail(scope.row)" v-has="'查看'">查看
								</el-button>
								<el-button type="text" size="small" @click="toDownLoad(scope.row)">关系表
								</el-button>
								<el-button type="text" size="small" @click="giftShop(scope.row)">赠送店铺
								</el-button>
								<el-button type="text" size="small" @click="toEdit(scope.row)" v-has="'编辑'">编辑
								</el-button>
								<el-button type="text" size="small" class="el_edits"
									v-if="scope.row.status == '1' || scope.row.status == '2'">
									<div v-show="scope.row.status == '2'" class="el_state"
										@click="upOrdown(scope.row, 1)" v-has="'禁用'">
										禁用
										<img src="../../../../../../assets/img/downs.png" style="margin-left: 10px;" />
									</div>
									<div v-show="scope.row.status == '1'" class="el_state"
										@click="upOrdown(scope.row, 2)" v-has="'启用'">
										启用<img src="../../../../../../assets/img/ups.png" style="margin-left: 10px;" />
									</div>
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<!--团队长比例-->
		<el-dialog title="团队长比例" :visible.sync="dialogVisible" width="25%">
			<el-form :model="teamform" ref="teamform" label-width="130px" :rules="teamrules" class="demo-ruleForm">
				<el-form-item label="店铺成交奖励" prop="shop_reward">
					<el-input placeholder="请输入店铺成交奖励" size="small" oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value<0)value='0';" v-model="teamform.shop_reward"
						@input="change($event)">
						<template slot="append">%</template>
					</el-input>
				</el-form-item>
				<el-form-item label="商品出货奖励" prop="goods_reward">
					<el-input placeholder="请输入店铺成交奖励" size="small"  oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value<0)value='0';" v-model="teamform.goods_reward"
						@input="change($event)">
						<template slot="append">%</template>
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="submitForm('teamform')">提交</el-button>
					<el-button size="small" @click="dialogVisible = false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!--关系表弹框-->
		<el-dialog title="关系表" :visible.sync="dialogAVisible" width="65%">
			<div style="width: 100%; max-height: 500px; overflow: auto;">
				<relationTable :data="relationData" ref="relation" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="downLoad">下载</el-button>
			</span>
		</el-dialog>
		<!--赠送店铺-->
		<el-dialog title="赠送网店/年" :visible.sync="dialogGiftShopVisible" width="25%">
			<el-form :model="form" ref="form" label-width="100px" class="demo-ruleForm">
				<el-form-item label="普通店长" prop="commonNum" :rules="[
			      { required: true, message: '不能为空'},
			      { type: 'number', message: '必须为数字值'}
			    ]">
					<el-input size="small" style="width: 85%;" v-model.number="form.commonNum">
						<template slot="append">个</template>
					</el-input>
				</el-form-item>
				<el-form-item label="高级店长" prop="seniorNum" :rules="[
				  { required: true, message: '不能为空'},
				  { type: 'number', message: '必须为数字值'}
				]">
					<el-input size="small" style="width: 85%;" v-model.number="form.seniorNum">
						<template slot="append">个</template>
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="submitForms('form')">提交</el-button>
					<el-button size="small" @click="resetForm('form')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!--金额扣除失败-->
		<el-dialog :visible.sync="centerFailVisible" :show-close="false" width="25%" center>
			<div style="width: 100%; text-align: center;">{{return_msg}}</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerFailVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="centerFailVisible = false" size="small">确定</el-button>
			</span>
		</el-dialog>
		<!--金额扣除成功-->
		<el-dialog :visible.sync="centerSuccessVisible" :show-close="false" width="25%" center>
			<div style="width: 100%; text-align: center;">赠送成功～</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerSuccessVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="surePay" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getShopList,
		saveStatus,
		setTeamLeader,
		setTeamProfit,
		shareTree,
		giveShop
	} from '@/api/plat/business'
	import relationTable from '../../../../../pagecomponents/relationTable.vue'
	export default {
		components: {
			relationTable
		},
		data() {
			return {
				loading:true,
				shop_name: '',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				accountcounts: {},
				dialogVisible: false,
				dialogAVisible: false,
				teamform: {
					shop_reward: '',
					goods_reward: ''
				},
				teamrules: {
					 shop_reward: [
					            { required: true, message: '请填写店铺成交奖励', trigger: 'blur' }
					   ],
					  goods_reward: [
								 { required: true, message: '请填写商品出货奖励', trigger: 'blur' }
							   ]
				},
				shop_id: '',
				relationData: {},
				dialogGiftShopVisible: false,
				return_msg: '',
				senior_manager_price: '', //高级店长价格
				general_manager_price: '', //普通店长价格
				form: {
					commonNum: 0,
					seniorNum: 0
				},
				centerFailVisible: false,
				centerSuccessVisible: false,
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			change(e) {
				this.$forceUpdate()
			},
			downLoad() {
				this.$refs.relation.down();
				this.dialogAVisible = false;

			},
			getList() {
				let data = {
					shop_name: this.shop_name,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data)
				getShopList(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list;
					this.accountcounts = res.data.account_count;
					this.tableData.forEach(item => {
						item.address = (item.shop_province != null ? item.shop_province : '') + (item
								.shop_city != null ? item.shop_city : '') +
							(item.shop_county != null ? item.shop_county : '') +
							(item.shop_address_detail != null ? item.shop_address_detail : '');
						if (item.account_shop_info != null) {
							item.account_shop_info.begin_time = this.tools.timeFormate(item
								.account_shop_info
								.begin_time * 1 * 1000);
							item.account_shop_info.end_time = this.tools.timeFormate(item.account_shop_info
								.end_time * 1 * 1000)
						}

					})

				})
			},
			toSearch() {
				this.currentPage=1;
				this.getList()
			},
			toReset() {
				this.currentPage=1;
				this.shop_name = '';
				this.getList()
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//查看详情
			toDetail(row) {
				this.$router.push({
					path: '/clientadmin/onlineaccount/onlineaccountadd',
					query: {
						id: row.shop_id,
						type: 'add'
					}
				})
			},
			//下载关系表
			toDownLoad(row) {
				let data = {
					account_shop_id: row.account_shop_id
				}
				shareTree(data).then(res => {
					this.relationData = res.data;
					this.dialogAVisible = true;
				})

			},
			toEdit(row) {
				this.$router.push({
					path: '/clientadmin/onlineaccount/onlineaccountadd',
					query: {
						id: row.shop_id,
						type: 'edit'
					}
				})
			},
			//禁用启用
			upOrdown(row, type) {
				this.$confirm(type == 1 ? '确定要启用吗？' : '确定要禁用吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						shop_id: row.shop_id,
						status: type == 1 ? 1 : 2
					}
					saveStatus(data).then(res => {
						this.getList()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					})
				})

			},
			submitForm(teamform) {
				this.$refs[teamform].validate((valid) => {
					if (valid) {
						let data = {
							shop_id: this.shop_id,
							shop_reward: this.teamform.shop_reward,
							goods_reward: this.teamform.goods_reward,
						}
						setTeamProfit(data).then(res => {
							this.$message.success("设置成功！")
							this.dialogVisible = false;
							this.getList()
						})
					} else {
						return false;
					}
				});
			},
			resetForm(teamform) {
				this.$refs[teamform].resetFields();
				this.dialogVisible = false;
			},
			isOpenColonel(row, type) {
				this.$confirm(type == 1 ? '确定要启用团队长吗？' : '确定要禁用团队长吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.shop_id = row.shop_id;
					if (type == 1) {
						let data = {
							shop_id: row.shop_id,
							team_leader: '1'
						}
						setTeamLeader(data).then(res => {
							this.dialogVisible = true;
							this.teamform.shop_reward = '';
							this.teamform.goods_reward = '';
							//this.getList()
						})
					}
					if (type == 0) {
						let data = {
							shop_id: row.shop_id,
							team_leader: '0'
						}
						setTeamLeader(data).then(res => {
							this.getList()
						})
					}

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					})
				})
			},
			toEditColonel(row) {
				this.dialogVisible = true;
				this.shop_id = row.shop_id;
				this.teamform.shop_reward = JSON.parse(JSON.stringify(row.shop_transaction_reward));
				this.teamform.goods_reward = JSON.parse(JSON.stringify(row.goods_shipment_reward));
			},
			//赠送店铺
			giftShop(row) {
				this.form.commonNum = 0;
				this.form.seniorNum = 0;
				this.dialogGiftShopVisible = true;
				this.account_shop_id = row.account_shop_id;
			},
			submitForms(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						let data = {
							account_shop_id: this.account_shop_id,
							senior_manager_number: this.form.seniorNum,
							general_manager_number: this.form.commonNum,
						}
						data = this.tools.filterParams(data);
						giveShop(data).then(res => {
							// this.centerSuccessVisible = false;
							// this.dialogGiftShopVisible = false;
							this.dialogGiftShopVisible = false;
							this.$message({
								message: '店铺赠送成功，商户可开始使用咯',
								type: 'success'
							});
							this.getList();
							
						}).catch(err => {
							this.$message({
								message: err.return_msg,
								type: 'error'
							});
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogGiftShopVisible = false;
			},
			surePay() {
				let data = {
					account_shop_id: this.account_shop_id,
					senior_manager_number: this.form.seniorNum,
					general_manager_number: this.form.commonNum,
				}
				data = this.tools.filterParams(data);
				giveShop(data).then(res => {
					this.centerSuccessVisible = false;
					this.dialogGiftShopVisible = false;
					this.getList();
					this.$message({
						message: '店铺赠送成功，商户可开始使用咯',
						type: 'success'
					});
				}).catch(err => {
					this.centerFailVisible = true;
					this.return_msg = err.return_msg
					//this.$message.error(err.return_msg)
				})

			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.el-col {
		border-radius: 4px;
	}

	.grid-content {
		background: #fff;
		padding: 30px 30px;
	}

	.shadow-box {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: #fff;
	}
</style>
