<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="alertstyle">
					<el-alert title="活动最多设置两个活动推送至移动端售卖" :closable="false" type="warning">
					</el-alert>
				</div>
				<div class="main-cont mt10 pp20">
					<el-row :gutter="20" style="position: relative;z-index: 1;">
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<el-button type="primary" size="small" @click="addActivityGood">添加活动商品</el-button>
								<el-button size="small" @click="batchOpera('del')">批量移除</el-button>
								<el-button size="small" @click="batchOpera('main')">设为主推</el-button>
								<span style="color: #2589FF;cursor: pointer;font-size: 14px;" @mouseover="mouseover"
									@mouseleave="mouseleave">示例</span>
							</div>
						</el-col>
						<el-col :span="6">
							<div style="margin-left: 50%">
								<el-row class="all_search_list" style="margin-bottom: 0;">
									<div class="demo-input-suffix">
										<el-input class="inputWidth" prefix-icon="el-icon-search" clearable @keyup.enter.native="toSearch"
											v-model="activity_name" placeholder="请输入活动名称" size="small" type="text">
										</el-input>
									</div>
									<div class="demo-input-suffix">
										<el-button type="primary" size="small" @click="toSearch">搜索
										</el-button>
									</div>
									<div class="demo-input-suffix">
										<el-button size="small" @click="toReset">重置</el-button>
									</div>
								</el-row>
							</div>
						</el-col>
						<div class="sampleImg mt20"
							style="position: absolute;z-index: 200;left: -300px;pointer-events:none;">
							<img src="../../../../../../assets/img/plat/market/goodimgshow.png" style=" width: 227px;"
								v-show="goodImgShow" />
						</div>
					</el-row>
				</div>
				<div class="main-cont mt10">
					<div class="tableMain">
						<el-table :data="tableData" style="width: 100%" min-height="250" border
							@selection-change="handleSelectionChange">
							<el-table-column type="selection" width="55">
							</el-table-column>
							<el-table-column label="商品名称" width="380" align="left">
								<template slot-scope="scope">
									<div class="el_name">
										<img :src="scope.row.goods_main_pic.split(',', 1)" class="el_img" />
										<div>
											<p>{{ scope.row.goods_name }}</p>
											<p class="mt10" style="color: #E6A23C;" v-if="scope.row.main_push==1">主推</p>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="cost_price" label="成本价" align="center">
							</el-table-column>
							<el-table-column prop="general_price" label="普通店长价" align="center">
							</el-table-column>
							<el-table-column prop="senior_price" label="高级店长价" align="center">
							</el-table-column>
							<el-table-column prop="sale" label="实际销量" align="center">
							</el-table-column>
							<el-table-column prop="total_stock" label="库存" align="center">
							</el-table-column>
							<el-table-column label="操作" align="center" prop="status" fixed="right">
								<template slot-scope="scope">
									<el-button type="text" size="small" @click="toRemove(scope.row)">移除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>

			</div>
		</div>
		<!-- 分页 -->
		<div class="pagecurrent">
			<div class="pagination-container">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getActivityGoodsPageList,
		modifyActivityPrice,
		modifyActivityStock,
		setMainPush,
		delActivityGoods
	} from '@/api/plat/market.js'
	export default {
		data() {
			return {
				activity_name: '',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				goodImgShow: false,
				multipleSelection: [],
				activity_id: '',
			}
		},
		mounted() {
			this.activity_id = this.$route.query.id;
			if (this.activity_id) {
				this.getList()
			}
		},

		methods: {
			//获取活动商品
			getList() {
				let params = {
					activity_id: this.activity_id,
					keyword: this.activity_name,
					page: this.currentPage,
					perPage: this.pagesize
				}
				params = this.tools.filterParams(params)
				getActivityGoodsPageList(params).then(res => {
					this.total = res.data.count;
					this.tableData = res.data.list;

				})
			},
			//库存价格判断
			onExchangeChange(row, index) {
				// console.log("exchange price change");
				let tmpObj = this.tableData[index];

				let activity_stock = parseFloat(tmpObj.activity_stock);
				let total_stock = parseFloat(tmpObj.total_stock);
				//console.log(activity_stock,total_stock,"666")
				if (activity_stock > total_stock) {
					this.$message.error('不能大于库存数量', {
						time: 1000
					})
					this.tableData[index].activity_stock = 0;
					return;
				}
				let data = {
					activity_goods_id: row.activity_goods_id,
					stock: row.activity_stock
				}
				modifyActivityStock(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.getList()
					}

				})

			},
			//设置活动价
			setPrice(val) {
				//console.log(val)
				let data = {
					activity_goods_id: val.activity_goods_id,
					price: val.activity_price
				}
				modifyActivityPrice(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.getList()
					}

				})

			},

			//去添加活动商品
			addActivityGood() {
				this.$router.push({
					path: "/marketingadmin/cattleshopgood/goodslibrary",
					query: {
						id: this.activity_id
					},
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},

			//示例
			mouseover() {
				this.goodImgShow = true;
			},
			mouseleave() {
				this.goodImgShow = false;
			},
			//多选
			handleSelectionChange(val) {
				let aa = [];
				val.forEach(item => {
					aa.push(item.activity_goods_id)
				})
				this.multipleSelection = aa;
			},
			//批量操作
			batchOpera(type) {
				if (this.multipleSelection.length == 0) {
					this.$message.info("请选择商品！");
					return;
				}
				if (type == 'del') {
					this.tools.confirm("确定批量移除商品？").then(res => {
						if (res) {
							//调接口
							let data = {
								activity_goods_ids: this.multipleSelection.toString()
							}
							delActivityGoods(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.getList()
								}
							})
						}
					})
				}
				if (type == 'main') {
					// if(this.multipleSelection.length > 2){
					// 	this.$message.info("请选择2款商品设为主推！");
					// 	return;
					// }
					let data = {
						activity_goods_ids: this.multipleSelection.toString()
					}
					setMainPush(data).then(res => {
						if (res.return_code == 'SUCCESS') {
							this.getList()
						}

					})
				}

			},

			//搜索
			toSearch() {
				this.currentPage = 1;
				this.getList()
			},
			toReset(){
				this.currentPage = 1;
				this.activity_name='';
				this.getList()
			},
			//单个移除
			toRemove(row) {
				this.tools.confirm("确定移除商品？").then(res => {
					if (res) {
						//调接口
						let data = {
							activity_goods_ids: row.activity_goods_id
						}
						delActivityGoods(data).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.getList()
							}
						})
					}
				})
			}

		}
	}
</script>

<style scoped="scoped" lang="scss">
	.alertstyle::v-deep.el-alert--warning.is-light {
		background-color: #FFEBDE;
		color: #FF822F;
	}

	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.el_img {
			width: 70px;
			height: 70px;
			margin-right: 6px;
		}
	}

	.sampleImg {
		text-align: center;
		width: 100%;
		height: auto;
	}
</style>
