<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont">
					<div class="order-search" style="padding: 0;">
						<!-- <el-row class="all_search_list " style="border-bottom: 1px solid #F2F2F6; padding-bottom: 20px;">
							<div class="demo-input-suffix">
								<el-select size="small" v-model="is_publish" placeholder="全部状态">
									<el-option label="全部状态" value=""></el-option>
									<el-option label="上架" value="1"></el-option>
									<el-option label="下架" value="2"></el-option>
								</el-select>
							</div>
							<div class="demo-input-suffix">
								<el-input prefix-icon="el-icon-search" placeholder="请输入商品名称" size="small" type="text"
									v-model="keyword">
								</el-input>
							</div>
							<div class="demo-input-suffix">
								<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
							</div>
							<div class="demo-input-suffix">
								<el-button size="small" @click="toReset">重置</el-button>
							</div>
						</el-row> -->
						<el-row type="flex" class="row-bg" justify="space-between">
							<el-col :span="12">
								<div class="grid-content bg-purple">
									<el-button type="primary" size="small" plain @click="changeTab()">返回专场列表</el-button>
									<el-button size="small" plain @click="addGoods">添加商品</el-button>
									<el-button size="small" plain @click="banthDel()">批量移除</el-button>
								</div>
							</el-col>
							<el-col :span="10">
								<div class="grid-content bg-purple selectdata " style="margin-top: 50px;">
									<div class="samplegraph">
										<p>三款商品设为专场主推商品</p>
										<div class="img_blo">
											<span>示例</span>
											<img src="../../../../../../assets/img/merchant/specialimg.png" />
										</div>
									</div>
								</div>
							</el-col>
						</el-row>

					</div>
				</div>
				<div class="main-cont mt10">
					<el-table :data="tableData" tooltip-effect="dark" style="width: 100%" max-height="530"
						@selection-change="handleSelectionChange" border>
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column label="商品名称" min-width="360">
							<template slot-scope="scope">
								<div class="el_names" v-if="scope.row">
									<img :src="scope.row.goods_main_pic" class="names_img" />
									<div>
										<div class="namegood">
											{{ scope.row.goods_name }}
										</div>
										<!-- <span>数量：{{ scope.row.goods_amount }}</span> -->
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="goods_sku.sku_market_price" label="原价" align="center">
						</el-table-column>
						<!-- <el-table-column prop="goods_sku.sku_purchase_price" label="结算价"  align="center">
						</el-table-column> -->
						<el-table-column prop="goods_sku.sku_sale_price" label="销售价" align="center">
						</el-table-column>
						<!-- <el-table-column prop="goods_sku.goods_stock" label="总库存"  align="center">
						</el-table-column> -->
						<el-table-column label="实际销量" align="center" prop="sale">
						</el-table-column>
						<el-table-column prop="type" label="上架状态" align="center">
							<template slot-scope="scope">
								<div class="el_ups">
									<span v-if="scope.row.is_publish == 1">上架</span>
									<span v-else>下架</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="200px" align="center">
							<template slot-scope="scope">
								<!-- <el-button type="text"  @click="upEdit(scope.row)">编辑</el-button> -->
								<el-button type="text" style="color: #E6A23C;" v-if="scope.row.hot == 'Y'"
									@click="removeSet(scope.row)">移除主推</el-button>
								<el-button type="text" v-if="scope.row.hot == 'N'" @click="upSet(scope.row)">设置主推
								</el-button>
								<el-button type="text" style="color: #F56C6C;" @click="upDel(scope.row)">移除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<!-- 分页 -->
		<div class="pagecurrent">
			<div class="pagination-container">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getActivitiesResetGoodsList,
		saveActivitiesResetGoodsHot,
		activitiesResetGoodsDel
	} from "@/api/merchant/specia.js"
	export default {
		data() {
			return {
				is_publish: '',
				keyword: '',
				tableData: [],
				multipleSelection_goods_id: [],
				multipleSelection_reset_id: [],
				composing_special_activities_reset_id: '',
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}

		},
		mounted() {
			if (this.$route.query.id) {
				this.composing_special_activities_reset_id = this.$route.query.id;
				this.getList()
			}
		},
		methods: {
			getList() {
				let data = {
					composing_special_activities_reset_id: this.composing_special_activities_reset_id,
					page: this.currentPage,
					perPage: this.pagesize
				}
				getActivitiesResetGoodsList(data).then(res => {
					this.total = res.data.count;
					this.tableData = res.data.list;
					this.tableData.forEach(item => {
						item.goods_main_pic = item.goods_main_pic.split(',')[0]
					})

				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//查询
			toSearch() {
				this.currentPage = 1;
				this.getList()
			},
			toReset() {
				this.currentPage = 1;
				this.getList()
			},
			//返回专场列表
			changeTab() {
				this.$router.push({
					path: "/specialadmin/specialmanage/speciallist"
				})

			},
			//添加商品
			addGoods() {
				this.$router.push({
					path: "/specialadmin/specialmanage/goodmanage",
					query: {
						id: this.composing_special_activities_reset_id
					}
				})

			},
			//批量移除
			banthDel() {
				this.tools.confirm("确定要批量移除？").then(res => {
					if (res) {
						//调接口
						let data = {
							composing_special_activities_reset_id: this.multipleSelection_reset_id.toString(),
							composing_special_activities_goods_id: this.multipleSelection_goods_id.toString()
						}
						
						activitiesResetGoodsDel(data).then(res => {
							this.getList()
						})
					}
				})

			},
			//批量操作
			handleSelectionChange(val) {
				let aa = [],bb = [];
				val.forEach(item => {
					aa.push(item.composing_special_activities_goods_id);
					bb.push(item.composing_special_activities_reset_id);
				})
				this.multipleSelection_goods_id = aa;
				this.multipleSelection_reset_id = bb;
			},
			//单个移除
			upDel(row) {
				this.tools.confirm("确定要移除？").then(res => {
					if (res) {
						//调接口
						let data = {
							composing_special_activities_reset_id: row.composing_special_activities_reset_id,
							composing_special_activities_goods_id: row.composing_special_activities_goods_id
						}
						activitiesResetGoodsDel(data).then(res => {
							this.getList()
						})
					}
				})
			},
			//设为主推
			upSet(row) {
				let data = {
					composing_special_activities_reset_id: row.composing_special_activities_reset_id,
					composing_special_activities_goods_id: row.composing_special_activities_goods_id,
					hot: 'Y'
				}
				data = this.tools.filterParams(data);
				saveActivitiesResetGoodsHot(data).then(res => {
					this.getList()
				})
			},
			removeSet(row) {
				let data = {
					composing_special_activities_reset_id: this.composing_special_activities_reset_id,
					composing_special_activities_goods_id: row.composing_special_activities_goods_id,
					hot: 'N'
				}
				data = this.tools.filterParams(data);
				saveActivitiesResetGoodsHot(data).then(res => {
					this.getList()
				})
			},
			//编辑
			upEdit(row) {

			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.selectdata {
		position: relative;
	}

	.samplegraph {
		position: absolute;
		right: 10px;
		top: -48px;
		z-index: 999;

		.img_blo {
			display: flex;
			color: #2589FF;
			font-size: 16px;

			&:hover {
				img {
					opacity: 1;
				}
			}

			span {
				padding-right: 10px;
				cursor: pointer;
			}


			img {
				width: 129px;
				height: 111px;
				opacity: 0;
			}
		}

		p {
			font-size: 16px;
			color: #666;
			margin-bottom: 5px;
		}

	}

	.el_names {
		display: flex;
		font-size: 12px;
		color: #666;

		.names_img {
			width: 70px;
			height: 70px;
			margin-right: 5px;
		}

		.namegood {
			color: #333;
			margin-bottom: 30px;
			width: 180px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
</style>
