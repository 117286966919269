<template>
	<div>
		<div class="wrapper-main pp20">
			<el-tabs v-model="activeName">
				<el-tab-pane label="专场列表" name="first">
					<div class="wrapper-cont">
						<div class="main-cont bgwt pp20">
							<el-row class="all_search_list" style="margin-bottom: 0;">
								<div class="demo-input-suffix">
									<el-button size="small" class="mr10" type="primary" plain @click="toAddSpecial">添加专场
									</el-button>
									<!-- <el-select size="small" class="mr10" v-model="special_mark" placeholder="专场标签">
										<el-option label="专场标签" value="1"></el-option>
									</el-select>
									<el-button size="small" class="mr10" @click="batchOpera('tag')">打标签</el-button> -->
									<el-button size="small" class="mr10" @click="batchOpera('open')">启用</el-button>
									<el-button size="small" class="mr10" @click="batchOpera('stop')">禁用</el-button>
									<!-- <el-button size="small" class="mr10" @click="batchOpera('del')">删除</el-button> -->
								</div>
								<div class="demo-input-suffix">
									<el-input size="small" class="inputWidth" placeholder="请输入专场名称"
										v-model="special_name"   @keyup.enter.native="toSearch" clearable>
									</el-input>
								</div>
								<div class="demo-input-suffix">
									<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
								</div>
								<div class="demo-input-suffix">
									<el-button size="small" @click="toReset">重置</el-button>
								</div>
							</el-row>
						</div>
						<div class="main-cont bgwt mt10">
							<el-table :data="tableData" style="width: 100%" border
								@selection-change="handleSelectionChange" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
								<el-table-column type="selection" width="55"> </el-table-column>
								<el-table-column prop="special_name" label="专场名称">
								</el-table-column>
								<el-table-column label="专场首页图片" align="center" min-width="100px">
									<template slot-scope="scope">
										<img :src="scope.row.entrance_image" class="imgwidth" />
									</template>
								</el-table-column>
								<el-table-column label="专场顶部图片" align="center" min-width="100px">
									<template slot-scope="scope">
										<img :src="scope.row.top_image" class="imgwidth" />
									</template>
								</el-table-column>
								<el-table-column prop="weight" label="排序" align="center">
								</el-table-column>
								<!-- <el-table-column prop="glance_amount" label="浏览量" align="center">
								</el-table-column> -->
								<el-table-column prop="state" label="启用禁用" align="center">
									<template slot-scope="scope">
										<div v-if="scope.row.state == 'Y'" class="el_state"
											@click="upOrdown(scope.row, 'close')">
											启用
											<img src="../../../../../../assets/img/ups.png"
												style="margin-left: 10px;" />
										</div>
										<div v-else-if="scope.row.state == 'N'" class="el_state"
											@click="upOrdown(scope.row, 'open')">
											禁用<img src="../../../../../../assets/img/downs.png"
												style="margin-left: 10px;" />
										</div>
									</template>
								</el-table-column>
								<el-table-column label="操作" align="center" fixed="right">
									<template slot-scope="scope">
										<el-button type="text" size="small" style="color: #409eff"
											@click="editgoods(scope.row)">编辑</el-button>
										<el-button type="text" size="small" @click="addSpeGoods(scope.row)">专场商品
										</el-button>
										<el-button type="text" size="small" style="color: #f56c6c"
											@click="toDel(scope.row)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<!-- 分页 -->
							<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
								:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
								layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>

		<!--打标签-->
		<MakeTag :dialogMarkVisible="dialogMarkVisible" @tagname="tagname" @hideModel="hideModel"></MakeTag>
	</div>
</template>

<script>
	import {
		getActivitiesReset,
		saveActivitiesResetStatus,
		activitiesResetDel
	} from "@/api/merchant/specia.js"
	import MakeTag from '../../../../../pagecomponents/makeTag.vue'
	export default {
		components: {
			MakeTag
		},
		data() {
			return {
				loading:true,
				activeName: 'first',
				special_mark: '',
				special_name: '',
				tableData: [],
				multipleSelection: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				dialogMarkVisible: false,
				tagsname: '', //打标签值
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let data = {
					special_name: this.special_name,
					page: this.currentPage,
					perPage: this.pagesize
				}
				getActivitiesReset(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list;

				})
			},
			//搜索
			
			toSearch() {
				this.currentPage = 1;
				//调接口
				this.getList()
			},
			toReset() {
				this.currentPage = 1;
				this.special_name = '';
				this.getList()
			},
			//启用禁用
			upOrdown(row, type) {
				if (type == 'close') {
					this.$confirm('确定要禁用吗？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let data = {
							composing_special_activities_reset_id: row.composing_special_activities_reset_id,
							state: 'N'
						}
						saveActivitiesResetStatus(data).then(res => {
							this.getList()
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						})
					})
				}
				if (type == 'open') {
					this.$confirm('确定要启用吗？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let data = {
							composing_special_activities_reset_id: row.composing_special_activities_reset_id,
							state: 'Y'
						}
						saveActivitiesResetStatus(data).then(res => {
							this.getList()
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						})
					})
				}


			},


			//全选
			handleSelectionChange(val) {
				let aa = []
				val.forEach(item => {
					aa.push(item.composing_special_activities_reset_id)
				})
				this.multipleSelection = aa;

			},
			//编辑专场
			editgoods(row) {
				this.$router.push({
					path: "/specialadmin/specialmanage/specialadd",
					query: {
						id: row.composing_special_activities_reset_id
					}
				})
			},
			//添加商品
			addSpeGoods(row) {
				this.$router.push({
					path: '/specialadmin/specialmanage/specialgood',
					query: {
						id: row.composing_special_activities_reset_id
					}
				})
			},
			//单个删除
			toDel(row) {
				this.tools.confirm("确定要删除？").then(res => {
					if (res) {
						//调接口
						activitiesResetDel({composing_special_activities_reset_id:row.composing_special_activities_reset_id}).then(res=>{
							this.$message.success("删除成功！")
							this.getList()
							
						})
					}
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//添加专场
			toAddSpecial() {
				this.$router.push({
					path: "/specialadmin/specialmanage/specialadd"
				})
			},
			//批量操作
			batchOpera(type) {
				if (this.multipleSelection.length <= 0) {
					this.$message.warning("请先选择专场！")
					return;
				}
				// if(type=='tag'){
				// 	this.dialogMarkVisible = !this.dialogMarkVisible
				// }
				if (type == 'open') {
					this.tools.confirm("确定要批量启用？").then(res => {
						if (res) {
							//调接口

							let data = {
								composing_special_activities_reset_id: this.multipleSelection.toString(),
								state: 'Y'
							}
							saveActivitiesResetStatus(data).then(res => {
								this.getList()
							})

						}
					})

				}
				if (type == 'stop') {
					this.tools.confirm("确定要批量禁用？").then(res => {
						if (res) {
							//调接口

							let data = {
								composing_special_activities_reset_id: this.multipleSelection.toString(),
								state: 'N'
							}
							saveActivitiesResetStatus(data).then(res => {
								this.getList()
							})

						}
					})
				}
				if (type == 'del') {
					this.tools.confirm("确定要批量删除？").then(res => {
						if (res) {
							//调接口
						}
					})
				}
			},
			//获取tagname
			tagname(val) {
				this.tagsname = val;
			},
			//关闭标签弹框
			hideModel(val) {
				this.dialogMarkVisible = val
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.imgwidth {
		width: 70px;
		height: 40px;
	}

	.el_state {
		display: flex;
		justify-content: center;

		img {
			margin-left: 10px;
			width: 42px;
			height: 22px;
		}
	}
</style>
