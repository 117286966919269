<template>
	<div>
		<div class="acdetail" v-if="type=='detail'">
			<PlatGoodSupplyDetail :activeName="activeName" :currentPage='currentPage'></PlatGoodSupplyDetail>
		</div>
		<div class="addetail" v-if="type=='add'">
			<PlatGoodSupplyAdd :activeName="activeName" :currentPage='currentPage'></PlatGoodSupplyAdd>
		</div>
	</div>
</template>
<script>
	import PlatGoodSupplyDetail from '../../../../../pagecomponents/platGoodSupplyDetail.vue'
	import PlatGoodSupplyAdd from '../../../../../pagecomponents/platGoodSupplyAdd.vue'
	export default {
		components: {
			 PlatGoodSupplyDetail,
			 PlatGoodSupplyAdd
		},
		data() {
			return {
				type:this.$route.query.type,
				activeName:this.$route.query.activeName,
				currentPage:this.$route.query.currentPage,
				
			}
		},
		mounted() {
			
		},
		methods: {



		}

	}
</script>

<style lang="scss" scoped="scoped">

</style>
