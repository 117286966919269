<template>
    <div class="pdsb">
        <el-form :model="formData" label-width="150px" class="demo-ruleForm" status-icon>
            <div class="wrapper-cont pd20">
                <div class="line-title mgb20">基础信息</div>
                <el-form-item label="店铺名称：">{{formData.shop_name}}</el-form-item>
                <el-form-item label="店铺logo：">
                    <div style="width:60px;height:60px;background-color: #fff;">
                        <el-image :src="formData.shop_logo" class="el_img" v-if="formData.shop_logo"
                            :preview-src-list="[formData.shop_logo]" />
                    </div>
                </el-form-item>
                <el-form-item label="主营类目：">
                    <span v-if="formData.marketing_trade_info">{{formData.marketing_trade_info.trade_name}}</span>
                </el-form-item>
                <el-form-item label="详细地址：">
                    {{(formData.shop_province || '')+(formData.shop_city || '')+(formData.shop_county ||
                    '')+(formData.shop_address_detail || '')}}
                </el-form-item>
                <el-form-item label="店铺状态：">
                    {{formData.status==1?'正常营业':formData.status==2?"已关闭":formData.status==3?"审核中":'待续费'}}
                </el-form-item>
                <el-form-item label="联系电话：">{{formData.service_tel}}</el-form-item>
                <!-- <el-form-item label="营业时间：">{{formData.business_start + formData.business_end }}</el-form-item> -->
                <el-form-item label="开店时间：">{{formData.created_at}}</el-form-item>
            </div>
            <div class="wrapper-cont mt10">
                <div class="line-title mgb20">其他信息</div>
                <el-form-item label="营业时间：">
                    <ul class="infinite-list"  style="overflow:auto">
                        <li v-for="item in formData.business_day" class="infinite-list-item">{{ item.weekText }}：{{item.startTime}}~{{item.diurnalEndTime}}-{{item.overnightBeginTime}}~{{item.endTime}}</li>
                    </ul>
                </el-form-item>
                <el-form-item label="服务电话：">{{formData.tele_phone || ''}}</el-form-item>
                <el-form-item label="微信二维码：">
                    <div style="width:60px;height:60px;background: #fff;">
                        <el-image :src="formData.wechat_qrcode" class="el_img" v-if="formData.wechat_qrcode"
                            :preview-src-list="[formData.wechat_qrcode]" />
                    </div>
                </el-form-item>
                <el-form-item label="营业执照：">
                    <div style="width:130px;height:96px;background: #DFDFDF;">
                        <el-image :src="formData.business_license_images" class="el_img"
                            v-if="formData.business_license_images"
                            :preview-src-list="[formData.business_license_images]" />
                    </div>
                </el-form-item>
                <el-form-item label="法人名字：">
                    <span v-if="formData.business_license_info">{{formData.business_license_info.ENTERPRISE_OWNER}}</span>
                </el-form-item>
                <el-form-item label="住所：">
                    <span v-if="formData.business_license_info">{{formData.business_license_info.ENTERPRISE_REGISTER_ADDRESS}}</span>
                </el-form-item>
                <el-form-item label="联系电话：">
                    <span v-if="formData.business_license_info">{{formData.business_license_info.ENTERPRISE_TEL}}</span>
                </el-form-item>
            </div>
        </el-form>
        <div class="save-cont">
            <div class="cancel-btn" @click="goback">返回</div>
            <div class="save-btn" @click="twoSubmit()" v-if="formData.status==1">关闭店铺</div>
        </div>
        <!--审核不通过-->
        <el-dialog title="店铺关闭原因" :visible.sync="visibleModel" width="25%">
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="reason"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visibleModel=false">取消</el-button>
                <el-button size="small" type="primary" @click="visibleSubmit">提交</el-button>
            </span>

        </el-dialog>
    </div>
</template>
<script>
import { getShopInfo, saveStatus } from '@/api/plat/shop.js'
export default {
    data() {
        return {
            shop_id: this.$route.query.shop_id,
            formData: {},
            visibleModel: false,
            reason: ''
        }
    },
    mounted() {
        // if (this.shop_id) {
        //     this.getInfo()
        // }
    },
    methods: {
        getInfo() {
            let data = {
                shop_id: this.shop_id
            }
            getShopInfo(data).then(res => {
                this.formData = res.data;


            })
        },
        goback() {
            this.$router.go(-1)
        },
        twoSubmit() {
            this.reason = '';
            this.visibleModel = true

        },
        visibleSubmit() {
            let data = {
                shop_id: this.shop_id,
                status: 2,
                reason: this.reason
            }
            saveStatus(data).then(() => {
                this.visibleModel=false;
                this.$message.success("店铺关闭成功！");
                this.getInfo()
            })
        }



    }
}
</script>
<style lang="less" scoped>
.pdsb {
    padding-bottom: 120px !important;
}
</style>