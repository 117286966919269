<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<el-tabs class="tabstyle" v-model="activeName" type="card" @tab-click="handleClick">
					<el-tab-pane name=""><span slot="label">全部订单</span></el-tab-pane>
					<el-tab-pane label="待支付订单" name="1"><span slot="label">待支付</span></el-tab-pane>
					<el-tab-pane label="待牛店发货" name="2"><span slot="label">待牛店发货</span></el-tab-pane>
					<el-tab-pane label="牛店已发货" name="3"><span slot="label">牛店已发货</span></el-tab-pane>
					<el-tab-pane label="已完成" name="5"><span slot="label">已完成</span></el-tab-pane>
					<el-tab-pane label="交易关闭" name="6"><span slot="label">交易关闭</span></el-tab-pane>
				</el-tabs>
				<div class="order-search">
					<el-row class="all_search_list" style="margin-bottom:0">
						<!-- <div class="demo-input-suffix" style="margin-right: 0px;">
							<el-select style="width: 120px;" size="small" v-model="search.search_type"
								placeholder="订单编号">
								<el-option label="全部" value=""></el-option>
								<el-option label="订单号" value="1"></el-option>
								<el-option label="姓名" value="2"></el-option>
								<el-option label="手机号" value="3"></el-option>
							</el-select>
						</div> -->
						<div class="demo-input-suffix">
							<!-- <el-input clearable class="inputWidth" prefix-icon="el-icon-search"
								v-model="search.search_value" placeholder="请输入" size="small" type="text">
							</el-input> -->
							<el-input placeholder="请输入" v-model="search.search_value" size="small"
								class="input-with-select" @keyup.enter.native="searchOrder" clearable>
								<el-select v-model="search.search_type" slot="prepend" placeholder="请选择">
									<el-option label="全部" value=""></el-option>
									<el-option label="订单号" value="1"></el-option>
									<el-option label="姓名" value="2"></el-option>
									<el-option label="手机号" value="3"></el-option>
								</el-select>
							</el-input>
						</div>
						<div class="demo-input-suffix" style="margin-right: 0;">
							<el-select class="w150" size="small" v-model="search.order_ganre"
								placeholder="请选择订单类型">
								<el-option label="全部" value=""></el-option>
								<el-option label="普通订单" value="1"></el-option>
								<el-option label="秒杀订单" value="3"></el-option>
								<el-option label="拼团订单" value="4"></el-option>
								<el-option label="视频号订单" value="5"></el-option>
								<el-option label="裂变优惠券" value="6"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
							下单时间：
							<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
								range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
								@change="changetimer"></el-date-picker>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder">查询</el-button>
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
					</el-row>
				</div>
				<div class="tableMain">
					<el-table  :data="tableData"
						style="font-size: 12px;width: 100%;" max-height="550px" :default-expand-all="true" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<div style="overflow: hidden">
							<el-table-column type="expand" width="1">
								<template slot-scope="props">
									<table border="1" class="tableStyle">
										<tbody>
											<tr v-for="(item,index) in props.row" :key="index">
												<td style=" width:25%;">
													<div style="display: flex; margin: 10px 0px;">
														<div style="margin:auto 10px" v-if="item.order_goods_info != null">
															<!-- <el-popover placement="right" title width="200" trigger="hover">
																<div style="width:200px;height:200px;">
																  <img
																	style="width:100%;height:100%;object-fit: cover;"
																	:src="item.order_goods_info.master_map"
																	alt
																  />
																</div>
																<div style="width:70px;height:70px;" slot="reference">
																 <img style="width: 100%;height: 100%;"  v-if="item.order_goods_info != null" :src="item.order_goods_info.master_map" class="el_img"/>
																</div>
															  </el-popover> -->
															   <div style="width:70px;height:70px;" >
																<el-image style="width: 100%;height: 100%;" :src="item.order_goods_info.master_map" class="el_img" :preview-src-list="[item.order_goods_info.master_map]"/>
															</div>
														</div>
														<div  v-if="item.order_goods_info != null">
															<div class="namegood">
																<span  v-if="item.order_ganre=='3'">整点秒杀</span>
																<span v-if="item.order_ganre=='4'">幸运团购</span>
																<span v-if="item.order_ganre=='5'">视频号</span>
																<span v-if="item.coupon_price">裂变优惠券</span>
																{{item.order_goods_info.goods_name }}
															</div>
															<div class="namegood" style="margin-top:5px">
																子单号：{{item.order_goods_info.order_number }}</div>
															<div class="pricegood" style="margin-top:5px">
																数量：{{item.order_goods_info.goods_count}}
																<span
																	style="margin-left: 50px;">￥{{item.order_goods_info.goods_price }}</span>
															</div>
														</div>
													</div>
												</td>
												<td style="width: 10%;padding-left:15px">
													<p>应付：{{item.total_money}}</p>
													<p>运费：{{item.freight}}</p>
													<p style="color: #F56C6C;" v-if="item.status!=1&&item.status!=6">实付：{{props.row.status}}{{item.actual_payment}}</p>
												</td>
												<td style="width:10%;text-align: center">
													<div>
														<p>{{item.consignee_name}}</p>
														<p>{{item.consignee_phone}}</p>
													</div>
												</td>
												<td style="width:8%;text-align: center">
													<span v-if="item.payment_type==0">未支付</span>
													<span v-else-if="item.payment_type==1">微信</span>
													<span v-else-if="item.payment_type==2">支付宝</span>
													<span v-else-if="item.payment_type==3">余额</span>
													<span v-else-if="item.payment_type==4">微信组合</span>
													<span v-else-if="item.payment_type==5">支付宝组合</span>
												</td>
												<td style="width:8%;text-align: center">
													<span v-if="item.courier_code">快递配送</span>
													<span v-else>/</span>
												</td>
												<td style="width:10%;text-align: center">
													<p v-if="item.status === 1">
														<!-- 待付款 -->
														<span>待支付 </span>
														<!-- <br />
														<el-tag style="margin-top: 5px; cursor: pointer;" size="small"
															@click="closeOrders(item)">取消订单</el-tag> -->
													</p>
													<p v-else-if="item.status === 2">
														<!-- <span>待发货 </span> -->
														<span>已支付</span>
														<!-- <br />
														<el-tag style="margin-top: 5px; cursor: pointer;" size="small"
															@click="shipments(item,'one')">发货</el-tag> -->
													</p>
													<p v-else-if="item.status === 3">
														<span>待收货 </span>
														<br />
														<el-tag style="margin-top: 5px; cursor: pointer;" size="small"
															@click="logisticsQuery(item)">物流查询</el-tag>
													</p>
													<p v-else-if="item.status === 4">
														<span>已收货 </span>
													</p>
													<p v-else-if="item.status === 5">
														<span>已完成 </span>
													</p>
													<p v-else-if="item.status === 6">
														<span>已关闭 </span>
													</p>
												</td>
												<td style="width:10%;text-align: center">
													{{item.buyers_remark}}
												</td>
												<td style="width:10%;text-align: center">
													<el-button type="text" size="small" @click="orderDetail(item)">
														查看详情</el-button>
												</td>
											</tr>
										</tbody>
									</table>
								</template>
							</el-table-column>
						</div>
						<el-table-column label="商品信息" min-width="200">
							<template slot-scope="scope" style="padding: 10px 0;">
								<div class="aaa">
									<span class="orderTime">{{scope.row[0].created_at}}</span>
									主单号：<span style="color: #1d8ce0;">{{ scope.row[0].master_order_number}}</span>
									<!-- <el-tag v-if="scope.row.length>1 && scope.row.map(item => item.status === 2).toString().includes('true')" effect="dark" style="margin-left: 5px; cursor: pointer;" size="small"
										@click="shipments(scope.row,'more')">合并发货</el-tag> -->
								</div>
							</template>
						</el-table-column>
						<el-table-column label="付款" align="center">
						</el-table-column>
						<el-table-column label="买家" align="center">
						</el-table-column>
						<el-table-column label="支付方式" align="center">
						</el-table-column>
						<el-table-column label="配送方式" align="center">
						</el-table-column>
						<el-table-column label="订单状态" align="center">
						</el-table-column>
						<el-table-column label="备注" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
						</el-table-column>
					</el-table>
					<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
		<!--物流发货弹框-->
		<el-dialog title="物流发货" :visible.sync="logisticsModel" width="40%">
			<el-form ref="logisticsForm" :model="logisticsForm" :rules="logisticsRules" label-width="100px"
				size="small">
				<el-form-item label="物流单号:" prop="oddNum">
					<el-input v-model="logisticsForm.oddNum" class="inputWidth" placeholder="请填写物流单号">
					</el-input>
				</el-form-item>
				<el-form-item label="物流公司:" prop="company">
					<el-select v-model="logisticsForm.company">
						<el-option v-for="item in logisticData" :key="item.name" :value="item.name" :label="item.name">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="logisticsModel=false">取消</el-button>
				<el-button size="small" type="primary" @click="onSubmitLogistic('logisticsForm')">提交</el-button>
			</div>
		</el-dialog>
		<!--物流详情-->
		<el-dialog title="物流详情" :visible.sync="logisticsDetailModel" width="40%">
			<div v-if="activities.length>0">
				<div style="font-size: 16px;margin-bottom: 20px;color: #409EFF;">快递名称：{{typename}} 快递单号：{{typenumber}}</div>
				<el-timeline>
					<el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.time">
						{{activity.status}}
					</el-timeline-item>
				</el-timeline>
			</div>
			<div v-else style="height: 100px; display: flex;align-items: center;justify-content: center;">
				暂无快递信息！
			</div>

		</el-dialog>
	</div>
</template>

<script>
	import {
		orderList,
		getExpressInfoList,
		dispatchBill,
		closeOrder,
		orderNumberQuery
	} from "@/api/merchant/order.js"
	export default {
		data() {
			return {
				loading:true,
				activeName: '',
				search: {
					search_type: '',
					search_value: '',
					begin_time: '',
					end_time: '',
					goods_source: 1,
					status: '',
					order_ganre:''
				},
				logisticsDetailModel: false,
				due_dt: '',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				logisticsModel: false,
				logisticsForm: {
					oddNum: '',
					company: ''
				},
				logisticsRules: {
					oddNum: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}, ],
					company: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}]
				},
				logisticData: [],
				order_number: '',
				orderTypeNum: {
					close_number: 0,
					completed: 0,
					refund_number: 0,
					to_be_deliverd_number: 0,
					to_be_received: 0,
					unpaid_number: 0,
					whole_order_number: 0,
				},
				order_status: "",
				activities: [],
				typename: '',
				typenumber:'',
			}
		},
		created() {
			if (this.$route.query.name) {
				this.activeName = this.$route.query.name;
				this.search.status = this.$route.query.name;
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			//订单列表
			getList() {
				let params = this.search;
				params.page = this.currentPage;
				params.perPage = this.pagesize;
				params = this.tools.filterParams(params);
				orderList(params).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list;
				})
			},
			//获取物流列表
			getExpressList() {
				let params = {
					pitchon: 1
				}
				params = this.tools.filterParams(params);
				getExpressInfoList(params).then(res => {
					this.logisticData = res.data;

				})
			},
			handleClick(val) {
				this.currentPage = 1;
				this.search.search_type = '';
				this.search.search_value = '';
				this.search.order_ganre = '';
				this.search.begin_time = '';
				this.search.end_time = '';
				this.search.goods_source = 1;
				this.search.status = val.name;
				this.getList()
			},
			searchOrder() {
				this.currentPage = 1;
				this.getList()
			},
			toReset() {
				this.currentPage = 1;
				this.search.search_type = '';
				this.search.search_value = '';
				this.search.begin_time = '';
				this.search.end_time = '';
				this.search.order_ganre = '';
				this.search.goods_source = 1;
				this.search.status = '';
				this.getList();
			},
			//时间选择
			changetimer(val) { //时间
				if (val) {
					this.search.begin_time = val[0] / 1000;
					this.search.end_time = val[1] / 1000;
				} else {
					this.search.begin_time = '';
					this.search.end_time = '';
				}
			},
			//订单详情
			orderDetail(row) {
				this.$router.push({
					path: '/ordergoodadmin/supplierorder/supplierorderdetail',
					query: {
						id: row.order_number,
						type: 'merchant-supply',
					}
				})
			},

			handleSizeChange(size) {
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
			//发货
			shipments(row, type) {
				if (type == 'more') {
					let nums = []
					row.forEach(item => {
						nums.push(item.order_number)
					});
					this.order_number = nums.toString()
				} else if (type == 'one') {
					this.order_number = row.order_number
				}
				this.logisticsForm.oddNum = '';
				this.logisticsForm.company = '';
				this.logisticsModel = !this.logisticsModel;
				this.getExpressList()
			},
			//取消订单
			closeOrders(row) {
				this.tools.confirm("确定要取消此订单吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							order_number: row.order_number
						}
						closeOrder(data).then(res => {
							this.getList()
						})
					}
				})
			},
			//提交物流
			onSubmitLogistic(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//调接口
						let data = {
							order_number: this.order_number,
							courierCode: this.logisticsForm.oddNum,
							logisticsName: this.logisticsForm.company
						}
						data = this.tools.filterParams(data)
						dispatchBill(data).then(res => {
							this.logisticsModel = !this.logisticsModel;
							this.getList();
							this.logisticsForm.oddNum = '',
								this.logisticsForm.company = ''
						})
					} else {
						return false;
					}
				});
			},
			logisticsQuery(row) {
				this.logisticsDetailModel = !this.logisticsDetailModel;
				let params = {
					order_number: row.order_number
				}
				orderNumberQuery(params).then(res => {
					//物流信息
					if (res.data.list.length > 0) {
						this.activities = res.data.list;
						this.typenumber=res.data.number
						this.typename = res.data.typename;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	@import "@/assets/css/plat/order.scss";

	::v-deep.el-table .el-table_1_column_2 .cell {
		width: 31.25rem !important;
	}

	::v-deep.el-tabs__header {
		padding: 0;
		position: relative;
		margin: 0 0 0;
	}

	::v-deep.input-with-select .el-select .el-input {
		width: 130px !important;
	}

	::v-deep.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}

	::v-deep.el-table__expand-icon>.el-icon {
		display: none;
	}

	::v-deep.el-table .cell {
		overflow: visible !important;
	}
	::v-deep .el-table .el-table__cell{
		position: inherit;
	}
	.tableMain::v-deep.aaa {
		width: 500px;
	}
</style>
