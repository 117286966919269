import axios from '@/config/httpConfig'
import qs from  'qs'
//获取页面标题配置
export const saveBasics=(data)=> {
    return axios('post','/Composing/saveBasics', data);
}
//获取页面标题数据配置
export const getBasics=(params)=> {
    // return axios('get','/Composing/getBasics'+'?'+qs.stringify=(params)=>)
  return axios('get','/Composing/getBasics', {
      params
    })
}
//获取轮播数据配置
export const getCarouselInfo=(params)=> {
    return axios('get','/Composing/getCarouselInfo',{
      params
    })
}
//轮播数据处理
export const saveCarousel=(data)=> {
    return axios('post','/Composing/saveCarousel', data);
}
//获取icon数据配置
export const getIcon=(params)=> {
    return axios('get','/Composing/getIcon', {
        params
      })
}
//icon数据处理
export const saveIcon=(data)=> {
    return axios('post','/Composing/saveIcon', data);
}
//获取单商品数据配置
export const getOnlyGoods=(params)=> {
    return axios('get','/Composing/getOnlyGoods', {
        params
      })
}
//单商品数据处理
export const onlyGoods=(data)=> {
    return axios('post','/Composing/onlyGoods', data);
}
//获取版位专场数据配置
export const getSpeciallist=(params)=> {
    return axios('get','/Composing/getSpeciallist', {
        params
      })
}
//版位专场绑定处理
export const specialActivities=(data)=> {
    return axios('post','/Composing/specialActivities', data);
}
//专场列表下拉获取
export const getspecialActivitiesList=(params)=> {
    return axios('get','/Composing/getspecialActivitiesList', {
        params
      })
}
// 获取icon链接列表
export const getPageLink=(params)=> {
    return axios('get','/WebpageConfiguration/getPageLinkAll', {
        params
      })
}