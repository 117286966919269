import axios from '@/config/httpConfig'
//数据看榜概览
export const viewCenter=(params)=> {
    return axios('get','Account/shop/data/viewCenter', params)
}
//合伙人列表
export const accountShopList=(data)=> {
    return axios('post','/Account/platform/accountShopList', data);
}
//合伙人等级
export const accountLevel=(params)=> {
    return axios('get','/Account/platform/accountLevel', params)
}
//合伙人详情-用户信息
export const viewUserInfo=(params)=> {
    return axios('get','/Account/shop/data/viewUserInfo', params)
}
//合伙人详情-数据看榜概览
export const viewLevel=(params)=> {
    return axios('get','/Account/shop/data/view', params)
}
//合伙人详情-数据看榜概览2
export const myView=(params)=> {
    return axios('get','/Account/shop/data/myView', params)
}
//我的粉丝-会员
export const teamfans=(params)=> {
    return axios('get','/Account/shop/data/team', params)
}
//修改用户等级
export const modifyLevel=(data)=> {
    return axios('post','/Account/shop/data/modifyLevel', data);
}
//奖金池订单
export const getStockList=(params)=> {
    return axios('get','/Commission/platform/getStockList', params);
}
//奖金池订单-修改金额
export const editPoolMoney=(data)=> {
    return axios('post','Commission/platform/editPoolMoney', data);
}
//资料包列表
export const pdfList=(params)=> {
    return axios('get','/Oss/platform/pdfList', params);
}
//多文件上传
export const fileUploading=(data)=> {
    return axios('post','/Oss/platform/fileUploading', data);
}
//删除图片
export const imagesNameDel=(data)=> {
    return axios('post','/Oss/platform/imagesNameDel', data);
}
//分红结算
export const platConfigDetail=(params)=> {
    return axios('get','Commission/platform/platConfigDetail', params);
}
export const editPlatConfigPeriod=(data)=> {
    return axios('post','Commission/platform/editPlatConfigPeriod', data);
}
//合伙人权益详情
export const accountEquityInfo=(params)=> {
    return axios('get','/Account/platform/accountEquityInfo', params);
}
export const EditAccountEquity=(data)=> {
    return axios('post','/Account/platform/EditAccountEquity', data);
}

