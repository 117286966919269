<template>
	<div>
		<OrderSupplyDetailModule :order_type="order_type"></OrderSupplyDetailModule>
	</div>
</template>

<script>
	import OrderSupplyDetailModule from "../../../../../pagecomponents/orderSupplyDetailModule.vue"
	export default{
		components:{
			OrderSupplyDetailModule
		},
		data(){
			return{
				order_type:''
			}
		},
		mounted() {
			this.order_type = this.$route.query.type
		},
	}
</script>

<style scoped lang="scss">

</style>
