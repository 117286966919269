<template>
	<div>
		<div class="wrapper-main">
			<GoodAddModule :activeName="activeName"></GoodAddModule>
		</div>
	</div>
</template>
<script>
	import GoodAddModule from '../../../../../pagecomponents/goodAddModule.vue'
	export default {
		components: {
			GoodAddModule
		},
		data() {
			return {
				activeName:this.$route.query.activeName
			}
		},
		methods: {}

	}
</script>

<style lang="scss" scoped="scoped">

</style>
