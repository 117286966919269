<template>
    <div>
        <el-form ref="allFormRef" :model="allFromData" label-width="150px" class="demo-ruleForm" status-icon>
            <div class="wrapper-cont pd20">
                <div class="line-title mgb20">全局配置</div>
                <el-form-item label="推广合伙人">
                    <el-input v-model="num1" size="small" disabled class="input-with-select inputsWidth">
                        <template #prepend>
                            <span>≤</span>
                        </template>
                        <template #append>
                            人
                        </template>
                    </el-input>
                    <div></div>
                    <span class="spanstyle mr5">直推第一个A，获得</span>
                    <el-input v-model="allFromData.direct_percent_A" :min="0" :max="100"
                        oninput="value=value.replace(/[^\d]/g,'');if(value>100)value='';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
                        size="small" class="w150 mr10" style="vertical-align:middle;">
                        <template slot="append">%</template>
                    </el-input>
                    <span class="spanstyle ml5">奖励；直推第二个B，获得</span>
                    <el-input v-model="allFromData.direct_percent_B" :min="0" :max="100"
                        oninput="value=value.replace(/[^\d]/g,'');if(value>100)value='';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
                        size="small" class="w150 mr10" style="vertical-align:middle;">
                        <template slot="append">%</template>
                    </el-input><span class="spanstyle">奖励</span>
                </el-form-item>
                <el-form-item label="推广合伙人">
                    <el-input v-model="num2" size="small" disabled class="input-with-select inputsWidth">
                        <template #prepend>
                            <span>></span>
                        </template>
                        <template #append>
                            人
                        </template>
                    </el-input>
                    <div></div>
                    <span class="spanstyle mr5">直推奖获得</span>
                    <el-input v-model="allFromData.direct_percent_C" :min="0" :max="100"
                        oninput="value=value.replace(/[^\d]/g,'');if(value>100)value='';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
                        size="small" class="w150 mr10" style="vertical-align:middle;">
                        <template slot="append">%</template>
                    </el-input><span class="spanstyle">奖励</span>
                </el-form-item>
            </div>
            <div class="wrapper-cont pd20 mt10">
                <div class="line-title mgb20">全局配置</div>
                <el-form-item label="加权分红奖获得">
                    <el-input v-model="allFromData.stock_percent" :min="0" :max="100"
                        oninput="value=value.replace(/[^\d]/g,'');if(value>100)value='';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
                        size="small" class="w150 mr10" style="vertical-align:middle;">
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="save-cont">
            <div class="cancel-btn" @click="goback">取消</div>
            <div class="save-btn" @click="allSubmit()">保存</div>
        </div>
    </div>
</template>
<script>
import { editPlatConfig } from '@/api/plat/platprofit'//编辑全局配置
export default {
    props: ['allFromData'],
    data() {
        return {
            allFormRef: null,
            num1: '2',
            num2: '2',
        }
    },
    methods: {
        goback() {
            this.$emit('toback', true)
        },
        allSubmit() {
            let data = JSON.parse(JSON.stringify(this.allFromData))
            editPlatConfig(data).then(() => {
                this.$message({
                    message: '全局配置设置成功',
                    type: 'success'
                });

            })
        }


    }
}
</script>
<style lang="scss" scoped="scoped">
.redstyle {
    font-size: 14px;
    color: #FE527F;
    line-height: 22px;
}
</style>