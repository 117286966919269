import axios from '@/config/httpConfig'
import qs from  'qs'

//分配服务商列表
export const getAd=(params)=> {
    return axios('get','api/Twiker/home/getAds', params);
}
export const insert_ad=(data)=> {
    return axios('post','api/Twiker/home/insert_ad', data);
}
//获取广告
export const getAdvertising=(data)=> {
    return axios('post','/Account/platform/getAdvertising', data);
}
//修改添加广告
export const saveAdvertising=(data)=> {
    return axios('post','/Account/platform/saveAdvertising', data);
}
//删除广告
export const delAdvertising=(data)=> {
    return axios('post','/Account/platform/delAdvertising', data);
}
//获取广告分类
export const getidentifying=(params)=> {
    return axios('get','/Account/platform/getidentifying', params)
}
//下拉框列表
export const dropDown=(params)=> {
	  return axios('get','/College/platform/dropDown', params)
}
//添加广告轮播图片
export const addAdvertisingImg=(params)=> {
    return axios('get','/College/platform/addAdvertisingImg', params)
}