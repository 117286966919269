<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<el-form :model="form" ref="form" :rules="rules" label-width="150px" class="demo-ruleForm">
					<div class="wrapper-cont" style="padding: 0!important;">
						<div class="formsty">
							<el-row :gutter="20">
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<div class="setTitle">创业帮扶申请信息</div>
										<el-form-item label="姓名:" prop="username">
											<el-input size="small" placeholder="输入姓名" class="inputWidth"
												v-model="form.username"></el-input>
										</el-form-item>
										<el-form-item label="手机号:" prop="account">
											<el-input size="small" placeholder="输入手机号" maxlength="11"
												class="tele_phone inputWidth" v-model="form.account"></el-input>
										</el-form-item>
										<el-form-item label="身份证" prop="identity_code">
											<el-input size="small" maxlength="18" v-model="form.identity_code"
												placeholder="输入身份证" class="tele_phone inputWidth">
											</el-input>
										</el-form-item>
										<el-form-item
											:label="form.application_type==1?'学生':form.application_type==2?'军人':'残疾人'"
											prop="additional_proof">
											<div class="certificates clearfix">
												<div class="img-uploader" v-for="(item,index) in form.additional_proof" :key="index">
													<img  :src="item"
														class="avatar" />
													<!-- <i v-else class="el-icon-plus"></i> -->
												</div>
											</div>
											
										</el-form-item>
										<el-form-item label="负责人身份反面:" prop="identity_card_front">
											<div class="img-uploader">
												<img v-if="form.identity_card_front" :src="form.identity_card_front"
													class="avatar" />
												<i v-else class="el-icon-plus"></i>
											</div>
										</el-form-item>
										<el-form-item label="负责人身份正面:" prop="identity_card_reverse">
											<div class="img-uploader">
												<img v-if="form.identity_card_reverse" :src="form.identity_card_reverse"
													class="avatar" />
												<i v-else class="el-icon-plus"></i>
											</div>
										</el-form-item>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<div class="setTitle">帮扶所属网店主信息</div>
										<el-form-item label="店主姓名:" prop="shop_name">
											<el-input size="small" placeholder="输入店主姓名" class="inputWidth"
												v-model="form.shop_name"></el-input>
										</el-form-item>
										<el-form-item label="联系电话:" prop="shop_phone">
											<el-input size="small" placeholder="输入联系电话" maxlength="11"
												class="tele_phone inputWidth" v-model="form.shop_phone"></el-input>
										</el-form-item>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</el-form>
			</div>
		</div>
		<div class="save-cont">
			<div class="cancel-btn" @click="toBack">返回</div>
			<div class="cancel-btn" v-if="form.status && form.status == '1'" @click="toRefuse">拒绝</div>
			<div class="save-btn" v-if="form.status && form.status == '1'" @click="submitSet()">通过</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="true" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
		<!--拒绝原因弹框-->
		<el-dialog title="拒绝原因" :visible.sync="dialogVisible" width="25%">
			<div>
				<el-input type="textarea" placeholder="请输入内容" v-model="reason_refusal" rows="4" maxlength="50"
					show-word-limit>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="toSubmitCheck()" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getBusinessSupportAudit,
		auditBusinessSupportAudit
	} from "@/api/plat/business.js"
	import ChooseImg from '../../../../../common/uploadImgs.vue'
	import {
		isvalidPhone
	} from '@/utils/validate'
	import idNumber from '@/utils/idNumber'
	const rulesIdNumber = (rule, value, callback) => {
		if (idNumber.cnid(value) === false) {
			callback('身份证信息错误')
		} else {
			callback()
		}
	};
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入电话号码'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		components: {
			ChooseImg
		},
		data() {
			return {
				form: {
					username: '',
					account: '',
					identity_code: '',
					additional_proof: [],
					identity_card_front: '',
					identity_card_reverse: '',
					shop_name: '',
					shop_phone: '',
					application_type: ''
				},
				rules: {
					username: [{
						required: true,
						message: '请填写姓名',
						trigger: 'blur'
					}],
					account: [{
							required: true,
							trigger: 'blur',
							validator: validPhone
						}, //这里需要用到全局变量
					],
					identity_code: [{
							required: true,
							message: '身份证不能为空',
							trigger: 'blur'
						},
						{
							validator: rulesIdNumber,
							trigger: 'blur'
						}
					],
					additional_proof: [{
						required: true,
						message: '请选择证件',
						trigger: 'change'
					}],
					identity_card_front: [{
						required: true,
						message: '请选择身份证反面',
						trigger: 'change'
					}],
					identity_card_reverse: [{
						required: true,
						message: '请选择身份证正面',
						trigger: 'change'
					}],
					shop_name: [{
						required: true,
						message: '请填写姓名',
						trigger: 'blur'
					}],
					shop_phone: [{
							required: true,
							trigger: 'blur',
							validator: validPhone
						}, //这里需要用到全局变量
					],

				},
				uploadFlag: false,
				typeImg: '',
				dialogVisible: false,
				reason_refusal: '',
				entrepreneurship_support_id: this.$route.query.id
			}
		},
		mounted() {
			if (this.entrepreneurship_support_id) {
				this.getInfo();
			}
		},
		methods: {
			getInfo() {
				let data = {
					entrepreneurship_support_id: this.entrepreneurship_support_id
				}
				getBusinessSupportAudit(data).then(res => {
					this.form = res.data;
					this.form.additional_proof=this.form.additional_proof.split(",");
				})
			},
			//证件
			documentType(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			//身份证
			identitycardfront(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			identitycardback(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				if (this.typeImg == 'type') {
					this.form.additional_proof = val[0].resource_name;
				}
				if (this.typeImg == 'cardfront') {
					this.form.identity_card_front = val[0].resource_name;
				}
				if (this.typeImg == 'cardback') {
					this.form.identity_card_reverse = val[0].resource_name;
				}
				this.uploadFlag = false;
			},
			//审核拒绝
			toRefuse() {
				this.reason_refusal = '';
				this.dialogVisible = true;
			},
			toSubmitCheck() {
				if (this.reason_refusal == '') {
					this.$message.warning("请填写拒绝原因");
					return;
				}
				//调接口
				let data = {
					entrepreneurship_support_id: this.entrepreneurship_support_id,
					status: 3,
					reason_refusal:this.reason_refusal
				}
				data = this.tools.filterParams(data);
				auditBusinessSupportAudit(data).then(res => {
					this.$message.success("提交成功");
					this.$router.push({
						path: "/clientadmin/assistanceaudit/assistanceauditlist",
					})
				})
			},
			//返回
			toBack(){
				this.$router.push({
					path: "/clientadmin/assistanceaudit/assistanceauditlist",
				})
			},
			//审核通过
			submitSet() {
				let data = {
					entrepreneurship_support_id: this.entrepreneurship_support_id,
					status: 2,
				}
				data = this.tools.filterParams(data);
				auditBusinessSupportAudit(data).then(res => {
					this.$message.success("审核通过");
					this.$router.push({
						path: "/clientadmin/assistanceaudit/assistanceauditlist",
					})
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.formsty {
		padding: 30px;
		background-color: #fff;
		border-radius: 2px;

	}
	.certificates{
		.img-uploader{
			float: left;
			margin-right: 10px;
			margin-bottom: 10px;
			
		}
		
	}
</style>
