<template>
	<div>
		<div class="wrapper-main pds">
			<div class="wrapper-cont">
				<div class="pmargin10">
					<div class="setTitle">基础信息</div>
					<div class="formBox">
						<el-form :model="form" ref="form" :rules="rules" label-width="150px" class="demo-ruleForm">
							<el-row :gutter="20">
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-form-item label="客户名称:" prop="name">
											<el-input type="text" placeholder="请输入客户名称" v-model="form.name"
												class="inputWidth" size="small">
											</el-input>
										</el-form-item>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-form-item label="客户来源:" prop="source">
											<el-select v-model="form.source" size="small" class="inputWidth"
												placeholder="请选择客户来源">
												<el-option v-for="item in customType" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-form-item label="联系电话:" prop="phone">
											<el-input type="text" class="inputWidth" placeholder="请输入联系电话" size="small"
												v-model.number="form.phone" maxlength="11"></el-input>
										</el-form-item>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-form-item label="是否加急:" prop="isUrgent">
											<el-select v-model="form.isUrgent" size="small" class="inputWidth"
												placeholder="是否加急">
												<el-option v-for="item in urgent" :key="item.value" :label="item.label"
													:value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-form-item label="门店类型:" prop="shoptype">
											<el-select v-model="form.shoptype" size="small" class="inputWidth"
												placeholder="请选择门店类型">
												<el-option v-for="item in entity" :key="item.value" :label="item.label"
													:value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
								</el-col>
							</el-row>
							<el-form-item label="行业类目:" prop="industrytype">
								<el-select v-model="form.industrytype" size="small" class="inputWidth"
									placeholder="请选择门店类型">
									<el-option v-for="item in industry" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="跟进内容:" prop="followContent">
								<el-input type="textarea" v-model="form.followContent" class="inputWidth"></el-input>
							</el-form-item>
							<el-form-item label="通信地址:" prop="address">
								<el-cascader size="small" placeholder="地区" style="width:300px;" :options="options"
									collapse-tags :props="{multiple: false }" clearable @change="handleChange"
									v-model="form.address" ref="cascader">
								</el-cascader>
							</el-form-item>
							<el-form-item label="地图点位:" prop="location">
								<div class="mapsure">请在地图中确认地址信息</div>
								<div class="relation" style="margin-left: 0;">
									<div class="absolute">
										<el-input placeholder="请搜索地址" prefix-icon="el-icon-search"
											v-model="form.location" @input="inputAddress" style="width: 250px;"
											size="small">
										</el-input>
									</div>
									<div id="container" style="width:500px;height:350px;"></div>
								</div>
							</el-form-item>
							<el-form-item label="详细地址:">
								<el-input type="text" placeholder="请输入详细地址" v-model="form.addressDetail"
									@input="setAddressDetail" class="inputWidth" size="small">
								</el-input>
							</el-form-item>
							<el-form-item label="备注:">
								<el-input type="textarea" v-model="form.mark" class="inputWidth"></el-input>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<div class="save-cont">
			<div class="cancel-btn" @click="toBack">返回上一页</div>
			<div class="save-btn" @click="submitSet('form')">保存</div>
		</div>
	</div>
</template>

<script>
	var searchService, geocoder, map, markersArray = [];
	import {
		TMap
	} from "@/utils/TMap.js";
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	import {
		getShopTradeList,
		getBusinessIncubatorInfo,
		createOrUpBusinessIncubator
	} from "@/api/channel/business"
	import {
		isvalidPhone
	} from '@/utils/validate'
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入电话号码'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		data() {
			return {
				id: 0,
				form: {
					name: '',
					source: '',
					// contacts:'',
					phone: '',
					isUrgent: '',
					shoptype: '',
					industrytype: '',
					followContent: '',
					address: [],
					addressDetail: '',
					mark: '',
					location: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					source: [{
						required: true,
						message: '请选择客户来源',
						trigger: 'blur'
					}],
					phone: [{
							required: true,
							trigger: 'blur',
							validator: validPhone
						}, //这里需要用到全局变量
					],
					isUrgent: [{
						required: true,
						message: '是否加急',
						trigger: 'blur'
					}],
					shoptype: [{
						required: true,
						message: '请选择门店类型',
						trigger: 'blur'
					}],
					industrytype: [{
						required: true,
						message: '请选择行业类目',
						trigger: 'blur'
					}],
					followContent: [{
						required: true,
						message: '请输入跟进内容',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请选择通信地址',
						trigger: 'blur'
					}],
					location: [{
						required: true,
						message: '请确认地址信息',
						trigger: 'blur'
					}]
				},
				options: regionData,
				longitude: 30.18534, //经度
				latitude: 120.26457, //纬度
				searchService: null,
				geocoder: null,
				map: null,
				marker: null,
				mapAddress: '浙江省,杭州市,萧山区,博地中心',
				province: '',
				city: '',
				county: '',
				customType: [{
						label: '平台创建',
						value: 1
					},
					{
						label: '网上注册',
						value: 2
					},
					{
						label: '服务商创建',
						value: 3
					}
				],
				entity: [{
						label: '实体门店',
						value: 2
					},
					{
						label: '线上网店',
						value: 1
					},
				],
				urgent: [{
						label: '不加急',
						value: 1
					},
					{
						label: '加急',
						value: 2
					},
				],
				industry: []
			}
		},
		mounted() {
			/** 行业列表 */
			getShopTradeList().then(res => {
				res.data.forEach(item => {
					this.industry.push({
						label: item.trade_name,
						value: item.marketing_trade_id
					})
				})
			})
			if (this.$route.query.id) {
				this.id = this.$route.query.id
				this.initData(this.id)
			} else {
				this.codeAddress()
			}
		},
		methods: {
			initData(id) {
				getBusinessIncubatorInfo({
					business_incubator_id: id
				}).then(res => {
					this.form.name = res.data.username
					this.form.source = res.data.customer_source
					this.form.phone = Number(res.data.phone)
					this.form.isUrgent = res.data.is_urgent
					this.form.shoptype = res.data.is_entity
					this.form.industrytype = res.data.marketing_trade_id
					this.form.followContent = res.data.follow_remark
					this.form.address = [res.data.shop_province_code, res.data.shop_city_code, res.data
						.shop_county_code
					];
					this.form.addressDetail = res.data.shop_address_detail
					this.form.mark = res.data.remark
					this.province = res.data.shop_province_code
					this.city = res.data.shop_city_code
					this.county = res.data.shop_county_code
					this.longitude = res.data.longitude
					this.latitude = res.data.latitude
					this.form.location = this.mapAddress = res.data.shop_province + res.data.shop_city + res.data
						.shop_county + res.data.shop_address_detail
					if (this.province && this.city && this.county) {
						this.codeAddress()
					}
				})
			},
			//地址
			handleChange(area) {
				if (area.length > 0) {
					this.province = area[0];
					this.city = area[1];
					this.county = area[2];
					this.form.location = this.mapAddress = CodeToText[area[0]] + CodeToText[area[1]] + CodeToText[area[2]]
					this.codeAddress()
				} else {
					this.province = this.city = this.county = this.form.location = this.mapAddress = this.longitude = this
						.latitude = ''
				}
			},
			//输入调地图
			inputAddress() {
				this.mapAddress = this.form.location;
				this.codeAddress();
			},
			codeAddress() {
				let lng, lat;
				let geocoder = new qq.maps.Geocoder();
				//对指定地址进行解析
				var result = geocoder.getLocation(this.mapAddress);
				//设置服务请求成功的回调函数
				geocoder.setComplete(result => {
					this.longitude = result.detail.location.lng;
					this.latitude = result.detail.location.lat;
					this.setMap()

				});
				//若服务请求失败，则运行以下函数
				geocoder.setError(function() {
					alert("出错了，请输入正确的地址！！！");
				});
			},
			setMap() {
				var myLatlng = new qq.maps.LatLng(this.latitude, this.longitude);
				var myOptions = {
					zoom: 8,
					center: myLatlng,
					mapTypeId: qq.maps.MapTypeId.ROADMAP
				}
				var map = new qq.maps.Map(document.getElementById("container"), myOptions);
				var marker = new qq.maps.Marker({
					position: myLatlng,
					map: map
				});
			},
			/** 详细地址 */
			setAddressDetail(value) {
				if (this.province && this.city && this.county) {
					this.form.location = this.mapAddress = CodeToText[this.province] + CodeToText[this.city] + CodeToText[
						this.county] + value
					this.codeAddress()
				} else {
					this.form.location = this.mapAddress = this.longitude = this.latitude = value
				}
			},
			//返回上一步
			toBack() {
				// 返回列表页
				this.$router.go(-1);
			},
			//保存提交
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						let params = {
							business_incubator_id: this.id,
							username: this.form.name,
							phone: this.form.phone,
							is_entity: this.form.shoptype,
							marketing_trade_id: this.form.industrytype,
							shop_province: this.province ? CodeToText[this.province] : '',
							shop_province_code: this.province,
							shop_city: this.city ? CodeToText[this.city] : '',
							shop_city_code: this.city,
							shop_county: this.county ? CodeToText[this.county] : '',
							shop_county_code: this.county,
							shop_address_detail: this.form.addressDetail,
							longitude: this.longitude,
							latitude: this.latitude,
							is_urgent: this.form.isUrgent,
							customer_source: this.form.source,
							follow_remark: this.form.followContent,
							remark: this.form.mark,
						}
						params = this.tools.filterParams(params)
						createOrUpBusinessIncubator(params).then(res => {
							if (res.return_code == 'SUCCESS') {
								let message = this.id ? '修改成功' : '添加成功'
								this.$message({
									message: message,
									type: 'success',
								});
								this.$router.go(-1);
							} else {
								this.$message.error(res.return_msg)
							}
						})
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	// .formBox::v-deep.el-form-item {
	// 	margin-bottom: 10px;
	// }

	.mapsure {
		font-size: 14px;
		color: #999999;
	}
	.pds{
		padding-bottom: 70px;
	}
</style>
