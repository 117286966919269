<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <el-button size="small" type="primary" @click="toAdd">添加奖金</el-button>

                    </div>
                    <div class="datas"><span>奖金池金额：</span>¥{{ commission_pool_stock_money }}</div>
                    <div class="datas"><span>总进账：</span>¥{{ stock_sum }}<span class="ml10">总出账：</span>¥{{ stock_log_sum }}
                    </div>
                </el-card>
                <el-table :data="tableData" border style="width: 100%" v-loading="loading"
                    element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.2)" max-height="550px" class="mt10"
                    :header-cell-style="{ background: '#f3f8fe', color: '#606060' }">
                    <el-table-column label="周期" prop="stock_count" align="center"></el-table-column>
                    <el-table-column label="结算周期" prop="plat_commission_period" align="center"></el-table-column>
                    <el-table-column label="结算时间" prop="created_at" align="center"></el-table-column>
                    <el-table-column label="奖金池情况" prop="money" align="center"></el-table-column>
                </el-table>
                <!-- 分页 -->
                <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]"
                    :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="新增/减少奖金池" :visible.sync="dialogVisible" width="25%" >
            <div class="common-btn" style="text-align:center" >
                <el-button plain size="small" :class="{ 'btn_active': type === 1 }" @click="type = 1">增加
                </el-button>
                <el-button plain size="small" :class="{ 'btn_active': type === 2}" @click="type = 2">减少
                </el-button>
               
            </div>
            <div class="mt10">
                 <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item :label="type === 1?'添加':'减少'" prop="commission_pool_stock_money" :rules="[
                    { required: true, message: '不能为空' },
                    { type: 'number', message: '必须为数字值' }
                ]">

                    <el-input type="age" size="small" v-model.number="ruleForm.commission_pool_stock_money" autocomplete="off"></el-input>
                </el-form-item>
                
            </el-form>
            </div>
           
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getStockList,editPoolMoney} from '@/api/plat/partner'
export default {
    data() {
        return {
            tableData: [],
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
            loading: true,
            dialogVisible: false,
            ruleForm: {
                commission_pool_stock_money: '',
            },
            commission_pool_stock_money: '',
            stock_log_sum: '',
            stock_sum: '',
            type: 1,
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            let data = {
                page: this.currentPage,
                perPage: this.pagesize,
            }
            getStockList(data).then(res => {
                this.loading = false;
                this.commission_pool_stock_money = res.data.commission_pool_stock_money;
                this.stock_log_sum = res.data.stock_log_sum;
                this.stock_sum = res.data.stock_sum;
                this.tableData = res.data.list;
                this.total = res.data.count
            })

        },
        handleSizeChange(size) {
            this.pagesize = size;
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getList()
        },
        toAdd() {
            this.dialogVisible = true;
            this.$nextTick(()=>{
					this.$refs['ruleForm'].clearValidate();
				});
            this.ruleForm.commission_pool_stock_money=''
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data={
                        type:this.type,
                        commission_pool_stock_money:this.ruleForm.commission_pool_stock_money
                    }
                    editPoolMoney(data).then(()=>{
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                            });
                        this.dialogVisible = false;
                         this.getList()

                    })
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style lang="less" scoped>
.datas {
    line-height: 45px;

    span {
        color: #666
    }
}
.common-btn {
    // float: left;
    button {
        display: inline-block;
        width: 70px;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background: #FFF;
        border: 1px solid #DCDFE6;
        color: #606266;
        outline: 0;
        padding: 7px 8px;
        font-size: 14px;
        border-radius: 4px;
        margin-right: 10px;
    }

    .btn_active {
        background: #409eff;
        border-color: #b3d8ff;
        color: #fff;
    }
}
</style>