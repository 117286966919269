<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont">
					<div class="twitter-cont">
						<div class="twitterpic">
							<img :src="detailInfo.backstage_image"/>
						</div>
						<div class="twitter-right">
							<div class="title">{{detailInfo.title}}</div>
							<div class="sub-title">{{detailInfo.description}}</div>
							<div class="price"><span>价格：</span>¥{{sale_price}}</div>
							<div class="edition" v-if="detailInfo.is_free=='2'">
								<el-row>
									<span>版本</span>
									<ul class="buttons">
										<li class="actived"  @click="selected(item)" v-for="item in detailInfo.sale_commercial" :key="item.time_cycle"  :class="{active : active == item.time_cycle}" >{{item.time_cycle}}</li>
									</ul>
								</el-row>
							</div>
							<div class="tobuy" @click.once="toPayBtn">立即订购</div>
						</div>
					</div>
					
				</div>
				<div class="main-cont mt10">
					<div class="details">
						<div class="title">应用详情</div>
						<div class="detail-pic">
							<div v-html="detailInfo.details"></div>
							<!-- <img src="../../../../../../assets/img/merchant/twitter/twitterdetail.png" /> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--支付扫码-->
		<el-dialog title="扫码" :visible.sync="dialogQRcodeVisible" width="25%">
			<div class="qr_code_pay" style="display: flex;justify-content: center;align-items: center;">
				<div v-if="payTypes==1">
					<img v-if="recharge_qrCode" :src="recharge_qrCode" width="180" height="180" style="overflow:hidden;" />
				</div>
				<div v-if="payTypes==2">
					<iframe :srcdoc="recharge_qrCode" frameborder="no" border="0" marginwidth="0"
						marginheight="0" scrolling="no" width="95" height="95" style="overflow:hidden;">
					</iframe>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitPay" size="small">确认支付完成</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {TwitterDetails,rechargeAmount} from '@/api/merchant/marketing.js'
	import {getVirtualOrderStatus } from '@/api/merchant/finance.js'
	export default{
		data(){
			return{
				active:'一年',
				plugin_id:'',
				detailInfo:{},
				sale_price:'',
				dialogQRcodeVisible:false,
				payState:{},
				payTypes:1,
				recharge_qrCode: '',
				order_nuber:''
			}
		},
		mounted() {
			this.plugin_id=this.$route.query.plugin_id;
			if(this.plugin_id){
				this.getTwitterDetails()
			}
			
		},
		methods:{
			getTwitterDetails(){
				TwitterDetails({plugin_id:this.plugin_id}).then(res=>{
					 this.detailInfo=res.data;
					 if(this.detailInfo.is_free=='1'){
						 this.sale_price='免费'
					 }else{
						 this.active=this.detailInfo.sale_commercial[0].time_cycle;
						 this.sale_price=this.detailInfo.sale_commercial[0].sale_price;
					 }
					
				})
			},
			selected(item){
			   this.active = item.time_cycle;
			   this.sale_price=item.sale_price
			},
			//立即购买
			toPayBtn(){
				let data={
					order_type:2,
					commodity_id:this.plugin_id,
					payType:1,
					money:this.sale_price=='免费'?'0':this.sale_price,
					indate_time:this.active
				}
				rechargeAmount(data).then(res=>{
						this.payState=res.data;
						if(this.payState.money==0){
							this.$message.success("购买成功！");
							this.$router.push({
								path: "/marketingcenteradmin/marketingcenter/marketingcenteritem",
							});
						}else if(this.payState.money>0){
							this.payTypes = res.data.payType;
							this.recharge_qrCode = res.data.images;
							this.order_nuber=res.data.order_nuber;
							this.dialogQRcodeVisible=!this.dialogQRcodeVisible
						}
				})
			},
			//确认支付
			submitPay() {
				let params = {
					order_nuber: this.order_nuber
				}
				getVirtualOrderStatus(params).then(res => {
					if (res.data == 1) {
						this.$message.error("请支付！")
					} else if (res.data == 2) {
						this.$message.success("支付成功！");
						this.dialogRechargeVisible = !this.dialogRechargeVisible;
						this.$router.push({
							path: "/marketingcenteradmin/marketingcenter/marketingcenteritem",
						});
					} else if (res.data == 4) {
						this.$message.error("支付失败！")
						this.dialogRechargeVisible = !this.dialogRechargeVisible;
					}
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.twitter-cont{
		// margin-top: 10px;
		overflow: hidden;
		.twitterpic{
			width: 100px;
			height: 100px;
			float: left;
		}
		.twitter-right{
			margin-left: 20px;
			margin-top: 6px;
			.title{
				color: #333;
				line-height: 25px;
				font-size: 18px;
			}
			.sub-title{
				margin-top: 3px;
				height: 20px;
				font-size: 14px;
				color: #888;
				line-height: 20px;
			}
			.price{
				height: 25px;
				font-size: 18px;
				margin-top: 3px;
				font-weight: 400;
				color: #E02020;
				line-height: 25px;
				span{
					height: 20px;
					font-size: 14px;
					font-weight: 400;
					color: #888888;
					line-height: 20px;
				}
			}
			.edition{
				overflow: hidden;
				margin-top: 13px;
				span{
					height: 20px;
					font-size: 14px;
					font-weight: 400;
					color: #888888;
					line-height: 28px;
					float: left;
				}
				.buttons{
					margin-left: 10px;
					float: left;
					>li{
						width: 90px;
						height: 28px;
						background: #FFFFFF;
						border-radius: 2px;
						border: 1px solid #CCCCCC;
						list-style: none;
						float: left;
						text-align: center;
						line-height: 28px;
						margin-right: 20px;
						border-radius: 2px;
						color: #999;
						font-size: 14px;
						cursor: pointer;
					}
					>li.active{
						border: 1px solid #0091FF;
						border-radius: 2px;
						color: #0091FF;
					}
				}
				
			}
			.tobuy{
				margin-left: 118px;
				width: 90px;
				margin-top: 20px;
				height: 28px;
				line-height: 28px;
				cursor: pointer;
				text-align: center;
				background: #4185F4;
				border-radius: 2px;
				border: 1px solid #0091FF;
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
			}
		}
	}
	.details{
		padding: 24px 30px;
		.title{
			height: 20px;
			font-size: 14px;
			font-weight: 500;
			color: #333333;
			line-height: 20px;
		}
		.detail-pic{
			padding: 30px 20px;
		}
	}
	
</style>
