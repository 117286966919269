<template>
	<div>
		<div class="wrapper-cont" style="padding: 0;">
			<div class="shopmain">
				<!--头部-->
				<div class="wrapper-main pp20">
					<el-row type="flex" class="row-bg" justify="space-between">
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div class="flex">
									<img :src="head_portrait" class="head_portrait" />
									<div class="shop_title">牛店-淘货市场</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" style="text-align: right;">
							<div class="grid-content bg-purple">
								<el-input placeholder="请输入内容" size="small" class="search_input"
									suffix-icon="el-icon-search" v-model="shop_search"  @keyup.enter.native="searchGood" clearable>
									<template slot="append" class="searchbtns"> <span
											@click="searchGood">搜索</span></template>
								</el-input>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="top_det">
					<div class="det_title">图片</div>
					<div id="ol_boxs" class="detail_cons">
						<template v-if="imgList">
							<div v-for="(img,index) in imgList" :key="index">
								<img :src="img" />
							</div>
				
						</template>
						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				head_portrait: localStorage.getItem("head_portrait"),
				shop_search: '',
				imgList:[]
			}
		},
		mounted() {
			console.log("1342423")
			this.imgList=localStorage.getItem("pc_click_img").split(",");
			
		},
		methods:{
			//搜索
			searchGood() {
				this.$router.push({
					path: '/shoppingadmin/moreclassify',
					query: {
						keyword: this.shop_search
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../../../assets/css/shopping/shopping.scss";
	@import "../../../../../assets/css/shopping/details.scss";
</style>
