<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
					<div class="order-set">
						<el-form-item label="接口域名:" prop="APP_URL">
							<el-input type="text" v-model="ruleForm.APP_URL" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="令牌有效时间:">
							<el-input size="small" class="shippedInput" v-model="ruleForm.TOKEN_EXPIRATION_TIME" :min="1" :onkeyup="(ruleForm.TOKEN_EXPIRATION_TIME = ruleForm.TOKEN_EXPIRATION_TIME.replace(/\D|^-/g,''))"
							 type="number" />
						</el-form-item>
						<el-form-item label="刷新令牌有效时间:">
							<el-input size="small" class="shippedInput" v-model="ruleForm.REFRESH_EXPIRATIONTIME" :min="1" :onkeyup="(ruleForm.REFRESH_EXPIRATIONTIME = ruleForm.REFRESH_EXPIRATIONTIME.replace(/\D|^-/g,''))"
							 type="number" />
						</el-form-item>
						<el-form-item label="数据库类型:">
							<el-input type="text" v-model="ruleForm.DB_CONNECTION" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="数据库地址:">
							<el-input type="text" v-model="ruleForm.DB_HOST" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="数据库端口:">
							<el-input type="text" v-model="ruleForm.DB_PORT" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="数据库名称:" prop="DB_DATABASE">
							<el-input type="text" v-model="ruleForm.DB_DATABASE" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="数据库账号:" prop="DB_USERNAME">
							<el-input type="text" v-model="ruleForm.DB_USERNAME" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="数据库密码:" prop="DB_PASSWORD">
							<el-input type="password" :show-password="true" v-model="ruleForm.DB_PASSWORD" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="数据库表前缀:">
							<el-input type="text" v-model="ruleForm.DB_PREFIX" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="redis密码:">
							<el-input type="password" :show-password="true" v-model="ruleForm.REDIS_PASSWORD" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="redis使用库:">
							<el-input type="text" v-model="ruleForm.REDIS_DB" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="前端接口调用密钥:" prop="API_KEY">
							<el-input type="text" v-model="ruleForm.API_KEY" size="small" class="shippedInput"></el-input>
						</el-form-item>
						<el-form-item label="后端接口调用密钥:" prop="API_ADMIN_KEY">
							<el-input type="text" v-model="ruleForm.API_ADMIN_KEY" size="small" class="shippedInput"></el-input>
						</el-form-item>
					</div>
				</el-form>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet('ruleForm')">提交</div>
		</div>
	</div>
</template>

<script>
	import {
		getModifyEnv,
		modifyEnv
	} from '@/api/baseSet.js'
	export default {
		data() {
			return {
				ruleForm: {
					APP_URL: '',
					TOKEN_EXPIRATION_TIME: '3600',
					REFRESH_EXPIRATIONTIME: '7200',
					DB_CONNECTION: 'mysql',
					DB_HOST: 'localhost',
					DB_PORT: '3306',
					DB_DATABASE: '',
					DB_USERNAME: '',
					DB_PASSWORD: '',
					DB_PREFIX: 'zhx_',
					REDIS_PASSWORD: '',
					REDIS_DB: '',
					API_KEY: '',
					API_ADMIN_KEY: ''
				},
				rules: {
					APP_URL: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					DB_DATABASE: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					DB_USERNAME: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					REDIS_PASSWORD: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					API_KEY: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					API_ADMIN_KEY: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}]
				}
			}
		},
		mounted() {
			this.getModifyEnvData()
		},
		methods: {
			//获取信息
			getModifyEnvData() {
				let params = {}
				getModifyEnv(params).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.ruleForm = res.data
					}
				})
			},
			//提交
			submitSet() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						//调接口
						let data = this.ruleForm;
						data = this.tools.filterParams(data);
						modifyEnv(data).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.$message({
									message: '更新成功',
									type: 'success'
								});
								this.getALiConfigData();
							}
						})
					} else {
						this.$message.error('请填写完整信息')
						return false;
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.order-set {
		padding: 15px;
		border-radius: 6px;
		// min-height: 300px;
		background-color: #f2f2f6;
		margin-bottom: 20px;
	}

	.shippedInput {
		width: 400px !important;
	}
</style>
