import axios from '@/config/httpConfig'
import qs from  'qs'
//提现数据
export const getChannelWithdrawalData=(params)=> {
  return axios('get','Withdrawal/channel/getChannelWithdrawalData', params)
}
//提现分页
export const getChannelWithdrawalPageList=(params)=> {
  return axios('get','Withdrawal/channel/getChannelWithdrawalPageList', params)
}
//提现申请
export const channelApplyWithdrawal=(data)=> {
  return axios('post','Withdrawal/channel/channelApplyWithdrawal', data)
}
//获取微信扫码二维码
export const getAuthorizationUrl=(params)=> {
	  return axios('get','/Account/channel/getAuthorizationUrl', params)
}
//扫码确认支付
export const WithdrawalDispose=(data)=> {
  return axios('post','Withdrawal/channel/WithdrawalDispose', data);
}
//支付宝授权成功
export const aliPayWithdrawal=(data)=> {
  return axios('post','Withdrawal/channel/aliPayWithdrawal', data);
}
//支付宝授权
export const userLoginAuthorization=(data)=> {
  return axios('post','Thirdparty/channel/userLoginAuthorization', data);
}
