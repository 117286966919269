<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="页面设置" name="first">
						<el-form ref="form" :model="form" label-width="150px" class="demo-ruleForm" :rules="rules">
							<!-- <div class="wrapper-cont">
								<el-form-item label="分享海报:">
									<div class="poster">
										<div class="img_blos">
											<div class="img_blo"  @click="handlePosterImg('share_image')">
												<img :src="form.share_image" class="item_img" v-if="form.share_image" style="margin-right: 0!important;"  />
												<div class="plusImg" v-else>
													<i class="el-icon-plus avatar-uploader-icon"></i>
												</div>
											</div>
										</div>
										<p style="color:#999; font-size: 12px;">海报尺寸建议：580*910</p>
									</div>
									<div class="example">
										<div class="exampleimg">
											<span @mouseenter="mouseover" @mouseleave="mouseleave">示例：</span>
											<img style="pointer-events:none;" v-show="tipIimg"
												src="@/assets/img/merchant/poster.png" />
										</div>
									</div>
								</el-form-item>
							</div> -->
							<div class="wrapper-cont mt10">
								<el-form-item label="商品海报背景图:">
									<div class="img_blos">
										<div class="img_blo" @click="handlePosterImg('goods_image')">
											<img :src="form.goods_image" class="item_img" v-if="form.goods_image" style="margin-right: 0!important;" />
											<div class="plusImg" v-else>
												<i class="el-icon-plus avatar-uploader-icon"></i>
											</div>
										</div>
									</div>
									<p style="color:#999; font-size: 12px; margin-top: 25px;">海报尺寸建议： 540*840像素</p>
								</el-form-item>
							</div>

							<!-- <div class="wrapper-cont mt10">
								<el-form-item label="首页轮播图:">
									<div class="img_blos">
										<div class="img_blo" style="display: inline-flex;">
											<div class="img_blo_duo" v-for="(item,index) in form.home_image" :key="index">
												<img class="item_img" :src="item" />
												<img class="xxx" src="../../../../../assets/img/closeimg.png" @click="delImg(item,index)"/>
											</div>

											<div class="plusImg_banner" @click="handlePosterImg('home_image')">
												<i class="el-icon-plus avatar-uploader-icon"></i>
											</div>
										</div>
									</div>
									<p style="color:#999; font-size: 12px;">轮播图尺寸建议：750*370</p>
								</el-form-item>
							</div> -->
							
							<div class="wrapper-cont mt10">
								<el-form-item label="首页头部背景图:">
									<div class="img_blos">
										<div class="img_blo" @click="handlePosterImg('home_bj')">
											<img :src="form.home_bj" class="item_img" v-if="form.home_bj" style="margin-right: 0!important;" />
											<div class="plusImg" v-else>
												<i class="el-icon-plus avatar-uploader-icon"></i>
											</div>
										</div>
									</div>
									<p style="color:#999; font-size: 12px; margin-top: 25px;">海报尺寸建议：750*464像素</p>
								</el-form-item>
							</div>
							
							<div class="wrapper-cont mt10">
								<el-form-item label="Logo图:">
									<div class="img_blos">
										<div class="img_blo" @click="handlePosterImg('shop_logo')">
											<img :src="form.shop_logo" class="item_img" v-if="form.shop_logo" style="margin-right: 0!important;" />
											<div class="plusImg" v-else>
												<i class="el-icon-plus avatar-uploader-icon"></i>
											</div>
										</div>
									</div>
									<p style="color:#999; font-size: 12px; margin-top: 25px;">海报尺寸建议： 540*840像素</p>
								</el-form-item>
							</div>
						</el-form>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet('form')">提交</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="mulity" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		addSharePoster,
		getPosterInfo,
		editPoster
	} from '@/api/merchant/setting.js'
	import ChooseImg from '../../../../common/uploadImgs.vue'
	export default {
		components: {
			ChooseImg
		},
		data() {
			return {
				activeName: 'first',
				mulity: false, //单张上传
				uploadFlag: false,
				form: {
					share_image: '',
					goods_image: '',
					home_bj:'',	//首页头部背景
					shop_logo:'',
					home_image:[],	//图片显示路径
				},
				rules: {},
				tipIimg: false,
				poster_id: ''
			}
		},
		mounted() {
			//if(this.poster_id){
			this.getInfo();
			//}

		},
		methods: {
			getInfo() {
				getPosterInfo({}).then(res => {
					if (res.data != "") {
						if(res.data.home_image){
							res.data.home_image = res.data.home_image.split(',');
						}
						this.form = res.data;
						this.poster_id = res.data.shop_poster_id;
					}

				})
			},
			handleClick(val) {},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				if (this.sureImgBtn == 'share_image') {
					this.form.share_image = val[0].resource_name;
				}
				if (this.sureImgBtn == 'goods_image') {
					this.form.goods_image = val[0].resource_name;
				}
				if (this.sureImgBtn == 'home_bj') {
					this.form.home_bj = val[0].resource_name;
				}
				if (this.sureImgBtn == 'shop_logo') {
					this.form.shop_logo = val[0].resource_name;
				}
				if(this.sureImgBtn == 'home_image'){
					let arr = [];
					val.forEach(element => {
						arr.push(element.resource_name)
					});
					this.form.home_image = [...this.form.home_image,...arr];
				}
				this.uploadFlag = false;
			},
			// 选择图片
			handlePosterImg(type) {
				this.sureImgBtn = type;
				if(type == 'home_image'){
					this.mulity = false;
				}else{
					this.mulity = true;
				}
				this.uploadFlag = !this.uploadFlag;
			},
			delImg(item,index){	//多选删除
				this.form.home_image.splice(index,1);
			},
			mouseover() {
				this.tipIimg = true;
			},
			mouseleave() {
				this.tipIimg = false;
			},
			//提交设置
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.form));
						data.share_image = this.tools.filterImgPre(data.share_image);
						data.goods_image = this.tools.filterImgPre(data.goods_image);
						data.home_bj = this.tools.filterImgPre(data.home_bj);
						data.shop_logo = this.tools.filterImgPre(data.shop_logo);
						let arr = [];
						data.home_image.forEach(item=>{
							arr.push(this.tools.filterImgPre(item))
						})
						data.home_image = arr.toString();
						data = this.tools.filterParams(data);
						if (this.poster_id) {
							data.poster_id = this.poster_id;
							editPoster(data).then(res => {
								this.$message.success("修改成功!");
								this.getInfo();
							})
						} else {
							addSharePoster(data).then(res => {
								this.$message.success("添加成功!");
								this.getInfo();
							})
						}

						//调接口
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.avatar-uploader {
		border-radius: 4px 4px;
		// border: 1px solid #CCC;
		// background-color: #fff;
		width: 156px;
		position: relative;
		height: auto;
		margin-top: 15px;
		// line-height: 148px;
		margin-right: 28px;

		::v-deep.el-icon-plus {
			width: 140px;
			border: 1.3px dashed #CCC;
			background-color: #F7F8FA;
			border-radius: 4px 4px;
			text-align: center;
			height: 140px;
			line-height: 140px;

			::v-deep.el-icon-plus {
				font-size: 24px;
				color: #969799;
			}
		}

		.closeImg {
			font-size: 20px;
			width: 20px;
			height: 20px;
			position: absolute;
			top: -6px;
			right: -22px;
			cursor: pointer;

			i {
				display: block;
			}
		}

		img {

			border-radius: 4px 4px;
			width: 100%;
			// height: 100%;
		}
	}

	.plusImg {
		width: 120px;
		margin-top: 15px;
		border: 1.3px dashed #CCC;
		background-color: #F7F8FA;
		border-radius: 4px 4px;
		text-align: center;
		height: 120px;
		line-height: 120px;

		::v-deep.el-icon-plus {
			width: 120px;
			height: 120px;
			line-height: 120px;
			font-size: 24px;
			color: #969799;
		}
	}
	.plusImg_banner{
		width: 115px;
		border: 1.3px dashed #CCC;
		background-color: #F7F8FA;
		border-radius: 4px 4px;
		text-align: center;
		height: 115px;
		line-height: 115px;
		float: right;

		::v-deep.el-icon-plus {
			width: 115px;
			height: 115px;
			line-height: 115px;
			font-size: 24px;
			color: #969799;
		}
	}
	.imgContent {
		max-height: 500px;
		overflow-y: scroll;
	}
	.img_blo_duo{
		width: 115px;
		height: 115px;
		position: relative;
		margin-right: 20px;
		.item_img{
			position: absolute;
			inset: 0;
		}
		.xxx{
			position: absolute;
			top: -10px;
			right: -10px !important;
		}
	}
	.example {
		height: 235px;
		margin-left: 10px;
		top: 14px;
		position: absolute;
		display: inline-block;

		span {
			font-size: 14px;
			color: #409EFF;
			cursor: pointer;
			vertical-align: top;
		}

		::v-deepimg {
			width: 165px;
			height: 235px;
		}
	}

	.poster {
		display: inline-block;
	}
</style>
