<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<div class="storemain">
					<el-button type="primary" size="small" plain @click="addAdress">新建地址</el-button><span>已添加{{total}}个，最多创建20个地址</span>
				</div>
				<div class="storemain" style="margin-top:10px">
					<div class="tables">
						<el-table border :data="tableData" style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
							<el-table-column prop="contact_name" label="联系人" align="center"></el-table-column>
							<el-table-column prop="addressDetails" label="地址" align="center">
							</el-table-column>
							<el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
							<el-table-column prop="postcode" label="邮编" align="center" oninput="value=value.replace(/[\u4E00-\u9FA5]/g,'')"></el-table-column>
							<el-table-column prop="state" label="默认设置" width="300" align="center">
								<template slot-scope="scope">
									<el-button size="small" disabled type="primary" v-if="scope.row.is_delivery == 1 && scope.row.is_delivery_default == 1">发货地址</el-button>
									<el-button size="small" disabled type="primary" v-if="scope.row.is_return == 1 && scope.row.is_return_default == 1">退货地址</el-button>
									<el-button size="small"  v-if="scope.row.is_return == 1 && scope.row.is_return_default == 0" @click="setDefault(scope.row,2)">退货地址</el-button>
									<el-button size="small" v-if="scope.row.is_delivery == 1 && scope.row.is_delivery_default == 0" @click="setDefault(scope.row,1)">发货地址</el-button>
								</template>
							</el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button type="text" @click="editAddress(scope.row.shop_address_id)">编辑</el-button>
									<el-button type="text"  style="color: #F56C6C;" @click="delAddress(scope.row.shop_address_id)">删除</el-button>
									</template>
							</el-table-column>
						</el-table>
						<!-- 分页 -->
						<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getAddressPageList,
		delAddress,
		setDefault
	} from "@/api/merchant/setting.js"
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	export default {
		data() {
			return {
				loading:true,
				total: 0,
				currentPage: 1, //初始页
				pagesize: 20, //每页的数据
				tableData: []
			}
		},
		methods: {
			addressListData(){
				let data={
					page:this.currentPage,
					pagesize:this.pagesize
				}
				getAddressPageList(data).then(res=>{
						this.loading=false;
						this.tableData = res.data.list;
						this.total = res.data.count;
						this.tableData.forEach(item=>{
							item.addressDetails = item.address+item.full_address;
						})
				})
			},
			handleSizeChange(size) {
				this.pagesize = size;
				this.addressListData();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.addressListData();
			},
			//新建地址
			addAdress(){
				if(this.tableData.length>20){
					this.$message({
						message: '最多只能创建20个',
						type: 'error'
					});
				}else{
					this.$router.push('/merchantsettingadmin/distributionset/meraddaddress')
				}
			},
			//编辑地址
			editAddress(id){
				 this.$router.push({path:'/merchantsettingadmin/distributionset/meraddaddress',query:{id:id}})
			},
			//删除地址
			delAddress(id){
				this.$confirm("确认要删除此地址吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params={
						address_id:id
					}
					delAddress(params).then(res=>{
						if (res.return_code === 'SUCCESS') {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.addressListData();
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					})
				})
			},
			setDefault(row,type){
				this.$confirm(type==1?"确认要设置默认发货地址吗？":"确认要设置默认退货地址吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params={
						address_id:row.shop_address_id,
						type:type
					}
					setDefault(params).then(res=>{
						if (res.return_code === 'SUCCESS') {
							this.$message({
								message: '设置成功',
								type: 'success'
							});
							this.addressListData();
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					})
				})
				
			},

		}
	}
</script>

<style scoped="scoped" lang="scss">
	.storemain {
		background: #F2F2F6;
		border-radius: 4px;
		padding: 10px 10px;
		font-size: 14px;

		span {
			font-size: 12px;
			color: #999;
			margin-left: 10px;
		}

		.title {
			font-size: 16px;
			padding: 20px 20px;
			color: #333;
			margin-bottom: 20px
		}
	}
</style>
