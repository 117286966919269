<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>合伙人人数</span>
                    </div>
                    <div class="demo-type">
                        <div>
                            <p><span>{{agent_count.sum}}</span></p>
                            <p>合伙人总数</p>
                        </div>
                        <div>
                            <p><span>{{agent_count.A}}</span></p>
                            <p>A</p>
                        </div>
                        <div>
                            <p><span>{{agent_count.B}}</span></p>
                            <p>B</p>
                        </div>
                        <div>
                            <p><span>{{agent_count.C1}}</span></p>
                            <p>C</p>
                        </div>
                    </div>
                </el-card>
                <el-card class="box-card mt10" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>合伙人业绩</span>
                    </div>
                    <div class="demo-type">
                        <div>
                            <p><span>¥{{achievement.sum}}</span></p>
                            <p>总业绩</p>
                        </div>
                        <div>
                            <p><span>¥{{achievement.A}}</span></p>
                            <p>A</p>
                        </div>
                        <div>
                            <p><span>¥{{achievement.B}}</span></p>
                            <p>B</p>
                        </div>
                        <div>
                            <p><span>¥{{achievement.C1}}</span></p>
                            <p>C</p>
                        </div>
                    </div>
                </el-card>
                <el-card class="box-card mt10" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>合伙人奖励</span>
                    </div>
                    <div class="demo-type">
                        <div>
                            <p><span>¥{{commission.sum}}</span></p>
                            <p>累计已结算奖励</p>
                        </div>
                        <div>
                            <p><span>¥{{commission.A}}</span></p>
                            <p>A</p>
                        </div>
                        <div>
                            <p><span>¥{{commission.B}}</span></p>
                            <p>B</p>
                        </div>
                        <div>
                            <p><span>¥{{commission.C1}}</span></p>
                            <p>C</p>
                        </div>
                    </div>
                </el-card>
                <el-card class="box-card mt10" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>合伙人已提现</span>
                    </div>
                    <div class="demo-type">
                        <div>
                            <p><span>¥{{withdrawal.sum}}</span></p>
                            <p>累计已提现奖励</p>
                        </div>
                        <div>
                            <p><span>¥{{withdrawal.A}}</span></p>
                            <p>A</p>
                        </div>
                        <div>
                            <p><span>¥{{withdrawal.B}}</span></p>
                            <p>B</p>
                        </div>
                        <div>
                            <p><span>¥{{withdrawal.C1}}</span></p>
                            <p>C</p>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
	import {viewCenter} from '@/api/plat/partner'
export default {
    data() {
        return {
            agent_count:{},
            achievement:{},
            commission:{},
            withdrawal:{},
        }
    },
    mounted() {
        this.getinfo()
     },
    methods:{
        getinfo(){
            viewCenter({}).then(res=>{
                this.agent_count=res.data.agent_count;
                this.achievement=res.data.achievement;
                this.commission=res.data.commission;
                this.withdrawal=res.data.withdrawal;
			})
        }
    }
}
</script>
<style lang="less" scoped>
.demo-type {
    display: flex;
}

.demo-type>div {
    border-right: 1px solid rgba(224, 230, 237, .5);
    flex: 1;
    text-align: center;

    p {
        line-height: 28px;
        font-size: 14px;

        span {
            font-size: 16px;
            display: block;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
        }
    }

}
</style>