import axios from '@/config/httpConfig'
import qs from  'qs'
//插件列表
export const twitterList=(params)=> {
  return axios('get','Twiker/channel/serverTwitterList', params)
}
//修改营销插件是否上架
export const serverTwitterUpState=(params) =>{
  return axios('get','Twiker/channel/serverTwitterUpState', params)
}
//营销插件详情
export const serverDetails=(params)=> {
  return axios('get','Twiker/channel/serverDetails', params)
}
//订单-创建虚拟订单
export const rechargeAmount=(data) =>{
  return axios('post','/Order/channel/rechargeAmount', data)
}