<template>
	<div>
		<div class="wrapper-main">
			<PlatGoodAuditDetail :activeName="activeName"></PlatGoodAuditDetail>
		</div>
	</div>
</template>
<script>
	import PlatGoodAuditDetail from '../../../../../pagecomponents/platGoodAuditDetail.vue'
	export default {
		components: {
			 PlatGoodAuditDetail,
		},
		data() {
			return {
				activeName:this.$route.query.activeName
			}
		},
		mounted() {
		},
		methods: {



		}

	}
</script>

<style lang="scss" scoped="scoped">

</style>
