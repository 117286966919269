<template>
	<div>
		<div class="mt10" style="border: 10px solid #F2F2F6;">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="课程列表" name="college">
					<CollegeList ref="colleges"></CollegeList>
				</el-tab-pane>
				<el-tab-pane label="课程讲师" name="teacher">
					<CollegeTeacher ref="teachers"></CollegeTeacher>
				</el-tab-pane>
				<el-tab-pane label="课程类目" name="category">
					<CollegeCategory ref="categorys"></CollegeCategory>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import CollegeList from '../collegelist/index.vue';
	import CollegeTeacher from '../collegeteacher/index.vue';
	import CollegeCategory from '../collegecategory/index.vue';
	export default {
		components: {
			CollegeList,
			CollegeTeacher,
			CollegeCategory
		},
		data() {
			return {
				activeName: 'college'
			}
		},
		mounted() {
			
		},
		methods: {
			handleClick(val) {
				if (val.name == 'college') {
					this.$refs.colleges.getCollegeList()
				} else if (val.name == 'teacher') {
					this.$refs.teachers.getCollegeTeacherList()
				} else if (val.name == 'category') {
					this.$refs.categorys.getCollegeCategoryList()
				}
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	::v-deep.el-tabs__header {
		padding: 0;
		position: relative;
		margin: 0px 0px 0px 15px;
	}
</style>
