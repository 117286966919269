<template>
	<div>
		<!---渠道登录-->
		<div class="login-cont" v-if="codeShow == false">
			<div class="item-title titles">
				<li v-for="(item,index) in titleItem" :key="item.name" :class="{active : curIndex == index}"
					@click="selected(index)">{{item.name}}</li>
			</div>
			<el-form v-if="curIndex==0" :model="loginChannelForm" :rules="Channelrules" ref="loginChannelForm"
				label-width="0px" class="ms-content">
				<el-form-item prop="username">
					<el-input v-model="loginChannelForm.username" clearable @input="phoneChange_c1" maxlength="11"
						placeholder="请输入手机号">
					</el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input type="password" placeholder="请输入登录密码" maxlength="16" clearable
						v-model="loginChannelForm.password">
					</el-input>
				</el-form-item>
				<div>
					<el-checkbox v-model="checked">记住密码</el-checkbox>
				</div>
				<div class="login-btn">
					<el-button type="primary" @click="submitChannelLoginForm('loginChannelForm')">登录</el-button>
				</div>
			</el-form>
			<el-form v-if="curIndex==1" :model="codeChannelForm" :rules="codeChannelrules" ref="codeChannelForm"
				label-width="0px" class="ms-content">
				<el-form-item prop="phone">
					<el-input @input="phoneChange_c2" maxlength="11" clearable v-model="codeChannelForm.phone"
						placeholder="请输入手机号">
					</el-input>
				</el-form-item>
				<el-form-item prop="code">
					<div class="codeitem">
						<el-input class="inputcode" maxlength="6" type="password" placeholder="请输入验证码"
							v-model="codeChannelForm.code">

						</el-input>
						<div class="codebtn" v-show="show" @click="getChannelCode">获取验证码</div>
						<div v-show="!show" class="codebtn">{{count}} s</div>
					</div>
				</el-form-item>
				<div class="login-btn">
					<el-button type="primary" @click="submitChannelCodeForm('codeChannelForm')">登录</el-button>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		channelLogin,
		loginSmsCodeSend
	} from '@/api/channel/login';
	const sha256 = require('js-sha256').sha256;
	import {
		isvalidPhone
	} from '@/utils/validate'
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入手机号'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		data() {
			return {
				titleItem: [{
					id: 1,
					name: '密码登录'
				}, {
					id: 2,
					name: '验证码登录'
				}],
				checked: true,
				codeShow: false,
				curIndex: 0,
				show: true,
				count: '',
				//渠道登录
				loginChannelForm: {
					username: '', //13269822844
					password: '', //123456789
				},
				codeChannelForm: {
					phone: '',
					code: '',
				},
				codeChannelrules: {
					phone: [{
						required: true,
						trigger: 'blur',
						validator: validPhone
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}],
				},
				Channelrules: {
					username: [{
						required: true,
						trigger: 'blur',
						validator: validPhone
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
				},
				show: true,
				count: '',
				timer: null,
			}
		},
		mounted() {
			this.getCookie();
		},
		methods: {
			selected(indx) {
				this.curIndex = indx;
			},
			//账号密码登录
			//验证手机号
			phoneChange_c1() {
				this.loginChannelForm.username = this.loginChannelForm.username.replace(/[^\d]/g, "");
				var reg = /^1[3456789]\d{9}$/;
			},
			submitChannelLoginForm(loginChannelForm) {
				this.$refs[loginChannelForm].validate((valid) => {
					if (valid) {
						var reg = /^1[3456789]\d{9}$/;
						if (this.loginChannelForm.username.length >= 11 && !reg.test(this.loginChannelForm
								.username)) {
							this.$message.error("请输入正确的手机号码格式");
							return;
						};
						if (this.loginChannelForm.password.length < 6 || this.loginChannelForm.password.length >
							16) {
							this.$message.error('请输入6-16位密码');
							return;
						}
						//记住密码
						if (this.checked == true) {
							//传入账号名，密码，和保存天数3个参数
							this.tools.setCookie(this.loginChannelForm.username, this.loginChannelForm.password,
							7);
						} else {
							//清空Cookie
							this.tools.clearCookie();
						};
						let params = {
							account: this.loginChannelForm.username,
							password: sha256(this.loginChannelForm.password)
						}
						// let rasparams={
						// 	secretData:this.encryption.$getrsa(JSON.stringify(params))
						// } 
						channelLogin(params).then(res => {
							let token = res.data.token;
							localStorage.setItem('dynamic', res.data.dynamic);
							localStorage.setItem('refreshToken', res.data.refreshToken);
							localStorage.setItem('terminal', res.data.terminal);
							localStorage.setItem('head_portrait', res.data.head_portrait);
							localStorage.setItem('shop_name', res.data.shop_name);
							this.$store.commit('LOGIN_IN', token);
							localStorage.setItem('dataset',res.data.permission);//保存权限路由
							this.$router.replace('/home').catch(err => {})
						}).catch(err => {
							this.$message.error(err.return_msg)
						})
					} else {
						this.$message.error('请输入账号和密码');
						return false;
					}
				});
			},
			//读取cookie
			getCookie() {
				if (document.cookie.length > 0) {
					var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
					for (var i = 0; i < arr.length; i++) {
						var arr2 = arr[i].split('='); //再次切割
						//判断查找相对应的值
						if (arr2[0] == 'userName') {
							this.loginChannelForm.username = arr2[1]; //保存到保存数据的地方
						} else if (arr2[0] == 'userPwd') {
							this.loginChannelForm.password = arr2[1];
						}
					}
				}
			},
			//验证码登录
			phoneChange_c2() {
				this.codeChannelForm.phone = this.codeChannelForm.phone.replace(/[^\d]/g, "");
			},
			//发送验证码
			getChannelCode() {
				if (this.codeChannelForm.phone == '') {
					this.$message.error("请输入手机号！");
					return;
				}
				let data = {
					account: this.codeChannelForm.phone,
					random: 'channelAccountLogin'
				}
				// let rasdata = {
				// 	secretData: this.encryption.$getrsa(JSON.stringify(data))
				// }
				loginSmsCodeSend(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000)
						}
					}
				}).catch(err => {
					this.$message.error(err.return_msg)
				})
			},
			submitChannelCodeForm(codeChannelForm) {
				this.$refs[codeChannelForm].validate((valid) => {
					if (valid) {
						localStorage.setItem('loginstate', "channel");
						var reg = /^1[3456789]\d{9}$/;
						if (this.codeChannelForm.phone.length >= 11 && !reg.test(this.codeChannelForm.phone)) {
							this.$message.error("请输入正确的手机号码格式");
							return;
						}
						let params = {
							account: this.codeChannelForm.phone,
							sms_code: this.codeChannelForm.code
						}
						// let rasparams={
						// 	secretData:this.encryption.$getrsa(JSON.stringify(params))
						// } 
						channelLogin(params).then(res => {
							let token = res.data.token;
							localStorage.setItem('dynamic', res.data.dynamic);
							localStorage.setItem('refreshToken', res.data.refreshToken);
							localStorage.setItem('head_portrait', res.data.head_portrait);
							localStorage.setItem('shop_name', res.data.shop_name);
							this.$store.commit('LOGIN_IN', token);
							localStorage.setItem('dataset',res.data.permission);//保存权限路由
							this.$router.replace('/home').catch(err => {})
						}).catch(err => {
							this.$message.error(err.return_msg)
						})
					} else {
						this.$message.error('请输入账号和验证码');
						return false;
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/css/login.scss";

	.tabs::v-deep.el-tabs__nav {
		margin-left: 27%;
		font-size: 16px;
	}

	.tabs::v-deep.el-tabs__item {
		font-size: 16px;
		padding: 0 50px;
	}

	.login-cont {
		width: 420px;
		margin: 0 auto;
	}

	.titles {
		margin: 35px 0;
	}

	//切换菜单
	.item-title {
		// width: 100%;
		display: flex;
		margin-bottom: 30px;
	}

	.item-title li {
		list-style: none;
		width: 100px;
		line-height: 34px;
		height: 34px;
		color: #999;
		font-size: 16px;
		cursor: pointer;
		text-align: center;
		margin-right: 30px
	}

	.item-title li.active {
		width: 100px;
		height: 34px;
		line-height: 34px;
		border-radius: 4px;
		color: #333300;
		font-size: 20px;
	}
</style>
