<template>
	<div>
		<el-popover placement="bottom"  width="284" trigger="hover" :content="content" :title="title">
			<span slot="reference" class="tips">
				<span>{{name}}</span>
				<img class="img" align="center" src="../../assets/img/question.png" />
			</span>
		</el-popover>
	</div>
</template>

<script>
	export default{
		props: {
		   
			content: {
			    type: String,
			    default: () => {
			        return ''
			    }
			},
			name: {
			    type: String,
			    default: () => {
			        return ''
			    }
			},
			title: {
			    type: String,
			    default: () => {
			        return ''
			    }
			},
		},
		data(){
			return{
				
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.img{
		width: 20px;
		height: 20px;
		vertical-align: middle;
		// margin-left: 8px;
	}
</style>
