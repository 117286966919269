<template>
	<div>
		<div style="border-top: 10px solid #F2F2F6;">
			<div class="list">
				<el-row class="all_search_list" style="margin-bottom: 0; padding: 15px 15px;">
					<div class="demo-input-suffix">
						<el-button size="small" type="primary" @click="toAdd" v-has="'学院课程/课程列表/新建课程'" >新建课堂</el-button>
						<el-button size="small" @click="toDel" v-has="'学院课程/课程列表/删除'" >删除</el-button>
					</div>
					<div class="demo-input-suffix">
						<el-select v-model="search.course_type" size="small" class="w120" placeholder="全部课程">
							<el-option label="全部课程" value=""></el-option>
							<el-option label="图文课程" value="1"></el-option>
							<el-option label="视频课程" value="2"></el-option>
						</el-select>
					</div>
					<div class="demo-input-suffix">
						<el-select v-model="search.status" size="small" class="w120" placeholder="课程状态">
							<el-option label="全部状态" value=""></el-option>
							<el-option label="启用" value="1"></el-option>
							<el-option label="禁用" value="2"></el-option>
						</el-select>
					</div>
					<div class="demo-input-suffix">
						<el-cascader size="small" v-model="collegeCategory" :options="allCate" placeholder="课程分类"
							:props="{ expandTrigger: 'hover',value:'college_course_id',label:'course_name',children:'children'}">
						</el-cascader>
					</div>
					<div class="demo-input-suffix">
						<el-input prefix-icon="el-icon-search" class="inputWidth" v-model="search.course_title"
							placeholder="请输入课程名称" size="small" type="text"  @keyup.enter.native="toSearch"  clearable>
						</el-input>
					</div>
					<div class="demo-input-suffix">
						<el-button size="small" type="primary" @click="toSearch">搜索</el-button>
					</div>
					<div class="demo-input-suffix">
						<el-button size="small" @click="toReset">重置</el-button>
					</div>
				</el-row>
				<el-table :data="tableData" style="width: 100%"  max-height="550px"  border @selection-change="handleSelectionChange" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" :header-cell-style="{background:'#f3f8fe',color:'#606060'}">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column label="排序" type="index" width="50"></el-table-column>
					<el-table-column label="课程标题" align="center" prop="course_title"></el-table-column>
					<el-table-column label="属于类目" align="center" prop="course_name"></el-table-column>
					<el-table-column label="课程类型" align="center">
						<template slot-scope="scope">
							<span style="color: #E02020;" v-if="scope.row.course_type == '1'">图文</span>
							<span style="color: #409EFF;" v-if="scope.row.course_type == '2'">视频</span>
						</template>
					</el-table-column>
					<el-table-column label="状态" align="center">
						<template slot-scope="scope">
							<span style="color: #409EFF;" v-if="scope.row.status == '1'">启用</span>
							<span style="color: #E02020;" v-if="scope.row.status == '2'">禁用</span>
						</template>
					</el-table-column>
					<el-table-column label="创建时间" align="center" prop="created_at"></el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<!-- <el-button style="padding: 5px 0;" type="text" size="small"
								@click="toOpera(scope.row,'detail')">查看
							</el-button> -->
							<el-button style="padding: 5px 0;color: #E02020;" type="text" size="small"
								@click="toOpera(scope.row,'del')" v-has="'学院课程/课程列表/删除'" >删除</el-button>
							<el-button style="padding: 5px 0px;" type="text" size="small"
								@click="toOpera(scope.row,'edit')" v-has="'学院课程/课程列表/编辑'" >编辑
							</el-button>
							<el-button type="text" @click="toOpera(scope.row,'isopen')" style="color: #0091FF;"
								size="small" v-has="scope.row.status==1?'学院课程/课程列表/禁用':'学院课程/课程列表/启用'" >{{scope.row.status==1?'禁用':'启用'}}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>

	</div>
</template>
<script>
	import {
		getCollegeCurriculumList,
		courseList,
		saveStatus,
		delCurriculum
	} from "@/api/plat/college.js"
	export default {
		data() {
			return {
				loading:true,
				//搜索条件
				search: {
					course_type: '',
					course_title: '',
					status: ''
				},
				collegeCategory: [],
				//课程列表
				tableData: [],
				multipleSelection: [], //全选课程
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				allCate: []
			}
		},
		created() {
			this.$on('collegeList', this.getCollegeList())
			this.$on('collegeType', this.getCourseType())
		},
		methods: {
			getCourseType() {
				courseList({}).then(res => {
					this.allCate = res.data
				})
			},
			getCollegeList() {
				let data = {
					course_title: this.search.course_title,
					college_course_id_one: this.collegeCategory[0] ? this.collegeCategory[0] : '',
					college_course_id_two: this.collegeCategory[1] ? this.collegeCategory[1] : '',
					course_type: this.search.course_type,
					status: this.search.status,
					page: this.currentPage,
					perPage: this.pagesize,
				}
				data = this.tools.filterParams(data);
				getCollegeCurriculumList(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
				})
			},
			toSearch(){
				this.currentPage = 1;
				this.getCollegeList()
			},
			toReset(){
				this.currentPage = 1;
				this.search.course_title='';
				this.collegeCategory=[];
				this.search.course_type='';
				this.search.status='';
				this.getCollegeList()
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getCollegeList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getCollegeList()
			},
			//新增
			toAdd() {
				this.$router.push({
					path: "/marketingadmin/businesscollege/collegeset",
				})
			},
			//删除
			toDel() {
				if (this.multipleSelection.length == 0) {
					this.$message.warning("请选择要删除的课程！")
					return;
				}
				this.tools.confirm("确定要批量删除课程吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							college_curriculum_id: this.multipleSelection.toString()
						}
						delCurriculum(data).then(res => {
							this.$message.success("删除成功")
							this.getCollegeList()
						})
					}
				})
			},
			//课程列表全选
			handleSelectionChange(val) {
				let aa=[];
				val.forEach(item=>{
					aa.push(item.college_curriculum_id)
				})
				this.multipleSelection = aa;
			},
			//操作
			toOpera(row, type) {
				//详情
				if (type == 'detail') {
					this.$router.push({
						path: "/marketingadmin/businesscollege/collegeset",
						query: {
							id: row.college_curriculum_id,
							type:'detail'
						}
					})
				}
				//删除
				if (type == 'del') {
					this.tools.confirm("确定要删除此课程吗？").then(res => {
						if (res) {
							//调接口
							let data = {
								college_curriculum_id: row.college_curriculum_id
							}
							delCurriculum(data).then(res => {
								this.$message.success("删除成功")
								this.getCollegeList()
							})
						}
					})
				}
				//编辑
				if (type == 'edit') {
					this.$router.push({
						path: "/marketingadmin/businesscollege/collegeset",
						query: {
							id: row.college_curriculum_id
						}
					})
				}
				//启用禁用
				if (type == 'isopen') {
					this.tools.confirm(row.status == 1 ? "确定要禁用课程吗？" : "确定要启用课程吗？").then(res => {
						if (res) {
							//调接口
							saveStatus({
								college_curriculum_id: row.college_curriculum_id,
								status: row.status == 1 ? 2 : 1
							}).then(res => {
								this.$message.success("操作成功")
								this.getCollegeList()
							})
						}
					})
				}
			}
		}
	}
</script>

<style>
</style>
