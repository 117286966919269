<template>
	<div>
		<div class="expressList">
			<div class="operate-cont">
				<div class="title">已添加快递</div>
				<el-button plain size="small" @click="addExpress">新增物流</el-button>
			</div>
			<div class="tables" style="margin-top: 20px;">
				<el-table border :data="tableData" style="width: 100%">
					<el-table-column prop="name" label="物流公司" align="center"></el-table-column>
					<el-table-column prop="name" label="物流公司名称" align="center"></el-table-column>
					<el-table-column prop="status" label="状态" align="center">
						<template  slot-scope="scope">
							<span v-if="scope.row.status=='Y'" >启用</span>
							<span v-else >禁用</span>
						</template>
					</el-table-column>
					<el-table-column prop="date" label="操作" align="center">
						<template slot-scope="scope">
							<el-button  type="text" style="color: #67C23A;" size="small" v-if="scope.row.status=='N'" @click="handleExpress(scope.row,'Y')">启用</el-button>
							<el-button  type="text" style="color: #F56C6C;" size="small" v-else-if="scope.row.status=='Y'" @click="handleExpress(scope.row,'N')">禁用</el-button>
							<el-button  type="text"   size="small" @click="delExpress(scope.row.expressage_id)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<!--新增物流公司-->
		<el-dialog title="新增物流公司" :visible.sync="expressModel" width="30%">
			<el-form ref="ruleForm" :model="ruleForm"  label-width="120px">
				<el-form-item label="物流公司:" prop="name">
					<el-select v-model="ruleForm.expressage_id">
						<el-option v-for="(item,index) in expressList" :key='index' :label="item.name" :value="item.expressage_id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="expressModel=false">取消</el-button>
				<el-button size="small" type="primary" @click="expressSubmit('ruleForm')">确认</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		getExpressInfoList,
		ExpressUpdate,
		ExpressInsert,
		Expressdelete
	} from "@/api/merchant/setting.js";
	export default {
		data() {
			return {
				expressModel:false,
				tableData: [],
				expressList:[],//物流公司
				ruleForm:{
					expressage_id:''
				}
			}
		},
		mounted() {
			// this.getExpressInfoListData()
		},
		methods: {
			getExpressInfoListData(){
				let data={
					pitchon:1
				}
				getExpressInfoList(data).then(res=>{
					if (res.return_code === 'SUCCESS') {
						this.tableData = res.data;
					}
				})
			},
			//未添加物流
			getNoExpressInfoListData(){
				let data={
					pitchon:0
				}
				getExpressInfoList(data).then(res=>{
					if (res.return_code === 'SUCCESS') {
						this.expressList = res.data;
					}
				})
			},
			
			//启用禁用
			handleExpress(row,type){
				let tipcontent = (type == 'N') ? '确定要禁用此物流吗？' : '确定要启用此物流吗？'
				this.$confirm(tipcontent, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var data={
						expressage_id:row.expressage_id,
						status:type=='N'?'N':'Y',
					}
					ExpressUpdate(data).then(response => {
						if (response.return_code == "SUCCESS") {
							this.getExpressInfoListData()
						} 
					})
				})
			
			},
			//删除
			delExpress(id) {
				this.$confirm('确定要删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var data = {
						expressage_id: id,
					}
					Expressdelete(data).then(response => {
						if (response.return_code == "SUCCESS") {
							this.$notify({
								title: '成功',
								message: '删除成功',
								type: 'success',
								duration: 2000
							})
						this.getExpressInfoListData()
						} 
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					})
				})
			},
			//添加
			addExpress(){
				this.expressModel=true;
				this.getNoExpressInfoListData();
			},
			//新增物流
			expressSubmit(){
				let data={
					expressage_id:this.ruleForm.expressage_id
				}
				ExpressInsert(data).then(response => {
					if (response.return_code == "SUCCESS") {
						this.$notify({
							title: '成功',
							message: '新增成功',
							type: 'success',
							duration: 2000
						});
						this.getExpressInfoListData();
						this.ruleForm.expressage_id='';
						this.expressModel=false
					} 
				})
				
			}

		}
	}
</script>

<style scoped="scoped" lang="scss">
	.submitBtn {
		text-align: right;
		margin-top: 20px;
	}

	.expressList {
		padding: 20px;

		background: #FFFFFF;
		border-radius: 4px;
	}

	.operate-cont {
		display: flex;

		.title {
			font-size: 14px;
			line-height: 32px;
			margin-right: 10px;
		}

	}
</style>
