<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card shadow="never" v-has="'创建资料包'">
                    <el-button type="primary" size="small" @click="addpackage">创建资料包</el-button>
                </el-card>
                <div class="mt10">
                    <el-table :data="tableData" border style="width: 100%" v-loading="loading" size="small"
                        element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.2)" max-height="550px" class="mt10"
                        :header-cell-style="{ background: '#f3f8fe', color: '#606060' }">
                        <el-table-column label="资料包名称" prop="nickname" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column label="格式" prop="postfix" align="center"></el-table-column>
                        <el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="toDownload(scope.row)" v-has="'下载'">下载</el-button>
                                <el-button type="text" size="small" @click="toDel(scope.row)" v-has="'删除'">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]"
                    :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog title="创建资料包" :visible.sync="dialogVisible" width="25%" >
            <el-form :model="resourceForm" :rules="rules" ref="resourceForm" label-width="150px">
                <el-form-item label="资料包名称：" >
                    <el-input v-model="resourceForm.resourceName" size="small" disabled></el-input>
                </el-form-item>
                <el-form-item label="上传资料包：" prop="resourceNewName">
                    <el-upload class="upload-demo uploads" :headers="{ token: tokens }" action="javascript:;" :on-preview="handlePreview"
                        :on-remove="handleRemove" :before-upload="beforeUpload"  :show-file-list="false"
                        :on-success="handleSuccess" >
                        <el-button style="padding:3px 10px;min-height:33px" icon="el-icon-upload2">
                            {{ pdftitle }}
                        </el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
             <!-- <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitForm('resourceForm')">确 定</el-button>
                </span> -->
        </el-dialog>
    </div>
</template>
<script>
import {pdfList,fileUploading,imagesNameDel }  from '@/api/plat/partner'
export default {
    data() {
        return {
            tableData: [],
            loading: true,
            dialogVisible: false,
            resourceForm: {
                resourceName: ''
            },
            rules:{
                resourceName: [
                        { required: true, message: '请填写资料包名称', trigger: 'blur' }
                    ]
            },
            tokens: '',
            pdftitle: '上传文件',
               fileList:[],
             total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            let data={
                page:this.currentPage,
                perPage:this.pagesize
            }
            pdfList(data).then(res=>{
                this.tableData=res.data.list;
                this.total=res.data.count;
                this.loading = false;

            })
            
        },
         handleSizeChange(size) {
            this.pagesize = size;
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getList()
        },
        addpackage() {
            this.dialogVisible = true
        },
          handleRemove(file, fileList){
                console.log(file, fileList)
        },
         handlePreview(file) {
            console.log(file)
        },
        beforeUpload (file) {
            console.log(file.type,"222")
             const isFile = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpg', 'image/jpeg','image/png','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type);
            // const isPDF = file.type=='application/pdf'
            const isLt5M = file.size < 1 * 1024 * 1024
            if(!isFile){
                this.$message.error('支持PDF、WORD、PPT、EXCEL、JPG、JPEG、PNG');
             return false
            }
            // if (!isLt5M) {
            //     this.$message.error('上传文件大小不能超过 0.8MB!');
            //      return false
            // }
            
             if (isFile && isLt5M) {
                var data = new FormData();
                data.append('file', file)//当前为
                 data.append('up_type',2)
                fileUploading(data).then(res => {
                     this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                    this.resourceForm.resourceName=res.data[0].image_name;
                    setTimeout(() => {
                        this.dialogVisible = false
                    }, 1500)
                   
                })

            }
          return false
        },
         handleSuccess (res){
                console.log(res)
                // imgState.imgList = [...res.data, ...imgState.imgList]
        },
        //删除资源包
        toDel(row){
            	this.tools.confirm("确定要删除此资源包吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							ossResourceId: row.oss_resource_id
						}
						imagesNameDel(data).then(() => {
                             this.$message({
                                message: '删除成功',
                                type: 'success'
                            });
							 this.getList()
						})
					}
				})
        },
        //下载文件
        toDownload(row){
            window.location.href = row.resource_name;

        }
        // submitForm(formName) {
        //     this.$refs[formName].validate((valid) => {
        //         if (valid) {
        //           alert(1)
        //         } else {
        //             return false;
        //         }
        //     });
        // },
    }
}
</script>
<style lang="less" scoped>
.uploads ::v-deep.el-upload--text{
    width: 110px;
    height: 40px;
    border: none;

}
</style>