<template>
	<div>
		<div class="wrapper-main pds">
			<div class="ms-title titles">
			<li v-for="(item,index) in titleItem" :key="item.name"
				:class="{active : curIndex == index}" @click="selected(index)">{{item.name}}</li>
				</div>
			<div v-if="curIndex == 0"><ManagerProfit ref="general_manager"></ManagerProfit></div>
			<div v-else><AgentProfit ref="senior_manager"></AgentProfit></div>

		</div>
	</div>
</template>

<script>

	import  ManagerProfit from './managerprofit.vue'//店长分润
	import  AgentProfit from './agentprofit.vue'//服务商分润
	export default {
		components:{
			ManagerProfit,
			AgentProfit
		},
		data() {
			return {
				curIndex: 0,
				titleItem: [{
					id: 1,
					name: '店长分润'
				}, {
					id: 2,
					name: '服务商分润'
				}],
			}
		},
		mounted() {
			this.$refs.general_manager.getManagerFenRunList()
		},
		methods:{
			selected(indx) {
				this.curIndex = indx;
				if(indx=='0'){
					 this.$nextTick(()=>{
						 this.$refs.general_manager.getManagerFenRunList()
					 }) 
					
				}else{
					this.$nextTick(()=>{
						 this.$refs.senior_manager.getChannelAllocationInfo()
					}) 
					
				}
				
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
.pds{
	padding-bottom: 120px !important;
}
	
	
</style>
