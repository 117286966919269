<template>
	<div class="wrapper-main">
		<div class="wrapper-cont">
	<!-- 		<div class="main-cont mt10">
				<div class="all_search_list">
					<div class="demo-input-suffix" style="font-size: 13px;">分类搜索
						<el-input size="small" placeholder="请输入分类名称" v-model="keyword" clearable
							style="width:200px;margin-left: 10px;"></el-input>
					</div>
					<el-button type="primary" size="small" icon="el-icon-search" style="margin-left:20px;"
						@click="handleSearch()">查询</el-button>
					<el-button type="primary" size="small" style="margin-left: 10px;" @click="handleSetData">重置
					</el-button>
				</div>
			</div> -->
			<div class="main-cont bgwt mt10">
				<div class="blo_search">
					<div class="search_left" @click="showOrHideAll">
						<img
							:src="showAll ? require('../../../../../../assets/img/cat_downs.png') : require('../../../../../../assets/img/cat_ups.png')" />
						<span v-if="!showAll">展开全部</span>
						<span v-else>折叠全部</span>
					</div>
					<!-- <div>
						<el-input size="small" v-model="keyword" placeholder="请输入分类名称" class="inputWidth mr10" ></el-input>
						<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
						<el-button size="small" @click="toReset">重置</el-button>
					</div> -->
				</div>
			</div>
			<div class="blo_table mt10">
				<el-table ref="llzc" :data="catData" :tree-props="{ children: 'children' }" row-key="id"  max-height="550px" 
					stripe @expand-change="cellChange" @row-click="expandChange" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" :header-cell-style="{background:'#f3f8fe',color:'#606060'}">
					<el-table-column label="分类ID" prop="id"> </el-table-column>
					<el-table-column label="分类名称" prop="name"> </el-table-column>
					<el-table-column label="关联分类名称" prop="locality_categort_name">
					</el-table-column>
					<el-table-column prop="created_at" label="创建时间" align="center">
					</el-table-column>
					<el-table-column label="操作" width="300">
						<template slot-scope="scope">
							<!-- <el-button v-if="scope.row.parent_id == 0 || isParentRelation(scope.$index,scope.row)" @click="relevance(scope)" type="primary" size="small">关联</el-button> -->
							<el-button v-if="scope.row.parent_id == 0 || scope.row.isParentRelation"
								@click="relevance(scope)" type="primary" size="small" v-has="'关联'" >关联</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<!-- 选择图片弹窗 -->
		<!--关联商品-->
		<el-dialog title="平台分类" :visible.sync="goodLibraryModel" width="469px">
			<el-select v-model="case_id" placeholder="请选择">
				<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelModel" size="small">取 消</el-button>
				<el-button type="primary" @click="submitRelevance" size="small">确认</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		ThirdCateList,
		cateList,
		relevanceThirdCate
	} from '@/api/plat/goods'
	export default {
		data() {
			return {
				loading:true,
				keyword: '',
				showAll: false, //是否展开全部
				goodLibraryModel: false,
				currentRow: [],
				case_id: '',
				options: [],
				catData: [], //分类数据
				dataId: '',

			}
		},
		mounted() {
			this.caseList()
		},
		methods: {
			//获取分类列表
			caseList() {
				var data = {
					 keyword: this.keyword,
				}
				// for (const key in data) {
				// 	if (!data[key] || data[key].length == 0) {
				// 		delete data[key];
				// 	}
				// }
				ThirdCateList(data).then(response => {
					//console.log("response",response.data)
					//判断父级是否关联分类
					// console.log(response.data,121212)
					this.loading=false;
					this.catData = response.data;

					this.catData.forEach(res => {
						if (res.locality_categort_id > 0) {
							res.isParentRelation = true
							res.children.forEach(item => {
								item.isParentRelation = true;
								item.parentcategort_id = res.locality_categort_id
							})
						}
					})
					//console.log(this.catData, "4444")

				})
			},
			
			isParentRelation(index, row) {
				/*找父级有无关联*/
				if (row.parent_id > 0) {
					//console.log(row,"5555")
				}

			},
			//获取关联分类下拉框数据
			getCase(scope) {
				var data = {
					parent_id: scope.row.parent_id == 0 ? '0' : scope.row.parentcategort_id,
					level: scope.row.parent_id == '0' ? '0' : "1",
				}
				for (const key in data) {
					if (!data[key] || data[key].length == 0) {
						delete data[key];
					}
				}
				cateList(data).then(response => {
					this.options = response.data;
				})
			},
			//关联弹框
			relevance(scope) {
				this.case_id = '';
				this.dataId = scope.row.id;
				this.getCase(scope);
				this.goodLibraryModel = !this.goodLibraryModel
			},
			// handleSearch() {
			// 	this.caseList()
			// },
			// handleSetData() {
			// 	this.keyword = '';
			// 	this.caseList()
			// },
			// 是否全部展开或关闭
			showOrHideAll() {
				this.currentRow = [];
				this.getExpends();
				this.showAll = !this.showAll;
			},
			cellChange(e) {
				let name = e.catname;
				let row = [...this.currentRow];
				if (!row.includes(name)) {
					row[row.length] = name;
				} else {
					row.splice(row.indexOf(name), 1);
				}
				this.currentRow = [...row];
				if (this.currentRow.length == 2) {
					this.showAll = !this.showAll;
					this.currentRow = [];
				}
			},
			//设置table中的扩展项
			getExpends() {
				this.catData.forEach((item) => {
					this.$refs.llzc.toggleRowExpansion(item);
				});
			},
			expandChange(row, index, e) {
				this.$refs.llzc.toggleRowExpansion(row)
			},
			getRowKeys(row) {
				return row.id;
			},

			cancelModel() {
				this.goodLibraryModel = !this.goodLibraryModel;
			},
			//确认关联
			submitRelevance() {
				let data = {
					type: 1,
					id: this.dataId,
					locality_categort_id: this.case_id
				}
				for (const key in data) {
					if (!data[key] || data[key].length == 0) {
						delete data[key];
					}
				}
				relevanceThirdCate(data).then(response => {
					this.options = response.data;
					this.goodLibraryModel = !this.goodLibraryModel;
					this.caseList();
				})

			},

		}
	}
</script>

<style scoped="scoped" lang="scss">
	.all_search_list {
		width: 100%;
		display: flex;
		margin-bottom: 20px;
	}

	.blo_search {
		display: flex;
		height: 50px;
		justify-content: space-between;
		margin: 0 5px;
		align-items: center;
		padding: 0 12px;

		.search_left {
			font-size: 14px;
			width: 88px;
			height: 30px;
			border: 1px solid #DDDDDD;
			color: #666666;
			border-radius: 4px;
			align-items: center;
			display: flex;
			justify-content: center;
			cursor: pointer;

			img {
				width: 14px;
				height: 14px;
				display: block;
			}
		}


	}

	.blo_table::v-deep.el-table .cell {
		color: #666666;
		font-size: 14px;
	}

	.blo_table::v-deep.el-table .cell,
	.el-table--border td:first-child .cell,
	.el-table--border th:first-child .cell {
		padding-left: 18px;
	}

	.blo_table::v-deep.el-table th {
		font-weight: normal;
		color: #333;
	}

	.blo_table {
		.el_show {
			display: flex;
			align-items: center;

			img {
				margin-left: 6px;
				cursor: pointer;
			}
		}

		.el_move {
			cursor: pointer;
			font-size: 12px;
			border-radius: 2px;
			padding: 4px 6px;
		}

		.el_ups {
			margin-right: 6px;
			border: 1px solid #2589FF;
			color: #2589FF;
		}

		.el_downs {
			border: 1px solid #FFA76D;
			color: #FA6400;
		}

		.el_img {
			width: 72px;
			height: 34px;
		}
	}
</style>
