import { render, staticRenderFns } from "./gradetwo.vue?vue&type=template&id=00831a46&scoped=true&"
import script from "./gradetwo.vue?vue&type=script&lang=js&"
export * from "./gradetwo.vue?vue&type=script&lang=js&"
import style0 from "./gradetwo.vue?vue&type=style&index=0&id=00831a46&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00831a46",
  null
  
)

export default component.exports