<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
	
				<div class="main-cont bgwt">
					<div style="overflow: hidden;">
						<i class="el-icon-picture-outline" style="margin-right: 10px;"></i>
						<span class="title">banner</span>
						<el-button type="primary" size="small" style="float: right;" @click="addContext('banner')">新增</el-button>
					</div>
					<el-table class="mt10" ref="multipleTable" :data="bannerList" size="small" border tooltip-effect="dark"
						style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
						v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
						element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="index" prop="index" label="#" align="center"></el-table-column>
						<el-table-column prop="title" label="标题" align="center"></el-table-column>
						<el-table-column prop="sort" label="排序值" align="center"></el-table-column>
						<el-table-column prop="created_at" label="缩略图" align="center">
							<template slot-scope="scope">
								<img :src="JSON.parse(scope.row.image)[0]" alt="" srcset="" style="width: 70px; height: 70px;">
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="跳转类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.bind_type == 1">商品</span>
								<span v-if="scope.row.bind_type == 2">分类</span>
								<span v-if="scope.row.bind_type == 3">不跳转</span>
								<span v-if="scope.row.bind_type == 4">图片</span>
								<span v-if="scope.row.bind_type == 5">页面</span>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="操作" align="center">
							<template slot-scope="scope">
								<el-button type="text" @click="toDetail('banner',scope.row)" >编辑</el-button>
								<el-button type="text" @click="toDel(scope.row)" ><span style="color:red">删除</span></el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>


				<div class="main-cont bgwt mt10">
					<div style="overflow: hidden;">
						<i class="el-icon-picture-outline" style="margin-right: 10px;"></i>
						<span class="title">金刚区</span>
						<el-button type="primary" size="small" style="float: right;" @click="addContext('diamond')">新增</el-button>
					</div>
					<el-table class="mt10" ref="multipleTable" :data="diamondList" size="small" border tooltip-effect="dark"
						style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
						v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
						element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="index" prop="index" label="#" align="center"></el-table-column>
						<el-table-column prop="title" label="标题" align="center"></el-table-column>
						<el-table-column prop="sort" label="排序值" align="center"></el-table-column>
						<el-table-column prop="created_at" label="缩略图" align="center">
							<template slot-scope="scope">
								<img :src="JSON.parse(scope.row.image)[0]" alt="" srcset="" style="width: 70px; height: 70px;">
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="跳转类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.bind_type == 1">商品</span>
								<span v-if="scope.row.bind_type == 2">分类</span>
								<span v-if="scope.row.bind_type == 3">不跳转</span>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="操作" align="center">
							<template slot-scope="scope">
								<el-button type="text" @click="toDetail('diamond',scope.row)" >编辑</el-button>
								<el-button type="text" @click="toDel(scope.row)" >删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>


				<div class="main-cont bgwt mt10">
					<div style="overflow: hidden;">
						<i class="el-icon-picture-outline" style="margin-right: 10px;"></i>
						<span class="title">广告位</span>
						<el-button type="primary" size="small" style="float: right;" @click="addContext('adver')">新增</el-button>
					</div>
					<el-table class="mt10" ref="multipleTable" :data="adverList" size="small" border tooltip-effect="dark"
						style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
						v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
						element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="index" prop="index" label="#" align="center"></el-table-column>
						<el-table-column prop="title" label="标题" align="center"></el-table-column>
						<el-table-column prop="sort" label="排序值" align="center"></el-table-column>
						<el-table-column prop="created_at" label="缩略图" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.type == 1"><img :src="JSON.parse(scope.row.image)[0]" alt="" srcset="" style="width: 70px; height: 70px;"></div>
								<div v-if="scope.row.type == 2">
									<img :src="JSON.parse(scope.row.image)[0]" alt="" srcset="" style="width: 70px; height: 70px; margin-right: 10px;">
									<img :src="JSON.parse(scope.row.image)[1]" alt="" srcset="" style="width: 70px; height: 70px;">
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.type == 1">一行一个</span>
								<span v-if="scope.row.type == 2">一行两个</span>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="跳转类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.bind_type == 1">商品</span>
								<span v-if="scope.row.bind_type == 2">分类</span>
								<span v-if="scope.row.bind_type == 3">不跳转</span>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="操作" align="center">
							<template slot-scope="scope">
								<el-button type="text" @click="toDetail('adver',scope.row)" >编辑</el-button>
								<el-button type="text" @click="toDel(scope.row)" >删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div style="height: 50px;"></div>
		</div>
	</div>
</template>

<script>
	import {getPageConfig,delPageConfig} from "@/api/merchant/good.js";
	export default {
		data() {
			return {
				loading:true,
				bannerList:[],
				diamondList:[],
				adverList:[],
			}
		},
		mounted() {
			this.getPageConfig();
		},
		methods: {
			getPageConfig(){
				getPageConfig({}).then(res=>{
					this.bannerList = res.data.plate1;
					this.diamondList = res.data.plate2;
					this.adverList = res.data.plate3;
					this.loading = false;
				})
			},
			addContext(type){
				this.$router.push({
					path: '/pageConfig/pageConfiguration/pageConfigurationDeatils',
					query: {
						type: type
					}
				})
			},
			toDetail(type,row){
				this.$router.push({
					path: '/pageConfig/pageConfiguration/pageConfigurationDeatils',
					query: {
						type: type,
						page_id:row.page_id
					}
				})
			},
			toDel(row){	//删除
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
					}).then(() => {
						delPageConfig({page_id:row.page_id}).then(res=>{
							this.$message.success("删除成功！");
							this.getPageConfig();
						})
					}).catch(() => {});
			}



		}
	}
</script>

<style lang="scss" scoped="scoped">
.title{
	font-size: 16px;
}
</style>
