<!--角色编辑-->

<template>
	<div>
		<div class="wrapper-main pds">
			<div class="model-content">
				<div class="storemain">
					<div class="add">
						<el-row class="title">
							账号信息
						</el-row>
						<el-form ref="formLabelAlign" :label-position="labelPosition" style="margin-left: 5%" label-width="90px"
							:model="formLabelAlign">
							<el-form-item label="角色：">
								<el-input size="small" v-model="formLabelAlign.role_nickname" style="max-width:500px"
									placeholder="请输入角色名称"></el-input>
							</el-form-item>
						</el-form>
						<el-row class="title">
							<span style="margin-right: 20px;">权限设置</span>
							<el-checkbox  v-model="menuExpand" @change="handleCheckedTreeExpand($event, 'menu')">展开/折叠
							</el-checkbox>
							<el-checkbox   v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event, 'menu')">全选/全不选
							</el-checkbox>
						</el-row>
						<div class="treeclass">
							<!-- :default-expanded-keys="openBranch" -->
							<el-tree ref="tree" v-if="role_id" :check-strictly="checkStrictly"  :default-expanded-keys="openBranch"
								:data="formLabelAlign.permission" show-checkbox node-key="permission_id"
								 @check-change="handleCheckChange" :props="defaultProps">
							</el-tree>
							<el-tree ref="tree" v-else :data="treeData" show-checkbox node-key="permission_id" :default-expanded-keys="openBranch"
								@check-change="handleCheckChange" :props="defaultProps">
							</el-tree>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="save-cont">
			<div class="save-btn" v-if="typedetail=='detail'" @click="toback()" size="small">返回</div>
			<div class="save-btn" v-else @click="submitForm('formLabelAlign')" size="small">提交</div>
		</div>
	</div>
</template>

<script>
	import {
		authoritysettingDetail,
		authoritysettingmenuList,
		authoritysettingModify,
		authoritysettingroleAdd
	} from '@/api/plat/setting.js'
	export default {
		name: 'looks',
		data() {
			return {
				labelPosition: 'right',
				defaultProps: {
					children: 'children',
					label: 'main_part_name'
				},
				role_id: this.$route.query.id,
				typedetail: this.$route.query.type,
				formLabelAlign: {
					role_nickname: '',
					permissionArray: [],
					state: 'Y',
					permission: []
				},
				ruleForm: '',
				data: [],
				selectIds: [],
				checkStrictly: false,
				treeData: [],
				openBranch:[],
				menuExpand: false,
				menuNodeAll: false,
			}
		},
		mounted() {
			if (this.role_id) {
				this.getDetail();
			} else {
				this.List();
			}


		},
		methods: {
			async List() { //获取权限列表
				const params = {}
				try {
					let data = await authoritysettingmenuList(params)
					this.treeData = data.data;
					
				} catch (error) {}
			},
	
			async submitForm(formLabelAlign ) { //保存
				this.$refs[formLabelAlign].validate((valid) => {
					if (valid) {
						if (this.role_id) {
							this.toModify()
						} else {
							this.Add()
						}
					} else {
						this.$message.error('请填写完整信息');
						return false;
					}
				});
			},
			// 树权限（展开/折叠）
			handleCheckedTreeExpand(value, type) {
				if(type == 'menu' && this.typedetail=='add'){
					let treeList = this.treeData;
					for (let i = 0; i < treeList.length; i++) {
						
						this.$refs.tree.store.nodesMap[treeList[i].permission_id].expanded = value;
					}
				}
				if (type == 'menu' && this.role_id) {
					let treeList =this.formLabelAlign.permission;
					//this.openTree(treeList,value)
					for (let i = 0; i < treeList.length; i++) {
						//console.log(this.$refs.tree.store.nodesMap[treeList[i].permission_id],"111")
						this.$refs.tree.store.nodesMap[treeList[i].permission_id].expanded = value;
					}
				}
			},
			// openTree(treeList,value){
			// 	var self = this;
			// 	treeList.forEach(item=>{
			// 		this.$refs.tree.store.nodesMap[item.permission_id].expanded = value;
			// 		if(item.children){
			// 			self.openTree(item.children)
			// 		}
			// 	})
			// 	return treeList;
			// 	// //获取传进来的
			// 	// var self = this;
			// 	// var ids = [];
			// 	// if (val.length !== 0) {
			// 	// 	val.forEach(item => {
			// 	// 			ids.push(item.permission_id);
			// 	// 		if (item.children) {
			// 	// 			ids.push([...self.branchTree(item.children)]);
			// 	// 		}
			// 	// 	});
			// 	// 	return ids.flat(Infinity);
			// 	// }
			// },
			// 树权限（全选/全不选）
			handleCheckedTreeNodeAll(value, type) {
				let that = this;
				if (type == 'menu' && this.role_id) {
					let branch= that.branchTree(this.formLabelAlign.permission);
					that.$refs.tree.setCheckedKeys(value ? branch : []);
				}
				if (type == 'menu' && this.typedetail=='add') {
					let branch= that.branchTree(this.treeData);
					that.$refs.tree.setCheckedKeys(value ? branch : []);
				}
			},
			//修改
			async toModify() {
				this.formLabelAlign.permissionArray = this.formLabelAlign.permissionArray.toString()
				const params = {
					role_id: this.$route.query.id,
					role_nickname: this.formLabelAlign.role_nickname,
					state: 'Y',
					permissionArray: this.formLabelAlign.permissionArray
				}
				try {
					let data = await authoritysettingModify(params)

					if (data.return_code === "SUCCESS") {
						this.$message({
							message: '修改成功！',
							type: 'success'
						});
						this.$router.push('/merchantsettingadmin/jurisdictionsetting/rolelist')
					}
				} catch (error) {}
			},
			toback(){
				this.$router.push('/merchantsettingadmin/jurisdictionsetting/rolelist')
			},
			//添加
			async Add() {
				this.formLabelAlign.permissionArray = this.formLabelAlign.permissionArray.toString();
				try {
					let data = await authoritysettingroleAdd(this.formLabelAlign)
					if (data.return_code === "SUCCESS") {
						this.$message({
							message: '添加成功，马上为您跳转！',
							type: 'success'
						});
						this.$router.push('/merchantsettingadmin/jurisdictionsetting/rolelist')
					}
				} catch (error) {}
			},
			async getDetail() {
				//查看详情
				const params = {
					role_id: this.$route.query.id
				}
				try {
					let data = await authoritysettingDetail(params)
					this.formLabelAlign = data.data;
					let that = this;
					that.checkStrictly = true //重点：给数节点赋值之前 先设置为true
					that.$nextTick(() => {
						let pp = that.changeTree(data.data.permission);
						//let branch= that.branchTree(data.data.permission);
						//this.openBranch=branch;
						that.$refs.tree.setCheckedKeys(pp);
						that.checkStrictly = false //重点： 赋值完成后 设置为false
					})
				} catch (error) {};
			},
			branchTree(val){
				//获取传进来的
				var self = this;
				var ids = [];
				if (val.length !== 0) {
					val.forEach(item => {
							ids.push(item.permission_id);
						if (item.children) {
							ids.push([...self.branchTree(item.children)]);
						}
					});
					return ids.flat(Infinity);
				}
			},
			changeTree(val) {
				//获取传进来的
				var self = this;
				var ids = [];
				if (val.length !== 0) {
					val.forEach(item => {
						if (item.exist == true) {
							ids.push(item.permission_id);
						}
						if (item.children) {
							ids.push([...self.changeTree(item.children)]);
						}
					});
					return ids.flat(Infinity);
				}
			},
			getCheckedKeys() {},
			handleCheckChange(data, checked, indeterminate) {
				this.mentId = this.$refs.tree
					.getCheckedKeys()
					.concat(this.$refs.tree.getHalfCheckedKeys())
				this.mentId = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())

				this.formLabelAlign.permissionArray = this.mentId.filter(function(item) {
					if (item !== undefined) {
						return item;
					}
				})
			},
			handleExpand() { //节点被展开时触发的事件
				//因为该函数执行在renderContent函数之前，所以得加this.$nextTick()
				// this.$nextTick(() => {
				// 	this.changeCss();
				// })
			},
			renderContent(h, {
				node,
				data,
				store
			}) { //树节点的内容区的渲染 Function
				let classname = "";
				// 由于项目中有三级菜单也有四级级菜单，就要在此做出判断
				if (node.level === 2) {
					classname = "foo";
				}
				// if (node.level === 1 && node.childNodes.length === 0) {
				// 	classname = "foo";
				// }
				return h(
					"p", {
						class: classname
					},
					node.label
				);
			},
		},


	}
</script>

<style lang="scss" scoped>
	.add {
		height: 100%;
		padding: 20px 60px;

		.title {
			height: 50px;
			left: 50px;
			font-size: 16px;
			color: #000;
		}

		::v-deep .el-textarea__inner {
			min-height: 150px !important;
		}

		.bottom {
			margin-left: 30%;
			margin-top: 10%;

			::v-deep .el-button {
				margin-right: 5%;
			}
		}
	}

	.storemain {
		background: #F2F2F6;
		border-radius: 4px;
		padding: 10px 10px;
		font-size: 14px;
	}

	.treeclass {
		background-color: #fff;
		padding: 30px 10px;
		margin-left: 8%;
		border-radius: 4px;
	}
	.pds{
		padding-bottom: 120px !important;
	}
</style>
