<template>
	<div>
		<div style="border-top: 10px solid #F2F2F6;">
			<div class="list">
				<el-row class="all_search_list" style="margin-bottom: 0; padding: 15px 15px;">
					<div class="demo-input-suffix">
						<el-button size="small" type="primary" @click="toAdd" v-has="'学院课程/课程类目/新建课堂类目'" >新建课堂类目</el-button>
					</div>
				</el-row>
				<el-table ref="llzc" :data="catData" :tree-props="{ children: 'children' }" row-key="college_course_id"
					stripe @expand-change="cellChange" border align="left"  max-height="550px" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"   :header-cell-style="{background:'#f3f8fe',color:'#606060'}">
					<el-table-column label="类目名称" prop="course_name"> </el-table-column>
					<el-table-column prop="is_show" label="是否显示">
						<template slot-scope="scope">
							<div class="el_show">
								<span style="line-height: 22px; font-size: 12px;">{{scope.row.is_show==2?'禁用':'启用'}}</span>
								<img style="vertical-align: bottom;" v-has="scope.row.is_show==2?'学院课程/课程类目/禁用':'学院课程/课程类目/启用'" 
									:src="scope.row.is_show == 2? require('../../../../../../assets/img/downs.png'): require('../../../../../../assets/img/ups.png')"
									@click="showOrhide(scope)" />
							</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" min-width="100">
						<template slot-scope="scope">
							<el-button @click="createtwoCat(scope)" type="text" size="small" v-if="scope.row.children" v-has="'学院课程/课程类目/新增子类目'" >
								新增子类目</el-button>
							  <el-button @click="toEdit(scope)" type="text" size="small" v-has="'学院课程/课程类目/编辑'" >编辑</el-button>
							 <el-button @click="toDels(scope.row)" type="text" size="small" v-has="'学院课程/课程类目/删除'" >删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<!--添加编辑类目-->
		<el-dialog :title="title" :visible.sync="dialogVisible " width="469px" @close="formCancel()">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
				<el-form-item label="类目名称" prop="course_name">
					<el-input size="small" v-model="form.course_name" placeholder="请输入类目名称" maxlength="5" show-word-limit></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="formCancel()">取 消</el-button>
				<el-button size="small" type="primary" @click="formSubmit('form')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		courseListPage,
		addFirstCourse,
		addSecondCourse,
		updateCourse,
		delCourse,
		courseInfo,
		updateCourseIsShow
	} from "@/api/plat/college.js"
	export default {
		data() {
			return {
				loading:true,
				catData: [],
				dialogVisible: false,
				title: '新建一级课程类目',
				form: {
					course_name: ''
				},
				rules: {
					course_name: [{
						required: true,
						message: '请填写类目名称',
						trigger: 'blur'
					}]
				},
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				college_course_parent_id: '',
				college_course_id: '',
				showState: false
			}
		},
		created() {
			//this.$on('categoryList',this.getCollegeCategoryList())
		},
		methods: {
			getCollegeCategoryList() {
				let data = {
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				courseListPage(data).then(res => {
					this.loading=false
					this.catData = res.data.list;
					this.total = res.data.count

				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getCollegeCategoryList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getCollegeCategoryList()
			},
			getInfo() {
				let data = {
					college_course_id: this.college_course_id
				}
				courseInfo(data).then(res => {
					this.form = res.data
				})
			},
			//新建课程
			toAdd() {
				this.title = '新建一级课程类目';
				this.form.course_name = '';
				this.dialogVisible = true
			},
			cellChange(e) {},
			// 显示隐藏分租
			showOrhide(row) {
				this.tools.confirm(row.row.is_show == 1?"确认要禁用此分类？":'确认要开启此分类？').then(res => {
					if (res) {
						updateCourseIsShow({
								college_course_id: row.row.college_course_id,
								is_show: row.row.is_show == 1 ? 2 : 1
							}).then((res) => {
								this.getCollegeCategoryList()
							})
							
					}
				})

			},
			// 新增二级分类
			createtwoCat(row) {
				this.college_course_parent_id = row.row.college_course_id;
				this.form.course_name = '';
				this.title = '新建二级课程类目';
				this.dialogVisible = true;

			},
			formCancel() {
				this.dialogVisible = false;
				this.showState = false;
			},
			// 编辑分类
			toEdit(row) {
				this.college_course_id = row.row.college_course_id;
				this.dialogVisible = true;
				this.title = '编辑课程类目';
				this.showState = true;
				this.getInfo()

			},
			// 删除分类
			toDels(row) {
				if (row.college_course_parent_id == 0 && row.children.length != 0) {
					this.$message.error("请先删除子分类");
					return;
				};
				this.tools.confirm("确认要删除？").then(res => {
					if (res) {
						let data = {
							college_course_id: row.college_course_id
						}
						delCourse(data).then(res => {
							this.getCollegeCategoryList();
							this.$message.success("删除成功");
						})
					}
				})
			},
			//确认添加名字
			formSubmit(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//添加一级类目
						let data = JSON.parse(JSON.stringify(this.form));
						console.log(this.college_course_parent_id, this.showState, "this.college_course_parent_id")
						if (this.college_course_parent_id) {
							//添加二级分类
							data.college_course_parent_id = this.college_course_parent_id;
							addSecondCourse(data).then(res => {
								this.getCollegeCategoryList();
								this.$message.success("添加二级分类成功");
								this.dialogVisible = false;
								this.college_course_parent_id = '';
							})
						} else if (this.showState == true) {
							data.college_course_id = this.college_course_id;
							updateCourse(data).then(res => {
								this.getCollegeCategoryList();
								this.$message.success("编辑成功");
								this.dialogVisible = false;
								this.showState = false
							})
						} else {
							addFirstCourse(data).then(res => {
								this.getCollegeCategoryList();
								this.$message.success("添加一级分类成功");
								this.dialogVisible = false;

							})
						}
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style>
</style>
