<template>
	<div>
		<div class="wrapper-cont">
			<div class="main-cont bgwt" style="padding: 20px;">
				<el-row :gutter="20">
					<el-col :span="4">
						<div class="grid-content bg-purple">
							<div class="all_search_list" style="margin-bottom: 0px;">
								<div class="demo-input-suffix" v-if="check.is_checked==1">
									<el-button size="small" @click="batchOpera('3')"  v-has="'服务商审核/批量同意'" >批量同意</el-button>
									<el-button size="small" @click="batchOpera('2')" v-has="'服务商审核/批量拒绝'" >批量拒绝</el-button>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="12" :offset="6">
						<div class="grid-content bg-purple">
							<div class="all_search_list" style="margin-bottom: 0px;">
								<div class="demo-input-suffix">
									<el-select style="width: 150px !important;" size="small"
										v-model="check.is_checked" class="inputWidth" @change="toChange" placeholder="全部状态">
										<el-option label="待审核" value="1"></el-option>
										<el-option label="已拒绝" value="2"></el-option>
										<el-option label="通过" value="3"></el-option>
									</el-select>
								</div>
								<div class="demo-input-suffix">
									<el-input prefix-icon="el-icon-search" class="inputWidth" size="small" placeholder="名称/注册手机号"
										v-model="check.search" @keyup.enter.native="toSearch" clearable>
									</el-input>
								</div>
								<div class="demo-input-suffix">
									<el-button type="primary" size="small" @click="toSearch">查询</el-button>
									<el-button size="small" @click="toReset">重置</el-button>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<!--表格-->
			<div class="tableMain">
				<el-table :data="checkData" style="width: 100%" min-height="250" max-height="550" border v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"  :header-cell-style="{background:'#f3f8fe',color:'#606060'}" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55" align="center">
					</el-table-column>
					<el-table-column prop="username" label="服务商名称" align="center">
					</el-table-column>
					<el-table-column prop="account" label="手机号" align="center">
					</el-table-column>
					<el-table-column prop="address" label="服务商区域" align="center">
					</el-table-column>
					<el-table-column prop="channel_info.channel_name" label="服务商等级" align="center">
					</el-table-column>
					<el-table-column prop="opening_amount" label="金额" align="center">
					</el-table-column>
					<el-table-column prop="earnest_money" label="储值金额" sortable align="center">
					</el-table-column>
					<el-table-column  label="状态" align="center">、
						<template slot-scope="scope">
							<span v-if="scope.row.is_checked == 1">审核中</span>
							<span v-else-if="scope.row.is_checked == 2">拒绝</span>
						</template>
					</el-table-column>
					<el-table-column prop="created_at" label="创建时间">
					</el-table-column>
					<el-table-column label="操作" width="200" align="center">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="checkDetail(scope.row)"  v-has="'服务商审核/查看'" >查看</el-button>
							<el-button type="text" size="small" v-if="scope.row.is_checked=='1'" @click="toCheck(scope.row,'3')" v-has="'服务商审核/通过'" >通过</el-button>
							<el-button type="text" size="small" v-if="scope.row.is_checked=='1'" @click="toCheck(scope.row,'2')" v-has="'服务商审核/拒绝'" >拒绝</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
			</div>
		</div>
		<!--拒绝原因弹框-->
		<el-dialog title="拒绝原因" :visible.sync="dialogDescVisible" width="25%" :before-close="handleClose">
			<div>
				<el-input type="textarea" placeholder="请输入内容" v-model="desc_txt" rows="4" maxlength="50"
					show-word-limit>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogDescVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitCheck" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
			getChannelList,
			auditChannelType,
			batchAuditChannelType
		}  from '@/api/plat/business'
	export default{
		data(){
			return{
				loading:true,
				desc_txt:'',
				check: {
					is_checked:'1',
					search: ''
				},
				checkData: [],
				//拒绝原因弹框
				dialogDescVisible: false,
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				multipleSelection:[],
				account_channel_id:'',
				idsstatus:''
			}
		},
		mounted() {
		},
		methods:{
			//审核列表
			getList(){
				let data=this.check;
				data.page = this.currentPage;
				data.perPage=this.pagesize;
				data=this.tools.filterParams(data);
				getChannelList(data).then(res=>{
					this.loading=false;
					this.total=res.data.count;
					this.checkData=res.data.list;
					this.checkData.forEach(item=>{
						item.begin_time=this.tools.timeFormate(item.begin_time*1000);
						item.end_time=this.tools.timeFormate(item.end_time*1000);
						item.address = item.province+item.city+item.county;
						item.earnest_money = item.capital_info.earnest_money;
					})
				})
			},
			//搜索
			toSearch(){
				this.currentPage=1;
				this.getList()
			},
			toReset(){
				this.currentPage=1;
				this.check.search='';
				this.check.is_checked='1';
				this.getList()
			},
			toChange(val){
				this.check.is_checked=val;
				this.getList()
			},
			//批量操作
			  handleSelectionChange(val) {
					let ids=[]
					val.forEach(item=>{
						ids.push(item.account_channel_id)
					})
					this.multipleSelection =ids
			  },
			  batchOpera(type){
				  //type==2是拒绝  3是同意
				  if(this.multipleSelection.length<=0){
					  this.$message.error("请选择数据");
					  return;
				  }
				  if(type == 2){
					  this.desc_txt='';
					  this.account_channel_id = this.multipleSelection.toString();
					  this.dialogDescVisible = !this.dialogDescVisible;
					  this.idsstatus=1
				  }else{
					let data={
						  account_channel_id:this.multipleSelection.toString(),
						  is_checked:3,
					}  
					batchAuditChannelType(data).then(res=>{
						this.getList()
					})
				  }
				 
			  },
			//审核分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//审核查看
			checkDetail(row) {
				this.$router.push({
					path: "/clientadmin/agentmanagement/agentcheckdetail",
					query:{
						id:row.account_channel_id
					}
				})
			},
			//审核
			toCheck(row,type){
				if(type==3){
					let data={
						account_channel_id:row.account_channel_id,
						is_checked:3
					}
					auditChannelType(data).then(res=>{
						this.getList()
					})
				}
				if(type==2){
					 this.desc_txt='';
					this.account_channel_id = row.account_channel_id
					this.dialogDescVisible = !this.dialogDescVisible
				}
				
			},
			//提交审核
			submitCheck(){
				let data={
					account_channel_id:this.account_channel_id,
					is_checked:2,
					checked_reason:this.desc_txt
				}
				if(this.idsstatus ==1){
					batchAuditChannelType(data).then(res=>{
						this.dialogDescVisible = !this.dialogDescVisible
						this.getList();
						this.idsstatus='';
					})
				}else{
					auditChannelType(data).then(res=>{
						this.dialogDescVisible = !this.dialogDescVisible
						this.getList();
						this.desc_txt=''
					})
				}
				
			},
			handleClose() {
				this.desc_txt='';
				this.dialogDescVisible = !this.dialogDescVisible
			},
		}
	}
</script>

<style>
</style>
