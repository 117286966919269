<template>
	<div>
		<div class="wrapper-main">
			<CattleGoodEduit :activeName="activeName"></CattleGoodEduit>
		</div>
	</div>
</template>
<script>
	import CattleGoodEduit from '../../../../../pagecomponents/cattleGoodEduit.vue'
	export default {
		components: {
			CattleGoodEduit
		},
		data() {
			return {
				activeName:this.$route.query.activeName
			}
		},
		methods: {



		}

	}
</script>

<style lang="scss" scoped="scoped">

</style>