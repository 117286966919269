<template>
	<div>
		<div class="wrapper-main">
			<el-form :model="form" ref="form"  label-width="150px" class="demo-ruleForm">
				<div class="wrapper-cont">
					<div class="pmargin10">
						<div class="setTitle">基础信息</div>
						<el-form-item label="标识码:">
							<el-input type="text" class="inputWidth" placeholder="请输入标识码" size="small"
								v-model="form.identification_code"></el-input>
						</el-form-item>
						<el-form-item label="手机号:" prop="account">
							<el-input type="text" class="inputWidth" maxlength="11" placeholder="请输入手机号" size="small"
								v-model.number="form.account"></el-input>
						</el-form-item>
						
					</div>
				</div>
				<div class="wrapper-cont mt10">
					<div class="pmargin10">
						<div class="setTitle">业务信息</div>
						<el-form-item label="服务商名称:" prop="username">
							<el-input type="text" class="inputWidth" placeholder="请输入服务商名称" size="small"
								v-model="form.username"></el-input>
						</el-form-item>
						<el-form-item label="服务商所属地区:" prop="address">
							<el-cascader size="small" placeholder="服务商所属地区" style="width:300px;" :options="options"
								collapse-tags :props="{multiple: false }" clearable 
								v-model="form.address" ref="cascader">
							</el-cascader>
						</el-form-item>
					</div>
				</div>
				<div class="wrapper-cont mt10">
					<div class="pmargin10">
						<div class="setTitle">等级信息</div>
						<el-form-item label="服务商等级:" prop="channel_level">
							<el-select  size="small" v-model="form.channel_level"
								class="inputWidth" placeholder="请选择">
								<el-option v-for="(item,index) in levelList" :key="index" :label="item.channel_name" :value="item.unique_identification"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="实付金额:" >
							<el-input type="text" class="inputWidth" placeholder="请输入实付金额" size="small"
								v-model.number="form.opening_amount"></el-input>
						</el-form-item>
						<el-form-item label="启用时间:" prop="due_dt">
							<el-date-picker style="width: 350px" size="small" v-model="form.due_dt"
								type="daterange" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期" ></el-date-picker>
						</el-form-item>
					</div>
				</div>
				<div class="wrapper-cont mt10">
					<div class="pmargin10">
						<div class="setTitle">联系信息</div>
						<el-form-item label="企业联系人:" >
							<el-input type="text" class="inputWidth" placeholder="请输入企业联系人" size="small"
								v-model="form.contact_name"></el-input>
						</el-form-item>
						<el-form-item label="手机号:"  prop="contact_phone">
							<el-input type="text" class="inputWidth" placeholder="请输入手机号" size="small"
								v-model.number="form.contact_phone"></el-input>
						</el-form-item>
					</div>
				</div>
			</el-form>
		</div>
		<div class="save-cont">
			<div class="cancel-btn" @click="rejective">返回</div>
			
		</div>

	</div>
</template>

<script>
	import {getChannelAccountInfo,channelAgentLevel}  from '@/api/plat/business'
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	export default {
		data() {
			return {
				dialogDescVisible:false,
				desc_txt:'',
				form: {
					identification_code: '',
					account: '',
					username: '',
					address: [],
					channel_level:'',
					opening_amount:'',
					due_dt:'',
					contact_phone:'',
					contact_name:''
				},
				options: regionData,
				
				
				account_channel_id:this.$route.query.id,
				levelList:[]
			}
		},
		mounted() {
			this.getInfo();
			this.getChannelAgentLevel()
		},
		methods: {
			//获取服务商等级
			getChannelAgentLevel(){
				let data={}
				channelAgentLevel(data).then(res=>{
					this.levelList=res.data
				})
			},
			getInfo(){
				let data={
					account_channel_id:this.account_channel_id
				}
				getChannelAccountInfo(data).then(res=>{
					this.form=res.data;
					this.form.account=Number(this.form.account);
					this.form.contact_phone=Number(this.form.contact_phone);
					let addressData;
					addressData = res.data.province_code+','+ res.data.city_code+','+ res.data.county_code;
					addressData = addressData.split(",");
					this.form.address = addressData;
					this.form.due_dt=[this.tools.timeFormate(res.data.begin_time*1000),this.tools.timeFormate(res.data.end_time*1000)];
				})
			},
		
	
			//返回上一步
			rejective(){
				this.$router.go(-1);
			},
		}
	}
</script>

<style>
</style>
