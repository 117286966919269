<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-button size="small" @click="addIndustry" v-has="'新建行业'">新建行业</el-button>
				</div>
				<div class="main-cont bgwt mt10">
					<el-table ref="multipleTable" border :data="tableData" style="width: 100%"  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="trade_name" label="行业名称" align="center">
						</el-table-column>
						<el-table-column label="是否启用" align="center">
							<template slot-scope="scope">
								 <el-button type="text" size="small" class="el_edits">
									<div v-show="scope.row.state == '0'" class="el_state"
										@click="upOrdown(scope.row, 'open')" v-has="'禁用行业'">
										禁用行业
										<img src="../../../../../assets/img/downs.png" style="margin-left: 10px;" />
									</div>
									<div v-show="scope.row.state == '1'" class="el_state"
										@click="upOrdown(scope.row, 'close')" v-has="'启用行业'">
										启用行业<img src="../../../../../assets/img/ups.png" style="margin-left: 10px;" />
									</div>
								</el-button>
							</template>
						</el-table-column>
						<el-table-column label="是否配置样板店" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.system_shop_relevance == 0">
									否
								</span>
								<span v-else>
									是
								</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center" fixed="right">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toEdit(scope.row)" v-has="'编辑'">编辑
								</el-button>
								<el-button type="text" size="small" @click="toDel(scope.row)" v-has="'删除'">删除
								</el-button>
								<el-button type="text" size="small"  v-if="scope.row.system_shop_relevance == 0"  v-has="'绑定样板店'" @click="toBind(scope.row)">绑定样板店</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
		<!--商品弹框-->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
			<el-form :model="form" :rules="rules" ref="form" label-width="150px" class="demo-ruleForm">
				<el-form-item label="行业名称" prop="trade_name">
					<el-input class="inputWidth" v-model="form.trade_name" size="small"></el-input>
				</el-form-item>
				<el-form-item label="样板店"  v-if="system_shop_relevance==1">
					
					<el-select size="small" v-model="shop_id">
						<el-option v-for="(item,index) in marketingShop" :key="index" :label="item.shop_name" :value="item.shop_id">
							{{item.shop_name}}
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="行业状态">
					<el-radio v-model="form.state" label="1">启用</el-radio>
					<el-radio v-model="form.state" label="0">隐藏</el-radio>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitForm('form')" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!--绑定关系-->
		<el-dialog title="样板店关联关系" :visible.sync="dialogBindVisible" width="30%">
			<el-form :model="bindform" :rules="bindrules" ref="bindform" label-width="150px" class="demo-ruleForm">
				<el-form-item label="样板店" prop="shop_id">
					<el-select size="small" v-model="bindform.shop_id">
						<el-option v-for="(item,index) in marketingShop" :key="index" :label="item.shop_name" :value="item.shop_id">
							{{item.shop_name}}
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogBindVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitBindForm('bindform')" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getMarketingTradePageList,
		onTrade,
		offTrade,
		addMarketingTrade,
		editMarketingTrade,
		getMarketingTradeInfo,
		delMarketingTrade,
		getComposingMarketingShop,
		saveComposingMarketingShop
	} from '@/api/plat/market'
	export default {
		data() {
			return {
				loading:true,
				tableData: [{
					industry_name: '服装',
					isuse: '1'
				}],
				dialogVisible: false,
				form: {
					trade_name: '',
					state: '1',
					

				},
				title:'添加行业名称',
				shop_id:'',
				rules: {
					trade_name: [{
						required: true,
						message: '请输入行业名称',
						trigger: 'blur'
					}, ],
				},
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				marketing_trade_id: '',
				dialogBindVisible: false,
				marketingShop: [],
				system_shop_relevance:'',
				bindform: {
					shop_id: '',
				},
				bindrules: {
					shop_id: [{ required: true, message: '请选择样板店', trigger: 'change' }],
				},
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let params = {
					page: this.currentPage,
					perPage: this.pagesize
				}
				getMarketingTradePageList(params).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list;
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//详情
			getInfo() {
				let params = {
					trade_id: this.marketing_trade_id
				}
				getMarketingTradeInfo(params).then(res => {
					this.form = res.data;
					this.form.state = this.form.state.toString()

				})
			},
			// 上架或下架
			upOrdown(row, type) {
				if (type == 'open') {
					this.tools.confirm("确认要启用？").then(res => {
						if (res) {
							//调接口
							onTrade({
								trade_id: row.marketing_trade_id
							}).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.getList()
								}
							})
						}
					})
				}
				if (type == 'close') {
					this.tools.confirm("确认要禁用？").then(res => {
						if (res) {
							//调接口
							offTrade({
								trade_id: row.marketing_trade_id
							}).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.getList()
								}
							})
						}
					})
				}

			},
			//新建行业
			addIndustry() {
				this.title='添加行业名称';
				this.shop_id='';
				this.form.trade_name = '';
				this.system_shop_relevance='';
				this.form.state = '1';
				this.marketing_trade_id='';
				this.dialogVisible = !this.dialogVisible
			},
			//编辑
			toEdit(row) {
				this.title='编辑行业名称';
				this.shop_id='';
				this.dialogVisible = !this.dialogVisible;
				this.marketing_trade_id = row.marketing_trade_id;
				this.system_shop_relevance=row.system_shop_relevance;
				console.log(this.system_shop_relevance,"this.system_shop_relevance")
				if(this.system_shop_relevance==1){
					this.shop_id=row.shop_id
					this.getComposingMarketingShopData();
				}
				this.getInfo()
			},
			//删除
			toDel(row) {
				this.tools.confirm("确认要删除？").then(res => {
					if (res) {
						//调接口
						delMarketingTrade({
							trade_id: row.marketing_trade_id
						}).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.getList()
							}

						})
					}
				})
			},
			//
			getComposingMarketingShopData() {
				getComposingMarketingShop({}).then(res => {
					this.marketingShop = res.data
				})
			},
			//绑定样板店
			toBind(row) {
				this.getComposingMarketingShopData();
				this.dialogBindVisible = true;
				this.bindform.shop_id='';
				this.marketing_trade_id = row.marketing_trade_id;
			},
			//添加行业提交
			submitForm(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data = JSON.parse(JSON.stringify(this.form));
						data.trade_id = this.marketing_trade_id;
						data.shop_id=this.shop_id;
						data = this.tools.filterParams(data);
						if (this.marketing_trade_id) {
							editMarketingTrade(data).then(res => {
								this.$message.success("编辑成功")
								this.dialogVisible = !this.dialogVisible;
								this.marketing_trade_id = '';
								this.getList()
							})
						} else {
							addMarketingTrade(data).then(res => {
								this.$message.success("添加成功")
								this.dialogVisible = !this.dialogVisible;
								this.getList()
							})
						}
					} else {
						//console.log('error submit!!');
						return false;
					}
				});
			},
			//绑定样板店
			submitBindForm(bindform) {
				this.$refs[bindform].validate((valid) => {
					if (valid) {
						//调接口
						let data = JSON.parse(JSON.stringify(this.bindform));
							data.marketing_trade_id = this.marketing_trade_id;
							data = this.tools.filterParams(data);
							saveComposingMarketingShop(data).then(res => {
								this.$message.success("绑定成功")
								this.dialogBindVisible = !this.dialogBindVisible;
								this.getList()
							})
						
					} else {
						//console.log('error submit!!');
						return false;
					}
				});
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.el_state {
		display: flex;
		align-items: center;
		color: #666666;

		img {
			width: 42px;
			height: 22px;
			margin-left: 6px;
		}
	}
</style>
