<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-tabs class="tabstyle" v-model="status" type="card" @tab-click="handleClick" v-has>
					<el-tab-pane label="全部订单" name="-1"></el-tab-pane>
					<el-tab-pane label="已完成" name="5"></el-tab-pane>
					<el-tab-pane label="交易关闭" name="6"></el-tab-pane>
				</el-tabs>
                <el-card class="box-card mt10" shadow="never">
                    <el-row class="all_search_list" style="margin-bottom:0">
                        <div class="demo-input-suffix">
                            <el-input clearable class="inputsWidth" prefix-icon="el-icon-search" v-model="order_number"
                                placeholder="请输入订单编号" size="small" type="text">
                            </el-input>
                        </div>
                        <div class="demo-input-suffix">
                            <el-select style="width: 150px;" size="small" v-model="order_ganre" placeholder="请选择订单类型"
                                filterable clearable>
                                <el-option label="实物商品" value="1"></el-option>
                                <el-option label="虚拟商品" value="2"></el-option>
                                <el-option label="会员套餐" value="3"></el-option>
                            </el-select>
                        </div>
                        <div class="demo-input-suffix">
                            <el-button type="primary" size="small" @click="searchOrder">查询</el-button>
                            <el-button size="small" @click="toReset">重置</el-button>
                        </div>
                    </el-row>
                </el-card>
                <!-- <div class="main-cont mt10"> -->
                    <el-table :data="tableData" border style="width: 100%" class="mt10" v-loading="loading"
                        element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.2)" max-height="550px"
                        :header-cell-style="{background:'#f3f8fe',color:'#606060'}">
                        <el-table-column label="订单编号" align="center" prop="order_number" width="220"></el-table-column>
                        <el-table-column label="订单类型" align="center">
                            <template slot-scope="scope">
                                    <p v-if="scope.row.order_ganre==1">实物商品</p>
                                    <p v-else-if="scope.row.order_ganre==2">虚拟商品</p>
                                    <p v-else>/</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="实付金额" prop="totalMoney" align="center"></el-table-column>
                        <el-table-column label="商品总价" align="center" prop="goods_price"></el-table-column>
                        <el-table-column label="支付方式" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.payment_type == 0">未调起</span>
                                <span v-else-if="scope.row.payment_type == 1">微信</span>
                                <span v-else-if="scope.row.payment_type == 2">支付宝</span>
                                <span v-else-if="scope.row.payment_type == 3">余额</span>
                                <span v-else-if="scope.row.payment_type == 4">组合-微信</span>
                                <span v-else-if="scope.row.payment_type == 5">组合-支付宝</span>
                                <span v-else>/</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="用户信息"  align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.userInfo">
                                    <p>{{scope.row.userInfo.username}}</p>
                                    <p>{{scope.row.userInfo.mobile}}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" prop="created_at" align="center">
                        </el-table-column>
                        <el-table-column label="订单状态"  align="center">
                            <template slot-scope="scope">
                               <span v-if="scope.row.status==1">待支付</span>
                               <span v-else-if="scope.row.status==2">已支付</span>
                               <span v-else-if="scope.row.status==3">待收货</span>
                               <span v-else-if="scope.row.status==4">已收货</span>
                               <span v-else-if="scope.row.status==5">已完成</span>
                               <span v-else-if="scope.row.status==6">已关闭</span>
                               <span v-else>/</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" @click="toDetail(scope.row)" v-has="'详情'">详情</el-button>
                            </template>
						</el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10,20,30,50]"
                        :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>
<script>
  import {
        shopOrderList
} from "@/api/plat/order.js"
export default {
    data() {
        return {
            order_number:'',
            status:'-1',
            order_ganre:'',
            tableData: [],
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
            loading: true,

        }
    },
    mounted() {
			this.getList()
		},
    methods: {
        getList() {
            let data = {
                page: this.currentPage,
                perPage: this.pagesize,
                status:this.status == -1 ? '' : this.status,
                order_number: this.order_number,
                order_ganre:this.order_ganre
            }
            shopOrderList(data).then(res => {
                this.loading = false;
                this.total = res.data.count;
                this.tableData = res.data.list;
            })
        },
        handleClick(val) {
				this.currentPage = 1;
				this.order_number = '';
				this.order_ganre = '';
                if (val.name) {
                this.status = val.name
                } else {
                    this.status = ''
                }
				this.getList()
			},
        searchOrder(){
            this.currentPage = 1;
            this.getList()
        },
        toReset(){
            this.currentPage = 1;
            this.status = '';
            this.order_number = '';
            this.order_ganre = '';
            this.getList()
        },
        handleSizeChange(size) {
            this.pagesize = size;
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getList()
        },
        //去详情
        toDetail(row){
            this.$router.push({
					path: '/orderadmin/storeorder/orderdetail',
					query: {
						id: row.order_number
					}
				})
        }
    }
}
</script>
<style lang="less" scoped>
@import "@/assets/css/plat/order.scss";

</style>