<template>
	<div>
		<div class="wrapper-main pp20">
			<div class="main-cont" style="padding: 0!important;margin-bottom: 20px;">
				<div class="all_search_list" style="margin-bottom: 0;">
					<el-input prefix-icon="el-icon-search" clearable v-model="keyword" style="width: 200px!important;"
						placeholder="营销名称" size="small" type="text"  @keyup.enter.native="toSearch">
					</el-input>
					<el-select v-model="is_buy" size="small" style="margin-left: 10px;" placeholder="请选择">
						<el-option label="已购买" value="1"></el-option>
						<el-option label="未购买" value="2"></el-option>
						<el-option label="全部" value="0"></el-option>
					</el-select>
					<el-button style="margin-left: 10px;" type="primary" size="small"  @click="toSearch">搜索</el-button>
					<el-button size="small" @click="toReset">重置</el-button>
				</div>
			</div>
			<el-tabs v-model="activeName"> <!-- @tab-click="handleClick" -->
			    <el-tab-pane label="营销插件" name="first">
					<div class="itemList">
						<ul class="items">
							<li v-for="(item,index) in marketList"  :key="index">
								<div class="market-cont">
									<div class="marketflex">
										<div class="iconimg">
											<div class="tjimg">
												<img class="timg" v-if="item.is_recommend ==1" src="../../../../../../assets/img/plat/market/market_tj.png" />
											</div>
											<img :src='item.backstage_image' />
										</div>
										<div class="intro">
											<p class="title">{{item.title}}</p>
											<p >{{item.description}}</p>
										</div>
									</div>
								</div>
								<div style="overflow: hidden;">
									<div  v-if="item.is_buy==1"  class="opens">已开启{{item.have_opened}}家</div>
									<div class="switchstate" v-if="item.is_buy==1">
										<img style="vertical-align: middle;" v-if="item.down_status==2"
											:src="item.status==2? require('../../../../../../assets/img/ups.png'): require('../../../../../../assets/img/downs.png')"
											 />
										<img style="vertical-align: middle;" v-else
											:src="item.status==2? require('../../../../../../assets/img/ups.png'): require('../../../../../../assets/img/downs.png')"
											@click="changeSwitch(item)" />
										<span style="line-height: 22px; font-size: 12px;">{{item.status==2?'启用':'禁用'}}</span>
									</div>
								</div>
								<div v-if="item.is_buy==2" class="buttons" @click="toBuy(item)">立即订购</div>
								<div v-if="item.is_buy==1" class="biaos">已购买</div>
								<div v-if="item.down_status==2" class="biaos down">平台下架</div>
							</li>
					
						</ul>
					</div>
				</el-tab-pane>
			  </el-tabs>
		</div>
	</div>
</template>

<script>
	import {twitterList,serverTwitterUpState} from "@/api/channel/market"
	export default{
		data(){
			return{
				is_buy:'',
				keyword:'',
				activeName:'first',
				marketList: [],
			}
		},
		mounted() {
			this.getList()
		},
		methods:{
			getList(){
				let data={
					title:this.keyword,
					is_buy:this.is_buy
				}
				data=this.tools.filterParams(data);
				twitterList(data).then(res=>{
					this.marketList=res.data;
					// this.marketList.forEach(item=>{
					// 	if (item.status == 1) {
					// 		item.status = true
					// 	} else if (item.status == 2) {
					// 		item.status = false
					// 	}
					// })
					
				})
			},
			changeSwitch(item){
				this.tools.confirm(item.status == 2?"确认要禁用？":"确认要启用？").then(res => {
					if (res) {
						let data={
							state:item.status==2?1:2,
							plugin_id:item.plugin_id
						}
						serverTwitterUpState(data).then(res=>{
							this.getList()
						})
					}
				});
				
			},
			//搜索
			toSearch(){
				this.getList()
			},
			//重置
			toReset(){
				this.keyword='';
				this.is_buy='';
				this.getList()
			},
			//tab切换
			handleClick(val){
				
			},
			//立即购买
			toBuy(item){
				this.$router.push({
					path:'/marketingchanneladmin/marketingchannel/marketingdetail',
					query:{
						plugin_id:item.plugin_id
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	::v-deep.el-tabs__nav-wrap::after {
	    content: "";
	    position: absolute;
	    left: 0;
	    bottom: 0;
	    width: 100%;
	    height: 2px;
	    background-color: #FFFFFF;
	    z-index: 1;
	}
	.itemList {
		width: 100%;
	}
	
	.items {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	
	.items li {
		list-style: none;
		padding: 10px 10px;
		border-radius: 4px;
		min-height: 36px;
		background: #F7FBFF;
		border: 1px solid #2589FF;
		width: 351px;
		margin-right:20px;
		cursor: pointer;
		margin-bottom: 30px;
		position: relative;
	}
	.biaos{
		position: absolute;
		right: 0;
		top:0;
		width: 70px;
		height: 25px;
		line-height: 25px;
		font-size: 14px;
		color: #fff;
		text-align: center;
		background: #36B400;
		border-radius: 2px 4px 2px 10px;
	}
	.biaos.down{
		background: #DCDFE6;
		color: #666;
	}
	.market-cont{
		overflow: hidden;
	}
	.marketflex {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		float: left;
		img {
			width:50px;
			height: 50px;
			vertical-align: middle;
		}
		.iconimg{
			// width: 60px;
			// height: 60px;
			vertical-align: middle;
			position: relative;
			img {
				width: 50px;
				height: 50px;
				vertical-align: middle;
			}
		}
		.tjimg{
			position: absolute;
			top: 5px;
			left:4px;
			width: 21px;
			height: 19px;
			.timg{
				width: 100%;
				height: 100%;
			}
		}
		.intro {
			font-size: 14px;
			color: #999;
			margin-left: 15px;
			line-height: 20px;
			.title {
				font-size: 14px;
				color: #333;
				line-height: 20px;
	
	
			}
		}
	}
	.switchstate{
		float: right;
	}
	.opens{
		font-size: 14px;
		color: #999;
		height: 28px;
		line-height: 28px;
		float: left;
	}
	.buttons{
		width: 90px;
		height: 28px;
		background: #4185F4;
		border-radius: 2px;
		border: 1px solid #0091FF;
		color: #FFFFFF;
		font-size: 14px;
		line-height: 28px;
		text-align: center;
		float: right;
		margin-right: 20px;
	}
	.open-count{
		margin-top:10px;
		height: 20px;
		font-size: 14px;
		font-weight: 400;
		color: #333333;
		line-height: 20px;
	}
</style>
