<template>
	<div>
		<div class="wrapper-main">
			<el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small" class="demo-ruleForm">
				<div class="wrapper-cont pp20">
					<el-form-item label="选择商品:" prop="course_cover">
						<div class="img_blos">
							<div class="img_blo" @click="uploadImg()">
								<img :src="form.course_cover" class="item_img" v-if="form.course_cover" style="margin-right: 0!important;"/>
								<div class="plusImg" v-else>
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="活动起止时间:" prop="start_time">
						<el-date-picker v-model="form.start_time" type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" @change="startTimeStatus"
							:picker-options="pickerOptionsStart" style="margin-right: 10px;">
						</el-date-picker>
						至
						<el-date-picker v-model="form.end_time" type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" @change="endTimeStatus"
							:picker-options="pickerOptionsEnd" style="margin-left: 10px;">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="课程节数:">{{videoNumber}}</el-form-item>
					<el-form-item label="课程售价:">{{selling_price}}</el-form-item>
					<el-form-item label="秒杀价格:" prop="seconds_price">
						<el-input class="w180" oninput="value=value.replace(/[^\d.]/g,'')" v-model="form.seconds_price">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</div>
				<div class="wrapper-cont pp20 mt10">
					<div class="setTitle">规则设置</div>
					<el-form-item label="订单取消:" prop="close_time">
						<el-input class="w180" oninput="value=value.replace(/[^\d.]/g,'')" v-model="form.close_time">
							<template slot="append">分钟</template>
						</el-input>
						<p class="imgtxtstyle">注：规定时间内未支付订单，订单将自动取消，不得小于15分钟（秒杀商品与其他商品同时下单时，取消时间以秒杀商品为准）</p>
					</el-form-item>
					<el-form-item label="预热开始时间:">
						<el-date-picker v-model="form.preheating_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
							placeholder="选择日期时间" @change="preheatTimeStatus" style="margin-right: 10px;">
						</el-date-picker>
						<p class="imgtxtstyle">到预热开始时间可在首页和详情页展示秒杀预告，未到预热时间，不展示活动预告。预热开始时间不填时，默认从当前开始预热。</p>
					</el-form-item>
					<el-form-item label="商品状态:">
						<el-radio v-model="form.status" label="1">上架</el-radio>
						<el-radio v-model="form.status" label="2">下架</el-radio>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<div class="save-cont" v-if="btnState=='detail'">
			<div class="cancel-btn" @click="cancelSet">返回</div>
		</div>
		<div class="save-cont" v-else-if="btnState=='edit' || btnState=='add'">
			<div class="cancel-btn" @click="cancelSet">取消</div>
			<div class="save-btn" @click="submitSet('form')">提交</div>
		</div>
		<!--商品弹框-->
		<el-dialog title="选择视频课程" :visible.sync="dialogVisible" width="55%" @close="cancelClose">
			<el-row class="all_search_list" style="margin-bottom: 0;">
				<div class="demo-input-suffix">
					<el-cascader size="small" v-model="search.course_List" :options="allCate" placeholder="课程分类"
						:props="{ expandTrigger: 'hover',value:'college_course_id',label:'course_name',children:'children'}">
					</el-cascader>
				</div>
				<div class="demo-input-suffix">
					<el-input prefix-icon="el-icon-search" v-model="search.course_title" placeholder="请输入商品名称" size="small"
						type="text">
					</el-input>
				</div>
				<div class="demo-input-suffix">
					<el-button size="small" type="primary" slot="append" @click="searchOrder()">搜索
					</el-button>
					<el-button size="small" @click="toReset">重置</el-button>
				</div>
			</el-row>
			<el-table border :data="tableData" ref="multipleTable" highlight-current-row
				@current-change="handleCurrent" style="width: 100%;margin-top:15px">
				<el-table-column width="55" align="center">
					<template slot-scope="scope">
						<div class="tableradio">
							<el-radio :label="scope.row.college_curriculum_id" v-model="current_college_course_id"
								></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="course_title" label="课程名称" align="center">
				</el-table-column>
				<el-table-column prop="course_name" label="课程类目" align="center">
				</el-table-column>
				<el-table-column prop="videoNumber" label="课程节数" align="center">
				</el-table-column>
				<el-table-column prop="selling_price" label="课程价格" align="center">
				</el-table-column>
				<el-table-column prop="created_at" label="添加时间" align="center">
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelClose" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddGood" size="small">确 定</el-button>
			</span>
			<div style="background-color: #F2F2F6; height: 10px;"></div>
			<!-- 分页 -->
			<div class="pagecurrent" style="margin-top: 0 !important;">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		courseList,
		getCollegeCurriculumList,
		saveSecondsKill,
		getSecondsKillInfo
	} from "@/api/plat/college.js"
	export default {
		data() {
			var checkCount = (rule, value, callback) => {
				if (!value) {
					callback(new Error('订单取消时间不能为空'));
				} else if (value < 15) {
					callback(new Error('不得小于15分钟'));
				} else {
					callback();
				}
			};
			var salePrice= (rule, value, callback) => {
				if (!value) {
					callback(new Error('秒杀价格不能为空'));
				} else if (value ==0) {
					callback(new Error('秒杀价格必须大于0'));
				} else {
					callback();
				}
			};
			return {
				radio: '',
				form: {
					course_cover: '',
					start_time: '',
					end_time: '',
					seconds_price: '', //件数
					status: '1',
					close_time: '',
					preheating_time: '',
				},
				rules: {
					course_cover: [{
						required: true,
						message: '请选择商品',
						trigger: 'blur'
					}],
					close_time: [{
						validator: checkCount,
						trigger: 'blur',
						required: true,
					}],
					preheating_time: [{
						required: true,
						message: '请选择预热时间',
						trigger: 'blur'
					}],
					seconds_price: [{
						required: true,
						validator: salePrice,
						trigger: 'blur'
					}],
					start_time: [{
						required: true,
						message: '请选择活动起止时间',
						trigger: 'blur'
					}],
				},
				// 开始和结束日期转换的时间戳
				startTime: null,
				endTime: null,
				preheatTime: null,
				/* start 开始时间小于今天,结束时间不能大于开始时间 */
				//time.getTime() < Date.now()-8.64e7 今天之前的时间不能选
				pickerOptionsStart: {
					disabledDate: time => {
						if (this.form.end_time) {
							return time.getTime() < Date.now() - 8.64e7 || time.getTime() > this.form
								.end_time;
						} else {
							return time.getTime() < Date.now() - 8.64e7; //当前时间之后
						}
					}
				},
				pickerOptionsEnd: {
					disabledDate: time => {
						// return time.getTime() < Date.now() - 8.64e7 || time.getTime() < this.form.start_time;
						if (this.form.start_time) {
							return time.getTime() < new Date(this.form.start_time).getTime()
						} else {
							return time.getTime() < Date.now() - 8.64e7; //当前时间之后
						}
					},
				},
				dialogVisible: false,
				//商品弹框
				search: {
					course_List:[],
					course_title:''
				},
				allCate: [],
				tableData: [], // 表格数据
				multipleSelection: [],
				current_college_course_id: '',
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				currentRow:null,
				selling_price:'',
				videoNumber:'',
				college_curriculum_seconds_id:this.$route.query.id,
				btnState:this.$route.query.btnState,
			}
		},
		mounted() {
			if(this.college_curriculum_seconds_id){
				this.getInfo();
			}
		},
		methods: {
			getInfo(){
				let data={
					college_curriculum_seconds_id:this.college_curriculum_seconds_id
				}
				getSecondsKillInfo(data).then(res=>{
					this.form=res.data;
					this.videoNumber=res.data.videoNumber;
					this.selling_price=res.data.selling_price;
					this.form.status=this.form.status.toString();
					this.current_college_course_id=res.data.college_curriculum_id.toString();
					
				})
			},
			//课程类目
			getCourseType() {
				courseList({}).then(res => {
					this.allCate = res.data
				})
			},
			////获取商品列表
			getGoodsList() {
				let data = {
					course_title:this.search.course_title,
					college_course_id_one:this.search.course_List[0]?this.search.course_List[0]:'',
					college_course_id_two:this.search.course_List[1]?this.search.course_List[1]:'',
					course_type	:2,
					status:1,
					is_free:'N',
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				getCollegeCurriculumList(data).then(res => {
					this.tableData = res.data.list;
					this.tableData.forEach(item=>{
						item.college_curriculum_id=item.college_curriculum_id.toString()
					})
					this.total = res.data.count;
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getGoodsList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getGoodsList()
			},
			//点击表格中每一行也能选中单选按钮,选择改行数据   
			handleCurrent(row) {
				//如果没有row,终止
				if (!row) return
				//把当前行label绑定的值和v-model绑定的值相同时,单选按钮就可以选中
				this.current_college_course_id = row.college_course_id
				this.currentRow = row;
			},
			// 时间开始选择器
			startTimeStatus(val) {
				let date = new Date(val).getTime();
				this.startTime = date;
				if (this.endTime) {
					if (this.endTime < this.startTime) {
						this.$message.error('开始时间不能大于结束时间');
						this.form.start_time = '';
						this.startTime = null;
					}
				}
			},
			// 时间结束选择器
			endTimeStatus(val) {
				let date = new Date(val).getTime();
				this.endTime = date;
				if (this.startTime) {
					if (this.endTime <= this.startTime) {
						this.$message.error('结束时间不能小开始时间');
						this.form.end_time = '';
						this.endTime = null;
					}
				}
			},
			//预热时间
			preheatTimeStatus(val) {
				let date = new Date(val).getTime();
				this.preheatTime = date;
				if (this.startTime) {
					if (this.preheatTime >= this.startTime) {
						this.$message.error('预热时间不能大于活动开始时间');
						this.form.preheating_time = '';
						this.preheatTime = null;
					}
				}
				if (this.form.start_time) {
					if (this.preheatTime >= new Date(this.form.start_time).getTime()) {
						this.$message.error('预热时间不能大于活动开始时间');
						this.form.preheating_time = '';
						this.preheatTime = null;
					}
				}
			},
			uploadImg() {
				this.dialogVisible = !this.dialogVisible;
				this.currentPage=1;
				this.getGoodsList();
				this.getCourseType();
				
				// if(!this.college_curriculum_seconds_id){
				// 	this.current_college_course_id=''
				// }
			},
			//搜索商品
			searchOrder() {
				this.currentPage = 1;
				this.getGoodsList()
			},
			toReset() {
				this.search.course_title = '';
				this.search.course_List = [];
				this.currentPage=1;
				this.getGoodsList()
			},
			sureAddGood() {
				this.dialogVisible = false;
				this.form.course_cover=this.currentRow.course_cover;
				this.videoNumber=this.currentRow.videoNumber;
				this.selling_price=this.currentRow.selling_price;
			},
			cancelClose(){
				this.dialogVisible = false;
				// if(!this.college_curriculum_seconds_id){
				// 	this.current_college_course_id=''
				// }
			},
			
			//取消设置
			cancelSet() {
				this.$router.push({
					path: "/marketingadmin/businesscollege/businesscollegeindex",
					query: {
						activeName: 'second'
					}
				})
			},
			//提交设置
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data=JSON.parse(JSON.stringify(this.form));
						if(this.college_curriculum_seconds_id){
							data.college_curriculum_seconds_id=this.college_curriculum_seconds_id
						};
						data.start_time=(new Date(data.start_time).getTime())/1000;
						data.end_time=(new Date(data.end_time).getTime())/1000;
						data.preheating_time=(new Date(data.preheating_time).getTime())/1000;
						data.college_curriculum_id=this.current_college_course_id?this.current_college_course_id:this.currentRow.college_curriculum_id;
						data=this.tools.filterParams(data);
						delete data["course_cover"];
						saveSecondsKill(data).then(res=>{
							if(this.college_curriculum_seconds_id){
								this.$message.success("编辑成功")
							}else{
								this.$message.success("添加成功")
							}
							this.$router.push({
								path: "/marketingadmin/businesscollege/businesscollegeindex",
								query: {
									activeName: 'second'
								}
							})
						})
						

					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	::v-deep.tableradio .el-radio {
		margin-left: 6px;
	}

	::v-deep.tableradio .el-radio .el-radio__label {
		display: none;
	}

	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.el_img {
			width: 31px;
			height: 31px;
			margin-right: 6px;
		}
	}

	.priceIput::v-deep.el-input-group__append {
		padding: 0 8px;
	}

	.opera-cont {
		position: relative
	}

	.popovers {
		position: absolute;
		z-index: 999;
	}
</style>
