<template>
	<div style="border-radius: 10px; padding: 10px; background-color: #fff;">

		<div style="border-radius: 10px; padding: 5px; background-color: #F2F2F6;">
			<div class="wrapper-main" style="margin-bottom: 30px;background-color: #F2F2F6; ">
				<div class="model-content">
					<div class="specialmain">
						<div class="box">
							<span style="margin-right: 30px;">商户名称：</span><span>{{ userInfo.user_name }}</span>
						</div>
						<div class="box">
							<span style="margin-right: 30px;">收款方式</span><span> <el-radio v-model="radio"
									label="1">连连支付分账</el-radio></span>
						</div>
						<div class="box">
							<span style="margin-right: 30px;">商户名称：</span><span>{{ userInfo.user_status_name }}</span>
						</div>
						<div class="box" style="display: flex;">
							<div style="margin-right: 30px;">分账账号</div>

							<div v-if="userInfo.linked_acctlist.length==0">
								<el-button type="primary" size="small" @click="centerDialogVisible = true">开启连连账号</el-button>
							</div>
							
								<div else-if>
								<span>连连用户编码:{{ userInfo.oid_userno }}</span>
								<div>银行卡：{{ userInfo.linked_acctlist[0].linked_acctno }}</div>
								<div>银行：{{ userInfo.linked_acctlist[0].linked_brbankname }}</div>
								<div>手机号：{{ userInfo.linked_acctlist[0].linked_phone }}</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class="save-cont">
				<div class="save-btn">提交</div>
			</div>
			<!-- 获取验证码弹窗 -->
			<el-dialog title="" :visible.sync="centerDialogVisible" width="30%" center>
				<el-form :model="form" :rules="rules" ref="form" label-width="0px" class="ms-content">
					<el-form-item prop="phone" class="yzm">
						商户手机号：<el-input v-model="form.phone" style="padding-left:0px;width: 200px;" clearable maxlength="11"
							placeholder="请输入手机号">
						</el-input>
					</el-form-item>
					<el-form-item prop="code" class="yzm">
						<div class="codeitem" style="padding-left: 29px;">
							验证码：<el-input class="inputcode" maxlength="6" placeholder="请输入验证码" v-model="form.code"
								style="width: 200px;">
							</el-input>
							<el-button type="text" v-show="show" @click="getMerchantCode" style="margin-left: 20px;">获取验证码</el-button>
							<span v-show="!show" class="codebtn" style="margin-left: 20px;">{{ count }} s后可重新获取</span>
						</div>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="nextTo">下一步</el-button>
				</span>
			</el-dialog>


			<!-- 填写信息弹窗 -->
			<el-dialog title="" :visible.sync="infoDialogVisible" width="30%" center>
				<el-form :model="formInfo" ref="formInfo" :rules="formInfoRules" label-width="100px" class="demo-formInfo">

					<el-form-item label="身份类型" label-width="150px">
						<el-radio v-model="radio" label="1">企业</el-radio>
					</el-form-item>

					<el-form-item label="企业名称" prop="user_name" label-width="150px">
						<el-input size="small" placeholder="企业名称" style="width: 80%!important;" clearable
							v-model="formInfo.user_name"></el-input>
					</el-form-item>

					<el-form-item label="营业执照:" prop="businessLicense" label-width="150px" style="width: 180px !important;">
						<!-- <div class="img_blos">
										<div class="img_blo"@click="uploadImg('businessLicense')" >
											<img :src="formInfo.businessLicense" class="item_img" v-if="formInfo.businessLicense" style="margin-right: 0!important;" />
											<div class="plusImg" v-else>
												<i class="el-icon-plus avatar-uploader-icon"></i>
											</div>
											
										</div>
									</div> -->

						<!-- <el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="beforeUpload" >
							<img v-if="form.avatar" :src="form.avatar" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload> -->

						<!-- <el-upload  action="#" :auto-upload="false" :show-file-list="false"
						:before-upload="changePictureUploadIDFront">
							<img v-if="formInfo.businessLicense" :src="formInfo.businessLicense" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon" />
						</el-upload> -->

						<el-upload action="javascript:;" :data="paramData" name="file" class="upload-demo" :show-file-list="false"
							:on-success="handleSuccess" multiple :before-upload="OnChange">
							<!-- <el-button  size="small" type="primary">点击上传</el-button> -->
							<img v-if="formInfo.businessLicense" :src="formInfo.businessLicense" class="avatar" style="width: 100%;">
							<i v-else class="el-icon-plus avatar-uploader-icon" style="margin-top: 50px;" />
						</el-upload>

					</el-form-item>

					<el-form-item label="统一社会信用代码证" prop="unified_code" label-width="150px">
						<el-input size="small" placeholder="统一社会信用代码证" style="width: 80%!important;" clearable
							v-model="formInfo.unified_code"></el-input>
					</el-form-item>

					<el-form-item label="企业地址" prop="address" label-width="150px">
						<el-input size="small" placeholder="请输入企业地址" style="width: 80%!important;" clearable
							v-model="formInfo.address"></el-input>
					</el-form-item>

					<el-form-item label="有效期" label-width="150px" prop="codeValidity">
						<!-- <el-radio v-model="radio2" value="1">存在有效期</el-radio>
						<el-radio v-model="radio2" value="2">长期有效</el-radio> -->

						<el-radio v-model="radio" label="1">
							<span style="font-size: 14px;margin-left: 0;">存在有效期</span>
						</el-radio>
						<el-radio v-model="radio" label="2"><span style="font-size: 14px;margin-left: 0;">长期有效</span>
						</el-radio>

					</el-form-item>

					<el-form-item label="" label-width="150px" style="margin-top: -20px;" v-if="radio2 == 1">
						<el-date-picker v-model="formInfo.id_exp" type="date" placeholder="选择过期日期" style="width: 80%;"
							value-format="yyyyMMdd">
						</el-date-picker>
					</el-form-item>

					<el-form-item label="企业邮箱" prop="reg_email" label-width="150px">
						<el-input size="small" placeholder="请输入企业邮箱" style="width: 80%!important;" clearable
							v-model="formInfo.reg_email"></el-input>
					</el-form-item>

					<el-form-item label="绑定银行卡" prop="bind_type" label-width="150px">

					</el-form-item>


					<el-form-item label="绑定类型" label-width="150px">
						<el-radio v-model="radio2" label="1">对公银行卡</el-radio>
						<!-- <el-radio v-model="radio2" label="2">长期有效</el-radio> -->
					</el-form-item>

					<el-form-item label="银行" prop="bank" label-width="150px">
						<el-select size="small" v-model="formInfo.linked_bankcode" allow-create filterable>
							<el-option v-for="(item, index) in bankList" :key="index" :label="item.bank_name" :value="item.bank_code">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="银行开户行" prop="bankAccount" label-width="150px">
						<el-cascader style="width: 80%;" size="small" class="labelWidth" :options="options" collapse-tags
							:props="{ multiple: false }" clearable @change="handleChange" v-model="formInfo.bankAccount" ref="cascader">
						</el-cascader>
					</el-form-item>

					<el-form-item label="" prop="bind_type" label-width="150px" style="margin-top: -20px;">
						<el-input size="small" placeholder="请输入开户行名称" style="width: 80%!important;" clearable
							v-model="formInfo.sort"></el-input>
					</el-form-item>
					<!-- 
					<el-form-item label="开户行行号" prop="bind_type" label-width="150px">
						<el-button type="primary" size="small">查询</el-button>
					</el-form-item> -->

					<el-form-item label="银行卡号" prop="linked_acctno" label-width="150px">
						<el-input size="small" placeholder="请输入银行卡号" style="width: 80%!important;" clearable
							v-model="formInfo.linked_acctno"></el-input>
					</el-form-item>

					<el-form-item label="对公账号名称" prop="linked_acctname" label-width="150px">
						<el-input size="small" placeholder="请输入对公账号名称" style="width: 80%!important;" clearable
							v-model="formInfo.linked_acctname"></el-input>
					</el-form-item>

					<!-- <el-form-item label="设置密码" prop="setPassword" label-width="150px">
						<div @click="setPasd">
							<el-input size="setPassword" placeholder="请设置密码" style="width: 80%!important;" clearable
							v-model="formInfo.sort"  ></el-input>
						</div>
					</el-form-item> -->

					<el-form-item label="法定代表人信息" prop="bind_type" label-width="150px">
					</el-form-item>

					<el-form-item label="法人名称" prop="legalrept_name" label-width="150px">
						<el-input size="small" placeholder="请输入法人名称" style="width: 80%!important;" clearable
							v-model="formInfo.legalrept_name"></el-input>
					</el-form-item>

					<el-form-item label="法人手机号" prop="legalrept_phone" label-width="150px">
						<el-input size="small" placeholder="请输入法人手机号" style="width: 80%!important;" clearable
							v-model="formInfo.legalrept_phone"></el-input>
					</el-form-item>

					<el-form-item label="法人身份证号" prop="legalrept_idno" label-width="150px">
						<el-input size="small" placeholder="请输法人身份证号" style="width: 80%!important;" clearable
							v-model="formInfo.legalrept_idno"></el-input>
					</el-form-item>

					<el-form-item label="法人身份证正反照:" prop="image" label-width="150px">
						<!-- <div style="display: flex;padding: 0 10px;box-sizing: border-box;">
							<div class="img_blos">
							<div  class="img_blo" style="margin-bottom:15px">
								<img :src="formInfo.identityCard" class="item_img" @click="handlePictureCardPreview(item)" />
							</div>
							<div class="plusImg" @click="uploadImg('identityCard')" style="position: relative;">
								<i class="el-icon-plus avatar-uploader-icon"></i>
								<div style="position: absolute;bottom: -75px;left: 35px;">人像面</div>
							</div>
						</div>

						<div class="img_blos">
							<div v-for="(item, index) in formInfo.image" :key="index" class="img_blo" style="margin-bottom:15px">
							</div>
							<div class="plusImg" @click="uploadImg('goods')" style="position: relative;">
								<i class="el-icon-plus avatar-uploader-icon"></i>
							<div style="position: absolute;bottom: -75px;left: 35px;">国徽面</div>
							</div>
						
						</div>
						</div> -->
						<div style="display: flex;padding: 0 10px;box-sizing: border-box;width: 80%;">
							<div style="position: relative;">
								<el-upload action="javascript:;" :data="paramData" name="file" class="upload-demo" :show-file-list="false"
									:on-success="handleSuccess" multiple :before-upload="OnChange2">
									<!-- <el-button  size="small" type="primary">点击上传</el-button> -->
									<img v-if="formInfo.identityCard" :src="formInfo.identityCard" class="avatar" style="width: 100%;">
									<i v-else class="el-icon-plus avatar-uploader-icon" style="margin-top: 50px;" />
								</el-upload>
								<div style="position: absolute;bottom: -20px;left:37px">人面像</div>
							</div>
							<div style="width: 50px;"></div>

							<div style="position: relative;">
								<el-upload action="javascript:;" :data="paramData" name="file" class="upload-demo" :show-file-list="false"
									:on-success="handleSuccess" multiple :before-upload="OnChange3">
									<!-- <el-button  size="small" type="primary">点击上传</el-button> -->
									<img v-if="formInfo.reverseCard" :src="formInfo.reverseCard" class="avatar" style="width: 100%;">
									<i v-else class="el-icon-plus avatar-uploader-icon" style="margin-top: 50px;" />
								</el-upload>
								<div style="position: absolute;bottom: -20px;left:37px">国徽像</div>
							</div>

						</div>

					</el-form-item>

					<el-form-item label="有效期" label-width="150px" style="margin-top:50px" prop="cardValidity">
						<el-radio v-model="radio2" label="1">
							<span style="font-size: 14px;margin-left: 0;">存在有效期</span>
						</el-radio>
						<el-radio v-model="radio2" label="2"><span style="font-size: 14px;margin-left: 0;">长期有效</span>
						</el-radio>
					</el-form-item>

					<el-form-item label="" label-width="150px" style="margin-top: -20px;" v-if="radio2 == 1">
						<el-date-picker v-model="formInfo.legalnept_idexp" type="date" placeholder="选择过期日期" style="width: 80%;"
							value-format="yyyyMMdd">
						</el-date-picker>
					</el-form-item>

				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="centerDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="openacct_apply_enterprise">确 定</el-button>
				</span>
			</el-dialog>

			<!-- 验证码弹窗 -->
			<el-dialog title="提示" :visible.sync="yzmDialogVisible" width="30%" center  v-loading="loading">

				<div class="setup">
					<div class="password">
						<input type="text" placeholder="请输入验证码" class="verify_code" v-model="verify_code"/>
					</div>

					<div class="password">
						<p>请设置密码</p>
						<!-- <input type="text" placeholder="请输入6位数密码" id="password" /> -->
						<span id="password_text" @click="setPass">请输入6位数密码</span>
					</div>
				</div>
				<!-- <span slot="footer" class="dialog-footer">
					<el-button @click="yzmDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="yzmDialogVisible = false">确 定</el-button>
				</span> -->
			</el-dialog>

			<!-- 选择图片弹窗 -->
			<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
			<!--图片放大-->
			<el-dialog :visible.sync="dialogImgVisible">
				<img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {
	regionData,
	CodeToText,
	TextToCode
} from "element-china-area-data";

import ChooseImg from '@/components/common/uploadImgs.vue';

import  lianlianpay from '../../../../../utils/lianlian.js';

import {
	query_userinfo,
	openacct_apply_individual,
	regphone_verifycode_verify,
	upload,
	bankcode,
	openacct_apply_enterprise,
	apply_password_element,
	openacct_verify_enterprise,
} from "@/api/merchant/finance.js"
import { isvalidPhone } from '@/utils/validate'
var validPhone = (rule, value, callback) => {
	if (!value) {
		callback(new Error('请输入手机号'))
	} else if (!isvalidPhone(value)) {
		callback(new Error('请输入正确的11位手机号码'))
	} else {
		callback()
	}
};
export default {
	components: {
		ChooseImg
	},
	data() {
		return {
			value1: '',
			radio: "1",
			radio2: "1",
			centerDialogVisible: false,
			infoDialogVisible: false,//信息弹窗
			options: regionData,
			dialogImgVisible: false,
			dialogImageUrl: '',
			yzmDialogVisible: false,//验证码弹窗
			uploadType: '',
			uploadFlag: false, //是否展示选择图片弹窗
			loading:true,
			paramData: {},
			imgFileList: '',
			form: {
				phone: "",
				code: ''
			},
			formInfo: {
				identityCard: '',
				reverseCard: '',
				businessLicense: '',
				unified_code: '',
				address: '',
				codeValidity: '',
				reg_email: '',
				linked_bankcode: '',
				bankAccount: '',
				linked_acctno: '',
				linked_acctname: '',
				setPassword: '',
				legalrept_name: '',
				legalrept_phone: '',
				legalrept_idno: '',
				image: '',
				cardValidity: '',
				user_name:'',
				legalnept_idexp:'',
				id_no: '',
				id_portrait:'',
				id_emblem:'',
			},
			doc_id:'',
			doc_id2:'',
			doc_id3:'',
			rules: {
				phone: [
					{ required: true, trigger: 'blur', validator: validPhone }
				],
				code: [{
					required: true,
					message: '请输入验证码',
					trigger: 'blur'
				}],
			},
			formInfoRules: {
				user_name: [
					{ required: true, message: '请填写企业名称', trigger: 'blur' }
				],
				businessLicense: [
					{ required: true, message: '请上传营业执照图', trigger: 'blur' }
				],
				unified_code: [
					{ required: true, message: '请填写统一社会信用代码证', trigger: 'blur' }
				],
				address: [
					{ required: true, message: '请填写企业地址', trigger: 'blur' }
				],
				codeValidity: [
					{ required: true, message: '请选择代码证有效期', trigger: 'blur' }
				],
				reg_email: [
					{ required: true, message: '请填写邮箱地址', trigger: 'blur' }
				],
				linked_bankcode: [
					{ required: true, message: '请选择银行', trigger: 'change' }
				],
				bankAccount: [
					{ required: true, message: '请选择银行', trigger: 'blur' }
				],
				linked_acctno: [
					{ required: true, message: '请填写银行卡号', trigger: 'blur' }
				],
				linked_acctname: [
					{ required: true, message: '请填写对公账号名称', trigger: 'blur' }
				],
				// setPassword: [
				// 	{ required: true, message: '请设置密码', trigger: 'blur' }
				// ],
				legalrept_name: [
					{ required: true, message: '请填写法人名称', trigger: 'blur' }
				],
				legalrept_phone: [
					{ required: true, message: '请填写法人名称', trigger: 'blur' }
				],
				legalrept_idno: [
					{ required: true, message: '请填写法人身份证号', trigger: 'blur' }
				],
				image: [
					{ required: true, message: '请上传身份证正反照', trigger: 'blur' }
				],
				cardValidity: [
					{ required: true, message: '请选择身份证有效期', trigger: 'blur' }
				],
			},
			show: true,
			curIndex: 0,
			timer: '',
			count: '',
			userInfo: {},
			bankList:[],
			oid_partner:'',
			password_element_token:'',
			ret_code:'',
			user_id:'',
			verify_code:'',
			// user_status_name:'',
			// oid_userno: '',
			// user_id: '',
		}
	},
	mounted() {
		this.query_userinfo()
		this.bankcode()
	},
	methods: {
		setPass(){
			this.yzmDialogVisible = false;	//密码弹窗
			// 以下参数通过服务端获取
			lianlianpay.invokePasswordControl({
				passwordScene: 'setting_password',
				oidPartner: this.oid_partner, // 商户号
				userId: this.user_id,//getQueryString('user_id'), // 用户号
				passwordElementToken:this.password_element_token, //getQueryString('token'), // 向服务端请求生成的 token
				env: 'production', // 默认 production-生产
				styles: { // 样式集合
					themeColor: '#66b1ff', // 主题颜色（按钮等）
				}
			}, (res) => {
				console.log('密码数据', res)
				this.paddwords(res.data)
			})
		},

		paddwords(res){	//密码
			let data = {
				token:this.password_element_token,
				verify_code:this.verify_code,
				password:res.password,
				random_key:res.randomKey
			}
			// 接口（）.then()
			openacct_verify_enterprise(data).then(res=>{

				this.$message('提交成功!')
			})
		},

		openacct_apply_enterprise(){
			if(this.radio==2){
				this.formInfo.id_exp = 99991231;
			}
			if(this.radio2==2){
				this.formInfo.legalnept_idexp = 99991231;
			}
			this.formInfo.reg_phone=this.form.phone || '18038684069';

			openacct_apply_enterprise(this.formInfo).then(res=>{
				this.infoDialogVisible = false;	//信息弹窗
			this.yzmDialogVisible = true;
			let id = localStorage.getItem('account_id')
			apply_password_element({terminal:'shop',account_id:id,user_id:id}).then(res=>{
				this.loading=false;
				this.oid_partner = res.data.oid_partner;
				this.password_element_token = res.data.password_element_token;
				this.ret_code = res.data.ret_code;
				this.user_id = res.data.user_id;
			})
				
			})
		
		},
		setPasd(){
			this.yzmDialogVisible=true
		},
		bankcode(){
			bankcode({}).then(res=>{
				console.log(res);
				this.bankList=res.data
			})
		},

		OnChange(file) {
			// console.log("file",file.type)
			const isLt5M = file.size / 1024 / 1024 < 5;
			const isJPG = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg'].includes(file.type);
			if (!isLt5M) {
				this.$message.error("上传图片大小不能超过 5MB!");
				return false
			}
			if (!isJPG) {
				this.$message.error("请上传正确的文件格式!");
				return false
			}
			if (isLt5M && isJPG) {
				const fd = new FormData();
				fd.append('file', file)//当前为
				this.paramData.ossResourceClassifyId && fd.append('ossResourceClassifyId', this.paramData.ossResourceClassifyId);
				upload(fd).then(res => {
					// console.log(res.data,this.imgList,"7777")
					// this.imgList = [...res.data, ...this.imgList]
					this.formInfo.businessLicense = res.data.image
					this.formInfo.id_no=res.data.doc_id
				})
			}

			return false
		},
		OnChange2(file) {
			// console.log("file",file.type)
			const isLt5M = file.size / 1024 / 1024 < 5;
			const isJPG = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg'].includes(file.type);
			if (!isLt5M) {
				this.$message.error("上传图片大小不能超过 5MB!");
				return false
			}
			if (!isJPG) {
				this.$message.error("请上传正确的文件格式!");
				return false
			}
			if (isLt5M && isJPG) {
				const fd = new FormData();
				fd.append('file', file)//当前为
				this.paramData.ossResourceClassifyId && fd.append('ossResourceClassifyId', this.paramData.ossResourceClassifyId);
				upload(fd).then(res => {
					// console.log(res.data,this.imgList,"7777")
					// this.imgList = [...res.data, ...this.imgList]
					this.formInfo.identityCard = res.data.image
					this.formInfo.id_portrait=res.data.doc_id
				})
			}

			return false
		},
		OnChange3(file) {
			// console.log("file",file.type)
			const isLt5M = file.size / 1024 / 1024 < 5;
			const isJPG = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg'].includes(file.type);
			if (!isLt5M) {
				this.$message.error("上传图片大小不能超过 5MB!");
				return false
			}
			if (!isJPG) {
				this.$message.error("请上传正确的文件格式!");
				return false
			}
			if (isLt5M && isJPG) {
				const fd = new FormData();
				fd.append('file', file)//当前为
				this.paramData.ossResourceClassifyId && fd.append('ossResourceClassifyId', this.paramData.ossResourceClassifyId);
				upload(fd).then(res => {
					// console.log(res.data,this.imgList,"7777")
					// this.imgList = [...res.data, ...this.imgList]
					this.formInfo.reverseCard = res.data.image
					this.formInfo.id_emblem =res.data.doc_id
				})
			}

			return false
		},

		handleSuccess(){

		},
		query_userinfo() {
			query_userinfo({}).then(res => {
				console.log(res,'userInfo');
				this.userInfo = res.data
			})
		},
		// 点击取消
		chooseCancel(val) {
			this.uploadFlag = val;
		},
		// 点击确定
		chooseOk(val) {
			if (this.uploadType == 'identityCard') {
				this.formInfo.identityCard = val[0].resource_name;
			}
			if (this.uploadType == 'reverseCard') {
				this.formInfo.reverseCard = val[0].resource_name;
			}
			if (this.uploadType == 'businessLicense') {

				upload({ file: val[0] }).then(res => {
					this.formInfo.businessLicense = val[0].resource_name;
				})


			}

			this.uploadFlag = false;
		},
		// 选择图片
		uploadImg(type, parIndex, index) {

			this.uploadType = type;
			this.uploadFlag = !this.uploadFlag;
		},
		//删除图片
		delImg(index, type) {
			if (type == 'goods') {
				this.form.image.splice(index, 1);
			}
			if (type == 'details') {
				this.form.detailImgs.splice(index, 1);
			}
			if (type == 'bind_data') {
				this.form.bind_data = '';
			}
			if (type == 'bind_data_a') {
				this.form.bind_data_a = '';
			}
		},
		handlePictureCardPreview(file) {
			// console.log("11111111=============",file);

			this.dialogImageUrl = file;
			this.dialogImgVisible = true;
		},
		handleChange(area) {

			// console.log(area,'000000000000000000000000');
			
			if (area) {
				// this.formInfo.province_id = area[0];
				// this.formInfo.city_id = area[1];
				// this.formInfo.district_id = area[2];
				// this.formInfo.address = CodeToText[this.formInfo.province_id] + '-' + CodeToText[this.formInfo
				// 	.city_id] + '-' + CodeToText[this.formInfo.district_id]
			}
		},
		getMerchantCode() {

			this.$refs.form.validate((valid) => {
				if (valid) {
					var reg = /^1[3456789]\d{9}$/;
					if (this.form.phone.length != 11 && !reg.test(this.form.username)) {
						this.$message.error("请输入正确的手机号码格式");
						return;
					}
				}
			})

			const TIME_COUNT = 60;
			if (!this.timer) {
				this.count = TIME_COUNT;
				this.show = false;
				this.timer = setInterval(() => {
					if (this.count > 0 && this.count <= TIME_COUNT) {
						this.count--;
					} else {
						this.show = true;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000)
			}

			openacct_apply_individual({ reg_phone: this.form.phone }).then(res => {
			})

		},
		nextTo() {
			//调接口
			regphone_verifycode_verify({ reg_phone: this.form.phone, verify_code: this.form.code }).then(res => {

				this.infoDialogVisible = true


			})

			// this.centerDialogVisible = false
			// this.infoDialogVisible=true
		}
	}
}
</script>

<style lang="scss" scoped>
input:focus {
	outline: none;
}

::v-deep .yzm .el-form-item__error {
	color: #F56C6C;
	font-size: 12px;
	line-height: 1;
	padding-top: 4px;
	position: absolute;
	top: 100%;
	left: 84px !important;
}

::v-deep .el-upload--text {
	width: 115px !important;
	height: 115px !important;
}

.store-container {
	padding-bottom: 60px;
	font-size: 14px;
	font-weight: 500;
	background-color: #F2F2F6;
	height: 0px;
	box-sizing: border-box;
	padding-top: 25px;
	padding-left: 20px;
	border-radius: 10px;
	// line-height: 40px;
}

.specialmain {
	background: #fff;
	border-radius: 4px;
	padding: 20px 40px;
	font-size: 14px;
	height: 650px;

	.box {
		margin: 50px 0;
		font-weight: 700;
		font-size: 14px;

	}
}

.password {
	padding: 0 16px 5px;
	height: auto;
	background: #fff;
	margin-top: 16px;
	overflow: hidden;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
}

.password p {
	font-size: 15px;
}

.password input {
	width: 100%;
	height: 30px;
	border: none;
}

.password span {
	font-size: 14px;
	color: #999;
}</style>
