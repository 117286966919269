<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="order-search" style="margin-top: 0;">
					<el-row class="all_search_list" style="margin-bottom:0">
						<div class="demo-input-suffix">
							<el-select size="small" v-model="search.userType" class="w150" placeholder="请选择用户角色">
								<el-option label="游客" value="1"></el-option>
								<el-option label="直推" value="2"></el-option>
								<el-option label="间推" value="3"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-input prefix-icon="el-icon-search" clearable v-model="search.search"
								placeholder="昵称/手机号"
								size="small" type="text" @keyup.enter.native="searchOrder" >
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder">搜索</el-button>
							<el-button size="small" @click="toResetOrder">重置</el-button>
						</div>
					</el-row>
				</div>
				<div class="main-cont">
					<el-table ref="multipleTable" :data="tableData" border tooltip-effect="dark"   max-height="550" style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column prop="account" label="用户手机号" align="center"></el-table-column>
						<el-table-column prop="username" label="客户" align="center"></el-table-column>
						<!-- <el-table-column prop="shop_level_info.shop_level_name" label="角色" align="center"></el-table-column> -->
						<!-- <el-table-column  label="类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.sex == 1">男</span>
								<span v-else-if="scope.row.sex == 2">女</span>
								<span v-else>未知</span>
							</template>
						</el-table-column> -->
						<el-table-column prop="opening_amount" label="开通金额" sortable align="center"></el-table-column>
						<el-table-column prop="created_at" label="创建时间" sortable align="center"></el-table-column>
					</el-table>
					<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {getAccountShopRelevance} from "@/api/merchant/users.js"
	export default {
		data() {
			return {
				loading:true,
				search: {
					userType: '1',
					search: ''
				},
				tableData: [],
				multipleSelection: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList(){
				let params=this.search;
				params.page=this.currentPage;
				params.perPage=this.pagesize;
				params=this.tools.filterParams(params);
				getAccountShopRelevance(params).then(res=>{
					this.loading=false;
					this.tableData=res.data.list;
					this.tableData.forEach(item=>{
						if(item.shop_level_info==null){
							item.role="游客"
							
						}else{
							item.role=item.shop_level_info.shop_level_name
						}
					})
					this.total=res.data.count
					
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 调接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 调接口
				this.getList()
			},
			//搜索
			searchOrder(){
				this.currentPage = 1;
				//调接口
				this.getList()
			},
			toResetOrder(){
				this.currentPage = 1;
				this.search.userType='1';
				this.search.search='';
				this.getList()
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
