<template>
	<div>
		<div class="wrapper-main pds">
			<div class="model-content">
				<div class="specialmain">
					<div class="tips">
						<p>认证说明：</p>
						<p>1. 认证之后的店铺可解除免费版用户访问H5数量限制、提高账户安全等级、优先获得运营支持；</p>
						<p>2. 提供3种帐号主体认证类型，请根据实际情况任选一种；</p>
						<p>3. 选择个人认证类型时，填写基本信息。</p>
					</div>
					<div class="main-cont">
						<div class="title">立即认证</div>
						<div class="rztype">
							<span>认证类别</span>
							<div class="radio-cont">
								<el-radio v-model="certificationType" label="1">企业</el-radio>
								<el-radio v-model="certificationType" label="2">个体工商户</el-radio>
							</div>
						</div>
						<div class="title" style="margin-top:10px">公司基本信息</div>
					<!-- 	<div class="order-state">
							<ul>
								<li v-for="(item,index) in statesteplist">
									<div class="statestep">
										<span class="circleNum" :class="{active : active == item.name}" @click="selected(item.name)">{{item.num}}</span>
										<span class="statetext">{{item.name}}</span>
										<span class="stateLine"></span>
									</div>
								</li>
							</ul>
						</div> -->
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="250px" class="demo-ruleForm">
							<div v-if="certificationType == '1'">
								<el-form-item label="企业所在地:" >
									<el-input class="labelWidth" placeholder="企业所在地" :disabled="isshow" v-model="address" size="small"></el-input>
								</el-form-item>
								<el-form-item label="详细地址:">
									<el-input style="width: 400px;"  placeholder="详细地址" :disabled="isshow" v-model="addressdetail" size="small"></el-input>
									<span style="font-size: 12px; color: #999;">地址与营业应执照上一致</span>
								</el-form-item>
								<el-form-item label="公司名称:">
									<el-input class="labelWidth" :disabled="isshow" v-model="companyName" placeholder="请输入公司名称" size="small"></el-input>
									<span style="font-size: 12px; color: #999;">公司名称与营业执照上一致</span>
								</el-form-item>
								<el-form-item label="联系人:" prop="tele_name">
									<el-input class="labelWidth"  v-model="ruleForm.tele_name" placeholder="请输入联系人" size="small"></el-input>
								</el-form-item>
								<el-form-item label="联系电话:" prop="tele_phone">
									<el-input class="labelWidth" maxlength="11"  v-model.trim="ruleForm.tele_phone" placeholder="请输入手机" size="small"></el-input>
								</el-form-item>
								<el-form-item label="经营类目:" size="small">
									<el-select class="labelWidth" :disabled="isshow"  v-model="ruleForm.marketing_trade_id" placeholder="请选择">
										<el-option v-for="industry in industryList" :key="industry.marketing_trade_id" :label="industry.trade_name" :value="industry.marketing_trade_id">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="主体资质类型:" >
									<el-select size="small" class="labelWidth" v-model="allCard"  placeholder="请选择">
										<el-option  label="三证合一" value="三证合一">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="营业执照:" prop="business_license_original">
									<div  v-if="ruleForm.business_license_original" class="avatar-uploader avatar-uploader1"  @click="businessLicenseOriginal('business')">
										<img  :src="ruleForm.business_license_original" class="avatar">
									</div>
									<div class="plusImg" v-else @click="businessLicenseOriginal('business')">
										<i class="el-icon-plus avatar-uploader-icon"></i>
									</div>
									
									<p style="font-size: 12px;line-height: 20px; color: #888;width: 500px;">
										文件需清晰可见，并在有效期内。请提供扫描原件，如为复印件，请加盖公章，如为三证合一的营业执照请在企业营业执照、组织机构代码和企业税务登记证上传三次，请上传证件清晰彩色原件扫描件或数码拍摄照片大小不超过5M，格式为png、jpg、jpeg
									</p>
								</el-form-item>
								<el-form-item label="法人身份证正面:" prop="identity_card_front">
										<div class="avatar-uploader avatar-uploader2" @click="identityCardFront('cardfront')">
											<img v-if="ruleForm.identity_card_front" :src="ruleForm.identity_card_front" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon" @click="identityCardFront('cardfront')"></i>
										</div>
									<p style="font-size: 12px;line-height: 20px; color: #888;width: 500px;">图片建议支持jpg，jpeg，png格式，大小不超过5M</p>
								</el-form-item>
								<el-form-item label="法人身份证反面:" prop="identity_card_reverse">
									<div class="avatar-uploader avatar-uploader2" @click="identityCardBack('cardback')">
										<img v-if="ruleForm.identity_card_reverse" :src="ruleForm.identity_card_reverse" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon" @click="identityCardBack('cardback')"></i>
									</div>
								</el-form-item>
								<el-form-item label="3C认证书:" prop="identity_card_reverse">
									<div class="img_blos">
										<div v-for="(item, index) in ruleForm.certification" :key="index" class="img_blo"
											style="margin-bottom:15px">
											<i class="el-icon-error close_img" @click="delImg(index, 'certification')"></i>
											<img :src="item" class="item_img" />
										</div>
										<div class="plusImg" @click="certification('certification')">
											<i class="el-icon-plus avatar-uploader-icon"></i>
										</div>
									</div>
								</el-form-item>
							</div>
							<div  v-if="certificationType == '2'">
								<el-form-item label="公司名称:" >
									<el-input class="labelWidth" :disabled="isshow" v-model="ruleForm.company_name" placeholder="请输入公司名称" size="small"></el-input>
									<span style="font-size: 12px; color: #999;">公司名称与营业执照上一致</span>
								</el-form-item>
								<el-form-item label="联系电话:" prop="tele_phone">
									<el-input class="labelWidth" maxlength="11" v-model="ruleForm.tele_phone" placeholder="请输入手机" size="small"></el-input>
								</el-form-item>
								<el-form-item label="经营类目:"  size="small">
									<el-select class="labelWidth" :disabled="isshow"  v-model="ruleForm.marketing_trade_id" placeholder="请选择">
										<el-option v-for="industry in industryList" :key="industry.marketing_trade_id" :label="industry.trade_name" :value="industry.marketing_trade_id">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="法人身份证正面:" prop="identity_card_front">
										<div class="avatar-uploader avatar-uploader2" @click="identityCardFront('cardfront')">
											<img v-if="ruleForm.identity_card_front" :src="ruleForm.identity_card_front" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon" @click="identityCardFront('cardfront')"></i>
										</div>
									<p style="font-size: 12px;line-height: 20px; color: #888;width: 500px;">图片建议支持jpg，jpeg，png格式，大小不超过5M</p>
								</el-form-item>
								<el-form-item label="法人身份证反面:" prop="identity_card_reverse">
									<div class="avatar-uploader avatar-uploader2" @click="identityCardBack('cardback')">
										<img v-if="ruleForm.identity_card_reverse" :src="ruleForm.identity_card_reverse" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon" @click="identityCardBack('cardback')"></i>
									</div>
								</el-form-item>
							</div>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet('ruleForm')">提交</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="moreimg" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import ChooseImg from '../../../../common/uploadImgs.vue'
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	import {
		recognition,
		getAuthenticate,
		saveAuthenticate
	} from "@/api/merchant/setting.js";
	import {isvalidPhone} from '@/utils/validate'
	var validPhone=(rule, value,callback)=>{
	      if (!value){
	          callback(new Error('请输入电话号码'))
	      }else  if (!isvalidPhone(value)){
	        callback(new Error('请输入正确的11位手机号码'))
	      }else {
	          callback()
	      }
	  };
	export default {
		components: {
			ChooseImg
		},
		data() {
			return {
				moreimg:true,
				allCard:'三证合一',
				isshow:true,
				uploadFlag: false,
				account_id: localStorage.getItem('account_id'),
				paramsimg: {
					terminal: 'platform',
					classify: '2',
					prefixUrl: true
				},
				options: regionData,
				certificationType: '1',
				industryList: [],
				qualityList: [{
						id: 1,
						name: '三证合一'
					}

				],
				statesteplist: [{
						name: '公司信息',
						num: 1
					},
					{
						name: '企业资源',
						num: 2
					}
				],
				address: '',
				addressdetail:'',
				companyName:'',
				ruleForm: {
					tele_name: '',
					tele_phone:'',
					certification:[],
					business_license_original: '', //营业执照正本
					identity_card_front: '', //身份证正面
					identity_card_reverse: '', //身份证反面
					company_name:'',
					
					legal_person: '',
					tel: '',
					identity_card_phone: '',
					email: '',
					marketing_trade_id: '',
					
					// hs_mark: '', //认证书
					company_province: '',
					company_city: '',
					company_area: ''
				},
				rules: {
					company_name: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					tele_name: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					tele_phone: [
						{ required: true, trigger: 'blur', validator: validPhone },//这里需要用到全局变量
					],
					legal_person: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					tel: [
						{ required: true, trigger: 'blur', validator: validPhone },//这里需要用到全局变量
					],
					identity_card_phone: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					email: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					industry_classify_id: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					business_license_original: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
			
			
					identity_card_front: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					identity_card_reverse: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					
				},
				sureImgBtn:'',
				
			}
		},
		mounted() {
			this.getInfo();
		},
		methods: {
			//获取信息
			getInfo(){
				getAuthenticate({}).then(res=>{
					//this.ruleForm=res.data;
					if(res.data.business_license_info != null){
						this.address = res.data.business_license_info.ENTERPRISE_BANK_PROVINCE+ res.data.business_license_info.ENTERPRISE_CITY+ res.data.business_license_info.ENTERPRISE_BANK_DISTRICT;
						this.addressdetail=res.data.business_license_info.ENTERPRISE_REGISTER_ADDRESS;
						this.companyName=res.data.business_license_info.ENTERPRISE_NAME_CH;
					}
					
					
					let aa=[];
					if(res.data.marketing_trade_info){
						aa.push(res.data.marketing_trade_info);
						this.industryList =aa;
					}
					this.ruleForm.business_license_original=res.data.business_license_images;
					this.ruleForm.identity_card_front=res.data.identity_card_front;
					this.ruleForm.identity_card_reverse=res.data.identity_card_reverse;
					this.ruleForm.certification = res.data.certification.split(',');
					this.ruleForm.tele_name=res.data.tele_name;
					this.ruleForm.tele_phone=res.data.tele_phone;
					this.ruleForm.marketing_trade_id = res.data.marketing_trade_id;
				})
			},
			// 营业执照正本
			businessLicenseOriginal(type) {
				this.sureImgBtn=type;
				this.uploadFlag = !this.uploadFlag;
			},
			//营业执照副本
			businessLicenseCopy(type) {
				this.sureImgBtn=type;
				this.uploadFlag = !this.uploadFlag;
			},
			//身份证正面
			identityCardFront(type) {
				this.sureImgBtn=type;
				this.uploadFlag = !this.uploadFlag;
			},
			//身份证反面
			identityCardBack(type) {
				this.sureImgBtn=type;
				this.uploadFlag = !this.uploadFlag;
			},
			//3c认证
			certification(type) {
				this.moreimg=false;
				this.sureImgBtn=type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			
		
			// 点击确定
			chooseOk(val) {
				if(this.sureImgBtn == 'business'){
					this.ruleForm.business_license_original = val[0].resource_name;
					//获取营业执照信息
					let params={
						business_license_path:this.tools.filterImgPre(this.ruleForm.business_license_original)
					}
					recognition(params).then(res=>{
						this.address = res.data.ENTERPRISE_BANK_PROVINCE+ res.data.ENTERPRISE_CITY+ res.data.ENTERPRISE_BANK_DISTRICT;
						this.addressdetail=res.data.ENTERPRISE_REGISTER_ADDRESS;
						this.companyName=res.data.ENTERPRISE_NAME_CH;
					})
				}
				
				if(this.sureImgBtn == 'cardfront'){
					val.forEach(img=>{
						this.ruleForm.identity_card_front = val[0].resource_name;
					})
				}
				if(this.sureImgBtn == 'cardback'){
					val.forEach(img=>{
						this.ruleForm.identity_card_reverse = val[0].resource_name;
					})
				}
				if(this.sureImgBtn == 'certification'){
					val.forEach((res) => {
						this.ruleForm.certification = this.ruleForm.certification.concat([res.resource_name]);
					});
				}
				this.uploadFlag = false;
			},
			//删除图片
			delImg(index, type) {
				if (type == 'certification') {
					this.ruleForm.certification.splice(index, 1);
				}
			},
		
			selected(name) {
				this.active = name;
			},
			handleChange(area) {
				if(area){
					this.ruleForm.company_province = area[0]
					this.ruleForm.company_city = area[1]
					this.ruleForm.company_area = area[2]
				}
				
			},
		
			 submitSet(tradeform) {
				this.$refs[tradeform].validate((valid) => {
					if (valid) {
						let data={
							certificationType:this.certificationType,
							business_license_images:this.tools.filterImgPre(this.ruleForm.business_license_original),
							identity_card_reverse:this.tools.filterImgPre(this.ruleForm.identity_card_reverse),
							identity_card_front:this.tools.filterImgPre(this.ruleForm.identity_card_front),
							tele_name:this.ruleForm.tele_name,
							tele_phone:this.ruleForm.tele_phone,
							certification:this.ruleForm.certification,
						}
						let certificationp = [];
						data.certification.forEach((item) => {
							certificationp.push(this.tools.filterImgPre(item));
						});
						data.certification = certificationp.join(",");
						data=this.tools.filterParams(data);
						saveAuthenticate(data).then(res=>{
							this.$message.info("更新成功！")
							this.getInfo();
						})
					} else {
						return false;
					}
				});
			},
	
		}

	}
</script>

<style scoped lang="scss">
	@import "@/assets/css/merchant/tradeauthentication.scss";
	.avatar-uploader1{
		margin-top: 14px;
		border-radius: 4px 4px;
		border: 1px solid #CCC;
		width: 200px;
		overflow: hidden;
		height: auto;
		
		img{
			width: 100%;
			// height: 100%;
		}
	}
	.avatar-uploader2{
		margin-top: 14px;
		border-radius: 4px 4px;
		border: 1px solid #CCC;
		width: 240px;
		overflow: hidden;
		text-align: center;
		height: 140px;
		line-height: 140px;
		img{
			width: 100%;
			// height: 100%;
		}
	}
	.demo-ruleForm{
		::v-deep.el-form-item {
		    margin-bottom: 10px;
		}
	}
	.plusImg{
		width: 140px;
		margin-top: 15px;
		border: 1.3px dashed #CCC;
		background-color: #F7F8FA;
		border-radius: 4px 4px;
		text-align: center;
		height: 140px;
		line-height: 140px;
		::v-deep.el-icon-plus{
			width: 140px;
			height: 140px;
			line-height: 140px;
			font-size: 24px;
			color: #969799;
		}
	}
	.pds{
		padding-bottom: 70px;
	}
</style>
