import axios from '@/config/httpConfig'
import qs from  'qs'
//获取所有分类
export const getAllCate=(data)=> {
    return axios('post','Category/getAllCate', data);
}
//删除分类
export const del_list=(data)=> {
    return axios('post','Category/del_list', data);
}
//添加分类
export const add_cate=(data)=> {
    return axios('post','Category/add_cate', data);
}
//禁用/启用分类
export const change_cate=(data)=> {
    return axios('post','Category/change_cate', data);
}
//编辑分类
export const edit_cate=(data)=> {
    return axios('post','Category/edit_cate', data);
}
//获取所有一级分类
export const cate_list=(data)=> {
    return axios('post','Category/cate_list', data);
}

// 分类排序
export const changeCateSort=(data)=> {
    return axios('post','Category/changeCateSort', data);
}

