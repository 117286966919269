<template>
	<div>
		<div class="wrapper-cont">
			<div class="main-cont bgwt" style="padding: 20px;">
				<div class="all_search_list">
					<div class="demo-input-suffix">
						<el-input prefix-icon="el-icon-search" class="inputWidth" size="small" placeholder="服务商名称 / 手机号"
							v-model="search.searchInput" @keyup.enter.native="toSearch" clearable>
						</el-input>
					</div>
					<div class="demo-input-suffix">
						<el-select style="width: 150px !important;" size="small" v-model="search.channel_level"
							class="inputWidth" placeholder="请选择等级" @change="changeLevel">
							<el-option v-for="(item,index) in levelList" :key="index" :label="item.channel_name"
								:value="item.unique_identification"></el-option>
						</el-select>
					</div>

					<div class="demo-input-suffix" style="margin-top:8px">成为服务商的时间：</div>
					<div class="demo-input-suffix" style="font-size: 13px;">
						<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changetimer" :default-time="['00:00:00', '23:59:59']">
						</el-date-picker>
					</div>
					<div class="demo-input-suffix">
						<el-button type="primary" size="small" @click="toSearch">查询</el-button>
					</div>
					<div class="demo-input-suffix">
						<el-button size="small" @click="toReset">重置</el-button>
					</div>
				</div>
				<el-row class="all_search_list" style="margin-bottom:0">
					<el-button type="primary" plain size="small" @click="toadd"  v-has="'服务商列表/添加服务商'" >添加服务商</el-button>
					<el-button size="small" @click="isEnabled('1')" v-has="'服务商列表/启用'" >启用</el-button>
					<el-button size="small" @click="isEnabled('2')" v-has="'服务商列表/禁用'" >禁用</el-button>
				</el-row>
			</div>
			<!--表格-->
			<div class="tableMain">
				<el-table :data="tableData" style="width: 100%" min-height="250" max-height="550" border v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"  :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
					@selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55" align="center">
					</el-table-column>
					<el-table-column prop="identification_code" label="服务商标识码" align="center">
					</el-table-column>
					<el-table-column prop="username" label="服务商名称" align="center">
					</el-table-column>
					<el-table-column prop="account" label="登录账号" align="center">
					</el-table-column>
					<el-table-column prop="address" label="负责区域" align="center">
					</el-table-column>
					<el-table-column prop="channel_info.channel_name" label="等级" align="center">
					</el-table-column>
					<el-table-column prop="status" label="状态" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.status == 1">启用</span>
							<span v-else-if="scope.row.status == 2">禁用</span>
							<span v-else-if="scope.row.status == 3">审核中</span>
						</template>
					</el-table-column>
					<!-- 	<el-table-column prop="zip" label="充值" sortable align="center">
					</el-table-column> -->
					<el-table-column prop="capital_info.available_money" label="可提现余额" sortable align="center">
					</el-table-column>
					<el-table-column prop="capital_info.earnest_money" label="储值余额" sortable align="center">
					</el-table-column>
					<el-table-column prop="begin_time" label="开始时间" align="center">
					</el-table-column>
					<el-table-column prop="end_time" label="到期时间" align="center">
					</el-table-column>
					<el-table-column prop="created_at" label="创建时间" align="center">
					</el-table-column>
					<el-table-column fixed="left" label="操作" width="200" align="center">
						<template slot-scope="scope">
							<div>
								<el-button type="text" size="small" @click="toOpera(scope.row,'edit')" v-has="'服务商列表/编辑'" >编辑</el-button>
								<el-button type="text" size="small" @click="toOpera(scope.row,'plug')" v-has="'服务商列表/插件'" >插件
								</el-button>
								<el-button type="text" size="small" @click="toOpera(scope.row,'recharge')" v-has="'服务商列表/充值'" >充值
								</el-button>
							</div>
							<el-button type="text" size="small" @click="toOpera(scope.row,'record')" v-has="'服务商列表/记录'" >记录</el-button>
							<el-button type="text" size="small" @click="toOpera(scope.row,'store')" v-has="'服务商列表/查看网店'" >查看网店</el-button>
							<el-button type="text" size="small" @click="toOpera(scope.row,'modify')" v-has="'服务商列表/修改密码'" >修改密码</el-button>
							<!-- <el-button type="text" size="small" @click="toOpera(scope.row,'del')">删除</el-button> -->
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
			</div>
			
		</div>
		<!--查看网店弹框-->
		<el-dialog title="服务商的网店" :visible.sync="dialogStoreVisible" width="40%" :before-close="handleClose">
			<div class="pd20">
				<el-table :data="storeData" style="width: 100%" min-height="250" border>
					<el-table-column prop="shop_name" label="商户名称" align="center">
					</el-table-column>
					<el-table-column prop="account_shop_info.account" label="手机号码" align="center">
					</el-table-column>

					<el-table-column prop="marketing_trade_info.trade_name" label="行业类目" align="center">
					</el-table-column>
					<el-table-column prop="begin_time" label="启用时间" align="center">
					</el-table-column>
					<el-table-column prop="end_time" label="过期时间" align="center">
					</el-table-column>
				</el-table>
				<div class="pagination-container">
					<el-pagination background @size-change="storehandleSizeChange" @current-change="handleCurrentChange"
						:current-page="storecurrentPage" :page-sizes="[10,20,30,50]" :page-size="storepagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="storetotal">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
		<!--记录弹框-->
		<el-dialog title="记录" :visible.sync="dialogRecordVisible" width="80%" :before-close="recordhandleClose">
			<div class="pd20">
				<el-table :data="recordData" style="width: 100%" min-height="250" border>
					<el-table-column prop="account_capital_log_id" label="ID" align="center">
					</el-table-column>
					<el-table-column label="原价" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.original_price}}万</span>
						</template>
					</el-table-column>
					<el-table-column label="实付" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.renew_price}}元</span>
						</template>
					</el-table-column>
					<el-table-column  label="变动前资金" align="center">
						<template slot-scope="scope">
							{{scope.row.fund_type == 4?'--':scope.row.before_money}}
						</template>
					</el-table-column>
					<el-table-column  label="变动后资金" sortable align="center">
						<template slot-scope="scope">
							{{scope.row.fund_type == 4?'--':scope.row.back_money}}
						</template>
					</el-table-column>
					<el-table-column  label="变动资金" align="center">
						<template slot-scope="scope">
							{{scope.row.fund_type == 4?'--':scope.row.money}}
						</template>
					</el-table-column>
					<el-table-column label="类型" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.fund_type == 1">储值金额</span>
							<span v-else-if="scope.row.fund_type == 2">余额</span>
							<span v-else-if="scope.row.fund_type == 3">红包</span>
							<span v-else-if="scope.row.fund_type == 4">平台手动续费</span>
						</template>
					</el-table-column>
					<el-table-column prop="discount_rate" label="折扣" align="center">
					</el-table-column>
					<el-table-column label="增减" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.regulation_type == 1">增加</span>
							<span v-else-if="scope.row.regulation_type == 2">减少</span>
							<span v-else>无</span>
						</template>
					</el-table-column>
					<el-table-column label="状态" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.status == 1">正常</span>
							<span v-else-if="scope.row.status == 2">冻结</span>
							<span v-else>作废</span>
						</template>
					</el-table-column>
					<el-table-column prop="remark" label="备注" show-overflow-tooltip align="center">
					</el-table-column>
					<el-table-column prop="created_at" label="创建时间" align="center">
					</el-table-column>
				</el-table>
				<div class="pagination-container">
					<el-pagination background @size-change="recordhandleSizeChange"
						@current-change="recordhandleCurrentChange" :current-page="recordcurrentPage"
						:page-sizes="[10,20,30,50]" :page-size="recordpagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="recordtotal">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
		<!--插件弹框-->
		<el-dialog title="服务商插件" :visible.sync="dialogPluginVisible" width="40%" :before-close="pluginhandleClose">
			<div class="pd20">
				<el-table :data="pluginData" style="width: 100%" min-height="250" border>
					<el-table-column prop="title" label="插件名称" align="center">
					</el-table-column>
					<el-table-column prop="description" label="插件简介" align="center">
					</el-table-column>
					<el-table-column prop="username" label="服务商" align="center">
					</el-table-column>
					<el-table-column  label="当前状态" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.status == 1">上架</span>
							<span v-else-if="scope.row.status == 2">下架</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-container">
					<el-pagination background @size-change="pluginhandleSizeChange"
						@current-change="pluginhandleCurrentChange" :current-page="plugincurrentPage"
						:page-sizes="[10,20,30,50]" :page-size="pluginpagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="plugintotal">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
		<!--修改密码弹框-->
		<el-dialog title="修改密码" :visible.sync="dialogModifyPwdVisible" width="25%">
			<div class="mb10">
				<label>&nbsp;&nbsp;&nbsp;新密码：</label>
				<el-input size="small" type="password" show-password maxlength="16" v-model.trim="modify.newpassword"
					class="inputWidth"></el-input>
			</div>
			<div class="mb10">
				<label>确认密码：</label>
				<el-input size="small" type="password" show-password maxlength="16" v-model.trim="modify.surepassword"
					class="inputWidth"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogModifyPwdVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureModify" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!--充值-->
		<el-dialog title="充值金额" :visible.sync="dialogRechargePwdVisible" width="35%">
			<div class="mb10">
				<el-form :model="recharge" ref="recharge" label-width="100px" class="demo-ruleForm formBox">
					<el-form-item label="充值类型:">
						<el-radio-group v-model="recharge.order_type" @change="changeRadio">
							<!-- <el-radio :label="1" disabled>实付金额</el-radio> -->
							<el-radio :label="4">套餐充值</el-radio>
						</el-radio-group>

					</el-form-item>
					<el-form-item label="储值套餐:" v-if="recharge.order_type == '4'">
						<el-select v-model="recharge.combo" size="small" @change="getComboVal(recharge.combo)"
							class="inputWidth" placeholder="全部">
							<el-option v-for="(card,index) in comboList" :key="index" :label="card.pet_card_title"
								:value="card.pet_card_id"></el-option>
						</el-select>
						<div>
							<label>折扣 </label>
							<el-input size="small" v-model="comboVal.discount" style="width: 80px;"></el-input>%
							<label>充值金额 </label>
							<el-input size="small" v-model="comboVal.package_price" style="width: 80px;"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="充值金额:" v-if="recharge.order_type == '1'">
						<el-input size="small" v-model="recharge.money" class="inputWidth"></el-input>
					</el-form-item>
					<!-- <el-form-item label="充值来源:">
						<el-radio v-model="recharge.sources" label="1">现金</el-radio>
						<el-radio v-model="recharge.sources" label="2">支付宝</el-radio>
						<el-radio v-model="recharge.sources" label="3">微信</el-radio>
					</el-form-item> -->
					<el-form-item label="实付金额:" style="color:#F56C6C;font-weight: bold;"
						v-if="recharge.order_type == '4' && comboVal.selling_price">
						<span>¥{{comboVal.selling_price}}</span>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogRechargePwdVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitPay()" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		getChannelList,
		channelAgentLevel,
		getChannelFundLog,
		getChanneShopList,
		upChannelPasswd,
		delChannel,
		setChannelStatus,
		rechargeAmount,
		petCardList,
		serverTwitterList
	} from '@/api/plat/business'
	const sha256 = require('js-sha256').sha256
	export default {
		data() {
			return {
				loading:true,
				search: {
					is_checked: 3,
					searchInput: '',
					channel_level: '',
					begin_time: '',
					end_time: '',
				},
				due_dt: '',
				recharge: {
					order_type: 4,
					money: '',
					combo: ''
					// sources: '1',
					// payMoney: '5000',
					// combo: '',
					// discount: '',
					// rechargeMoney: ''
				},
				levelList: [],
				tableData: [{
					code: 'YH-KI-JI',
					name: 'YCP'
				}],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				//网店
				dialogStoreVisible: false,
				storetotal: 0,
				storecurrentPage: 1, //初始页
				storepagesize: 10, //每页的数据
				storeData: [],
				//记录
				dialogRecordVisible: false,
				recordtotal: 0,
				recordcurrentPage: 1, //初始页
				recordpagesize: 10, //每页的数据
				recordData: [{
					id: 1123214
				}],
				//插件
				dialogPluginVisible: false,
				plugintotal: 0,
				plugincurrentPage: 1, //初始页
				pluginpagesize: 10, //每页的数据
				pluginData: [{
					name: '社群接龙'
				}],
				//修改密码
				dialogModifyPwdVisible: false,
				modify: {
					newpassword: '',
					surepassword: ''
				},
				//充值
				dialogRechargePwdVisible: false,

				form: {},
				multipleSelection: [],
				account_channel_id: '',
				comboList: [],
				comboVal: {},
				contact_name:''
			}
		},
		mounted() {
			this.getChannelAgentLevel();
		},
		methods: {
			//获取服务商等级
			getChannelAgentLevel() {
				let data = {}
				channelAgentLevel(data).then(res => {
					this.levelList = res.data;
					this.levelList.unshift({
						channel_name: '全部',
						unique_identification: ''
					})
				})
			},
			//服务商列表
			getChannelData() {
				let data = this.search;
				data.page = this.currentPage;
				data.perPage = this.pagesize;
				data.search = this.search.searchInput;
				data = this.tools.filterParams(data);
				getChannelList(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list;
					this.tableData.forEach(item => {
						item.begin_time = this.tools.timeFormate(item.begin_time * 1000);
						item.end_time = this.tools.timeFormate(item.end_time * 1000);
						item.address = item.province + item.city + item.county;
						item.earnest_money = item.capital_info==null?'':item.capital_info.earnest_money
					})

				})
			},
			//搜索
			toSearch() {
				this.currentPage=1;
				this.getChannelData()
			},
			toReset() {
				this.currentPage=1;
				this.search.searchInput = '';
				this.search.channel_level = '';
				this.search.begin_time = '';
				this.search.end_time = '';
				this.due_dt = '';
				this.getChannelData()
			},
			changeLevel(val) {
				this.search.channel_level = val;

			},
			//全选
			handleSelectionChange(val) {
				let ids = []
				val.forEach(item => {
					ids.push(item.account_channel_id)
				})
				this.multipleSelection = ids
			},
			//禁用启用
			isEnabled(type) {
				if (this.multipleSelection.length == 0) {
					this.$message.warning('请选择要操作的数据！');
					return;
				}
				let data
				if (type == 1) {
					data = {
						account_channel_id: this.multipleSelection.toString(),
						status: 1
					}
				}
				if (type == 2) {
					data = {
						account_channel_id: this.multipleSelection.toString(),
						status: 2
					}
				}
				setChannelStatus(data).then(res => {
					this.getChannelData()

				})
			},
			//时间搜索
			changetimer(val) { //时间
				if (val != null) {
					this.search.begin_time = val[0] / 1000;
					this.search.end_time = val[1] / 1000;
				}
				if (val == null) {
					this.search.begin_time = '';
					this.search.end_time = '';
				}
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getChannelData()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getChannelData()
			},
			//添加服务商
			toadd() {
				this.$router.push({
					path: "/clientadmin/agentmanagement/agentmanagementadd"
				})
			},
			getPlugList(){
				let data={
					channel_id:this.account_channel_id,
					username:this.contact_name,
					page:this.plugincurrentPage, //初始页
					perPage:this.pluginpagesize, //每页的数据
				}
				serverTwitterList(data).then(res=>{
					this.plugintotal=res.data.count
					this.pluginData=res.data.list
				})
			},
			//操作
			toOpera(row, type) {
				if (type == 'edit') {
					this.$router.push({
						path: "/clientadmin/agentmanagement/agentmanagementadd",
						query: {
							id: row.account_channel_id
						}
					})
				} else if (type == 'plug') {
					this.dialogPluginVisible = !this.dialogPluginVisible;
					this.account_channel_id=row.account_channel_id;
					this.contact_name=row.contact_name;
					this.getPlugList();
				} else if (type == 'recharge') {
					this.account_channel_id = row.account_channel_id;
					this.recharge.combo = '';
					this.comboVal = {};
					this.dialogRechargePwdVisible = !this.dialogRechargePwdVisible;
					this.getPetCardList()
				} else if (type == 'record') {
					this.dialogRecordVisible = !this.dialogRecordVisible;
					this.account_channel_id = row.account_channel_id;
					this.recordList()
				} else if (type == 'store') {
					this.dialogStoreVisible = !this.dialogStoreVisible;
					this.account_channel_id = row.account_channel_id;
					this.channeShopList()
				} else if (type == "modify") {
					this.dialogModifyPwdVisible = !this.dialogModifyPwdVisible;
					this.modify.newpassword = '';
					this.modify.surepassword = ''
					this.account_channel_id = row.account_channel_id;
				} else if (type == "del") {
					this.tools.confirm('确定要删除吗？').then(res => {
						if (res) {
							//调接口
							delChannel({
								account_channel_id: row.account_channel_id
							}).then(() => {
								this.$message.success("删除成功！");
								this.getChannelData()

							})
						}
					})
				}
			},
			//充值---
			//切换获取储值套餐
			changeRadio(val) {
				if (val == 4) {
					this.getPetCardList()
				}
			},
			//套餐列表
			getPetCardList() {
				petCardList({}).then(res => {
					this.comboList = res.data.list
				})
			},
			//选择下拉获取值
			getComboVal(val) {
				this.comboList.forEach(item => {
					if (item.pet_card_id == val) {
						this.comboVal = item
					}
				})
			},
			//提交充值
			submitPay() {
				if (this.recharge.order_type == 1 && this.recharge.money == '') {
					this.$message.error("请输入充值金额！");
					return;
				}
				if (this.recharge.order_type == 4 && this.recharge.combo == '') {
					this.$message.error("请选择套餐！");
					return;
				}
				let data = {
					beneficiary_account_id: this.account_channel_id,
					beneficiary_terminal: "channel",
					order_type: this.recharge.order_type,
					commodity_id: this.recharge.combo,
					payType: 3,
					money: this.recharge.order_type == 1 ? this.recharge.money : this.comboVal.selling_price
				}
				data = this.tools.filterParams(data)
				rechargeAmount(data).then(res => {
					this.dialogRechargePwdVisible = !this.dialogRechargePwdVisible;
					this.$message.success("充值成功！");
					this.recharge.order_type = 1;
					this.recharge.combo = '';
					this.recharge.money = '';
					this.getChannelData()
				})
			},
			//获取流水记录
			recordList() {
				let data = {
					account_channel_id: this.account_channel_id,
					page: this.recordcurrentPage,
					perPage: this.recordpagesize
				}
				data = this.tools.filterParams(data)
				getChannelFundLog(data).then(res => {
					this.recordtotal = res.data.count;
					this.recordData = res.data.list;
					this.recordData.forEach(item => {
						if (item.discount_rate != null) {
							item.discount_rate = parseFloat(item.discount_rate / 10)
						}
					})

				})
			},
			recordhandleClose() {
				this.dialogRecordVisible = !this.dialogRecordVisible
			},
			//记录分页
			recordhandleSizeChange(recordpagesize) {
				this.recordpagesize = recordpagesize;
				// 掉接口
				this.recordList()
			},
			recordhandleCurrentChange(recordcurrentPage) {
				this.recordcurrentPage = recordcurrentPage;
				// 掉接口
				this.recordList()
			},
			//获取指定服务商下级门店列表
			channeShopList() {
				let data = {
					account_channel_id: this.account_channel_id,
					page: this.storecurrentPage,
					perPage: this.storepagesize
				}
				data = this.tools.filterParams(data)
				getChanneShopList(data).then(res => {
					this.storetotal = res.data.count;
					this.storeData = res.data.list;
					this.storeData.forEach(item => {
						item.begin_time = this.tools.timeFormate(item.account_shop_info.begin_time * 1000);
						item.end_time = this.tools.timeFormate(item.account_shop_info.end_time * 1000);
					})
				})
			},
			handleClose() {
				this.dialogStoreVisible = !this.dialogStoreVisible
			},
			//网店分页
			storehandleSizeChange(size) {
				this.storepagesize = size;
				// 掉接口
				this.channeShopList()
			},
			storehandleCurrentChange(currentPage) {
				this.storecurrentPage = currentPage;
				// 掉接口
				this.channeShopList()
			},

			//修改密码
			sureModify() {
				if (this.modify.newpassword == '') {
					this.$message.info("请输入新密码！")
					return;
				}
				if (this.modify.newpassword == '' || this.modify.surepassword == '') {
					this.$message.warning("密码不能为空");
					return;
				}
				if (this.modify.newpassword != this.modify.surepassword) {
					this.$message.warning("两次密码输入不一致");
					return;
				}

				//调接口
				let data = {
					account_channel_id: this.account_channel_id,
					newPassword: sha256(this.modify.newpassword)
				}
				upChannelPasswd(data).then(res => {
					this.dialogModifyPwdVisible = !this.dialogModifyPwdVisible;
					this.modify.newpassword = '';
					this.modify.surepassword = ''
				})

			},
			pluginhandleClose() {
				this.dialogPluginVisible = !this.dialogPluginVisible
			},
			//插件分页
			pluginhandleSizeChange(size) {
				this.pluginpagesize = size;
				// 掉接口
				this.getPlugList()
			},
			pluginhandleCurrentChange(currentPage) {
				this.plugincurrentPage = currentPage;
				// 掉接口
				 this.getPlugList()
			},



		}
	}
</script>

<style>
</style>
