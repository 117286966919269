import axios from '@/config/httpConfig'
import qs from  'qs'
//获取排序列表
export const getComposingPpositionList=(data)=> {
    return axios('post','/Composing/getComposingPpositionList', data);
}
// 排序位置移动
export const ComposingPpositionMove=(data)=> {
    return axios('post','/Composing/ComposingPpositionMove', data);
}
// 删除数据
export const delPosition=(data)=> {
    return axios('post','/Composing/ComposingPpositionDel', data);
}

