<template>
	<div class="order">
		<div class="wrapper-main" style="height: 100%;">
			<div class="model-content">
				<el-form  :model="ruleForm" ref="ruleForm" :rules="rules"  class="demo-ruleForm" >
					<div class="order-set">
						<div class="top_title">
							商品折扣设置
						</div>
						<el-form-item label="是否开启折扣：" label-width="160px">
							<el-radio v-model="ruleForm.whether_open" label="1">开</el-radio>
							<el-radio v-model="ruleForm.whether_open" label="2">关</el-radio>
						</el-form-item>
						<el-form-item label-width="230px"  label="商品采购价折扣小于（含）" prop="discount" >
							<div class="flex">
								<el-input v-model="ruleForm.discount" :min="0" :max="100"
									oninput="if(value > 100 || value < 1 ){value = ''}"  onkeyup="value=value.replace(/[^\d]/g,'');"
									size="small" class="w150 mrl" style="vertical-align:middle;float: left;height: 32px!important;">
									<template slot="append" style="height: 32px!important;">%</template>
								</el-input>，<div style="position: relative;">在淘货显示折扣标签
									<span class="pointtxt" @mouseenter="mouseover"
										@mouseleave="mouseleave">示例</span>
									<div class="imgposition">
										<img src="../../../../../../assets/img/discount.png" style=" width: 400px;"
											v-show="imgshow" />
									</div>
								</div>
							</div>
						</el-form-item>
						<div style="margin-top:60px;margin-left: 40px;margin-bottom: 40px;">
							<el-button style="width: 100px; height: 34px;" @click="submitSet('ruleForm')"
								type="primary" size="small">提交</el-button>
						</div>
					</div>
					
				</el-form>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getCommodityDiscountSettings,
		commodityDiscountSettings
	} from '@/api/plat/goods'
	export default {
		data() {
			return {
				imgshow: false,
				ruleForm: {
					whether_open: '1',
					discount: ''
				},
				rules:{
					discount: [
							{ required: true, message: '请输入商品折扣', trigger: 'blur' }
						  ]
				}
			}
		},
		mounted() {
			this.getCommodityDiscountSettings();
		},
		methods: {
			
			mouseover() {
				this.imgshow = true;
			},
			mouseleave() {
				this.imgshow = false;
			},
			//数据获取
			getCommodityDiscountSettings(){
				getCommodityDiscountSettings({}).then(res=>{
					if(res.return_code == 'SUCCESS'){
						res.data.whether_open =res.data.whether_open=='Y'?'1':'2';
						res.data.discount = ((res.data.discount*1) *100);
						this.ruleForm=res.data;
						
					}
				})
			},
			//提交申请
			submitSet() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						//调接口
						let data=JSON.parse(JSON.stringify(this.ruleForm));
						data.whether_open=data.whether_open=='1'?'Y':'N';
						data.discount = ((data.discount*1)/100);
						data = this.tools.filterParams(data);
						commodityDiscountSettings(data).then(res=>{
							if (res.return_code == 'SUCCESS') {
								this.$message({
									message: '更新成功',
									type: 'success'
								});
								this.getCommodityDiscountSettings();
							}
						})
					} else {
						this.$message.error('请填写完整信息')
						return false;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.order {
		padding-bottom: 60px;

		.order-set {
			padding: 15px;
			border-radius: 6px;
			// min-height: 300px;
			background-color: #f2f2f6;
			margin-bottom: 20px;

			.top_title {
				font-weight: bold;
				padding: 15px;
				margin: 10px 0;
				font-size: 14px;
				color: #606266
			}

			.orderTime {
				.timeInput {
					width: 50px !important;
					text-align: center;
				}

				.shippedInput {
					width: 400px !important;
				}

				span {
					color: #595961;
					margin: 0px 10px;
				}

				.tipmsg {
					font-size: 12px;
					color: #999999;
				}
			}

			.radioContent {
				span {
					color: #9797a1;
				}

				::v-deep .el-radio {
					color: #666;
					cursor: pointer;
					margin-right: 30px;
				}
			}

			.afterSale {
				span {
					color: #9797a1;
					margin-left: 30px;
				}
			}

			.reasonList {
				ul {
					li {
						padding: 10px;
						display: inline-block;
					}
				}
			}

			.explain {
				width: 600px;
				background-color: #ffe6c6;
				padding: 10px 20px;

				.ant-col {
					line-height: 30px;
					color: #595961;
				}
			}
		}

		.store-btns {
			position: fixed;
			left: 200px;
			bottom: 0px;
			right: 20px;
			padding: 8px 10px;
			background: white;
			z-index: 100;
			box-shadow: 0 2px 10px 0 rgba(15, 12, 70, 0.1);

			// border-radius: 10px 10px 0 0;
			.text-center {
				text-align: center !important;
			}
		}
	}
	.imgposition{
		position: absolute; 
		top:12px;
		 left: 170px;
		 z-index: 10;
		 pointer-events:none;
	}
	.pointtxt{
		color: #409EFF;
		 cursor: pointer;
	}
</style>
