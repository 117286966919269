<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<div class="all_search_list" style="margin-bottom: 0;">
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toAdd()" v-has="'秒杀课程/创建秒杀课程'" >创建秒杀课程</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toDel" v-has="'秒杀课程/删除'" >删除</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-input prefix-icon="el-icon-search" class="inputWidth" v-model="search.course_title"
								placeholder="请输入课程名称" size="small" type="text"  @keyup.enter.native="toSearch"  clearable>
							</el-input>
						</div>
						<div class="demo-input-suffix">
							<el-select v-model="search.seconds_type" size="small" class="w120" placeholder="活动状态">
								<el-option label="活动状态" value=""></el-option>
								<el-option label="待开始" value="1"></el-option>
								<el-option label="进行中" value="2"></el-option>
								<el-option label="已结束" value="3"></el-option>
								<el-option label="停止" value="4"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
					</div>
				</div>
				<div class="main-cont bgwt mt10">
					<el-table :data="tableData" border style="width: 100%" 
						@selection-change="handleSelectionChange" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column prop="course_title" label="课程名称" align="center">
						</el-table-column>
						<el-table-column prop="account" label="活动时间" align="center">
							<template slot-scope="scope">
								<div>开始时间：<span>{{scope.row.start_time}}</span></div>
								<div>结束时间：<span>{{scope.row.end_time}}</span></div>
							</template>
						</el-table-column>
						<el-table-column prop="order_count.sum" label="订单实付金额(元)" align="center">
						</el-table-column>
						<el-table-column prop="order_count.count" label="付款订单数" align="center">
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<span style="color: #64C200;" v-if="scope.row.seconds_type == '1'">待开始</span>
								<span style="color: #FA6400;" v-if="scope.row.seconds_type == '2'">进行中</span>
								<span style="color: #E02020;" v-if="scope.row.seconds_type == '3'">已结束</span>
								<span style="color: #E02020;" v-if="scope.row.seconds_type == '4'">停止</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button style="padding: 5px 0;" type="text" size="small"
									@click="toOpera(scope.row,'detail')" v-has="'秒杀课程/查看'" >查看
								</el-button>
								<el-button style="padding: 5px 0;color: #E02020;" type="text" size="small"
									v-if="scope.row.seconds_type != '2'" @click="toOpera(scope.row,'del')" v-has="'秒杀课程/删除'" >删除
								</el-button>
								<el-button style="padding: 5px 0px;" type="text" size="small"
									v-if="scope.row.seconds_type == '1'" @click="toOpera(scope.row,'edit')" v-has="'秒杀课程/编辑'" >编辑
								</el-button>
								<el-button style="padding: 5px 0px;" type="text" size="small"
									v-if="scope.row.seconds_type == '1'" @click="toOpera(scope.row,'isstop')" v-has="'秒杀课程/停止'" >停止
								</el-button>

							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getSecondsKillList,
		stopSecondsKill,
		delSecondsKill 
	} from "@/api/plat/college.js"
	export default {
		data() {
			return {
				loading:true,
				search: {
					course_title: '',
					seconds_type: '',
				},
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				multipleSelection: [], //全选课程
			}
		},
		created() {},
		methods: {
			getSecondKillCourseList() {
				let data = {
					course_title: this.search.course_title,
					seconds_type: this.search.seconds_type,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				getSecondsKillList(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
				})
			},
			//添加秒杀课程
			toAdd() {
				this.$router.push({
					path: "/marketingadmin/businesscollege/addsecondkillcourse",
					query: {
						btnState: "add"
					}
				})
			},
			//删除
			toDel() {
				if (this.multipleSelection.length == 0) {
					this.$message.warning("请选择要删除的课程！")
					return;
				}
				this.tools.confirm("确定要批量删除该秒杀课程吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							college_curriculum_seconds_id: this.multipleSelection.toString()
						}
						delSecondsKill(data).then(res => {
							this.$message.success("删除成功")
							this.getSecondKillCourseList()
						})
					}
				})
			},
			//课程列表全选
			handleSelectionChange(val) {
				let aa = [];
				val.forEach(item => {
					aa.push(item.college_curriculum_seconds_id)
				})
				this.multipleSelection = aa;
			},
			handleSizeChange(size) {
				this.pagesize = size;
				//调接口
				this.getSecondKillCourseList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				//调接口
				this.getSecondKillCourseList()
			},
			//搜索
			toSearch() {
				this.currentPage = 1;
				//调接口
				this.getSecondKillCourseList()
			},
			//重置
			toReset() {
				this.search.course_title = '';
				this.search.seconds_type = '';
				this.currentPage = 1;
				//调接口
				this.getSecondKillCourseList()
			},
			//操作
			toOpera(row, type) {
				if (type == 'edit') {
					this.$router.push({
						path: "/marketingadmin/businesscollege/addsecondkillcourse",
						query: {
							id: row.college_curriculum_seconds_id,
							btnState: "edit"
						}
					})
				}
				if (type == 'detail') {
					this.$router.push({
						path: "/marketingadmin/businesscollege/addsecondkillcourse",
						query: {
							id: row.college_curriculum_seconds_id,
							btnState: "detail"
						}
					})
				}
				if (type == 'del') {
					this.tools.confirm("确定要删除该秒杀课程吗？").then(res => {
						if (res) {
							//调接口
							delSecondsKill({
								college_curriculum_seconds_id: row.college_curriculum_seconds_id
							}).then(res => {
								this.$message.success("删除成功")
								this.getSecondKillCourseList()
							})
						}
					})

				}
				if (type == 'isstop') {
					this.tools.confirm("确定要停止该秒杀课程吗？").then(res => {
						if (res) {
							//调接口
							stopSecondsKill({
								college_curriculum_seconds_id: row.college_curriculum_seconds_id
							}).then(res => {
								this.$message.success("操作成功")
								this.getSecondKillCourseList()
							})
						}
					})

				}

			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	::v-deep.selectvalinput .el-input {
		width: 130px !important;
	}

	::v-deep.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}
</style>
