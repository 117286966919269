<template>
	<div>
		<div class="wrapper-main">
			<el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small" class="demo-ruleForm">
				<div class="wrapper-cont pp20">
					<div class="setTitle">活动设置</div>
					<el-form-item label="活动名称:" prop="activity_name">
						<el-input style="width: 400px!important;" size="small" maxlength="20" show-word-limit
							v-model="form.activity_name">
						</el-input>
					</el-form-item>
					<el-form-item label="活动日期:" prop="start_time">
						<el-date-picker v-model="form.start_time" type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" @change="startTimeStatus"
							:picker-options="pickerOptionsStart" style="margin-right: 10px;">
						</el-date-picker>
						至
						<el-date-picker v-model="form.end_time" type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" @change="endTimeStatus"
							:picker-options="pickerOptionsEnd" style="margin-left: 10px;">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="活动营销素材:" prop="activity_image">
						<div class="img_blos">
							<div class="img_blo" @click="uploadImg('activity')">
								<img :src="form.activity_image" class="item_img" v-if="form.activity_image" />
								<div class="plusImg" v-else>
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
						</div>
						<div style="color: #999;font-size: 12px;">
							活动会场顶部图750*560px
						</div>
					</el-form-item>
				</div>
				<div class="wrapper-cont pp20 mt10">
					<div class="setTitle">规则设置</div>
					<el-form-item label="是否参与推客分销:">
						<el-radio v-model="form.is_distribution" label="Y">参与</el-radio>
						<el-radio v-model="form.is_distribution" label="N">不参与</el-radio>
					</el-form-item>
					<el-form-item label="成团人数:" prop="group_number">
						<el-input class="w180" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="form.group_number">
							<template slot="append">人</template>
						</el-input>
					</el-form-item>
					<el-form-item label="拼中人数:" prop="buy_number">
						<el-input class="w180" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="form.buy_number">
							<template slot="append">人</template>
						</el-input>
					</el-form-item>
					<el-form-item label="奖励消费金额:" prop="reward">
						<el-input class="w180" oninput="value=value.replace(/[^\d.]/g,'')" v-model="form.reward">
							<template slot="append">元</template>
						</el-input>
						<p class="imgtxtstyle">返现返回会员余额，消费余额不可以提现</p>
					</el-form-item>
					<el-form-item label="虚拟成团:" prop="virtual_group_complete_time" :rules="form.virtual_group== 'Y' ? rules.virtual_group_complete_time:[{required: false}]" >
						<div>
							<el-radio v-model="form.virtual_group"  label="Y">启用</el-radio>
							<el-radio v-model="form.virtual_group"  label="N">不启用</el-radio>
						</div>
						<div v-if="form.virtual_group=='Y'">
							<p class="imgtxtstyle">开启虚拟成团后，在幸运团购有效期内人数不够的团，系统会虚拟用户凑满人数，使幸运团购成功。虚拟的用户不生成订单，只需对真实买家发货。</p>
							<span style="color:#999;margin-right: 3px;">开团</span>
							<el-input class="w180"   oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="form.virtual_group_complete_time">
								<template slot="append">小时</template>
							</el-input><span style="color:#999;margin-left: 3px;">后，自动成团。</span>
						</div>
					</el-form-item>
					<el-form-item label="虚拟已成团数:" prop="virtual_group_num">
						<el-input class="w180"  oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="form.virtual_group_num">
						</el-input>
						<p class="imgtxtstyle">已成团数量为：虚拟已成团数+真实已成团数</p>
					</el-form-item>
					<el-form-item label="拼团须知:">
						<el-input type="textarea" style="width: 600px!important;" :rows="4" maxlength="200"
							show-word-limit v-model="form.notice"></el-input>
					</el-form-item>
					<el-form-item label="选择商品" prop="goods_list">
						<el-button size="small" type="primary" @click="selectGood" v-if="btnState=='edit' || btnState=='add'">选择拼团商品</el-button>
						<el-table :data="form.goods_list" v-if="form.goods_list.length>0" style="width: 100%"
							height="500" class="mt20">
							<el-table-column type="index" width="50">
							</el-table-column>
							<el-table-column prop="date" label="商品名称" width="380" align="left">
								<template slot-scope="scope">
									<div class="el_name">
										<img v-if="scope.row.goods_main_pic"
											:src="scope.row.goods_main_pic.split(',')[0]" class="el_img" />
										<div>
											<p>{{ scope.row.goods_name }}</p>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="goods_stock" label="总库存"  align="center">
							</el-table-column>
							<el-table-column prop="sku_purchase_price" label="成本价"  align="center">
							</el-table-column>
							<el-table-column prop="address" label="幸运团购设置" align="center">
								<template slot-scope="scope">
									<el-button size="small" @click="setPrice(scope.row)">
										<span :style="{color:(scope.row.is_set=='1'?'#909399':'#67C23A')}">
											{{scope.row.is_set=='1'?'已设置幸运团购价格库存':'设置幸运团购价格库存'}}
										</span>
									</el-button>
								</template>
							</el-table-column>
							<el-table-column label="操作" align="center" class="opera-cont" width="180">
								<template slot-scope="scope">
									<el-button type="text" @click="toSetMain(scope.$index)" style="color: #0091FF;"
										size="small">{{scope.row.is_master==0?'设为主推':'取消主推'}}</el-button>
									<el-button type="text" size="small" style="color: #E02020"
										@click="toDel(scope.$index)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<div class="save-cont" v-if="btnState=='detail'">
			<div class="cancel-btn" @click="toBack">返回</div>
		</div>
		<div class="save-cont" v-else-if="btnState=='edit' || btnState=='add'">
			<div class="cancel-btn" @click="cancelSet">取消</div>
			<div class="save-btn" @click="submitSet('form')">提交</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
		<!--商品弹框-->
		<el-dialog title="选择商品" :visible.sync="dialogVisible" width="55%">
			<el-row :gutter="20">
				<el-col :span="5">
					<div class="grid-content bg-purple treestyle">
						<el-radio-group size="small" v-model="tabPosition" @change="changeTab" style="margin-bottom: 30px;">
						    <el-radio-button label="1">平台</el-radio-button>
						    <el-radio-button label="2">自营</el-radio-button>
						  </el-radio-group>
						  <div style="max-height: 600px; overflow-y: scroll;">
							  <el-tree :data="treedata" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
						  </div>
						
					</div>
				</el-col>
				<el-col :span="19">
					<div class="grid-content bg-purple ">
						<el-row class="all_search_list" style="margin-bottom: 0;">
							<div class="demo-input-suffix">
								<el-input prefix-icon="el-icon-search" v-model="search.keyward" placeholder="请输入商品名称" size="small"
									type="text">
								</el-input>
							</div>
							<div class="demo-input-suffix">
								<el-button size="small" type="primary" slot="append" @click="searchOrder()">搜索
								</el-button>
								<el-button size="small" @click="toReset">重置</el-button>
							</div>
						</el-row>
						<el-table border :data="tableData" ref="multipleTable" @selection-change="handleSelectionChange"
							style="width: 100%;margin-top:15px">
							<el-table-column type="selection" width="55" align="center">
							</el-table-column>
							<el-table-column prop="goods_name" label="商品名称" align="left">
								<template slot-scope="scope">
									<div class="el_name">
										<img v-if="scope.row.goods_main_pic"
											:src="scope.row.goods_main_pic.split(',')[0]" class="el_img" />
										<div>
											<p>{{ scope.row.goods_name }}</p>
										</div>
									</div>
								
								</template>
							</el-table-column>
							<el-table-column prop="sku_unified_price" label="成本价" align="center">
							</el-table-column>
							<el-table-column prop="sku_sale_price" label="销售价" align="center">
							</el-table-column>
							<el-table-column prop="goods_stock" label="库存" align="center">
							</el-table-column>
						</el-table>
					</div>
				</el-col>
			</el-row>

			<!-- 分页 -->
			<div class="pagecurrent">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddGood" size="small">确 定</el-button>
			</span>
		</el-dialog>

		<!--选择规格-->
		<el-dialog title="选择规格" :visible.sync="dialogSpecVisible" width="58%">
			<div class="el_name">当前商品：
				<img :src="specList.goods_main_pic" class="el_img" />
				<div style="color: #0091FF;">
					<p>{{specList.goods_name }}</p>
				</div>
			</div>
			<el-table :data="specList.sku" stripe style="width: 100%;" border class="mt10">
				<el-table-column fixed prop="vals1" :label="skuName[0]" v-if="skuName[0]">
				</el-table-column>
				<el-table-column prop="vals2" :label="skuName[1]" v-if="skuName[1]">
				</el-table-column>
				<el-table-column prop="vals3" :label="skuName[2]" v-if="skuName[2]">
				</el-table-column>
				<el-table-column prop="sku_sale_price" label="销售价" align="center">
				</el-table-column>
				<el-table-column prop="sku_purchase_price" label="成本价" align="center">
				</el-table-column>
				<el-table-column prop="total_stock" label="现有库存" align="center">
				</el-table-column>
				<el-table-column label="幸运团购价格" align="center">
					<template slot-scope="scope">
						<el-input type="text" class="w80" size="small" oninput="value=value.replace(/[^\d.]/g,'')"
							v-model="scope.row.group_buying_price" placeholder="价格"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="幸运团购库存" align="center">
					<template slot-scope="scope">
						<el-input type="text" class="w80" size="small" @change="changeStock(scope.row)"
							oninput="value=value.replace(/[^\d.]/g,'')" v-model="scope.row.group_buying_stock"
							placeholder="库存"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="ispartake" label="是否参与" align="center">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.is_join" :true-label="1" :false-label="0"></el-checkbox>
					</template>
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogSpecVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddSpec" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import ChooseImg from "../../../../../common/uploadImgs";
	import {
		getCategoryList,
		getGoodsList,
		addGroupBuyingActivity,
		updateGroupBuyingActivity
	} from '@/api/merchant/marketing.js'
	import {
		all_cate, //所有商品分类
	} from '@/api/plat/goods';
	// import {
	// 	addSecondskillActivity, //拼团列表
	// 	updateSecondskillActivity
	// } from '@/api/plat/skillactivity';
	export default {
		components: {
			ChooseImg
		},
		data() {
			var checkCount = (rule, value, callback) => {
				if (!value) {
					callback(new Error('开团时间不能为空'));
				} else if (value < 15) {
					callback(new Error('不得小于15分钟'));
				} else {
					callback();
				}
			};
			return {
				form: {
					activity_name: '',
					start_time: '',
					end_time: '',
					activity_image: '',
					group_number: '', //件数
					buy_number: '', //数量
					reward: '',
					virtual_group: 'Y',
					virtual_group_num: '',
					virtual_group_complete_time: '',
					is_distribution: 'N',
					notice: '参团成功后，可邀请好友进行参团，达到成团人数后，团购成功，活动结束未成团的将自动退款。成团后，随机抽取X人获得商品，未拼中商品的订单自动退款并可获得参团奖励。',
					goods_list: []
				},
				rules: {
					activity_name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					start_time: [{
						required: true,
						message: '请选择时间',
						trigger: 'change'
					}],
					activity_image: [{
						required: true,
						message: '请上传活动营销素材',
						trigger: 'change'
					}],
					group_number: [{
						required: true,
						message: '请输入成团人数',
						trigger: 'blur'
					}],
					buy_number: [{
						required: true,
						message: '请输入拼中人数',
						trigger: 'blur'
					}],
					reward: [{
						required: true,
						message: '请输入奖励消费金额',
						trigger: 'blur'
					}],
					virtual_group_complete_time: [{
						message: '请输入开团时间',
						trigger: 'blur',
						required: true,
					}],
					goods_list: [{
						type: 'array',
						required: true,
						message: '请选择拼团商品',
						trigger: 'change'
					}]
				},
				// 开始和结束日期转换的时间戳
				startTime: null,
				endTime: null,
				/* start 开始时间小于今天,结束时间不能大于开始时间 */
				//time.getTime() < Date.now()-8.64e7 今天之前的时间不能选
				pickerOptionsStart: {
					disabledDate: time => {
						if (this.form.end_time) {
							return time.getTime() < Date.now() - 8.64e7 || time.getTime() > this.form
								.end_time;
						} else {
							return time.getTime() < Date.now() - 8.64e7; //当前时间之后
						}
					}
				},
				pickerOptionsEnd: {
					disabledDate: time => {
						// return time.getTime() < Date.now() - 8.64e7 || time.getTime() < this.form.start_time;
						if (this.form.start_time) {
							return time.getTime() < new Date(this.form.start_time).getTime()
						} else {
							return time.getTime() < Date.now() - 8.64e7; //当前时间之后
						}
					},
				},

				/* end*/
				isSingle: true,
				uploadFlag: false, //是否展示选择图片弹窗
				//规格弹框
				dialogSpecVisible: false, //商品规格
				specList: {},
				checkedData: [], // 初始化弹窗选中的行
				dialogVisible: false,
				//商品弹框
				search: {
					start_time:'',
					end_time:'',
					goods_source:'',
					cate_id:'',
					keyword:'',
				},
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				multipleSelectionAll: [], // 所有选中的数据包含跨页数据
				multipleSelection: [], // 当前页选中的数据
				idKey: "goods_unified_id", // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
				allCate: [],
				tableData: [], // 表格数据
				skuName: [],
				group_buying_activity_id: '',
				imgType: '',
				btnState: this.$route.query.type,
				selectVals: [],
				selectMain: 0, //设置主推
				goolength: 0, //选择商品的数量
				treedata:[],
				defaultProps: {
				          children: 'children',
				          label: 'name'
				        },
				tabPosition:'1',
				checkDefault:[]
			}
		},
		mounted() {
			if (this.$route.query.id) {
				this.group_buying_activity_id = this.$route.query.id;
				this.getDetailInfo()
			}
			this.$nextTick(function() {
				// 模拟初始化一个选中
				this.checkedData = []
			})

		},
		watch: {
			'checkedData': {
				handler(val) {
					// 转换一下目的是为了不被同步
					this.multipleSelectionAll = JSON.parse(JSON.stringify(val));
				},
				immediate: true,
				deep: true
			},
			'checkDefault':function(newVal, oldVal) {
			       if(newVal){
			         this.$nextTick(() => {
			              document.querySelector('.el-tree-node__content').click()
			          })
			       }
			     }
		},
		methods: {
			getDetailInfo() {
				let data = {
					group_buying_activity_id: this.group_buying_activity_id,
					type: 1
				}
				updateGroupBuyingActivity(data).then(res => {
					this.form = res.data;
					this.form.goods_list = res.data.group_buying_activity_goods;
					this.multipleSelectionAll = res.data.group_buying_activity_goods;
					this.multipleSelectionAll.forEach(item => {
						let aa = item.sku.some((val) => val.is_join == '1');
						if (aa == true) {
							item.is_set = 1
						} else {
							item.is_set = 2
						}
					})
				})
			},
			//正整数验证
			changeInput(){
				var pattern = /^[1-9][0-9]*$/ // 正整数的正则表达式
				      // 不符合正整数时
				      if (!pattern.test(this.num)) {
				        // input 框绑定的内容为空
				        this.num = ''
				      }
			},
			// 时间开始选择器
			startTimeStatus(val) {
				let date = new Date(val).getTime();
				let currentDate = new Date().getTime();
				this.startTime = date;
				if(this.startTime<currentDate){
					this.$message.error('开始时间要大于当前时间');
					this.form.start_time = '';
					this.startTime = null;
				}
				if (this.endTime) {
					if (this.endTime < this.startTime) {
						this.$message.error('开始时间不能大于结束时间');
						this.form.start_time = '';
						this.startTime = null;
					}
				}
			},
			// 时间结束选择器
			endTimeStatus(val) {
				let date = new Date(val).getTime();
				this.endTime = date;
				if (this.startTime) {
					if (this.endTime <= this.startTime) {
						this.$message.error('结束时间不能小开始时间');
						this.form.end_time = '';
						this.endTime = null;
					}
				}
			},
			uploadImg(type) {
				this.imgType = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				if (this.imgType == 'activity') {
					this.form.activity_image = val[0].resource_name;
				}
			},
			//选择拼团商品
			selectGood() {
				if(this.form.start_time=='' && this.form.end_time==''){
					this.$message.error("请选择活动时间！")
					return;
				}
				this.getAllCase();
				this.dialogVisible = true;
				// 初始化渲染
				this.currentPage = 1;
				this.getGoodsList();
			},
			//设置拼团价格库存
			setPrice(row) {
				this.dialogSpecVisible = true;
				this.specList = row;
				//let reguData = res.data.sku;
				var kk = [];
				if (this.specList.sku.length > 0 && this.specList.sku[0].skuname != null) {
					this.skuName = this.specList.sku[0].skuname;
				}
				this.specList.sku.forEach((item, index) => {
					if (item.sku_desc != null) {
						var sku_desc = item.sku_desc; //规格值
						index = 0;
						kk.push({
							vals1: item.sku_desc[index],
							vals2: item.sku_desc[index + 1],
							vals3: item.sku_desc[index + 2]
						});
					}
				});
				this.$forceUpdate();
				var obj = this.specList.sku.map((it, index) => {
					return {
						...it,
						...kk[index]
					};
				});
				this.$nextTick(() => {
					this.specList.sku = obj;
				});
				// console.log(this.specList, "aaaa")
			},
			sureAddSpec() {
				for (var i = 0; i < this.specList.sku.length; i++) {
					if (this.specList.sku[i].is_join == '1') {
						if (this.specList.sku[i].group_buying_stock <= 0 || this.specList.sku[i].group_buying_price <= 0) {
							this.$message.error("拼团库存或者拼团价格需大于0");
							return;
						}
					}

				}
				let row = [...this.form.goods_list];
				row.forEach(item => {
					let aa = item.sku.some((val) => val.is_join == '1');
					if (aa == true) {
						item.is_set = 1
					} else {
						item.is_set = 2
					}
				});
				this.form.goods_list = [...row];
				//console.log(this.form.goods_list,"888888444")
				this.dialogSpecVisible = false;

			},
			//多规格拼团库存不能大于现有库存
			changeStock(row) {
				if (row.group_buying_stock * 1 > row.total_stock * 1) {
					this.$message.error("拼团库存不能大于现有库存");
				}
			},
			//单规格拼团库存不能大于现有库存
			changeSinglePrice(row) {
				if (row.group_buying_stock * 1 > row.total_stock * 1) {
					this.$message.error("拼团库存不能大于总库存");
				}
			},
			//切换分类
			changeTab(type){
				this.tabPosition=type;
				this.currentPage = 1;
				this.getAllCase();
			},
			//获取所有商品分类
			getAllCase() {
				getCategoryList({type:this.tabPosition}).then(res => {
					this.treedata=res.data;
					let pp={
						id: '',
						img: "https://cattle-shop.oss-accelerate.aliyuncs.com/df7b689ed7b511eb959500163e0fb62d.png",
						is_show: 1,
						level: 0,
						name: "全部",
						parent_id: '',
					}
					this.treedata.unshift(pp);
					let aa=[];
					aa.push(this.treedata[0].id);
					this.checkDefault=aa;
				})
			},
			//分页
			handleSizeChange(size) {
				this.changePageCoreRecordData();
				this.pagesize = size;
				// 掉接口
				this.getGoodsList()
			},
			handleCurrentChange(currentPage) {
				this.changePageCoreRecordData();
				this.currentPage = currentPage;
				// 掉接口
				this.getGoodsList()
			},
			//搜索商品
			searchOrder() {
				this.getGoodsList()
			},
			toReset() {
				this.search.cate_id = '';
				this.search.keyward = '';
				this.search.is_publish = 1,
				this.search.is_verify = 1,
				this.getGoodsList()
			},
			sureAddGood() {
				this.changePageCoreRecordData();
				if (this.multipleSelectionAll.length <= 0) {
					this.$message({
						message: '无勾选数据！',
						type: 'warning'
					});
					return;
				};
				// if (this.multipleSelectionAll.length < 3) {
				// 	this.$message({
				// 		message: '最少选择3款商品！',
				// 		type: 'warning'
				// 	});
				// 	return;
				// }
				if (this.multipleSelectionAll.length > 50) {
					this.$message({
						message: '最多选择50款商品！',
						type: 'warning'
					});
					return;
				}
				console.log(this.multipleSelectionAll, "-----");

				this.form.goods_list = this.multipleSelectionAll;
				console.log(this.form.goods_list,"this.form.goods_list")

				this.dialogVisible = false;

			},
			// 设置选中的方法
			setSelectRow() {
				if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
					return;
				}
				// 标识当前行的唯一键的名称
				let idKey = this.idKey;
				let selectAllIds = [];
				let that = this;
				this.multipleSelectionAll.forEach(row => {
					selectAllIds.push(row[idKey]);
				});
				this.$refs.multipleTable.clearSelection();
				for (var i = 0; i < this.tableData.length; i++) {
					if (selectAllIds.indexOf(this.tableData[i][idKey]) >= 0) {
						// 设置选中，记住table组件需要使用ref="table"
						this.$refs.multipleTable.toggleRowSelection(this.tableData[i], true);

					}
				}
			},

			// 记忆选择核心方法
			changePageCoreRecordData() {
				// 标识当前行的唯一键的名称
				let idKey = this.idKey;
				let that = this;
				// 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
				if (this.multipleSelectionAll.length <= 0) {
					this.multipleSelectionAll = this.multipleSelection;
					return;
				}
				// 总选择里面的key集合
				let selectAllIds = [];
				this.multipleSelectionAll.forEach(row => {
					selectAllIds.push(row[idKey]);
				});
				let selectIds = [];
				// 获取当前页选中的id
				this.multipleSelection.forEach(row => {
					selectIds.push(row[idKey]);
					// 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
					if (selectAllIds.indexOf(row[idKey]) < 0) {
						that.multipleSelectionAll.push(row);
					}
				});
				let noSelectIds = [];
				// 得到当前页没有选中的id
				this.tableData.forEach(row => {
					if (selectIds.indexOf(row[idKey]) < 0) {
						noSelectIds.push(row[idKey]);
					}
				});
				noSelectIds.forEach(id => {
					if (selectAllIds.indexOf(id) >= 0) {
						for (let i = 0; i < that.multipleSelectionAll.length; i++) {
							if (that.multipleSelectionAll[i][idKey] == id) {
								// 如果总选择中有未被选中的，那么就删除这条
								that.multipleSelectionAll.splice(i, 1);
								break;
							}
						}
					}
				});
			},
			currentChange(val) {
				// 改变页的时候调用一次
				this.changePageCoreRecordData();
				this.currentPage = val;
				this.getGoodsList();
			},
			sizeChange(val) {
				// 改变每页显示条数的时候调用一次
				this.changePageCoreRecordData();
				this.pageSize = val;
				this.getGoodsList();
			},
			handleSelectionChange(val) {
				// table组件选中事件,记得加上@selection-change="handleSelectionChange"
				this.multipleSelection = val;
			},
			handleNodeClick(data) {
					this.search.cate_id=data.id;
					this.getGoodsList();
			  },
			//获取商品列表
			getGoodsList() {
				let data = {
					start_time:this.form.start_time,
					end_time:this.form.end_time,
					goods_source:this.tabPosition,
					cate_id:this.search.cate_id,
					keyword:this.search.keyword,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				getGoodsList(data).then(res => {
					this.tableData = res.data.list;
					this.total = res.data.count;
					if (this.tableData.length > 0) {
						this.$nextTick(function() {
							// 初始化渲染
							this.setSelectRow();
						})
					}
				})
			},
			//设为主推
			toSetMain(index) {
				this.selectMain = 0;
				this.form.goods_list.forEach(item => {
					if (item.is_master == 1 || item.is_master == '1') { //查询主推数量
						this.selectMain++;
					}
				})

				if (this.form.goods_list[index].is_master == '0' || this.form.goods_list[index].is_master == 0) {
					if (this.selectMain < 3) {
						this.selectMain++;
					}else {
						this.$message.error("只能选择三款商品作为主推！");
						return;
					}
					this.form.goods_list[index].is_master = '1';
				} else if (this.form.goods_list[index].is_master == '1' || this.form.goods_list[index].is_master == 1) {
					this.selectMain--;
					//console.log(this.selectMain, "----")
					if (this.selectMain <= 0) {
						this.selectMain = 0;
					}
					this.form.goods_list[index].is_master = '0';
				}
			},
			//删除
			toDel(index) {
				this.tools.confirm("确定要删除此商品吗？").then(res => {
					if (res) {
						//调接口
						let row = [...this.form.goods_list];
						row.splice(index, 1)
						this.form.goods_list = [...row];
						console.log(this.form.goods_list,"this.form.goods_list")
						 this.multipleSelectionAll=this.form.goods_list ;
						this.$forceUpdate();
						this.selectMain--;
					}
				})
			},
			// 得到选中的所有数据
			getAllSelectionData() {
				// 再执行一次记忆勾选数据匹配，目的是为了在当前页操作勾选后直接获取选中数据
				this.changePageCoreRecordData();
			},
			//整体提交
			cancelSet() {
				this.$router.push({
					path: "/marketingcenteradmin/luckypuzzle/luckypuzzlelist",
				})
			},
			toBack() {
				this.$router.push({
					path: "/marketingcenteradmin/luckypuzzle/luckypuzzlelist",
				})
			},
			//提交设置
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data = JSON.parse(JSON.stringify(this.form));
						for (let i = 0; i < data.goods_list.length; i++) {
							data.goods_list[i].sku = data.goods_list[i].sku.filter(item => {
								if (item.is_join == 1) return item
							})
						}
						data.goods_list = data.goods_list.filter(item => {
							if (item.sku.length != 0) return item
						});

						// if (this.startTime == null) {
						// 	data.start_time = new Date(data.start_time).getTime() / 1000
						// } else {
						// 	data.start_time = this.startTime / 1000;
						// }
						// if (this.endTime == null) {
						// 	data.end_time = new Date(data.end_time).getTime() / 1000
						// } else {
						// 	data.end_time = this.endTime / 1000;
						// }

						data.activity_image = this.tools.filterImgPre(data.activity_image);
						var flagv=data.goods_list.find(value=>value.is_master==1);
						if(flagv == undefined){
							this.$message.error("请设置主推商品！");
							return;
						}
						data.goods_list = JSON.stringify(data.goods_list);
						delete data["group_buying_activity_goods"];
						data = this.tools.filterParams(data);
						if (this.group_buying_activity_id) {
							data.group_buying_activity_id = this.group_buying_activity_id;
							data.type = 2;
							updateGroupBuyingActivity(data).then(res => {
								this.$message.success("编辑成功！")
								this.$router.push({
									path: "/marketingcenteradmin/luckypuzzle/luckypuzzlelist",
								})
							})
						} else {
							addGroupBuyingActivity(data).then(res => {
								this.$message.success("添加成功！")
								this.$router.push({
									path: "/marketingcenteradmin/luckypuzzle/luckypuzzlelist",
								})
							})
						}


					} else {
						return false;
					}
				});
			}

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.el_img {
			width: 31px;
			height: 31px;
			margin-right: 6px;
		}
	}

	.priceIput>>>.el-input-group__append {
		padding: 0 8px;
	}

	.opera-cont {
		position: relative
	}

	.popovers {
		position: absolute;
		z-index: 999;
	}
	.treestyle ::v-deep.el-tree-node__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 36px;
    cursor: pointer;
}

.treestyle ::v-deep .el-tree-node__content {
      &:hover {
        background: #DAEDFF;
		color: #1890FF;
      }
    }
    .treestyle ::v-deep .el-tree-node:focus>.el-tree-node__content{
      background-color: #DAEDFF;
	  color: #1890FF;
    }
</style>
