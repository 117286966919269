<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card class="box-card" shadow="never">
                    <el-row class="all_search_list" style="margin-bottom:0">
                        <div class="demo-input-suffix">
                            <el-input clearable class="inputsWidth" prefix-icon="el-icon-search" v-model="order_number"
                                placeholder="请输入订单编号" size="small" type="text">
                            </el-input>
                        </div>
                        <!-- <div class="demo-input-suffix">
                            <el-select style="width: 150px;" size="small" v-model="search_type" placeholder="请选择订单类型"
                                filterable clearable>
                                <el-option label="订单号" value="1"></el-option>
                                <el-option label="姓名" value="2"></el-option>
                                <el-option label="手机号" value="3"></el-option>
                            </el-select>
                        </div> -->
                        <div class="demo-input-suffix">
                            <el-button type="primary" size="small" @click="searchOrder">查询</el-button>
                            <el-button size="small" @click="toReset">重置</el-button>
                        </div>
                    </el-row>
                </el-card>
                <div class="main-cont mt10">
                    <el-table :data="tableData" border style="width: 100%" v-loading="loading"
                        element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.2)" max-height="550px"
                        :header-cell-style="{background:'#f3f8fe',color:'#606060'}">
                        <el-table-column label="合伙人名称" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.user_info">
                                    <p>{{ scope.row.user_info.username }}</p>
                                    <p>{{ scope.row.user_info.account }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="购买等级" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.user_info">
                                    <p>{{ scope.row.user_info.level }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="支付金额" prop="goods_price" align="center">
                        </el-table-column>
                        <el-table-column label="邀请人" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.p_user_info">
                                    <p>{{ scope.row.p_user_info.username }}</p>
                                    <p>{{ scope.row.p_user_info.account }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="所属部门" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.user_info">
                                    <p>{{ scope.row.user_info.buyer_type }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="订单号" prop="order_number" align="center">
                        </el-table-column>
                        <el-table-column label="下单时间" prop="created_at" align="center">
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10,20,30,50]"
                        :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    partnerOrderList
} from "@/api/plat/order.js"
export default {
    data() {
        return {
            order_number: '',
            // search_type: '',
            tableData: [],
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
            loading: true,
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            let data = {
                page: this.currentPage,
                perPage: this.pagesize,
                order_number: this.order_number
            }
            partnerOrderList(data).then(res => {
                this.loading = false;
                this.total = res.data.count;
                this.tableData = res.data.list;

            })
        },
        //搜索
        searchOrder() {
            this.currentPage = 1;
            this.getList()
        },
        //重置
        toReset() {
            this.currentPage = 1;
            this.order_number = '';
            this.getList()
        },
        handleSizeChange(size) {
            this.pagesize = size;
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getList()
        },
    }
}
</script>
<style lang="less" scoped>

</style>