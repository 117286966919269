import axios from '@/config/httpConfig'
import qs from  'qs'
//店长分润设置
export const managerFenRunSetting=(data)=> {
    return axios('post','Commission/platform/managerFenRunSetting', data);
}
//获取店长分润设置信息
export const managerFenRunList=(data)=> {
    return axios('post','Commission/platform/managerFenRunList', data);
}
//获取服务商分润信息
export const channelAllocationInfo=(data)=> {
    return axios('post','Commission/platform/channelAllocationInfo', data);
}
//服务商分润设置-全部提交
export const channelAllocationSetting=(data)=> {
    return axios('post','Commission/platform/channelAllocationSetting', data);
}
//分佣配置详情
export const commissionConfigDetail=(data)=> {
    return axios('post','Commission/platform/commissionConfigDetail', data);
}
//编辑分佣配置
export const editCommissionConfig=(data)=> {
    return axios('post','Commission/platform/editCommissionConfig', data);
}
//全局配置详情
export const platConfigDetail=(params)=> {
    return axios('get','Commission/platform/platConfigDetail', params)
}
//编辑全局配置
export const editPlatConfig=(data)=> {
    return axios('post','Commission/platform/editPlatConfig', data);
}