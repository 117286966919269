<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-row type="flex" class="row-bg" justify="space-between">
						<el-col :span="18">
							<div class="grid-content bg-purple flex">
								<el-select placeholder="账户类型" size="small" v-model="search.order_type"
									class="mr20 w120">
									<el-option label="全部" value=""></el-option>
									<!-- <el-option label="充值" value="1"></el-option> -->
									<el-option label="插件购买" value="2"></el-option>
									<el-option label="店铺购买" value="3"></el-option>
									<el-option label="套餐购买" value="4"></el-option>
									<el-option label="店铺升级" value="5"></el-option>
								</el-select>
								<el-date-picker style="width: 350px" class="mr20" size="small" :clearable="false"
									v-model="dute" type="daterange" range-separator="至" start-placeholder="开始日期" :default-time="['00:00:00', '23:59:59']"
									end-placeholder="结束日期" @change="changetimer">
								</el-date-picker>
								<el-button type="primary" size="small" @click="toSearch()">查询</el-button>
								<el-button size="small" @click="toReset()">重置</el-button>
								<el-button style="margin-left: 20%;" type="primary" size="small" @click="toRecharge">充值
								</el-button>
							</div>
						</el-col>

					</el-row>
				</div>
				<div class="main-cont bgwt mt10">
					<el-table :data="tableData" style="width: 100%" min-height="250" border  max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="index" width="50" label="序号" align="center">
						</el-table-column>
						<el-table-column prop="total_money" label="总金额" width="180" sortable align="center">
						</el-table-column>
						<el-table-column prop="discount" label="折扣" sortable align="center">
						</el-table-column>
						<el-table-column prop="type" label="类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.order_type == '1'" style="color: #F56C6C;">充值</span>
								<span v-else-if="scope.row.order_type == '2'">插件购买</span>
								<span v-else-if="scope.row.order_type == '3'">店铺购买</span>
								<span v-else-if="scope.row.order_type == '4'">套餐购买</span>
								<span v-else-if="scope.row.order_type == '5'">店铺升级</span>
								<span v-else-if="scope.row.order_type == '6'">皮肤购买</span>
							</template>
						</el-table-column>
						<el-table-column prop="actual_payment" label="支付金额" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="提交时间" align="center">
						</el-table-column>
						<el-table-column prop="remark" width="280px" label="备注信息" show-overflow-tooltip align="center">
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<!--充值-->
		<el-dialog title="充值金额" :visible.sync="dialogRechargeVisible" width="35%">
			<div class="mb10">
				<el-form :model="recharge" ref="recharge" :rules="rules" label-width="100px"
					class="demo-ruleForm formBox">
					<el-form-item label="充值类型:">
						<el-radio-group v-model="recharge.radiotype" @change="changeRadio">
							<!--  <el-radio :label="1">实付金额</el-radio> -->
							<el-radio :label="2">套餐充值</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="储值套餐:" v-if="recharge.radiotype == '2'">
						<el-select v-model="recharge.combo" size="small" @change="getComboVal(recharge.combo)"
							class="inputWidth" placeholder="全部">
							<el-option v-for="item in comboList" :key="item.pet_card_id" :label="item.pet_card_title"
								:value="item.pet_card_id"></el-option>
						</el-select>
						<div>
							<label>折扣 </label>
							<el-input size="small" v-model="comboVal.discount" style="width: 80px;"></el-input>%
							<label>充值金额 </label>
							<el-input size="small" v-model="comboVal.package_price" style="width: 80px;"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="充值金额:" v-if="recharge.radiotype == '1'" prop="money">
						<el-input size="small" v-model="recharge.money" class="inputWidth" @change="inputMoney">
						</el-input>
					</el-form-item>
					<el-form-item label="实付金额:" style="color:#F56C6C;font-weight: bold;"
						v-if="recharge.radiotype == '1' && recharge.money">
						<span v-if="recharge.money">¥{{recharge.money}}</span>
					</el-form-item>
					<el-form-item label="实付金额:" style="color:#F56C6C;font-weight: bold;"
						v-if="recharge.radiotype == '2' && recharge.combo">
						<span v-if="comboVal.selling_price">¥{{comboVal.selling_price}}</span>
					</el-form-item>
					<el-form-item label="支付类型" v-if="recharge.money || comboVal.selling_price">
						<el-radio v-model="recharge.payType" @change="changePayRadio" label="1">微信</el-radio>
						<el-radio v-model="recharge.payType" @change="changePayRadio" label="2">支付宝</el-radio>
					</el-form-item>
					<el-form-item label="二维码" v-if="recharge.money || comboVal.selling_price">
						<div class="qr_code">
							<div v-if="recharge.payType==1">
								<img v-if="recharge_qrCode" :src="recharge_qrCode" width="95" height="95"
									style="overflow:hidden;" />
							</div>
							<div v-if="recharge.payType==2">
								<iframe v-if="recharge_qrCode" :srcdoc="recharge_qrCode" frameborder="no" border="0"
									marginwidth="0" marginheight="0" scrolling="no" width="95" height="95"
									style="overflow:hidden;">
								</iframe>
							</div>

						</div>
					</el-form-item>
					<el-form-item v-if="recharge.money || comboVal.selling_price"><span
							class="pstyle">{{recharge.payType=="1"?"请用微信扫一扫授权":"请用支付宝扫一扫授权"}}</span></el-form-item>
					<el-form-item v-if="recharge.money || comboVal.selling_price">
						<el-button type="primary" @click="submitPay('recharge')" size="small">确认支付完成</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<!--订单付款-->
		<el-dialog title="订单付款" :visible.sync="dialogPayVisible" width="30%">
			<div class="statepic"></div>
			<div class="paystate">支付失败</div>
			<div class="paybtn">
				<el-button type="primary" size="small">重新支付</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		VirtualOrderList,
		petCardList,
		rechargeAmount,
		getVirtualOrderStatus
	} from '@/api/channel/order.js'
	export default {
		data() {
			return {
				loading:true,
				search: {
					order_type: '',
					beginTime: '',
					endTime: ''
				},
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				dialogRechargeVisible: false,
				recharge: {
					radiotype: 2,
					money: '',
					combo: '',
					discount: '',
					rechargeMoney: '',
					payType: '1'
				},
				rules: {
					money: [{
						required: true,
						message: '请填写金额',
						trigger: 'blur'
					}],
				},
				dialogPayVisible: false,
				dute: '',
				comboList: [],
				comboVal: {},
				recharge_qrCode: '',
				order_nuber: ''
			}
		},
		mounted() {
			this.getList();

		},
		methods: {
			//表格数据
			getList() {
				let params = this.search;
				params.page = this.currentPage;
				params.perPage = this.pagesize;
				params = this.tools.filterParams(params)
				VirtualOrderList(params).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
					this.tableData.forEach(item => {
						if (item.discount) {
							item.discount = item.discount + '%'
						}
					})
				})
			},
			//切换获取储值套餐
			changeRadio(val) {
				if (val == 2) {
					this.cardList()
				}
			},
			//储值套餐下拉列表
			cardList() {
				petCardList({}).then(res => {
					this.comboList = res.data;

				})
			},
			//选择下拉获取值
			getComboVal(val) {
				this.comboList.forEach(item => {
					if (item.pet_card_id == val) {
						this.comboVal = item
					}
				})
				this.getRechargeCode()
			},
			//充值弹框
			toRecharge() {
				this.recharge.combo = '';
				this.recharge_qrCode = '';
				this.comboVal = {};
				this.cardList()
				this.dialogRechargeVisible = !this.dialogRechargeVisible;
			},
			//输入值失去焦点
			inputMoney(val) {
				this.recharge.money = val;
				this.getRechargeCode()
			},
			//选择支付方式
			changePayRadio(val) {
				this.recharge.payType = val;
				if (this.recharge.money || this.comboVal.selling_price) {
					this.getRechargeCode();
				}
			},
			//获取支付二维码
			getRechargeCode() {
				let data = {
					order_type: this.recharge.radiotype == 1 ? '1' : '4',
					payType: this.recharge.payType,
					money: this.recharge.radiotype == 1 ? this.recharge.money : this.comboVal.selling_price,
				}
				if (data.order_type == '4') {
					data.commodity_id = this.comboVal.pet_card_id
				};
				rechargeAmount(data).then(res => {
					this.recharge_qrCode = res.data.images;
					this.order_nuber = res.data.order_nuber
				})
			},
			//提交充值
			submitPay(recharge) {
				this.$refs[recharge].validate((valid) => {
					if (valid) {
						let params = {
							order_nuber: this.order_nuber
						}
						getVirtualOrderStatus(params).then(res => {
							if (res.data == 1) {
								this.$message.error("请支付！")
							} else if (res.data == 2) {
								this.$message.success("支付成功！")
								this.dialogRechargeVisible = !this.dialogRechargeVisible;
								this.getList();
							} else if (res.data == 4) {
								this.$message.error("支付失败！")
								this.dialogRechargeVisible = !this.dialogRechargeVisible
							}
						})
					} else {
						return false;
					}
				});

			},
			//时间选择
			changetimer(val) { //时间
				this.search.beginTime = val[0] / 1000;
				this.search.endTime = val[1] / 1000;
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//搜索
			toSearch() {
				this.currentPage = 1;
				//调接口
				this.getList()
			},
			//重置
			toReset() {
				this.currentPage = 1;
				this.search.order_type = '';
				this.search.beginTime = '';
				this.search.endTime = '';
				this.dute = "";
				this.getList()
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	::v-deep.el-form-item {
		margin-bottom: 8px;
	}

	.statepic {
		width: 185px;
		height: 185px;
		margin: 0 auto;
		background-color: red;
	}

	.paystate {
		margin-top: 10px;
		text-align: center;
		color: #333;
		font-size: 16px;
	}

	.paybtn {
		width: 100%;
		margin-top: 15px;
		text-align: center;
	}
</style>
