<template>
	<div>
		<div class="container">
			<div class="col-md-10 col-md-offset-1">
				<div class="oTree" id="main">
					<vue2-org-tree :data="data" :horizontal="true" :collapsable="false"
						:label-class-name="labelClassNameFun" :render-content="renderContent" @on-expand="onExpand"
						@on-node-click="onNodeClick" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import html2canvas from "html2canvas";
	export default {
		props: {
			data: {
				type: Object,
				default () {
					return {
					}
				}
			}
		},
		data() {
			return {

			};
		},
		mounted() {},
		methods: {
			//层级颜色
			labelClassNameFun(data) {
				let clorearr = [
					"bg1",
					"bg2",
					"bg3",
					"bg4",
					"bg5",
					"bg6",
					"bg7",
					"bg8",
					"bg9",
					"bg10",
				];
				let ind = data.level;
				if (ind > clorearr.length) {
					ind = ind - clorearr.length;
				}
				return clorearr[ind + 1];
			},

			// 节点内容
			renderContent(h, data) {
				// console.log(data)
				return ( <
					div >
					<
					p style = "padding:0;margin:0;" > {
						data.label
					} < /p> <
					p style = "padding:0;margin:0;" > {
						data.account
					} < /p> <
					p style = "padding:0;margin:0;" > {
						data.username
					} < /p> <
					p style = "padding:0;margin:0;" > ({
						data.level
					}) < /p> < /
					div >
				);
			},
			// 节点点击展开/折叠（注意：参数e需要携带）
			onExpand(e, data) {
				if ("expand" in data) {
					data.expand = !data.expand;
					if (!data.expand && data.children) {
						this.collapse(data.children);
					}
				} else {
					this.$set(data, "expand", true);
				}
			},
			// 节点点击
			onNodeClick(e, data) {
				// alert(data.label);
				console.log(data);
			},
			collapse(list) {
				var _this = this;
				list.forEach(function(child) {
					if (child.expand) {
						child.expand = false;
					}
					child.children && _this.collapse(child.children);
				});
			},
			// 是否全部展开
			expandChange() {
				this.toggleExpand(this.data, this.expandAll);
			},
			toggleExpand(data, val) {
				var _this = this;
				if (Array.isArray(data)) {
					data.forEach(function(item) {
						_this.$set(item, "expand", val);
						if (item.children) {
							_this.toggleExpand(item.children, val);
						}
					});
				} else {
					this.$set(data, "expand", val);
					if (data.children) {
						_this.toggleExpand(data.children, val);
					}
				}
			},
			// 下载
			down() {
				let width = document.getElementById("main").offsetWidth,
					height = document.getElementById("main").offsetHeight;

				// 截图下载
				html2canvas(document.getElementById("main"), {
					backgroundColor: "white", //画出来的图片有白色的边框,不要可设置背景为透明色（null）
					useCORS: true, //支持图片跨域
					scale: 1, //设置放大的倍数
					width, //画布的宽高
					height,
				}).then((canvas) => {
					console.log("宽", width, "高", height);
					//截图用img元素承装，显示在页面的上
					// let img = new Image()
					//如果你需要下载截图，可以使用a标签进行下载
					let a = document.createElement("a");
					a.href = canvas.toDataURL("image/pdf");
					a.download = "test";
					a.click();
				});
			},
		},
	};
</script>

<style>
	.bg1 {
		background-color: #55b9da;
	}

	.bg2 {
		background-color: #83af9b;
	}

	.bg3 {
		background-color: #c8c8a9;
	}

	.bg4 {
		background-color: #f9cdad;
	}

	.bg5 {
		background-color: #fc9d9a;
	}

	.bg6 {
		background-color: #ff96b9;
	}

	.bg7 {
		background-color: #ff5e48;
	}

	.bg8 {
		background-color: #fe4365;
	}

	.bg9 {
		background-color: #e69b14;
	}

	.bg10 {
		background-color: #dbd0a7;
	}

	.oTree {
		margin-top: 50px;
		text-align: center;
		min-width: 100%;
		min-height: 100%;
	}

	.oTree:deep(.org-tree-node-label-inner) {
		white-space: nowrap;
		background-color: white;
		border-radius: 5px;
		padding: 10px 20px;
		border: 2px #ccc solid;
	}
</style>
