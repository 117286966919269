<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt" style="padding: 0;">
					<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
						<el-tab-pane label="已上架" name="1"></el-tab-pane>
						<el-tab-pane label="待上架" name="0"></el-tab-pane>
						<el-tab-pane label="已售空" name="2"></el-tab-pane>
						<el-tab-pane label="仓库中" name="3"></el-tab-pane>
					</el-tabs>
				</div>
				<div class="main-cont bgwt mt10">
					<div class="order-search" style="padding: 0;">
						<el-row class="all_search_list" style="border-bottom: 1px solid #F2F2F6; padding-bottom: 20px;">
							<div class="demo-input-suffix">
								<el-input prefix-icon="el-icon-search" clearable placeholder="请输入商品名称" size="small"
									type="text" v-model="search.keyword"   @keyup.enter.native="toSearch">
								</el-input>
							</div>
							<div class="demo-input-suffix">
								<el-select v-model="search.one_cat_id" filterable clearable size="small" class="selectWidth"
									placeholder="请选择一级分类" @change="getCats(1)">
									<el-option v-for="item in oneLevel" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
								<el-select v-model="search.two_cat_id" filterable clearable size="small" class="selectWidth"
									style="margin-left:10px" placeholder="请选择二级分类"
									v-if="twoLevel && twoLevel.length > 0">
									<el-option v-for="item in twoLevel" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
							<div class="demo-input-suffix">
								<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
							</div>
							<div class="demo-input-suffix">
								<el-button size="small" @click="toReset">重置</el-button>
							</div>
						</el-row>
						<el-row class="all_search_list" style="margin-bottom: 0;">
							<el-button size="small" @click="toCattle">众康联淘货</el-button>
							<el-button size="small" v-if="activeName == '3'|| activeName == '0'"
								@click="batchOpera('up')">批量上架</el-button>
							<el-button size="small" v-if="activeName == '1' || activeName == '3'"
								@click="batchOpera('down')">批量下架</el-button>
							<el-button size="small" @click="batchOpera('del')">批量删除</el-button>
						</el-row>
					</div>
				</div>
				<div class="main-cont bgwt mt10">
					<el-table :data="tableData" style="width: 100%" min-height="250" max-height="550" border
						@selection-change="handleSelectionChange" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column label="商品名称" width="350" align="left">
							<template slot-scope="scope">
								<div class="el_name">
									<!-- <el-popover placement="right" title width="200" trigger="hover">
										<div style="width:200px;height:200px;">
										  <img
											style="width:100%;height:100%;object-fit: cover;"
											:src="scope.row.goods_main_pic.split(',')[0]"
											alt
										  />
										</div>
										<div style="width:70px;height:70px;" slot="reference">
										 <img :src="scope.row.goods_main_pic.split(',')[0]" class="el_img"/>
										</div>
									  </el-popover> -->
									<div style="width:70px;height:70px;" >
											<el-image style="width: 100%;height: 100%;"  :src="scope.row.goods_main_pic.split(',')[0]" :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]" class="el_img"/>
										</div>
									<!-- <img :src="scope.row.goods_main_pic.split(',', 1)" class="el_img" /> -->
									<div  style="width: 78%;margin-left: 10px;">
										<p>{{ scope.row.goods_name }}</p>
										<p class="mt10">{{ scope.row.count }}</p>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="sku_purchase_price" label="成本价" align="center">
						</el-table-column>
						<el-table-column prop="sku_sale_price" label="销售价" align="center">
						</el-table-column>
						<el-table-column prop="goods_category_name" label="商品分类" align="center">
						</el-table-column>
						<!-- <el-table-column prop="sku_sale_price" label="商品单位" align="center">
						</el-table-column> -->
						<el-table-column prop="goods_stock" label="总库存" align="center">
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_publish == '1'">上架</span>
								<span v-else-if="scope.row.is_publish == '2'">库存预警</span>
								<span v-else>下架</span>
							</template>
						</el-table-column>
						<el-table-column prop="sale" label="总销量" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="创建时间" align="center" width="200px">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button type="text" @click="toDetail(scope.row)">编辑</el-button>
								<el-button type="text" @click="toDel(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		goods_list,
		cate_list,
		goods_delete,
		goods_putaway
	} from "@/api/merchant/good.js"
	export default {
		data() {
			return {
				loading:true,
				activeName: '1',
				goodsName: '',
				deliverystate: '',
				search: {
					is_publish: '1',
					keyword: '',
					one_cat_id: '',
					two_cat_id: '',
					goods_source: '1',
				},
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				multipleSelection: [],
				oneLevel: [],
				twoLevel: [],
				cateId: "", //判断是否需要清空二级分类id
			}
		},
		mounted() {
			this.getCats();
			if (this.$route.query.activeName) {
				this.activeName = this.$route.query.activeName;
				this.search.is_publish = this.$route.query.activeName;
				this.getList();
			} else {
				this.getList();
			}
		},
		methods: {
			//获取分类
			getCats(type) {
				// console.log(type, 新增商品);
				if (type == 1) {
					this.search.cate_id = this.search.one_cat_id;
					//清空二级分类
					this.search.two_cat_id && (this.search.two_cat_id = "");
				}
				cate_list({
						parent_id: this.search.cate_id ? this.search.cate_id : 0
					})
					.then((res) => {
						// console.log(新增商品);
						if (type == 1) {
							this.twoLevel = res.data;
						} else {
							this.oneLevel = res.data;
						}
					})
					
			},
			//获取列表
			getList() {
				let data = {
					is_publish: this.search.is_publish,
					keyword: this.search.keyword,
					cate_id: this.search.two_cat_id ? this.search.two_cat_id : this.search.one_cat_id,
					goods_source: '1',
					page: this.currentPage,
					perPage: this.pagesize
				};
				data.page = this.currentPage;
				data.perPage = this.pagesize;
				data = this.tools.filterParams(data);
				goods_list(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list
				})
			},
			handleClick(tab) {
				this.search.keyword = '';
				this.currentPage = 1;
				this.search.one_cat_id = '';
				this.search.two_cat_id = '';
				this.twoLevel = [];
				this.twoLevel = [];
				this.cateId = "";
				this.search.is_publish = tab.name;
				this.getList();
			},
			//搜索
			toSearch() {
				this.currentPage = 1;
				this.getList();
			},
			//重置
			toReset() {
				this.currentPage = 1;
				this.search.keyword = '';
				this.search.one_cat_id = '';
				this.search.two_cat_id = '';
				this.twoLevel = [];
				this.twoLevel = [];
				this.cateId = "";
				this.getList();
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList();
			},
			toDetail(row) {
				this.$router.push({
					path: '/merchandiseadmin/cattleshopmerchandise/cattleshopdetail',
					query: {
						id: row.goods_unified_id,
						activeName: this.activeName
					}
				})
			},
			//新增商品
			toAdd() {
				this.$router.push({
					path: '/merchandiseadmin/cattleshopmerchandise/cattleshopdetail'
				})
			},
			//批量选择
			handleSelectionChange(val) {
				let ids = []
				val.forEach(item => {
					ids.push(item.goods_unified_id)
				})
				this.multipleSelection = ids;
			},
			//批量操作
			batchOpera(type) {
				if (this.multipleSelection.length == 0) {
					this.$message.warning("请选择要操作的商品！");
					return;

				}
				if (type == 'up') {
					this.tools.confirm("确定要上架？").then(res => {
						if (res) {
							//调接口
							let data = {
								goods_unified_id: this.multipleSelection.toString(),
								is_publish: 1
							}
							goods_putaway(data).then(res => {
								this.getList()
							})
						}
					})
				}
				if (type == 'down') {
					this.tools.confirm("确定要下架？").then(res => {
						if (res) {
							//调接口
							let data = {
								goods_unified_id: this.multipleSelection.toString(),
								is_publish: 0
							}
							goods_putaway(data).then(res => {
								this.getList()
							})
						}
					})
				}
				if (type == 'del') {
					this.tools.confirm("确定要删除？").then(res => {
						if (res) {
							//调接口
							let data = {
								goods_unified_id: this.multipleSelection.toString()
							}
							goods_delete(data).then(res => {
								this.getList()
							})
						}
					})
				}
			},
			//单个删除
			toDel(row) {
				this.tools.confirm("确定要删除？").then(res => {
					if (res) {
						//调接口
						let data = {
							goods_unified_id: row.goods_unified_id
						}
						goods_delete(data).then(res => {
							this.getList()

						})
					}
				})
			},
			//牛店淘货
			toCattle() {
				this.$router.push({
					path: '/shoppingadmin/shoppingindex'
				})
			}


		}
	}
</script>

<style lang="scss" scoped="scoped">
	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.el_img {
			width: 100%;
			height: 100%;
			margin-right: 6px;
		}
	}

	.pricetip {
		height: 30px;
		line-height: 30px;
		width: 300px;
		margin: 0 auto;
		text-align: center;
		background-color: #FFF0E5;
		color: #EB5E00;
		font-size: 14px;
	}

	::v-deep.el-tabs__header {
		padding: 0;
		position: relative;
		margin: 0 0 0;
	}
</style>
