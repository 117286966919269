<template>
	<div>
		<div class="wrapper-main pds">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="商户端海报" name="first">
						<el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small"
							class="demo-ruleForm">
							<div style="position: relative;z-index: 1;">
							<div class="wrapper-cont pp20">
								<div class="setTitle">店长入驻弹窗</div>
								<el-form-item label="弹窗广告:">
									<el-radio v-model="form.popup_status" label="1">开</el-radio>
									<el-radio v-model="form.popup_status" label="2">关</el-radio>
									<div v-if="form.popup_status == '1'">
										<div class="img_blos">
											<div class="img_blo" @click="uploadImg('popup_image')">
												<img :src="form.popup_image" class="item_img" v-if="form.popup_image" style="margin-right: 0!important;" />
												<div class="plusImg" v-else>
													<i class="el-icon-plus avatar-uploader-icon"></i>
												</div>
											</div>
										</div>
										<p class="imgtxtstyle">弹窗建议尺寸600*600px</p>
									</div>
								</el-form-item>
							</div>
							<div class="wrapper-cont pp20 mt10">
								<div class="setTitle">用户端海报</div>
								<el-form-item label="图标:">
									<div class="icon_blos">
										<div class="icon_blo" @click="uploadImg('user_icon')">
											<img :src="form.user_icon" class="item_img"
												v-if="form.user_icon" style="margin-right: 0!important;"  />
											<div class="iconImg" v-else>
												<i class="el-icon-plus avatar-uploader-icon"></i>
											</div>
										</div>
									</div>
									<p class="imgtxtstyle">弹窗建议尺寸50*50px</p>
								</el-form-item>
								<el-form-item label="海报轮播图:">
									<div class="img_blos">
										<div v-for="(item, index) in form.user_image" :key="index" class="img_blo"
											style="margin-bottom:15px">
											<i class="el-icon-error close_img" @click="delImg(index, 'user_image')"></i>
											<img :src="item" class="item_img" />
										</div>
										<div class="plusImg" @click="uploadImg('user_image')">
											<i class="el-icon-plus avatar-uploader-icon"></i>
										</div>
									</div>
									<p class="imgtxtstyle">海报建议尺寸500*820px，最多9张海报图</p>
								</el-form-item>
							</div>
							<div class="wrapper-cont pp20 mt10">
								<div class="setTitle">邀请店长海报</div>
								<el-form-item label="图标:">
									<div class="icon_blos">
										<div class="icon_blo"  @click="uploadImg('invite_icon')">
											<img :src="form.invite_icon" class="item_img"
												v-if="form.invite_icon" style="margin-right: 0!important;"/>
											<div class="iconImg" v-else>
												<i class="el-icon-plus avatar-uploader-icon"></i>
											</div>
										</div>
									</div>
									<p class="imgtxtstyle">弹窗建议尺寸50*50px</p>
								</el-form-item>
								<el-form-item label="海报轮播图:">
									<div class="img_blos">
										<div v-for="(item, index) in form.invite_image" :key="index"
											class="img_blo" style="margin-bottom:15px">
											<i class="el-icon-error close_img" @click="delImg(index, 'invite_image')"></i>
											<img :src="item" class="item_img" />
										</div>
										<div class="plusImg" @click="uploadImg('invite_image')">
											<i class="el-icon-plus avatar-uploader-icon"></i>
										</div>
									</div>
									<p class="imgtxtstyle">海报建议尺寸500*820px，最多9张海报图</p>
								</el-form-item>
							</div>
							<div style="color: #2589FF;cursor: pointer;font-size: 14px;position: absolute; z-index: 99; top:50px; right: 3%;" @mouseenter="mouseover"
								@mouseleave="mouseleave">示例</div>
							<div style="position: absolute; z-index: 99; top:50px; right: 7%;pointer-events:none;">
									<div style="display: flex;">
										<img src="../../../../../../assets/img/plat/newLong.png" style=" width: 235px;"
											v-show="imgshow" />
										
									</div>
								</div>
							</div>
						</el-form>
					
					
				</el-tab-pane>
				<el-tab-pane label="商户端公告" name="second">
					<div class="wrapper-cont">
						<div class="wrapper-main pp20">
							<el-row :gutter="20">
								<el-col :span="6">
									<div class="grid-content bg-purple">
										<el-button size="small"  type="primary" plain @click="toAdd" v-has="'新建公告'">新建公告</el-button>
										<el-button size="small" @click="allDel"  v-has="'删除'">删除</el-button>
									</div>
								</el-col>
								<el-col :span="6" :offset="6">
									<div class="grid-content bg-purple">
										  <el-input size="small" class="inputWidth"  clearable  prefix-icon="el-icon-search" placeholder="请输入内容" v-model="searchkey">
										    <template slot="append"><span @click="toSearch">搜索</span></template>
										  </el-input>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="wrapper-main mt10">
							<div class="tableMain imgs">
								<el-table :data="tableData" style="width: 100%" min-height="250" border   @selection-change="handleSelectionChange" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
									<el-table-column type="selection" width="55" align="center"></el-table-column>
									<el-table-column label="标题" prop="title" align="center"></el-table-column>
									<el-table-column label="封面" align="center">
										<template slot-scope="scope">
											<img :src="scope.row.banner" class="el_img" />
										</template>
									</el-table-column>
									<el-table-column label="状态" prop="status" align="center">
										<template slot-scope="scope">
											<span style="color:#FA6400;" v-if="scope.row.status=='1'">使用中</span>
											<span v-else-if="scope.row.status=='0'">未发布</span>
											<span v-else>已停止</span>
										</template>
									</el-table-column>
									<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
									<el-table-column label="操作" fixed="left">
										<template slot-scope="scope">
											<el-button type="text" size="small" @click="toDetail(scope.row)"  v-has="'详情'">详情</el-button>
											<el-button type="text" size="small" v-if="scope.row.status=='1'" @click="toStop(scope.row)">停止</el-button>
											<el-button type="text" size="small" @click="toEduit(scope.row)"   v-has="'编辑'">编辑</el-button>
											<el-button type="text" size="small" @click="toDel(scope.row)" v-has="'删除'">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
								<!-- 分页 -->
							<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
								:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
								layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
							</div>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="save-cont" v-if="activeName == 'first'">
			<div class="save-btn" @click="submitSet('form')">保存</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		addPosterSetting,
		getPosterSetting,
		editPosterSetting,
		getNoticePageList,
		delNotice,
		pauseNotice
		} from '@/api/plat/setting.js'
	import ChooseImg from "../../../../../common/uploadImgs";
	export default {
		components: {
			ChooseImg,
		},
		data() {
			return {
				loading:true,
				imgshow: false,
				isSingle: false, //上传图片是单张还是多张
				uploadFlag: false, //是否展示选择图片弹窗
				uploadType: '',
				activeName: 'first',
				form: {
					popup_status: '1',
					popup_image: '',
					user_icon: '',
					user_image: [],
					invite_icon: '',
					invite_image: []
				},
				rules: {

				},
				searchkey:'',
				tableData:[],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				multipleSelection:[]

			}
		},
		mounted() {
			this.getInfo();
			if(this.$route.query.activeName){
				this.activeName = this.$route.query.activeName;
				this.getList();
			}
		},
		methods: {
			getInfo(){
				getPosterSetting({}).then(res=>{
					this.form=res.data;
					this.setting_id=res.data.poster_setting_id;
					this.form.popup_status = res.data.popup_status.toString()
					
				})
			},
			getList(){
				let params={
					keyword:this.searchkey,
					page:this.currentPage,
					perPage:this.pagesize,
				}
				params=this.tools.filterParams(params);
				getNoticePageList(params).then(res=>{
					this.loading=false;
					this.tableData=res.data.list;
					this.total=res.data.count
					
				})
			},
			toSearch(){
				this.getList()
			},
			handleSizeChange(size) {
				this.pagesize = size;
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList();
			},
			handleClick(tab) {
				if(tab.name == 'first'){
					this.getInfo()
				}
				if(tab.name == 'second'){
					this.getList();
				}
			},
			mouseover() {
				this.imgshow = true;
			},
			mouseleave() {
				this.imgshow = false;
			},
			// 上传商品图
			uploadImg(type) {
				if (type == "user_icon" || type == "invite_icon" || type == "popup_image") {
					this.isSingle = true;
				} else {
					this.isSingle = false;
				}
				this.uploadType = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				let type = this.uploadType;
				this.uploadFlag = false;
				if (type == "popup_image") {
					this.form.popup_image = val[0].resource_name;
				}
				if (type == "user_icon") {
					this.form.user_icon = val[0].resource_name;
				}
				if (type == "user_image") {
					val.forEach((res) => {
						if(this.form.user_image.length>=9){
							this.$message.warning("最多添加9张")
							return;
						}
						this.form.user_image = this.form.user_image.concat([res.resource_name]);
					});
				}
				if (type == "invite_icon") {
					
						this.form.invite_icon = val[0].resource_name;
					
				}
				if (type == "invite_image") {
					val.forEach((res) => {
						if(this.form.invite_image.length>=9){
							this.$message.warning("最多添加9张")
							return;
						}
						this.form.invite_image = this.form.invite_image.concat([res.resource_name]);
					});
				}
			},
			//图片删除
			delImg(index, type) {
				if (type == "user_image") {
					this.form.user_image.splice(index, 1);
				}
				if (type == 'invite_image') {
					this.form.invite_image.splice(index, 1);
				}
			},
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						let data=JSON.parse(JSON.stringify(this.form));
						data.popup_image=this.tools.filterImgPre(data.popup_image);
						data.user_icon=this.tools.filterImgPre(data.user_icon);
						let user_image = [];
						data.user_image.forEach((item) => {
							user_image.push(this.tools.filterImgPre(item));
						});
						data.user_image = user_image.join(",");
						data.invite_icon=this.tools.filterImgPre(data.invite_icon);
						let invite_image = [];
						data.invite_image.forEach((item) => {
							invite_image.push(this.tools.filterImgPre(item));
						});
						data.invite_image = invite_image.join(",");
						data=this.tools.filterParams(data);
						if(data.poster_setting_id){
							data.setting_id=this.setting_id;
							editPosterSetting(data).then(res=>{
								this.$message.success("设置成功!");
								this.getInfo();
							})
						}else{
							addPosterSetting(data).then(res=>{
								this.$message.success("添加成功!");
								this.getInfo();
							})
						}
						

					} else {
						return false;
					}
				});
			},
			//新增公告
			toAdd(){
				this.$router.push({
					path:"/settingadmin/merchantsshop/noticedetail",
					query:{
						type:'add'
					}
				})
			},
			//删除公告
			toDel(row){
				this.tools.confirm("确定删除？").then(res=>{
						if(res){
							//调接口
							let data={
								notice_id:row.shop_notice_id
							}
							delNotice(data).then(res=>{
								this.getList()
								
							})
						}
					})
			},
		   handleSelectionChange(val) {
			   let ids=[];
			   val.forEach(item=>{
				   ids.push(item.shop_notice_id)
			   })
				this.multipleSelection = ids;
			},
			allDel(){
				if(this.multipleSelection.length == 0){
					this.$message.info("请选择要操作的数据！");
					return;
				}
				this.tools.confirm("确定批量删除？").then(res=>{
					if(res){
						let data={
							notice_id:this.multipleSelection.toString()
						}
						delNotice(data).then(res=>{
							this.getList()
						})
					}
				})
			},
			toDetail(row){
				this.$router.push({
					path:"/settingadmin/merchantsshop/noticedetail",
					query:{
						id:row.shop_notice_id,
						type:'detail'
					}
				})
			},
			toEduit(row){				this.$router.push({					path:"/settingadmin/merchantsshop/noticedetail",					query:{						id:row.shop_notice_id,
						type:'eduit'					}				})			},
			toStop(row){
				let data={
					notice_id:row.shop_notice_id,
				}
				pauseNotice(data).then(res=>{
					this.getList()
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.imgs .el_img {
	    width: 72px;
	    height: 34px;
	}
	.wrapper-main::v-deep.el-tabs__nav-wrap::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background-color: #fff;
		z-index: 1;
	}

	.wrapper-main::v-deep.el-tabs__item.is-active {
		background-color: #2589FF;
		color: #fff;
	}

	.wrapper-main::v-deep.el-tabs__item {
		padding: 0 20px;
		height: 34px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		line-height: 34px;
		display: inline-block;
		list-style: none;
		font-size: 14px;
		font-weight: 500;
		margin-right: 40px;
		text-align: center;
		color: #303133;
		position: relative;
		border-radius: 4px;
	}

	.wrapper-main::v-deep.el-tabs__item.is-top:nth-child(2) {
		padding-left: 20px;
	}

	.wrapper-main::v-deep.el-tabs--top .el-tabs__item.is-top:last-child {
		padding-right: 20px;
	}

	.wrapper-main::v-deep.el-tabs__active-bar {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 2px;
		background-color: transparent;
		z-index: 1;
	}

	.wrapper-main::v-deep.el-tabs__nav {
		margin-left: 20px;
	}
	.pds{
		padding-bottom: 120px !important;
	}
</style>
