<template>
    <div>
        <div class="wrapper-main">
			<div class="sub-title">活动名称：{{form.activity_name}}({{form.activity_start_time}}-{{form.activity_end_time}})</div>
            <div class="wrapper-cont">
                <el-row :gutter="20">
					<el-col :span="4">
						<div class="grid-content bg-purple">
							<div class="title">优惠券领取数(张)</div>
							<div class="vals">{{form.count}}</div>
						</div>
					</el-col>
                    <el-col :span="4">
						<div class="grid-content bg-purple">
							<div class="title">优惠券使用数(张)</div>
							<div class="vals">{{form.used_num}}</div>
						</div>
					</el-col>
                    <el-col :span="4">
						<div class="grid-content bg-purple">
							<div class="title">参与会员人数</div>
							<div class="vals">{{form.user_num}}</div>
						</div>
					</el-col>
                     <el-col :span="12" style="background-color: #fff;">
                         <el-row :gutter="20">
                             <el-col :span="8">
                                 <div class="grid-content bg-purple">
                                    <div class="title">优惠券发放金额(元)</div>
                                    <div class="vals">{{form.payment_amount}}</div>
                                </div>
                             </el-col>
                              <el-col :span="8">
                                 <div class="grid-content bg-purple">
                                    <div class="title">优惠券已使用金额(元)</div>
                                    <div class="vals">{{form.amount_used}}</div>
                                </div>
                             </el-col>
                              <el-col :span="8">
                                 <div class="grid-content bg-purple">
                                    <div class="title">拉动消费金额(元)</div>
                                    <div class="vals">{{form.consumption_amount}}</div>
                                </div>
                             </el-col>
                         </el-row>
					</el-col>
                </el-row> 
                <div class="main-cont bgwt tableMain mt10">
					<div style="height: 40px;line-height: 35px; font-size: 14px;">活动详情</div>
					<el-table :data="form.list" style="width: 100%" border max-height="400" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column label="会员"  align="center">
							<template slot-scope="scope">
								<div class="el_name">
									<img v-if="scope.row.head_portrait" :src="scope.row.head_portrait" class="el_img" />
									<div>
										<p>{{ scope.row.nickname }}</p>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="券类型" align="center" >
							<template slot-scope="scope">
								<span v-if="scope.row">商品满减券</span>
							</template>
						</el-table-column>
						<el-table-column label="优惠券内容" prop="groupBuyingOrderNumber" align="center">
							<template slot-scope="scope">
								<span>满{{scope.row.minimum_consumption}}元，减{{scope.row.price}}元</span>
							</template>
						</el-table-column>
						<el-table-column label="领取时间" prop="add_time" align="center"></el-table-column>
						<el-table-column label="用券时间" prop="use_time" align="center"></el-table-column>
					</el-table>
					<!-- 分页 -->
					<!-- <div class="pagecurrent" style="margin-top: 0;">
						<div class="pagination-container">
							<el-pagination background @size-change="handleSizeChange"
								@current-change="handleCurrentChange" :current-page="currentPage"
								:page-sizes="[5,10,15,20]" :page-size="pagesize"
								layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
						</div>
					</div> -->
				</div>  
            </div>
         </div>
    </div>
</template>
<script>
	import {
		couponActivityInfo
	} from '@/api/merchant/marketing.js'
export default{
    data(){
        return{
			loading:true,
			form:{},
            tableData: [],
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据   每页请求5条
			activity_id:this.$route.query.id
        }
    },
	mounted() {
			this.getInfo();
		},
    methods:{
			getInfo(){
				let data = {
					activity_id: this.activity_id,
					// page: this.currentPage,
					// perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				couponActivityInfo(data).then(res=>{
					this.loading=false;
					this.form=res.data

				})
			},
        	//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				//this.getActiveList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				//this.getActiveList()
			},
    }
}
</script>
<style scoped lang="scss">
	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.el_img {
			width: 31px;
			height: 31px;
			margin-right: 6px;
		}
	}
.sub-title {
		color: #2589FF;
		font-size: 14px;
		padding-bottom: 20px;
		margin-left: 10px;
	}

	.bg-purple {
		background: #fff;
		border-radius: 4px;
		text-align: center;
		padding: 20px 0;

		.title {
			line-height: 50px;
			font-size: 14px;
		}
	}

</style>