<template>
	<div>
		<div class="wrapper-main" style="margin-bottom: 30px;">
			<div class="model-content">
				<div class="specialmain">
					<div class="store-container">
						<div>
							存储方式:
							<el-radio-group v-model="storage_type"  @change="changeStorage" style="margin-left:100px">
								<!-- <el-radio  :label="0">关闭</el-radio>
								<el-radio  :label="1">七牛云存储</el-radio> -->
								<el-radio  :label="2">阿里云OSS</el-radio>
							</el-radio-group>
						</div>
						<el-form  v-if="storage_type == 1" :model="ruleFormqn" :rules="rulesqn" ref="ruleFormqn" label-width="200px" class="demo-ruleForm">
							<div class="store-infor">
									<el-form-item label="账号" prop="access_key" >
										<el-input class="labelWidth" v-model="ruleFormqn.access_key" placeholder="请输入账号" size="small"></el-input>
									</el-form-item>
									<el-form-item label="密钥" prop="secret_key"  >
										<el-input class="labelWidth" v-model="ruleFormqn.secret_key" placeholder="请输入账号" size="small"></el-input>
									</el-form-item>
									<el-form-item label="仓库区域" prop="endpoint"  >
										<el-input class="labelWidth" v-model="ruleFormqn.endpoint" placeholder="" size="small"></el-input>
									</el-form-item>
									<el-form-item label="公有仓库域名" prop="bucket_public_url"  >
										<el-input class="labelWidth" v-model="ruleFormqn.bucket_public_url" placeholder="请输入公有仓库域名" size="small"></el-input>
									</el-form-item>
								
									<el-form-item label="共有仓库名称" prop="bucket_public_name"  >
										<el-input class="labelWidth" v-model="ruleFormqn.bucket_public_name" placeholder="请输入共有仓库名称" size="small"></el-input>
									</el-form-item>
									<!-- <el-form-item label="私有仓库域名" prop="bucket_private_url"  >
										<el-input class="labelWidth" v-model="ruleFormqn.bucket_private_url" placeholder="请输入私有仓库域名" size="small"></el-input>
									</el-form-item>
									<el-form-item label="私有仓库名称" prop="bucket_private_name"  >
										<el-input class="labelWidth" v-model="ruleFormqn.bucket_private_name" placeholder="请输入私有仓库名称" size="small"></el-input>
									</el-form-item> -->
									<el-form-item label="是否为默认" prop="default" >
										<el-radio-group v-model="ruleFormqn.default">
											<el-radio :label="0">不使用</el-radio>
											<el-radio :label="1">使用</el-radio>
										</el-radio-group>
									</el-form-item>
							</div>
							<!-- <div class="save-cont" >
								<div class="save-btn" @click="submitFormqn('ruleFormqn')">提交</div>
							</div> -->
						</el-form>
						<el-form v-if="storage_type == 2" :model="ruleFormal" :rules="rulesal" ref="ruleFormal" label-width="200px" class="demo-ruleForm">
							<div class="store-infor">
									<el-form-item label="账号" prop="access_key" >
										<el-input class="labelWidth" v-model="ruleFormal.access_key" placeholder="请输入账号" size="small"></el-input>
									</el-form-item>
									<el-form-item label="密钥" prop="secret_key"  >
										<el-input class="labelWidth" v-model="ruleFormal.secret_key" placeholder="请输入账号" size="small"></el-input>
									</el-form-item>
									<el-form-item label="仓库区域" prop="endpoint"  >
										<el-input class="labelWidth" v-model="ruleFormal.endpoint" placeholder="http://oss-cn-hangzhou.aliyuncs.com" size="small"></el-input>
									</el-form-item>
									<el-form-item label="公有仓库域名" prop="bucket_public_url"  >
										<el-input class="labelWidth" v-model="ruleFormal.bucket_public_url" placeholder="请输入公有仓库域名" size="small"></el-input>
									</el-form-item>
									<el-form-item label="共有仓库名称" prop="bucket_public_name"  >
										<el-input class="labelWidth" v-model="ruleFormal.bucket_public_name" placeholder="请输入共有仓库名称" size="small"></el-input>
									</el-form-item>
									<!-- <el-form-item label="私有仓库域名" prop="bucket_private_url"  >
										<el-input class="labelWidth" v-model="ruleFormal.bucket_private_url" placeholder="请输入私有仓库域名" size="small"></el-input>
									</el-form-item>
									<el-form-item label="私有仓库名称" prop="bucket_private_name"  >
										<el-input class="labelWidth" v-model="ruleFormal.bucket_private_name" placeholder="请输入私有仓库名称" size="small"></el-input>
									</el-form-item> -->
									<el-form-item label="是否为默认" prop="default">
										<el-radio-group v-model="ruleFormal.default">
											<el-radio :label="0">不使用</el-radio>
											<el-radio :label="1">使用</el-radio>
										</el-radio-group>
									</el-form-item>
							</div>
							<!-- <div class="save-cont"  >
								<div class="save-btn" @click="submitFormal('ruleFormal')">提交</div>
							</div> -->
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class="save-cont"  v-if="storage_type == 1">
				<div class="save-btn" @click="submitFormqn('ruleFormqn')">提交</div>
			</div>
			<div class="save-cont"   v-if="storage_type == 2" >
				<div class="save-btn" @click="submitFormal('ruleFormal')">提交</div>
			</div>
		</div>
	</div>

</template>
<script>
	import {
		setNiuYunAuth,
		getNiuYunAuth,
		niuYunAuthUp,
		niuYunAuthClose
	} from "@/api/plat/setting.js"
	export default {
		data() {
			return {
				ruleFormqn: {
					access_key:'',
					secret_key:'',
					endpoint:'',
					bucket_public_url:'',
					// bucket_private_url:'',
					bucket_public_name:'',
					// bucket_private_name:'',
					default:0
				},
				ruleFormal: {
					access_key:'',
					secret_key:'',
					endpoint:'',
					bucket_public_url:'',
					// bucket_private_url:'',
					bucket_public_name:'',
					// bucket_private_name:'',
					default:0
				},
				storage_type:-1,
				getData: [],
				ossType: '',
				oss_config_id: '',
				closetype:'',
				rulesqn: {
					endpoint: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					secret_key: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					secret_key: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					bucket_public_url: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					// bucket_private_url: [{
					// 	required: true,
					// 	message: '请填写',
					// 	trigger: 'blur'
					// }],
					bucket_public_name: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					// bucket_private_name: [{
					// 	required: true,
					// 	message: '请填写',
					// 	trigger: 'blur'
					// }],

				},
				rulesal: {
					access_key: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					endpoint: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					secret_key: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					bucket_public_url: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					// bucket_private_url: [{
					// 	required: true,
					// 	message: '请填写',
					// 	trigger: 'blur'
					// }],
					bucket_public_name: [{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}],
					// bucket_private_name: [{
					// 	required: true,
					// 	message: '请填写',
					// 	trigger: 'blur'
					// }],
					
				
				},
				selecttype:''
			}
		},
		mounted() {
			this.List()
		},
		methods: {
			changeStorage(val){
				this.selecttype=val;
				if(val == 0){
					this.niuYunAuthClose();
					this.$message.warning("网站中的所有图片存放处(/public/uploading)迁移到此选项软件中");
				}
				if(val == 1){
					this.ossType = 'qiniu';
					this.$message.warning("网站中的所有图片存放处(/public/uploading)迁移到此选项软件中");
				}
				if(val == 2){
					this.ossType = 'ali';
					this.$message.warning("网站中的所有图片存放处(/public/uploading)迁移到此选项软件中");
				}
			},
			async List() { //获取云仓数据
				const params = {}
				try {
					let data = await getNiuYunAuth(params)
					if (data.return_code === 'SUCCESS') {
						this.getData = data.data.list;
						this.storage_type =data.data.type;
						if(this.getData.length>0){
							this.getData.forEach(item=>{
								if(item.oss_type =='ali'){
									this.ruleFormal=item;
									this.ruleFormal.default = parseInt(this.ruleFormal.default);
									this.ossType ='ali';
									this.oss_config_id=item.oss_config_id;
								}else if(item.oss_type =='qiniu'){
									this.ruleFormqn=item;
									this.ruleFormqn.default = parseInt(this.ruleFormqn.default);
									this.ossType ='ali';
									this.oss_config_id=item.oss_config_id;
								}
							})	
						}
					}
				} catch (error) {}
			},
			//获取关闭云仓数据
			async niuYunAuthClose(){
				let params = {}
				try {
					let data = await niuYunAuthClose(params)
					if (data.return_code === 'SUCCESS') {
						this.getData = data.data.list;
					}
				} catch (error) {}
			},
			
			submitFormqn(ruleFormqn) {
					this.$refs[ruleFormqn].validate((valid) => {
						if (valid) {
							const params =JSON.parse(JSON.stringify(this.ruleFormqn));
							params.oss_config_id = this.oss_config_id;
							params.oss_type = this.ossType;
							params.type = 1;
							
							this.authorityPont(params)
						} else {
							this.$message.error('请填写完整信息')
							return false;
						}
					});
			
			},
			submitFormal(ruleFormal) {
					this.$refs[ruleFormal].validate((valid) => {
						if (valid) {
							const params =JSON.parse(JSON.stringify(this.ruleFormal));
							params.oss_config_id = this.oss_config_id;
							params.oss_type = this.ossType;
							params.type = 2;
							
							this.authorityPont(params)
						} else {
							this.$message.error('请填写完整信息')
							return false;
						}
					});
			
			},
			async authorityPont(params) {
				if(this.oss_config_id){
					try {
						params = this.tools.filterParams(params);
						let data = await setNiuYunAuth(params);
						
						if (data.return_code === 'SUCCESS') {
							this.$message({
								message: '修改云仓成功',
								type: 'success'
							});
						}
					} catch (error) {}
				}else{
					try {
						let data = await setNiuYunAuth(params)
						if (data.return_code === 'SUCCESS') {
							this.$message({
								message: '添加云仓成功',
								type: 'success'
							});
							// this.ruleFormqn={};
							// this.ruleFormal={};
						}
					} catch (error) {}
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.store-container {
		padding-bottom: 60px;

		.store-infor {
			padding: 15px;
			border-radius: 6px;
			min-height: 300px;

			margin-bottom: 20px;

			.top_title {
				font-weight: 700;
				padding: 15px;
				margin: 10px 0;
				font-size: 16px;
				color: #606266
			}

			.labelWidth {
				width: 400px;
			}

			.prompt {
				display: inline-block;
				padding-left: 20px;
				color: #9797a1;
			}

		}
	}
	.specialmain {
		background: #F2F2F6;
		border-radius: 4px;
		padding: 20px 40px;
		font-size: 14px;
		}
</style>
