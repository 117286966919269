<template>
	<div class="order">
		<div class="wrapper-main">
			<div class="model-content">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
					<div class="order-set">
						<div class="top_title">
							订单流程设置
						</div>
						<el-form-item label="未支付订单" prop="auto_close_minute">
							<div class="orderTime">
								<el-input type="text" size="small" class="timeInput" v-model="ruleForm.auto_close_minute"></el-input><span>分钟后自动关闭</span>
								<!-- <span class="tipmsg">最长可设置90天，货到付款订单不适用</span> -->
							</div>
						</el-form-item>
						<el-form-item label="买家交易成功" prop="auto_confirm_receipt" >
							<div class="orderTime">
								<el-input size="small" class="shippedInput" v-model.number="ruleForm.auto_confirm_receipt"></el-input><span>天后自动确认收货
								</span>
								<span class="tipmsg">请考虑物流运输时间，最长可设置90天，自提订单在配送方式中设置</span>
							</div>
						</el-form-item>
						<el-form-item label="已收货订单"  prop="close_refund_goods">
							<div class="orderTime">
								<el-input  size="small" class="shippedInput" v-model.number="ruleForm.close_refund_goods"></el-input><span>天后关闭退款/退货功能
								</span>
								<span class="tipmsg">填写则买家可在确认收货后进行退款/退货申请，最长可设置365天</span>
							</div>
						</el-form-item>

					</div>
					<div class="order-set">
						<div class="top_title">
							售后原因设置
						</div>
						<el-form-item label="售后原因">
							<div class="afterSale">
								<el-switch disabled v-model="ruleForm.open_refund_reason" active-text="已开启" active-color='#409EFF'>
								</el-switch>
								<span>说明：设置后买家在申请售后时可以进行选择，最多支持添加10个</span>
							</div>
							<div class="reasonList" v-show="ruleForm.open_refund_reason == true">
								<ul>
									<li class="businessItem" v-for="(tim,index) in ruleForm.refund_reason" :key="index">
										<el-button size="small">{{tim}}<i class="el-icon-close el-icon--right" @click="deleteItemReason(index)"></i></el-button>
									</li>
								</ul>

								<el-button type="primary" size="small" @click="addReason">新增</el-button>
							</div>
						</el-form-item>
					</div>
					<div class="order-set">
						<div class="top_title">
							售后流程设置
						</div>
						<el-form-item label="发货后支持的售后类型" >
							<div class="orderTime" >
							<!-- 	<el-checkbox v-model="ruleForm.is_delivery_default">设为默认发货地址</el-checkbox>
								 <el-checkbox v-model="ruleForm.is_return_default">设为默认退货地址</el-checkbox> -->
							  <el-radio v-model="ruleForm.oas_genre" label="1">仅退款</el-radio>
							  <el-radio v-model="ruleForm.oas_genre" label="2">退货退款</el-radio>
							<span class="tipmsg" style="display: inline-block;">无需物流商品仅支持退款 目前仅支持同款同价的商品换货，不支持补差价。</span>
							</div>
						</el-form-item>
						
					<el-form-item label="买家发起退款申请" prop="applied_auto_refund_time">
						<div class="orderTime">
							<el-input size="small" class="shippedInput" v-model.number="ruleForm.applied_auto_refund_time"></el-input>
							<span class="tipmsg">天后商家未处理，系统将自动同意。</span>
						</div>
					</el-form-item>
					<el-form-item label="买家发起退货退款申请" prop="auto_rights_time">
						<div class="orderTime">
							<el-input type="text" size="small" class="shippedInput" v-model.number="ruleForm.auto_rights_time"></el-input>
							<span class="tipmsg">天后商家未处理，系统将自动同意售后。</span>
						</div>
					</el-form-item>
					<el-form-item label="商家同意退货" prop="auto_refuse_rights_time">
						<div class="orderTime">
							<el-input type="text" size="small" class="shippedInput" v-model.number="ruleForm.auto_refuse_rights_time"></el-input>
							<span class="tipmsg">天后商家未处理，系统将自动同意售后。</span>
						</div>
					</el-form-item>
					<el-form-item label="买家已退货" prop="returned_auto_refund_time">
						<div class="orderTime">
							<el-input-number :min="1" :controls="false" type="text"  style="text-align: left;"  size="small" class="shippedInput" v-model="ruleForm.returned_auto_refund_time"></el-input-number>
							<span class="tipmsg">天后商家未处理，系统将自动同意。</span>
						</div>
					</el-form-item>
					<el-form-item label="退款中自动退款" prop="returned_time">
						<div class="orderTime">
							<el-input-number :min="1" :controls="false" type="text"  style="text-align: left;"  size="small" class="shippedInput" v-model="ruleForm.returned_time"></el-input-number>
							<span class="tipmsg">天后商家未处理，系统将自动同意。</span>
						</div>
					</el-form-item>
						<el-form-item label="">
							<div class="explain">
								<div>
									说明:
								</div>
								<div class="ant-col">1、系统默认的时间为7天，手动设置时间最长不超过90天。</div>
								<div class="ant-col">2、线上支付的订单系统做自动同意处理，线下支付的订单系统做自动拒绝处理。</div>
							</div>
						</el-form-item>
					</div>
				</el-form>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet('ruleForm')">提交</div>
		</div>
	</div>
</template>

<script>
	import {
		getOrderConfig,
		saveOrderConfig
	} from '@/api/plat/setting.js'
	export default {
		data() {
			return {
				account_id: '',
				ruleForm: {
					auto_close_minute: '', //分钟
					auto_confirm_receipt: '', //已发货
					close_refund_goods: '', //已收货
					open_refund_reason: true, //售后原因
					refund_reason: [],
					oas_genre: '', //售后类型
					applied_auto_refund_time: '', //退款
					auto_rights_time: '', //退货退款
					auto_refuse_rights_time: '', //卖家退货
					returned_auto_refund_time: '', //买家退货
					returned_time:''
				},
				rules: {
					auto_close_minute: [{
						required: true,
						message: '必须填写',
						trigger: 'blur'
					}],
					auto_confirm_receipt: [
						{required: true,message: '必须填写',trigger: 'blur'},
						{type:"number" ,min: 1, max: 90, message: '大小要在0到90', trigger: 'blur' },
					],
					close_refund_goods: [
						{required: true,message: '必须填写',trigger: 'blur'},
						{type:"number" ,min: 1, max: 365, message: '不能大于365天', trigger: 'blur' },
					],
					applied_auto_refund_time: [
						{required: true,message: '必须填写',trigger: 'blur'},
						{type:"number" ,min: 1, max: 7, message: '不能大于7天', trigger: 'blur' },
					],
					auto_rights_time: [
						{required: true,message: '必须填写',trigger: 'blur'},
						{type:"number" ,min: 1, max: 7, message: '不能大于7天', trigger: 'blur' },
					],
					auto_refuse_rights_time: [
						{required: true,message: '必须填写',trigger: 'blur'},
						{type:"number" ,min: 1, max: 7, message: '不能大于7天', trigger: 'blur' },
					],
					returned_auto_refund_time: [
						{required: true,message: '必须填写',trigger: 'blur'},
						{type:"number" ,min: 1, max: 7, message: '不能大于7天', trigger: 'blur' },
					],
					returned_time: [
						{required: true,message: '必须填写',trigger: 'blur'},
						{type:"number" ,min: 1, max: 7, message: '不能大于7天', trigger: 'blur' },
					],
					
				}
			}
		},
		mounted() {
			this.getOrdersConfigData();

		},
		methods: {
			//获取设置
			async getOrdersConfigData() {
				try {
					let data = await getOrderConfig({});
					if (data.return_code == 'SUCCESS') {
						this.ruleForm = data.data;
						this.ruleForm.oas_genre=this.ruleForm.oas_genre.toString();
						if (this.ruleForm.open_refund_reason === 1) {
							this.ruleForm.open_refund_reason = true;
							this.ruleForm.refund_reason=this.ruleForm.refund_reason.split('@')
						} else {
							this.ruleForm.open_refund_reason = false
						}
						

					}
				} catch (error) {}
			},
			//添加售后原因
			addReason() {
				this.$prompt('请输入原因', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '不能为空'
				}).then(({
					value
				}) => {
						this.ruleForm.refund_reason.push(value);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消输入'
					});
				});
			},
			//删除售后原因
			deleteItemReason(index) {
				this.ruleForm.refund_reason.splice(index, 1);
			},
			//提交申请
			submitSet() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						let params = JSON.parse(JSON.stringify(this.ruleForm));
						params.refund_reason=params.refund_reason.join("@")
						if (params.open_refund_reason == true) {
							params.open_refund_reason = 1
						} else {
							params.open_refund_reason = 2
						}
						
						saveOrderConfig(params).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.getOrdersConfigData();
							}

						})

					} else {
						this.$message.error('请填写完整信息')
						return false;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	 ::v-deep .el-input-number .el-input__inner {
	    -webkit-appearance: none;
	    padding-left: 15px;
	    padding-right: 15px;
	    text-align: left;
	}
	.order {
		padding-bottom: 60px;

		.order-set {
			padding: 15px;
			border-radius: 6px;
			// min-height: 300px;
			background-color: #f2f2f6;
			margin-bottom: 20px;

			.top_title {
				font-weight: bold;
				padding: 15px;
				margin: 10px 0;
				font-size: 14px;
				color: #606266
			}

			.orderTime {
				.timeInput {
					width: 50px !important;
					text-align: center;
				}

				.shippedInput {
					width: 200px !important;
				}

				span {
					color: #595961;
					margin: 0px 10px;
				}

				.tipmsg {
					font-size: 12px;
					color: #999999;
				}
			}

			.radioContent {
				span {
					color: #9797a1;
				}

				::v-deep .el-radio {
					color: #666;
					cursor: pointer;
					margin-right: 30px;
				}
			}

			.afterSale {
				span {
					color: #9797a1;
					margin-left: 30px;
				}
			}

			.reasonList {
				ul {
					li {
						padding: 10px;
						display: inline-block;
					}
				}
			}

			.explain {
				width: 600px;
				background: #FFF7EE;
				border-radius: 4px;
				border: 1px solid #FFD9A4;
				padding: 5px 20px 20px 20px;

				.ant-col {
					line-height: 30px;
					color: #595961;
				}
			}
		}

		.store-btns {
			position: fixed;
			left: 200px;
			bottom: 0px;
			right: 20px;
			padding: 8px 10px;
			background: white;
			z-index: 100;
			box-shadow: 0 2px 10px 0 rgba(15, 12, 70, 0.1);

			// border-radius: 10px 10px 0 0;
			.text-center {
				text-align: center !important;
			}
		}
	}
</style>
