/*   示例
import upviedo from './upvideo.js'

mixins:[upviedo],

methods: {
  preview(file) {
    this.num++
    if(this.num>=2)return

    this.uploadViedo(上传文件对象,分片允许最大M（Num）).then(res=>{
      console.log('总体完成')
    }).catch(err=>{
      console.log('总体失败')
    })
  },
}

this.Loading   变量当前上传进度

*/

import {
	uploadVideoPretreatment,
	uploadVideo
} from '@/api/video'
export default {
	data() {
		return {
			NowTotal: 0, //当前
			AllTotal: 1, //总数
			Loading: 0, //当前进度%   
			clearT: 0, //清除定时器
			anticipation: ''
		};
	},
	mounted() {
		// 检测断网
		window.addEventListener("offline", () => {
			console.log("已断网");
		});
		window.addEventListener("online", () => {
			console.log("网络已连接");
		});
	},
	methods: {
		// 上传进度
		changeLoading() {
			this.NowTotal++;
			let ratio = (this.NowTotal * 100) / (this.AllTotal * 100)
			ratio = Math.round(ratio * 100)
			clearInterval(this.clearT)
			this.clearT = setInterval(() => {
				this.Loading++;
				// console.log(this.Loading, "进度")
				if (this.Loading >= ratio) clearInterval(this.clearT);
			}, 10)
		},
		// 视频长度
		videoLong(file) {
			return new Promise((resolve, reject) => {
				var url = URL.createObjectURL(file);
				var audioElement = new Audio(url);
				audioElement.addEventListener("loadedmetadata", function(_event) {
					var hour = parseInt((audioElement.duration) / 3600);
					if (hour < 10) {
						hour = "0" + hour;
					}
					var minute = parseInt((audioElement.duration % 3600) / 60);
					if (minute < 10) {
						minute = "0" + minute;
					}
					var second = Math.ceil(audioElement.duration % 60);
					if (second < 10) {
						second = "0" + second;
					}
					resolve(hour + ":" + minute + ":" + second)
				});
			})
		},
		// file :文件             college_curriculum_id:行业id                      byte :每片字节大小 单位M
		async uploadViedo(file, college_curriculum_id, byte = 2) {
			//console.log(file,"5555")
			let fileName = file.type;
			let suffix = fileName.substring(fileName.lastIndexOf("/") + 1, fileName.length).toLowerCase();
			//这里判断文件格式，有其他格式的自行判断
			if (suffix != 'mp4' && suffix != 'AVI' && suffix != 'mov' && suffix != 'rmvb' && suffix != 'rm' &&
				suffix != 'FLV') {
				this.$message.error('文件格式错了哦。。');
				return;
			};

			var duration = await this.videoLong(file);

			let chunkSize = byte * 1024 * 1024 //分片大小
			let chunkArr = [];
			// 拆分文件
			if (file.size < chunkSize) {
				// 小于规定大小一次性上传
				chunkArr.push(file.slice(0))
			} else {
				// 开始分片
				var start = 0,
					end = 0
				while (true) {
					end += chunkSize
					var blob = file.slice(start, end)
					start += chunkSize

					if (!blob.size) {
						break;
					}
					chunkArr.push(blob)
				}
			}

			this.AllTotal = chunkArr.length;
			   this.NowTotal=0;//当前
			      this.Loading=0;

			return new Promise((ok, no) => {
				//预链接
				uploadVideoPretreatment({
					"video_number": this.AllTotal,
					"size": file.size,
					"video_len": duration || 0,
					"suffix": file.type,
					"college_curriculum_id": college_curriculum_id
				}).then(res => {
					this.anticipation = res.data;
					let all = [];
					chunkArr.forEach((item, index) => {
						let p = new Promise((resolve, reject) => {
							var data = new FormData();
							data.append('video', item)
							data.append('name', res.data)
							data.append('blob_num', index + 1)
							data.append('total_blob_num', this.AllTotal)
							uploadVideo(data).then(res => {
								this.changeLoading();
								resolve(res)
							}, err => {
								reject(err)
							})

						})
						all.push(p);
					})

					Promise.all(all).then(res => {
						ok()
					}).catch(err => {
						no(err)
					})

				}).catch(err => {
					no(err)
				})

			})

		}
	},
	beforeDestroy() {
		clearInterval(this.clearT)
	}
}
