<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card shadow="never">
                    <el-row class="all_search_list" style="margin-bottom:0">
                        <!-- <div class="demo-input-suffix">
                            <el-input placeholder="请输入内容" v-model="search.search" size="small"
								class="input-with-select"   @keyup.enter.native="searchOrder" clearable>
								<el-select v-model="search.type" slot="prepend" clearable placeholder="请选择">
									<el-option label="昵称" value="1"></el-option>
									<el-option label="手机号" value="2"></el-option>
								</el-select>
							</el-input>
                        </div> -->
                        
                        <div class="demo-input-suffix">
                            手机号：
                            <el-input clearable class="inputsWidth" prefix-icon="el-icon-search"
                                v-model="search.search" placeholder="手机号"
                                size="small" type="text">
                            </el-input>
                        </div>
                        <!-- <div class="demo-input-suffix">
                            上级手机号：
                            <el-input clearable class="inputsWidth" prefix-icon="el-icon-search"
                                v-model="search.shop_name" placeholder="店铺名称/用户账号" @keyup.enter.native="toSearch"
                                size="small" type="text">
                            </el-input>
                        </div> -->
                        <div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
                            等级：
                            <el-select style="width: 150px;!important" size="small" clearable v-model="search.level"
                                placeholder="请选择">
                                <el-option value="1" label="等级1"></el-option>
                                <el-option value="2" label="等级2"></el-option>
                                <el-option value="3" label="等级3"></el-option>
                                <el-option value="4" label="等级4"></el-option>
                                <el-option value="5" label="等级5"></el-option>
                                <el-option value="6" label="等级6"></el-option>
                                <el-option value="7" label="等级7"></el-option>
                            </el-select>
                        </div>
                        <!-- <div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
                            注册时间：
                            <el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
                                range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']" @change="changetimer"></el-date-picker>
                        </div> -->
                  
                        <div class="demo-input-suffix">
                            <el-button type="primary" size="small" @click="searchOrder">搜索</el-button>
                            <el-button size="small" @click="toResetOrder">重置</el-button>
                        </div>
                    </el-row>
                </el-card>
                <el-table class="mt10" ref="multipleTable" :data="tableData" size="small" border tooltip-effect="dark"
                    max-height="550" style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
                    v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.2)">
                    <!-- <el-table-column prop="account" label="客户" align="center">
                        <template slot-scope="scope">
                            <div class="flex-align-center ">
                                <div style="width:50px;height:50px;">
                                    <el-image style="width: 100%;height: 100%;" v-if="scope.row.head_portrait" :src="scope.row.head_portrait"
                                        :preview-src-list="[scope.row.head_portrait]" />
                                </div>
                                <span class="ml10">{{scope.row.nickname}}</span>
                            </div>
                       </template>
                    </el-table-column> -->
                    <el-table-column prop="mobile" label="用户手机号" align="center"></el-table-column>
                    <el-table-column prop="pidInfo" label="所属上级" align="center">
                        <template slot-scope="scope">
                             {{ scope.row.pidInfo.mobile||'' }}
                            </template>
                    </el-table-column>
                    <el-table-column prop="level" label="等级" align="center"></el-table-column>
                    <el-table-column prop="childCount" label="下级团队数" align="center"></el-table-column>
                    <el-table-column prop="created_at" label="注册时间" sortable align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="toExamine(scope.row)" v-has="'查看'">查看</el-button>
                            </template>
                        </el-table-column>
                </el-table>
                <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10,20,30,50]"
                    :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>

        </div>
    </div>
</template>
<script>
    import {accountList} from "@/api/merchant/users.js"
    import {getConfig} from "@/api/customers.js"
export default {
    data() {
        return {
            search: {
                type:1,
                search:'',
                level:''
            },
            tableData: [],
            total: 100,
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
            loading: true,
            due_dt:'',
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        // getConfig(){
        //     getConfig()
        // },
        toExamine(val){
            // console.log(val,'----------val'); 
            this.$router.push({
                    path: '/customeradmin/ordinary/ordinaryusersdetail',
                    query:{
                        account_user_id:val.account_user_id,
                        // account_id:val.junior_account_id
                    }
                })
        },
        //时间搜索
			changetimer(val) { //时间
				this.search.begin_time = val[0] / 1000;
				this.search.end_time = val[1] / 1000;
			},
        getList() {
            let data=this.search;
            data.page=this.currentPage;
            data.perPage=this.pagesize;
            // data.account_user_id=107
            accountList(data).then(res => {
                this.loading = false;
                this.tableData = res.data.list;
                // console.log(213131323123);
                
                // console.log(res.data);
                this.total = res.data.count
            })
        },
        //分页
        handleSizeChange(size) {
            this.pagesize = size;
            // 调接口
            this.getList()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            // 调接口
            this.getList()
        },
        //搜索
        searchOrder() {
            this.currentPage = 1;
            //调接口
            this.getList()
        },
        toResetOrder() {
            this.currentPage = 1;
            this.search.search = '';
            this.search.level = '';
            this.getList()
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep.el-select .el-input {
    width: 130px;
  }
  ::v-deep.input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>