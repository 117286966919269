<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt" style="padding: 0!important;">
					<el-tabs v-model="activeName" type="card" @tab-click="handleClick" v-has>
						<el-tab-pane label="待审核" name="0"></el-tab-pane>
						<el-tab-pane label="通过审核" name="1"></el-tab-pane>
					</el-tabs>
				</div>
				<div class="main-cont bgwt mt10 pp20">
					<el-row class="all_search_list" style="margin-bottom: 0;">
					<!-- 	<div class="demo-input-suffix"  style="margin-right:0">
							<el-select  style="width: 120px !important;" size="small" v-model="search.goodtype" class="inputWidth"
								placeholder="全部商品">
								<el-option label="全部商品" value="0"></el-option>
								<el-option label="商品名称" value="1"></el-option>
								<el-option label="所属商户" value="2"></el-option>
							</el-select>
						</div> -->
						<div class="demo-input-suffix">
							<!-- <el-input prefix-icon="el-icon-search" v-model="search.keysward" placeholder="请输入" size="small" type="text">
							</el-input> -->
							<el-input placeholder="请输入内容" size="small" v-model="search.keysward" @keyup.enter.native="searchOrder" class="input-with-select">
							    <el-select class="inputselectval" v-model="search.goodtype" slot="prepend" placeholder="请选择">
							      <el-option label="全部商品" value=""></el-option>
							      <el-option label="商品名称" value="1"></el-option>
							      <el-option label="所属商户" value="2"></el-option>
							    </el-select>
							  </el-input>
						</div>
						
							<div class="demo-input-suffix ml0 flex">
								<el-select v-model="search.one_cat_id" filterable clearable size="small"  class="selectWidth"
									placeholder="请选择一级分类" @change="getCats(1)">
									<el-option v-for="item in oneLevel" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
								<el-select v-model="search.two_cat_id" filterable clearable size="small" class="selectWidth"
									style="margin-left:10px" placeholder="请选择二级分类"
									v-if="twoLevel && twoLevel.length > 0">
									<el-option v-for="item in twoLevel" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							
						</div>
						<div class="demo-input-suffix ml0">
							<el-button type="primary" size="small"  @click="searchOrder()">查询</el-button>
							<el-button  size="small" @click="cancelbtn">重置</el-button>
						</div>
					</el-row>
					<!-- <el-row class="all_search_list" style="margin-bottom: 0;">
						<el-button v-if="activeName == 'first'" size="small" @click="batchOpera('up')">批量上架</el-button>
						<el-button v-if="activeName == 'second'" size="small" @click="batchOpera('down')">批量下架</el-button>
						<el-button v-if="activeName == 'first'" size="small" @click="batchOpera('del')">批量删除</el-button>
					</el-row> -->
				</div>
				<div class="main-cont mt10">
					<el-table :data="tableData" style="width: 100%" min-height="250" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" border @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column prop="shop_name" label="所属商户" align="center">
						</el-table-column>
						<el-table-column label="商品名称" width="380" align="left">
							<template slot-scope="scope">
								<div class="el_name">
									<!-- <img :src="scope.row.goods_main_pic.split(',', 1)" class="el_img" />
									<div>
										<p>{{ scope.row.goods_name }}</p>
									</div> -->
									<!-- <el-popover placement="right" title width="200" trigger="hover">
										<div style="width:200px;height:200px;">
										  <img
											style="width:100%;height:100%;object-fit: cover;"
											:src="scope.row.goods_main_pic.split(',')[0]"
											alt
										  />
										</div>
										<div style="width:70px;height:70px;" slot="reference">
										 <img :src="scope.row.goods_main_pic.split(',')[0]" style="width: 100%;height: 100%;"/>
										</div>
									  </el-popover> -->
									  <div style="width:70px;height:70px;" >
											<el-image  :src="scope.row.goods_main_pic.split(',')[0]" class="el_img" :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]"/>
										</div>
									<div  style="width: 78%;margin-left: 10px;">
										<p>{{ scope.row.goods_name }}</p>
										<!-- <p class="mt10">{{ scope.row.count }}</p> -->
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="sku_purchase_price" label="商品成本" align="center">
						</el-table-column>
						<el-table-column prop="goods_category_name" label="商品分类" align="center">
						</el-table-column>
						<el-table-column prop="sale" label="实际销量" align="center" v-if="activeName=='second'">
						</el-table-column>
						<el-table-column prop="goods_stock" label="总库存" align="center">
						</el-table-column>
						<el-table-column prop="goods_stock" label="状态" align="center">
							<template slot-scope="scope">
								<span style="color: #409EFF;" v-if="scope.row.is_verify == 1">审核通过</span>
								<span style="color: #409EFF;" v-else-if="scope.row.is_verify == 0">未审核</span>
								<span style="color: #409EFF;" v-else>审核不通过</span>
							</template>
						</el-table-column>
						<el-table-column prop="created_at" label="创建时间" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center" prop="status" fixed="right">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toDetail(scope.row)"  v-has="'查看'" >查看</el-button>
								<el-button type="text" size="small" v-show="activeName=='0'" @click="repulse(scope.row,1)" v-has="'通过'" >通过</el-button>
								<el-button type="text" size="small" v-show="activeName=='0'" @click="repulse(scope.row,2)" v-has="'拒绝'" >拒绝</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>

		<!--拒绝原因弹框-->
		<el-dialog :title="desctitle" :visible.sync="dialogVisible" width="25%" >
			<div>
				<el-input type="textarea" placeholder="请输入内容" v-model="desc_txt" rows="4" maxlength="50" show-word-limit>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="refuseCommit" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		cate_list, //商品分类
		platform_reviewMerchantsGoodsList,//审核列表
		platform_goodsAudit,//商品审核
	} from '@/api/plat/goods'
	export default {
		data() {
			return {
				loading:true,
				activeName: '0',
				search:{
					goodtype:'',
					keysward:'',
					one_cat_id:'',
					two_cat_id: '',
				},
				tableData: [],
				multipleSelection:[],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				dialogVisible: false,
				desc_txt:'',
				desctitle:'',
				is_verify:'0',
				oneLevel: [],
				twoLevel: [],
				cateId: "", //判断是否需要清空二级分类id
				repulsedata:{}
			}
		},
		mounted() {
			this.getCats();
			if(this.$route.query.activeName){
				this.activeName=this.$route.query.activeName;
				this.is_verify=this.$route.query.activeName;
				this.getList()
			}else{
				this.getList()
			}
		},
		methods:{
			//获取分类
			getCats(type) {
				// console.log(type, 新增商品);
				if (type == 1) {
					this.search.cate_id = this.search.one_cat_id;
					//清空二级分类
					this.search.two_cat_id && (this.search.two_cat_id = "");
				}
				cate_list({
						parent_id: this.search.cate_id ? this.search.cate_id : '0'
					})
					.then((res) => {
						// console.log(新增商品);
						if (type == 1) {
							this.twoLevel = res.data;
						} else {
							this.oneLevel = res.data;
						}
					})
					
			},
			//审核列表
			getList(){
				let data={
					keyword_classify:this.search.goodtype,
					keyword:this.search.keysward,
					is_verify:this.is_verify,
					cate_id:this.search.two_cat_id ?this.search.two_cat_id :this.search.one_cat_id,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				platform_reviewMerchantsGoodsList(data).then(res=>{
					this.loading=false;
					this.total=res.data.count;
					this.tableData = res.data.list;
				})
			},
			handleClick(tab) {
				this.oneLevel= [];
				this.twoLevel= [];
				this.search.one_cat_id='';
				this.search.goodtype='';
				this.search.keysward='';
				if(tab.name=='0'){
					this.currentPage=1;
					this.is_verify=tab.name;
					this.search.cate_id=0;
					this.search.one_cat_id='';
					this.search.two_cat_id = "";
					this.getCats();
					this.getList()
				}else if(tab.name=='1'){
					this.currentPage=1;
					this.is_verify=tab.name;
					this.search.one_cat_id='';
					this.search.two_cat_id = "";
					this.search.cate_id=0;
					this.getCats();
					this.getList()
				}
			},
			searchOrder(){
				this.currentPage = 1;
				this.getList()
			},
			cancelbtn(){
				this.currentPage = 1;
				this.oneLevel= [];
				this.twoLevel= [];
				this.search.cate_id='';
				this.search.one_cat_id='';
				this.search.two_cat_id = "";
				this.getCats();
				
				this.search.goodtype='';
				this.search.keysward='';
				this.getList()
			},
			//批量选中
			handleSelectionChange(val){
				 this.multipleSelection = val;
			},
			toDetail(row){
				this.$router.push({
					path:'/goodsadmin/commodityaudit/auditdetail',
					query:{
						id:row.goods_unified_id,
						activeName:this.activeName
					}
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//审核拒绝弹框
			repulse(row,type){
				this.repulsedata=row;
				if(type==1){
					this.tools.confirm('确认要审核通过此商品？').then(res => {
						if (res) {
								let data={
									goods_unified_id:row.goods_unified_id,
									is_verify:'1',
									shop_id:row.shop_id
								}
								platform_goodsAudit(data).then(res=>{
									if(res.return_code == 'SUCCESS'){
										this.$message.success("审核完成")
											this.getList()
										}
								})
						}
					})
				
					
				}
				if(type == 2){
					this.desctitle = "拒绝原因"
					this.dialogVisible = !this.dialogVisible
				}
				
			},
			//审核拒绝
			refuseCommit(){
				let data={
					goods_unified_id:this.repulsedata.goods_unified_id,
					is_verify:'2',
					shop_id:this.repulsedata.shop_id,
					verify_comment:this.desc_txt
				}
				platform_goodsAudit(data).then(res=>{
					if(res.return_code == 'SUCCESS'){
						this.dialogVisible = !this.dialogVisible;
						this.desc_txt='';
						this.getList();
						
					}
					
				})
			},
			//批量下架原因
			// batchOpera(type){
			// 	if(this.multipleSelection.length == 0){
			// 		this.$message.info("请选择要操作的数据！");
			// 		return;
			// 	}
			// 	if(type=='up'){
			// 		//批量上架
			// 		this.tools.confirm("确定批量上架？").then(res=>{
			// 			if(res){
			// 				//调接口
			// 			}
			// 		})
			// 	}else if(type == 'down'){
			// 		//批量下架
			// 		this.desctitle = "下架原因"
			// 		this.dialogVisible = !this.dialogVisible
					
			// 	}else if(type == 'del'){
			// 		//批量删除
			// 		this.tools.confirm("确定批量删除？").then(res=>{
			// 			if(res){
			// 				//调接口
			// 			}
			// 		})
			// 	}
				
			// }
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	
		.el_img {
			width: 100%;
			height: 100%;
			margin-right: 6px;
		}
	}
	::v-deep.el-tabs__header {
    padding: 0;
    position: relative;
    margin:0;
}
::v-deep.el-select .el-input  {
    width: 120px;
  }
  ::v-deep.selectWidth .el-input  {
    width: 220px;
  }
  ::v-deep.input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>
