<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<el-card shadow="never">
					<el-row class="all_search_list" style="margin-bottom:0">
						<div class="demo-input-suffix">
							<el-select v-model="status" size="small">
								<el-option label="充值成功" value="1"></el-option>
								<el-option label="未支付" value="2"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder">搜索</el-button>
							<el-button size="small" @click="toResetOrder">重置</el-button>
						</div>
					</el-row>
				</el-card>
				<el-table class="mt10" size="small" ref="multipleTable" :data="tableData" border tooltip-effect="dark"
					style="width: 100%" max-height="550"
					:header-cell-style="{ background: '#f3f8fe', color: '#606060' }" v-loading="loading"
					element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.2)">
					<el-table-column prop="order_number" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="order_sn" label="订单类型" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.order_ganre == 1">实物商品</span>
							<span v-else-if="scope.row.order_ganre == 2">虚拟商品</span>
							<span v-else-if="scope.row.order_ganre == 3">会员套餐</span>
						</template>
					</el-table-column>
					<el-table-column prop="actual_payment" label="订单金额" align="center"></el-table-column>
					<el-table-column label="下单时间" align="center">
						<template slot-scope="scope">
							<span>{{ getTime(scope.row.creation_time) }}</span>
						</template>
					</el-table-column>
					<el-table-column label="支付方式" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.payment_type == 0">未调起</span>
							<span v-else-if="scope.row.payment_type == 1">微信</span>
							<span v-else-if="scope.row.payment_type == 2">支付宝</span>
							<span v-else-if="scope.row.payment_type == 3">余额</span>
							<span v-else-if="scope.row.payment_type == 4">组合-微信</span>
							<span v-else-if="scope.row.payment_type == 5">组合-支付宝</span>
						</template>
					</el-table-column>
					<el-table-column label="状态" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.status == 1">待支付</span>
							<span v-else-if="scope.row.status == 2">已支付</span>
							<span v-else-if="scope.row.status == 3">待收货</span>
							<span v-else-if="scope.row.status == 4">已收货</span>
							<span v-else-if="scope.row.status == 5">已完成</span>
							<span v-else-if="scope.row.status == 6">已关闭</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="toDetail(scope.row)">详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]"
					:page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
import { orderList } from "@/api/merchant/users.js"
export default {
	data() {
		return {
			status: '',
			loading: true,
			tableData: [],
			total: 0,
			currentPage: 1, //初始页
			pagesize: 10, //每页的数据
		}
	},
	mounted() {
		this.getList()
	},
	methods: {
		getTime(time) {
			var date = new Date(time * 1000);
			let Y = date.getFullYear() + '-';
			let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			let D = date.getDate() + ' ';
			let h = this.addZero(date.getHours()) + ':';
			let m = this.addZero(date.getMinutes()) + ':';
			let s = this.addZero(date.getSeconds());
			return Y + M + D + h + m + s;
		},
		addZero(a){
			return a<10?`0${a}`:a
		},	
		getList() {
			let data = {
				page: this.currentPage,
				perPage: this.pagesize,
				status: this.status
			}
			orderList(data).then(res => {
				this.loading = false;
				this.total = res.data.count;
				this.tableData = res.data.list;
			})
		},
		searchOrder() {
			this.currentPage = 1;
			//调接口
			this.getList()
		},
		toResetOrder() {
			this.currentPage = 1;
			this.status = '';
			this.getList()
		},
		//分页
		handleSizeChange(size) {
			this.pagesize = size;
			// 调接口
			this.getList()
		},
		handleCurrentChange(currentPage) {
			this.currentPage = currentPage;
			// 调接口
			this.getList()
		},
		//去详情页面
		toDetail(row) {
			this.$router.push({
				path: '/financialmanageadmin/withdrawalorder/withdrawalorderdetail',
				query: {
					order_number: row.order_number,
				},
			})

		}
	}
}
</script>