<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<el-card shadow="never">
					<el-row class="all_search_list" style="margin-bottom:0">
						<div class="demo-input-suffix">
							手机号：
							<el-input clearable class="inputsWidth" prefix-icon="el-icon-search" v-model="search.phone"
								placeholder="手机号" @keyup.enter.native="toSearch" size="small" type="text">
							</el-input>
						</div>
						<div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
							申请时间：
							<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange" range-separator="至"
								start-placeholder="开始日期" clearable end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
								@change="changetimer"></el-date-picker>
						</div>

						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder">搜索</el-button>
							<el-button size="small" @click="toResetOrder">重置</el-button>
						</div>
					</el-row>
				</el-card>
				<el-table class="mt10" ref="multipleTable" :data="tableData" size="small" border tooltip-effect="dark"
					max-height="550" style="width: 100%" :header-cell-style="{ background: '#f3f8fe', color: '#606060' }"
					v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.2)">
					<el-table-column prop="mobile" label="申请用户" align="center">
					</el-table-column>
					<el-table-column prop="amount" label="转出金额" align="center">
					</el-table-column>
					<el-table-column prop="created_at" label="申请时间" sortable align="center"></el-table-column>
					<el-table-column prop="amount" label="状态" align="center">
						<template #default="{ row }">
							<div v-if="row.examine_status == 0">
								待审核
							</div>
							<div v-if="row.examine_status == 1">
								<div>审核成功</div>
								<div>{{ row.examined_at }}</div>
							</div>
							<div v-if="row.examine_status == 2">
								<div>审核失败</div>
								<div>{{ row.examined_at }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="reason" label="失败原因" align="center"></el-table-column>



					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="agree(scope.row)" v-if="scope.row.examine_status == 0"
								v-has="'同意转出'">同意转出</el-button>
							<el-button type="text" size="small" @click="refuse(scope.row)" style="color: red;"
								v-if="scope.row.examine_status == 0" v-has="'拒绝'">拒绝</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]"
					:page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
			<!-- 弹窗 -->
			<el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%">
				<div class="main" style="display: flex;">
					<span style="color: red;font-size: 16px;">*</span><span
						style="font-size: 16px;font-weight: 500;">请填写拒绝原因：</span> <el-input type="textarea" :rows="10"
						placeholder="请输入内容" v-model="textarea" style="width: 350px;">
					</el-input>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="centerDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="submitRefuse">审核拒绝</el-button>
				</span>
			</el-dialog>

			

		</div>
	</div>
</template>
<script>
import { getPlatformWithdrawalPageList, passWithdrawal, refuseWithdrawal } from "@/api/merchant/users.js"
import { transferListPlatform } from "@/api/merchant/users.js"

export default {
	data() {
		return {
			search: {
				phone: '',
				start_time: '',
				end_time: '',
			},
			tableData: [],
			total: 100,
			currentPage: 1, //初始页
			pagesize: 10, //每页的数据
			loading: true,
			due_dt: '',
			textarea: '',
			id: '',
			centerDialogVisible: false,
		}
	},
	mounted() {
		this.getList()
	},
	methods: {
		agree(val) {
			this.tools.confirm("是否同意并转出到开户账号？").then(res => {
				if (res) {
					// passWithdrawal({ withdrawal_id: val.withdrawal_id }).then(res => {
					// 	this.$message.success('打款成功！')
					// 	this.getList()
					// })
					this.yzmDialogVisible=true

				}
			})
		},
		refuse(val) {
			this.id = val.withdrawal_id
			this.centerDialogVisible = true
		},
		submitRefuse() {
			if (this.textarea == "") {
				this.$message.info("请输入拒绝原因！")
				return
			}
			let data = {
				withdrawal_id: this.id,

				reason: this.textarea
			}
			refuseWithdrawal(data).then(res => {
				this.$message.success("拒绝成功")
				this.getList()
				this.centerDialogVisible = false,
					this.textarea = '',
					this.id = ''
			})
		},
		//时间搜索
		changetimer(val) { //时间
			this.search.start_time = val[0] / 1000;
			this.search.end_time = val[1] / 1000;
		},
		getList() {
			let data = this.search;
			data.page = this.currentPage;
			data.perPage = this.pagesize;
			transferListPlatform(data).then(res => {
				this.loading = false;
				this.tableData = res.data.list;
				// console.log(res.data);

				this.total = res.data.count
			})
		},
		//分页
		handleSizeChange(size) {
			this.pagesize = size;
			// 调接口
			this.getList()
		},
		handleCurrentChange(currentPage) {
			this.currentPage = currentPage;
			// 调接口
			this.getList()
		},
		//搜索
		searchOrder() {
			this.currentPage = 1;
			//调接口
			this.getList()
		},
		toResetOrder() {
			this.currentPage = 1;
			this.search.phone = '';
			this.search.start_time = '';
			this.search.end_time = '';
			this.due_dt = '';
			this.getList()


		},
	}
}
</script>
<style lang="scss" scoped>
::v-deep.el-select .el-input {
	width: 130px;
}

::v-deep.input-with-select .el-input-group__prepend {
	background-color: #fff;
}

.password {
			padding: 0 16px 5px;
			height: auto;
			background: #fff;
			margin-top: 16px;
			overflow: hidden;
			box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
		}

		.password p {
			font-size: 15px;
		}

		.password input {
			width: 100%;
			height: 30px;
			border: none;
		}
		.password span{
			font-size: 14px;
			color: #999;
		}

</style>