<template>
    <div>
		<div class="wrapper-main">
            <el-form ref="form" :model="form" :rules="rules" label-width="170px" size="small" class="demo-ruleForm">
                <div class="wrapper-cont pp20">
					<div class="setTitle">活动设置</div>
                    <el-form-item label="活动名称:" prop="activity_name">
						<el-input style="width: 400px!important;" size="small" placeholder="请输入活动名称" maxlength="20" show-word-limit
							v-model="form.activity_name">
						</el-input>
					</el-form-item>
                    <el-form-item label="活动日期:" prop="start_time">
						<el-date-picker v-model="form.start_time" type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" @change="startTimeStatus"
							:picker-options="pickerOptionsStart" style="margin-right: 10px;">
						</el-date-picker>
						至
						<el-date-picker v-model="form.end_time" type="datetime"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" @change="endTimeStatus"
							:picker-options="pickerOptionsEnd" style="margin-left: 10px;">
						</el-date-picker>
					</el-form-item>
                </div>
                <div class="wrapper-cont pp20 mt10">
					<div class="setTitle">规则设置</div>
                    <el-form-item label="优惠券可用范围:">
						<el-radio v-model="form.available_range" label="1">全场通用</el-radio>
						<el-radio v-model="form.available_range" label="2">指定商品</el-radio>
					</el-form-item>
                     <el-form-item label="活动类型:" >
                        <el-select v-model="form.plugin_limit" multiple placeholder="请选择">
                            <el-option
                            v-for="item in activityData"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                            </el-option>
                        </el-select>
                     </el-form-item>
                     <el-form-item label="选择商品" prop="goods_list" v-if="form.available_range==2">
						<el-button size="small" type="primary" @click="selectGood"  >选择商品</el-button>
						<el-table :data="form.goods_list" v-if="form.goods_list.length>0" style="width: 100%"
							max-height="500" class="mt20">
							<el-table-column type="index" width="50">
							</el-table-column>
							<el-table-column prop="date" label="商品名称" width="380" align="left">
								<template slot-scope="scope">
									<div class="el_name">
										<img v-if="scope.row.goods_main_pic"
											:src="scope.row.goods_main_pic" class="el_img" />
										<div>
											<p>{{ scope.row.goods_name }}</p>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="sku_sale_price" label="销售价"  align="center">
							</el-table-column>
							<el-table-column prop="sku_purchase_price" label="成本价"  align="center">
							</el-table-column>
							<el-table-column label="操作" align="center" class="opera-cont" width="180">
								<template slot-scope="scope">
									<el-button type="text" size="small" style="color: #E02020"
										@click="toDel(scope.$index)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
                     <el-form-item label="券面额:" required >
                            <el-col :span="4.5">
                                <el-form-item prop="denomination_min">
                                    范围内随机 <el-input class="w120 mr10 ml0" oninput="value=value.replace(/[^\d.]/g,'');" v-model="form.denomination_min" style="width: 100%;"></el-input>至
                                </el-form-item>
                                </el-col>
                            <el-col :span="3">
                                <el-form-item  prop="denomination_max">
                                    <el-input class="w120 ml0" oninput="value=value.replace(/[^\d.]/g,'')"  v-model="form.denomination_max" style="width: 100%;"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <div class="pstyle">会员领取优惠券时，金额为该范围内的随机金额，设置大额优惠时需谨慎，避免造成损失，建议金额区间跨度不要过大。</div>
                            </el-col>
                     </el-form-item>
                     <el-form-item label="优惠券领取:">
						<el-radio v-model="form.receiving_method" label="1">范围内随机金额</el-radio>
						<el-radio v-model="form.receiving_method" label="2">第<el-input class="w120 mr10 ml0" oninput="value=value.replace(/[^\d.]/g,'');" v-model="form.receiving_number" ></el-input>个领取<el-input class="w120 mr10 ml0" oninput="value=value.replace(/[^\d.]/g,'');" v-model="form.receiving_money" style="width: 100%;"></el-input>元</el-radio>
                        <div class="pstyle">设置第N个领取N元时，金额不受券面额范围限制。可设置大额优惠券增加活动吸引力。</div>
                    </el-form-item>
                    <el-form-item label="下单人领券面额:" required >
                            <el-col :span="4.5">
                                <el-form-item prop="buyer_denomination_min">
                                    范围内随机 <el-input class="w120 mr10 ml0" oninput="value=value.replace(/[^\d.]/g,'');" v-model="form.buyer_denomination_min" style="width: 100%;"></el-input>至
                                </el-form-item>
                                </el-col>
                            <el-col :span="3">
                                <el-form-item  prop="buyer_denomination_max">
                                    <el-input class="w120 ml0" oninput="value=value.replace(/[^\d.]/g,'')"  v-model="form.buyer_denomination_max" style="width: 100%;"></el-input>
                                </el-form-item>
                            </el-col>
                     </el-form-item>
                     <el-form-item label="消费门槛:" prop="minimum_consumption">
						<el-input v-model="form.minimum_consumption" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150" style="vertical-align:middle;">
									<template slot="append">元</template>
						</el-input>
					</el-form-item>
                    <el-form-item label="优惠券库存:" prop="coupon_stock">
						<el-input v-model="form.coupon_stock" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150" style="vertical-align:middle;">
									<template slot="append">张</template>
						</el-input>
					</el-form-item>
                    <el-form-item label="券有效期:" required>
						<el-radio v-model="form.validity_time" label="1">相对时间</el-radio>
                        <el-radio v-model="form.validity_time" label="2">固定时间</el-radio>
                        <el-row :gutter="14" style="display: flex" v-if="form.validity_time==1">
                            <el-form-item prop="term_fixed_start">
                                从发券<el-input v-model="form.term_fixed_start" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150 ml0" style="vertical-align:middle;">
									<template slot="append">天</template>
						            </el-input>
                            </el-form-item>
                            <div class="form-center">至</div>
                            <el-form-item  class="form-end  ml0" prop="term_fixed_end">
                                <el-input v-model="form.term_fixed_end" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150" style="vertical-align:middle;">
									<template slot="append">天</template>
						            </el-input>
                            </el-form-item>
                           
                        </el-row>
                        <el-row :gutter="14" style="display: flex" v-if="form.validity_time==2">
                            <el-form-item prop="validity_start_times">
                               <el-date-picker
                                v-model="form.validity_start_times"
                                type="date"
								value-format="yyyy-MM-dd"
                                placeholder="开始日期"
                                :picker-options="pickerOptions0">
                            </el-date-picker>
                            </el-form-item>
                            <div class="form-center">至</div>
                            <el-form-item  class="form-end  ml0" prop="validity_end_times">
                               <el-date-picker
                                    v-model="form.validity_end_times"
                                    type="date"
									value-format="yyyy-MM-dd"
                                    placeholder="结束日期"
                                    :picker-options="pickerOptions1">
                                </el-date-picker>
                            </el-form-item>
                        </el-row>
					</el-form-item>
					<el-form-item label="券说明:" prop="coupon_explain">
						<el-input type="textarea" style="width: 600px!important;" placeholder="请输入你的说明" :rows="4" maxlength="200"
							show-word-limit v-model="form.coupon_explain"></el-input>
					</el-form-item>
                </div>
				<div class="wrapper-cont pp20 mt10">
					<div class="setTitle">活动设置</div>
					<el-form-item label="每张优惠券发放数量:" prop="coupon_num">
						<el-input v-model="form.coupon_num" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150" style="vertical-align:middle;">
									<template slot="append">张</template>
						</el-input>
						<p class="pstyle">每个订单赠送的优惠券数量。举例：数量设置为10，则每笔订单送出去10张优惠券，最多可有10位用户分别领取一张。</p>
					</el-form-item>
					<el-form-item label="优惠券赠送条件:" prop="coupon_condition">
						订单实付金额满<el-input v-model="form.coupon_condition" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150 ml0" style="vertical-align:middle;">
									<template slot="append">元</template>
						</el-input>
					</el-form-item>
					<el-form-item label="领取次数限制:" >
						<el-radio v-model="form.receiving_limit" label="1">不限制</el-radio>
						<p class="mt10">
							<el-radio v-model="form.receiving_limit" label="2">
							每人活动期间最多领取<el-input v-model="form.more_times" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150 ml0" style="vertical-align:middle;">
									<template slot="append">次</template>
							</el-input>
						</el-radio>
						</p>
                        <p class="mt10">
						 <el-radio v-model="form.receiving_limit" label="3">
							 每人每天最多领取<el-input v-model="form.day_times" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150 ml0" style="vertical-align:middle;">
									<template slot="append">次</template>
							</el-input>
						 </el-radio>
						 </p>
					</el-form-item>
					<el-form-item label="活动规则说明:" prop="activity_explain">
						<el-input type="textarea" style="width: 600px!important;" placeholder="请输入活动规则说明" :rows="4" maxlength="200"
							show-word-limit v-model="form.activity_explain"></el-input>
					</el-form-item>
				</div>
				<div class="wrapper-cont pp20 mt10">
					<div class="setTitle">分享设置</div>
					<el-form-item label="分享方式:" >
						<el-radio v-model="form.share_mode" label="1">默认内容</el-radio>
						<el-radio v-model="form.share_mode" label="2">自定义</el-radio>
					</el-form-item>
					<el-form-item label="分享标题:" prop="share_title" v-if="form.share_mode==2">
						<el-input style="width: 400px!important;" size="small" maxlength="20" show-word-limit
							v-model="form.share_title">
						</el-input>
					</el-form-item>
					<el-form-item label="分享图标:" prop="share_icon" v-if="form.share_mode==2">
						<div class="img_blos">
							<div class="img_blo" @click="uploadImg()">
								<img :src="form.share_icon" class="item_img" v-if="form.share_icon" />
								<div class="plusImg" v-else>
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
						</div>
						<div style="color: #999;font-size: 12px;">
							建议尺寸330*330px
						</div>
					</el-form-item>
					<el-form-item label="分享简介:"  v-if="form.share_mode==2">
						<el-input type="textarea" style="width: 600px!important;" placeholder="请输入活动规则说明" :rows="4" maxlength="200"
							show-word-limit v-model="form.share_profile"></el-input>
					</el-form-item>
				</div>
            </el-form>
        </div>
        	<div class="save-cont" v-if="btnState=='detail'">
			<div class="cancel-btn" @click="toBack">返回</div>
		</div>
		<div class="save-cont" v-else-if="btnState=='edit' || btnState=='add'">
			<div class="cancel-btn" @click="cancelSet">取消</div>
			<div class="save-btn" @click="submitSet('form')">提交</div>
		</div>
        <!--商品弹框-->
		<el-dialog title="选择商品" :visible.sync="dialogVisible" width="55%">
			<el-row :gutter="20">
				<el-col :span="5">
					<div class="grid-content bg-purple treestyle">
						<el-radio-group size="small" v-model="tabPosition" @change="changeTab" style="margin-bottom: 30px;">
						    <el-radio-button label="1">平台</el-radio-button>
						    <el-radio-button label="2">自营</el-radio-button>
						  </el-radio-group>
						  <div style="max-height: 600px; overflow-y: scroll;">
							  <el-tree :data="treedata" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
						  </div>
						
					</div>
				</el-col>
				<el-col :span="19">
					<div class="grid-content bg-purple ">
						<el-row class="all_search_list" style="margin-bottom: 0;">
							<div class="demo-input-suffix">
								<el-input prefix-icon="el-icon-search" v-model="search.keyword" placeholder="请输入商品名称" size="small"
									type="text">
								</el-input>
							</div>
							<div class="demo-input-suffix">
								<el-button size="small" type="primary" slot="append" @click="searchOrder()">搜索
								</el-button>
								<el-button size="small" @click="toReset">重置</el-button>
							</div>
						</el-row>
						<el-table border :data="tableData" ref="multipleTable" @selection-change="handleSelectionChange"
							style="width: 100%;margin-top:15px">
							<el-table-column type="selection" width="55" align="center">
							</el-table-column>
							<el-table-column prop="goods_name" label="商品名称" align="left"  width="350">
								<template slot-scope="scope">
									<div class="el_name">
										<img v-if="scope.row.goods_main_pic"
											:src="scope.row.goods_main_pic" class="el_img" />
										<div>
											<p>{{ scope.row.goods_name }}</p>
										</div>
									</div>
								
								</template>
							</el-table-column>
							<el-table-column prop="sku_purchase_price" label="成本价" align="center">
							</el-table-column>
							<el-table-column prop="sku_sale_price" label="销售价" align="center">
							</el-table-column>
							<el-table-column prop="goods_stock" label="库存" align="center">
							</el-table-column>
						</el-table>
					</div>
				</el-col>
			</el-row>
			<!-- 分页 -->
			<div class="pagecurrent">
				<div class="pagination-container">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureAddGood" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
    </div>
</template>
<script>
import ChooseImg from "../../../../../common/uploadImgs";
import {
		getCategoryList,
		getCouponGoodsList,
		couponPluginList,
		couponCreate,
		activityInfo
	} from '@/api/merchant/marketing.js'

export default{
	components: {
			ChooseImg
		},
    data(){
        //券面额
         var denominationStart = (rule, value, callback) => {
            if(this.form.denomination_max){
                this.$refs.form.validateField('denomination_max');
            }
            callback();
        };
        var denominationEnd = (rule, value, callback) => {
            if(this.form.denomination_min){
                if(Number(this.form.denomination_min) <= Number(value)){
                callback();
                }else{
                callback(new Error('结束券面额必须大于开始券面额'));
                }
            }else{
                callback();
            }
        }
        //发券人券面额
        var orderdenominationStart = (rule, value, callback) => {
            if(this.form.buyer_denomination_max){
                this.$refs.form.validateField('buyer_denomination_max');
            }
            callback();
        };
        var orderdenominationEnd = (rule, value, callback) => {
            if(this.form.buyer_denomination_min){
                if(Number(this.form.buyer_denomination_min) <= Number(value)){
                callback();
                }else{
                callback(new Error('结束券面额必须大于开始券面额'));
                }
            }else{
                callback();
            }
        }
        //券有效期--固定时间
        var termfixedStart = (rule, value, callback) => {
            if(this.form.term_fixed_end){
                this.$refs.form.validateField('term_fixed_end');
            }
            callback();
        }
         var termfixedEnd = (rule, value, callback) => {
            if(this.form.term_fixed_start){
                if(Number(this.form.term_fixed_start) <= Number(value)){
                callback();
                }else{
                callback(new Error('结束时间必须大于开始时间'));
                }
            }else{
                callback();
            }
        }
        return{
			/* end*/
				isSingle: true,
				uploadFlag: false, //是否展示选择图片弹窗
            form:{
                activity_name:'',//活动名称
                start_time: '',//活动日期
				end_time: '',//活动日期
                available_range:'1',
                plugin_limit:[],//优惠券适用活动
                goods_list:[],//商品
                denomination_min:'',//领券面额 
                denomination_max:'',//领券面额 
                receiving_method:'1',
                receiving_number:'',//优惠券领取个数
                receiving_money:'',//优惠券领取金额
                buyer_denomination_min:'',//下单人领券面额 
                buyer_denomination_max:'',//下单人领券面额 
                minimum_consumption:'',//消费门槛
                coupon_stock:'',//消费券库存
                validity_time:'1',//优惠券期限
                term_fixed_start:'',//券固定开始时间
                term_fixed_end:'',//券固定结束时间
                validity_start_times:'',//券相对开始时间
                validity_end_times:'',//券相对开始时间
				coupon_explain:'',//券说明
				coupon_num:'',//券发放数量
				coupon_condition:'',//券赠送条件
				receiving_limit:'1',//领取次数限制
				more_times:'',//期间最多领取
				day_times:'',//每天最多领取
				activity_explain:'',//活动规则说明
				share_mode:'2',//分享方式
				share_title:'',//分享标题
				share_icon:'',//分享图标
				share_profile:'',//分享简介
            },
            rules:{
                activity_name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
               start_time: [{
						required: true,
						message: '请选择时间',
						trigger: 'change'
					}],
      
                denomination_min: [{
						required: true,
						message: '请输入券面额',
						trigger: 'blur'
					},
                     { validator: denominationStart, trigger: 'blur' }],
                denomination_max: [{
						required: true,
						message: '请输入券面额',
						trigger: 'blur'
					},
                    { validator: denominationEnd, trigger: 'blur' }],
                buyer_denomination_min: [{
						required: true,
						message: '请输入券面额',
						trigger: 'blur'
					},
                     { validator: orderdenominationStart, trigger: 'blur' }],
                buyer_denomination_max: [{
						required: true,
						message: '请输入券面额',
						trigger: 'blur'
					},
                    { validator: orderdenominationEnd, trigger: 'blur' }],
                 minimum_consumption: [{
						required: true,
						message: '请输入消费门槛',
						trigger: 'blur'
					}],
                 coupon_stock: [{
						required: true,
						message: '请输入消费券库存',
						trigger: 'blur'
					}],
                term_fixed_start: [
                    {required: true,message: '请输入券有效期',trigger: 'blur'},
                     { validator: termfixedStart, trigger: 'blur' }],
                term_fixed_end: [
                    {required: true,message: '请输入券有效期',trigger: 'blur'},
                    { validator: termfixedEnd, trigger: 'blur' }],
                validity_start_times: [{
						required: true,
						message: '请选择开始时间',
						trigger: 'change'
					}],
                validity_end_times: [{
						required: true,
						message: '请选择结束时间',
						trigger: 'change'
					}],
				coupon_explain: [{
						required: true,
						message: '请输入券须知',
						trigger: 'blur'
					}],
				coupon_num: [{
						required: true,
						message: '请输入券发放数量',
						trigger: 'blur'
					}],
				coupon_condition: [{
						required: true,
						message: '请输入券赠送条件',
						trigger: 'blur'
					}],
				activity_explain: [{
						required: true,
						message: '请输入活动规则说明',
						trigger: 'blur'
					}],
				share_title: [{
						required: true,
						message: '请输入分享标题',
						trigger: 'blur'
					}],
				share_icon: [{
					required: true,
					message: '请上传分享图标',
					trigger: 'change'
				}],
            },
            activityData:[],//优惠券适用活动
            // 开始和结束日期转换的时间戳
            startTime: null,
            endTime: null,
            /* start 开始时间小于今天,结束时间不能大于开始时间 */
            //time.getTime() < Date.now()-8.64e7 今天之前的时间不能选
            pickerOptionsStart: {
                disabledDate: time => {
                    if (this.form.end_time) {
                        return time.getTime() < Date.now() - 8.64e7 || time.getTime() > this.form
                            .end_time;
                    } else {
                        return time.getTime() < Date.now() - 8.64e7; //当前时间之后
                    }
                }
            },
            pickerOptionsEnd: {
                disabledDate: time => {
                    // return time.getTime() < Date.now() - 8.64e7 || time.getTime() < this.form.start_time;
                    if (this.form.start_time) {
                        return time.getTime() < new Date(this.form.start_time).getTime()
                    } else {
                        return time.getTime() < Date.now() - 8.64e7; //当前时间之后
                    }
                },
            },
            //发券相对时间
            pickerOptions0: {
                disabledDate: (time) => {
                     if (this.form.validity_end_times) {
                        return time.getTime() < Date.now() - 8.64e7 || time.getTime() > this.form
                            .validity_end_times;
                    } else {
                        return time.getTime() < Date.now() - 8.64e7; //当前时间之后
                    }
 
                }
            },
            pickerOptions1: {
                disabledDate: (time) => {
                   if (this.form.validity_start_times) {
                        return time.getTime() < new Date(this.form.validity_start_times).getTime()
                    } else {
                        return time.getTime() < Date.now() - 8.64e7; //当前时间之后
                    }
                }
            },
         dialogVisible: false,
         tabPosition:'1',
        treedata:[],
        defaultProps: {
            children: 'children',
            label: 'name'
        },
        tabPosition:'1',
        allCate: [],
		tableData: [], // 表格数据
        //商品弹框
        search: {
            goods_source:'',
            cate_id:'',
            keyword:'',
        },
		idKey: "goods_unified_id", // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
        total: 0,
        currentPage: 1, //初始页
        pagesize: 10, //每页的数据
        multipleSelectionAll: [], // 所有选中的数据包含跨页数据
		multipleSelection: [], // 当前页选中的数据
		checkedData: [], // 初始化弹窗选中的行
		activity_id:'',
		btnState: this.$route.query.type,
        }
    },
		mounted() {
			if (this.$route.query.id) {
				this.activity_id = this.$route.query.id;
				 this.getDetailInfo()
			}
			couponPluginList({}).then(res=>{
				this.activityData=res.data
			})
			this.$nextTick(function() {
				// 模拟初始化一个选中
				this.checkedData = []
			})

		},
		watch: {
			'checkedData': {
				handler(val) {
					// 转换一下目的是为了不被同步
					this.multipleSelectionAll = val;
				},
				immediate: true,
				deep: true
			},
			'checkDefault':function(newVal, oldVal) {
			       if(newVal){
			         this.$nextTick(() => {
			              document.querySelector('.el-tree-node__content').click()
			          })
			       }
			     }
		},
    methods:{
			getDetailInfo() {
				let data = {
					activity_id: this.activity_id
				}
				activityInfo(data).then(res => {
					this.form = res.data;
					this.multipleSelectionAll = res.data.goods_list;
					let dataStrArr=this.form.plugin_limit.split(',');  //分割成字符串数组  
					let dataIntArr=[];//保存转换后的整型字符串 
					 dataIntArr=dataStrArr.map(item => {  
					return +item;  
					}); 
					this.form.plugin_limit=  dataIntArr;
					if(this.form.validity_time==1){
							this.form.term_fixed_start=res.data.validity_start_time;
							this.form.term_fixed_end=res.data.validity_end_time;
							this.form.validity_start_times='';
							this.form.validity_end_times='';
						}else{
							this.form.validity_start_times=res.data.validity_start_time;
							this.form.validity_end_times=res.data.validity_end_time;
							this.form.term_fixed_start='';
							this.form.term_fixed_end=''
						}
						if(this.form.receiving_limit==2){
							this.form.more_times=res.data.limit_times;
							this.form.day_times='';
						}else if(this.form.receiving_limit==3){
							this.form.day_times=res.data.limit_times;
							this.form.more_times=''
						}
			
				})
			},
			uploadImg() {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				this.form.share_icon = val[0].resource_name;
			},
        	// 时间开始选择器
			startTimeStatus(val) {
				let date = new Date(val).getTime();
				let currentDate = new Date().getTime();
				this.startTime = date;
				if(this.startTime<currentDate){
					this.$message.error('开始时间要大于当前时间');
					this.form.start_time = '';
					this.startTime = null;
				}
				if (this.endTime) {
					if (this.endTime < this.startTime) {
						this.$message.error('开始时间不能大于结束时间');
						this.form.start_time = '';
						this.startTime = null;
					}
				}
			},
			// 时间结束选择器
			endTimeStatus(val) {
				let date = new Date(val).getTime();
				this.endTime = date;
				if (this.startTime) {
					if (this.endTime <= this.startTime) {
						this.$message.error('结束时间不能小开始时间');
						this.form.end_time = '';
						this.endTime = null;
					}
				}
			},
            //选择优惠券商品
			selectGood() {
				this.getAllCase();
				this.dialogVisible = true;
				// 初始化渲染
				this.currentPage = 1;
				this.getGoodsList();
			},
            	//切换分类
			changeTab(type){
				this.tabPosition=type;
				this.currentPage = 1;
				this.getAllCase();
            },
            //选择分类
            handleNodeClick(data) {
                this.search.cate_id=data.id;
                this.getGoodsList();
            },
            	//获取所有商品分类
			getAllCase() {
				getCategoryList({type:this.tabPosition}).then(res => {
					this.treedata=res.data;
					let pp={
						id: '',
						img: "https://cattle-shop.oss-accelerate.aliyuncs.com/df7b689ed7b511eb959500163e0fb62d.png",
						is_show: 1,
						level: 0,
						name: "全部",
						parent_id: '',
					}
					this.treedata.unshift(pp);
					let aa=[];
					aa.push(this.treedata[0].id);
					this.checkDefault=aa;
				})
			},
            	//搜索商品
			searchOrder() {
				this.getGoodsList()
			},
			toReset() {
				this.search.cate_id = '';
				this.search.keyword = '';
				this.getGoodsList()
			},
            	//获取商品列表
			getGoodsList() {
				let data = {
					goods_source:this.tabPosition,
					cate_id:this.search.cate_id,
					keyword:this.search.keyword,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				getCouponGoodsList(data).then(res => {
					this.tableData = res.data.list;
					this.total = res.data.count;
					if (this.tableData.length > 0) {
						this.$nextTick(function() {
							// 初始化渲染
							this.setSelectRow();
						})
					}
				})
			},
            //删除
			toDel(index) {
				this.tools.confirm("确定要删除此商品吗？").then(res => {
					if (res) {
						//调接口
						let row = [...this.form.goods_list];
						row.splice(index, 1)
						this.form.goods_list = [...row];
						// console.log(this.form.goods_list,"this.form.goods_list")
						 this.multipleSelectionAll=this.form.goods_list ;
						this.$forceUpdate();
						
					}
				})
			},
            //分页
			handleSizeChange(size) {
				this.changePageCoreRecordData();
				this.pagesize = size;
				// 掉接口
				this.getGoodsList()
			},
			handleCurrentChange(currentPage) {
				this.changePageCoreRecordData();
				this.currentPage = currentPage;
				// 掉接口
				this.getGoodsList()
			},
            //选择商品
            handleSelectionChange(val) {
            // table组件选中事件,记得加上@selection-change="handleSelectionChange"
             this.multipleSelection = val;
			},
            	// 设置选中的方法
			setSelectRow() {
				if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
					return;
				}
				// 标识当前行的唯一键的名称
				let idKey = this.idKey;
				let selectAllIds = [];
				let that = this;
				this.multipleSelectionAll.forEach(row => {
					selectAllIds.push(row[idKey]);
				});
				this.$refs.multipleTable.clearSelection();
				for (var i = 0; i < this.tableData.length; i++) {
					if (selectAllIds.indexOf(this.tableData[i][idKey]) >= 0) {
						// 设置选中，记住table组件需要使用ref="table"
						this.$refs.multipleTable.toggleRowSelection(this.tableData[i], true);

					}
				}
			},

			// 记忆选择核心方法
			changePageCoreRecordData() {
				// 标识当前行的唯一键的名称
				let idKey = this.idKey;
				let that = this;
				// 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
				if (this.multipleSelectionAll.length <= 0) {
					this.multipleSelectionAll = this.multipleSelection;
					return;
				}
				// 总选择里面的key集合
				let selectAllIds = [];
				this.multipleSelectionAll.forEach(row => {
					selectAllIds.push(row[idKey]);
				});
				let selectIds = [];
				// 获取当前页选中的id
				this.multipleSelection.forEach(row => {
					selectIds.push(row[idKey]);
					// 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
					if (selectAllIds.indexOf(row[idKey]) < 0) {
						that.multipleSelectionAll.push(row);
					}
				});
				let noSelectIds = [];
				// 得到当前页没有选中的id
				this.tableData.forEach(row => {
					if (selectIds.indexOf(row[idKey]) < 0) {
						noSelectIds.push(row[idKey]);
					}
				});
				noSelectIds.forEach(id => {
					if (selectAllIds.indexOf(id) >= 0) {
						for (let i = 0; i < that.multipleSelectionAll.length; i++) {
							if (that.multipleSelectionAll[i][idKey] == id) {
								// 如果总选择中有未被选中的，那么就删除这条
								that.multipleSelectionAll.splice(i, 1);
								break;
							}
						}
					}
				});
			},
            sureAddGood() {
				this.changePageCoreRecordData();
				if (this.multipleSelectionAll.length <= 0) {
					this.$message({
						message: '无勾选数据！',
						type: 'warning'
					});
					return;
				}
		
				if (this.multipleSelectionAll.length > 50) {
					this.$message({
						message: '最多选择50款商品！',
						type: 'warning'
					});
					return;
				}
				// console.log(this.multipleSelectionAll, "-----");

				this.form.goods_list = this.multipleSelectionAll;
				// console.log(this.form.goods_list,"this.form.goods_list")

				this.dialogVisible = false;
			},
            //返回
			cancelSet() {
				this.$router.go(-1)
			},
			toBack() {
				this.$router.push({
					path: "/marketingcenteradmin/fissioncoupon/couponlist",
			 })
			},
        	//提交设置
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					// this.$router.push({
					// 	path: '/marketingcenteradmin/luckypuzzle/couponlist',
						
					// })
					if (valid) {
						//调接口
						let datas = JSON.parse(JSON.stringify(this.form));
						// console.log(datas,"datadata")
						datas.plugin_limit=datas.plugin_limit.toString();
						datas.share_icon = this.tools.filterImgPre(datas.share_icon);
						const goods_unified_ids=[];
						datas.goods_list.forEach(item=>{
								goods_unified_ids.push(item.goods_unified_id)
						})
						datas.goods_unified_ids=goods_unified_ids.toString();
						if(datas.validity_time==1){
							datas.validity_start_time=datas.term_fixed_start;
							datas.validity_end_time=datas.term_fixed_end;
							datas.validity_start_times='';
							datas.validity_end_times='';
						}else{
							datas.validity_start_time=datas.validity_start_times;
							datas.validity_end_time=datas.validity_end_times;
							datas.term_fixed_start='';
							datas.term_fixed_end=''
						}
						if(datas.receiving_limit==2){
							datas.limit_times=datas.more_times;
							datas.day_times='';
						}else if(datas.receiving_limit==3){
							datas.limit_times=datas.day_times;
							datas.more_times=''
						}
						if(datas.share_mode == 1){
							datas.share_title = '';
							datas.share_icon = '';
							datas.share_profile = '';
						}
						 delete datas["day_times"];
						 delete datas['goods_list']
						 delete datas['more_times']
						 delete datas['validity_end_times']
						 delete datas['validity_start_times']
						 delete datas['term_fixed_start']
						 delete datas['term_fixed_end']
						 if(this.activity_id){
							 datas.activity_id=this.activity_id
						 }
						datas = this.tools.filterParams(datas);
						couponCreate(datas).then(() => {
							if(this.activity_id){
								this.$message.success("编辑成功！")
							}else{
								this.$message.success("添加成功！")
							}
							this.$router.push({
								path: "/marketingcenteradmin/fissioncoupon/couponlist",
							})
						})
					} else {
						return false;
					}
				});
			}

    }
}
</script>
<style scoped lang="scss">
	.el_name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.el_img {
			width: 31px;
			height: 31px;
			margin-right: 6px;
		}
	}
.form-center{
  margin-left: 20px;
  line-height: 40px;
}
.form-conten{
  position: relative;
  width:650px;
  .remaining{
    position:absolute;
    bottom: 10px;
    right: 30px;
  }
}


</style>