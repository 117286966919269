<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt" style="padding: 20px;padding-bottom: 0;">
					<el-row>
						<div class="all_search_list">
							<div class="demo-input-suffix">
								<el-select style="width: 150px !important;" size="small" @change="changeState"
									v-model="status" class="inputWidth" placeholder="请选择审核状态">
									<!-- <el-option label="全部" value=""></el-option> -->
									<el-option label="未审核" value="1"></el-option>
									<el-option label="审核通过" value="2"></el-option>
									<el-option label="审核拒绝" value="3"></el-option>
								</el-select>
							</div>
							<div class="demo-input-suffix">
								<el-input prefix-icon="el-icon-search" class="inputWidth" size="small" clearable
									placeholder="手机号" v-model="account" @keyup.enter.native="toSearch">
								</el-input>
							</div>
							<div class="demo-input-suffix">
								<el-button type="primary" size="small" @click="toSearch">查询</el-button>
							</div>
							<div class="demo-input-suffix">
								<el-button size="small" @click="toReset">重置</el-button>
							</div>
						</div>
					</el-row>
				</div>
				<!--表格-->
				<div class="tableMain">
					<el-table :data="tableData" style="width: 100%" min-height="250" border max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
						@selection-change="handleSelectionChange" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<!-- <el-table-column type="selection" width="55" align="center">
						</el-table-column> -->
						<el-table-column prop="shop_name" label="所属店铺" align="center">
						</el-table-column>
						<el-table-column prop="username" label="联系人名称" align="center">
						</el-table-column>
						<el-table-column prop="account" label="联系手机号" align="center">
						</el-table-column>
						<el-table-column  label="身份类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.application_type == 1">学生</span>
								<span v-else-if="scope.row.application_type == 2">军人</span>
								<span v-else-if="scope.row.application_type == 3">残疾人</span>
							</template>
						</el-table-column>
						<el-table-column prop="linetype" label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.status == 1">未审核</span>
								<span v-else-if="scope.row.status == 2">审核通过</span>
								<span v-else-if="scope.row.status == 3">审核拒绝</span>
							</template>
						</el-table-column>
						<el-table-column prop="reason_refusal" label="原因" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="创建时间" align="center">
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="150" align="center">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toDetail(scope.row)"  v-has="'查看'">查看</el-button>
								<el-button type="text" size="small"
									v-if="scope.row.status && scope.row.status == '1'"
									@click="toOperate('single',scope.row,2)"  v-has="'通过'">通过</el-button>
								<el-button type="text" size="small"
									v-if="scope.row.status && scope.row.status == '1'"
									@click="toOperate('single',scope.row,3)" v-has="'拒绝'">拒绝</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<!--拒绝原因弹框-->
		<el-dialog title="拒绝原因" :visible.sync="dialogVisible" width="25%" :before-close="handleClose">
			<div>
				<el-input type="textarea" placeholder="请输入内容" v-model="desc_txt" rows="4" maxlength="50"
					show-word-limit>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="toSubmitCheck()" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		businessSupportAuditList,
		auditBusinessSupportAudit
	} from "@/api/plat/business.js"
	export default {
		data() {
			return {
				loading:true,
				status: '',
				account: '',
				tableData: [],
				dialogVisible: false,
				desc_txt: '',
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				multipleSelection: [],
				entrepreneurship_support_id: ''
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let data = {
					status: this.status,
					page: this.currentPage,
					perPage: this.pagesize,
					account: this.account
				}
				data = this.tools.filterParams(data);
				businessSupportAuditList(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list
				})
			},
			//切换状态搜索
			changeState(val) {
				this.status = val;
			},
			toSearch() {
				this.currentPage=1;
				this.getList()
			},
			toReset() {
				this.currentPage=1;
				this.status = '';
				this.account = '';
				this.getList()
			},
			//审核操作
			//批量选择
			handleSelectionChange(val) {
				let ids = [];
				val.forEach(item => {
					ids.push(item.account_shop_id)
				})
				this.multipleSelection = ids;
			},
			toOperate(type, row, state) {
				if (type == 'single' && state == 2) {
					this.entrepreneurship_support_id = row.entrepreneurship_support_id;
					this.toCheck(state);
				}
				if (type == 'single' && state == 3) {
					this.desc_txt='';
					this.dialogVisible = !this.dialogVisible
					this.entrepreneurship_support_id = row.entrepreneurship_support_id
				}

			},
			toCheck(state) {
				let data = {
					entrepreneurship_support_id:this.entrepreneurship_support_id,
					status:state,
					reason_refusal:this.desc_txt
				}
				data = this.tools.filterParams(data);
				auditBusinessSupportAudit(data).then(res => {
					if (state == 3) {
						this.$message.success("提交成功！");
						this.dialogVisible = !this.dialogVisible;
						this.getList();
					}else{
						this.$message.success("审核通过！");
						this.getList();
					}
				})
			},
			toSubmitCheck() {
				if (this.desc_txt == '') {
					this.$message.warning("请填写拒绝原因");
					return;
				}
				this.toCheck(3);
			},
			toDetail(row) {
				this.$router.push({
					path: "/clientadmin/assistanceaudit/assistanceauditdetail",
					query: {
						id: row.entrepreneurship_support_id
					}
				})
			},
			handleClose(done) {
				this.dialogVisible = !this.dialogVisible
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
		}
	}
</script>

<style>
</style>
