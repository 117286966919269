<template>
	<div class="navs">
		<div class="navs_blo">
			<div class="cons_btn">
				<el-button size="small" type="primary" @click="fbFooter" :loading="isLoading">发布</el-button>
				<el-button class="btn_cancel" size="small" @click="saveFooter">保存</el-button>
			</div>
			<el-tabs v-model="activeName">
				<el-tab-pane label="页面导航" name="first">
					<div class="cons">
						<div class="cons_left">
							<img src="../../../../../assets/img/merchant/store/footer_back.png" class="left_back" />
							<div class="left_bot">
								<div v-for="(item, index) in footerIcon" :key="index" class="bots">
									<img :src="item.use_icon" />
									<div :style="{ color: navColor }">
										{{ item.webpage_name }}
									</div>
								</div>
							</div>
						</div>
						<div class="cons_right">
							<div class="right_top">
								<div class="top_desc">备注：底部导航有一项必须是首页链接</div>
								<div class="top_color">
									<label>导航配色</label>
									<div class="color_item">
										<el-color-picker v-model="navSelectColor"></el-color-picker>
										<div>选中</div>
									</div>
									<div class="color_item">
										<el-color-picker v-model="navColor"></el-color-picker>
										<div>未选中</div>
									</div>
								</div>
							</div>
							<div class="right_con">
								<div v-for="(item, index) in footerIcon" :key="index" class="con">
									<i class="el-icon-error con_img" @click="delNav(index)" v-if="index != 0"></i>
									<div class="con_nav">导航{{ index + 1 }}</div>
									<div class="con_blo">
										<div class="blo_name">
											<label class="labels">名称 <span class="img_x"
													style="margin-right: 0"></span></label>
											<el-input size="small" class="inputwidth" type="text"
												v-model="item.webpage_name" maxlength="4" show-word-limit></el-input>
										</div>
										<div class="blo_url">
											<label class="labels">链接<span class="img_x"
													style="margin-right: 0"></span></label>
											<div class="url" @click="showUrls(index, item)">
												{{ item.title }}
											</div>
										</div>
										<div class="blo_name">
											<label class="labels">排序 <span class="img_x"
													style="margin-right: 0"></span></label>
											<el-input size="small" class="inputwidth" type="number"
												v-model="item.weight" min="0"></el-input>
										</div>
										<div class="blo_img">
											<p class="img_p">
												<label>图标</label><span class="img_x"></span> 图标上传
											</p>
											<div class="imgs" >
												<p @click="uploadImg(index, 1)">
													<img :src="item.use_icon" v-if="item.use_icon" />
													<span>选中</span>
												</p>
												<p @click="uploadImg(index, 2)">
													<img :src="item.icon" v-if="item.icon" />
													<span>未选中</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div @click="addNav" class="add_navs" v-if="footerIcon.length !== 5">
								添加导航
							</div>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<!-- {{addCheck}} {{ removeCheck}} -->
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="true" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
		<!-- 页面链接弹出层 -->
		<!--:type="1"默认传1  只是底部导航的设置   2是页面设置-->
		<pageUrl :type="1" :nowChose="nowChose" :nowReplaceChose="nowReplaceChose" :curPos="curPos"
			:footerIcon="footerIcon" @closePage="hidePage" @okPage="submitPage" v-if="pageFlag"></pageUrl>
	</div>
</template>

<script>
	import ChooseImg from "../../../../common/uploadImgs.vue";
	import pageUrl from "../../../../pagecomponents/pageUrl.vue";
	import {
		getWebpageStoreBinding,
		delWebpageStoreBinding,
		setWebpageStoreBinding,
		getPageLink,
		publish,
	} from "@/api/pagenav";
	let nowChose = "";
	let nowChoseId = "";
	let nowReplaceChose = "";
	export default {
		components: {
			ChooseImg,
			pageUrl,
		},
		data() {
			return {
				isLoading: false, //是否显示等待中
				pageFlag: false, //是否展示页面链接弹出层
				uploadFlag: false, //是否展示图片弹窗
				uploadIndex: -1, //导航索引
				chooseType: "", //图片展示类型
				total: 30,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				chooseIndex: -1, //选择链接
				curPos: -1, //当前url弹出层
				isUrl: false, //是否展示url选择弹出层
				activeName: "first",
				urlName: "urlone",
				urlNames: "基础页面",
				navColor: "#666666",
				navSelectColor: "#E02020",
				footerIcon: [],
				footerIconCopy: [],
			};
		},
		mounted() {
			// 获取配置信息
			this.getFootList();
		},
		methods: {
			//获取页面标题
			getFootList() {
				getWebpageStoreBinding({
						versions: "dev"
					})
					.then((res) => {
						// console.log("list", res);
						// 设置颜色
						this.navColor = res.data[0].font_color;
						this.navSelectColor = res.data[0].use_font_color;
						this.footerIcon = res.data;
						this.footerIconCopy = res.data;
					})
				
			},
			//添加导航
			addNav() {
				this.footerIcon.push({
					webpage_name: "",
					img: require("../../../../../assets/img/merchant/store/default_footer.png"),
					urls: "",
					url: "",
				});
			},
			// 删除导航
			delNav(index) {
				let urlId = this.footerIcon[index].webpage_link_id;//店铺页面链接使用id
				this.footerIcon.splice(index, 1);
			},
			// 点击保存
			saveFooter(type) {
				let icons = JSON.parse(JSON.stringify(this.footerIcon));
				// 链接参数=》后期有用
				// 校验 数据是否完整
				let isChecks = icons.some((res) => {
					return (
						!res.webpage_name ||
						!res.icon ||
						!res.use_icon ||
						!res.webpage_link_id
					);
				});
				if (isChecks) {
					this.$message("请完善导航信息");
					return;
				}
				// 过滤图片前缀
				icons.forEach((item) => {
					item.icon = this.tools.filterImgPre(item.icon);
					item.use_icon = this.tools.filterImgPre(item.use_icon);
				});
				let search = {
					webpageStore: JSON.stringify(icons),
					fontColor: this.navColor,
					useFontColor: this.navSelectColor,
				};
				// 掉保存接口
				setWebpageStoreBinding(this.tools.filterParams(search))
					.then((res) => {
						if (type != 1) {
							this.$message("保存成功");
						}
					})
				
			},
			// 点击发布
			async fbFooter() {
				this.isLoading = true;
				await this.saveFooter(1);
				// 掉发布接口
				setTimeout(() => {
					publish()
						.then((res) => {
							this.$message("发布成功");
							this.isLoading = false;
						})
					
				}, 1000)

			},
			// 选择图片
			uploadImg(parIndex, type) {
				this.uploadIndex = parIndex;
				this.chooseType = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				let navs = [...this.footerIcon];
				this.uploadFlag = false;
				if (this.chooseType == 1) {
					navs[this.uploadIndex].use_icon = val[0].resource_name;
				} else if (this.chooseType == 2) {
					navs[this.uploadIndex].icon = val[0].resource_name;
				}
				this.footerIcon = [...navs];
			},
			// 显示链接弹出层
			showUrls(index, item) {
				this.pageFlag = !this.pageFlag;
				this.nowChose = item.title;//标题
				this.nowReplaceChose = item.title;
				this.curPos = index;
			},
			//取消页面链接弹出
			hidePage(val) {
				this.pageFlag = val;
			},
			//页面链接点击确定
			submitPage(val) {
				let icons = [...val]
				this.footerIcon = [...icons];
				//请空之前选择的
				nowChoseId = "";
				nowChose = "";
				nowReplaceChose = "";
				this.pageFlag = false;
			},
		},
	};
</script>

<style lang="sass" scoped>
	@import '../../../../../assets/css/merchant/storenav.scss'
</style>
