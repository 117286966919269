<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="order-search" style="margin-top: 0;">
					<el-row class="all_search_list" style="margin-bottom: 0;">
						<div class="demo-input-suffix">
							<el-select size="small" v-model="search.oas_status" placeholder="请选择售后状态">
								<el-option label="全部" value=""></el-option>
								<el-option label="审核中" value="1"></el-option>
								<el-option label="审核失败" value="2"></el-option>
								<el-option label="审核成功退货" value="3"></el-option>
								<el-option label="待收货" value="4"></el-option>
								<el-option label="已收货" value="5"></el-option>
								<el-option label="退款中" value="6"></el-option>
								<el-option label="完成" value="7"></el-option>
								<el-option label="取消" value="8"></el-option>
								<el-option label="关闭" value="9"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-select size="small" v-model="search.oas_genre" placeholder="请选择售后类型">
								<el-option label="全部" value=""></el-option>
								<el-option label="退款" value="1"></el-option>
								<el-option label="退货退款" value="2"></el-option>
							</el-select>
						</div>
						<div class="demo-input-suffix">
							<el-select class="w150" size="small" v-model="search.order_ganre"
								placeholder="请选择订单类型">
								<el-option label="全部" value=""></el-option>
								<el-option label="普通订单" value="1"></el-option>
								<el-option label="秒杀订单" value="3"></el-option>
								<el-option label="拼团订单" value="4"></el-option>
								<el-option label="视频号订单" value="5"></el-option>
								<el-option label="裂变优惠券" value="6"></el-option>
							</el-select>
						</div>

						<div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
							<div class="block">
								<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
									range-separator="至" @change="changetimer" start-placeholder="开始日期" :default-time="['00:00:00', '23:59:59']"
									:clearable="false" end-placeholder="结束日期"></el-date-picker>
							</div>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="toSearch">搜索</el-button>
						</div>
						<div class="demo-input-suffix">
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
					</el-row>
				</div>
				<div class="tableMain">
					<el-table :data="tableData"
						style="font-size: 12px;width: 100%;" max-height="600px" :default-expand-all="true" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<div style="overflow: hidden">
							<el-table-column type="expand" width="1">
								<template slot-scope="props">
									<table border="1" class="tableStyle">
										<tbody>
											<tr>
												<td style=" width:26%;">
													<div style="display: flex;margin-bottom: 8px;" class="mt10">
														<div style="margin:0 10px">
															<!--   -->
															<div style="width:70px;height:70px;" >
																<el-image style="width: 100%;height: 100%;" :src="props.row.order_goods_info.master_map.split(',',1)" class="el_img" :preview-src-list="[props.row.order_goods_info.master_map.split(',',1)]"/>
															</div>
														</div>
														<div  v-if="props.row.order_goods_info != null">
															<div class="namegood">
																<span v-if="props.row.order_goods_info.order_ganre=='3'">整点秒杀</span>
																<span v-if="props.row.order_goods_info.order_ganre=='4'">幸运团购</span>
																<span v-if="props.row.order_goods_info.order_ganre=='5'">视频号</span>
																<span v-if="props.row.order_goods_info.coupon_price">裂变优惠券</span>
																{{props.row.order_goods_info.goods_name }}</div>
															<div class="pricegood">
																￥{{props.row.order_goods_info.goods_price }}
																<span
																	style="margin-left: 50px;">数量：{{props.row.order_goods_info.goods_count}}</span>
															</div>
														</div>
													</div>
												</td>
												<td style="width: 8%;text-align: center">
													<!-- <p v-if="props.row.goods_source == 1">
														<span v-if="props.row.third_party_type == 1">库宝宝</span>
														<span v-else-if="props.row.third_party_type == -1">平台</span>
														<span v-else-if="props.row.third_party_type == 3">京东</span>
														<span v-else-if="props.row.third_party_type == 4">阿里</span>
														<span v-else-if="props.row.third_party_type == 5">天猫</span>
														<span v-else-if="props.row.third_party_type == 6">云仓</span>
														<span v-else-if="props.row.third_party_type == 7">苏宁</span>
													</p>
													<p v-else-if="props.row.goods_source==2 ">商家自营</p> -->
													<p >{{props.row.get_shop_info.shop_name}}</p>
													<!-- <p v-if="props.row.third_party_type ==1">库宝宝</p>
													<p v-else-if="props.row.third_party_type ==1">库宝宝</p>
													<p v-else-if="props.row.third_party_type ==-1">
														<span v-if="props.row.goods_source ==1">平台</span>
														<span v-else>店铺</span>
													</p> -->
												</td>
												<td style="width: 8%;padding-left:15px">
													<p>应付：{{props.row.total_money}}</p>
													<p>运费：{{props.row.freight}}</p>
													<p style="color: #F56C6C;" >实付：{{props.row.status}}{{props.row.actual_payment}}</p>

												</td>
												<td style="width: 8%;text-align: center">
													<p>{{props.row.consignee_name}}</p>
													<p>{{props.row.consignee_phone}}</p>
												</td>
												<td style="width:8%;text-align: center">
													<span v-if="props.row.payment_type==0">未支付</span>
													<span v-else-if="props.row.payment_type==1">微信</span>
													<span v-else-if="props.row.payment_type==2">支付宝</span>
													<span v-else-if="props.row.payment_type==3">余额</span>
													<span v-else-if="props.row.payment_type==4">微信组合</span>
													<span v-else-if="props.row.payment_type==5">支付宝组合</span>
												</td>
												<td style="width: 8%;text-align: center">
													<span>{{props.row.refund_money}}</span>
												</td>
												<td style="width: 8%;text-align: center">
													<span>{{props.row.refund_freight}}</span>
												</td>
												<td style="width: 8%;text-align: center">
													<p v-if="props.row.oas_genre === 1">
														<span>退款</span>
													</p>
													<p v-if="props.row.oas_genre === 2">
														<span>退货退款</span>
													</p>
												</td>
												<td style="width: 8%;text-align: center">
													<p v-if="props.row.oas_status === 1">
														<span>审核中</span>
														<br />
														<el-tag size="small" style="cursor: pointer;margin-top:5px"
															v-if="props.row.oas_status === 1"
															@click="noPassAduit(props.row)">拒绝</el-tag>
														<el-tag size="small" style="cursor: pointer;margin-top:5px"
															v-if="props.row.oas_status === 1"
															@click="passAduit(props.row)">同意</el-tag>
													</p>
													<p v-if="props.row.oas_status === 2">
														<span>审核驳回</span>
													</p>
													<p v-if="props.row.oas_status === 3">
														<span>审核通过</span>
													</p>
													<p v-if="props.row.oas_status === 4">
														<span>待收货</span>
														<br />
														<el-tag size="small" style="cursor: pointer;margin-top:5px"
															@click="sureDeliveryOrder(props.row)">确认收货</el-tag>
														<el-tag size="small" style="cursor: pointer;"
															@click="logisticsQuery(props.row)">物流查询</el-tag>
													</p>
													<p v-if="props.row.oas_status === 5">
														<span>已收货</span>
														<br />
														<el-tag size="small" style="cursor: pointer;margin-top:5px"
															@click="sureRefund(props.row)">退款</el-tag>
													</p>
													<p v-if="props.row.oas_status === 6">
														<span>退款中</span>
														<br />
														<el-tag size="small" style="cursor: pointer;margin-top:5px"
															@click="sureToRefund(props.row)">确认退款</el-tag>
													</p>
													<p v-if="props.row.oas_status === 7">
														<span>完成</span>
													</p>
													<p v-if="props.row.oas_status === 8">
														<span>取消</span>
													</p>
													<p v-if="props.row.oas_status === 9">
														<span>关闭</span>
													</p>
												</td>
												<td style="width: 8%;text-align: center">
													<el-button type="text" size="small" @click="orderDetail(props.row)">
														查看详情</el-button>
												</td>
											</tr>
										</tbody>
									</table>
								</template>
							</el-table-column>
						</div>
						<el-table-column label="商品信息" min-width="250">
							<template slot-scope="scope" style="padding: 10px 0;">
								<div class="aaa">
									<span class="orderTime">{{scope.row.created_at}}</span>订单号：<span
										style="color: #1d8ce0;">{{scope.row.sale_order_number}}
									</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="所属商户" align="center">
						</el-table-column>
						<el-table-column label="实收金额" align="center">
						</el-table-column>
						<el-table-column label="买家" align="center">
						</el-table-column>
						<el-table-column label="支付方式" align="center">
						</el-table-column>
						<el-table-column label="退款金额" align="center">
						</el-table-column>
						<el-table-column label="退款运费" align="center">
						</el-table-column>
						<el-table-column label="售后类型" align="center">
						</el-table-column>
						<el-table-column label="售后状态" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<!--审核不通过-->
		<el-dialog title="添加备注" :visible.sync="refuseSaleAfterModel" width="25%">
			<el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="audit_leave_word">
			</el-input>
			<div class="submitBtn">
				<el-button size="small" @click="refuseSaleAfterModel=false">取消</el-button>
				<el-button size="small" type="primary" @click="refuseSaleAfterSubmit">提交</el-button>
			</div>
		</el-dialog>
		<!--确认退款-->
		<el-dialog title="同意退款售后" :visible.sync="agreeSaleAfterRefundModel" width="35%">
			<el-form v-model="agreeSaleAfterformSale" label-width="150px">
				<el-form-item label="售后处理:">同意售后申请</el-form-item>
				<el-form-item label="实际退款金额:">
					<el-input style="width: 300px !important;" onblur="if(value<=0)value=''"
						oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
						v-model="refund_money_sale" size="small"></el-input>
				</el-form-item>
				<el-form-item label="是否退运费:">
					<el-radio v-model="freightRadio" label="2">不退运费</el-radio>
					<el-radio v-model="freightRadio" label="1">退运费</el-radio>
				</el-form-item>
				<p style="color: #409EFF;padding-left: 30px;">
					此订单将执行订单原路退款并且关闭当前售后单，请确认并填写退款金额（不能大于订单实付款）
				</p>
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="agreeSaleAfterRefundModel=false">取消</el-button>
				<el-button size="small" type="primary" @click="agreeSaleAfterRefundSubmit">提交</el-button>
			</div>
		</el-dialog>
		<!--退款-->
		<el-dialog title="同意退款售后" :visible.sync="agreeSaleAfterModel" width="35%">
			<el-form v-model="agreeSaleAfterform" label-width="150px">
				<el-form-item label="售后处理">同意售后申请</el-form-item>
				<el-form-item label="实际退款金额">
					<el-input style="width: 300px;" disabled v-model="refund_money" size="small"></el-input>
				</el-form-item>
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="agreeSaleAfterModel=false">取消</el-button>
				<el-button size="small" type="primary" @click="agreeSaleAfterSubmit">提交</el-button>
			</div>
			<!-- <p style="color: #409EFF;padding-left: 30px;">
				此订单将执行订单原路退款并且关闭当前售后单，请确认并填写退款金额（不能大于订单实付款）
			</p> -->
		</el-dialog>
		<!--退货退款-->
		<el-dialog title="同意退货退款售后" :visible.sync="agreeSaleAfterAllModel" width="35%">
			<el-form v-model="agreeSaleAfterAllform" label-width="150px">
				<el-form-item label="售后处理：">同意退货退款申请</el-form-item>
				<el-form-item label="选择退货地址：">
					<el-select v-model="address_id" style="width: 300px;" size="small">
						<el-option v-for="(item,itemindex) in addressList" :key="itemindex"
							:value="item.shop_address_id"
							:label="item.province+item.city+item.district+item.full_address"></el-option>
					</el-select>
				</el-form-item>
				<!-- 	<el-form-item label="实际退款金额：">
					<el-input style="width: 300px;" v-model="refund_money" disabled size="small"></el-input>
				</el-form-item> -->
				<!-- <p style="color: #409EFF;padding-left: 30px;">
					此订单将执行订单原路退款并且关闭当前售后单，请确认并填写退款金额（不能大于订单实付款）
				</p> -->
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="agreeSaleAfterAllModel=false">取消</el-button>
				<el-button size="small" type="primary" @click="agreeSaleAfterAllSubmit">提交</el-button>
			</div>
		</el-dialog>
		<!--物流详情-->
		<el-dialog title="物流详情" :visible.sync="logisticsDetailModel" width="40%">
			<div v-if="activities.length>0">
				<div style="font-size: 16px;margin-bottom: 20px;color: #409EFF;">快递名称：{{typename}} 快递单号：{{typenumber}}</div>
				<el-timeline>
					<el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.time">
						{{activity.status}}
					</el-timeline-item>
				</el-timeline>
			</div>
			<div v-else style="height: 100px; display: flex;align-items: center;justify-content: center;">
				暂无快递信息！
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import {
		applyForList,
		refuseSaleAfter,
		passSaleAfter,
		getRefundAddress,
		ConfirmReceivingGoodsSaleAfter,
		refund,
		saleOrderNumberQuery
	} from '@/api/merchant/order.js'
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	export default {
		data() {
			return {
				loading:true,
				search: {
					third_party_type: '',
					oas_genre: '',
					begin_time: '',
					end_time: '',
					oas_status: '',
					order_ganre: '',
					goods_source: 2,
				},
				keyswards_type: '',
				audit_leave_word: '',
				agreeSaleAfterModel: false,
				agreeSaleAfterAllModel: false,
				agreeSaleAfterAllform: {},
				agreeSaleAfterform: {},
				agreeSaleAfterformSale: {},
				refund_money_sale: '',
				agreeSaleAfterRefundModel: false,
				due_dt: '',
				activeName: 'first',
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				tableData: [],
				transaction_time: '',
				keywards: '',
				refuseSaleAfterModel: false,
				order_after_sales_id: '',
				refund_money: '',
				shop_id: '',
				address_id: '',
				addressList: [],
				logisticsDetailModel: false,
				typename: '',
				typenumber:'',
				activities: [],
				order_number: '',
				freightRadio: '2',
				input_money: ''
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
			//查看售后列表
			getList() {
				let params = this.search;
				params.page = this.currentPage;
				params.perPage = this.pagesize;
				params = this.tools.filterParams(params);
				applyForList(params).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
				})
			},
			//搜索
			searchStatus(val) {
				this.search.oas_status = val;
				this.getList();
			},
			toSearch() {
				this.currentPage = 1;
				this.getList();
			},
			toReset() {
				this.currentPage = 1;
				this.search.order_ganre='';
				this.search.oas_status = '';
				this.search.oas_genre = '';
				this.search.third_party_type = '';
				this.search.begin_time = '';
				this.search.end_time = '';
				this.due_dt = '';
				this.search.goods_source = 2;
				this.getList();
			},
			searchType(val) {
				if (val == 0) {
					this.oas_genre = '0'
				}
				if (val == 1) {
					this.oas_genre = '1'
				}
				if (val == 2) {
					this.oas_genre = '2'
				}
				this.getList();
			},
			searchParty(val) {
				this.search.third_party_type = val;
				this.getList();
			},
			clearInput() {
				this.keyswards_type = '0'
				this.getList();
			},

			//时间选择
			changetimer(val) { //时间
				if (val) {
					this.search.begin_time = val[0] / 1000;
					this.search.end_time = val[1] / 1000;
				} else {
					this.search.begin_time = '';
					this.search.end_time = '';
				}
			},


			handleSizeChange(size) {
				this.pagesize = size;
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList();
			},
			//详情
			orderDetail(row) {
				this.$router.push({
					path: '/ordergoodadmin/selfaftersalesorder/selfaftersalesorderdetail',
					query: {
						id: row.order_after_sales_id,
					},
				})
			},
			noPassAduit(row) {
				this.audit_leave_word = '';
				this.refuseSaleAfterModel = !this.refuseSaleAfterModel;
				this.order_after_sales_id = row.order_after_sales_id;
				this.order_number = row.order_number;
			},
			//审核不通过
			refuseSaleAfterSubmit() {
				let data = {
					order_after_sales_id: this.order_after_sales_id,
					audit_leave_word: this.audit_leave_word
				}
				data = this.tools.filterParams(data);
				refuseSaleAfter(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.refuseSaleAfterModel = !this.refuseSaleAfterModel;
						this.getList();
					}
				})
			},
			//退款审核通过
			passAduit(row) {
				if (row.oas_genre === 1) {
					this.refund_money = row.apply_refund_money;
					this.order_after_sales_id = row.order_after_sales_id;
					this.shop_id = row.shop_id;
					this.input_money = ((row.actual_payment * 1) - (row.freight * 1)).toFixed(2);
					this.agreeSaleAfterModel = !this.agreeSaleAfterModel;
					this.order_number = row.order_number
				} else {
					this.refund_money = row.apply_refund_money;
					this.input_money = ((row.actual_payment * 1) - (row.freight * 1)).toFixed(2);
					this.shop_id = row.shop_id;
					this.order_after_sales_id = row.order_after_sales_id;
					this.agreeSaleAfterAllModel = !this.agreeSaleAfterAllModel;
					this.order_number = row.order_number
					this.getRefundAddressData();
				}
			},
			//售后地址
			getRefundAddressData() {
				let data = {
					shop_id: this.shop_id,
					order_number: this.order_number
				}
				getRefundAddress(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.addressList = res.data;
					}
				})
			},
			//同意退款
			agreeSaleAfterSubmit() {
				let data = {
					order_after_sales_id: this.order_after_sales_id,
					shop_id: this.shop_id,
				}
				data = this.tools.filterParams(data);
				passSaleAfter(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.getList();
						this.agreeSaleAfterModel = !this.agreeSaleAfterModel;
					}
				})
			},
			//同意退货退款
			agreeSaleAfterAllSubmit() {
				if(!this.shop_id){
					this.$message.error('请选择退货地址');
					return false
				}
				let data = {
					order_after_sales_id: this.order_after_sales_id,
					shop_id: this.shop_id,
					shop_address_id: this.address_id
				}
				data = this.tools.filterParams(data);
				passSaleAfter(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.getList();
						this.agreeSaleAfterAllModel = !this.agreeSaleAfterAllModel;
					}
				})
			},
			//确认收货
			sureDeliveryOrder(row) {
				let data = {
					order_after_sales_id: row.order_after_sales_id
				}
				ConfirmReceivingGoodsSaleAfter(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.getList();
					}
				})
			},
			sureToRefund(row) {
				this.refund_money_sale = ((row.actual_payment * 1) - (row.freight * 1)).toFixed(2) ;
				this.input_money = ((row.actual_payment * 1) - (row.freight * 1)).toFixed(2);
				this.freightRadio = row.freight * 1 > 0 ? '1' : '2';
				this.agreeSaleAfterRefundModel = !this.agreeSaleAfterRefundModel;
				this.order_after_sales_id = row.order_after_sales_id;
				this.refund_money = row.actual_payment
			},
			agreeSaleAfterRefundSubmit() {
				let data = {
					order_after_sales_id: this.order_after_sales_id,
					refund_money: this.refund_money_sale,
					is_freight: this.freightRadio
				}
				if (this.refund_money_sale * 1 > (this.input_money * 1).toFixed(2)) {
					this.$message.error("输入金额不能大于退款金额")
					return;
				}
				data = this.tools.filterParams(data);
				refund(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.getList();
						this.agreeSaleAfterRefundModel = !this.agreeSaleAfterRefundModel;
					}
				})
			},
			//确认退款
			sureRefund(row) {
				let data = {
					order_after_sales_id: row.order_after_sales_id,
					refund_money: row.refund_money
				}
				refund(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.getList();
					}
				})
			},
			logisticsQuery(row) {
				this.logisticsDetailModel = !this.logisticsDetailModel;
				let params = {
					sale_order_number: row.sale_order_number
				}
				saleOrderNumberQuery(params).then(res => {
					//物流信息
					this.activities = res.data.list;
					this.typename = res.data.typename;
					this.typenumber=res.data.number
				})
			},

		}

	}
</script>

<style scoped lang="scss">
	@import "@/assets/css/plat/aftersalelist.scss";

	.page {
		margin: 20px 0;
		float: right;
	}


	::v-deep .el-table__row .cell .el-table__expand-icon i {
		display: none;
	}

	::v-deep .el-table__expanded-cell {
		padding: 10px;
	}

	.tabstyle {
		background-color: #fff;
		font-size: 12px;
	}

	.ordernum {
		color: #FF0000
	}

	::v-deep .el-table__row .cell .el-table__expand-icon i {
		display: none;
	}

	.el-tabs__header {
		padding: 0px;
		position: relative;
		margin: 0 !important;
	}


	::v-deep .el-table__expanded-cell[class*=cell] {
		padding: 0px 0px;

	}

	.orderTime {
		margin-right: 10px;
		margin-left: -6px;
		background: #F2F2F6;
	}

	::v-deep .el-table__row {
		background: #F2F2F6;
		border-radius: 4px 4px 0px 0px;
	}

	::v-deep.el-tabs__header {
		margin: 0 0;
	}

	::v-deep.el-table th {
		background-color: #fff;

	}

	.namegood {}

	.pricegood {
		line-height: 30px;
		margin-top: 10px
	}

	.order-search {
		margin-top: 10px;
		margin-bottom: 10px;
		background: #fff;
		padding: 20px 20px;
	}

	::v-deep.el-table__row td {
		padding: 10px 0;
	}

	.submitBtn {
		margin-top: 30px;
		text-align: right;
	}

	::v-deep.el-table__expand-icon>.el-icon {
		display: none;
	}

	::v-deep.el-table .cell {
		overflow: visible !important;
	}

	.tableMain::v-deep.aaa {
		width: 500px;
	}
</style>
