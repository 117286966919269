import axios from '@/config/httpConfig'
import qs from  'qs'
//行业分类
export const industryList=(params)=> {
    // return axios('get','account/industry_list'+'?'+qs.stringify=(params)=>)
	return axios('get','account/industry_list',params)
}
//认证商户信息
export const companyAuthentication=(data)=> {
    return axios('post','account/company_authentication', data);
}
//获取商户认证详情
export const companyAuthenticationDetail=(params)=> {
	  return axios('get','account/company_authentication_detail', params)
}
//网店功能设置
export const StoreConfigSetupUp=(data)=> {
    return axios('post','storesConfig/StoreConfigSetupUp', data);
}
//网店功能详情
export const getStoreConfigSetup=(params)=> {    
	 return axios('get','storesConfig/getStoreConfigSetup', params)
}
//云仓设置
export const setNiuYunAuth=(data)=> {
	 return axios('post','/Thirdparty/setNiuYunAuth', data)
}
//云仓获取
export const getNiuYunAuth=(data)=> {
	 return axios('post','/Thirdparty/getNiuYunAuth', data)
}
//云仓修改    
export const niuYunAuthUp=(data)=> {
	 return axios('post','/Thirdparty/niuYunAuthUp', data)
}
//关闭云仓
export const niuYunAuthClose=(params)=> {
	  return axios('get','/Thirdparty/niuYunAuthClose', params)
}

//获取快递物流列表
export const getExpressInfoList=(data)=> {
    return axios('post','product/getExpressInfoList', data);
}
//添加新增-删除-禁用启用
export const ExpressUpdate=(data)=> {
    return axios('post','product/ExpressUpdate', data);
}
//添加新增
export const ExpressInsert=(data)=> {
    return axios('post','product/ExpressInsert', data);
}
//删除
export const Expressdelete=(data)=> {
    return axios('post','product/Expressdelete', data);
}
//地址列表
export const addressList=(data)=> {
    return axios('post','Address/address_list', data);
}
//添加地址
export const addressInsert=(data)=> {
    return axios('post','Address/address_insert', data);
}
//地址详情
export const addressFindInfo=(data)=> {
    return axios('post','Address/address_find_info', data);
}
//修改地址
export const addressUp=(data)=> {
    return axios('post','Address/address_up', data);
}
//删除地址
export const addressDel=(params)=> {
	  return axios('get','Address/address_del', params)
}
//更新默认地址
export const settingAddressDefault=(params)=> {
	  return axios('get','Address/setting_address_default', params)
}

//添加编辑商户默认店铺信息
export const operateStoreDefault=(data)=> {
    return axios('post','stores/operate_store_default', data);
}
//获取商户默认店铺信息详情
export const storeDefault=(params)=> {
	  return axios('get','stores/store_default', params)
}
//商品设置-admin
export const GoodsSetting=(data)=> {
    return axios('post','Goods/GoodsSetting', data);
}
//获取商品设置信息-admin
export const getGoodsSetting=(data)=> {
    return axios('post','Goods/getGoodsSetting', data);
}
//获取商户订单设置信息
export const getOrdersConfig=(params)=> {
	  return axios('get','Orders/get_orders_config', params)
}
//获取商户订单售后原因列表
export const getOrdersRefundReason=(params)=> {
	  return axios('get','Orders/get_orders_refund_reason', params)
}
//添加商户订单售后原因信息
export const addOrdersRefundReason=(data)=> {
	 return axios('post','Orders/add_orders_refund_reason', data)
}
//设置商户订单配置信息
export const setOrdersConfig=(data)=> {
	 return axios('post','Orders/set_orders_config', data)
}
//运费模板列表

export const getProductTemp=(params)=> {
	  return axios('get','product/get_product_temp', params)
}
//获取地址信息
export const getProvincesInfo=(data)=> {
    return axios('post','product/getProvincesInfo', data);
}
//修改运费模板
export const modifyProductTemp=(data)=> {
    return axios('post','product/modify_product_temp', data);
}
//短信模板列表
export const querySmsTemplateList=(params)=> {
	  return axios('get','Sms/querySmsTemplateList', params)
}
//添加短信模板
export const AddSmsTemplate=(data)=> {
    return axios('post','Sms/AddSmsTemplate', data);
}
//模板标识
export const templateIdentifying=(params)=> {
	  return axios('get','Sms/templateIdentifying', params)
}
//模板详情
export const querySmsTemplate=(data)=> {
    return axios('post','Sms/querySmsTemplate', data);
}
//修改模板
export const modifySmsTemplate=(data)=> {
    return axios('post','Sms/modifySmsTemplate', data);
}
//短信模板删除
export const deleteSmsTemplate=(params)=> {
	  return axios('get','Sms/deleteSmsTemplate', params)
}

//短信签名列表
export const querySmsList=(params)=> {
     return axios('get','Sms/querySmsList', params)
}
//删除签名
export const deleteSmsSign=(data)=> {
    return axios('post','Sms/deleteSmsSign', data);
}

//微信支付配置
export const setWxConfig=(data)=> {
    return axios('post','/Thirdparty/Wx/setWxConfig', data);
}
//微信支付配置获取
export const getWxConfig=(params)=> {
	  return axios('get','/Thirdparty/Wx/getWxConfig', params)
}
//消息发送日志
export const smsLogList=(data)=> {
    return axios('post','Sms/smsLogList', data);
}
//添加签名
export const addSmsSign=(data)=> {
    return axios('post','Sms/addSmsSign', data);
}
//获取签名
export const querySmsSigns=(data)=> {
    return axios('post','Sms/querySmsSigns', data);
}
//修改签名
export const modifySmsSign=(data)=> {
    return axios('post','Sms/modifySmsSign', data);
}
//阿里接口配置获取
export const getALiConfig=(params)=> {
	  return axios('get','/Thirdparty/ALi/getALiConfig', params)
}
//阿里接口配置设置
export const setALiConfig=(data)=> {
    return axios('post','/Thirdparty/ALi/setALiConfig', data);
}
//编辑用户模版

export const productTempInfo=(params)=> {
	  return axios('get','product/product_temp_info', params)
}

//设置默认使用签名状态修改
export const signDefaultEmploy=(data)=> {
    return axios('post','Sms/signDefaultEmploy', data);
}

// 新增运费模板
export const create_product_temp=(data)=> {
  return axios('post','product/create_product_temp', data);
}

//删除运费模板
export const delete_product_temp=(params)=> {
  return axios('get','product/delete_product_temp', params)
}

//角色列表
export const roleAccountList=(params)=> {
	  return axios('get','Permission/roleAccountList', params)
}
//获取单个角色信息
export const authoritysettingDetail=(data)=> {
  return axios('post','Permission/RoleToShow', data);
}

//获取角色信息
export const authoritysettingmenuList=(params)=> {
	  return axios('get','Permission/getRolePermission', params)
}
//角色添加
export const authoritysettingroleAdd=(data)=> {
  return axios('post','Permission/RoleToWrite', data);
}
//修改角色
export const authoritysettingModify=(data)=> {
  return axios('post','Permission/removeRolePermission', data);
}
//单个权限写入到权限池中
export const PermissionToWrite=(data)=> {
  return axios('post','Permission/PermissionToWrite', data);
}
//修改env文件
export const modifyEnv=(data)=> {
  return axios('post','storesConfig/modifyEnv', data);
}
//env文件信息获取
export const getModifyEnv=(params)=> {
	  return axios('get','storesConfig/getModifyEnv', params)
}
//设置分享背景图
export const GiftCycleCardBackageGroundSeeting=(data)=> {
  return axios('post','GiftPackage/GiftCycleCardBackageGroundSeeting', data);
}
//获取分享背景图
export const getGiftCycleCardBacSeetingInfo=(data)=> {
  return axios('post','GiftPackage/getGiftCycleCardBacSeetingInfo', data);
}
//获取金豆玩法
export const get_gold_beans_explain=(params)=> {
	  return axios('get','Marketing/get_gold_beans_explain', params)
}
//设置金豆玩法
export const set_gold_beans_explain=(data)=> {
  return axios('post','Marketing/set_gold_beans_explain', data);
}