<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt" style="padding: 20px;padding-bottom: 0;">
					<el-row :gutter="20">
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<el-button size="small" @click="toOperate('batch','',2)" v-has="'批量同意'" >批量同意</el-button>
								<el-button size="small" class="mr10" @click="toOperate('batch','',3)" v-has="'批量拒绝'" >批量拒绝</el-button>
							</div>
						</el-col>
						<el-col :span="12" :offset="6">
							<div class="grid-content bg-purple">
								<div class="all_search_list">
									<div class="demo-input-suffix">
										<el-select style="width: 120px !important;" size="small" @change="changeState"
											v-model="physical_review" class="inputWidth" placeholder="全部状态">
											<el-option label="全部" value=""></el-option>
											<el-option label="审核中" value="1"></el-option>
											<el-option label="拒绝" value="3"></el-option>
										</el-select>
									</div>
									<div class="demo-input-suffix">
										<el-input prefix-icon="el-icon-search" class="inputWidth" size="small" clearable
											placeholder="网店名称/手机号" @keyup.enter.native="toSearch" v-model="search">
										</el-input>
									</div>
									<div class="demo-input-suffix">
										<el-button type="primary" size="small" @click="toSearch">查询</el-button>
									</div>
									<div class="demo-input-suffix">
										<el-button size="small" @click="toReset">重置</el-button>
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<!--表格-->
				<div class="tableMain">
					<el-table :data="tableData" style="width: 100%" min-height="250" border v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55" align="center">
						</el-table-column>
						<el-table-column prop="shop_name" label="商户名称" align="center">
						</el-table-column>
						<el-table-column prop="account_shop_info.account" label="手机号" align="center">
						</el-table-column>
						<el-table-column prop="marketing_trade_info.trade_name" label="行业类目" align="center">
						</el-table-column>
						<el-table-column prop="account_channel_info.username" label="所属服务商" align="center">
						</el-table-column>
						<el-table-column prop="linetype" label="网店类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.is_entity == 1">线上门店</span>
								<span v-else>实体门店</span>
							</template>
						</el-table-column>
						<el-table-column prop="states" label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.physical_review == 1">审核中</span>
								<span v-else-if="scope.row.physical_review == 2">通过</span>
								<span v-else-if="scope.row.physical_review == 3">拒绝</span>
							</template>
						</el-table-column>
						<el-table-column prop="checked_reason" label="原因" align="center">
						</el-table-column>
						<el-table-column prop="physical_review_time" label="创建时间" align="center">
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="150" align="center">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="toDetail(scope.row)" v-has="' 查看 '" >查看</el-button>
								<el-button type="text" size="small"
									v-if="scope.row.physical_review && scope.row.physical_review == '1'"
									@click="toOperate('single',scope.row,2)"  v-has="'通过'" >通过</el-button>
								<el-button type="text" size="small"
									v-if="scope.row.physical_review && scope.row.physical_review == '1'"
									@click="toOperate('single',scope.row,3)" v-has="'拒绝'" >拒绝</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="pagecurrent" background hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>

		<!--拒绝原因弹框-->
		<el-dialog title="拒绝原因" :visible.sync="dialogVisible" width="25%" :before-close="handleClose">
			<div>
				<el-input type="textarea" placeholder="请输入内容" v-model="desc_txt" rows="4" maxlength="50"
					show-word-limit>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="toSubmitCheck()" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getAuditShopList,
		saveAuditShop
	} from "@/api/plat/business.js"

	export default {
		data() {
			return {
				loading:true,
				physical_review: '',
				search: '',
				tableData: [],
				dialogVisible: false,
				desc_txt: '',
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				multipleSelection: [],
				account_shop_id: ''
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let data = {
					physical_review: this.physical_review,
					page: this.currentPage,
					perPage: this.pagesize,
					search: this.search
				}
				data = this.tools.filterParams(data);
				getAuditShopList(data).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list

				})
			},
			//切换状态搜索
			changeState(val) {
				this.physical_review = val;
			},
			toSearch() {
				this.currentPage=1;
				this.getList()
			},
			toReset() {
				this.currentPage=1;
				this.physical_review = '';
				this.search = '';
				this.getList()
			},
			//审核操作
			//批量选择
			handleSelectionChange(val) {
				let ids = [];
				val.forEach(item => {
					ids.push(item.account_shop_id)
				})
				this.multipleSelection = ids;
			},
			toOperate(type, row, state) {
				if (type == 'batch' && state == 2) {
					if (this.multipleSelection.length <= 0) {
						this.$message.error("请选择商户");
						return;
					}
					this.account_shop_id = this.multipleSelection.toString();
					this.toCheck(2);
				}
				if (type == 'batch' && state == 3) {
					if (this.multipleSelection.length <= 0) {
						this.$message.error("请选择商户");
						return;
					}
					this.dialogVisible = !this.dialogVisible
					this.account_shop_id = this.multipleSelection.toString()
				}
				if (type == 'single' && state == 2) {
					this.account_shop_id = row.account_shop_id;
					this.toCheck(state);
				}
				if (type == 'single' && state == 3) {
					this.dialogVisible = !this.dialogVisible
					this.account_shop_id = row.account_shop_id
				}

			},
			toCheck(state) {
				let data = {
					account_shop_id: this.account_shop_id,
					physical_review: state,
					checked_reason: this.desc_txt,
				}
				data = this.tools.filterParams(data);
				saveAuditShop(data).then(res => {
					this.getList();
					if (state == 3) {
						this.dialogVisible = !this.dialogVisible
					}

				})
			},
			toSubmitCheck() {
				this.toCheck(3);
			},

			toDetail(row) {
				this.$router.push({
					path: "/clientadmin/siteaudit/siteauditdetail",
					query: {
						id: row.shop_id
					}
				})
			},
			handleClose(done) {
				this.dialogVisible = !this.dialogVisible
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
		}
	}
</script>

<style>
</style>
