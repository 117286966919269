import axios from '@/config/httpConfig'
import qs from  'qs'
//提交资质类目列表
export const getShopVideoCategroyList=(params)=> {
	  return axios('get','Video/platform/getShopVideoCategroyList', params)
}
//三级类目详情
export const getThirdCatDetail=(params)=> {
	  return axios('get','Video/platform/getThirdCatDetail', params)
}
//上传图片
export const uploadFile=(data)=> {
    return axios('post','Video/platform/uploadFile', data);
}
//类目资质提交
export const auditCategory=(params)=> {
	  return axios('get','Video/platform/auditCategory', params)
}
//申请的视频号店铺列表
export const videoShopList=(params)=> {
	  return axios('get','Video/platform/videoShopList', params)
}
//删除店铺
export const videoShopDel=(params)=> {
	  return axios('get','Video/platform/videoShopDel', params)
}
//审核店铺
export const videoShopAudit=(params)=> {
	  return axios('get','Video/platform/videoShopAudit', params)
}