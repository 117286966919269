<template>
	<div class="order">
		<div class="wrapper-main">
			<div class="model-content">
				<el-form :model="form" :rules="rules" ref="form" label-width="200px" class="demo-ruleForm">
					<div class="order-set">
						<el-form-item label="广告类型" prop="title">
							<el-input class="inputWidth" type="text" size="small" placeholder="邀请好友弹窗视频（16:9）"
								v-model="form.title"></el-input>
						</el-form-item>
						<el-form-item label="视频" prop="video_file">
							<div class="banners_blos" @click="uploadVideo" style="width: 148px;">
								<div v-if="form.video_file" class="video_bos" style="margin-right: 0!important;">
									<video v-if="form.video_file" :src="form.video_file" class="avatar video-avatar"
										controls="controls" ref="video_player">
										您的浏览器不支持视频播放
									</video>
								</div>
								<div class="plusImg" v-else>
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
						</el-form-item>
					</div>

				</el-form>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet('form')">提交</div>
		</div>
		<ChooseVideo v-if="uploadVideoFlag" @videoClose="videoCancel" @videoOk="videoOk"></ChooseVideo>
	</div>
</template>

<script>
	import ChooseVideo from "../../../../common/uploadVideos.vue";
	import {
		insert_ad,
		getAd
	} from '@/api/plat/adverty'
	export default {
		components: {
			ChooseVideo,
		},
		data() {
			return {
				form: {
					title: '',
					video_file: '',
					video_poster: ''
				},
				rules: {
					title: [{
						required: true,
						message: '请填写广告类型',
						trigger: 'blur'
					}],
					video_file: [{
						required: true,
						message: '请选择视频',
						trigger: 'change'
					}],
				},
				uploadVideoFlag: false, //是否展示视频弹窗
				advertising_id:''
			}
		},
		mounted() {
			this.getDetail()
		},
		methods: {
			getDetail(){
				let data = {
					// filename: this.form.video_file,
					type: 1,
					// title: this.form.title
				}
				// data=this.tools.filterParams(data);
				// data.filename=this.tools.filterImgPre(data.filename);
				getAd(data).then(res => {
					console.log("2222")
					this.form.title=res.data[0].title;
					this.form.video_file=res.data[0].filename;
					this.advertising_id=res.data[0].advertising_id
				})
			},
			// 选择视频
			uploadVideo() {
				this.uploadVideoFlag = !this.uploadVideoFlag;
			},
			//视频弹窗-取消
			videoCancel(val) {
				this.uploadVideoFlag = val;
			},
			//视频弹出-确认
			videoOk(val) {
				this.form.video_poster = val[0].video_image_path;
				this.form.video_file = val[0].resource_name;
				this.uploadVideoFlag = false;
			},
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data = {
							filename: this.form.video_file,
							type: 1,
							title: this.form.title
						}
						data.advertising_id=this.advertising_id;
						data.filename=this.tools.filterImgPre(data.filename);
						insert_ad(data).then(res => {
							if(this.advertising_id){
								this.$message.success("编辑成功！")
							}else{
								this.$message.success("添加成功！")
							}
						})
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.banners_blos {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;

		.video_bos {
			margin-right: 30px;
			width: 148px;
			height: 148px;

			>video {
				width: 100%;
				height: 100%;
				border-radius: 2px;
			}
		}
	}
</style>
