<template>
	<div>
		<div class="wrapper-cont" style="padding: 0;">
			<div class="shopmain">
				<!--头部-->
				<div class="wrapper-main pp20">
					<el-row type="flex" class="row-bg" justify="space-between" style="align-items: center;">
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<div class="flex">
									<img :src="head_portrait" class="head_portrait" />
									<div class="shop_title">牛店-淘货市场</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" style="text-align: right;">
							<div class="grid-content bg-purple">
								<el-input placeholder="请输入内容" size="small" class="search_input"
									suffix-icon="el-icon-search" v-model="infos.keyword"  @keyup.enter.native="searchGood" clearable>
									<template slot="append" class="searchbtns"> <span
											@click="searchGood">搜索</span></template>
								</el-input>
							</div>
						</el-col>
					</el-row>
					<!--搜索-->
					<div class="bot pt20 pl20">
						<div class="default" :class="[sortArr == 1 && 'active']" @click="searchGoods(1)">默认</div>
						<div class="default" :class="[sortArr == 2 && 'active']" @click="searchGoods(2)">最新</div>
						<div class="tools sales" :class="[sortArr == 3 && 'active']" @click="searchGoods(3)">销量
							<img
								:src="sortSaleDesc ? require('../../../../../assets/img/shopping/bots.png') : require('../../../../../assets/img/shopping/ups.png') ">
						</div>
						<div class="tools" :class="[sortArr == 4 && 'active']" @click="searchGoods(4)">价格
							<img
								:src="sortPriceDesc ? require('../../../../../assets/img/shopping/bots.png') : require('../../../../../assets/img/shopping/ups.png') ">
						</div>
						<div class="prices">
							<input type="text" placeholder="¥ 最低价" v-model="infos.floor_price" />
							<div class="lines"></div>
							<input type="text" placeholder="¥ 最高价" v-model="infos.ceiling_price" />
							<div class="price_btn" @click="getPriceList" v-if="infos.floor_price && infos.ceiling_price">确定</div>
						</div>
					</div>
				</div>
				<!--商品-->
				<div class="good_cons mt10">
					
						<div v-for="(item,index) in recommends" :key="index" class="cons">
							<img :src="item.goods_main_pic.split(',')[0]" class="con_img" @click="toDet(item)"
							 v-if="item.goods_main_pic" />
							<div class="con_text">
								<div class="con_blos" v-if="item.is_put == 0"></div>
								<div class="title rich-omit">{{item.goods_name}}</div>
								<div class="clearfix">
									<div class="sales" style="float: left;">已售 {{item.sale}}件</div>
									<div style="float: right;" v-if="item.is_put == 1">
										<el-tag type="info" size="small">已入库</el-tag>
									</div>
								</div>
								<div class="ords"  v-if="item.is_put == 0" @click="addOrds(item)">放入仓库</div>
							</div>
						</div>
					
					
				
				</div>
				<!-- 分页 -->
				<div class="pagecurrent" v-if="recommends.length>0">
					<div class="pagination-container">
						<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			
			</div>
		</div>
	</div>
</template>

<script>
	import {getAdminGoodsPageList,StoreManagerSpreadGoodsTransfer} from "@/api/merchant/shopping.js"
	export default {
		data() {
			return {
				head_portrait: localStorage.getItem("head_portrait"),
				sortArr: 1, //筛选
				sortSaleDesc: false, //销售排序
				sortPriceDesc: false, //价格排序
				recommends:[],
				category_id:'',
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				infos:{
					category_id:'',
					activity_id:'',
					keyword: '',
					new:'0',
					sale:'2',
					price:'2',
					min_price:'',
					max_price:'',
					goods_id:''
				}
			}
		},
		mounted() {
			this.infos.goods_id=localStorage.getItem("pc_click_content");
			if(this.infos.goods_id){
				this.getList()
			}
			if(this.$route.query.keyword){
				this.infos.keyword = this.$route.query.keyword
				this.getList()
			}
			if(this.$route.query.category_id){
				this.infos.category_id = this.$route.query.category_id
				this.getList()
			}
			if(this.$route.query.activity_id){
				this.infos.activity_id = this.$route.query.activity_id
				this.getList()
			}
		},
		destroyed() {
			localStorage.removeItem("pc_click_content")
		},
		methods: {
			getList(){
				let params=this.infos;
				params.page=this.currentPage;
				params.perPage=this.pagesize;
				params = this.tools.filterParams(params)
				getAdminGoodsPageList(params).then(res=>{
					this.recommends=res.data.list;
					this.total=res.data.count;
				})
			},
			getPriceList(){
				this.getList()
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 调接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 调接口
				 this.getList()
			},
			searchGood() {
				 this.getList()
			},
			searchGoods(type) {
				this.sortArr = type;
				let infos = this.infos;
				if(type == 1){
					infos.new='0';
					infos.sale='0';
					infos.price='0';
				}else if(type == 2){
					infos.new = '1';
					infos.sale='0';
					infos.price='0';
				}else if (type == 3) {
					infos.new='0';
					infos.price='0';
					this.sortSaleDesc = !this.sortSaleDesc;
					infos.sale = !this.sortSaleDesc ? '2' : '1';
				} else if (type == 4) {
					infos.new = '0';
					infos.sale='0';
					this.sortPriceDesc = !this.sortPriceDesc;
					infos.price = !this.sortPriceDesc ? '2' : '1';
				}
				this.getList()
				
			},

			// 去商品详情
			toDet(item) {
				this.$router.push({
					path: '/shoppingadmin/detailofgood',
					query: {
						id: item.original_goods_unified_id
					}
				})
			},
			//上架至店铺
			addOrds(item){
				let obj={},arr=[];
				obj[item.primeval_goods_id] = item.original_goods_unified_id;
				arr.push(obj)
				let data={
					goodsInfo:JSON.stringify(arr),
					is_publish:'2'
				}
				StoreManagerSpreadGoodsTransfer(data).then(res=>{
					this.getList()
				})
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	@import "../../../../../assets/css/shopping/shopping.scss";
.pagination-container ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #D01717;
    color: #FFF;
}
	
</style>
