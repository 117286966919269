<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont">
					<div class="data-detail">
						客户名称：
					</div>
					<div class="listdata">
						<el-tabs  v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="客户详细信息" name="first">
								<el-table border :data="tableData" style="width: 100%" min-height="250">
									<el-table-column fixed prop="username" label="客户姓名" align="center">
									</el-table-column>
									<el-table-column prop="phone" label="联系电话" align="center">
									</el-table-column>
									<el-table-column prop="sex" label="性别" align="center">
										<template slot-scope="scope">
											<span v-if="scope.row.sex == 1">男</span>
											<span v-else-if="scope.row.sex == 2">女</span>
											<span v-else>未知</span>
										</template>
									</el-table-column>
									<el-table-column  label="来源" align="center">
										<template slot-scope="scope">
											<span v-if="scope.row.customer_source == 1">平台创建</span>
											<span v-else-if="scope.row.customer_source == 2">网上注册</span>
											<span v-else>服务商创建</span>
										</template>
									</el-table-column>
									<el-table-column prop="shop_county" label="区域" align="center">
									</el-table-column>
									<el-table-column prop="shop_province" label="省"  align="center">
									</el-table-column>
									<el-table-column prop="shop_city" label="市"  align="center">
									</el-table-column>
								</el-table>
								<!-- 分页 -->
								<div class="pagecurrent">
									<div class="pagination-container">
										<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
											:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
											layout="total, sizes, prev, pager, next, jumper" :total="total">
										</el-pagination>
									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="处理过程" name="second">
								<el-table border :data="disposeData" style="width: 100%" min-height="250">
									<el-table-column fixed prop="type" label="处理类别"  align="center">
										<template slot-scope="scope">
											<span v-if="scope.row.customer_source == 1">平台创建</span>
											<span v-else-if="scope.row.customer_source == 2">网上注册</span>
											<span v-else>服务商创建</span>
										</template>
									</el-table-column>
									<el-table-column prop="processing_time" label="处理时间"  align="center">
									</el-table-column>
									<el-table-column  label="处理人员"  align="center">
										<template slot-scope="scope">
											<span>服务商</span>
										</template>
									</el-table-column>
									<el-table-column prop="follow_remark" label="处理内容"  align="center">
									</el-table-column>
								</el-table>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
		import {businessIncubatorLinkmanList,followRemark}from '@/api/plat/business'
	export default {
		data() {
			return {
				activeName: 'first',
				tableData:[],
				disposeData:[],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				business_incubator_id:this.$route.query.id
			}
		},
		mounted() {
			if(this.business_incubator_id){
				this.businessList();
			}
		},
		methods: {
			//客户详细信息
			businessList(){
				let data={
					business_incubator_id:this.business_incubator_id,
					page:this.currentPage,
					perPage:this.pagesize
				}
				businessIncubatorLinkmanList(data).then(res=>{
					this.total=res.data.count;
					this.tableData=res.data.list;
					
				}).catch(err=>{
					 this.$message.error(err.return_msg)
					})
			},
			//处理过程
			getInfo(){
				let data={
					business_incubator_id:this.business_incubator_id
				}
				followRemark(data).then(res=>{
					let arr=[];
					arr.push(res.data);
					this.disposeData=arr;
				}).catch(err=>{
					 this.$message.error(err.return_msg)
					})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.businessList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.businessList()
			},
			handleClick(tab, event) {
				if(tab.name=='second'){
					this.getInfo()
				}else{
					this.businessList();
				}
				
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-detail {
		height: 50px;
		line-height: 50px;
		border-bottom: 1px solid #EEEEEE;
		padding: 0px 20px;
		font-size: 14px;
		color: #666;
	}

	.listdata {
		margin-top: 20px;
	}
</style>
