import axios from '@/config/httpConfig'
import qs from  'qs'
//获取订单列表
export const getShopVideoList=(params)=> {
	  return axios('get','Video/shop/getShopVideoList', params)
}
//获取商品分类列表
export const getCategroyTree=(params)=> {
	  return axios('get','Video/shop/getCategroyTree', params)
}
//添加商品列表
export const getShopCategoryList=(params)=> {
	  return axios('get','Video/shop/getShopCategoryList', params)
}
//上传商品到微信中台
export const addGoods=(params)=> {
	  return axios('get','Video/shop/addGoods', params)
}
//删除商品
export const delGoods=(params)=> {
	  return axios('get','Video/shop/delGoods', params)
}
//下架商品
export const spuDelisting=(params)=> {
	  return axios('get','Video/shop/spuDelisting', params)
}
//更新商品
export const updateWithoutAudit=(params)=> {
	  return axios('get','Video/shop/updateWithoutAudit', params)
}