import axios from '@/config/httpConfig'
import qs from  'qs'
//平台活动列表
export const getActivityList=(params)=> {
	  return axios('get','Activity/platform/getActivityList', params)
}
//平台活动开启
export const onActivity=(data)=> {
    return axios('post','Activity/platform/onActivity', data);
}
//平台活动关闭
export const offActivity=(data)=> {
    return axios('post','Activity/platform/offActivity', data);
}
//添加活动
export const addActivity=(data)=> {
    return axios('post','Activity/platform/addActivity', data);
}
//删除活动
export const delActivity=(data)=> {
    return axios('post','Activity/platform/delActivity', data);
}

//活动详情
export const getActivityInfo=(params)=> {
	  return axios('get','Activity/platform/getActivityInfo', params)
}
//活动编辑
export const editActivity=(data)=> {
    return axios('post','Activity/platform/editActivity', data);
}
//活动商品
export const getActivityGoodsPageList=(params)=> {
	 return axios('get','Activity/platform/getActivityGoodsPageList', params)
}
//平台活动商品添加
export const addActivityGoods=(data)=> {
    return axios('post','Activity/platform/addActivityGoods', data);
}
//平台活动商品设置活动价
export const modifyActivityPrice=(data)=> {
    return axios('post','Activity/platform/modifyActivityPrice', data);
}
//平台活动商品设置库存
export const modifyActivityStock=(data)=> {
    return axios('post','Activity/platform/modifyActivityStock', data);
}
//设置为主推
export const setMainPush=(data)=> {
    return axios('post','Activity/platform/setMainPush', data);
}
//平台活动商品批量删除
export const delActivityGoods=(data)=> {
    return axios('post','Activity/platform/delActivityGoods', data);
}

//平台Banner列表
export const getBannerList=(params)=> {
	 return axios('get','Activity/platform/getBannerList', params)
}
//平台Banner开启
export const onBanner=(data)=> {
    return axios('post','Activity/platform/onBanner', data);
}
//平台Banner停止
export const offBanner=(data)=> {
    return axios('post','Activity/platform/offBanner', data);
}
//删除轮播
export const delBanner=(data)=> {
    return axios('post','Activity/platform/delBanner', data);
}
//添加banner
export const addBanner=(data)=> {
    return axios('post','Activity/platform/addBanner', data);
}
//banner详情
export const getBannerInfo=(params)=> {
    return axios('get','Activity/platform/getBannerInfo', params);
}
//banner编辑
export const editBanner=(data)=> {
    return axios('post','Activity/platform/editBanner', data);
}

//公告添加
export const addNotice=(data)=> {
    return axios('post','Activity/platform/addNotice', data);
}
//公告详情
export const getNoticeInfo=(params)=> {
    return axios('get','Activity/platform/getNoticeInfo', params);
}
//编辑公告
export const editNotice=(data)=> {
    return axios('post','Activity/platform/editNotice', data);
}

//素材圈列表
export const getMaterialPageList=(params)=> {
    return axios('get','Material/platform/getMaterialPageList', params);
}
//行业类目
export const getMarketingTradeList=(params)=> {
    return axios('get','Marketing/platform/getTradeList', params);
}
//行业类目新
export const getShopTradeList=(params)=> {
    return axios('get','Marketing/platform/getShopTradeList', params);
}
// //行业类目启用
// export const getTradeList=(params)=> {
//     return axios('get','Marketing/platform/getTradeList', params);
// }


//创建素材圈
export const addMaterial=(data)=> {
    return axios('post','Material/platform/addMaterial', data);
}
//素材详情
export const getMaterialInfo=(params)=> {
    return axios('get','Material/platform/getMaterialInfo',  params);
}
//平台素材编辑
export const editMaterial=(data)=> {
    return axios('post','Material/platform/editMaterial', data);
}
//平台素材禁用
export const offMaterial=(data)=> {
    return axios('post','Material/platform/offMaterial', data);
}
//平台素材启用
export const onMaterial=(data)=> {
    return axios('post','Material/platform/onMaterial', data);
}
//平台素材删除
export const delMaterial=(data)=> {
    return axios('post','Material/platform/delMaterial', data);
}
//平台营销行业分页
export const getMarketingTradePageList=(params)=> {
    return axios('get','Marketing/platform/getMarketingTradePageList',  params);
}
//营销行业启用
export const onTrade=(data)=> {
    return axios('post','Marketing/platform/onTrade', data);
}
//营销行业禁用
export const offTrade=(data)=> {
    return axios('post','Marketing/platform/offTrade', data);
}
//营销行业添加
export const addMarketingTrade=(data)=> {
    return axios('post','Marketing/platform/addMarketingTrade', data);
}
//营销行业详情
export const getMarketingTradeInfo=(params)=> {
    return axios('get','Marketing/platform/getMarketingTradeInfo',  params);
}
//营销行业编辑
export const editMarketingTrade=(data)=> {
    return axios('post','Marketing/platform/editMarketingTrade', data);
}
//营销行业删除
export const delMarketingTrade=(data)=> {
    return axios('post','Marketing/platform/delTrade', data);
}
//插件列表
export const getPluginList=(params)=> {
    return axios('get','Marketing/platform/getPluginList',  params);
}
//营销插件列表
export const twitterList=(params)=> {
    return axios('get','Twiker/platform/twitterList',  params);
}
//修改营销插件是否上架
export const twitterUpState=(params)=> {
    return axios('get','Twiker/platform/twitterUpState',  params);
}
//营销插件素材列表
export const materialList=(params)=> {
    return axios('get','Twiker/platform/materialList',  params);
}
//添加营销插件素材
export const materialInsert=(data)=> {
    return axios('post','Twiker/platform/materialInsert', data);
}
//编辑营销插件素材
export const materialEdit=(data)=> {
    return axios('post','Twiker/platform/materialEdit', data);
}
//删除营销插件素材
export const materialDel=(params)=> {
    return axios('get','Twiker/platform/materialDel',  params);
}
//营销插件素材详情
export const materialDetails=(params)=> {
    return axios('get','Twiker/platform/materialDetails',  params);
}
//营销插件详情
export const twitterDetails=(params)=> {
    return axios('get','Twiker/platform/twitterDetails',  params);
}
//标签列表
export const tagList=(params)=> {
    return axios('get','Twiker/platform/tagList',  params);
}
//分组列表
export const packetList=(params)=> {
    return axios('get','Twiker/platform/packetList',  params);
}
//编辑营销插件
export const twitterUpdate=(data)=> {
    return axios('post','Twiker/platform/twitterUpdate', data);
}
//添加标签
export const tagInsert=(data)=> {
    return axios('post','Twiker/platform/tagInsert', data);
}
//标签删除
export const tagDel=(params)=> {
    return axios('get','Twiker/platform/tagDel',  params);
}
//添加分组
export const packetInsert=(data)=> {
    return axios('post','Twiker/platform/packetInsert', data);
}
//分组删除
export const packetDel=(params)=> {
    return axios('get','Twiker/platform/packetDel',  params);
}
//获取样板店关联关系
export const getComposingMarketingShop=(params)=> {
    return axios('get','/Fitment/platform/getComposingMarketingShop',  params);
}
//添加样板店关联关系
export const saveComposingMarketingShop=(data)=> {
    return axios('post','/Fitment/platform/saveComposingMarketingShop', data);
}