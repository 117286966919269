<template>
	<div>
		<div class="wrapper-cont pp20">
			<div class="timeStyle">基础设置</div>
			<div class="level_title">
				<label class="pla">店长等级：</label>
				<el-radio v-model="shop_unique_identification" disabled label="general_manager">等级一</el-radio>
				<el-radio v-model="shop_unique_identification" label="senior_manager">等级二</el-radio>
			</div>
		</div>
		<el-form v-if="shop_unique_identification == 'general_manager'" :model="commonform" :rules="commonrules"
			ref="commonform" label-width="150px" class="demo-ruleForm">
			<div class="wrapper-cont pd20 mt10">
				<div class="timeStyle">普通店长</div>
				<el-form-item label="名称自定义：" prop="manager_name">
					<el-input class="inputWidth" size="small" v-model="commonform.manager_name" maxlength="4"
						show-word-limit>
					</el-input>
					<p class="pstyle">默认为普通店长</p>
				</el-form-item>
				<el-form-item label="店长入驻开通：">
					<div class="checkboxs">
						<el-checkbox v-for="(item,index) in commonform.manager_price" @change="changecheck(item)"
							v-model="item.status" :key="index">
							<div v-for="(inx,indexp) in item.openList" :key="indexp" style="margin-bottom:10px">
								<el-input v-model="inx.figure" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150" style="vertical-align:middle;">
									<template slot="append">元</template>
								</el-input>
								<span v-if="inx.name.includes('新用户')">{{inx.name}}</span>
								<span v-else>{{inx.name}}店铺</span>
							</div>
						</el-checkbox>
					</div>
				</el-form-item>
				<el-form-item label="活动折扣：">
					<el-radio v-model="commonform.discount_activity_open" label="Y">是</el-radio>
					<el-radio v-model="commonform.discount_activity_open" label="N">否</el-radio>

					<div v-if="commonform.discount_activity_open == 'Y'">
						<div>折扣有效期：<el-date-picker size="small" v-model='commontime'
								:picker-options='pickerBeginDateBefore' @change='changeCommonTimer' type='daterange'
								start-placeholder='开始日期' end-placeholder='结束日期'>
							</el-date-picker>
						</div>
						<div class="discount mb10" v-for="(dis,disindex) in commonform.discount_activity"
							:key="disindex">
							<div>
								购买店铺享受<el-input v-model="dis.discount" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
									size="small" class="w150 mrl" style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>折扣，前<el-input v-model="dis.people_number" :min="0"
									oninput="value=value.replace(/[^\d.]/g,'')" size="small" class="w150 mrl"
									style="vertical-align:middle;">
									<template slot="append">名</template>
								</el-input>购买可享受

							</div>
							<div class="del" @click="closeDiscount(disindex,'onecommon')">移除</div>
						</div>
						<div class="mt20 flex" v-if="commonform.discount_activity.length !== 3">
							<el-button icon="el-icon-plus" type="primary" size="small" plain
								@click="addDiscount('onecommon')">添加阶梯
							</el-button>
							<p class="pstyle ml0">阶梯购买设置最多只能够设置3个阶梯</p>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="店长采购价：">
					<div style="overflow: hidden;">
						<el-input v-model="commonform.manager_purchase_price" :min="0" :max="100"
							oninput="value=value.replace(/[^\d.]/g,'');if(value>1000)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
							size="small" class="w150 mrl" style="vertical-align:middle;float: left;">
							<template slot="append" style="height: 32px!important;">%</template>
						</el-input>
						<p class="pstyle" style="float: left;">设置百分点，采购价=成本价*（1+百分比）</p>
					</div>
				</el-form-item>
				<el-form-item label="店铺成交奖励：">
					<div class="awardList">
						<ul class="clearfix">
							<li>
								<div class="title">邀请人数</div>
								<div class="award fistunit">
									<el-input size="small" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
										v-model="commonform.awardList.less_award_user" class="w80 mrl">
										<template slot="prepend">≤</template>
									</el-input>
								</div>
							</li>
							<li>
								<div class="title">直推普通店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.less_direct_common">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">直推高级店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.less_direct_advanced">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推普通店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.less_indirect_common">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推高级店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.less_indirect_advanced">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">邀请人数</div>
								<div class="award fistunit">
									<el-input size="small" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
										v-model="commonform.awardList.large_award_user" class="w80" disabled>
										<template slot="prepend">></template>
									</el-input>
									<el-input size="small" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
										v-model="commonform.awardList.less_award_user" class="w80" disabled>
										<template slot="prepend" style="border-left:none">≤</template>
									</el-input>
								</div>
							</li>
							<li>
								<div class="title">直推普通店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.section_direct_common">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">直推高级店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.section_direct_advanced">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推普通店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.section_indirect_common">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推高级店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.section_indirect_advanced">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">邀请人数</div>
								<div class="award fistunit">
									<el-input size="small" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.large_award_user" class="w80 mrl">
										<template slot="prepend">></template>
									</el-input>
								</div>
							</li>
							<li>
								<div class="title">直推普通店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.large_direct_common">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">直推高级店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.large_direct_advanced">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推普通店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.large_indirect_common">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推高级店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.awardList.large_indirect_advanced">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
						</ul>
					</div>
				</el-form-item>
				<el-form-item label="商品出货奖励：">
					<div class="awardList" style="width:70%">
						<ul class="clearfix">
							<li style="width: 50%;">
								<div class="title">直推商品奖励</div>
							</li>
							<li style="width: 50%;">
								<div class="title">间推商品奖励</div>
							</li>
							<li class="goodword">
								<div class="title">普通店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.goodaward.goods_straight_ordinary">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li class="goodword">
								<div class="title">高级店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.goodaward.goods_straight_top">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li class="goodword">
								<div class="title">普通店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.goodaward.goods_push_ordinary">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li class="goodword">
								<div class="title">高级店长</div>
								<div class="award">
									<el-input class="w120" :min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
										v-model="commonform.goodaward.goods_push_senior">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
						</ul>
					</div>
				</el-form-item>
			</div>
		</el-form>
		<el-form v-if="shop_unique_identification == 'senior_manager'" :model="advancedform" :rules="advancedrules"
			ref="advancedform" label-width="150px" class="demo-ruleForm">
			<div class="wrapper-cont pd20 mt10">
				<div class="timeStyle">普通店长</div>
				<el-form-item label="名称自定义：">
					<el-input class="inputWidth" size="small" v-model="advancedform.levelone.manager_name" maxlength="4"
						show-word-limit>
					</el-input>
					<p class="pstyle">默认为普通店长</p>
				</el-form-item>
				<el-form-item label="店长入驻开通：">
					<div class="checkboxs">
						<el-checkbox v-for="(item,index) in advancedform.levelone.manager_price"
							@change="changecheck(item)" v-model="item.status" :key="index">
							<div v-for="(inx,indexp) in item.openList" :key="indexp" style="margin-bottom:10px">
								<el-input v-model="inx.figure" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150" style="vertical-align:middle;">
									<template slot="append">元</template>
								</el-input>
								<span v-if="inx.name.includes('新用户')">{{inx.name}}</span>
								<span v-else>{{inx.name}}店铺</span>
							</div>
						</el-checkbox>
					</div>
				</el-form-item>
				<el-form-item label="活动折扣：">
					<el-radio v-model="advancedform.levelone.discount_activity_open" label="Y">是</el-radio>
					<el-radio v-model="advancedform.levelone.discount_activity_open" label="N">否</el-radio>

					<div v-if="advancedform.levelone.discount_activity_open == 'Y'">
						<div>折扣有效期：<el-date-picker size="small" v-model='levelonetime'
								:picker-options='pickerBeginDateBefore1' @change='changeLeveloneTimer' type='daterange'
								start-placeholder='开始日期' end-placeholder='结束日期'>
							</el-date-picker>
						</div>
						<div class="discount mb10" v-for="(dis,disindex) in advancedform.levelone.discount_activity"
							:key="disindex">
							<div>
								购买店铺享受<el-input v-model="dis.discount" size="small" class="w150 mrl" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
									style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>折扣，前<el-input v-model="dis.people_number" size="small" class="w150 mrl"
									:min="0" oninput="value=value.replace(/[^\d.]/g,'')" style="vertical-align:middle;">
									<template slot="append">名</template>
								</el-input>购买可享受
							</div>
							<div class="del" @click="closeDiscount(disindex,'twocommon')">移除</div>
						</div>
						<div class="mt20 flex" v-if="advancedform.levelone.discount_activity.length !== 3">
							<el-button icon="el-icon-plus" type="primary" size="small" plain
								@click="addDiscount('twocommon')">添加阶梯
							</el-button>
							<p class="pstyle ml0">阶梯购买设置最多只能够设置3个阶梯</p>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="店长采购价：">
					<div style="overflow: hidden;">
						<el-input v-model="advancedform.levelone.manager_purchase_price" :min="0" :max="100"
							oninput="value=value.replace(/[^\d.]/g,'');if(value>1000)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
							size="small" class="w150 mrl" style="vertical-align:middle;float: left;">
							<template slot="append" style="height: 32px!important;">%</template>
						</el-input>
						<p class="pstyle" style="float: left;">设置百分点，采购价=成本价*（1+百分比）</p>
					</div>
				</el-form-item>
				<el-form-item label="店铺成交奖励：">
					<div class="awardList">
						<ul class="clearfix">
							<li>
								<div class="title">邀请人数</div>
								<div class="award fistunit">
									<el-input size="small" v-model="advancedform.levelone.awardList.less_award_user"
										:min="0" oninput="value=value.replace(/[^\d.]/g,'')" class="w80 mrl">
										<template slot="prepend">≤</template>
									</el-input>
								</div>
							</li>
							<li>
								<div class="title">直推普通店长</div>
								<div class="award">
									<el-input class="w120" v-model="advancedform.levelone.awardList.less_direct_common"
										:min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">直推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.awardList.less_direct_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推普通店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.awardList.less_indirect_common" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.awardList.less_indirect_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">邀请人数</div>
								<div class="award fistunit">
									<el-input size="small" v-model="advancedform.levelone.awardList.large_award_user"
										:min="0" oninput="value=value.replace(/[^\d.]/g,'')" class="w80" disabled>
										<template slot="prepend">></template>
									</el-input>
									<el-input size="small" v-model="advancedform.levelone.awardList.less_award_user"
										:min="0" oninput="value=value.replace(/[^\d.]/g,'')" class="w80" disabled>
										<template slot="prepend" style="border-left:none">≤</template>
									</el-input>
								</div>
							</li>
							<li>
								<div class="title">直推普通店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.awardList.section_direct_common" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">直推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.awardList.section_direct_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推普通店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.awardList.section_indirect_common" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.awardList.section_indirect_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">邀请人数</div>
								<div class="award fistunit">
									<el-input size="small" v-model="advancedform.levelone.awardList.large_award_user"
										oninput="value=value.replace(/[^\d.]/g,'')" :min="0" class="w80 mrl">
										<template slot="prepend">></template>
									</el-input>
								</div>
							</li>
							<li>
								<div class="title">直推普通店长</div>
								<div class="award">
									<el-input class="w120" v-model="advancedform.levelone.awardList.large_direct_common"
										:min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">直推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.awardList.large_direct_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推普通店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.awardList.large_indirect_common" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.awardList.large_indirect_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
						</ul>
					</div>
				</el-form-item>
				<el-form-item label="商品出货奖励：">
					<div class="awardList" style="width:70%">
						<ul class="clearfix">
							<li style="width: 50%;">
								<div class="title">直推商品奖励</div>
							</li>
							<li style="width: 50%;">
								<div class="title">间推商品奖励</div>
							</li>
							<li class="goodword">
								<div class="title">普通店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.levelone.goodaward.goods_straight_ordinary" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li class="goodword">
								<div class="title">高级店长</div>
								<div class="award">
									<el-input class="w120" v-model="advancedform.levelone.goodaward.goods_straight_top"
										:min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li class="goodword">
								<div class="title">普通店长</div>
								<div class="award">
									<el-input class="w120" v-model="advancedform.levelone.goodaward.goods_push_ordinary"
										:min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li class="goodword">
								<div class="title">高级店长</div>
								<div class="award">
									<el-input class="w120" v-model="advancedform.levelone.goodaward.goods_push_senior"
										:min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
						</ul>
					</div>
				</el-form-item>
			</div>
			<div class="wrapper-cont pd20 mt10">
				<div class="timeStyle">高级店长</div>
				<el-form-item label="名称自定义：">
					<el-input class="inputWidth" size="small" v-model="advancedform.leveltwo.manager_name" maxlength="4"
						show-word-limit>
					</el-input>
					<p class="pstyle">默认为普通店长</p>
				</el-form-item>
				<el-form-item label="店长入驻开通：">
					<div class="checkboxs">
						<el-checkbox v-for="(item,index) in advancedform.leveltwo.manager_price"
							@change="changecheck(item)" v-model="item.status" :key="index">
							<div v-for="(inx,indexp) in item.openList" :key="indexp" style="margin-bottom:10px">
								<el-input v-model="inx.figure" :min="0" oninput="value=value.replace(/[^\d.]/g,'')"
									size="small" class="w150" style="vertical-align:middle;">
									<template slot="append">元</template>
								</el-input>
								<span v-if="inx.name.includes('新用户')">{{inx.name}}</span>
								<span v-else>{{inx.name}}店铺</span>
							</div>
						</el-checkbox>
					</div>
				</el-form-item>
				<el-form-item label="活动折扣：">
					<el-radio v-model="advancedform.leveltwo.discount_activity_open" label="Y">是</el-radio>
					<el-radio v-model="advancedform.leveltwo.discount_activity_open" label="N">否</el-radio>
					<div v-if="advancedform.leveltwo.discount_activity_open == 'Y'">
						<div>折扣有效期：<el-date-picker size="small" v-model='leveltwotime'
								:picker-options='pickerBeginDateBefore2' @change='changeLeveltwoTimer' type='daterange'
								start-placeholder='开始日期' end-placeholder='结束日期'>
							</el-date-picker>
						</div>
						<div class="discount mb10" v-for="(dis,disindex) in advancedform.leveltwo.discount_activity"
							:key="disindex">
							<div>
								购买店铺享受<el-input v-model="dis.discount" size="small" class="w120 mrl" :min="0" :max="100"
									oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
									style="vertical-align:middle;">
									<template slot="append">%</template>
								</el-input>折扣，前<el-input v-model="dis.people_number" size="small" class="w120 mrl"
									:min="0" oninput="value=value.replace(/[^\d.]/g,'')" style="vertical-align:middle;">
									<template slot="append">名</template>
								</el-input>购买可享受

							</div>
							<div class="del" @click="closeDiscount(disindex,'twoadvanced')">移除</div>
						</div>
						<div class="mt20 flex" v-if="advancedform.leveltwo.discount_activity.length !== 3">
							<el-button icon="el-icon-plus" type="primary" size="small" plain
								@click="addDiscount('twoadvanced')">添加阶梯
							</el-button>
							<p class="pstyle ml0">阶梯购买设置最多只能够设置3个阶梯</p>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="店长采购价：">
					<div style="overflow: hidden;">
						<el-input v-model="advancedform.leveltwo.manager_purchase_price" :min="0" :max="100"
							oninput="value=value.replace(/[^\d.]/g,'');if(value>1000)value='0'" size="small"
							class="w150 mrl" style="vertical-align:middle;float: left;">
							<template slot="append" style="height: 32px!important;">%</template>
						</el-input>
						<p class="pstyle" style="float: left;">设置百分点，采购价=成本价*（1+百分比）</p>
					</div>
				</el-form-item>
				<el-form-item label="店铺成交奖励：">
					<div class="awardList">
						<ul class="clearfix">
							<li>
								<div class="title">邀请人数</div>
								<div class="award fistunit">
									<el-input size="small" v-model="advancedform.leveltwo.awardList.less_award_user"
										:min="0" oninput="value=value.replace(/[^\d.]/g,'')" class="w80 mrl">
										<template slot="prepend">≤</template>
									</el-input>
								</div>
							</li>
							<li>
								<div class="title">直推普通店长</div>
								<div class="award">
									<el-input class="w120" v-model="advancedform.leveltwo.awardList.less_direct_common"
										:min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">直推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.awardList.less_direct_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推普通店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.awardList.less_indirect_common" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.awardList.less_indirect_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">邀请人数</div>
								<div class="award fistunit">
									<el-input size="small" v-model="advancedform.leveltwo.awardList.less_award_user"
										:min="0" oninput="value=value.replace(/[^\d.]/g,'')" class="w80" disabled>
										<template slot="prepend">></template>
									</el-input>
									<el-input size="small" v-model="advancedform.leveltwo.awardList.large_award_user"
										:min="0" oninput="value=value.replace(/[^\d.]/g,'')" class="w80" disabled>
										<template slot="prepend" style="border-left:none">≤</template>
									</el-input>
								</div>
							</li>
							<li>
								<div class="title">直推普通店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.awardList.section_direct_common" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">直推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.awardList.section_direct_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推普通店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.awardList.section_indirect_common" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.awardList.section_indirect_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">邀请人数</div>
								<div class="award fistunit">
									<el-input size="small" v-model="advancedform.leveltwo.awardList.large_award_user"
										oninput="value=value.replace(/[^\d.]/g,'')" class="w80 mrl">
										<template slot="prepend">></template>
									</el-input>
								</div>
							</li>
							<li>
								<div class="title">直推普通店长</div>
								<div class="award">
									<el-input class="w120" v-model="advancedform.leveltwo.awardList.large_direct_common"
										:min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">直推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.awardList.large_direct_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推普通店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.awardList.large_indirect_common" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li>
								<div class="title">间推高级店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.awardList.large_indirect_advanced" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
						</ul>
					</div>
				</el-form-item>
				<el-form-item label="商品出货奖励：">
					<div class="awardList" style="width:70%">
						<ul class="clearfix">
							<li style="width: 50%;">
								<div class="title">直推商品奖励</div>
							</li>
							<li style="width: 50%;">
								<div class="title">间推商品奖励</div>
							</li>
							<li class="goodword">
								<div class="title">普通店长</div>
								<div class="award">
									<el-input class="w120"
										v-model="advancedform.leveltwo.goodaward.goods_straight_ordinary" :min="0"
										:max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li class="goodword">
								<div class="title">高级店长</div>
								<div class="award">
									<el-input class="w120" v-model="advancedform.leveltwo.goodaward.goods_straight_top"
										:min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li class="goodword">
								<div class="title">普通店长</div>
								<div class="award">
									<el-input class="w120" v-model="advancedform.leveltwo.goodaward.goods_push_ordinary"
										:min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
							<li class="goodword">
								<div class="title">高级店长</div>
								<div class="award">
									<el-input class="w120" v-model="advancedform.leveltwo.goodaward.goods_push_senior"
										:min="0" :max="100"
										oninput="value=value.replace(/[^\d.]/g,'');if(value>100)value='0';if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}">
									</el-input>
									<div class="unit">%</div>
								</div>
							</li>
						</ul>
					</div>
				</el-form-item>
			</div>
		</el-form>
		<div class="save-cont">
			<div class="save-btn"
				@click="submitSet(shop_unique_identification=='general_manager'?'commonform':'advancedform')">保存</div>
		</div>
	</div>
</template>

<script>
	import {
		managerFenRunSetting, //店长分润设置-全部提交
		managerFenRunList, //获取店长分润设置信息
	} from '@/api/plat/platprofit'
	export default {
		data() {
			return {
				commontime: [],
				levelonetime: [],
				leveltwotime: [],
				pickerBeginDateBefore: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7 //如果当天可选，就不用减8.64e7
					}
				},
				pickerBeginDateBefore1: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7 //如果当天可选，就不用减8.64e7
					}
				},
				pickerBeginDateBefore2: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7 //如果当天可选，就不用减8.64e7
					}
				},
				shop_unique_identification: 'general_manager',
				commonform: {
					manager_name: '',
					discount_start_time: '',
					discount_end_time: '',
					manager_price: [{
							status: false,
							openList: [{
								indate_time: "byear",
								figure: '',
								name: '1年'
							}]

						},
						{
							status: false,
							openList: [{
									indate_time: "djanuary",
									figure: '',
									name: '1个月'
								},
								{
									indate_time: "enew_january",
									figure: '',
									name: '1个月新用户享受'
								}
							]
						},
						{
							status: false,
							openList: [{
								indate_time: "cjune",
								figure: '',
								name: '6个月'
							}]

						},
						{
							status: false,
							openList: [{
								indate_time: "atwo_year",
								figure: '',
								name: '2年'
							}]
						},
					],
					discount_activity_open: 'Y',
					discount_activity: [{
						discount: '',
						people_number: '',
					}],
					manager_purchase_price: '',
					awardList: {
						less_award_user: '',
						less_direct_common: '',
						less_direct_advanced: '',
						less_indirect_common: '',
						less_indirect_advanced: '',
						sectionless_award_user: '',
						sectionlarge_award_user: '',
						section_direct_common: '',
						section_direct_advanced: '',
						section_indirect_common: '',
						section_indirect_advanced: '',
						large_award_user: '',
						large_direct_common: '',
						large_direct_advanced: '',
						large_indirect_common: '',
						large_indirect_advanced: '',
					},
					goodaward: {
						goods_straight_ordinary: '',
						goods_straight_top: '',
						goods_push_ordinary: '',
						goods_push_senior: ''
					}

				},
				commonrules: {
					manager_name: [{
						required: true,
						message: '请填写名称',
						trigger: 'blur'
					}]
				},
				advancedform: {
					levelone: {
						manager_name: '',
						manager_price: [{
								status: false,
								openList: [{
									indate_time: "byear",
									figure: '',
									name: '1年'
								}]

							},
							{
								status: false,
								openList: [{
										indate_time: "djanuary",
										figure: '',
										name: '1个月'
									},
									{
										indate_time: "enew_january",
										figure: '',
										name: '1个月新用户享受'
									}
								]
							},
							{
								status: false,
								openList: [{
									indate_time: "cjune",
									figure: '',
									name: '6个月'
								}]

							},
							{
								status: false,
								openList: [{
									indate_time: "atwo_year",
									figure: '',
									name: '2年'
								}]

							},
						],
						discount_activity_open: 'Y',
						discount_activity: [{
							discount: '',
							people_number: '',
						}],
						manager_purchase_price: '',
						awardList: {
							less_award_user: '',
							less_direct_common: '',
							less_direct_advanced: '',
							less_indirect_common: '',
							less_indirect_advanced: '',
							sectionless_award_user: '',
							sectionlarge_award_user: '',
							section_direct_common: '',
							section_direct_advanced: '',
							section_indirect_common: '',
							section_indirect_advanced: '',
							large_award_user: '',
							large_direct_common: '',
							large_direct_advanced: '',
							large_indirect_common: '',
							large_indirect_advanced: '',
						},
						goodaward: {
							goods_straight_ordinary: '',
							goods_straight_top: '',
							goods_push_ordinary: '',
							goods_push_senior: ''
						}

					},
					leveltwo: {
						manager_name: '',
						manager_price: [{
								status: false,
								openList: [{
									indate_time: "byear",
									figure: '',
									name: '1年'
								}]

							},
							{
								status: false,
								openList: [{
										indate_time: "djanuary",
										figure: '',
										name: '1个月'
									},
									{
										indate_time: "enew_january",
										figure: '',
										name: '1个月新用户享受'
									}
								]
							},
							{
								status: false,
								openList: [{
									indate_time: "cjune",
									figure: '',
									name: '6个月'
								}]

							},
							{
								status: false,
								openList: [{
									indate_time: "atwo_year",
									figure: '',
									name: '2年'
								}]

							},
						],
						discount_activity_open: 'Y',
						discount_activity: [{
							discount: '',
							people_number: '',
						}],
						manager_purchase_price: '',
						awardList: {
							less_award_user: '',
							less_direct_common: '',
							less_direct_advanced: '',
							less_indirect_common: '',
							less_indirect_advanced: '',
							sectionless_award_user: '',
							sectionlarge_award_user: '',
							section_direct_common: '',
							section_direct_advanced: '',
							section_indirect_common: '',
							section_indirect_advanced: '',
							large_award_user: '',
							large_direct_common: '',
							large_direct_advanced: '',
							large_indirect_common: '',
							large_indirect_advanced: '',
						},
						goodaward: {
							goods_straight_ordinary: '',
							goods_straight_top: '',
							goods_push_ordinary: '',
							goods_push_senior: ''
						}
					}


				},
				advancedrules: {},
				shop_share_profit_id: ''
			}

		},
		mounted() {

		},
		methods: {
			changecheck(item) {

			},
			//等级一 普通店长时间搜索
			changeCommonTimer(val) { //时间
				this.commonform.discount_start_time = val[0] / 1000;
				this.commonform.discount_end_time = val[1] / 1000;
			},
			//等级二 普通店长时间搜索
			changeLeveloneTimer(val) { //时间
				this.advancedform.levelone.discount_start_time = val[0] / 1000;
				this.advancedform.levelone.discount_end_time = val[1] / 1000;
			},
			//等级二 高级店长时间搜索
			changeLeveltwoTimer(val) { //时间
				this.advancedform.leveltwo.discount_start_time = val[0] / 1000;
				this.advancedform.leveltwo.discount_end_time = val[1] / 1000;
			},
			//获取设置信息
			getManagerFenRunList() {
				let data = {}
				managerFenRunList(data).then(res => {
					res.data.shopLevel.forEach(item => {
						if (item.status == 'Y') {
							this.shop_unique_identification = item.unique_identification
						}
					})
					res.data.shopShareProfit.forEach(shop => {
						shop.discount_activity.forEach(ac => {
							ac.discount = ac.discount * 100 * 1
						});
						shop.goods_shipment_reward.forEach(re => {
							re.goods_push_ordinary = parseFloat((re.goods_push_ordinary * 100)
								.toFixed(2));
							re.goods_push_senior = parseFloat((re.goods_push_senior * 100).toFixed(
								2));
							re.goods_straight_ordinary = parseFloat((re.goods_straight_ordinary *
								100).toFixed(2));
							re.goods_straight_top = parseFloat((re.goods_straight_top * 100)
								.toFixed(2));
						})
						if (shop.unique_identification == 'general_manager') {
							this.shop_share_profit_id = shop.shop_share_profit_id;
							//等级一 普通
							this.commonform.manager_name = shop.manager_name;
							this.commonform.manager_price = shop.manager_price;
							this.commonform.discount_activity_open = shop.discount_activity_open;

							if (shop.discount_start_time == '0' && shop.discount_end_time == '0') {
								this.commontime = '';
							} else {
								this.commontime = [this.tools.timeFormate(shop.discount_start_time * 1000),
									this.tools.timeFormate(shop.discount_end_time * 1000)
								];
							}
							this.commonform.discount_start_time = shop.discount_start_time;
							this.commonform.discount_end_time = shop.discount_end_time;
							this.commonform.discount_activity = shop.discount_activity;
							this.commonform.manager_purchase_price = parseFloat((shop
								.manager_purchase_price * 100).toFixed(2));

							this.commonform.awardList.less_award_user = shop.manager_bargain_award[0]
								.invite_number;
							this.commonform.awardList.less_direct_common = parseFloat((shop
								.manager_bargain_award[0].straight_ordinary * 100).toFixed(2));

							this.commonform.awardList.less_direct_advanced = parseFloat((shop
								.manager_bargain_award[0].straight_top * 100).toFixed(2));
							this.commonform.awardList.less_indirect_common = parseFloat((shop
								.manager_bargain_award[0].push_ordinary * 100).toFixed(2));
							this.commonform.awardList.less_indirect_advanced = parseFloat((shop
								.manager_bargain_award[0].push_senior * 100).toFixed(2));

							this.commonform.awardList.section_direct_common = parseFloat((shop
								.manager_bargain_award[1].straight_ordinary * 100).toFixed(2));
							this.commonform.awardList.section_direct_advanced = parseFloat((shop
								.manager_bargain_award[1].straight_top * 100).toFixed(2));
							this.commonform.awardList.section_indirect_common = parseFloat((shop
								.manager_bargain_award[1].push_ordinary * 100).toFixed(2));
							this.commonform.awardList.section_indirect_advanced = parseFloat((shop
								.manager_bargain_award[1].push_senior * 100).toFixed(2));

							this.commonform.awardList.large_award_user = shop.manager_bargain_award[2]
								.invite_number;
							this.commonform.awardList.large_direct_common = parseFloat((shop
								.manager_bargain_award[2].straight_ordinary * 100).toFixed(2));
							this.commonform.awardList.large_direct_advanced = parseFloat((shop
								.manager_bargain_award[2].straight_top * 100).toFixed(2));
							this.commonform.awardList.large_indirect_common = parseFloat((shop
								.manager_bargain_award[2].push_ordinary * 100).toFixed(2));
							this.commonform.awardList.large_indirect_advanced = parseFloat((shop
								.manager_bargain_award[2].push_senior * 100).toFixed(2));

							this.commonform.goodaward = shop.goods_shipment_reward[0];
							//等级二 普通
							this.advancedform.levelone.manager_name = shop.manager_name;
							this.advancedform.levelone.manager_price = shop.manager_price;
							this.advancedform.levelone.discount_activity_open = shop
							.discount_activity_open;
							if (shop.discount_start_time == '0' && shop.discount_end_time == '0') {
								this.levelonetime = '';
							} else {
								this.levelonetime = [this.tools.timeFormate(shop.discount_start_time *
									1000), this.tools.timeFormate(shop.discount_end_time * 1000)];
							}

							this.advancedform.levelone.discount_start_time = shop.discount_start_time;
							this.advancedform.levelone.discount_end_time = shop.discount_end_time;
							this.advancedform.levelone.discount_activity = shop.discount_activity;
							this.advancedform.levelone.manager_purchase_price = parseFloat((shop
								.manager_purchase_price * 100).toFixed(2));
							this.advancedform.levelone.awardList.less_award_user = shop
								.manager_bargain_award[0].invite_number;
							this.advancedform.levelone.awardList.less_direct_common = parseFloat((shop
								.manager_bargain_award[0].straight_ordinary * 100).toFixed(2));
							this.advancedform.levelone.awardList.less_direct_advanced = parseFloat((shop
								.manager_bargain_award[0].straight_top * 100).toFixed(2));
							this.advancedform.levelone.awardList.less_indirect_common = parseFloat((shop
								.manager_bargain_award[0].push_ordinary * 100).toFixed(2));
							this.advancedform.levelone.awardList.less_indirect_advanced = parseFloat((shop
								.manager_bargain_award[0].push_senior * 100).toFixed(2));

							this.advancedform.levelone.awardList.section_direct_common = parseFloat((shop
								.manager_bargain_award[1].straight_ordinary * 100).toFixed(2));
							this.advancedform.levelone.awardList.section_direct_advanced = parseFloat((shop
								.manager_bargain_award[1].straight_top * 100).toFixed(2));
							this.advancedform.levelone.awardList.section_indirect_common = parseFloat((shop
								.manager_bargain_award[1].push_ordinary * 100).toFixed(2));
							this.advancedform.levelone.awardList.section_indirect_advanced = parseFloat((
								shop.manager_bargain_award[1].push_senior * 100).toFixed(2));

							this.advancedform.levelone.awardList.large_award_user = shop
								.manager_bargain_award[2].invite_number;
							this.advancedform.levelone.awardList.large_direct_common = parseFloat((shop
								.manager_bargain_award[2].straight_ordinary * 100).toFixed(2));
							this.advancedform.levelone.awardList.large_direct_advanced = parseFloat((shop
								.manager_bargain_award[2].straight_top * 100).toFixed(2));
							this.advancedform.levelone.awardList.large_indirect_common = parseFloat((shop
								.manager_bargain_award[2].push_ordinary * 100).toFixed(2));
							this.advancedform.levelone.awardList.large_indirect_advanced = parseFloat((shop
								.manager_bargain_award[2].push_senior * 100).toFixed(2));

							this.advancedform.levelone.goodaward = shop.goods_shipment_reward[0]
						} else if (shop.unique_identification == 'senior_manager') {
							//等级二 高级
							this.shop_share_profit_id = shop.shop_share_profit_id;
							this.advancedform.leveltwo.manager_name = shop.manager_name;
							this.advancedform.leveltwo.manager_price = shop.manager_price;
							this.advancedform.leveltwo.discount_activity_open = shop
							.discount_activity_open;
							if (shop.discount_start_time == '0' && shop.discount_end_time == '0') {
								this.leveltwotime = '';
							} else {
								this.leveltwotime = [this.tools.timeFormate(shop.discount_start_time *
									1000), this.tools.timeFormate(shop.discount_end_time * 1000)];
							}

							this.advancedform.leveltwo.discount_start_time = shop.discount_start_time;
							this.advancedform.leveltwo.discount_end_time = shop.discount_end_time;
							this.advancedform.leveltwo.discount_activity = shop.discount_activity;
							this.advancedform.leveltwo.manager_purchase_price = parseFloat((shop
								.manager_purchase_price * 100).toFixed(2));

							this.advancedform.leveltwo.awardList.less_award_user = shop
								.manager_bargain_award[0].invite_number;
							this.advancedform.leveltwo.awardList.less_direct_common = parseFloat((shop
								.manager_bargain_award[0].straight_ordinary * 100).toFixed(2));
							this.advancedform.leveltwo.awardList.less_direct_advanced = parseFloat((shop
								.manager_bargain_award[0].straight_top * 100).toFixed(2));
							this.advancedform.leveltwo.awardList.less_indirect_common = parseFloat((shop
								.manager_bargain_award[0].push_ordinary * 100).toFixed(2));
							this.advancedform.leveltwo.awardList.less_indirect_advanced = parseFloat((shop
								.manager_bargain_award[0].push_senior * 100).toFixed(2));

							this.advancedform.leveltwo.awardList.section_direct_common = parseFloat((shop
								.manager_bargain_award[1].straight_ordinary * 100).toFixed(2));
							this.advancedform.leveltwo.awardList.section_direct_advanced = parseFloat((shop
								.manager_bargain_award[1].straight_top * 100).toFixed(2));
							this.advancedform.leveltwo.awardList.section_indirect_common = parseFloat((shop
								.manager_bargain_award[1].push_ordinary * 100).toFixed(2));
							this.advancedform.leveltwo.awardList.section_indirect_advanced = parseFloat((
								shop.manager_bargain_award[1].push_senior * 100).toFixed(2));

							this.advancedform.leveltwo.awardList.large_award_user = shop
								.manager_bargain_award[2].invite_number;
							this.advancedform.leveltwo.awardList.large_direct_common = parseFloat((shop
								.manager_bargain_award[2].straight_ordinary * 100).toFixed(2));
							this.advancedform.leveltwo.awardList.large_direct_advanced = parseFloat((shop
								.manager_bargain_award[2].straight_top * 100).toFixed(2));
							this.advancedform.leveltwo.awardList.large_indirect_common = parseFloat((shop
								.manager_bargain_award[2].push_ordinary * 100).toFixed(2));
							this.advancedform.leveltwo.awardList.large_indirect_advanced = parseFloat((shop
								.manager_bargain_award[2].push_senior * 100).toFixed(2));
							this.advancedform.leveltwo.goodaward = shop.goods_shipment_reward[0]
						}

					})

				})
			},
			//活动折扣 
			addDiscount(type) {
				//等级一---普通店长---活动折扣
				if (type == 'onecommon') {
					if (this.commonform.discount_activity.length >= 3) {
						this.$message.warning("最多只能够设置3个阶梯")
						return;
					}
					this.commonform.discount_activity.push({})
				}
				//等级二---普通店长---活动折扣
				if (type == 'twocommon') {
					if (this.advancedform.levelone.discount_activity.length >= 3) {
						this.$message.warning("最多只能够设置3个阶梯")
						return;
					}
					this.advancedform.levelone.discount_activity.push({})
				}
				//等级二---高级店长---活动折扣
				if (type == 'twoadvanced') {
					if (this.advancedform.leveltwo.discount_activity.length >= 3) {
						this.$message.warning("最多只能够设置3个阶梯")
						return;
					}
					this.advancedform.leveltwo.discount_activity.push({})
				}

			},
			// 删除折扣
			closeDiscount(index, type) {
				//等级一 普通
				if (type == 'onecommon') {
					this.commonform.discount_activity.splice(index, 1);
				}
				if (type == 'twocommon') {
					this.advancedform.levelone.discount_activity.splice(index, 1);
				}
				if (type == 'twoadvanced') {
					this.advancedform.leveltwo.discount_activity.splice(index, 1);
				}

			},
			//等级二---普通店长---活动折扣
			//提交设置
			submitSet(form) {
				if (form == 'commonform') {
					this.$refs.commonform.validate((valid) => {
						if (valid) {
							//等级一提交
							let data = {
								shop_unique_identification: this.shop_unique_identification == 1 ?
									'general_manager' : 'senior_manager',
								fenrunset: JSON.stringify([{
									unique_identification: 'general_manager',
									manager_name: this.commonform.manager_name, //名称自定义
									manager_price: this.commonform.manager_price, //店长入驻开通
									discount_start_time: this.commonform.discount_start_time,
									discount_end_time: this.commonform.discount_end_time,
									discount_activity_open: this.commonform
									.discount_activity_open, //活动折扣
									discount_activity: this.commonform.discount_activity, //活动折扣
									manager_purchase_price: this.commonform.manager_purchase_price,
									manager_bargain_award: [{
											invite_number: this.commonform.awardList
												.less_award_user,
											straight_ordinary: this.commonform.awardList
												.less_direct_common,
											straight_top: this.commonform.awardList
												.less_direct_advanced,
											push_ordinary: this.commonform.awardList
												.less_indirect_common,
											push_senior: this.commonform.awardList
												.less_indirect_advanced,
										},
										{
											invite_number: '1',
											straight_ordinary: this.commonform.awardList
												.section_direct_common,
											straight_top: this.commonform.awardList
												.section_direct_advanced,
											push_ordinary: this.commonform.awardList
												.section_direct_advanced,
											push_senior: this.commonform.awardList
												.section_indirect_advanced,
										},
										{
											invite_number: this.commonform.awardList
												.large_award_user,
											straight_ordinary: this.commonform.awardList
												.large_direct_common,
											straight_top: this.commonform.awardList
												.large_direct_advanced,
											push_ordinary: this.commonform.awardList
												.large_indirect_common,
											push_senior: this.commonform.awardList
												.large_indirect_advanced,
										},

									],
									goods_shipment_reward: [this.commonform.goodaward]

								}])

							}
							//console.log(data)
							managerFenRunSetting(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.$message({
										message: '设置成功',
										type: 'success'
									});
									this.getManagerFenRunList()
								}

							})

						} else {
							return false;
						}
					});
				} else {
					this.$refs.advancedform.validate((valid) => {
						if (this.advancedform.levelone.manager_name == '' || this.advancedform.leveltwo
							.manager_name == '') {
							this.$message.error('自义定名称不能为空');
							return;
						}
						if (valid) {
							let data = {
								shop_unique_identification: 'senior_manager',
								fenrunset: JSON.stringify([{
										unique_identification: 'general_manager',
										discount_start_time: this.advancedform.levelone
											.discount_start_time,
										discount_end_time: this.advancedform.levelone
											.discount_end_time,
										manager_name: this.advancedform.levelone.manager_name, //名称自定义
										manager_price: this.advancedform.levelone
										.manager_price, //店长入驻开通
										discount_activity_open: this.advancedform.levelone
											.discount_activity_open, //活动折扣
										discount_activity: this.advancedform.levelone
											.discount_activity, //活动折扣
										manager_purchase_price: this.advancedform.levelone
											.manager_purchase_price,
										manager_bargain_award: [{
												invite_number: this.advancedform.levelone.awardList
													.less_award_user,
												straight_ordinary: this.advancedform.levelone
													.awardList.less_direct_common,
												straight_top: this.advancedform.levelone.awardList
													.less_direct_advanced,
												push_ordinary: this.advancedform.levelone.awardList
													.less_indirect_common,
												push_senior: this.advancedform.levelone.awardList
													.less_indirect_advanced,
											},
											{
												invite_number: '1',
												straight_ordinary: this.advancedform.levelone
													.awardList.section_direct_common,
												straight_top: this.advancedform.levelone.awardList
													.section_direct_advanced,
												push_ordinary: this.advancedform.levelone.awardList
													.section_indirect_common,
												push_senior: this.advancedform.levelone.awardList
													.section_indirect_advanced,
											},
											{
												invite_number: this.advancedform.levelone.awardList
													.large_award_user,
												straight_ordinary: this.advancedform.levelone
													.awardList.large_direct_common,
												straight_top: this.advancedform.levelone.awardList
													.large_direct_advanced,
												push_ordinary: this.advancedform.levelone.awardList
													.large_indirect_common,
												push_senior: this.advancedform.levelone.awardList
													.large_indirect_advanced,
											}
										],
										goods_shipment_reward: [this.advancedform.levelone.goodaward]

									},
									{
										unique_identification: 'senior_manager',
										discount_start_time: this.advancedform.leveltwo
											.discount_start_time,
										discount_end_time: this.advancedform.leveltwo
											.discount_end_time,
										manager_name: this.advancedform.leveltwo.manager_name, //名称自定义
										manager_price: this.advancedform.leveltwo
										.manager_price, //店长入驻开通
										discount_activity_open: this.advancedform.leveltwo
											.discount_activity_open, //活动折扣
										discount_activity: this.advancedform.leveltwo
											.discount_activity, //活动折扣
										manager_purchase_price: this.advancedform.leveltwo
											.manager_purchase_price,
										manager_bargain_award: [{
												invite_number: this.advancedform.leveltwo.awardList
													.less_award_user,
												straight_ordinary: this.advancedform.leveltwo
													.awardList.less_direct_common,
												straight_top: this.advancedform.leveltwo.awardList
													.less_direct_advanced,
												push_ordinary: this.advancedform.leveltwo.awardList
													.less_indirect_common,
												push_senior: this.advancedform.leveltwo.awardList
													.less_indirect_advanced,
											},
											{
												invite_number: '1',
												straight_ordinary: this.advancedform.leveltwo
													.awardList.section_direct_common,
												straight_top: this.advancedform.leveltwo.awardList
													.section_direct_advanced,
												push_ordinary: this.advancedform.leveltwo.awardList
													.section_indirect_common,
												push_senior: this.advancedform.leveltwo.awardList
													.section_indirect_advanced,
											},
											{
												invite_number: this.advancedform.leveltwo.awardList
													.large_award_user,
												straight_ordinary: this.advancedform.leveltwo
													.awardList.large_direct_common,
												straight_top: this.advancedform.leveltwo.awardList
													.large_direct_advanced,
												push_ordinary: this.advancedform.leveltwo.awardList
													.large_indirect_common,
												push_senior: this.advancedform.leveltwo.awardList
													.large_indirect_advanced,
											}
										],
										goods_shipment_reward: [this.advancedform.leveltwo.goodaward]
									}
								])

							}
							managerFenRunSetting(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									this.$message({
										message: '设置成功',
										type: 'success'
									});
									this.getManagerFenRunList()
								}

							})
						} else {
							return false;
						}
					});
				}
			},

		}
	}
</script>

<style lang="scss" scoped="scoped">
	@import "@/assets/css/plat/profit.scss";

	.fistunit::v-deep.el-input-group__prepend {
		border-radius: 0;
	}

	.error {
		color: #F56C6C;
		font-size: 12px;
		line-height: 1;
		padding-top: 4px;

	}
</style>
