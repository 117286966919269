<template>
	<div>
		<div class="wrapper-main">
			<el-tabs v-model="activeName" @tab-click="handleClick">
			     <el-tab-pane :label="this.plugin_identifying==1?'基础设置':this.plugin_identifying==2?'整点秒杀设置':this.plugin_identifying==3?'幸运拼团':'裂变优惠券'" name="first">
					<baseSet ref="baseset" :plugin_id="plugin_id" :plugin_identifying="plugin_identifying"></baseSet>
				</el-tab-pane>
			    <el-tab-pane label="营销素材" name="second" v-if="this.plugin_identifying==1">
					<marketingMaterials  ref="materials" :plugin_id="plugin_id" :plugin_identifying="plugin_identifying"></marketingMaterials>
				</el-tab-pane>
			  </el-tabs>
		</div>
	</div>
</template>

<script>
	import baseSet from '../baseset/index.vue'
	import marketingMaterials from '../marketingmaterials/index.vue'
	export default{
		
		components:{
			baseSet,
			marketingMaterials
		},
		data(){
			return{
				activeName:'first',
				plugin_id:'',
				plugin_identifying:''
			}
		},
		mounted() {
			this.plugin_id=this.$route.query.plugin_id;
			this.plugin_identifying=this.$route.query.plugin_identifying;
			if(this.plugin_id){
				this.$nextTick(() => {
					this.$refs.baseset.getBaseInfo();
					this.$refs.baseset.tagListData();
					this.$refs.baseset.packetListData()
				})
			}
		},
		methods:{
			handleClick(val){
				if(val.name=='first'){
					this.$nextTick(() => {
						this.$refs.baseset.getBaseInfo();
						this.$refs.baseset.tagListData();
						this.$refs.baseset.packetListData()
					})
				}else if(val.name=='second'){
						this.$nextTick(() => {
							this.$refs.materials.getMaterialInfo();
							
						})
					
				}
				
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	::v-deep.el-tabs__header {
	padding: 0;
	position: relative;
	margin: 0;
	}
</style>
