<template>
	<div>
		<div class="wrapper-main wrapper-main1">
			<el-tabs v-model="withdrawal_money_type" @tab-click="handleClick">
				<el-tab-pane label="合伙人收入" name="1"></el-tab-pane>
				<el-tab-pane label="加权分红收入" name="2"></el-tab-pane>
				<!-- <el-tab-pane label="用户提现" name="three"></el-tab-pane> -->
			</el-tabs>
			<div class="wrapper-cont">
				<el-form ref="userform" :model="userform" :rules="userrules" label-width="250px" size="small" class="demo-ruleForm">
					<el-form-item label="提现开关:">
						<el-radio v-model="userform.is_open" label="1">开</el-radio>
						<el-radio v-model="userform.is_open" label="0">关</el-radio>
						<p style="font-size: 12px; color: #666;">提现关闭后，平台将无法提现</p>
					</el-form-item>
					<el-form-item label="提现方式:">
						 <el-checkbox v-model="userform.is_wechat" label="微信" ></el-checkbox>
    					 <el-checkbox v-model="userform.is_alipay" label="支付宝" ></el-checkbox>
						 <el-checkbox v-model="userform.is_bank" label="银行卡" ></el-checkbox>
					</el-form-item>
					<el-form-item label="最低提现额度:" prop="withdrawal_min">
						<el-input class="inputWidth" v-model="userform.withdrawal_min" placeholder="最低提现额度">
						</el-input>
						<p class="pstyle">如：设置100，商家只有达到100才可以提现</p>
					</el-form-item>
					<el-form-item label="免审额度:" prop="exemption_limit">
						<el-input class="inputWidth" v-model="userform.exemption_limit" placeholder="免审额度">
						</el-input>
						<p class="pstyle">如：设置1000，商家提现1000以内可以直接不需要后台审核打款，不设置情况下，默认任何额度都需要后台审核</p>
					</el-form-item>
					<el-form-item label="平台收取提现手续费" prop="service_fee">
						<el-input class="inputWidth" placeholder="平台收取提现手续费" v-model="userform.service_fee">
							<template slot="append">%</template>
						</el-input>
						<p class="pstyle">如：设置费率2%，当商家提现1000，手续费为20，当费率为0%，则不需要收取手续费</p>
					</el-form-item>
					<el-form-item label="提现固定时间:">
						<el-radio v-model="userform.fixed_type" label="1">每周</el-radio>
						<el-radio v-model="userform.fixed_type" label="2">月结</el-radio>
						<div class="weekmenu" v-if="userform.fixed_type == '1'">
							<el-select style="max-width:235px;" @change="$forceUpdate()"
								v-model="userform.weekVal" multiple placeholder="请选择">
								<el-option class="weekstyle" v-for="(item,index) in weekList" :key="index" :label="item.title"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="monthmenu" v-if="userform.fixed_type == '2'">
							<el-select popper-class="selects" @change="$forceUpdate()"
								v-model="userform.monthVal" multiple placeholder="请选择">
								<el-option class="weekstyle" v-for="(item1,index1) in monthList" :key="index1" :label="item1"
									:value="item1">
								</el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item label="提现须知:">
						<el-input type="textarea" class="inputWidth" :rows="5" placeholder="1.根据相关规定，必须绑定实名认证才提现。
							2.每周三日结算已到帐的收益。
							3.提现金额单笔最低¥10，最高¥5000。
							4.如有其他问题，请立即联系官方客服。" v-model="userform.note">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet()">提交</div>
		</div>
	</div>
</template>

<script>
	import {
		addWithdrawalConfig,
		getWithdrawalConfigInfo
	} from '@/api/plat/finance'
	export default {
		data() {
			return {
				withdrawal_money_type: '1',
				//用户
				userform: {
					is_open: '1',
					is_wechat:false,
					is_alipay:false,
					withdrawal_min: '',
					exemption_limit: '',
					service_fee: '',
					fixed_type: '1',
					fixed_time: '',
					weekVal: [],
					monthVal: [],
					note: ''
				},
				userrules:{},
				weekList:[
					{
					  value: '1',
					  title: '一'
					}, {
					  value: '2',
					  title: '二'
					}, {
					  value: '3',
					  title: '三'
					}, {
					  value: '4',
					  title: '四'
					}, {
					  value: '5',
					  title: '五'
					}, {
					  value: '6',
					  title: '六'
					}, {
					  value: '7',
					  title: '七'
					}
				],
				monthList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17",
					"18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"
				],
				withdrawal_config_id: '',
				account_type: '2'
			}
		},
		mounted() {
			//console.log(this.agentform.weekVal)
			this.getInfo()
		},
		methods: {
			getInfo() {
				let params = {
					account_type: this.account_type,
					withdrawal_money_type:this.withdrawal_money_type
					// is_open:this.activeName == 'first'?this.agentform.is_open:this.merchantform.is_open,
				}
				getWithdrawalConfigInfo(params).then(res => {
					if (res.data != '') {
						this.userform = JSON.parse(JSON.stringify(res.data));
						this.userform.is_open = this.userform.is_open.toString();
						this.userform.withdrawal_type = this.userform.withdrawal_type.toString();
						this.userform.fixed_type = this.userform.fixed_type.toString();
						this.withdrawal_config_id = this.userform.withdrawal_config_id;
						this.userform.is_alipay = this.userform.is_alipay==1?true:false;
						this.userform.is_wechat = this.userform.is_wechat==1?true:false;
						if (this.userform.fixed_type == 1) {
							this.userform.weekVal = this.userform.fixed_time.split(',')
						} else {
							this.userform.monthVal = this.userform.fixed_time.split(',')
						}
					}
				})
			},
			handleClick(tab) {
				this.withdrawal_money_type=tab.name;
				// if (tab.name == 'first') {
				// 	this.account_type = 1
				// } else if (tab.name == 'second') {
				// 	this.account_type = 2
				// } else if (tab.name == 'three') {
				// 	this.account_type = 3
				// }
				this.getInfo()
			},
			submitSet() {
					//商户
					this.$refs.userform.validate((valid) => {
						if (valid) {
							if (this.userform.fixed_type == 1) {
								this.userform.fixed_time = this.userform.weekVal.toString()
							} else {
								this.userform.fixed_time = this.userform.monthVal.toString()
							}
							let data = JSON.parse(JSON.stringify(this.userform));
							if (this.withdrawal_config_id) {
								data.withdrawal_config_id = this.withdrawal_config_id
							}
							data.is_alipay = data.is_alipay==true?'1':'0';
							data.is_wechat = data.is_wechat==true?'1':'0'
							data.account_type = this.account_type;
							delete data['weekVal'];
							delete data['monthVal']
							data = this.tools.filterParams(data);
							addWithdrawalConfig(data).then(() => {
								 this.$message({
										message: '设置完成',
										type: 'success'
									});
								this.withdrawal_config_id = '';
								this.getInfo()
							})

						} else {
							return false;
						}
					});
			}
		}
	}
</script>

<style lang="scss">
	.wrapper-main1::v-deep.el-tabs__nav-wrap::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background-color: #fff;
		z-index: 1;
	}

	.wrapper-main1::v-deep.el-tabs__item.is-active {
		background-color: #2589FF;
		color: #fff;
	}

	.wrapper-main1::v-deep.el-tabs__item {
		padding: 0 20px;
		height: 34px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		line-height: 34px;
		display: inline-block;
		list-style: none;
		font-size: 14px;
		font-weight: 500;
		margin-right: 40px;
		text-align: center;
		color: #303133;
		position: relative;
		border-radius: 4px;
	}

	.wrapper-main1::v-deep.el-tabs__item.is-top:nth-child(2) {
		padding-left: 20px;
	}

	.wrapper-main1::v-deep.el-tabs--top .el-tabs__item.is-top:last-child {
		padding-right: 20px;
	}

	.wrapper-main1::v-deep.el-tabs__active-bar {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 2px;
		background-color: transparent;
		z-index: 1;
	}

	.wrapper-main1::v-deep.el-tabs__nav {
		margin-left: 20px;
	}

	.weekstyle {
		font-size: 14px;
		width: 30px !important;
		padding: 0 !important;
		position: relative;
		white-space: nowrap;
		float: left;
		text-align: center;
		overflow: hidden;
		margin: 0 3px 5px;
		border-radius: 4px 4px;
		text-overflow: ellipsis;
		color: #606266;
		height: 30px;
		line-height: 30px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		cursor: pointer;

	}

	.weekstyle.selected {
		color: #fff !important;
		background-color: #FFF !important;
		background: #409EFF !important;
	}

	.weekstyle.selected::after {
		position: absolute !important;
		right: 20px !important;
		font-family: element-icons !important;
		content: none !important;
		font-size: 12px !important;
		font-weight: 700 !important
	}

	.selects {
		z-index: 3000 !important;
		width: 235px !important;
	}
</style>
