<template>
	<div>
		<div class="wrapper-main">
			<el-form :model="form" :rules="rules" ref="form" label-width="200px" class="demo-ruleForm">
			<div class="wrapper-cont pp20">
				<div class="setTitle">访客设置</div>
				
					<el-form-item label="绑定关系">
						无消费时绑定， <el-input size="small" class="inputWidth" placeholder="请输入内容" v-model="form.days">
							<template slot="append">天</template>
						</el-input>保护期
					</el-form-item>
				
			</div>
			<div class="wrapper-cont pp20 mt10">
				<div class="setTitle">商城页面设置</div>
				<el-form-item label="技术支持标识">
					 <el-radio v-model="form.sign" label="1">显示</el-radio>
					 <div><img src="../../../../../assets/img/skill.png" style="height: 51px;"></div>
				</el-form-item>
				<div class="setTitle">收藏小程序</div>
				<el-form-item label="收藏小程序">
					<el-switch v-model="form.open_refund_reason" active-text="已开启" active-color='#409EFF'>
					</el-switch>
					<div class="pstyle">开启后，用户进入小程序首页，右上角弹窗提示【点击“...”添加到我的小程序，微信首页下拉即可快速访问店铺】示例</div>
				</el-form-item>
			</div>
			</el-form>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="submitSet('form')">保存</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					days:'',
					sign:'1',
					open_refund_reason: true, 
				},
				rules:{
					
				}
			}
		},
		methods:{
			//提交申请
			submitSet(form) {
				this.$refs['form'].validate((valid) => {
					if (valid) {
				
			
					} else {
						this.$message.error('请填写完整信息')
						return false;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
