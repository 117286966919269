import axios from '@/config/httpConfig'
import qs from  'qs'
//视频列表
export const videoUploadingList=(params)=> {
    return axios('get','/Oss/platform/videoUploadingList', params)
}
//视频上传
export const videoUploading=(data)=> {
    return axios('post','/Oss/platform/videoUploading', data,true);
}
//封面上传
export const videoimages=(data)=> {
    return axios('post','/Oss/platform/videoimages', data,true);
}
//保存图片上传
export const videoUp=(data)=> {
    return axios('post','/Oss/platform/videoUp', data);
}


//分片上传视频预处理
export const uploadVideoPretreatment=(data)=> {
    return axios('post','/College/platform/uploadVideoPretreatment', data);
}

//分片上传视频
export const uploadVideo=(data)=> {
    return axios('post','/College/platform/uploadVideo', data);
}