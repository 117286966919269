<template>
	<div>
		<div class="wrapper-main pds">
			<el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small" class="demo-ruleForm">
				<div class="wrapper-cont pp20">
					<div class="setTitle">基础设置</div>
					<el-form-item label="课程标题:" prop="course_title">
						<el-input style="width: 600px!important;" placeholder="请输入课程标题" size="small" maxlength="36"
							show-word-limit v-model="form.course_title">
						</el-input>
					</el-form-item>
					<el-form-item label="副标题:">
						<el-input style="width: 600px!important;" placeholder="请输入副标题" size="small" maxlength="20"
							show-word-limit v-model="form.course_subtitle">
						</el-input>
					</el-form-item>
					<el-form-item label="选择类目" prop="course_List">
						<el-cascader size="small" v-model="form.course_List" :options="allCate" placeholder="课程分类"
							:props="{ expandTrigger: 'hover',value:'college_course_id',label:'course_name',children:'children'}">
						</el-cascader>
					</el-form-item>
					<el-form-item label="课程类型:">
						<el-radio v-model="form.course_type" label="1">图文课程</el-radio>
						<el-radio v-model="form.course_type" label="2">视频课程</el-radio>
					</el-form-item>
					<el-form-item label="课程讲师" prop="college_lecturer_id" v-if="form.course_type==2">
						<el-select placeholder="请选择课程讲师" v-model="form.college_lecturer_id">
							<el-option :label="item.lecturer_name" v-for="(item,index) in lecturerData" :key="index"
								:value="item.college_lecturer_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="课程简介" v-if="form.course_type==2">
						<el-input type="textarea" style="width:400px!important" v-model="form.introduced" maxlength="60"
							show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="是否推荐该课程:">
						<el-radio v-model="form.is_recommend " label="1">否</el-radio>
						<el-radio v-model="form.is_recommend " label="2">是</el-radio>
					</el-form-item>
					<el-form-item label="课程是否免费:" v-if="form.course_type==2">
						<el-radio v-model="form.is_free" label="Y">是</el-radio>
						<el-radio v-model="form.is_free" label="N">否</el-radio>
					</el-form-item>
					<el-form-item label="课程售价:" prop="selling_price" v-if="form.course_type==2 && form.is_free=='N'">
						<el-input class="w180" oninput="value=value.replace(/[^\d.]/g,'')" v-model="form.selling_price">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
					<el-form-item label="排序:">
						<el-input placeholder="请输入数值" style="width: 600px!important;" size="small"
							oninput="value=value.replace(/[^\d.]/g,'')" v-model="form.sort">
						</el-input>
						<p class="imgtxtstyle">数值越低课程排序越往前</p>
					</el-form-item>
				</div>
				<div class="wrapper-cont pp20 mt10">
					<el-form-item label="课程封面" prop="course_cover" >
						<div class="img_blos">
							<div class="img_blo" @click="uploadImg('cover')">
								<img :src="form.course_cover" class="item_img" v-if="form.course_cover" style="margin-right:0!important;" />
								<div class="plusImg" v-else>
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
						</div>
						<div style="color: #999;font-size: 12px;">
							建议尺寸320*300像素
						</div>
					</el-form-item>
					<el-form-item label="课程轮播:" prop="slideshow" v-if="form.course_type==2">
						<div class="img_blos">
							<div v-for="(item, index) in form.slideshow" :key="index" class="img_blo"
								style="margin-bottom:15px">
								<i class="el-icon-error close_img" @click="delImg(index)"></i>
								<img :src="item" class="item_img" />
							</div>
							<div class="plusImg" @click="uploadImg('loop')" v-if="form.slideshow.length<15" >
								<i class="el-icon-plus avatar-uploader-icon"></i>
							</div>
						</div>
						<div style="color: #999;font-size: 12px;">
							建议尺寸：800*800像素，你可以拖拽图片调整顺序，最多上传15张
						</div>
					</el-form-item>
					<el-form-item label="课程详情" prop="details">
						<div style="height: 500px; ">
							<Editor v-model="form.details" />
						</div>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<div class="save-cont">
			<div class="cancel-btn" @click="cancelSet">取消</div>
			<div v-if="type=='detail'">
				<div class="save-btn" v-if="form.course_type==1"  @click="nextStep()">下一步</div>
				<div class="save-btn" v-if="form.course_type==2"  @click="nextStep()">下一步</div>
			</div>
			<div v-else>
				<div class="save-btn" @click="submitSet('form')" v-if="form.course_type==1">提交</div>
				<div class="save-btn" @click="submitSet('form')" v-if="form.course_type==2">保存并下一步</div>
			</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>

</template>
<script>
	import Editor from '../../../../../common/newEditor.vue';
	import ChooseImg from "../../../../../common/uploadImgs";
	import {
		courseList,
		lecturerList,
		saveCollegeCurriculum,
		getCollegeCurriculumInfo
	} from "@/api/plat/college.js"
	export default {
		components: {
			Editor,
			ChooseImg
		},
		data() {
			var salePrice= (rule, value, callback) => {
				if (!value) {
					callback(new Error('课程售价不能为空'));
				} else if (value ==0) {
					callback(new Error('课程售价必须大于0'));
				} else {
					callback();
				}
			};
			return {
				isSingle: true,
				uploadFlag: false, //是否展示选择图片弹窗
				imgType: '',
				form: {
					course_title: '',
					course_subtitle: '',
					course_type: '1',
					is_recommend:'1',
					sort: '',
					details: '',
					college_lecturer_id: '',
					introduced: '',
					is_free: 'Y',
					selling_price: '',
					course_cover: '',
					slideshow: [],
					course_List: [], //课程类目的id
				},
				rules: {
					course_title: [{
						required: true,
						message: '请输入课程名称',
						trigger: 'blur'
					}],
					course_List: [{
						required: true,
						message: '请选择类目',
						trigger: 'change'
					}],
					college_lecturer_id: [{
						required: true,
						message: '请选择课程讲师',
						trigger: 'change'
					}],
					selling_price: [{
						required: true,
						validator: salePrice,
						trigger: 'blur'
					}],
					course_cover: [{
						required: true,
						message: '请选择课程封面',
						trigger: 'change'
					}],
					slideshow: [{
						required: true,
						message: '请选择课程轮播',
						trigger: 'change'
					}],
					details: [{
						required: true,
						message: '课程详情不能为空',
						trigger: 'blur'
					}],
				},
				allCate: [], //课程类目
				lecturerData: [], //课程讲师
				college_curriculum_id:this.$route.query.id,
				type:this.$route.query.type,
			}
		},
		mounted() {
			this.getCourseType();
			this.getLecturer();
			if(this.college_curriculum_id){
				 this.getInfo()
			}
		},
		methods: {
			//获取课程详情
			getInfo(){
				let data={
					college_curriculum_id:this.college_curriculum_id
				}
				getCollegeCurriculumInfo(data).then(res=>{
					this.form=res.data;
					this.form.course_type = this.form.course_type.toString();
					this.form.is_recommend = this.form.is_recommend.toString();
					this.form.slideshow=this.form.slideshow.split(',')
				})
			},
			//课程类目
			getCourseType() {
				courseList({}).then(res => {
					this.allCate = res.data
				})
			},
			//课程讲师
			getLecturer() {
				lecturerList({}).then(res => {
					this.lecturerData = res.data
				})
			},
			uploadImg(type) {
				this.imgType = type;
				if (this.imgType == 'cover') {
					this.isSingle = true;
				}
				if (this.imgType == 'loop') {
					this.isSingle = false;
				}
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				if (this.imgType == 'cover') {
					this.form.course_cover = val[0].resource_name;
				}
				if (this.imgType == 'loop') {
					val.forEach((res) => {
						if(this.form.slideshow.length>=15){
							this.$message.warning("最多添加15张")
							return;
						}
						this.form.slideshow = this.form.slideshow.concat([res.resource_name]);
					});
				}
			},
			//删除图片
			delImg(index) {
				this.form.slideshow.splice(index, 1);
			},
			//取消设置
			cancelSet() {
				this.$router.push({
					path: "/marketingadmin/businesscollege/businesscollegeindex",
				})
			},
			//提交设置
			submitSet(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data = JSON.parse(JSON.stringify(this.form));
						if(!this.college_curriculum_id){
							if (data.course_type == 1) {
								data.status = 1
							} else if (data.course_type == 2) {
								data.status = 2
							}
						}else{
							data.college_curriculum_id=this.college_curriculum_id
						}
						
						data.college_course_id_one=this.form.course_List[0]?this.form.course_List[0]:'';
						data.college_course_id_two=this.form.course_List[1]?this.form.course_List[1]:'';
						data.course_cover=this.tools.filterImgPre(data.course_cover);
						data.slideshow.forEach(item=>{
							item=this.tools.filterImgPre(data.course_cover);
						})
						let slideshow_pic = [];
						data.slideshow.forEach((item) => {
							slideshow_pic.push(this.tools.filterImgPre(item));
						});
						data.slideshow=slideshow_pic.toString();
						delete data["course_List"];
						data=this.tools.filterParams(data);
						saveCollegeCurriculum(data).then(res => {
							if (data.course_type == 1) {
								this.$router.go(-1);
							} else if (data.course_type == 2) {
								this.$router.push({
									path: "/marketingadmin/businesscollege/newcollege",
									query:{
										id:res.data
									}
								})
							}
						})

					}
				});
			},
			//详情
			nextStep(){
				if (this.form.course_type == 1) {
					this.$router.go(-1);
				} else if (this.form.course_type == 2) {
					this.$router.push({
						path: "/marketingadmin/businesscollege/newcollege",
						query:{
							id:this.college_curriculum_id
						}
					})
				}
			},
		}
	}
</script>

<style scoped>
.pds{
	padding-bottom: 70px;
}
</style>
