<template>
	<div>
		<div style="border-top: 10px solid #F2F2F6;">
			<div class="list">
				<el-row class="all_search_list" style="margin-bottom: 0; padding: 15px 15px;">
					<div class="demo-input-suffix">
						<el-button size="small" type="primary" @click="toAdd" v-has="'学院课程/课程讲师/新建讲师'" >新建讲师</el-button>
					</div>
					<div class="demo-input-suffix">
						<el-select v-model="search.status" size="small" class="w120" placeholder="全部状态">
							<el-option label="全部" value=""></el-option>
							<el-option label="启用" value="1"></el-option>
							<el-option label="禁用" value="2"></el-option>
						</el-select>
					</div>
					<div class="demo-input-suffix">
						<el-input prefix-icon="el-icon-search" class="inputWidth" v-model="search.lecturer_name"
							placeholder="请输入" size="small" type="text"  @keyup.enter.native="toSearch"  clearable>
						</el-input>
					</div>
					<div class="demo-input-suffix">
						<el-button size="small" type="primary" @click="toSearch">搜索</el-button>
					</div>
					<div class="demo-input-suffix">
						<el-button size="small"  @click="toReset">重置</el-button>
					</div>
				</el-row>
				<el-table :data="tableData" style="width: 100%" border v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}">
					<el-table-column label="讲师名称" align="center" prop="lecturer_name"></el-table-column>
					<el-table-column label="资历" align="center" prop="lecturer_synopsis"></el-table-column>
					<el-table-column label="状态" align="center">
						<template slot-scope="scope">
							<span style="color: #409EFF;" v-if="scope.row.status == '1'">启用</span>
							<span style="color: #E02020;" v-if="scope.row.status == '2'">禁用</span>
						</template>
					</el-table-column>
					<el-table-column label="创建时间" align="center" prop="created_at"></el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button style="padding: 5px 0;" type="text" size="small"
								@click="toOpera(scope.row,'detail')" v-has="'学院课程/课程讲师/查看'" >查看
							</el-button>
							<el-button style="padding: 5px 0;color: #E02020;" type="text" size="small"
								@click="toOpera(scope.row,'del')" v-has="'学院课程/课程讲师/删除'" >删除</el-button>
							<el-button style="padding: 5px 0px;" type="text" size="small"
								@click="toOpera(scope.row,'edit')" v-has="'学院课程/课程讲师/编辑'" >编辑
							</el-button>
							<el-button style="padding: 5px 0px;" type="text" size="small">
								<div v-if="scope.row.status == '1'"  style="color: #409EFF;" @click="isOpen(scope.row, 2)" v-has="'学院课程/课程讲师/禁用'" >
									禁用
								</div>
								<div v-else-if="scope.row.status == '2'" style="color: #E02020;" @click="isOpen(scope.row, 1)" v-has="'学院课程/课程讲师/启用'" >
									启用
								</div>
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		
		<!--新增导师弹框-->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="30%" @close="formCancel">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
				<el-form-item label="讲师名字" prop="lecturer_name">
					<el-input v-model="form.lecturer_name" placeholder="请输入讲师名称" size="small" maxlength="6" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="讲师资历" prop="lecturer_synopsis">
					<el-input v-model="form.lecturer_synopsis" size="small" placeholder="请输入讲师资历" maxlength="20" show-word-limit></el-input>
					<p class="imgtxtstyle">例如：国家二级心理咨询师</p>
				</el-form-item>
				<el-form-item label="讲师头像:" prop="lecturer_image">
					<div class="icon_blos">
						<div class="icon_blo"  @click="uploadImg()">
							<img :src="form.lecturer_image" class="item_img" v-if="form.lecturer_image" style="margin-right: 0!important;" />
							<div class="iconImg" v-else>
								<i class="el-icon-plus avatar-uploader-icon"></i>
							</div>
						</div>
					</div>

				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-if="detailBtn==true" @click="formCancel()" size="small">关闭</el-button>
				<div v-else>
					<el-button @click="dialogVisible = false" size="small">取 消</el-button>
					<el-button type="primary" @click="formSubmit('form')" size="small">确 定</el-button>
				</div>
			</span>
		</el-dialog>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="true" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import ChooseImg from "../../../../../common/uploadImgs.vue";
	import {
		lecturerListPage,
		addLecturer,
		updateLecturer,
		lecturerInfo,
		delLecturer,
		updateLecturerStatus
	} from "@/api/plat/college.js"
	export default {
		components: {
			ChooseImg
		},
		data() {
			return {
				loading:true,
				//搜索条件
				search: {
					status: '',
					lecturer_name: ''
				},
				//课程列表
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				title: '添加课程讲师',
				dialogVisible: false,
				form: {
					lecturer_name: '',
					lecturer_synopsis: '',
					lecturer_image: '',
				},
				rules: {
					lecturer_name: [{
						required: true,
						message: '请填写讲师名称',
						trigger: 'blur'
					}],
					lecturer_synopsis: [{
						required: true,
						message: '请填写讲师资历',
						trigger: 'blur'
					}],
					lecturer_image: [{
						required: true,
						message: '请上传讲师头像',
						trigger: 'change'
					}],
				},
				uploadFlag: false,
				college_lecturer_id: '',
				detailBtn: false

			}
		},
		created() {},
		methods: {
			getCollegeTeacherList() {
				let data = {
					lecturer_name: this.search.lecturer_name,
					status: this.search.status,
					page: this.currentPage,
					perPage: this.pagesize
				}
				data = this.tools.filterParams(data);
				lecturerListPage(data).then(res => {
					this.loading=false;
					this.tableData = res.data.list;
					this.total = res.data.count;
				}).catch(err => {
					this.$message.error(err.return_msg)
				})
			},
			toSearch(){
				this.currentPage = 1;
				this.getCollegeTeacherList()
			},
			toReset(){
				this.search.status='';
				this.search.lecturer_name='';
				this.currentPage=1;
				this.getCollegeTeacherList()
			},
			//获取课程详情
			getInfo() {
				let data = {
					college_lecturer_id: this.college_lecturer_id
				}
				data = this.tools.filterParams(data);
				lecturerInfo(data).then(res => {
					this.form = res.data;
				})
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getCollegeTeacherList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getCollegeTeacherList()
			},
			//新增
			toAdd() {
				this.college_lecturer_id = '';
				this.dialogVisible = true;
				this.title = '添加课程讲师';
				this.form.lecturer_name = '';
				this.form.lecturer_synopsis = '';
				this.form.lecturer_image = '';
			},
			uploadImg(type) {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				this.form.lecturer_image = val[0].resource_name;
			},
			//添加导师提交
			formSubmit(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口
						let data = JSON.parse(JSON.stringify(this.form));
						data.lecturer_image = this.tools.filterImgPre(data.lecturer_image)
						if (this.college_lecturer_id) {
							//编辑
							data.college_lecturer_id = this.college_lecturer_id;
							updateLecturer(data).then(res => {
								this.$message.success("编辑成功");
								this.dialogVisible = false;
								this.getCollegeTeacherList();
								this.college_lecturer_id = '';
							})
						} else {
							//添加
							addLecturer(data).then(res => {
								this.$message.success("添加成功");
								this.dialogVisible = false;
								this.getCollegeTeacherList()
							})
						}

					} else {
						return false;
					}
				});
			},
			//操作
			toOpera(row, type) {
				this.college_lecturer_id = row.college_lecturer_id;
				//详情
				if (type == 'detail') {
					this.dialogVisible = true;
					this.getInfo();
					this.title = '课程讲师';
					this.detailBtn = true;
				}
				//删除
				if (type == 'del') {
					this.tools.confirm("确定要删除此课程讲师吗？").then(res => {
						if (res) {
							let data = {
								college_lecturer_id: row.college_lecturer_id
							}
							delLecturer(data).then(res => {
								this.$message.success("删除成功！");
								this.getCollegeTeacherList()
							})
						}
					})
				}
				//编辑
				if (type == 'edit') {
					this.dialogVisible = true;
					this.getInfo();
					this.title = '修改课程讲师'
				}
			},
			isOpen(row, type) {
				this.tools.confirm(type==1?"确定要启用此讲师吗？":'确定要禁用此讲师吗？').then(res => {
					if (res) {
						let data = {
							college_lecturer_id: row.college_lecturer_id,
							status: type
						}
						updateLecturerStatus(data).then(res => {
							this.$message.success("操作成功！");
							this.getCollegeTeacherList()

						})
					}
				})
			},
			formCancel() {
				this.detailBtn = false;
				this.dialogVisible = false;
			}
		}
	}
</script>

<style>
</style>
