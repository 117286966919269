<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
					<div class="main-cont pp20">
						<div class="setTitle">基本信息</div>
						<el-form-item label="活动名称:" prop="activity_name">
							<el-input class="inputWidth" size="small" v-model="form.activity_name" maxlength="4"
								placeholder="请输入活动名称" show-word-limit></el-input>
						</el-form-item>
						<el-form-item label="活动标签:">
							<div class="img_blosmark" @click="uploadImg('mark')">
								<div class="img_blomark">
									<img :src="form.activity_tag" class="item_imgmark" v-if="form.activity_tag" />
									<div class="plusImgmark" v-else>
										<i class="el-icon-plus avatar-uploader-icon"></i>
									</div>
								</div>
							</div>
							<div class="imgtxtstyle mt10">
								可上传png、jpeg、jpg、gif；建议尺寸130*30像素，长最大限制160px
							</div>
						</el-form-item>
					</div>
					<div class="main-cont pp20 mt10">
						<div class="setTitle">活动顶部</div>
						<el-form-item label="样式设置:">
							<el-radio v-model="form.styleRadio" label="1">颜色</el-radio>
							<el-radio v-model="form.styleRadio" label="2">图片</el-radio>
							<div v-if="form.styleRadio == '1'">
								<div class="flex">
									文字颜色：<div class="el_color">
										<div class="color_con">{{ form.text_color }}</div>
										<el-color-picker v-model="form.text_color"></el-color-picker>
									</div>
								</div>
								<div class="flex">
									背景颜色：<div class="el_color">
										<div class="color_con">{{ form.background_color }}</div>
										<el-color-picker v-model="form.background_color"></el-color-picker>
									</div>
								</div>
							</div>
							<div v-if="form.styleRadio == '2'">
								<div class="img_blosmark" @click="uploadImg('top')">
									<div class="img_blomark">
										<img :src="form.item_imgstyle" class="item_imgmark" v-if="form.item_imgstyle" />
										<div class="plusImgmark" v-else>
											<i class="el-icon-plus avatar-uploader-icon"></i>
										</div>
									</div>
								</div>
								<div class="imgtxtstyle mt10">
									建议尺寸750*255像素，高不限制
								</div>
							</div>
						</el-form-item>
					</div>
				</el-form>
			</div>
		</div>
		<div class="save-cont">
			<div class="save-btn" @click="saveAddGood('form',1)">保存并添加商品</div>
			<div class="save-btn" @click="saveAddGood('form',2)">保存</div>
			<div class="cancel-btn" @click="goBack">取消</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		addActivity,
		getActivityInfo,
		editActivity
	} from '@/api/plat/market.js'
	import ChooseImg from "../../../../../common/uploadImgs";
	export default {
		components: {
			ChooseImg,
		},
		data() {
			return {
				form: {
					activity_name: '',
					activity_tag: '',
					styleRadio: '1',
					text_color: '',
					background_color: '',
					item_imgstyle: ''
				},
				rules: {
					activity_name: [{
						required: true,
						message: '请填写活动名称',
						trigger: 'blur'
					}]
				},
				isSingle: false, //上传图片是单张还是多张
				uploadFlag: false, //是否展示选择图片弹窗
				imgType: '',
				activity_id: ''
			}
		},
		mounted() {
			this.activity_id = this.$route.query.id;
			if (this.activity_id) {
				this.getInfo()
			}
		},
		methods: {
			//获取活动详情
			getInfo() {
				let params = {
					activity_id: this.activity_id
				}
				getActivityInfo(params).then(res => {
					let info = res.data;
					this.activity_id = info.activity_id;
					this.form.activity_name = info.activity_name;
					this.form.activity_tag = info.activity_tag;
					this.form.styleRadio = info.header_type.toString();
					this.form.text_color = info.header_font_color;
					if (this.form.styleRadio == '1') {
						this.form.background_color = info.header_bg
					} else {
						this.form.item_imgstyle = info.header_bg
					}

				})
			},
			// 上传商品图
			uploadImg(type) {
				this.imgType = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.uploadFlag = false;
				if (this.imgType == 'mark') {
					this.form.activity_tag = val[0].resource_name;
				}
				if (this.imgType == 'top') {
					this.form.item_imgstyle = val[0].resource_name;
				}

			},
			//保存并添加商品
			saveAddGood(form, type) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						//调接口

						if (this.activity_id) {
							let data = {
								activity_id: this.activity_id,
								name: this.form.activity_name,
								tag: this.tools.filterImgPre(this.form.activity_tag),
								type: this.form.styleRadio,
								bg: this.form.styleRadio == '1' ? this.form.background_color : this.tools
									.filterImgPre(this.form.item_imgstyle),
								fontColor: this.form.text_color
							}
							data = this.tools.filterParams(data)
							editActivity(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									if (type == 1) {
										this.$router.push({
											path: "/marketingadmin/cattleshopgood/goodslibrary?id=" +
												this.activity_id
										})
									} else {
										this.$router.go(-1)
									}
								}
							})
						} else { //新增
							let data = {
								name: this.form.activity_name,
								tag: this.tools.filterImgPre(this.form.activity_tag),
								type: this.form.styleRadio,
								bg: this.form.styleRadio == '1' ? this.form.background_color : this.tools
									.filterImgPre(this.form.item_imgstyle),
								fontColor: this.form.text_color
							}
							data = this.tools.filterParams(data);
							addActivity(data).then(res => {
								if (res.return_code == 'SUCCESS') {
									if (type == 1) {
										this.$router.push({
											path: "/marketingadmin/cattleshopgood/goodslibrary?id=" +
												res.data
										})
									} else {
										this.$router.go(-1)
									}
								}
							})
						}
					} else {
						return false;
					}
				});
			},
			//取消新建
			goBack() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.img_blosmark {
		display: flex;
		flex-wrap: wrap;
		width: 700px;
		min-height: 70px;

		.img_blomark {
			position: relative;
			height: 70px;

			.item_imgmark {
				width: 100px;
				height: 100%;
				margin-right: 30px;
			}

			.close_imgmark {
				font-size: 20px;
				right: 6px;
				top: -10px;
				position: absolute;
				cursor: pointer;
			}
		}

		.add_imgmark {
			width: 150px;
			height: 70px;
			cursor: pointer;
		}
	}

	.plusImgmark {
		width: 150px;
		border: 1.3px dashed #CCC;
		background-color: #F7F8FA;
		border-radius: 4px 4px;
		text-align: center;
		height: 70px;
		line-height: 70px;
		cursor: pointer
	}

	.plusImgmark .el-icon-plus {
		width: 150px;
		height: 70px;
		line-height: 70px;
		font-size: 24px;
		color: #969799
	}

	.el_color {
		display: flex;
		align-items: center;

		.color_con {
			height: 32px;
			line-height: 32px;
			padding: 0 10px;
			box-sizing: border-box;
			width: 210px;
			border-radius: 4px;
			margin: 0 12px;
			border: 1px solid rgba(0, 0, 0, 0.15);
			background-color: #fff;
		}
	}
</style>
