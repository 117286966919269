<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-row type="flex" class="row-bg" justify="space-between">
						<el-col :span="6">
							<div class="grid-content bg-purple">
								<el-button v-for="(item, index) in selectDay" :key="index" size="small"
									:type="isSelected == index ? 'primary' : ''" @click="changeTime(index)">
									{{ item.name }}
								</el-button>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple" style="text-align: right;">
								<el-button type="primary" size="small" @click="toWithdrow">提现</el-button>
								<el-button type="primary" size="small" @click="toRecharge">充值</el-button>
							</div>
						</el-col>
					</el-row>
				</div>
				<el-row :gutter="10">
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="main-cont bgwt pp20 mt10">
								<div class="fund_item">
									<div>余额</div>
									<div class="monval">{{ parseFloat(balance).toFixed(2)}}</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="main-cont bgwt pp20 mt10">
								<div class="fund_item">
									<div>待审核金额</div>
									<div class="monval" style="color: #E6A23C;">{{ parseFloat(unreviewed).toFixed(2)}}</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="main-cont bgwt pp20 mt10">
								<div class="fund_item">
									<div>已审核金额</div>
									<div class="monval">{{ parseFloat(reviewed).toFixed(2)}}</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="main-cont bgwt pp20 mt10">
								<div class="fund_item">
									<ElPopover :name="popover_audit_money_name" :title="popover_audit_money_title"
										:content="popover_audit_money_content">
									</ElPopover>
									<div class="monval">{{ parseFloat(earnest).toFixed(2)}}</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
				<div class="main-cont bgwt mt10">
					<el-table :data="tableData" style="width: 100%" min-height="250" border  max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="withdrawal_way" label="提现方式" align="center" sortable>
							<template slot-scope="scope">
								<span v-if="scope.row.pay_type == 1">微信</span>
								<span v-else-if="scope.row.pay_type == 2">支付宝</span>
							</template>
						</el-table-column>
						<el-table-column prop="money" label="提现金额" align="center" sortable>
						</el-table-column>
						<el-table-column prop="service_fee" label="手续费" align="center" sortable>
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.examine_status == 0" style="color: #E6A23C;">待审核</span>
								<span v-else-if="scope.row.examine_status == 1 && scope.row.pay_status == 0"
									style="color: #F56C6C;">未支付</span>
								<span v-else-if="scope.row.examine_status == 1 && scope.row.pay_status == 1"
									style="color: #F56C6C;">已支付</span>
								<span v-else-if="scope.row.examine_status == 1 && scope.row.pay_status == 2"
									style="color: #F56C6C;">支付失败</span>
								<span v-else-if="scope.row.examine_status == 1 && scope.row.pay_status == 3"
									style="color: #F56C6C;">已撤销</span>
								<span v-else-if="scope.row.examine_status == 2">被驳回</span>
							</template>
						</el-table-column>
						<el-table-column prop="reason" label="失败原因" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="提交时间" align="center">
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<!--充值-->
		<el-dialog title="充值金额" :visible.sync="dialogRechargeVisible" width="38%">
			<div class="mb10">
				<el-form :model="recharge" ref="recharge" :rules="rules" label-width="100px"
					class="demo-ruleForm formBox">
					<el-form-item label="充值类型:">
						<el-radio-group v-model="recharge.radiotype" @change="changeRadio">
							<!--  <el-radio disabled :label="1">实付金额</el-radio> -->
							<el-radio :label="2">套餐充值</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="储值套餐:" v-if="recharge.radiotype == '2'">
						<el-select v-model="recharge.combo" size="small" @change="getComboVal(recharge.combo)"
							class="inputWidth" placeholder="全部">
							<el-option v-for="(item,index) in comboList" :key="index"
								:label="item.pet_card_title" :value="item.pet_card_id"></el-option>
						</el-select>
						<div style="overflow: hidden">
							<label style="float: left;margin-right: 5px;">折扣 </label>
							<el-input size="small" v-model="comboVal.discount"
								style="width: 80px !important;float: left"></el-input>
							<span style="float: left;margin-left: 5px;">%</span>
							<label style="float: left;margin-right: 5px;margin-left: 5px;">充值金额 </label>
							<el-input size="small" v-model="comboVal.package_price"
								style="width: 80px !important;float: left"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="充值金额:" v-if="recharge.radiotype == '1'" prop="money">
						<el-input size="small" v-model="recharge.money" class="inputWidth" @change="inputMoney">
						</el-input>
					</el-form-item>
					<el-form-item label="实付金额:" style="color:#F56C6C;font-weight: bold;"
						v-if="recharge.radiotype == '1'">
						<span v-if="recharge.money">¥{{recharge.money}}</span>
					</el-form-item>
					<el-form-item label="实付金额:" style="color:#F56C6C;font-weight: bold;"
						v-if="recharge.radiotype == '2' && recharge.combo">
						<span v-if="comboVal.selling_price">¥{{comboVal.selling_price}}</span>
					</el-form-item>
					<el-form-item label="支付类型" v-if="recharge.money || comboVal.selling_price">
						<el-radio v-model="recharge.payType" @change="changePayRadio" label="1">微信</el-radio>
						<!-- <el-radio v-model="recharge.payType" @change="changePayRadio" label="2">支付宝</el-radio> -->
					</el-form-item>
					<el-form-item label="二维码" v-if="recharge.money || comboVal.selling_price" style="margin-bottom: 0;">
						<div class="qr_code_pay">
							<div v-if="recharge.payType==1">
								<img :src="recharge_qrCode" width="95" height="95" style="overflow:hidden;" />
							</div>
							<div v-if="recharge.payType==2">
								<iframe :srcdoc="recharge_qrCode" frameborder="no" border="0" marginwidth="0"
									marginheight="0" scrolling="no" width="95" height="95" style="overflow:hidden;">
								</iframe>
							</div>

						</div>
					</el-form-item>
					<el-form-item style="margin-bottom: 0;" v-if="recharge.money || comboVal.selling_price"><span
							class="pstyle">{{recharge.payType=="1"?"请用微信扫一扫授权":"请用支付宝扫一扫授权"}}</span></el-form-item>
					<el-form-item style="margin-bottom: 0;">
						<el-button type="primary" @click="submitPay('recharge')" size="small">确认支付完成</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<!--订单付款-->
		<el-dialog title="订单付款" :visible.sync="dialogPayVisible" width="30%">
			<div class="statepic"></div>
			<div class="paystate">支付失败</div>
			<div class="paybtn">
				<el-button type="primary" size="small">重新支付</el-button>
			</div>

		</el-dialog>
		<!--提现-->
		<el-dialog title="提现" :visible.sync="dialogWithdrawVisible" width="30%">
			<el-form :model="withdrawForm" ref="withdrawForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="可提现金额:">{{balance}}元</el-form-item>
				<el-form-item label="提现方式:">
					<!-- <el-radio v-model="withdrawForm.payType" label="1">微信</el-radio> -->
					<el-radio v-model="withdrawForm.payType" label="2">支付宝</el-radio>
				</el-form-item>
				<el-form-item label="提现金额:" prop="money" :rules="[
					  { required: true, message: '提现金额不能为空'}]">
					<el-input size="small" style="width: 250px;" placeholder="不能大于可提现金额"
						v-model.number="withdrawForm.money">
					</el-input>
				</el-form-item>
			<!-- 	<el-form-item label="二维码:" v-if="code.appid">
					<div style="height: auto;">
						<div class="qr_code">
							<wxlogin self_redirect="false" :appid="code.appid" :scope="'snsapi_login'"
								:state="code.state" :theme="'black'" :redirect_uri="code.redirect_uri"
								:href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIwMHB4O30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9'">
							</wxlogin>
						</div>
					</div>
				</el-form-item> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
			   <el-button type="primary" size="small" @click="sureZFBdraw()">确认</el-button>
			   <el-button  size="small" @click="dialogWithdrawVisible = false">取消</el-button>
			 </span>
		</el-dialog>
		<!--提现结果-->
		<el-dialog title="提现" :visible.sync="dialogResultVisible" width="30%">
			<div class="statepic">
				<img src="@/assets/img/paysuccess.png" />
			</div>
			<div class="paystate">提交成功</div>
			<div class="paybtn">
				<el-button type="primary" size="small" @click="paySuccess">知道了</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import wxlogin from 'vue-wxlogin'
	import ElPopover from '../../../../common/popover.vue'
	import {
		getChannelWithdrawalData,
		getChannelWithdrawalPageList,
		channelApplyWithdrawal,
		getAuthorizationUrl,
		userLoginAuthorization,
		WithdrawalDispose,
		aliPayWithdrawal
	} from "@/api/channel/accountAdmin"
	import {
		VirtualOrderList,
		petCardList,
		rechargeAmount,
		getVirtualOrderStatus
	} from '@/api/channel/order.js'
	export default {
		components: {
			ElPopover,
			wxlogin
		},
		data() {
			return {
				loading:true,
				code: {},
				selectDay: [{
						id: 1,
						name: "全部",
					},
					{
						id: 2,
						name: "待审核",
					},
					{
						id: 3,
						name: "已通过",
					},
					{
						id: 4,
						name: "被驳回",
					},
				],
				isSelected: 0, //当前选择
				popover_audit_money_name: '储值金额',
				popover_audit_money_title: '储值金额',
				popover_audit_money_content: '充值的金额不可提现哦',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				dialogRechargeVisible: false,
				recharge: {
					radiotype: 2,
					money: '',
					combo: '',
					discount: '',
					rechargeMoney: '',
					payType: '1'
				},
				rules: {
					money: [{
						required: true,
						message: '请填写金额',
						trigger: 'blur'
					}],
				},
				dialogPayVisible: false,
				withdrawForm: {
					payType: '2',
					money: ''
				},
				dialogWithdrawVisible: false,
				dialogResultVisible: false,
				balance: 0,
				earnest: 0,
				reviewed: 0,
				unreviewed: 0,
				comboList: [],
				comboVal: {},
				recharge_qrCode: '',
				order_nuber: '',
				withdrawData: {},
				timer: '',
				QRcode: '',
				QRstate: '',
				withdraw_zfb_qrCode:''
			}
		},
		mounted() {
			this.initData()
		},
		watch: {
			"$route.query": {
				handler(val) {
					this.withdrawForm.money = val.money;
					this.QRcode = val.auth_code;
					this.QRstate = val.state;
					if (this.QRcode && this.QRstate) {
						this.sureWithdraw();
						this.pageData()
					}
				},
				immediate: true
			}
		},
		methods: {
			sureZFBdraw(){
				this.changeWidthdraw();
				
			},
			//初始化
			initData() {
				this.tableData = []
				getChannelWithdrawalData({}).then(res => {
					this.loading=false;
					this.balance = res.data.balance
					this.earnest = res.data.earnest_money
					this.reviewed = res.data.reviewed
					this.unreviewed = res.data.to_reviewed
					this.total = res.data.withdrawal.count
					res.data.withdrawal.list.forEach(item => {
						this.tableData.push({
							withdrawal_id: item.withdrawal_id,
							pay_type: item.pay_type,
							money: item.money,
							service_fee: item.service_fee,
							examine_status: item.examine_status,
							pay_status: item.pay_status,
							reason: item.reason,
							created_at: item.created_at
						})
					})
				})
			},
			//分页数据
			pageData() {
				this.tableData = []
				let state = this.isSelected ? (this.isSelected - 1).toString() : '';
				let params = {
					state: state,
					page: this.currentPage,
					perPage: this.pagesize
				}
				params = this.tools.filterParams(params)
				getChannelWithdrawalPageList(params).then(res => {
					res.data.list.forEach(item => {
						this.tableData.push({
							withdrawal_id: item.withdrawal_id,
							pay_type: item.pay_type,
							money: item.money,
							service_fee: item.service_fee,
							examine_status: item.examine_status,
							pay_status: item.pay_status,
							reason: item.reason,
							created_at: item.created_at
						})
					})
				})
			},
			// 改变选择事件
			changeTime(index) {
				this.isSelected = index;
				this.currentPage = 1
				this.total = 0
				this.pageData()
			},
			//分页
			handleSizeChange(size) {
				this.currentPage = 1
				this.pagesize = size;
				this.pageData()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.pageData()
			},


			//充值弹框
			toRecharge() {
				this.recharge.combo = '';
				this.comboVal = {};
				this.dialogRechargeVisible = !this.dialogRechargeVisible;
				this.cardList()
			},
			//切换获取储值套餐
			changeRadio(val) {
				if (val == 2) {
					this.cardList()
				}
			},
			//储值套餐下拉列表
			cardList() {
				petCardList({}).then(res => {
					this.comboList = res.data;

				})
			},
			//选择下拉获取值
			getComboVal(val) {
				this.comboList.forEach(item => {
					if (item.pet_card_id == val) {
						this.comboVal = item
					}
				})
				this.getRechargeCode()
			},
			//输入值失去焦点
			inputMoney(val) {
				this.recharge.money = val;
				this.getRechargeCode()
			},
			//选择支付方式
			changePayRadio(val) {
				this.recharge.payType = val;
				if (this.recharge.money || this.comboVal.selling_price) {
					this.getRechargeCode();
				}
			},
			//获取支付二维码
			getRechargeCode() {
				let data = {
					order_type: this.recharge.radiotype == 1 ? '1' : '4',
					payType: this.recharge.payType,
					money: this.recharge.radiotype == 1 ? this.recharge.money : this.comboVal.selling_price,
				}
				if (data.order_type == '4') {
					data.commodity_id = this.comboVal.pet_card_id
				}
				rechargeAmount(data).then(res => {
					this.recharge_qrCode = res.data.images;
					this.order_nuber = res.data.order_nuber
				})
			},
			//提交充值
			submitPay(recharge) {
				this.$refs[recharge].validate((valid) => {
					if (valid) {
						let params = {
							order_nuber: this.order_nuber
						}
						getVirtualOrderStatus(params).then(res => {
							if (res.data == 1) {
								this.$message.error("请支付！");
							} else if (res.data == 2) {
								this.$message.success("充值成功！")
								this.dialogRechargeVisible = !this.dialogRechargeVisible;
								this.pageData()
							} else if (res.data == 4) {
								this.$message.error("支付失败！")
								this.dialogRechargeVisible = !this.dialogRechargeVisible;
							}
						})
					} else {
						return false;
					}
				});

			},
			//去提现
			toWithdrow() {
				// this.$alert('此功能正在开通中', '', {
				//           confirmButtonText: '确定'
				       
				//         });
				this.withdrawForm.money = '';
				this.code.appid = '';
				this.QRcode = '';
				this.QRstate = '';
				this.dialogWithdrawVisible = !this.dialogWithdrawVisible
			},
			//提现输入失去焦点
			changeWidthdraw() {
				let data = {
					redirect_uri: window.location.protocol + '//' + window.location.host +'/accountadmin/cashmanagement?fromType=swithdrawal&money=' + this.withdrawForm
						.money
					// is_skip: false
				}

				userLoginAuthorization(data).then(res => {
					//this.withdraw_zfb_qrCode = res.data;
					window.location.href=res.data;
				})
			},
			paySuccess() {
				this.dialogWithdrawVisible = !this.dialogWithdrawVisible;
				this.dialogResultVisible = !this.dialogResultVisible;
			},
			//确认提现
			sureWithdraw() {
				if (this.withdrawForm.money == '') {
					this.$message.error("提现金额不能为空");
					return
				}
				//弹出扫码
				let data = {
					code: this.QRcode,
					state: this.QRstate,
					money: this.withdrawForm.money,
					payType: this.withdrawForm.payType
				}
				aliPayWithdrawal(data).then(res => {
					if (res.return_code == 'SUCCESS') {
						this.$router.push({
							path: '/accountadmin/cashmanagement'
						});
						this.dialogWithdrawVisible = !this.dialogWithdrawVisible; //输入金额弹框
						this.dialogResultVisible = !this.dialogResultVisible; //提现成功弹框
						this.initData()
					}
				}).catch(err => {
					this.$message.error(err.return_msg);
					this.$router.push({
						path: '/accountadmin/cashmanagement'
					});
					this.dialogWithdrawVisible = false;
					this.initData()

				})
			},
		}

	}
</script>

<style lang="scss" scoped="scoped">
	.fund_item {
		margin-left: 20px;

		div {
			font-size: 14px;
			font-weight: 400;
			color: #000;
			line-height: 34px;
		}
	}

	.qr_code {
		width: 200px;
		height: 250px;
	}

	.qr_code_pay {
		width: 100px;
		height: 100px;
	}

	.statepic {
		width: 130px;
		height: 130px;
		margin: 0 auto;

		>img {
			width: 100%;
			height: 100%;
		}
	}

	.paystate {
		margin-top: 10px;
		text-align: center;
		color: #333;
		font-size: 16px;
	}

	.paybtn {
		width: 100%;
		margin-top: 15px;
		text-align: center;
	}

	.fund_item .monval {
		font-size: 26px;

	}
</style>
