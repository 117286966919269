<template>
	<div>
		<PlatShopOrderDetail :order_type="order_type"></PlatShopOrderDetail>
	</div>
</template>

<script>
	import PlatShopOrderDetail from "../../../../../pagecomponents/platShopOrderDetail.vue"
	export default{
		components:{
			PlatShopOrderDetail
		},
		data(){
			return{
				order_type:''
			}
		},
		mounted() {
			this.order_type = this.$route.query.type
		},
	}
</script>

<style scoped lang="scss">

</style>
