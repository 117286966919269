<template>
	<div>
		<div class="wrapper-main pds">
			<el-form :model="form" ref="form" label-width="150px" class="demo-ruleForm">
				<div class="wrapper-cont">
					<div class="pmargin10">
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="grid-content bg-purple">
									<div class="setTitle">基础信息</div>
									<el-form-item label="店铺名称:" prop="shop_name">
										<el-input size="small" placeholder="输入店铺名称" class="inputWidth"
											v-model="form.shop_name"></el-input>
									</el-form-item>
									<el-form-item label="店铺编码:" prop="shop_encoding">
										<el-input size="small" disabled placeholder="输入店铺编码" class="inputWidth"
											v-model="form.shop_encoding"></el-input>
									</el-form-item>
									<el-form-item label="门店类型:" prop="is_entity">
										<el-select v-model="form.is_entity" disabled size="small" class="inputWidth"
											placeholder="请选择门店类型">
											<el-option label="线上门店" value="1"></el-option>
											<el-option label="实体门店" value="2"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="行业类目:" prop="marketing_trade_id">
										<el-select v-model="form.marketing_trade_id" disabled size="small"
											class="inputWidth" placeholder="请选择门店类型">
											<el-option v-for="(item,index) in tradeList" :key="index"
												:label="item.trade_name" :value="item.marketing_trade_id"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="门店LOGO:">
										<div class="icon_blos">
											<div class="icon_blo" @click="shopLogo('shop_logo')">
												<img :src="form.shop_logo" class="item_img" v-if="form.shop_logo" style="margin-right: 0!important;" />
												<div class="iconImg" v-else>
													<i class="el-icon-plus avatar-uploader-icon"></i>
												</div>
											</div>
										</div>
										<p class="imgtxtstyle">弹窗建议尺寸50*50px</p>
									</el-form-item>
									<el-form-item label="门店图片:">
										<div class="img-uploader" @click="storeImg('shop_images')">
											<img v-if="form.shop_images" :src="form.shop_images" class="avatar" />
											<i v-else class="el-icon-plus"></i>
										</div>
									</el-form-item>
									<el-form-item label="所属地区:">
										<el-cascader size="small" placeholder="所属地区" style="width:300px;"
											:options="options" collapse-tags :props="{multiple: false }" clearable
											@change="handleChange" v-model="address" ref="cascader">
										</el-cascader>
									</el-form-item>
									<el-form-item label="地图点位:">
										<div class="mapsure">请在地图中确认地址信息</div>
										<div class="relation" style="margin-left: 0;">
											<div class="absolute">
												<el-input placeholder="请搜索地址" prefix-icon="el-icon-search"
													v-model="addresskeywords" @input="inputAddress"
													style="width: 250px;" size="small">
												</el-input>
											</div>
											<div id="container" style="width:500px;height:350px;"></div>
										</div>
									</el-form-item>
									<el-form-item label="详细地址:">
										<el-input type="text" placeholder="请输入详细地址" @input="setAddressDetail"
											v-model="form.shop_address_detail" class="inputWidth" size="small">
										</el-input>
									</el-form-item>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content bg-purple">
									<div class="setTitle">网店联系人</div>
									<el-form-item label="联系电话:" prop="tele_phone">
										<el-input size="small" maxlength="11" placeholder="输入联系电话"
											class="tele_phone inputWidth" v-model="form.tele_phone"></el-input>
									</el-form-item>
									<el-form-item label="联系人:" prop="tele_name">
										<el-input size="small" placeholder="输入联系人" class="tele_name inputWidth"
											v-model="form.tele_name"></el-input>
									</el-form-item>
									<el-form-item label="QQ:">
										<el-input size="small" placeholder="输入QQ" class="inputWidth"
											v-model="form.shop_qq">
										</el-input>
									</el-form-item>
									<el-form-item label="企业简介">
										<el-input type="textarea" class="inputWidth" v-model="form.synopsis"></el-input>
									</el-form-item>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="wrapper-cont mt10" v-if="this.form.business_license_id && form.business_license_info!=null">
					<div class="pmargin10">
						<div class="setTitle">运营人信息</div>
						<el-form-item label="企业名称:">
							<el-input size="small" disabled placeholder="输入企业名称" class="inputWidth"
								v-model="form.business_license_info.ENTERPRISE_NAME_CH"></el-input>
						</el-form-item>
						<el-form-item label="企业简介:" prop="ABBREVIATION_NAME">
							<el-input size="small" disabled placeholder="输入企业简介" class="inputWidth"
								v-model="form.ABBREVIATION_NAME"></el-input>
						</el-form-item>
						<el-form-item label="营业执照编码:">
							<el-input size="small" disabled placeholder="输入营业执照编码" class="inputWidth"
								v-model="form.business_license_info.ENTERPRISE_ID"></el-input>
						</el-form-item>
						<el-form-item label="工商注册有效期:">
							<el-input type="text" disabled size="small" style="width: 150px;"
								v-model="form.business_license_info.ENTERPRISE_VALID_DATE_START" placeholder="开始时间">
							</el-input>
							- <el-input type="text" disabled size="small" style="width: 150px;"
								v-model="form.business_license_info.ENTERPRISE_VALID_DATE_END" placeholder="结束时间">
							</el-input>
						</el-form-item>
						<el-form-item label="营业执照:">
							<div class="img-uploader">
								<!-- @click="businessLicenseFront('front')"-->
								<img v-if="form.business_license_images" :src="form.business_license_images"
									class="avatar" />
								<i v-else class="el-icon-plus"></i>
							</div>
						</el-form-item>
						<el-form-item label="负责人身份正面:" prop="identity_card_front">
							<div class="img-uploader">
								<!-- @click="identitycardfront('cardfront')" -->
								<img v-if="form.identity_card_front" :src="form.identity_card_front" class="avatar" />
								<i v-else class="el-icon-plus"></i>
							</div>
						</el-form-item>
						<el-form-item label="负责人身份反面:" prop="identity_card_reverse">
							<div class="img-uploader">
								<!--@click="identitycardback('cardback')" -->
								<img v-if="form.identity_card_reverse" :src="form.identity_card_reverse"
									class="avatar" />
								<i v-else class="el-icon-plus"></i>
							</div>
						</el-form-item>
					</div>
				</div>
			</el-form>
		</div>
		<div class="save-cont">
			<div class="cancel-btn" @click="goBack">返回上一步</div>
			<div class="save-btn" @click="toSubmit('form')">保存</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="true" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {
		getMarketingTradeList
	} from '@/api/plat/market.js'
	import {
		getShopInfo,
		saveShopInfo,
		recognition
	} from '@/api/plat/business'
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	import ChooseImg from '../../../../common/uploadImgs.vue'
	var searchService, geocoder, map, markersArray = [];
	import {
		TMap
	} from "@/utils/TMap.js";
	import {
		isvalidPhone
	} from '@/utils/validate'
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入电话号码'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		components: {
			ChooseImg
		},
		data() {
			return {
				form: {
					shop_name: '',
					shop_encoding: '',
					is_entity: '',
					marketing_trade_id: '',
					shop_logo: '',
					shop_images: '',
					province: '',
					province_code: '',
					city: '',
					city_code: '',
					county: '',
					county_code: '',
					shop_address_detail: '',
					tele_phone: '',
					tele_name: '',
					shop_qq: '',
					synopsis: '',
					ENTERPRISE_NAME_CH: '',
					ABBREVIATION_NAME: '',
					ENTERPRISE_ID: '',
					business_license_images: '',
					identity_card_front: '',
					identity_card_reverse: '',
					business_license_info: {}
				},
				address: [],
				rules: {
					shop_name: [{
						required: true,
						message: '请填写店铺名称',
						trigger: 'blur'
					}],
					shop_encoding: [{
						required: true,
						message: '请填写店铺编码',
						trigger: 'blur'
					}],
					is_entity: [{
						required: true,
						message: '请填写门店类型',
						trigger: 'change'
					}],
					marketing_trade_id: [{
						required: true,
						message: '请选择行业类目',
						trigger: 'change'
					}],
					shop_logo: [{
						required: true,
						message: '请上传店铺logo',
						trigger: 'change'
					}],

					tele_phone: [{
							required: true,
							trigger: 'blur',
							validator: validPhone
						}, //这里需要用到全局变量
					],
					tele_name: [{
						required: true,
						message: '请填写联系人',
						trigger: 'change'
					}],
				},
				options: regionData,
				uploadFlag: false,
				typeImg: '',
				shop_id: this.$route.query.id,
				longitude: 30.18534, //经度
				latitude: 120.26457, //纬度
				searchService: null,
				geocoder: null,
				map: null,
				marker: null,
				mapAddress: '浙江省,杭州市,萧山区',
				addresskeywords: '',
				tradeList: [],
				is_entity: '',
				business_license_id: ''
			}
		},
		mounted() {
			this.codeAddress();
			this.getRecInfo();
		},
		methods: {
			//获取营业执照信息
			getRecognition() {
				let data = {
					business_license_path: this.tools.filterImgPre(this.form.business_license_images)
				}
				recognition(data).then(res => {
					this.form.business_license_info = res.data;

				})

			},
			/** 详细地址 */
			setAddressDetail(value) {
				if (this.form.province && this.form.city && this.form.county) {
					this.mapAddress = CodeToText[this.form.province_code] + CodeToText[this.form.city_code] + CodeToText[
						this.form.county_code] + value
					this.codeAddress()
				} else {
					this.mapAddress = this.mapAddress = this.longitude = this.latitude = value
				}
			},
			//获取详细信息
			getRecInfo() {
				getShopInfo({}).then(res => {
					this.form = res.data;
					this.form.is_entity = this.form.is_entity.toString();
					let addressData;
					addressData = res.data.shop_province_code + ',' + res.data.shop_city_code + ',' + res.data
						.shop_county_code;
					addressData = addressData.split(",");
					this.address = addressData;
					let trades = [];
					if(res.data.marketing_trade_info){
						trades.push(res.data.marketing_trade_info);
						this.tradeList = trades;
					}
					
					this.mapAddress = res.data.shop_province + res.data.shop_city + res.data.shop_county
					this.codeAddress();
				})
			},
			//输入调地图
			inputAddress() {
				this.mapAddress = this.addresskeywords;
				this.codeAddress();
			},
			codeAddress() {
				let lng, lat;
				let geocoder = new qq.maps.Geocoder();
				//对指定地址进行解析
				var result = geocoder.getLocation(this.mapAddress);
				//设置服务请求成功的回调函数
				geocoder.setComplete(result => {
					this.longitude = result.detail.location.lng;
					this.latitude = result.detail.location.lat;
					this.setMap()

				});
				//若服务请求失败，则运行以下函数
				geocoder.setError(function() {
					//alert("出错了，请输入正确的地址！！！");
				});
			},
			setMap() {
				var myLatlng = new qq.maps.LatLng(this.latitude, this.longitude);
				var myOptions = {
					zoom: 8,
					center: myLatlng,
					mapTypeId: qq.maps.MapTypeId.ROADMAP
				}
				var map = new qq.maps.Map(document.getElementById("container"), myOptions);
				var marker = new qq.maps.Marker({
					position: myLatlng,
					map: map
				});
			},

			//地址选择
			handleChange(area) {
				this.form.province_code = area[0];
				this.form.city_code = area[1];
				this.form.county_code = area[2];
				this.form.province = CodeToText[this.form.province_code];
				this.form.city = CodeToText[this.form.city_code];
				this.form.county = CodeToText[this.form.county_code];
				this.mapAddress = this.form.province + this.form.city + this.form.county
				this.codeAddress();
			},
			//营业执照
			businessLicenseFront(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			//门店logo
			shopLogo(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			//门店图片
			storeImg(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			//身份证
			identitycardfront(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			identitycardback(type) {
				this.typeImg = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			//返回上一页
			goBack() {
				this.$router.go(-1)
			},
			// 点击确定
			chooseOk(val) {
				if (this.typeImg == "shop_logo") {
					this.form.shop_logo = val[0].resource_name;
				}
				if (this.typeImg == 'shop_images') {
					this.form.shop_images = val[0].resource_name;
				}
				if (this.typeImg == 'front') {
					this.form.business_license_images = val[0].resource_name;
					this.getRecognition();
				}
				if (this.typeImg == 'cardfront') {
					this.form.identity_card_front = val[0].resource_name;
				}
				if (this.typeImg == 'cardback') {
					this.form.identity_card_reverse = val[0].resource_name;
				}
				this.uploadFlag = false;
			},
			//提交设置
			toSubmit(form) {
				this.$refs[form].validate((valid) => {
					let data = JSON.parse(JSON.stringify(this.form));
					data.shop_logo = this.tools.filterImgPre(data.shop_logo);
					data.shop_logo = this.tools.filterImgPre(data.shop_logo);
					data.shop_images = this.tools.filterImgPre(data.shop_images);
					data.business_license_images = this.tools.filterImgPre(data.business_license_images);
					data.identity_card_reverse = this.tools.filterImgPre(data.identity_card_reverse);
					data.identity_card_front = this.tools.filterImgPre(data.identity_card_front);
					if (data.is_entity == 1) {
						delete data['business_license_images'];
					}
					delete data['ENTERPRISE_NAME_CH'];
					delete data['ABBREVIATION_NAME'];
					delete data['ENTERPRISE_ID'];
					delete data["business_license_info"];

					data = this.tools.filterParams(data);
					if (valid) {
						saveShopInfo(data).then(res => {
							this.$message.success("设置成功！")
							this.getRecInfo()
						})

					} else {
						return false;
					}
				});
			},
		}

	}
</script>

<style scoped>
.pds{
	padding-bottom: 120px !important;
	}
</style>
