import axios from '@/config/httpConfig'
import qs from  'qs'
//获取订单列表
export const orderList=(params)=> {
	  return axios('get','/Order/platform/orderList', params)
}
//获取订单信息
export const orderInfo=(params)=> {
	  return axios('get','/Order/platform/orderInfo', params)
}
//添加订单备注
export const saveRemark=(data)=> {
    return axios('post','/Order/platform/saveRemark', data);
}
//售后列表
export const applyForList=(params)=> {
	  return axios('get','/Order/platform/applyForList', params)
}
//售后订单详情
export const saleOrderInfo=(params)=> {
	  return axios('get','/Order/platform/saleOrderInfo', params)
}
//店铺退货地址列表
export const getRefundAddress=(params)=> {
	  return axios('get','/Order/platform/getRefundAddressList', params)
}
//售后订单-审核驳回
export const refuseSaleAfter=(data)=> {
	  return axios('post','/Order/platform/refundMoneyDismissed', data)
}
//售后-退货退款/同意
export const passSaleAfter=(data)=> {
	 return axios('post','/Order/platform/refundConsent', data);
}
//售后-确认收货
export const ConfirmReceivingGoodsSaleAfter=(params)=> {
	  return axios('get','/Order/platform/refundConfirmReceipt', params)
}
//售后-退款
export const refund=(data)=> {
	 return axios('post','/Order/platform/refundMoney', data);
}
//订单物流查询
export const orderNumberQuery=(params)=> {
	  return axios('get','/Order/platform/orderNumberQuery', params)
}
//售后订单物流
export const saleOrderNumberQuery=(params)=> {
	  return axios('get','/Order/platform/saleOrderNumberQuery', params)
}
//物流列表
export const getExpressInfoList=(data)=> {
    return axios('post','product/platform/getExpressInfoList', data);
}
//取消订单
export const closeOrder=(data)=> {
    return axios('post','/Order/platform/closeOrder', data);
}
//发货
export const dispatchBill=(data)=> {
    return axios('post','/Order/platform/dispatchBill', data);
}
//商学院订单列表
export const getOrderList=(params)=> {
	  return axios('get','/College/platform/getOrderList', params)
}
//合伙人订单
export const partnerOrderList=(params)=> {
	return axios('get','/api/Order/partnerOrderList', params)
}
//门店订单
export const shopOrderList=(params)=> {
	return axios('get','/api/Order/shopOrderList', params)
}
//获取单个订单信息
export const getorderInfo=(params)=> {
	return axios('get','/api/Order/orderInfo', params)
}
