import axios from '@/config/httpConfig'
import qs from  'qs'
//获取订单列表
export const getAccountShopRelevance=(params)=> {
	  return axios('get',`/Account/shop/getAccountShopRelevance`, params)
}
//专场状态修改
export const accountList=(params)=> {
	return axios('post','/Account/platform/accountList', params)
}
//会员列表
export const shopVipList=(params)=> {
	return axios('post','/Account/platform/shopVipList', params)
}
//会员储值
export const shopUserCapital=(params)=> {
	return axios('post','/Account/platform/shopUserCapital', params)
}
//会员详情
export const shopUserInfo=(params)=> {
	return axios('get',`/Account/platform/shopUserInfo`, params)
}
//充值记录
export const calculateLog=(params)=> {
	return axios('post','/Account/platform/calculateLog', params)
}
//提现管理
export const withdrawalInfo=(params)=> {
	return axios('get',`/Account/platform/withdrawalInfo`, params)
}
//订单列表
export const orderList=(params)=> {
	return axios('post','/Account/platform/orderList', params)
}
//获取门店详情
export const getShopInfo=(params)=> {
	return axios('post','/Account/shop/getShopInfo', params)
}
//获取用户详情
export const getUserInfo=(params)=> {
	return axios('get','/Account/platform/getUserInfo', params)
}
//获取用户上级链路详情
export const getLink=(params)=> {
	return axios('get','/Commission/platform/parent/link', params)
}
//获取提现列表  
export const getPlatformWithdrawalPageList=(params)=> {
	return axios('get','/Withdrawal/channel/getChannelWithdrawalPageList', params)
}
//同意打款  
export const passWithdrawal=(params)=> {
	return axios('post','/Withdrawal/platform/passWithdrawal', params)
}
//拒绝打款  
export const refuseWithdrawal=(params)=> {
	return axios('post','/Withdrawal/platform/refuseWithdrawal', params)
}
//修改用户等级  
export const levelModify=(params)=> {
	return axios('post','/Account/platform/levelModify', params)
}
//用户余额充值  
export const balanceRecharge=(params)=> {
	return axios('post','/Account/platform/balanceRecharge', params)
}
//获取资金明细列表
export const accountCapitalLog=(params)=> {
	return axios('get','/Account/platform/accountCapitalLog', params)
}
//获取提现记录列表
export const getWithdrawalPageListByAccountId=(params)=> {
	return axios('get','/Withdrawal/platform/getWithdrawalPageListByAccountId', params)
}

//修改上级
export const modifyParent=(params)=> {
	return axios('post','Account/platform/modifyParent', params)
}

//调整分红上限
export const commissionTotalModify=(params)=> {
	return axios('post','/Account/platform/commissionTotalModify', params)
}

//获取转出记录列表
export const transferListPlatform=(params)=> {
	return axios('get','/Withdrawal/platform/transferListPlatform', params)
}