<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20">
					<el-row type="flex" class="row-bg" justify="space-between">
						<el-col :span="18">
							<div class="grid-content bg-purple flex">
								<el-select v-model="search.order_type" placeholder="账户类型" size="small" class="w120 mr10">
									<el-option label="全部" value=""></el-option>
									<el-option label="充值" value="1"></el-option>
									<el-option label="插件购买" value="2"></el-option>
									<el-option label="店铺购买" value="3"></el-option>
									<el-option label="店铺升级" value="5"></el-option>
								</el-select>
								<el-date-picker v-model="dute" type="daterange" @change="changetimer" class="mr10" size="small" :default-time="['00:00:00', '23:59:59']"
									range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
								</el-date-picker>
								<el-button type="primary" size="small" class="mr10" @click="toSearch">查询</el-button>
								<el-button size="small" class="mr10" @click="toReset">重置</el-button>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple" style="text-align: right;">
								<el-button type="primary" size="small" @click="toRecharge">充值</el-button>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="main-cont bgwt mt10">
					<el-table :data="tableData" border style="width: 100%"  max-height="550" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column type="index" width="50" label="序号" align="center">
						</el-table-column>
						<el-table-column prop="actual_payment" label="实付" width="180" sortable align="center">
						</el-table-column>
						<el-table-column  label="状态"  align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.status == '1'">待支付</span>
								<span v-else-if="scope.row.status == '2'" >已支付</span>
								<span v-else-if="scope.row.status == '4'" >已关闭</span>
								<span v-else-if="scope.row.status == '5'" >已使用</span>
							</template>
						</el-table-column>
						<el-table-column prop="discount" label="折扣" sortable align="center">
						</el-table-column>
						<el-table-column prop="type" label="类型" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.order_type == '1'" style="color: #F56C6C;">充值</span>
								<span v-else-if="scope.row.order_type == '2'" >插件购买</span>
								<span v-else-if="scope.row.order_type == '3'" >店铺购买</span>
								<span v-else-if="scope.row.order_type == '5'" >店铺升级</span>
								<span v-else-if="scope.row.order_type == '6'" >皮肤购买</span>
							</template>
						</el-table-column>
						<el-table-column prop="third_payment_amount" label="支付金额" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="提交时间" align="center">
						</el-table-column>
						<el-table-column prop="remark" label="备注信息" align="center">
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<!--充值弹框-->
		<el-dialog title="订单充值" :visible.sync="dialogVisible" width="30%" @close="closeRechage">
			<el-form :model="form"  ref="form" label-width="100px" class="demo-ruleForm">
				<el-form-item label="充值金额:"  prop="money" :rules="[
				  { required: true, message: '充值金额不能为空'}]">
					<el-input size="small" v-model="form.money" @change="inputMoney" class="inputWidth"></el-input>
				</el-form-item>
				<el-form-item label="支付类型">
					 <el-radio v-model="form.payType" @change="changeRadio" label="1">微信</el-radio>
					 <el-radio v-model="form.payType"  @change="changeRadio" label="2">支付宝</el-radio>
				</el-form-item>
				<el-form-item>
					<div class="qr_code">
						<div v-if="form.payType==1">
							<img v-if="form.money" :src="recharge_qrCode"   width="95"
							        height="95"
							        style="overflow:hidden;" />
						</div>
						<div v-if="form.payType==2 && form.money">
							<iframe :srcdoc="recharge_qrCode" 
									frameborder="no"
									border="0"
									marginwidth="0"
									marginheight="0"
									scrolling="no"
									width="95"
									height="95"
									style="overflow:hidden;">
							</iframe>
						</div>
						
					</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitPay" size="small">确认支付完成</el-button>
			</span>
		</el-dialog>
	<!-- 	支付状态
		<el-dialog title="订单付款" :visible.sync="dialogPayVisible" width="30%">
			<div class="statepic"></div>
			<div class="paystate">支付失败</div>
			<div class="paybtn">
				<el-button type="primary"  size="small">重新支付</el-button>
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
	import {VirtualOrderList,rechargeAmount,getVirtualOrderStatus} from '@/api/merchant/finance.js'
	export default {
		data() {
			return {
				loading:true,
				search:{
					order_type:'',
					beginTime:'',
					endTime:''
				},
				account_type: '',
				transaction_time: '',
				dute:'',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				dialogVisible: false,
				dialogPayVisible: false,
				form: {
					money: '',
					payType:'1'
				},
				order_nuber:'',
				recharge_qrCode:''

			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList(){
			
				let params=this.search;
				 params.page=this.currentPage;
				 params.perPage=this.pagesize;
				 params=this.tools.filterParams(params)
				VirtualOrderList(params).then(res=>{
					this.loading=false;
					this.tableData=res.data.list;
					this.total=res.data.count;
					this.tableData.forEach(item=>{
						if(item.discount){
							item.discount=item.discount+'%'
						}
						
					})
				})
			},
			//时间选择
			changetimer(val) { //时间
				this.search.beginTime = val[0] / 1000;
				this.search.endTime = val[1] / 1000;
			},
			//查询
			toSearch(){
				this.currentPage = 1;
				//调接口
				this.getList()
			},
			//重置
			toReset(){
				this.currentPage = 1;
				this.search.order_type='';
				this.dute='';
				this.search.beginTime ='';
				this.search.endTime='';
				//调接口
				this.getList()
			},
			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
			//充值弹框
			toRecharge(){
				this.form.money='';
				this.recharge_qrCode='';
				this.dialogVisible = !this.dialogVisible
			},
			closeRechage(){
				this.form.money='';
				this.recharge_qrCode=''
			},
			//确认充值
			getRechargeCode(){
				let data={
					order_type:'1',
					payType:this.form.payType,
					money:this.form.money,
				}
				rechargeAmount(data).then(res=>{
					this.recharge_qrCode=res.data.images;
					this.order_nuber = res.data.order_nuber
				})
			},
			inputMoney(val){
				if(val){
					this.form.money=val;
					this.getRechargeCode()
				}
				
			},
			changeRadio(val){
				this.form.payType=val;
				if(this.form.money && val){
					this.getRechargeCode();
				}
			},
			submitPay(){
				let params={
					order_nuber:this.order_nuber
				}
				getVirtualOrderStatus(params).then(res=>{
					if(res.data==1){
						this.$message.error("请支付！");
					}else if(res.data==2 ){
						this.$message.success("支付成功！");
						this.dialogVisible = !this.dialogVisible;
						this.getList()
					}else if(res.data==4){
						this.$message.error("支付失败！")
						this.dialogVisible = !this.dialogVisible;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.statepic{
		width: 185px;
		height: 185px;
		margin: 0 auto;
		background-color: red;
	}
	.paystate{
		margin-top: 10px;
		text-align: center;
		color: #333;
		font-size: 16px;
	}
	.paybtn{
		width: 100%;
		margin-top: 15px;
		text-align: center;
	}
</style>
