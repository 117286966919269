import axios from '@/config/httpConfig'
import qs from  'qs'
//获取订单列表
export const goods_list=(data)=> {
  return axios('post','Goods/shop/goods_list', data);
}
//所有商品分类
export const all_cate=(data)=> {
    return axios('post','Category/shop/get_goods_cate', data);
}
//根据父类获取分类
export const cate_list=(data)=> {
    return axios('post','/Category/shop/cate_list', data);
}
//添加店铺商品
export const add_goods=(data)=> {
  return axios('post','Goods/shop/add_goods', data);
}
//创建规格名与规格值
export const createSpecification=(data)=> {
  return axios('post','Goods/shop/createSpecification', data);
}
//规格列表
export const SpecificationList=(data)=> {
  return axios('post','Goods/shop/SpecificationList', data);
}
//删除规格
export const SpecificationDel=(data)=> {
  return axios('post','Goods/shop/SpecificationDel', data);
}
//商品运费模板select列表
export const goods_temp_list=(params)=> {
	  return axios('get','product/shop/goods_temp_list', params)
}
//店铺商品详情-admin
export const goods_deatil=(data)=> {
  return axios('post','Goods/shop/goods_deatil', data);
}
//商品编辑
export const edit_goods=(data)=> {
  return axios('post','Goods/shop/edit_goods', data);
}
//商品发货地址
export const getAddressList=(params)=> {
	  return axios('get','Address/shop/getAddressList', params)
}
//删除商品
export const goods_delete=(data)=> {
  return axios('post','Goods/shop/goods_delete', data);
}
//商品上下架-后台
export const goods_putaway=(data)=> {
  return axios('post','Goods/shop/goods_putaway', data);
}
//查询店铺是否购买了此插件
export const whetherTheMerchantBuysPlugin=(data)=> {
  return axios('post','Goods/shop/whetherTheMerchantBuysPlugin', data);
}

//商品分类列表
export const get_all_Brand=(params)=> {
  return axios('post','Goods/shop/get_all_brand', params);
}
//添加商品分类
export const add_recommend=(params)=> {
  return axios('post','Goods/shop/add_recommend', params);
}
//编辑项目分类
export const edit_recommend=(params)=> {
  return axios('post','Goods/shop/edit_recommend', params);
}
//删除项目分类
export const del_data=(params)=> {
  return axios('post','Goods/shop/del_data', params);
}


//页面配置列表
export const getPageConfig=(params)=> {
  return axios('get','Account/shop/getPageConfig', params);
}

//页面配置保存-修改
export const savePageConfig=(params)=> {
  return axios('post','Account/shop/savePageConfig', params);
}

//页面配置删除
export const delPageConfig=(params)=> {
  return axios('get','Account/shop/delPageConfig', params);
}

//页面配置详情
export const getPageConfigInfo=(params)=> {
  return axios('get','Account/shop/getPageConfigInfo', params);
}

//获取商品分类
export const cate_list1=(data)=> {
  return axios('post','/Category/platform/cate_list', data);
}
//获取所有分类
export const getAllCate=(data)=> {
  return axios('post','/Category/platform/get_all_cate', data);
}
//删除分类
export const del_list=(data)=> {
  return axios('post','/Category/platform/del_list', data);
}
//添加分类
export const add_cate=(data)=> {
  return axios('post','/Category/platform/add_cate', data);
}
//编辑分类
export const edit_cate=(data)=> {
  return axios('post','/Category/platform/edit_cate', data);
}
//禁用启用分类分类
export const change_cate=(data)=> {
  return axios('post','/Category/platform/change_cate', data);
}
// 分类排序
export const changeCateSort=(data)=> {
  return axios('post','Category/platform/changeCateSort', data);
}