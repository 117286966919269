import axios from '@/config/httpConfig'
import qs from  'qs'
//获取订单列表
export const orderList=(params)=> {
	  return axios('get','/Order/shop/orderList', params)
}
//物流列表
export const getExpressInfoList=(data)=> {
    return axios('post','product/shop/getExpressInfoList', data);
}
//发货
export const dispatchBill=(data)=> {
    return axios('post','/Order/shop/dispatchBill', data);
}
//修改
export const saveOrderAddress=(data)=> {
    return axios('post','/Order/shop/saveOrderAddress', data);
}
//取消订单
export const closeOrder=(data)=> {
    return axios('post','/Order/shop/closeOrder', data);
}
//订单详情
export const orderInfo=(params)=> {
	  return axios('get','/Order/shop/orderInfo', params)
}
//售后列表
export const applyForList=(params)=> {
	  return axios('get','/Order/shop/applyForList', params)
}
//售后订单详情
export const saleOrderInfo=(params)=> {
	  return axios('get','/Order/shop/saleOrderInfo', params)
}
//店铺退货地址列表
export const getRefundAddress=(params)=> {
	  return axios('get','/Order/shop/getRefundAddressList', params)
}
//售后订单-审核驳回
export const refuseSaleAfter=(data)=> {
	  return axios('post','/Order/shop/refundMoneyDismissed', data)
}
//售后-退货退款/同意
export const passSaleAfter=(data)=> {
	 return axios('post','/Order/shop/refundConsent', data);
}
//售后-确认收货
export const ConfirmReceivingGoodsSaleAfter=(params)=> {
	  return axios('get','/Order/shop/refundConfirmReceipt', params)
}
//售后-退款
export const refund=(data)=> {
	 return axios('post','/Order/shop/refundMoney', data);
}
//订单物流查询
export const orderNumberQuery=(params)=> {
	  return axios('get','/Order/shop/orderNumberQuery', params)
}
//售后订单物流
export const saleOrderNumberQuery=(params)=> {
	  return axios('get','/Order/shop/saleOrderNumberQuery', params)
}
//核销券码
export const createVerification=(data)=> {
	return axios('post','/api/Order/cancelVerification', data);
}
//导出订单
export const orderExport=(data)=> {
	return axios('get','/Order/platform/orderExport?export=1', data);
}
