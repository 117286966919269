<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont bgwt pp20" >
					<div class="all_search_list" style="margin-bottom: 0;">
						<el-input prefix-icon="el-icon-search" v-model="shop_name" class="inputWidth"  @keyup.enter.native="toSearch" clearable
							placeholder="合伙人名称" size="small" type="text">
						</el-input>
						<el-button style="margin-left: 10px;" type="primary" size="small"  @click="toSearch">搜索</el-button>
						<el-button size="small" @click="toReset">重置</el-button>
					</div>
				</div>
				<div class="main-cont bgwt mt10">
					<el-table :data="tableData" border style="width: 100%"  max-height="550px" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<el-table-column prop="username" label="合伙人名称" align="center"></el-table-column>
						<el-table-column prop="level_name" label="等级" align="center"></el-table-column>
						<el-table-column prop="buyer_type" label="所属"  align="center"></el-table-column>
						<el-table-column prop="total_money" label="收入"  align="center"></el-table-column>
                        <el-table-column prop="available_money" label="余额"  align="center"></el-table-column>
						<el-table-column fixed="right" label="操作"  align="center">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.row)" type="text" size="small"  v-has="'详情'" >详情</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
				<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
        accountShopList
		} from '@/api/plat/finance'
	export default {
		data() {
			return {
				loading:true,
				shop_name:'',
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				tableData:[]
			}
		},
		mounted() {
			this.getList()
		},
		methods:{
			//获取列表
			getList(){
				let params = {
					shop_name:this.shop_name,
					page:this.currentPage,
					perPage:this.pagesize
				}
				params = this.tools.filterParams(params)
				accountShopList(params).then(res=>{
					this.loading=false;
					this.total =res.data.count;
					this.tableData = res.data.list
				})
			},
			//搜索
			toSearch(){
				this.currentPage = 1;
				//调接口
				this.getList()
			},
			toReset(){
				this.currentPage = 1;
				this.shop_name="";
				this.getList()
			},
			handleSizeChange(size) {
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
			handleClick(row){
				this.$router.push({
					path:'/financeadmin/partnercapital/partnerdetais',
					query:{
						account_shop_id:row.account_shop_id,
						}
				})
			},
			
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
