<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-card class="box-card" shadow="never">
                    <el-row :gutter="20">
                        <el-form ref="formpay" class="formstyle" :model="forms" label-width="90px">
                            <el-col :span="12">
                                <div class="or-state"><span></span>订单信息</div>
                                <el-form-item label="订单状态:">
                                    <span v-if="forms.status==1">待支付</span>
                                    <span v-else-if="forms.status==2">已支付</span>
                                    <span v-else-if="forms.status==3">待收货</span>
                                    <span v-else-if="forms.status==4">已收货</span>
                                    <span v-else-if="forms.status==5">已完成</span>
                                    <span v-else-if="forms.status==6">已关闭</span>
                                </el-form-item>
                                <el-form-item label="订单号:">{{forms.order_number}}</el-form-item>
                                <el-form-item label="快递公司:">{{forms.logistics_name}}</el-form-item>
                                <el-form-item label="快递单号:">{{forms.courier_code}}</el-form-item>
                                <el-form-item label="收货人:">{{forms.consignee_name}}</el-form-item>
                                <el-form-item label="联系方式:">{{forms.consignee_phone}}</el-form-item>
                                <el-form-item label="收货地址:">{{(forms.consignee_provincial || '') + (forms.consignee_city
                                || '') + (forms.consignee_county || '') + (forms.consignee_street || '')}}
                                </el-form-item>
                                <el-form-item label="退款原因:">{{forms.orders_refund_reason}}</el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <div class="or-state"><span></span>买家信息</div>
                                <el-form-item label="店铺名称:">{{forms.shop_name}}</el-form-item>
                                <el-form-item label="联系姓名:">{{forms.tele_name}}</el-form-item>
                                <el-form-item label="联系方式:">{{forms.tele_phone}}</el-form-item>
                            </el-col>
                        </el-form>
                    </el-row>
                </el-card>
                <div class="orders-table mt10">
                    <div class="order-num">{{forms.created_at}} <span style="margin-left: 10px;">订单号：{{forms.order_number}}</span></div>
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column prop="goods" label="商品信息" width="380px">
                            <template slot-scope="scope">
                                <div style="display: flex">
                                    <div style="margin:0 20px">
                                        <img :src="scope.row.master_map" style="width: 70px;height: 70px" />
                                    </div>
                                    <div class="namegood">{{ scope.row.goods_name }}</div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column  label="商品类型" align="center">
                            <template slot-scope="scope">
                                    <p v-if="scope.row.order_ganre==1">实物商品</p>
                                    <p v-else-if="scope.row.order_ganre==2">虚拟商品</p>
                                    <p v-else>/</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sku_desc" label="商品属性" align="center">
						<!-- <template slot-scope="scope">
							<div v-for="(item,index) in scope.row.one_specification" :key="index">
								<span>{{item.name}}{{item.value}}</span>
							</div>
						</template> -->
					</el-table-column>
                        <el-table-column prop="goods_price" label="商品单价" align="center">
                        </el-table-column>
                        <el-table-column label="支付方式" align="center">
                            <span>微信支付</span>
                        </el-table-column>
                        <el-table-column prop="goods_count" label="购买数量" align="center">
                        </el-table-column>
                        <el-table-column prop="goods_actual_payment" label="付款金额" align="center">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getorderInfo
} from "@/api/plat/order.js"
export default {
    data() {
        return {
            order_number: this.$route.query.id,
            forms: {},
            tableData: [],
        }
    },
    mounted() {
        if (this.order_number)
            this.getInfo();
    },
    methods: {
        getInfo() {
            let data = {
                order_number: this.order_number
            }
            getorderInfo(data).then(res => {
                this.forms = res.data;
                this.tableData=[this.forms.order_goods_info];
               
            })
        }

    }
}
</script>
<style lang="less" scoped>
@import "@/assets/css/plat/allorderdetail.scss";
</style>