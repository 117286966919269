<template>
	<div>
		<div class="wrapper-main">
			<div style="position: relative;">
				<el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small" class="demo-ruleForm">
					<div class="wrapper-cont">
						<div class="main-cont pp20">
							<div class="setTitle">基本信息</div>
							<el-form-item label="消息名称:">
								<el-input size="small" class="inputWidth" v-model="form.title" placeholder="请输入">
								</el-input>
							</el-form-item>
						</div>
						<div class="main-cont pp20 mt10">
							<div class="setTitle">内容设置</div>
							<el-form-item label="封面:">
								<div class="img_blos" @click="uploadImg('loop')">
									<div class="img_blo">
										<img :src="form.banner" class="item_img" v-if="form.banner" />
										<div class="plusImg" v-else>
											<i class="el-icon-plus avatar-uploader-icon"></i>
										</div>
									</div>
								</div>
								<p class="imgtxtstyle">建议尺寸：702*150像素</p>
							</el-form-item>
							<el-form-item label="公告内容:">
								<div style="height: 500px; ">
									<Editor v-model="form.link" />
								</div>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div style="position: absolute; top:50px; right: 5%">
					<div style="display: flex;">
						<img src="../../../../../../assets/img/plat/notice.png" style=" width: 235px;" v-show="imgshow" />
						<span style="color: #409EFF;cursor: pointer;" @mouseenter="mouseover"
							@mouseleave="mouseleave">示例</span>
					</div>
				</div>
			</div>
			
		</div>
		<div class="save-cont" v-if="type=='eduit' || type=='add'">
			<div class="save-btn" @click="submitSave('form')">保存并发布</div>
			<div class="save-btn" @click="submitSet('form')">保存</div>
			<div class="cancel-btn" @click="cancelBtn()">取消</div>
		</div>
		<div class="save-cont"  v-if="type=='detail'">
			<div class="cancel-btn" @click="cancelBtn()">返回</div>
		</div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import {addNotice,getNoticeInfo,editNotice} from '@/api/plat/setting.js'
	import ChooseImg from "../../../../../common/uploadImgs";
	import Editor from '../../../../../common/newEditor';
	export default {
		components: {
			ChooseImg,
			Editor
		},
		data() {
			return {
				form: {
					title: '',
					banner: '',
					link: undefined,
					
				},
				editorOption: {},
				rules: {},
				imgType: '',
				isSingle: true,
				uploadFlag: false, //是否展示选择图片弹窗
				imgshow: false,
				notice_id:this.$route.query.id,
				type:this.$route.query.type,
				shop_notice_id:''
			}
		},
		mounted() {
			if(this.notice_id){
				this.getInfo()
			}
		},
		methods: {
			uploadImg(type) {
				this.imgType = type;
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				let type = this.imgType;
				this.uploadFlag = false;
				if (type == "loop") {
					this.form.banner = val[0].resource_name;
				}
			},
			//获取详情
			getInfo(){
				let params={
					notice_id:this.notice_id
				}
				getNoticeInfo(params).then(res=>{
					this.form=res.data;
					this.shop_notice_id = res.data.shop_notice_id
				})
			},
			//示例显示隐藏
			mouseover() {
				this.imgshow = true;
			},
			mouseleave() {
				this.imgshow = false;
			},
			//保存设置
			submitSet(form){
				this.$refs[form].validate((valid) => {
					if (valid) {
							let data=JSON.parse(JSON.stringify(this.form));
						data.status='0';
						data.banner=this.tools.filterImgPre(data.banner);
						data=this.tools.filterParams(data);
						if(this.shop_notice_id){
							data.notice_id=this.shop_notice_id
							editNotice(data).then(res=>{
								this.$router.push({
									path:"/settingadmin/merchantsshop/merchantspage",
									query:{
										activeName:'second'
									}
								})
								
							})
						}else{
							addNotice(data).then(res=>{
								this.$router.push({
									path:"/settingadmin/merchantsshop/merchantspage",
									query:{
										activeName:'second'
									}
								})
							})
						}
				
					} else {
						return false;
					}
				});
			},
			//保存发布
			submitSave(form){
				this.$refs[form].validate((valid) => {
					if (valid) {
						let data=JSON.parse(JSON.stringify(this.form));
						data.banner=this.tools.filterImgPre(data.banner);
						data.status='1';
						data=this.tools.filterParams(data);
						if(this.shop_notice_id){
							data.notice_id=this.shop_notice_id
							editNotice(data).then(res=>{
								this.$router.push({
									path:"/settingadmin/merchantsshop/merchantspage",
									query:{
										activeName:'second'
									}
								})
							})
						}else{
							addNotice(data).then(res=>{
								this.$router.push({
									path:"/settingadmin/merchantsshop/merchantspage",
									query:{
										activeName:'second'
									}
								})
							})
						}
						
						
				
					} else {
						return false;
					}
				});
			},
			//返回列表
			cancelBtn(){
				 this.$router.go(-1);//返回上一层
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
</style>
