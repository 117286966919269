<template>
    <div>
        <div class="wrapper-main">
            <div class="wrapper-cont">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                    <el-form-item label="合伙人权益详情" prop="image_url">
                        <div class="img_blos" @click="uploadImg()" style="width: 115px!important;">
                            <div class="img_blo">
                                <img :src="ruleForm.image_url" class="item_img" v-if="ruleForm.image_url"
                                    style="margin-right: 0!important;" />
                                <div class="plusImg" v-else>
                                    <i class="el-icon-plus avatar-uploader-icon"></i>
                                </div>
                            </div>
                        </div>
                        <div>
                            尺寸建议：宽750，不限高
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="save-cont">
            <div class="save-btn" @click="submitForm('ruleForm')">保存</div>
        </div>
        <!-- 选择图片弹窗 -->
        <ChooseImg :single="isSingle" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
    </div>
</template>
<script>
import ChooseImg from '../../../../common/uploadImgs.vue'
import {accountEquityInfo,EditAccountEquity }  from '@/api/plat/partner'
export default {
    components: {
        ChooseImg

    },
    data() {
        return {
            isSingle: true, //上传图片是单张还是多张
            uploadFlag: false, //是否展示选择图片弹窗
            ruleForm: {
                image_url: ''
            },
            rules: {
                image_url: [
                    { required: true, message: '请上传图片', trigger: 'change' }
                ]
            }
        }
    },
      mounted() {
        this.Info()
    },
    methods: {
        Info(){
            accountEquityInfo({}).then(res=>{
                this.ruleForm.image_url=res.data.image_url

            })

        },
        // 选择图片
        uploadImg() {
            this.uploadFlag = !this.uploadFlag;
        },
        // 点击取消
        chooseCancel(val) {
            this.uploadFlag = val;
        },
        // 点击确定
        chooseOk(val) {
            this.uploadFlag = false;
            this.ruleForm.image_url = val[0].resource_name;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let forms = JSON.parse(JSON.stringify(this.ruleForm));
                    forms.image_url = this.tools.filterImgPre(forms.image_url);
                    EditAccountEquity(forms).then(()=>{
                         this.$message({
                            message: '设置完成',
                            type: 'success'
                        });
                        this.Info();
                    })
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style lang="less" scoped>
</style>