<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="main-cont">
					<div class="data-detail">客户名称：{{name}}</div>
					<div class="listdata">
						<el-tabs v-model="activeName" @tab-click="handleClick" style="margin-bottom: 0;">
							<div class="main-cont">
								<el-button v-if="activeName == 'first'" type="primary" size="small" @click="toAdd">添加联系人
								</el-button>
							</div>
							<el-tab-pane label="客户详细信息" name="first">
								<el-table border :data="tableData" style="width: 100%" min-height="250">
									<el-table-column fixed prop="username" label="客户姓名">
									</el-table-column>
									<el-table-column prop="phone" label="联系电话">
									</el-table-column>
									<el-table-column prop="sex" label="性别">
										<template slot-scope="scope">
											<span v-if="scope.row.sex == 1">男</span>
											<span v-else-if="scope.row.sex == 2">女</span>
											<span v-else>未知</span>
										</template>
									</el-table-column>
									<el-table-column prop="source" label="来源">
										<template slot-scope="scope">
											<span v-if="scope.row.source == 1">平台创建</span>
											<span v-else-if="scope.row.source == 2">网上注册</span>
											<span v-else>服务商创建</span>
										</template>
									</el-table-column>
									<el-table-column prop="shop_county" label="区域">
									</el-table-column>
									<el-table-column prop="shop_province" label="省">
									</el-table-column>
									<el-table-column prop="shop_city" label="市">
									</el-table-column>
									<el-table-column label="操作" width="150" align="center">
										<template slot-scope="scope">
											<el-button type="text" size="small" @click="toEdit(scope.row)">修改
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
							<el-tab-pane label="处理过程" name="second">
								<el-table border :data="disposeData" style="width: 100%" min-height="250">
									<el-table-column fixed  label="处理类别">
										<template slot-scope="scope">
											<span v-if="scope.row.customer_source == 1">平台创建</span>
											<span v-else-if="scope.row.customer_source == 2">网上注册</span>
											<span v-else>服务商创建</span>
										</template>
									</el-table-column>
									<el-table-column prop="processing_time" label="处理时间">
									</el-table-column>
									<el-table-column label="处理人员">
										<template slot-scope="scope">
											{{scope.row.account_channel_info != null ?scope.row.account_channel_info.username:''}}
										</template>
									</el-table-column>
									<el-table-column prop="follow_remark" label="处理内容">
									</el-table-column>
								</el-table>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</div>
		<!-- 分页 -->
		<div v-if="total > pagesize" class="pagecurrent">
			<div class="pagination-container">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<!--添加联系人弹框-->
		<el-dialog title="添加联系人" :visible.sync="showDialog" width="450px" @close="hideDialog">
			<el-form :model="createForm" :rules="createRules" label-width="100px" size="small">
				<el-form-item label="客户名称:" prop="username">
					<el-input class="" v-model="createForm.username" placeholder="请填写客户名称" maxlength="20"></el-input>
				</el-form-item>
				<el-form-item label="联系电话:" prop="phone">
					<el-input class="" v-model="createForm.phone" placeholder="请填写联系电话" maxlength="11"></el-input>
				</el-form-item>
				<el-form-item label="性别:">
					<el-select v-model="createForm.sex" placeholder="请选择性别">
						<el-option v-for="item in sexList" :key="item.value" :value="item.value" :label="item.label">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click="hideDialog">取消</el-button>
				<el-button size="small" type="primary" @click="toCreate">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		businessIncubatorLinkmanList,
		createOrUpBusinessIncubatorLinkman,
		followRemark
	} from "@/api/channel/business"
	export default {
		data() {
			return {
				id: '',
				name: '',
				activeName: 'first',
				tableData: [],
				disposeData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				currentId: 0,
				showDialog: false,
				createForm: {
					username: '',
					phone: '',
					sex: '',
					linkman_id: ''
				},
				createRules: {
					username: [{
						required: true,
						message: '请填写客户名称',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请填写联系电话',
						trigger: 'blur'
					}]
				},
				sexList: [{
						label: '男',
						value: 1
					},
					{
						label: '女',
						value: 2
					}
				]
			}
		},
		mounted() {
			if (this.$route.query.id) {
				this.id = this.$route.query.id
				this.initData()
			}
			this.name = this.$route.query.name ? this.$route.query.name : ''
		},
		methods: {
			initData() {
				if (this.activeName == 'first') { //客户详细信息
					let params = {
						business_incubator_id: this.id,
						page: this.currentPage,
						perPage: this.pagesize
					}
					params = this.tools.filterParams(params)
					businessIncubatorLinkmanList(params).then(res => {
						this.tableData=res.data.list;
						this.tableData.forEach(item => {
							item.shop_province = item.shop_province ? item.shop_province : '';
							item.shop_city = item.shop_city ? item.shop_city : '';
							item.shop_county = item.shop_county ? item.shop_county : '';
						
						})
						
						this.total = res.count
					})
				} else { // 处理过程
					let params = {
						business_incubator_id: this.id
					}
					params = this.tools.filterParams(params)
					followRemark(params).then(res => {
						let dispose=[];
						dispose.push(res.data);
						this.disposeData=dispose;
					
					})
				}
			},
			toAdd() {
				this.createForm = {
					username: '',
					phone: '',
					sex: '',
					linkman_id: ''
				}
				this.showDialog = true
			},
			toEdit(row) {
				let sex = row.sex == 3 ? '' : row.sex
				this.createForm = {
					username: row.username,
					phone: row.phone,
					sex: sex,
					linkman_id: row.business_incubator_linkman_id
				}
				this.showDialog = true
			},
			hideDialog() {
				this.showDialog = false
			},
			toCreate() {
				let params = {
					business_incubator_id: this.id,
					business_incubator_linkman_id: this.createForm.linkman_id,
					username: this.createForm.username,
					phone: this.createForm.phone,
					sex: this.createForm.sex ? this.createForm.sex : 3
				}
				
				params = this.tools.filterParams(params);
				createOrUpBusinessIncubatorLinkman(params).then(res => {
					if (res.return_code == 'SUCCESS') {
						let message = this.createForm.linkman_id ? '修改成功' : '添加成功'
						this.$message({
							message: message,
							type: 'success',
						});
						this.initData();
						this.showDialog = false;
						this.createForm.linkman_id='';
					} else {
						this.$message.error(res.return_msg)
					}
				})
			},
			handleClick(tab, event) {
				this.currentPage = 1
				this.pagesize = 10
				this.initData()
			},
			//分页
			handleSizeChange(size) {
				this.currentPage = 1
				this.pagesize = size;
				this.initData()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.initData()
			},
		}
	}
</script>

<style scoped lang="scss">
	.data-detail {
		height: 50px;
		line-height: 50px;
		border-bottom: 1px solid #EEEEEE;
		padding: 0px 20px;
		font-size: 14px;
		color: #666;
		font-weight: bold;
	}

	.listdata {
		margin-top: 20px;
	}
	::v-deep.el-tabs__header {
	   
	    margin: 0 0 0;
	}
</style>
