<template>
	<div>
		<div class="storemain">
			<div class="title">
				商家配送<el-switch class="switch" v-model="shopping" active-color="#409EFF" disabled active-text="已开启">
				</el-switch>
			</div>
		</div>
		<div class="storemain" style="margin-top:10px">
			<el-tabs v-model="templateName" @tab-click="templateClick">
				<el-tab-pane label="运费模板" name="templateFirst"  ref="template">
					<template-manage></template-manage>
				</el-tab-pane>
				<el-tab-pane label="快递管理" ref="express" name="templateSecond">
					<expressage-manage></expressage-manage>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import templateManage from './templatemanage/index.vue'
	import expressageManage from './expressageManage/index.vue'
	export default {
		components: {
			templateManage,
			expressageManage
		},
		data() {
			return {
				shopping: true,
				templateName: 'templateFirst'
			}


		},
		mounted() {
			this.$refs.template.$children[0].getProductTempData();
			console.log(this.$refs.template.$children[0].tableData,"777")
			
		},
		methods: {
			templateClick(tab) {
				if (tab.name === 'templateFirst') {
					this.$nextTick(() => {
						this.$refs.template.$children[0].getProductTempData()
					})
				} else if (tab.name === 'templateSecond') {
					this.$refs.express.$children[0].getExpressInfoListData()

				}
			}
		}

	}
</script>

<style scoped="scoped" lang="scss">
	.storemain {
		background: #F2F2F6;
		border-radius: 4px;
		padding: 10px 10px;
		font-size: 14px;

		.title {
			font-size: 16px;
			padding: 20px 20px;
			color: #333;
			margin-bottom: 20px
		}
	}

	.switch {
		margin-left: 20px
	}
</style>
