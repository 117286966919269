<template>
	<div>
		<img :src="item.goods_main_pic.split(',')[0] | filterImg" class="con_img" @click="toDet(item.goods_id,item.goods_unified_id)"
		 v-if="item.goods_main_pic" />
		<div class="con_text">
			<div class="con_blos" v-if="!item.reserver_count"></div>
			<div class="title rich-omit">{{item.goods_name}}</div>
			<div class="sales">已售 {{item.sale}}件</div>
			<div class="prices">
				<div class="pri_let">￥{{item.sku_market_price}}</div>
				<div class="pri_right" v-if="item.reserver_count > 0">
					<div class="mins" @click="numChange(2)"> - </div>
					<div class="nums">{{item.reserver_count}}</div>
					<div class="adds" @click="numChange(1)"> + </div>
				</div>
			</div>
			<div class="ords" @click="addOrds" v-if="!item.reserver_count">加入订货单</div>
		</div>
	</div>
</template>

<script>
	var timer = null;
	var orignNum = 0;
	// import {
	// 	addOrd,
	// 	orderTools,
	// 	orderList,
	// } from '@/utils/api/orders.js'
	export default {
		props: ['item'],
		data() {
			return {

			}
		},
		created() {
			// console.log(this.item, this.type, 5555)

		},
		mounted() {

		},
		methods: {
			getList() {
				orderList().then(res => {
					// console.log(res, 'ords')
					// this.orders = res.data.list;
					// this.total_money = res.data.total_money;
					// // 获取相似商品
					// this.getLikes();
				})
			},
			// 去商品详情
			toDet(ids, unid) {
				this.$router.push({
					path: '/goodsDetails',
					query: {
						goodId: ids,
						unid: unid,
						goodsType:'1'
					}
				})
			},
			numChange(type) {
				// 判断是否登录
				let name = localStorage.getItem('users');
				if (!name) {
					this.$toast.center('请先登录');
					this.$router.push({
						path: '/shoplogin'
					})
					return
				}
				// console.log(type)
				let item = this.item;
				let nums = item.reserver_count;
				if (type == 2) {
					// 减
					if (nums < 1) {
						this.$toast.center('不能再减少了');
						return
					} else {
						nums--;
						orignNum--;
					}
				} else if (type == 1) {
					// 加
					nums++;
					orignNum++;
				}
				this.$set(this.item, 'reserver_count', nums);
				this.deBounce(item.goods_unified_id, item.goods_sku_id, orignNum)
			},
			deBounce(unId, skuId, nums) {
				// console.log(nums)
				clearTimeout(timer);
				timer = setTimeout(() => {
					addOrd({
						goods_unified_id: unId,
						goods_sku_id: skuId,
						goods_number: nums
					}).then(res => {
						orignNum = 0;
						this.$listeners.changeGoods(nums)
					})
				}, 1500)
			},
			// 点击加入订货单
			addOrds() {
				// 判断是否登录
				let name = localStorage.getItem('users');
				if (!name) {
					// this.$toast.center('请先登录');
					this.$router.push({
						path: '/shoplogin'
					})
					return
				}
				// 掉接口
				let good = this.item;
				addOrd({
					goods_unified_id: good.goods_unified_id,
					goods_sku_id: good.goods_sku_id,
					goods_number: 1
				}).then(res => {
					// this.goods = [...good];
					console.log(res, '加入订货单')
					this.$set(this.item, 'reserver_count', 1);
					this.$listeners.changeGoods(1);
					// 刷新订货单列表
					this.$emit('getOrders')
					// this.$parent.$parent.totalOrds = 100;
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.cons {
		margin-right: 12px;
		position: relative;
		margin-bottom: 10px;
		width: 228px;
		cursor: pointer;
	

		&:hover {
			.con_text {
				.con_blos {
					display: block;
				}

				.ords {
					display: block;
					position: absolute;
					z-index: 12;
					left: 42px;
					top: 50px;
				}
			}

		}

		.cons_img {
			width: 100%;
			height: 356px;
			display: block;
		}

		.con_img {
			width: 100%;
			height: 232px;
			display: block;
			cursor: pointer;
		}

		.con_text {
			font-size: 14px;
			padding: 11px 16px;
			background: #fff;
			text-align: left;
			position: relative;
			cursor: pointer;
			.con_blos {
				display: none;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba(0, 0, 0, 0.5);
			}

			.ords {
				width: 148px;
				height: 36px;
				color: #fff;
				background: #D01717;
				border-radius: 20px;
				text-align: center;
				font-size: 14px;
				line-height: 36px;
				display: none;
			}

			.title {
				color: #333333;
				height: 40px;
			}

			.sales {
				color: #666666;
				margin: 8px 0 10px;
			}

			.prices {
				display: flex;
				justify-content: space-between;

				.pri_let {
					color: #D01717;
					font-size: 18px;
					font-weight: bold;
				}

				.pri_right {
					display: flex;
					align-items: center;

					.mins {
						width: 13px;
						height: 13px;
						border: 1px solid #979797;
						border-radius: 3px;
						text-align: center;
						line-height: 13px;
						color: #979797;
					}

					.nums {
						color: #333333;
						width: 50px;
						padding: 0 10px;
						box-sizing: border-box;
						text-align: center;
					}

					.adds {
						width: 13px;
						height: 13px;
						background-color: #D01717;
						border-radius: 3px;
						text-align: center;
						line-height: 13px;
						color: #fff;

					}
				}

			}
		}
	}
</style>
